import React, { useEffect, useState, useRef } from "react";
import { BsCameraFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getAvatar, isProprietaireOwner } from "../../../../../utils/Utils";
import UseEditUserFom from "../requestForm/UseEditUserFom";
import { FormError } from "../../../../common/CustomInputCheckbox";
import GoogleInput from "../../../../common/GoogleInput";
import { BtnSubmit } from "../../../../common/Button";
import { useAddOrEditUserMutation } from "../../../../../utils/api/user/user.api";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { onSetUser } from "../../../../../redux/slice/User.slice";
import ChangePasswordModal from "../../../../modal/admin/ChangePasswordModal";
import PhoneInput from "react-phone-input-2";
import DeleteAccountSection from "./DeleteAccountSection";
import AccountInfo from "./AccountInfo";
import MyOfferItem from "./MyOfferItem";

function MonCompte() {
  const user = useAppSelector((s) => s?.user?.user);
  const [avatar, setAvatar] = useState<any>(null);
  const {
    register,
    errors,
    onSubmit,
    address,
    addressAgence,
    onChangeAddress,
    isLoading,
    setValue,
    setPhone,
    phone,
    setPhoneWhatsapp,
    phoneWhatsapp,
  } = UseEditUserFom("account");
  const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
    useAddOrEditUserMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (done) {
      // console.log("data", data);
      dispatch(onSetUser(data));
      setAvatar(null);
      Swal.fire({
        icon: "success",
        title: "Photo de profil modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [done, isError]);

  const changeImage = (e: any) => {
    const file = e.target.files[0];
    setAvatar(URL.createObjectURL(file));
    let fd = new FormData();
    fd.append("avatar", file);
    sendData({ slug: user?.slug, userType: user?.user_type, data: fd });
  };

  const ref = useRef<any>();

  useEffect(() => {
    if (errors?.telephone?.message) {
      ref.current.scrollIntoView();
    }
  }, [errors?.telephone]);

  return (
    <div
      className="tab-pane fade show active"
      id="compte"
      role="tabpanel"
      aria-labelledby="compte-tab"
    >
      <div className="kanimmo-dash-locataire-param-main-page-container py-4 mb-3">
        <section className="mb-3">
          <div className="kanimmo-dash-locataire-param-title">
            Informations générales
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-center align-items-center py-4 mb-4 kanimmo-user-avatar-container">
                <label htmlFor="input-test" className="input-testt">
                  <img
                    src={avatar || getAvatar(user?.avatar)}
                    alt="user avatar"
                    className="kanimmo-dash-locataire-param-img-avatar"
                  />
                  <div className="kanimmo-user-avatar-edit-hover">
                    {load ? (
                      <span
                        className="spinner-border spinner-border-sm custom-spinner text-dark me-1 d-inline-block"
                        role="status"
                      ></span>
                    ) : (
                      <BsCameraFill className="edit-avatar-camera-icon" />
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  className="input-add-avatar"
                  id="input-test"
                  onChange={(e) => changeImage(e)}
                />
              </div>
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="nom"
                    className="form-label form-label-modal-custom"
                  >
                    Nom
                  </label>
                  <input
                    type="text"
                    placeholder="Nom"
                    className="form-control form-control-modal-custom form-control-params-custom"
                    id="nom"
                    {...register("nom")}
                  />
                  <FormError error={errors?.nom?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="prenom"
                    className="form-label form-label-modal-custom"
                  >
                    Prénom
                  </label>
                  <input
                    type="text"
                    placeholder="Prénom"
                    className="form-control form-control-modal-custom form-control-params-custom"
                    id="prenom"
                    {...register("prenom")}
                  />
                  <FormError error={errors?.prenom?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="email"
                    className="form-label form-label-modal-custom"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Email"
                    className="form-control form-control-modal-custom form-control-params-custom"
                    id="email"
                    {...register("email")}
                  />
                  <FormError error={errors?.email?.message} />
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="username"
                    className="form-label form-label-modal-custom"
                  >
                    Nom d'utilisateur
                  </label>
                  <input
                    type="text"
                    placeholder="Nom d'utilisateur"
                    className="form-control form-control-modal-custom form-control-params-custom"
                    id="username"
                    {...register("username")}
                  />
                  <FormError error={errors?.username?.message} />
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="tel"
                    className="form-label form-label-modal-custom"
                    ref={ref}
                  >
                    Téléphone
                  </label>
                  {/* <input
                    type="tel"
                    placeholder="Téléphone"
                    className="form-control form-control-modal-custom form-control-params-custom"
                    id="tel"
                    {...register("telephone")}
                  /> */}
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="form-control auth-form-control mb-3 p-0"
                    inputClass="form-control form-control-modal-custom form-control-params-custom"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) => {
                      setPhone(phone);

                      if (
                        country?.format?.match(/\./g)?.length === phone?.length
                      ) {
                        setValue("telephone", phone);
                      }
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                    }}
                  />
                  <FormError error={errors?.telephone?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="whatsapp"
                    className="form-label form-label-modal-custom"
                  >
                    Whatsapp
                  </label>
                  {/* <input
                    type="tel"
                    placeholder="Whatsapp"
                    className="form-control form-control-modal-custom form-control-params-custom"
                    id="whatsapp"
                    {...register("whatsapp")}
                  /> */}
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="form-control auth-form-control mb-3 p-0"
                    inputClass="form-control form-control-modal-custom form-control-params-custom"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "whatsapp",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phoneWhatsapp}
                    onChange={(phone, country: any) => {
                      setPhoneWhatsapp(phone);

                      if (
                        country?.format?.match(/\./g)?.length === phone?.length
                      ) {
                        setValue("whatsapp", phone);
                      }
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                    }}
                  />
                  <FormError error={errors?.whatsapp?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="adress"
                    className="form-label form-label-modal-custom"
                  >
                    Adresse
                  </label>
                  <GoogleInput
                    className="form-control form-control-modal-custom form-control-params-custom"
                    value={address !== null ? address : addressAgence !== null ?  addressAgence : ""}
                    onChange={onChangeAddress}
                  />
                  <FormError error={errors?.adresse_agence?.message} />
                  {/* <input
                      type="text"
                      placeholder="Petit Mbao"
                      className="form-control form-control-modal-custom form-control-params-custom"
                      id="adress"
                    /> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="boite"
                    className="form-label form-label-modal-custom"
                  >
                    Code postale
                  </label>
                  <input
                    type="number"
                    placeholder="Code postal"
                    className="form-control form-control-modal-custom form-control-params-custom"
                    id="boite"
                    {...register("code_postal")}
                  />
                  <FormError error={errors?.code_postal?.message} />
                </div>
              </div>
            </div>
            <div className="container-btn-modal row my-4">
              <div className="col-md-12">
                <div className="d-flex gap-3 justify-content-end">
                  <BtnSubmit label="Enregistrer" isLoading={isLoading} />
                  {/* <button className="btn auth-submit-btn" type="submit">
                      Enregistrer
                    </button> */}
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
      {/* Offer */}

      {/* {(user?.user_type === "agence" || user?.gerer_par_moi) &&
        user?.offre?.id && <MyOfferItem />} */}
      {/* Account Infos */}
      {/* {(user?.user_type === "agence" || isProprietaireOwner(user)) && (
        <AccountInfo />
      )} */}

      {/* Change password */}
      {/* <div className="kanimmo-dash-locataire-param-main-page-container py-4 mb-3">
        <div className="kanimmo-dash-locataire-param-title mb-4">
          Modifier votre mot de passe
        </div>
        <div className="kanimmo-dash-locataire-param-password-container">
          <div className="kanimmo-dash-locataire-param-password-title">
            Mot de passe
          </div>
          <button
            className="btn auth-submit-btn"
            data-bs-toggle="modal"
            data-bs-target="#ChangePasswordModal"
          >
            <FiEdit /> Changer de mot de passe
          </button>
        </div>
        <ChangePasswordModal />
      </div> */}
      {/* Demande suppression section */}
      {/* <DeleteAccountSection /> */}
    </div>
  );
}

export default MonCompte;
