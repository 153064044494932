import React from 'react'
import Skeleton from 'react-loading-skeleton'

function ReseauSociauxSkeleton() {
   return (
    <div className="col-md-12">
    <table className="table table-striped">
      <thead>
        <tr>
          <th
            scope="col"
            style={{
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#AAB2C8",
            }}
          >
            Url
          </th>
          <th
            scope="col"
            style={{
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#AAB2C8",
            }}
          >
            Description
          </th>
          <th
            scope="col"
            style={{
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#AAB2C8",
            }}
          >
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            <Skeleton />
          </th>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <Skeleton />
          </th>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <Skeleton />
          </th>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <Skeleton />
          </th>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
   )
}

export default ReseauSociauxSkeleton