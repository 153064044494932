import React, { useEffect, useState } from "react";
import AddPersonnalInfo from "./AddPersonnalInfo";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import AddInfoAgency from "./AddInfoAgency";
import useRegisterAgencePIForm from "../useAuthForm/useRegisterAgence";
import { UserType } from "../../../utils/api/user/user.type";
import Logo from "../../../assets/appImages/kanimmoLogoNoir.png";
import { NavLink } from "react-router-dom";
import ConditionsModal from "../../modal/user/ConditionsModal";
import CheckStatusPaymentModal from "../../TableauDebord/locataire/Dashboard/MonLoyer/CheckStatusPaymentModal";
import ChoicePaymentMethod from "../../TableauDebord/locataire/Dashboard/MonLoyer/ChoicePaymentMethod";
import OffersModal from "../../modal/user/OffersModal";

const steps = [
  {
    id: "add-info-personnel",
    Component: AddPersonnalInfo,
  },
  {
    id: "add-info-agency",
    Component: AddInfoAgency,
  },
];
const AgencyRegister = () => {
  const {
    register,
    onSubmit,
    setValue,
    errors,
    level,
    dataForm,
    handelLogoChange,
    previewLogo,
    handelAvatarChange,
    previewAvatar,
    onChangeAddressAgence,
    addressAgence,
    onRegister,
    handleCountrySelect,
    handleStateSelect,
    country,
    state,
    watchAllFields,
    isLoading,
    mode,
    onLoading,
    onCheckStatus,
    seconds,
    onPressSubmit,
  } = useRegisterAgencePIForm();

  const payerAbonnementEcheance = () => {
    alert("payment");
  };

  const [personnalInfo, setPersonnalInfo] = useState<UserType>();
  const { step, navigation, index }: useStepType = useStep({
    initialStep: 0,
    steps,
  });
  const { Component } = step;
  const props = {
    navigation,
    personnalInfo,
    setPersonnalInfo,
    register,
    onSubmit,
    setValue,
    errors,
    isLoading,
    level,
    dataForm,
    handelLogoChange,
    previewLogo,
    handelAvatarChange,
    previewAvatar,
    onChangeAddressAgence,
    addressAgence,
    handleCountrySelect,
    handleStateSelect,
    country,
    state,
    watchAllFields,
  };

  useEffect(() => {
    setValue("step", index + 1);
  }, [index]);

  useEffect(() => {
    if (level === 1) {
      navigation.go("add-info-agency");
    }
  }, [level, dataForm]);

  return (
    <div className="agency-register-component">
      <div className="auth-component">
        <div className="nav-empty-header-login">
          <NavLink className="navbar-brand" to="/">
            <img
              src={Logo}
              alt="Logo"
              className="logo-app"
              style={{ maxWidth: "250px", height: "170px" }}
            />
          </NavLink>
        </div>
        <div className="fixed-login-component">
          <div className="auth-container">
            <div className="auth-row row">
              <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 auth-col auth-right-side-col">
                <div className="auth-right-side-container">
                  <div className="auth-form-container">
                    <div className="py-4">
                      <h1 className="auth-form-title">Créer votre compte</h1>
                    </div>
                    <form onSubmit={onSubmit}>
                      <Component {...props} />
                    </form>
                    <div
                      className="modal fade"
                      id="ConditionsModal"
                      aria-labelledby="ConditionsModalLabel"
                      aria-hidden="true"
                    >
                      <ConditionsModal onValid={onRegister} />
                    </div>
                    <div
                      className="modal fade"
                      id="choicePaymentMethod"
                      aria-labelledby="choicePaymentMethodLabel"
                      aria-hidden="true"
                    >
                      <ChoicePaymentMethod
                        onPay={onPressSubmit}
                        isLoading={isLoading}
                        mode={mode}
                      />
                    </div>
                    <CheckStatusPaymentModal
                      isLoading={onLoading}
                      onCheckStatus={onCheckStatus}
                      seconds={seconds}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="offersModal"
        aria-labelledby="offersModalLabel"
        aria-hidden="true"
      >
        <OffersModal type="agence" />
      </div>
    </div>
  );
};

export default AgencyRegister;
