import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { NavLink } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import { useDeletedUsersListQuery } from "../../../../utils/api/user/user.api";
import { getAvatarFromUser } from "../../../../utils/Utils";
import { IUser, SuppressionType } from "../../../../utils/api/user/user.type";
import { CustomPagination } from "../Administratif/ListeFacture/ListeFactureAdminTable";
import AccountManagerSkeleton from "./AccountManagerSkeleton";

const AccountManagerTable = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useDeletedUsersListQuery({
      page,
      limit,
    });

  useEffect(() => {
  // console.log(data);
  }, [data]);
  const nameFormatter = (cell: IUser, row: any) => {
    let fullname = cell?.prenom && cell?.nom ? cell?.prenom + " " + cell?.nom : cell?.username
    return (
      <div className="image-and-name-container">
        <img
          src={getAvatarFromUser(cell)}
          alt="Produit"
          className="img-produit-table-admin"
        />
        <span className="ps-2">{fullname}</span>
      </div>
    );
  };

  const actionFormatter: any = (cell: string, row: SuppressionType) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <div>
          <NavLink
            to={`/admin/demande-suppression-compte/${row?.user?.slug}`}
            className="btn btn-action-user-admin"
            state={row}
          >
            <AiOutlineEye />
          </NavLink>
        </div>
      </div>
    );
  };
  const statusFormatter = (cell: string, row: SuppressionType) => {
    if (cell === "acceptee") {
      return <span className="status-reservation-valider">Valider</span>;
    }
    if (cell === "refusee") {
      return <span className="status-reservation-refuser">Refuser</span>;
    }
    if (cell === "archivee") {
      return <span className="status-reservation-archiver">Archiver</span>;
    }
    if (cell === "nouvelle") {
      return <span className="status-reservation-en-attente">En attente</span>;
    }
  };
  const columns = [
    {
      dataField: "user",
      text: "Nom",
      formatter: (cell: IUser, row: any) => nameFormatter(cell, row),
      style: { textAlign: "left" },
    },
    {
      dataField: "email",
      text: "Email",
      
      formatter: (cell: string, row: SuppressionType) => <a className='no-link' href={`mailto:${row?.user?.email}`}>{row?.user?.email}</a>,
    },
    {
      dataField: "telephone",
      text: "N° téléphone",
      style: { textAlign: "left" },
      formatter: (cell: string, row: SuppressionType) => <a className='no-link' href={`tel:${row?.user?.telephone}`}>{row?.user?.telephone}</a>,
    },

    {
      dataField: "created_at",
      text: "Date d'envoie",
      formatter: (cell: Date, row: SuppressionType) =>
        moment(cell).format("DD/MM/YYYY à HH:mm"),
    },
    {
      dataField: "status",
      text: "Statut",
      style: { textAlign: "center" },
      formatter: (cell: SuppressionType["status"], row: SuppressionType) =>
        statusFormatter(cell, row),
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: SuppressionType) =>
        actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <AccountManagerSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            striped
            bordered={true}
            condensed={false}
            noDataIndication={() => "Aucune demande pour le moment"}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            perPage={limit}
            onChange={(page, perPage) => {
              setLimit(perPage);
              setPage(page);
            }}
          />
        </>
      )}
    </>
  );
}

export default AccountManagerTable;
