import React from 'react'
import LocataireEnLitigeTable from '../../../../../tables/agence/LocataireTable/LocataireEnLitigeTable';

function LocatairesEnCoursDeLitige() {
  return (
    <div className="bg-white p-3 filter-and-table-container">
      <div className="bloc-filter-locataitaires-agence"></div>
      <div>
        <LocataireEnLitigeTable />
      </div>
    </div>
  );
}

export default LocatairesEnCoursDeLitige