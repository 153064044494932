import React from "react";
import { AiFillPlusSquare } from "react-icons/ai";
import { useSearchParams,NavLink } from "react-router-dom";
import "../../ProprietaireGererParLeProprietaire/Proprietes/ProprietesPQGSB.css";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../../utils/type";
import { Button } from "reactstrap";
import AjouterProprietesModal from "../../../../modal/agence/AjouterProprietesModal";
import VillaAppartementPQNGPSB from "./Proprietestabs/VillaAppartementPQNGPSB";
import ImmeublesPQNGPSB from "./Proprietestabs/ImmeublesPQNGPSB";
import { useAppSelector } from "../../../../../redux/hooks";

const steps = [
  { id: "villa-appartement", Component: VillaAppartementPQNGPSB },
  { id: "immeubles", Component: ImmeublesPQNGPSB },
];

const tabs = ["Villa et Appartement", "Immeubles"];
function ProprietesPQNGPSB() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const { user } = useAppSelector((s) => s?.user);

  const [searchParams, setSearchParams] = useSearchParams();
  const name = searchParams.get("name");
  const type = searchParams.get("type_propriete");

  const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        name: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };

  const handleFilterByTypePropriete = (
    e: React.FormEvent<HTMLSelectElement>
  ): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        type_propriete: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };

  const props = { handleFilterByTypePropriete, handleFilterByName, name, type };

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Mes propriétés
          </h4>
          {index === 1 ? (
            <NavLink
              to="/proprietes/gerer-par-agence/ajouter-immeuble"
              state={{ type_propriete: "immeuble" }}
              className="btn btn-see-more"
            >
              <AiFillPlusSquare style={{ fontSize: 22 }} />
              <span className="ps-2">Ajouter un immeuble</span>
            </NavLink>
          ) : (
            <Button
              data-bs-toggle="modal"
              data-bs-target="#AjouterProprietesModal"
              className="btn btn-see-more"
            >
              <AiFillPlusSquare style={{ fontSize: 22 }} />
              <span className="ps-2">Ajouter une propriété</span>
            </Button>
          )}
        </div>
        <div
          className="modal fade"
          id="AjouterProprietesModal"
          aria-labelledby="AjouterProprietesModalLabel"
          aria-hidden="true"
        >
          <AjouterProprietesModal modalId="AjouterProprietesModal" />
        </div>
      </div>
      <div className="kanimmo-tabs-locataire-container">
        <ul className="tabs-container pt-5 tabs-container-agence">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              onClick={() => go(key)}
              role="button"
            >
              {tab}
            </li>
          ))}
        </ul>
        <div className="tabs-component-container">
          <Component {...props} />
        </div>
      </div>
    </div>
  );
}

export default ProprietesPQNGPSB;
