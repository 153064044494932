import React, { InputHTMLAttributes } from "react";
import { FieldError } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
type InputPropsType = InputHTMLAttributes<HTMLInputElement> & {
	label: string;
	error?: string | FieldError;
};
export const CustomInputCheckbox = React.forwardRef<HTMLInputElement, InputPropsType>(
	(props, ref) => {
		const { name, error, label, ...rest } = props;
		return (
			<div className="custom-radio-checkbox-container">
				<label className="container-input-chexkbox " aria-labelledby={name}>
					<input name={name} id={name} ref={ref} type="checkbox" {...rest} />
					<span className="checkmark-input-chexkbox"></span>
					<span className="custom-input-label">{label}</span>
				</label>
			</div>
		);
	}
);

export const CustomInputRadio = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
	const { name, error, label, ...rest } = props;
	return (
		<div className="custom-radio-checkbox-container">
			<label className="container-input-chexkbox " aria-labelledby={name}>
				<input name={name} id={name} ref={ref} type="radio" {...rest} />
				<span className="checkmark-input-chexkbox"></span>
				<span className="custom-input-label">{label}</span>
			</label>
		</div>
	);
});

export function FormError({ error }: { error?: string | FieldError | any }) {
	const message = typeof error === "string" ? error : error ? error.message : error;
	if (!message) return null;

	return <ErrorMessage message={message} />;
}
