import React, { useState } from 'react'
import { useDeleteCategoryMutation, useGetCategoryQuery ,useCategorydepensebyuserQuery} from '../../../../utils/api/category/category.api';
import { ICategory } from '../../../../utils/api/category/category.type';
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BiEditAlt } from "react-icons/bi";
import { IoMdTrash } from "react-icons/io";
import { CustomPagination } from "../../../common/CustomPagination";
import { useDelete } from "../../../../utils/helpers";
import { formattedDate } from "../../../../utils/Utils";
import AjouterCategorieModal from '../../../modal/comptabilite/AjouterCategorieModal';
import { AlertInfo } from '../../../common/Alert';
import { useAppSelector } from "../../../../redux/hooks";


function CategorieTableComptable() {
    const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	// const { data, isLoading } = useGetCategoryQuery({ limit: perPage, page });
	const { user } = useAppSelector((state) => state.user);

	const slug: string = user?.slug 

	const { data, error, isLoading } = useCategorydepensebyuserQuery([slug, { limit: 10 }]);
	
	
	const [item, setItem] = useState<ICategory>();
  // console.log("data data", data)
    const actionFormatter: any = (cell: string, row: ICategory) => {
		return (
			<div className="table-actions-btn-container-commandes gap-1 d-flex">
				<button
					className="btn btn-action-modal-icon with-tooltip"
					data-tooltip-content="Modifier"
					data-bs-toggle="modal"
					data-bs-target={`#AddCategorieModal${row?.slug}`}
					// onClick={() => setItem(row)}
				>
					<BiEditAlt />
				</button>
				<DeleteCategorie item={row} />
                <div
                className="modal fade"
                id={`AddCategorieModal${row?.slug}`}
                aria-labelledby="AddCategorieModalLabel"
                aria-hidden="true"
              >
			    <AjouterCategorieModal modalId={`AddCategorieModal${row?.slug}`} item={row} />
              </div>
			</div>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: "Nom",
			style: { textAlign: "left" },
		},
		{
			dataField: "created_at",
			text: "Date d’ajout",
			style: { textAlign: "left" },
			formatter: (cell: string, row: ICategory) => formattedDate(cell),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: ICategory) => actionFormatter(cell, row),
			headerStyle: () => {
				return { width: "80px", whiteSpace: "normal" };
			},
		},
	];

	return (
		<>
			{/* {isLoading && <TagsAdminSkeleton />} */}
			{!isLoading && data?.results && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results?.slice().sort((a:any, b:any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive
						wrapperClasses="table-responsive locataire-table"
						noDataIndication={() => <AlertInfo message='Pas de données' />}
					/>
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={perPage}
					/>
				</>
			)}
             
		</>
	);
}

export default CategorieTableComptable

export function DeleteCategorie({ item }: { item: ICategory }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteCategoryMutation();
	const onDelete = useDelete<ICategory>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette catégorie?",
		successMessage: "Catégorie supprimée",
	});
	return (
		<button
			className="btn btn-action-administratif-proprietaire with-tooltip"
			data-tooltip-content="Supprimer"
			onClick={onDelete}
		>
			<IoMdTrash />
		</button>
	);
}