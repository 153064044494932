const DepenseComptabiliteTableData = [
	{
		id: 1,
		num: "#124",
		date: "18/05/2022",
        client: 'Fama Kebe',
        montant: '250 000 FCFA'
	},
	{
		id: 2,
		num: "#124",
		date: "18/05/2022",
        client: 'Fama Kebe',
        montant: '250 000 FCFA'

	},
	{
		id: 3,
		num: "#124",
		date: "18/05/2022",
        client: 'Fama Kebe',
        montant: '250 000 FCFA'
	},
	{
		id: 4,
		num: "#124",
		date: "18/05/2022",
        client: 'Fama Kebe',
        montant: '250 000 FCFA'
	},
];

export default DepenseComptabiliteTableData