import React, { useState } from "react";
import useSignalerProblemeForm from "./useSignalerProblemeForm";
import { FormError } from "../../common/CustomInputCheckbox";
import { BtnSubmit } from "../../common/Button";
import { useGetTypeProblemeQuery } from "../../../utils/api/typeProbleme/typeProbleme.api";
import { ITypeProbleme } from "../../../utils/api/typeProbleme/typeProbleme.type";
import { RequiredText } from "../../common/Input";
import { IoClose } from "react-icons/io5";

function SignalerProbleme() {
  const { data: listeTypeProbleme } = useGetTypeProblemeQuery({});

  const { register, onSubmit, errors, isLoading, authUser } =
    useSignalerProblemeForm();

  const [showAutre, setShowAutre] = useState(false);

  const handleChangeTypeRequete = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value === "visiteur") {
      setShowAutre(true);
    } else {
      setShowAutre(false);
    }
  };
  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header flex-sb">
          <h5 className="modal-title">Signaler un problème</h5>
          <button
            className="close-modal auth-submit-annuler"
            data-bs-dismiss="modal"
            type="button"
          >
            <IoClose />
          </button>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form action="" onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="nom"
                      className="form-label form-label-modal-custom"
                    >
                      Nom
                    </label>
                    <input
                      type="text"
                      placeholder="Nom"
                      className="form-control form-control-modal-custom"
                      id="nom"
                      defaultValue={authUser ? authUser?.nom : ""}
                      {...register("nom")}
                    />
                    {errors?.nom && <FormError error={errors?.nom} />}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="prenom"
                      className="form-label form-label-modal-custom"
                    >
                      Prénom
                    </label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      className="form-control form-control-modal-custom"
                      id="prenom"
                      defaultValue={authUser ? authUser?.prenom : ""}
                      {...register("prenom")}
                    />
                    {errors?.prenom && <FormError error={errors?.prenom} />}
                  </div>
                </div>
                <div className="auth-form-group col-md-6">
                  <label
                    htmlFor="user_type"
                    className="form-label form-label-contact-us"
                  >
                    Qui êtes-vous?
                    <RequiredText />
                  </label>
                  <select
                    id="user_type"
                    className="form-select auth-form-select form-control contact-us-form-control"
                    {...register("fonction")}
                    onChange={handleChangeTypeRequete}
                    defaultValue={authUser ? authUser?.user_type : "DEFAULT"}
                  >
                    <option value="DEFAULT" disabled>
                      Qui êtes-vous?
                    </option>
                    <option value="agence">Agence</option>
                    <option value="locataire">Locataire</option>
                    <option value="proprietaire">Proprietaire</option>
                    <option value="visiteur">Autre</option>
                  </select>
                  <div className={showAutre ? "d-block mt-3" : "d-none"}>
                    <input
                      type="text"
                      className="form-control contact-us-form-control mb-3"
                      id="autre"
                      placeholder="Préciser"
                      {...register("autre")}
                    />
                  </div>

                  {errors?.fonction && <FormError error={errors?.fonction} />}
                </div>
                <div className="col-md-6 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label className="form-label form-label-modal-custom">
                      Etes-vous inscrit sur la plateforme ?
                    </label>
                    <div className="d-flex align-items-center">
                      <div className="checkbox">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="inscrit"
                          value="true"
                          {...register("inscrit")}
                        />
                        {errors?.inscrit && (
                          <FormError error={errors?.inscrit} />
                        )}
                        <label
                          className="form-check-label form-label-login ms-2"
                          htmlFor="flexCheckDefault1"
                        >
                          OUI
                        </label>
                      </div>
                      <div className="checkbox ms-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="inscrit"
                          value="false"
                          {...register("inscrit")}
                          checked
                        />
                        <label
                          className="form-check-label form-label-login ms-2"
                          htmlFor="flexCheckDefault2"
                        >
                          NON
                        </label>
                      </div>
                      {errors?.inscrit && <FormError error={errors?.inscrit} />}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className="form-label form-label-modal-custom"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      placeholder="Email"
                      className="form-control form-control-modal-custom"
                      id="email"
                      defaultValue={authUser?.email}
                      {...register("email")}
                    />
                    {errors?.email && <FormError error={errors?.email} />}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="type_probleme"
                      className="form-label form-label-modal-custom"
                    >
                      Type de problème
                    </label>
                    <select
                      id="type_probleme"
                      className="form-select auth-form-select border"
                      {...register("type_probleme")}
                      defaultValue={"DEFAULT"}
                    >
                      <option value="DEFAULT" disabled>
                        Choisir un type de problème
                      </option>
                      {listeTypeProbleme?.results?.map((item: any, index) => (
                        <option value={item?.id} key={index}>
                          {item?.titre}
                        </option>
                      ))}
                    </select>
                    {errors?.type_probleme && (
                      <FormError error={errors?.type_probleme} />
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="message"
                      className="form-label form-label-modal-custom"
                    >
                      Message
                    </label>
                    <textarea
                      className="form-control form-control-modal-custom"
                      id="message"
                      rows={4}
                      {...register("message")}
                    ></textarea>
                    {errors?.message && <FormError error={errors?.message} />}
                  </div>
                </div>
                <div className="container-btn-modal row mt-2">
                  <div className="col-md-3">
                    <button
                      className="btn btn-cancelled"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="col-md-6 offset-md-3 flex-r ">
                    <BtnSubmit isLoading={isLoading} label="ENVOYER" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignalerProbleme;
