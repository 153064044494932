import React from 'react'
import ContratLocataireTable from '../../../../../tables/Locataire/Administratif/Contrat/ContratLocataireTable'

function Contrat() {
  return (
    <div className="bg-white p-3">
        <div className='locataire-requete-filtre-container my-4'>
            <form className="row g-3 locataire-requete-filtre-form">
                <div className="col-auto">
                    <label
                        htmlFor="filtreLogement"
                        className="locataire-requete-filtre-label"
                    >
                        Filtrer
                    </label>
                </div>
                <div className="col-auto">
                    <input
                        type="text"
                        className="form-control locataire-requete-filtre-input"
                        id="filtreLogement"
                        placeholder="Entrer un nom"
                    />
                </div>
            </form>
        </div>
        <div>
            <ContratLocataireTable />
        </div>
    </div>
  )
}

export default Contrat