import React, { useEffect } from "react";
import { ISlider } from "../../../utils/api/slider/slider.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import UseCrudSlider from "../../TableauDebord/Admin/Options/CrudSlider/UseCrudSlider";

function ModifierSlideModal({
  slider,
  modalId,
}: {
  slider?: ISlider;
  modalId: string;
}) {
  const { register, setValue, errors, onSubmit, isLoading } =
    UseCrudSlider(modalId, slider);
  useEffect(() => {
    if (slider) {
      setValue("slug", slider?.slug);
      setValue("titre", slider?.titre);
    }
  }, [slider]);
  return (
    <div
      className="modal fade"
      id={slider ? `ModifierSlideModal${slider?.slug}` : ""}
      aria-labelledby="ModifierSlideModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="container-form">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="titre"
                        className="form-label form-label-modal-custom"
                      >
                        Titre du slide
                      </label>
                      <input
                        type="text"
                        placeholder="Titre"
                        className="form-control form-control-modal-custom"
                        id="titre"
                        {...register("titre")}
                      />
                      {errors?.titre && (
                        <FormError error={errors?.titre?.message} />
                      )}
                    </div>
                  </div>
               
                  <div className="col-md-12">
                    <div className="mb-3">
                      <BtnSubmit
                        label="Modifier"
                        isLoading={isLoading}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifierSlideModal;
