import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  EtatLieuFormData,
  IEtatLieu,
} from "../../../../../../utils/api/etatlieu/etatlieu.type";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useGetProprietesByProprietaireQuery } from "../../../../../../utils/api/propriete/propriete.api";
import { useAddOrUpdateEtatLieuMutation } from "../../../../../../utils/api/etatlieu/etatlieu.api";
import { Color } from "../../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../../utils/Utils";
import { IPropriete } from "../../../../../../utils/api/propriete/propriete.type";
import {
  useGetLocataireByProprieteSlugQuery,
  useLazyGetLocataireByProprieteSlugQuery,
} from "../../../../../../utils/api/locataire/locataire.api";
import { ILocataire } from "../../../../../../utils/api/locataire/locataire.type";

function useCrudEtatdesLieux(modalId: string, item?: IEtatLieu) {
  const validationSchema = yup.object().shape({
    hasFile: yup.boolean(),
    locataire: yup
      .number()
      // .required()
      .label("Locataire")
      .transform((val: any) => (isNaN(val) ? null : val))
      .nullable(),
    propriete: yup
      .number()
      .label("Propriéte")
      .transform((val: any) => (isNaN(val) ? null : val))
      .nullable(),
    document: yup.mixed().when("hasFile", {
      is: false,
      then: yup.mixed().required().label("Document"),
    }),
  });

  // console.log("item item item item", item);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm<EtatLieuFormData>({
    resolver: yupResolver(validationSchema),
  });
  const proprietaire = useAppSelector((s) => s.user.user);
  const { data = { results: [] } } = useGetProprietesByProprietaireQuery({
    slug: proprietaire?.slug,
    limit: 10000,
  });
  const [getLocataires, { data: locataire = { results: [] }, status }] =
    useLazyGetLocataireByProprieteSlugQuery();
  const [options, setOptions] = useState<any>([]);

  const [option, setOption] = useState<any>();
  const [locataireItem, setLocataireItem] = useState<any>();
  const [filterProperties, setFilterProperties] = useState<
    IPropriete[] | undefined
  >();

  const [locataires, setLocataires] = useState<
    { label: string; value: number }[] | undefined
  >([]);
  const [document, setDocument] = useState<File>();

  useEffect(() => {
    if (data?.results?.length) {
      const propriete = data?.results?.filter(
        (item) => item?.type_propriete !== "immeuble"
      );
      setOptions(
        propriete?.map((item) => {
          return {
            label: item?.nom,
            value: item?.id,
            ...item,
          };
        })
      );
    }
  }, [data?.results]);

  useEffect(() => {
    if (status === "fulfilled") {
      if (locataire?.results?.length) {
        console.log("locataire?.results", locataire?.results);
        setLocataires(
          locataire?.results?.map((el: ILocataire) => {
            return {
              label: el?.prenom + " " + el?.nom,
              value: el?.id,
              ...el,
            };
          })
        );

        let locataireEl: ILocataire = locataire?.results?.find(
          (item: any) => item?.propriete?.id === option?.id
        );
        if (locataireEl) {
          console.log(locataireEl);
          setValue("locataire", locataireEl?.id);
          setLocataireItem({
            label: locataireEl?.prenom + " " + locataireEl?.nom,
            value: locataireEl?.id,
            ...locataireEl,
          });
        } else {
          console.log("first");
          setValue("locataire", "");
          setLocataireItem("");
        }
      } else {
        
        setValue("locataire", "");
        setLocataires([]);
        setLocataireItem("");
      }
    }
  }, [status]);

  const handleChangePropriete = (e: any) => {
    setOption(e);
    console.log("second",e?.value);
    setValue("propriete", e?.value);
    if (e?.value) {
      getLocataires({ slug: e?.slug });
    }
  };

  const handleChangeLocataire = (e: any) => {
    setValue("locataire", e?.value);
    setLocataireItem(e);
  };

  const handleChangeDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null) {
      // console.log(e.currentTarget.files[0]);
      setValue("document", e.currentTarget.files[0]);
      setDocument(e.currentTarget.files[0]);
    }
  };

  const [addOrUpdateEtatLieu, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateEtatLieuMutation();

  // useEffect(() => {
  //   if (locat) {
  //     const locataires = locat?.results?.map((locataire: ILocataire) => {
  //       return {
  //         label: locataire?.prenom + " " + locataire?.nom,
  //         value: locataire?.id,
  //       };
  //     });
  //     setLocataires(locataires);
  //   }
  // }, [locat]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Etat de lieu ${item ? "modifiée" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        if (!item) {
          reset();
        }
        onHide(modalId);

        // onHide(item ? `AjouterEtatLieuModal${item?.slug}` : "AjouterEtatLieuModal");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof EtatLieuFormData)[] = [
        "propriete",
        "locataire",
        "document",
      ];

      for (let field of fields) {
        if (field === "document" && item["document"]) {
          setValue("hasFile", true);
        } else {
          setValue("hasFile", false);
        }
        if (field === "propriete") {
          setValue("propriete", item?.propriete?.id);
        }
        if (field === "locataire") {
          setValue("locataire", item?.locataire?.id);
          // const locataires = data?.results
          //   ?.find((itm) => itm?.id === parseInt(item?.propriete?.id))
          //   ?.locataires?.map((locataire: any) => {
          //     return {
          //       label: locataire?.prenom + " " + locataire?.nom,
          //       value: locataire?.id,
          //     };
          //   });
          // setLocataires(locataires);
        }
      }
      if (item?.propriete?.id) {
        getLocataires({ slug: item?.propriete?.slug });
        setOption({
          label: item?.propriete?.nom,
          value: item?.propriete?.id,
          ...item?.propriete,
        });
      }
    } else {
      setValue("hasFile", false);
    }
  }, [item]);

  const handleReset = () => {
    if (!item) {
      // reset();
    }
  };

  const onSubmit = async (data: EtatLieuFormData) => {
    const fd = new FormData();
    if (!item) {
      fd.append(
        "proprietaire",
        proprietaire?.proprietaire_id
          ? proprietaire?.proprietaire_id?.id
          : proprietaire?.id
      );
    }
    for (let key of Object.keys(data)) {
      const val = data[key];
      fd.append(key, val);
    }
    console.log(data)
    await addOrUpdateEtatLieu({ slug: item?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangePropriete,
    proprietes: data?.results,
    locataires,
    handleChangeDocument,
    handleReset,
    Controller,
    control,
    option,
    options,
    locataireItem,
    handleChangeLocataire,
  };
}

export default useCrudEtatdesLieux;
