import React from 'react'
import ReseauSociauxTable from '../../../../tables/Admin/Options/ReseauSociaux/ReseauSociauxTable';

function ReseauSociaux() {
    return (
        <div className="bg-white p-3 filter-and-table-container">
          <div>
            <ReseauSociauxTable />
          </div>
        </div>
    );
}

export default ReseauSociaux