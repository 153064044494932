import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import "./ParametreLocataire.css";
import avata from "../../../../../assets/appImages/avata.png";
import masterCard from "../../../../../assets/icons/masterCard.png";
import { BsCameraFill, BsThreeDots } from "react-icons/bs";
import { BtnSubmit } from "../../../../common/Button";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useAddOrEditUserMutation } from "../../../../../utils/api/user/user.api";
import UseEditUserFom from "../../../Agence/OptionAgence/requestForm/UseEditUserFom";
import { onSetUser } from "../../../../../redux/slice/User.slice";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { getAvatar } from "../../../../../utils/Utils";
import { FormError } from "../../../../common/CustomInputCheckbox";
import GoogleInput from "../../../../common/GoogleInput";
import ChangePasswordModal from "../../../../modal/admin/ChangePasswordModal";

function ParametreLocataire() {
  const user = useAppSelector((s) => s?.user?.user);
  const [avatar, setAvatar] = useState<any>(null);
  const { register, errors, onSubmit, isLoading } =
    UseEditUserFom("account");
  const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
    useAddOrEditUserMutation();
  const dispatch = useAppDispatch();



  useEffect(() => {
    if (done) {
    // console.log("data", data);
      dispatch(onSetUser(data));
      setAvatar(null);
      Swal.fire({
        icon: "success",
        title: "Photo de profil modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [done, isError]);

  const changeImage = (e: any) => {
    const file = e.target.files[0];
    setAvatar(URL.createObjectURL(file));
    let fd = new FormData();
    fd.append("avatar", file);
    sendData({ slug: user?.slug, userType: user?.user_type, data: fd });
  };

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Paramètres
          </h4>
          {/* <button className="btn kanimmo-dash-locataire-parametre-header-button">
            <FiEdit className="kanimmo-dash-locataire-parametre-header-icon" />{" "}
            Editer le profil
          </button> */}
        </div>
        <div className="kanimmo-dash-locataire-param-main-page-container py-4 mb-3">
          <section className="mb-3">
            <div className="kanimmo-dash-locataire-param-title">
              Informations générales
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-center align-items-center py-4 mb-4 kanimmo-user-avatar-container">
                  <label htmlFor="input-test" className="input-testt">
                    <img
                      // src={avata}
                      src={avatar || getAvatar(user?.avatar)}
                      alt="user avatar"
                      className="kanimmo-dash-locataire-param-img-avatar"
                    />
                    <div className="kanimmo-user-avatar-edit-hover">
                      {load ? (
                        <span
                          className="spinner-border spinner-border-sm custom-spinner text-dark me-1 d-inline-block"
                          role="status"
                        ></span>
                      ) : (
                        <BsCameraFill className="edit-avatar-camera-icon" />
                      )}
                    </div>
                  </label>
                  <input
                    type="file"
                    className="input-add-avatar"
                    id="input-test"
                    onChange={(e) => changeImage(e)}
                  />
                </div>
              </div>
            </div>
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="nom"
                      className="form-label form-label-modal-custom"
                    >
                      Nom
                    </label>
                    <input
                      type="text"
                      placeholder="I"
                      className="form-control form-control-modal-custom form-control-params-custom"
                      id="nom"
                      {...register("nom")}
                    />
                    <FormError error={errors?.nom?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="prenom"
                      className="form-label form-label-modal-custom"
                    >
                      Prénom
                    </label>
                    <input
                      type="text"
                      placeholder="I"
                      className="form-control form-control-modal-custom form-control-params-custom"
                      id="prenom"
                      {...register("prenom")}
                    />
                    <FormError error={errors?.prenom?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className="form-label form-label-modal-custom"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="I"
                      className="form-control form-control-modal-custom form-control-params-custom"
                      id="email"
                      {...register("email")}
                    />
                    <FormError error={errors?.email?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="tel"
                      className="form-label form-label-modal-custom"
                    >
                      Téléphone
                    </label>
                    <input
                      type="text"
                      placeholder="I"
                      className="form-control form-control-modal-custom form-control-params-custom"
                      id="tel"
                      {...register("telephone")}
                    />
                    <FormError error={errors?.telephone?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="adress"
                      className="form-label form-label-modal-custom"
                    >
                      Adresse
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom form-control-params-custom"
                      value={user?.propriete?.adresse || ""}
                      disabled={true}
                      onChange={() => {}}
                    />
                    <FormError error={errors?.adresse?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="boite"
                      className="form-label form-label-modal-custom"
                    >
                      Code postale
                    </label>
                    <input
                      type="text"
                      placeholder="17500"
                      className="form-control form-control-modal-custom form-control-params-custom"
                      id="boite"
                      {...register("code_postal")}
                    />
                    <FormError error={errors?.code_postal?.message} />
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row my-4">
                <div className="col-md-12">
                  <div className="d-flex gap-3 justify-content-end">
                    <BtnSubmit label="Enregistrer" isLoading={isLoading} />
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
        <div className="kanimmo-dash-locataire-param-main-page-container py-4 mb-4">
          <div className="kanimmo-dash-locataire-param-title mb-4">
            Modifier votre mot de passe
          </div>
          <div className="kanimmo-dash-locataire-param-password-container">
            <div className="kanimmo-dash-locataire-param-password-title">
              Mot de passe
            </div>
            <button
              className="btn auth-submit-btn"
              data-bs-toggle="modal"
              data-bs-target="#ChangePasswordModal"
            >
              <FiEdit /> Changer de mot de passe
            </button>
          </div>
          <ChangePasswordModal />
        </div>
        {/* ===================== MODE PAIEMENT ===================== */}
        {/* <section className="kanimmo-dash-locataire-param-main-page-container py-4 mb-4">
          <div className="kanimmo-dash-locataire-param-title mb-4">
            Mode de paiement
          </div>
          <div className="kanimmo-dash-locataire-param-mode-paie-title mb-3">
            Modes de paiement
          </div>
          <div className="kanimmo-dash-locataire-param-mode-paie-container mb-3">
            <div className="d-flex gap-3">
              <div className="param-mode-paie-logo-container">
                <div className="dropdown param-mode-paie-logo-dropdown">
                  <a
                    className="btn-action-requete-locataire btn dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <BsThreeDots />
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Modifier
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Supprimer
                      </a>
                    </li>
                  </ul>
                </div>
                <img src={masterCard} alt="" className="param-mode-paie-logo" />
              </div>
              <div className="param-mode-paie-logo-container">
                <div className="dropdown param-mode-paie-logo-dropdown">
                  <a
                    className="btn-action-requete-locataire btn dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <BsThreeDots />
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Modifier
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Supprimer
                      </a>
                    </li>
                  </ul>
                </div>
                <img src={masterCard} alt="" className="param-mode-paie-logo" />
              </div>
              <div className="param-mode-paie-logo-container">
                <div className="dropdown param-mode-paie-logo-dropdown">
                  <a
                    className="btn-action-requete-locataire btn dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <BsThreeDots />
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Modifier
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Supprimer
                      </a>
                    </li>
                  </ul>
                </div>
                <img src={masterCard} alt="" className="param-mode-paie-logo" />
              </div>
            </div>
            <div>
              <button className="kanimmo-dash-locataire-param-btm-ajout-modepaie">
                Ajouter un autre mode de paiement
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  htmlFor="ncarte"
                  className="form-label form-label-modal-custom"
                >
                  Numéro de carte
                </label>
                <input
                  type="text"
                  placeholder="I"
                  className="form-control form-control-modal-custom form-control-params-custom"
                  id="ncarte"
                />
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </section> */}
      </div>
    </div>
  );
}

export default ParametreLocataire;
