import React from "react"
import VolkenoSkeleton from "volkeno-react-skeleton";
import 'volkeno-react-skeleton/dist/index.css'
function NewsItemSkeleton() {
  return (
		<section className="page-content-body-container container">
			<div className="partnership-item-page-content">
				<div className="partnership-item-row row">
					<div className="partnership-item-col col-lg-8 col-md-12">
						<div className="container-col-left-news-item">
							<div className="partnership-item-body mb-4">
								<h1 className="partnership-item-title">
									<VolkenoSkeleton
										variant="rect"
										height={20}
										width={130}
										style={{ borderRadius: "3px" }}
									/>
								</h1>
								<div className="news-item-content-header py-3 flex-sb-mobile">
									<div className="d-flex align-items-center">
										<div className="container-img-profil-news-item">
											<VolkenoSkeleton
												variant="rect"
												height={40}
												width={40}
												style={{
													borderRadius: "3px",
												}}
											/>
										</div>
										<div className="container-text-news-item ps-2">
											<span>
												<VolkenoSkeleton
													variant="rect"
													height={10}
													width={180}
													// style={{ borderRadius: "3px"}}
												/>
											</span>
										</div>
									</div>
									<div className="d-flex align-items-center">
										<div className="container-text-news-item ps-2">
											<span>
												<VolkenoSkeleton
													variant="rect"
													height={10}
													width={130}
													// style={{ borderRadius: "3px"}}
												/>
											</span>
										</div>
									</div>
								</div>
								<div className="container-img-banner-news-item mb-3">
									<VolkenoSkeleton
										variant="rect"
										height={280}
										// width={180}
										style={{ borderRadius: "0px" }}
									/>
								</div>
								<div className="partnership-item-description-container">
									<VolkenoSkeleton
										variant="rect"
										height={10}
										// width={180}
										// style={{ borderRadius: "3px"}}
									/>
									<VolkenoSkeleton
										variant="rect"
										height={10}
										// width={180}
										// style={{ borderRadius: "3px"}}
									/>
									<VolkenoSkeleton
										variant="rect"
										height={10}
										width={280}
										// style={{ borderRadius: "3px"}}
									/>
								</div>
							</div>
							<div className="news-item-body flex-sb-mobile">
								<div className="content-share-news-item">
									<div className="text-share-news-item pb-3">
										<VolkenoSkeleton
											variant="rect"
											height={20}
											width={130}
											style={{ borderRadius: "3px" }}
										/>
									</div>
									<div className="content-related-tags gap-2">
										<VolkenoSkeleton
											variant="rect"
											height={45}
											width={100}
											style={{ borderRadius: "3px" }}
										/>
										<VolkenoSkeleton
											variant="rect"
											height={45}
											width={100}
											style={{ borderRadius: "3px" }}
										/>
										<VolkenoSkeleton
											variant="rect"
											height={45}
											width={100}
											style={{ borderRadius: "3px" }}
										/>
									</div>
								</div>
								<div className="content-share-news-item">
									<div className="text-share-news-item pb-3">
										<VolkenoSkeleton
											variant="rect"
											height={20}
											width={130}
											style={{ borderRadius: "3px" }}
										/>
									</div>
									<div className="content-share-on-social-media">
										<section>
											<div className="wrapper d-flex flex-row gap-2">
												<VolkenoSkeleton
													variant="circle"
													height={30}
													width={30}
													style={{
														borderRadius:
															"50%",
													}}
												/>
												<VolkenoSkeleton
													variant="circle"
													height={30}
													width={30}
													style={{
														borderRadius:
															"50%",
													}}
												/>
												<VolkenoSkeleton
													variant="circle"
													height={30}
													width={30}
													style={{
														borderRadius:
															"50%",
													}}
												/>
											</div>
										</section>
									</div>
								</div>
							</div>
						</div>

						<div className="container-col-left-news-item">
							<div className="">
								<div className="container-messages-number py-3">
									<div className="messages-number">
										<VolkenoSkeleton
											variant="rect"
											height={20}
											width={130}
											style={{ borderRadius: "3px" }}
										/>
									</div>
								</div>
								<div className="my-4">
									<div className="reponse-comment-container">
										<div className="comment-message-container mb-3">
											<div className="container-profil-message">
												<VolkenoSkeleton
													variant="circle"
													height={80}
													width={80}
													style={{
														borderRadius:
															"50%",
													}}
												/>
											</div>
											<div className="container-content-message custom-container-content-message ps-3">
												<div className="flex-sb flex-sb-mobile">
													<div>
														<div className="profil-name">
															<VolkenoSkeleton
																variant="rect"
																height={
																	10
																}
																width={
																	130
																}
																// style={{ borderRadius: "3px"}}
															/>
														</div>
														<div className="comment-pub-date-color">
															<VolkenoSkeleton
																variant="rect"
																height={
																	10
																}
																width={
																	100
																}
																// style={{ borderRadius: "3px"}}
															/>
														</div>
													</div>
													<div>
														<button
															className="btn btn-repondre-message"
															type="button"
														>
															<span className="ps-2">
																<VolkenoSkeleton
																	variant="rect"
																	height={
																		10
																	}
																	width={
																		40
																	}
																	// style={{ borderRadius: "3px"}}
																/>
															</span>
														</button>
													</div>
												</div>
												<div className="container-comment-text py-2  pe-4">
													<VolkenoSkeleton
														variant="rect"
														height={10}
														// width={40}
														// style={{ borderRadius: "3px"}}
													/>
													<VolkenoSkeleton
														variant="rect"
														height={10}
														// width={40}
														// style={{ borderRadius: "3px"}}
													/>
													<VolkenoSkeleton
														variant="rect"
														height={10}
														width={130}
														// style={{ borderRadius: "3px"}}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="others-partnerships-col col-lg-4 col-md-12">
						<div className="container-col-right-news-item w-100">
							<h4 className="other-partnerships-title">
								<VolkenoSkeleton
									variant="rect"
									height={20}
									width={130}
									style={{ borderRadius: "3px" }}
								/>
							</h4>
							{[...Array(3)].map((item, index) => {
								return (
									<div
										className="others-partnerships-content"
										key={index}
									>
										<div className="other-partnership-item-container others-news-item-container">
											<div className="container-others-news-item-img">
												<VolkenoSkeleton
													variant="rect"
													height={100}
													width={100}
													style={{
														borderRadius:
															"3px",
													}}
												/>
											</div>
											<div className="others-news-items-content-text">
												<div className="other-partnership-item">
													<VolkenoSkeleton
														variant="rect"
														height={10}
														width={180}
														// style={{ marginBottom: "0px"}}
													/>
												</div>
												<div className="other-partnership-item-moment">
													<VolkenoSkeleton
														variant="rect"
														height={10}
														width={70}
														// style={{ marginBottom: "0px"}}
													/>
													<span>
														<VolkenoSkeleton
															variant="rect"
															height={
																10
															}
															width={
																100
															}
															// style={{ marginBottom: "0px"}}
														/>
													</span>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</section>
  );
}

export default NewsItemSkeleton