import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, onHide } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { ContratFormData, IContrat } from "../../../../../utils/api/contrat/contrat.type";
import { useAppSelector } from "../../../../../redux/hooks";
import { useAddOrUpdateContratAdminProprietaireMutation} from "../../../../../utils/api/contrat/contrat.api";
import { useGetUserByAdminQuery } from "../../../../../utils/api/admin/admin.api";

function useCrudContratAdminProprio(modalId: string, item?: IContrat) {
    const validationSchema = yup.object().shape({
        proprietaire: yup
			.number()
            .required()
			.label("Propriétaire")
			.typeError("Propriétaire est un champ obligatoire"),
        nom_document: yup
			.string()
			.label("Titre")
			.typeError("Titre est un champ obligatoire"),
		document: yup.mixed().required().label("Document"),
	});
    const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
        control,
        reset,
	} = useForm<ContratFormData>({
		resolver: yupResolver(validationSchema),
	});

    const admin = useAppSelector((s) => s.user.user);
    const { data: userAdmin } = useGetUserByAdminQuery({});
	const [document, setDocument] = useState<File>();

	const ListeProprietairsAdmin = userAdmin?.filter(item => item?.user_type === "proprietaire")

	const handleChangeDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files !== null) {
			console.log(e.currentTarget.files[0]);
			setValue("document", e.currentTarget.files[0]);
			setDocument(e.currentTarget.files[0]);
		}
	};

    const [proprios, setProprios] = useState<number | null>();

    const onChangeProprios = (selected: any) => {
		setValue(
			"proprietaire",
			selected?.value
		);
	};

    const [addOrUpdateContrat, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateContratAdminProprietaireMutation();

        useEffect(() => {
            if (isSuccess) {
                Swal.fire({
                    icon: "success",
                    title: `Contrat ${item ? "modifiée" : "ajouté"} avec succès!`,
                    iconColor: Color.success,
                    showConfirmButton: false,
                    timer: 1200,
                }).then(() => {
                    reset();
                    setProprios(null)
                    onHide(
                        modalId
                    );
                });
            }
            const err = error as any;
            if (isError) {
                Swal.fire({
                    icon: "error",
                    title: err?.data?.message
                        ? err?.data?.message
                        : `Erreur de statut ${err?.status}`,
                    showConfirmButton: false,
                    timer: 5000,
                });
            }
        }, [isLoading]);
        useEffect(() => {
            cleannerError(errors, clearErrors);
        }, [errors]);
    
        useEffect(() => {
            if (item?.id) {
                // setLocataires(item?.locataire)
                // setValue("propriete", item?.propriete?.id);
                // setValue("locataire", item?.locataire?.id);
    
            }
        }, [item]);

        const onSubmit = (data: ContratFormData) => {
            data["admin"] = admin?.id
            data["concernant"] = "proprietaire"
            const fd = new FormData();
            for (let key of Object.keys(data)) {
                const val = data[key];
                fd.append(key, val);
            }
          // console.log("data subm",data)
            addOrUpdateContrat({ slug: item?.slug, data: fd });
        };
    
        return {
            register,
            errors: errors,
            onSubmit: handleSubmit(onSubmit),
            setValue,
            isLoading,
            handleChangeDocument,
            listProprios: ListeProprietairsAdmin,
            control,
            Controller,
            onChangeProprios,
            proprios
        };
}

export default useCrudContratAdminProprio