import React from "react";
import RegisterOk from "../../modal/agence/RegisterOk";
import Logo from "../../../assets/appImages/logo.png";

function ProprietaireGPARegister() {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal(e: any) {
    e.preventDefault();
    window.sessionStorage.setItem("userType", "proprietaireAgence");
    setIsOpen(true);
  }
  return (
    <div className="auth-component">
      <div className="fixed-login-component">
        <div className="auth-container">
          <div className="auth-row row">
            <div className="col-md-6 offset-md-3 auth-col auth-right-side-col">
              <div className="auth-right-side-container">
                <div className="auth-form-container">
                  <div className="flex-c">
                    <img src={Logo} alt="Logo" className="logo-app" />
                  </div>
                  <div>
                    <form id="auth-form">
                      <div className="row auth-form-row">
                        <div className="col-md-12 auth-input-col">
                          <div className="py-4 col-md-4 offset-md-4 auth-submit-btn-container">
                            <button
                              className="btn auth-submit-btn"
                              onClick={openModal}
                            >
                              S’inscrire
                            </button>
                            <RegisterOk
                              modalIsOpen={modalIsOpen}
                              setIsOpen={setIsOpen}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProprietaireGPARegister;
