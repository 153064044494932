/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useState } from "react";
import RegisterOk from "../../modal/agence/RegisterOk";
import { IoIosArrowBack } from "react-icons/io";
import ErrorMessage from "../../common/ErrorMessage";
import { BtnSubmit } from "../../common/Button";
import { useGetAgencesQuery } from "../../../utils/api/agence/agence.api";

export type RegisterStepPropsType = {
  navigation: any;
  register: any;
  errors: any;
  isLoading: boolean;
  addressAgence: any;
  onChangeAddressAgence: (e: any) => void;
  type: string;
  handleChangeType: (e: ChangeEvent<HTMLSelectElement>) => void;
  setValue: any;
};

const AddProprietaireInfo: React.FC<RegisterStepPropsType> = ({
  navigation,
  register,
  errors,
  isLoading,
}) => {
  const { data = { results: [] } } = useGetAgencesQuery({});
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(e: any) {
    e.preventDefault();
    window.sessionStorage.setItem("userType", "proprietaire");
    setIsOpen(true);
  }

  const [showAutre, setShowAutre] = useState(true);

  const handleChangePropriete = (e: any) => {
    if (e.target.value) {
      // const agences = data?.results
      //   ?.find((item) => item?.id === parseInt(e.target.value))
      //   ?.proprietes?.map((propriete) => {
      //     return {
      //       label: propriete?.nom,
      //       value: propriete?.id,
      //     };
      //   });
      // setPropriete(agences);
      if (e.target.value === "true") {
        setShowAutre(true);
      } else {
        setShowAutre(false);
      }
    }
  };

  return (
    <div className="auth-form-container">
      <div className="py-4">
        <button
          className="btn btn-go-back-step"
          onClick={() => navigation.previous()}
        >
          <IoIosArrowBack />
          <span className="ps-2">Retour</span>
        </button>
      </div>
      <div>
        <div id="auth-form">
          <div className="row auth-form-row">
            {/* <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Gérez-vous vous-même vos biens?
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div className="d-flex align-items-center">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="true"
                      {...register("gerer_par_moi")}
                      id="flexCheckDefaultoui"
                      onClick={handleChangePropriete}
                      defaultChecked
                    />
                    <label
                      className="form-check-label form-label-login ms-2"
                      htmlFor="flexCheckDefaultoui"
                    >
                      OUI
                    </label>
                  </div>
                  <div className="checkbox ms-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="false"
                      id="flexCheckDefaultnon"
                      {...register("gerer_par_moi")}
                      onClick={handleChangePropriete}
                    />
                    <label
                      className="form-check-label form-label-login ms-2"
                      htmlFor="flexCheckDefaultnon"
                    >
                      NON
                    </label>
                  </div>
                </div>
                {errors?.prenom && (
                  <ErrorMessage message={errors?.prenom?.message} />
                )}
              </div>
            </div> */}
            {/* <div className={showAutre ? "d-block" : "d-none"}>
              <div className="col-md-12 auth-input-col">
                <div className="form-group auth-form-group">
                  <label className="form-label form-label-login">
                    Gérez vous votre comptabilité ou est elle gérée par un
                    comptable?
                  </label>
                  <div className="d-flex align-items-center">
                    <div className="checkbox">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="false"
                        id="flexCheckcomptabletrue"
                        {...register("a_un_comptable")}
                      />
                      <label
                        className="form-check-label form-label-login ms-2"
                        htmlFor="flexCheckcomptabletrue"
                      >
                        Je gère ma comptabilité
                      </label>
                    </div>
                    <div className="checkbox ms-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="true"
                        id="flexCheckcomptablefalse"
                        name="comptable"
                        {...register("a_un_comptable")}
                        defaultChecked
                      />
                      <label
                        className="form-check-label form-label-login ms-2"
                        htmlFor="flexCheckcomptablefalse"
                      >
                        Elle est gérée par un comptable
                      </label>
                    </div>
                  </div>
                  {errors?.a_un_comptable && (
                    <ErrorMessage message={errors?.a_un_comptable?.message} />
                  )}
                </div>
              </div>
            </div> */}

            {/* <div className={showAutre ? "d-none" : "d-block"}>

              <div className="col-md-12 auth-input-col">
                <div className="form-group auth-form-group">
                  <label className="form-label form-label-login">
                    Choisissez votre agence{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    id="agence_id"
                    className="form-select auth-form-select"
                    {...register("agence_id")}
                    onChange={handleChangePropriete}
                  >
                    <option value="">Choisissez votre agence</option>
                    {data?.results?.map((item, index) => (
                      <option value={item?.id} key={item?.slug}>
                        {item?.nom_agence}
                      </option>
                    ))}
                    <option value="autres">
                      Mon agence n’est pas sur cette liste
                    </option>
                  </select>
                </div>
              </div>
              {errors?.agence_id && (
                <ErrorMessage message={errors?.agence_id?.message} />
              )}
            </div> */}

            

            <div className="py-4 col-md-4 offset-md-4 auth-submit-btn-container">
              <BtnSubmit label="S’inscrire modal" isLoading={isLoading} />
              {/* <RegisterOk modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProprietaireInfo;
