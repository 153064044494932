import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { isAgence, isProprietaireOwner, onShow } from "../../../utils/Utils";
import OffersModal from "./OffersModal";
import UseCreateAbonnement from "./UseCreateAbonnement";
import ChoicePaymentMethod from "../../TableauDebord/locataire/Dashboard/MonLoyer/ChoicePaymentMethod";
import CheckStatusPaymentModal from "../../TableauDebord/locataire/Dashboard/MonLoyer/CheckStatusPaymentModal";
import { useLazyMeQuery } from "../../../utils/api/user/user.api";
import { onSetUser } from "../../../redux/slice/User.slice";

function CheckUserAbonnement() {
  const {
    isLoading: loading,
    mode,
    onCheckStatus,
    onLoading,
    onPressSubmit,
    seconds,
    setOffer,
    isChecked,
  } = UseCreateAbonnement("PaymentMethod");
  const { user } = useAppSelector((s) => s?.user);
  const [getData,{ isSuccess, data }] = useLazyMeQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isChecked) {
        getData();
    }
  }, [isChecked]);
  useEffect(() => {
    if (isSuccess) {
      console.log("data", data);
      dispatch(onSetUser(data));
    }
  }, [isSuccess, data]);
  useEffect(() => {
    if (user && !user?.offre && (isAgence(user) || isProprietaireOwner(user))) {
      onShow("offresModal");
    }
  }, [user]);
  return (
    <div>
      <div
        className="modal fade"
        id="offresModal"
        aria-labelledby="offresModalLabel"
        aria-hidden="true"
        style={{ zIndex: 9999 }}
      >
        <OffersModal
          type={isProprietaireOwner(user) ? "proprietaire" : "agence"}
          setOffer={setOffer}
          modalId="PaymentMethod"
        />
      </div>
      {user?.user_type !== "locataire" && (
        <>
          <div
            className="modal fade"
            id="PaymentMethod"
            aria-labelledby="PaymentMethodLabel"
            aria-hidden="true"
          >
            <ChoicePaymentMethod
              onPay={onPressSubmit}
              isLoading={loading}
              mode={mode}
            />
          </div>
          <CheckStatusPaymentModal
            isLoading={onLoading}
            onCheckStatus={onCheckStatus}
            seconds={seconds}
          />
        </>
      )}
    </div>
  );
}

export default CheckUserAbonnement;
