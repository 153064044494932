import React from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetAgenceByProprietaireQuery } from "../../../../../utils/api/proprietaire/proprietaire.api";
import { IAgence } from "../../../../../utils/api/agence/agence.type";
import { getAvatar, getImage } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import VolkenoSkeleton from "volkeno-react-skeleton";

function LIsteAgencesPQNGPSB({
  word,
  location,
}: {
  word?: any;
  location?: any;
}) {
  const { user } = useAppSelector((s) => s?.user);
  const { data = { results: [] }, isLoading } = useGetAgenceByProprietaireQuery({
    word,
    location,
    slug: user?.slug
  });
// console.log("liste agences", data)
  return (
    <div className="row">
      {!isLoading && data?.results?.length === 0 && (
        <AlertInfo message="Aucune donnée trouvéé" />
      )}
      {!isLoading &&
        data?.results?.map((item: IAgence) => (
          <div className="col-md-3" key={item?.id}>
            <div className="card kanimmo-agences-list-card mb-4 p-1 p-md-2">
              <NavLink
                to={`/proprietes/gerer-par-agence/agence/${item?.slug}`}
                className="btn btn-see-more p-0"
                state={item}
              >
                <div className="kanimo-agence-list-card-img-container">
                  <img
                    src={getImage(item?.logo)}
                    alt="img agence"
                    className="kanimmo-agence-list-img-top"
                  />
                </div>
                <div className="card-body pb-1 px-1">
                  <h5 className="card-title kanimmo-agence-list-nom">
                    {item?.nom_agence}
                  </h5>
                  <p className="card-subtitle mb-2 kanimmo-agence-list-nbr-birns ps-2">
                    
                    {item?.proprietes?.length >= 10
                      ? "+9 propriétés"
                      : item?.proprietes?.length > 1
                      ? `${item?.proprietes?.length} propriétés`
                      : `${item?.proprietes?.length} propriété`}{" "}
                  </p>
                  <div className="d-flex gap-2 align-items-center">
                    <img
                      src={getAvatar(item.avatar)}
                      alt="img avatar"
                      className="kanimmo-agence-list-img-avatar"
                    />
                    <div className="kanimmo-agence-list-username">
                      {item?.prenom + " " + item?.nom}
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        ))}
      {isLoading &&
        [...Array(8)].map((item, index) => {
          return <ListeAgenceSkeleton key={index} />;
        })}
    </div>
  );
}

export default LIsteAgencesPQNGPSB;

const ListeAgenceSkeleton = () => {
  return(
    <div className="col-md-3" >
        <div className="card kanimmo-agences-list-card mb-4 p-1 p-md-2">
          <div
            className="btn btn-see-more p-0"
          >
            <div className="kanimo-agence-list-card-img-container skeleton-kanimo-agence-list-card-img-container">
            <VolkenoSkeleton variant="rect" height={100} width="100%" className="kanimmo-agence-list-img-top" />
            </div>
            <div className="card-body pb-1 px-1">
              <h5 className="card-title kanimmo-agence-list-nom">
              <VolkenoSkeleton variant="rect" height={10} width={100} />
              </h5>
              <p className="card-subtitle mb-2 kanimmo-agence-list-nbr-birns ps-2">
              <VolkenoSkeleton variant="rect" height={10} width={100} />
              </p>
              <div className="d-flex gap-2 align-items-center">
               
                <VolkenoSkeleton variant="circle" height={20} width={20} />
                <div className="kanimmo-agence-list-username">
                <VolkenoSkeleton variant="rect" height={10} width={100} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
