import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IoMdTrash } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useDeleteCommoditesMutation, useGetCommoditesQuery } from "../../../../../utils/api/commodites/commodites.api";
import { ITypeCommodites, TypeCommoditesFormData } from "../../../../../utils/api/commodites/commodites.type";
import { useDelete } from "../../../../../utils/helpers";
import { AlertInfo } from "../../../../common/Alert";
import { CustomPagination } from "../../../../common/CustomPagination";
import AjouterCommoditesModal from "../../../../modal/admin/AjouterCommoditesModal";
import CommoditesSkeleton from "./CommoditesSkeleton";
import { showModal } from "../../../../../utils/Utils";

function CommoditesTable() {

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { data= { results: [] }, isLoading } = useGetCommoditesQuery({
        page: page,
        limit: perPage,
    });
    
    const actionFormatter: any = (cell: any, row: any) => {
        return (
          <div className="table-actions-btn-container-commandes gap-2 d-flex">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              // data-bs-toggle="modal"
              // data-bs-target={`#AddCommoditeModal${row?.slug}`}
              onClick={() => showModal(`AddCommoditeModal${row?.slug}`)}
            >
              <MdEdit />
            </button>
            <DeleteCommodites item={row} />

            <div
              className="modal fade"
              id={`AddCommoditeModal${row?.slug}`}
              aria-labelledby={`#AddCommoditeModal${row?.slug}Label`}
              aria-hidden="true"
            >
              <AjouterCommoditesModal item={row} />
            </div>
          </div>
        );
      };


      const columns = [
        {
          dataField: "nom",
          text: "Nom",
        },
        // {
        //   dataField: "description",
        //   text: "Description",
        //   style: { textAlign: "left" },
        // },
        {
          dataField: "slug",
          text: "Actions",
          style: { textAlign: "center" },
          formatter: (cell: any, row: any) => actionFormatter(cell, row),
          headerStyle: () => {
            return { width: "80px", whiteSpace: "normal" };
          },
        },
      ];
    
      return (
        <>
          {isLoading && <CommoditesSkeleton />}
          {!isLoading && data?.results && (
            <>
              <BootstrapTable
                keyField="id"
                data={data?.results}
                columns={columns}
                bordered={true}
                condensed={false}
                responsive
                wrapperClasses="table-responsive locataire-table"
                noDataIndication={() => <AlertInfo message="Pas de données" />}
                rowStyle={{ cursor: "pointer" }}
              />
              <CustomPagination
                nbPages={data?.count}
                page={page}
                perPage={perPage}
                onChange={(page, perPage) => {
                  setPerPage(perPage);
                  setPage(page);
                }}
              />
            </>
          )}
        </>
      );
}

export default CommoditesTable

export function DeleteCommodites({ item }: { item: ITypeCommodites }) {
    const [deleteItem, { isSuccess, isError, error }] =
      useDeleteCommoditesMutation();
    const onDelete = useDelete<TypeCommoditesFormData>({
      item,
      deleteItem,
      isSuccess,
      isError,
      error,
      question: "cette commodité?",
      successMessage: "Commodité supprimée",
    });
    return (
      <button
        className="btn btn-action-administratif-proprietaire with-tooltip"
        data-tooltip-content="Supprimer"
        onClick={onDelete}
      >
        <IoMdTrash />
      </button>
    );
  }