/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { ChangeEvent, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  IProprietaire,
  ProprietaireFormData,
} from "../../../../../utils/api/proprietaire/proprietaire.type";
import { useAddOrUpdateProprietaireMutation } from "../../../../../utils/api/proprietaire/proprietaire.api";
import {
  cleannerError,
  useLocationState,
} from "../../../../../utils/Utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGeneratePasswordMutation } from "../../../../../utils/api/user/user.api";
import { Color } from "../../../../../utils/theme";
import { Data } from "../../../../common/GoogleInput";
import { Offer } from "../../../../../utils/api/offer/offer.type";
import { useGetOffersQuery } from "../../../../../utils/api/offer/offer.api";

export function useCrudProprietaire() {
  const item = useLocationState<IProprietaire>(undefined);
  const { user } = useAppSelector((s) => s?.user);
  // console.log("item", item);
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Nom"),
    prenom: yup.string().required().label("Prenom"),
    telephone: yup.string().required().label("Téléphone"),
    email: yup.string().email().required().label("Email"),
    offre: yup.number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .when([], {
        is: () => user?.user_type === 'admin' || user?.user_type === 'superAdmin',
        then: yup.number().required().label("L'offre"),
        otherwise: yup.number().nullable(),
    }),
    adresse: yup.string().label("Adresse").nullable(),
    pays: yup.string().label("Pays").nullable(),
    region: yup.string().label("Région").nullable(),
    // contrat: yup.mixed().label("Mandat").nullable(),
    type_proprietaire: yup.string().required().label("Type").nullable(),
    nom_structure: yup
      .string()
      .when("type_proprietaire", {
        is: "morale",
        then: yup.string().required().label("Nom de la structure").nullable(),
      })
      .nullable(),
    ninea: yup
      .string()
      .when("type_proprietaire", {
        is: "morale",
        then: yup.string().required().label("Ninea").nullable(),
      })
      .nullable(),
    registre: yup
      .string()
      .when("type_proprietaire", {
        is: "morale",
        then: yup.string().required().label("Registre de commerce").nullable(),
      })
      .nullable(),
    contrat: yup
      .mixed()
      .when("type_proprietaire", {
        is: "morale",
        then: yup.mixed().required().label("Mandat de location").nullable(),
      })
      .nullable(),
      date_fin_formatted: yup.string().nullable().label("Date de fin abonnement"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<ProprietaireFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [
    addOrUpdateProprietaire,
    { isLoading, isSuccess, error, isError, data },
  ] = useAddOrUpdateProprietaireMutation();

  const { data: dataOffres = { results: [] } } = useGetOffersQuery({
    limit: 1000,
  });
  const authUser = useAppSelector((s) => s.user.user);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [address, setAddress] = useState("");
  const [proprietaire, setProprietaire] = React.useState<string>();
  const [phone, setPhone] = useState<string>();
  const [type, setType] = useState("");
  const [country, setCountry] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [offres, setOffres] = useState<Offer[]>([]);

  useEffect(() => {
    if (dataOffres?.results?.length) {
      setOffres(
        dataOffres?.results?.filter(
          (item: any) => item?.type === "proprietaire" && item?.gratuit
        )
      );
    }
  }, [data]);

  const handleCountrySelect = (option: Data) => {
    setCountry(option?.country);
    setValue("pays", option?.country);
  };

  const handleStateSelect = (option: Data) => {
    if (option?.region) {
      setState(option?.region);
      setValue("region", option?.region);
    }
  };

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
    if (val?.country) {
      setValue("pays", val?.country);
      setCountry(val?.country);
    }

    if (val?.region) {
      setValue("region", val?.region);
      setState(val?.region);
    }
  };

  const handleChangeType = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue("type_proprietaire", e?.target?.value);
    setType(e?.target?.value);
  };

  useEffect(() => {
    if (isSuccess) {
      // console.log("data", data);
      openModal();
      setProprietaire(data?.prenom + " " + data?.nom);
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Error status ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  function openModal() {
    setIsOpen(true);
  }

  // const onChangeAddress = (val: { rue: string }) => {
  //   setAddress(val?.rue);
  //   setValue("adresse", val?.rue);
  // };

  useEffect(() => {
    if (item?.id) {
      console.log(item?.abonnements[0]?.date_fin_formatted);
      
      let fields: (keyof ProprietaireFormData)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "offre",
        "adresse",
        "code_postal",
        "pays",
        "region",
        "type_proprietaire",
        "ninea",
        "nom_structure",
        "registre",
        "date_fin_formatted",
      ];
      for (let field of fields) {
        if (field !== "date_fin_formatted") {
          setValue(field, item[field]);
        }
      }
      setValue("date_fin_formatted", item?.abonnements[0]?.date_fin_formatted)
      setAddress(item["adresse"]);
      setCountry(item?.pays);
      setState(item?.region);
      if (
        (item?.telephone?.substring(0, 2) === "77" ||
          item?.telephone?.substring(0, 2) === "76" ||
          item?.telephone?.substring(0, 2) === "78" ||
          item?.telephone?.substring(0, 2) === "70" ||
          item?.telephone?.substring(0, 2) === "75") &&
        !item?.telephone?.includes("221")
      ) {
        setPhone("221" + item.telephone);
      } else {
        setPhone(item.telephone);
      }
      setType(item?.type_proprietaire);
      
    }
  }, [item, setValue]);



  const handleChangeGerePar = async (data: any) => {
    const newData: any = {
      ...data,
      gerer_par_moi: !data.gerer_par_moi,
      gerer_par_proprio_agence: data.gerer_par_moi,
    };

    const fd = new FormData();
    for (let key of Object.keys(newData)) {
      if (key === "contrat" && newData[key]) {
        fd.append(key, newData[key]);
      } else if (newData[key] !== undefined && newData[key] !== null) {
        fd.append(key, newData[key]);
      }
    }
    await addOrUpdateProprietaire({ slug: data?.slug, data: fd });
  };

  const onSubmit = async (data: ProprietaireFormData) => {
    if (data?.type_proprietaire === "physique") {
      delete data.registre;
      delete data.ninea;
      delete data.nom_structure;
      delete data.contrat;
    }
    if(data.date_fin === undefined || data.date_fin === null ) {
      delete data.date_fin;
    }
    const newData: any = {
      created_by:
        user.user_type === "admin"
          ? ""
          : user?.agence_id?.slug
          ? user?.agence_id?.id
          : user?.id,
      // est_gerer_par_proprio_agence: true,
      ...data,
    };

    const fd = new FormData();
    for (let key of Object.keys(newData)) {
      if (key === "contrat" && newData[key]) {
        fd.append(key, newData[key]);
        fd.append("offre", newData?.offre);
      } else if (newData[key] !== undefined && newData[key] !== null) {
        fd.append(key, newData[key]);
      }
    }

    await addOrUpdateProprietaire({ slug: item?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    handleChangeGerePar,
    setValue,
    isLoading,
    modalIsOpen,
    setIsOpen,
    proprietaire,
    item,
    offres,
    address,
    onChangeAddress,
    handleCountrySelect,
    handleStateSelect,
    country,
    state,
    setCountry,
    phone,
    setPhone,
    type,
    handleChangeType,
  };
}

export function UseGeneratePassword(
  slug: string
  // modalID: string
) {
  const [getData] = useGeneratePasswordMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir envoyer de nouveaux identifiants à cet utilisateur ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.bleu,
      confirmButtonColor: Color.bleu,
      preConfirm: () => {
        return getData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        // console.log(modalID)
        Swal.fire({
          icon: "success",
          title: `Identifiants envoyés avec succès!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
        // .then(() => {
        // onHide("EnvoieSuppressionNoteModal");
        // });
      }
    });
  };
  return onDelete;
}
