import React, { useState } from "react";
import {
  useGetValdatonGerantsQuery,
  useGetValidationManuelGerantsQuery,
} from "../../../../utils/api/validationGerant/validation.api";
import BootstrapTable from "react-bootstrap-table-next";
import {
  ValidationFormData,
  IAValidation,
} from "../../../../utils/api/validationGerant/validation.type";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import TableSkeleton from "../../../common/TableSkeleton";
import { useAppSelector } from "../../../../redux/hooks";
import { Color } from "../../../../utils/theme";
import {
  useSendPaymentMutation,
  useGetCreateCompteRenduMutation,
  useApprouveDemandeMutation,
} from "../../../../utils/api/proprietaire/proprietaire.api";
import Swal from "sweetalert2";
import { BsQuestionSquare } from "react-icons/bs";

function ValidationManuelTable() {
  const { user } = useAppSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const userSlug = user?.slug;
  const { data, isLoading } = useGetValidationManuelGerantsQuery({
    slug: userSlug,
  });
  console.log(data?.results);

  const transactions = data?.results || [];
  const [sendData] = useApprouveDemandeMutation();

  const onSend = async (slug: string, prenom: string, nom: string) => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir confirmer le paiement manuel de  ${prenom} ${nom}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "oui",
      cancelButtonText: "non",
      showLoaderOnConfirm: true,
      iconColor: Color.bleu,
      confirmButtonColor: Color.bleu,
      preConfirm: () => {
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: "Le paiement est enregistrer avec succès!",
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue lors du transfère.`,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  const actionFormatter = (cell: string, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes gap-2 d-flex">
          <div>
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Confirmer"
              disabled={row?.etat}
              onClick={() =>
                onSend(
                  row?.slug,
                  row?.proprietaire?.prenom,
                  row?.proprietaire?.nom
                )
              }
            >
              <BsQuestionSquare />
            </button>
          </div>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "gerant_name",
      text: "gerants",
      formatter: (cell: number, row: any) =>
        `${row?.gerant?.prenom} ${row?.gerant?.nom}`,
    },
    {
      dataField: "proprietaire_name",
      text: "proprietaire",
      formatter: (cell: number, row: any) =>
        `${row?.proprietaire?.prenom} ${row?.proprietaire?.nom}`,
    },
    {
      dataField: "etat",
      text: "Etat",
      formatter: (cell: boolean) => (cell ? "Validé" : "En attente"),
      style: (cell: boolean) => ({
        color: cell ? "green" : "orange",
      }),
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: IAValidation) =>
        actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={transactions}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
            rowStyle={{ cursor: "pointer" }}
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            perPage={limit}
            onChange={(page, perPage) => {
              setLimit(perPage);
              setPage(page);
            }}
          />
        </>
      )}
    </>
  );
}

export default ValidationManuelTable;
