import React from "react";
import { useStep } from "react-hooks-helper";
import { AiFillPlusSquare } from "react-icons/ai";
import { NavLink, useSearchParams } from "react-router-dom";
import { useStepType } from "../../../../utils/type";
import NewsAdminTable from "../../../tables/Admin/News/NewsAdminTable";
import TagsAdminTable from "../../../tables/Admin/Tags/TagsAdminTable";
import "./NewsAdmin.css";

const steps = [
  { id: "news-lists", Component: NewsAdminTable },
  { id: "tags-lists", Component: TagsAdminTable },
];
const tabs = ["News", "Tags"];

const NewsAdmin = () => {
  const {
    index,
    navigation: { go },
  }: useStepType = useStep({ initialStep: 0, steps });
  const [searchParams, setSearchParams] = useSearchParams();
  const titre = searchParams.get("titre");
  const handleFilterByTitle = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        titre: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="bg-white">
          <div className="kanimmo-dash-locataire-requetes-header">
            <div className="custom-filter-titre-select-container">
              <h4 className="kanimmo-dash-locataire-requetes-header-titre">
                News
              </h4>
            </div>
            {index === 0 ? (
              <div className="container-btn-add">
                <NavLink to="/admin/ajouter-news" className="btn btn-see-more">
                  <AiFillPlusSquare style={{ fontSize: 22 }} />
                  <span className="ps-2">Ajouter news</span>
                </NavLink>
              </div>
            ) : (
              <div className="container-btn-add">
                <button
                  className="btn btn-see-more"
                  data-bs-toggle="modal"
                  data-bs-target="#AddTagModal"
                >
                  <AiFillPlusSquare style={{ fontSize: 22 }} />
                  <span className="ps-2">Ajouter tag</span>
                </button>
              </div>
            )}
          </div>
          <div className="px-4 mt-3">
            <ul className="tabs-container custom-tabs-container ps-3">
              {tabs.map((tab, key) => (
                <li
                  className={`tab-item  ${index === key && "tab-active-item"}`}
                  key={key}
                  onClick={() => go(key)}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="kanimmo-proprietes-container">
          <div className="proprietes-component-container">
            {index === 0 && (
              <>
                <div className="locataire-requete-filtre-container my-3">
                  <form className="row g-3 locataire-requete-filtre-form">
                    <div className="col-auto">
                      <label
                        htmlFor="villa"
                        className="locataire-requete-filtre-label"
                      >
                        Filtrer
                      </label>
                    </div>
                    <div className="col-auto">
                      <input
                        type="text"
                        className="form-control locataire-requete-filtre-input"
                        id="villa"
                        placeholder="Titre"
                        onChange={handleFilterByTitle}
                      />
                    </div>
                  </form>
                </div>
                <div className="content-proprietes locataire-requete-filtre-container mt-5">
                  <div className="row">
                    <NewsAdminTable titre={titre} />
                  </div>
                </div>
              </>
            )}
            {index === 1 && (
              <div className="content-proprietes locataire-requete-filtre-container">
                <div className="row">
                  <TagsAdminTable />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsAdmin;
