import React, { useState } from "react";
import ContratLocataireSkeleton from "./ContratLocataireSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoEyeSharp } from "react-icons/io5";
import { useGetContratByLocataireBySlugQuery } from "../../../../../utils/api/contrat/contrat.api";
import { useAppSelector } from "../../../../../redux/hooks";
import DocumentContratModal from "../../../../modal/locataire/DocumentContratModal";
import { AlertInfo } from "../../../../common/Alert";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { formattedDate, getName } from "../../../../../utils/Utils";
import { CustomPagination } from "../../../../common/CustomPagination";
import { ApiBaseUrl } from "../../../../../utils/http";
import { BsCloudArrowDownFill } from "react-icons/bs";
import { Link } from "react-router-dom";

function ContratLocataireTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const locataire = useAppSelector((s) => s.user.user);
  const { data = { results: [] }, isLoading } =
    useGetContratByLocataireBySlugQuery({
      slug: locataire?.slug,
      page: page,
      limit: perPage,
    });
// console.log("doc", data);
  const dureeFormatter = (cell: any, row: any) => {
    return (
      <>
        <p className="mb-0">
          {row?.duree_par_an && (
            <>
              <span className="pe-2">{row?.duree_par_an} année</span>
            </>
          )}
          {row?.duree_par_mois && (
            <>
              <span className="pe-2">{row?.duree_par_mois} mois</span>
            </>
          )}
          {row?.duree_par_jour && (
            <>
              <span>{row?.duree_par_jour} jours</span>
            </>
          )}
        </p>
      </>
    );
  };
  const documentFormatter = (cell: any, row: any) => {
  // console.log("row.document", row);

    return (
      <div className="document-formatter-container">
        {row?.locataire?.contrat !== "undefined" &&
        row?.locataire?.contrat !== null ? (
          <>
            <a
              href={ApiBaseUrl + row?.locataire?.contrat}
              target="_blank"
              className="btn btn-action-administratif-admin-down"
              download
            >
              <BsCloudArrowDownFill className="document-formatter-icon-download" />
            </a>

            <button
              className="document-formatter-container btn"
              role="button"
              data-bs-toggle="modal"
              data-bs-target={`#DocumentContratModal${row?.slug}`}
            >
              <IoEyeSharp className="document-formatter-icon-print" />
            </button>
          </>
        ) : (
          <span className="document-formatter-pas-quittance">
            Pas de contrat
          </span>
        )}
        <div
          className="modal fade"
          id={`DocumentContratModal${row?.slug}`}
          aria-labelledby="DocumentContratModalLabel"
          aria-hidden="true"
        >
          <DocumentContratModal item={row} />
        </div>
      </div>
    );
  };

  const actionFormatter: any = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <div>
          <div>
            <div className="dropdown">
              <button
                className="btn btn-action-requete-locataire dropdown-toggle"
                type="button"
                id={`dropdownMenuContrat${row?.slug}`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <BsThreeDotsVertical />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby={`dropdownMenuContrat${row?.slug}`}
              >
                <li>
                  <a className="dropdown-item" href="#">
                    Arreter le bail
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Prologer le bail
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const columns = [
    {
      dataField: "id",
      text: "N°",
      style: { textAlign: "left" },
      headerStyle: () => {
        return { width: "50px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "date_debut",
      text: "Date d'ajout",
      style: { textAlign: "left" },
      formatter: (cell: string) => formattedDate(cell),
    },
    {
      dataField: "date_fin",
      text: "Date de fin",
      formatter: (cell: string) => formattedDate(cell),
    },
    {
      dataField: "agence",
      text: "Proprietaire / Agence",
      formatter: (cell: any, row: any) =>
        getName(row?.agence ? row?.agence : row?.proprietaire),
    },
    {
      dataField: "duree",
      text: "Durée",
      formatter: (cell: any, row: any) => dureeFormatter(cell, row),
    },
    {
      dataField: "propriete",
      text: "Proprieté",
      style: { color: "#1AA981" },
      formatter: (cell: IPropriete) => cell?.nom,
    },
    {
      dataField: "",
      text: "Document",
      formatter: (cell: any, row: any) => documentFormatter(cell, row),
    },
    // {
    //   dataField: "actions",
    //   text: "Actions",
    //   style: { textAlign: "center" },
    //   formatter: (cell: any, row: any) => actionFormatter(cell, row),
    //   headerStyle: () => {
    //     return { width: "80px", whiteSpace: "normal" };
    //   },
    // },
  ];

  return (
    <>
      {isLoading && <ContratLocataireSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />

          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export default ContratLocataireTable;
