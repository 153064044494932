/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useProprieteFromLocation } from "../../../../../utils/api/propriete/propriete.api";
import { useStepType } from "../../../../../utils/type";
import StepperHorizontal from "../../../../common/StepperHorizontal";
import UseCrudAppartImmeuble from "../requestPropriete/UseCrudAppartImmeuble";
import useCrudPropriete from "../requestPropriete/UseCrudPropriete";
import DetailsAppartement from "./AjoutAppartementStep/DetailsAppartement";
import GestionAppartement from "./AjoutAppartementStep/GestionAppartement";
import InfoAppartement from "./AjoutAppartementStep/InfoAppartement";
import "../AjoutPropriete/AjoutPropriete.css";
import {
  isProprietaireOwner,
  isProprioJuriste,
  useLocationState,
} from "../../../../../utils/Utils";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { useAppSelector } from "../../../../../redux/hooks";

const steps = [
  {
    id: "info-appartement",
    Component: InfoAppartement,
  },
  {
    id: "details-appartement",
    Component: DetailsAppartement,
  },
  {
    id: "gestion-appartement",
    Component: GestionAppartement,
  },
];

function AjoutAppartement() {
  const { step, navigation, index }: useStepType = useStep({
    initialStep: 0,
    steps,
  });
  const [proprieteItem] = useProprieteFromLocation();
  const itemState = useLocationState<any>(undefined);
  const { user } = useAppSelector((s) => s?.user);
  // console.log("itemState", itemState);

  const {
    register,
    onSubmit,
    errors,
    setValue,
    description,
    pictures,
    mandatPicture,
    handleChangeDescription,
    setPictures,
    handleChangeMandatPicture,
    isLoading,
    address,
    onChangeAddress,
    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    control,
    Controller,
    commoditesList,
    commodites,
    onChangeCommodite,
    unregister,
  } = UseCrudAppartImmeuble(navigation, proprieteItem);

  const { Component } = step;
  const props = {
    navigation,
    register,
    onSubmit,
    errors,
    description,
    pictures,
    mandatPicture,
    handleChangeDescription,
    setPictures,
    handleChangeMandatPicture,
    isLoading,
    setValue,
    propriete: proprieteItem,
    address,
    onChangeAddress,
    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    control,
    Controller,
    commoditesList,
    commodites,
    onChangeCommodite,
    itemState,
    unregister,
    proprieteItem: proprieteItem?.immo,
  };
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("proprieteItem", proprieteItem);
    window.scrollTo(10, 10);
    setValue("step", index + 1);
  }, [index]);

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="kanimmo-dash-locataire-requetes-header-titre d-flex align-items-baseline gap-2">
                  <BiArrowBack
                    className="locataire-btn-arrow-back pe-2"
                    onClick={() => navigate(-1)}
                  />{" "}
                  {itemState?.slug ? "Modifier" : "Ajouter"} un appartement
                  <span className="kanimmo-ajout-propriete-titre-span">
                    {index === 0
                      ? "Infos de la propriété"
                      : index === 1
                      ? "Détails de la propriété"
                      : "Gestion"}
                  </span>
                </h4>
              </div>
              <StepperHorizontal
                index={index}
                tabNum={
                  isProprietaireOwner(user) || isProprioJuriste(user) ? 2 : 3
                }
              />
            </div>
          </div>
        </div>
        <div className="bg-white p-3 filter-and-table-container">
          <div className="stepper-proprietes-form-container">
            <Component {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutAppartement;
