/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Facebook from "../../../../../assets/icons/facebook.png";
import Twitter from "../../../../../assets/icons/twitter.png";
import Linkedin from "../../../../../assets/icons/linkedin.png";
import bed from "../../../../../assets/icons/bed.png";
import bathtub from "../../../../../assets/icons/bathtub.png";
import kitchen from "../../../../../assets/icons/kitchen.png";
import plant from "../../../../../assets/icons/plant.png";
import pool from "../../../../../assets/icons/pool.png";
// import key from "../../../../../assets/icons/key.png";
import carpark from "../../../../../assets/icons/carpark.png";
import officechair from "../../../../../assets/icons/officechair.png";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import "./AgencesPQNGPSB.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  createMarkup,
  formatMontant,
  getAvatar,
  getImage,
  isProprietaireOwner,
  useLocationState,
} from "../../../../../utils/Utils";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";
import moment from "moment";
import { AlertInfo } from "../../../../common/Alert";
import { useFindProprieteBySlugQuery } from "../../../../../utils/api/propriete/propriete.api";
import { FaFileDownload } from "react-icons/fa";
import ContratFileModal from "../../../../modal/agence/ContratFileModal";
import { IconArmchair2, IconFan } from "../../../../common/Button";

function DetailsAppartementPQNGPSB() {
  const { user } = useAppSelector((state) => state.user);
  const itemState = useLocationState<IPropriete>(undefined);

  const navigate = useNavigate();

  const { data: propriete } = useFindProprieteBySlugQuery(itemState?.slug);
  // console.log("détails prop", propriete)

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <div>
            <div
              onClick={() => navigate(-1)}
              className="kanimmo-dash-locataire-requetes-header-titre no-link"
            >
              <BiArrowBack />
              <span className="ps-3">{propriete?.nom}</span>
            </div>
            <span className="kanimmo-detail-agence-adresse pt-2">
              {propriete?.pays}, {propriete?.ville}, {propriete?.adresse}
            </span>
          </div>
          <div>
            <div className="kanimmo-detail-agence-titre-prix-location">
              Prix de location
            </div>
            <div className="kanimmo-detail-agence-prix-location">
              {formatMontant(propriete?.prix)}
            </div>
          </div>
        </div>
        <div className="kanimmo-proprietes-container">
          <div className="proprietaire-component-container">
            <div className="content-detail-proprietaire">
              <div className="row">
                <div className="col-xl-4 col-lg-12 col-md-12">
                  <div className="col-left-detail-proprietaire">
                    <div className="content-title-detail-proprietaire">
                      <p className="title-detail-proprietaire">
                        Information du propriétaire{" "}
                        {/* {userType !== "agence"
                          ? "du propriétaire"
                          : "de l'agence"} */}
                      </p>
                    </div>
                    <div className="text-center pt-3">
                      <div className="content-pp-proprietaire pb-2">
                        <img
                          src={getAvatar(
                            propriete?.proprietaire?.avatar ||
                              propriete?.agence?.avatar
                          )}
                          alt="Avatar"
                          className="pp-proprietaire"
                        />
                      </div>
                      <p className="title-detail-proprietaire">
                        {(propriete?.proprietaire?.prenom ||
                          propriete?.agence?.prenom) +
                          " " +
                          (propriete?.proprietaire?.nom ||
                            propriete?.agence?.prenom)}
                      </p>
                      <p className="title-detail-proprietaire">
                        {propriete?.proprietaire?.adresse ||
                          propriete?.agence?.adresse}
                      </p>
                    </div>
                    <div className="custom-btn-social-media-container my-2">
                      <span className="input-group-text input-group-text-img">
                        <img
                          src={Facebook}
                          alt="social media"
                          className="social-media-contact"
                        />
                      </span>
                      <span className="input-group-text input-group-text-img">
                        <img
                          src={Linkedin}
                          alt="social media"
                          className="social-media-contact"
                        />
                      </span>
                      <span className="input-group-text input-group-text-img">
                        <img
                          src={Twitter}
                          alt="social media"
                          className="social-media-contact"
                        />
                      </span>
                    </div>
                    <div className="content-info-proprietaire">
                      <div className="content-info-prprietaire-item mb-2">
                        <MdOutlineMailOutline />
                        <span className="ps-3">
                          <a
                            className="no-link"
                            href={`mailto:${
                              propriete?.proprietaire?.email ||
                              propriete?.agence?.email
                            }`}
                          >
                            {propriete?.proprietaire?.email ||
                              propriete?.agence?.email}
                          </a>
                        </span>
                      </div>
                      <div className="content-info-prprietaire-item mb-2">
                        <IoCallOutline />
                        <span className="ps-3">
                          <a
                            className="no-link"
                            href={`tel:${
                              propriete?.proprietaire?.telephone ||
                              propriete?.agence?.telephone
                            }`}
                          >
                            {propriete?.proprietaire?.telephone ||
                              propriete?.agence?.telephone}
                          </a>
                        </span>
                      </div>
                    </div>
                    {!!propriete?.mandat && (
                      <div
                        className="content-info-prprietaire-item mb-2 linkable"
                        data-bs-toggle="modal"
                        data-bs-target={`#contratFileModal${propriete?.slug}`}
                      >
                        <FaFileDownload />
                        <span className="ps-3">Mandat de gérance</span>
                      </div>
                    )}
                  </div>
                  {!isProprietaireOwner(user) ||
                    (propriete?.agence_owner === false && (
                      <div className="container-renum my-2 mx-0">
                        <p className="text-renum mb-2">Taux de remunération</p>
                        <p className="text-renum mb-2">-</p>
                        <p className="text-renum mb-0">
                          {propriete?.commission_agence || 0}%{" "}
                        </p>
                      </div>
                    ))}
                </div>
                <div className="col-xl-8 col-lg-12 col-md-12">
                  <div className="col-right-detail-proprietaire mb-3">
                    <div className="content-tabs-detail-propriete">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="custom-img-detail-appart-container">
                            <img
                              src={getImage(propriete?.pictures[0]?.image)}
                              alt="img details appartement"
                              className="custom-img-detail-appart"
                            />
                            <p className="custom-img-detail-appart-legend">
                              Vue de face
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="kanimmo-detail-appart-titre-description-contrainer my-4">
                            <h5 className="kanimmo-detail-appart-titre-description">
                              {propriete?.nom}
                            </h5>
                          </div>
                          <div className="kanimmo-detail-appart-caracteristiques-contrainer">
                            <div className="kanimmo-detail-appart-titre-caracteristique mb-3">
                              Caractéristiques
                            </div>
                            <div className="kanimmo-detail-appart-caracteristiques-image-container mb-3">
                              {propriete?.nbre_chambres &&
                              propriete?.nbre_chambres > 0 ? (
                                <CaracteristiqueCard
                                  image={bed}
                                  title={
                                    propriete?.nbre_chambres > 1
                                      ? "Chambres"
                                      : "Chambre"
                                  }
                                  number={propriete?.nbre_chambres}
                                />
                              ) : null}

                              {!!propriete?.nbre_salon &&
                                propriete?.nbre_salon > 0 && (
                                  <CardIcon
                                    Image={IconArmchair2}
                                    title={
                                      propriete?.nbre_salon > 1
                                        ? "Salons"
                                        : "Salon"
                                    }
                                    number={propriete?.nbre_salon}
                                  />
                                )}
                              {!!propriete?.nbre_climatiseurs &&
                                propriete?.nbre_climatiseurs > 0 && (
                                  <CardIcon
                                    Image={IconFan}
                                    title={
                                      propriete?.nbre_climatiseurs > 1
                                        ? "Climatiseurs"
                                        : "Climatiseur"
                                    }
                                    number={propriete?.nbre_climatiseurs}
                                  />
                                )}
                              {propriete?.nbre_salle_de_bains &&
                              propriete?.nbre_salle_de_bains > 0 ? (
                                <CaracteristiqueCard
                                  image={bathtub}
                                  // title="Salle de bain"
                                  title={
                                    propriete?.nbre_salle_de_bains > 1
                                      ? "Salles de bain"
                                      : "Salle de bain"
                                  }
                                  number={propriete?.nbre_salle_de_bains}
                                />
                              ) : null}
                              {propriete?.nbre_cuisines &&
                              propriete?.nbre_cuisines > 0 ? (
                                <CaracteristiqueCard
                                  image={kitchen}
                                  title="Cuisine"
                                  number={propriete?.nbre_cuisines}
                                />
                              ) : null}
                              {propriete?.commodites?.map((itm: any) => (
                                <CaracteristiqueCard
                                  image={
                                    itm?.nom === "Parking"
                                      ? carpark
                                      : itm?.nom === "Piscine"
                                      ? pool
                                      : itm?.nom === "Jardin"
                                      ? plant
                                      : itm?.nom === "Entièrement meublé"
                                      ? officechair
                                      : officechair
                                  }
                                  title={itm?.nom}
                                  number={1}
                                />
                              ))}
                            </div>
                          </div>
                          {!!propriete?.charges?.length && (
                            <div className="kanimmo-detail-appart-caracteristiques-contrainer">
                              <div className="kanimmo-detail-appart-titre-caracteristique mb-3">
                                Charges complémentaires déduites du loyer
                              </div>
                              <div className="kanimmo-detail-appart-caracteristiques-image-container mb-3">
                                {propriete?.charges?.map((item, i) => (
                                  <div
                                    className="kanimmo-detail-appart-caracteristiques-image-item"
                                    key={i}
                                  >
                                    {/* <img
                                    src={image}
                                    alt="img illustration"
                                    className="kanimmo-detail-appart-caracteristiques-image"
                                  /> */}
                                    <p className="kanimmo-detail-appart-caracteristiques-titre-image">
                                      {item?.libelle}{" "}
                                      {item?.type === "pourcentage"
                                        ? item?.montant + "%"
                                        : formatMontant(item?.montant)}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          <div className="kanimmo-detail-appart-caracteristiques-contrainer">
                            <div className="kanimmo-detail-appart-titre-caracteristique mb-3">
                              Description
                            </div>
                            <div
                              className="kanimmo-detail-appart-caracteristiques-description-container mb-3"
                              dangerouslySetInnerHTML={createMarkup(
                                propriete?.description
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-right-detail-proprietaire mb-3">
                    <div className="row">
                      <div className="col-md-12">
                        <Carousel
                          showThumbs={false}
                          // showIndicators={false}
                          autoPlay
                          // interval={5000}
                          infiniteLoop
                        >
                          {propriete?.pictures?.map((item: any) => (
                            <div key={item.name}>
                              <img
                                src={getImage(item?.image)}
                                alt="img appartement"
                                className="custom-img-appart-details-appart"
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>
                      {propriete?.url_youtube && (
                        <div className="col-md-12">
                          <div className="mt-4 mb-3">
                            <a
                              href={propriete?.url_youtube}
                              target="_blank"
                              className="kanimmo-custom-url-link-youtube"
                            >
                              Lien vers la vidéo youtube
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-right-detail-proprietaire mb-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="kanimmo-detail-appart-titre-historique-locataire mb-3">
                          Historique des locataires
                        </div>
                        {propriete?.historique?.length > 0 ? (
                          propriete?.historique?.map((item: ILocataire) => (
                            <div
                              className="historie-paie-tab mb-3"
                              key={item?.id}
                            >
                              <div className="d-flex flex-column justify-content-between">
                                <div className="historie-paie-tab-mensulite mb-2">
                                  {item?.prenom + " " + item?.nom}
                                </div>
                                <div className="historie-paie-tab-date">
                                  {moment(item?.date_debut).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  -{" "}
                                  {moment(item?.date_fin).format("DD/MM/YYYY")}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <AlertInfo message="Pas d'historique disponible" />
                        )}
                      </div>
                      {!isProprietaireOwner(user) && (
                        <div className="col-md-12 mt-4">
                          <div className="kanimmo-detail-appart-titre-historique-locataire mb-3">
                            Taux de rémunération agence
                          </div>
                          <div className="historie-paie-tab mb-3">
                            <div className="d-flex flex-column justify-content-between">
                              <div className="historie-paie-tab-mensulite mb-2">
                                {propriete?.commission_agence
                                  ? propriete?.commission_agence + "%"
                                  : "0%"}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id={`contratFileModal${propriete?.slug}`}
                  aria-labelledby="contratFileModalLabel"
                  aria-hidden="true"
                >
                  <ContratFileModal item={propriete} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsAppartementPQNGPSB;

function CaracteristiqueCard({
  image,
  title,
  number,
}: {
  image: string;
  title: string;
  number: number;
}) {
  return (
    <div className="kanimmo-detail-appart-caracteristiques-image-item">
      <img
        src={image}
        alt="img illustration"
        className="kanimmo-detail-appart-caracteristiques-image"
      />
      <p className="kanimmo-detail-appart-caracteristiques-titre-image">
        {number} {title}
      </p>
    </div>
  );
}

const CardIcon = ({
  Image,
  title,
  number,
}: {
  Image: any;
  title: string;
  number: number;
}) => {
  return (
    <div className="kanimmo-detail-appart-caracteristiques-image-item">
      <Image className="kanimmo-detail-appart-caracteristiques-image app-svg-img" />
      <p className="kanimmo-detail-appart-caracteristiques-titre-image">
        {number} {title}
      </p>
    </div>
  );
};
