import React from "react";
// import {
//   CitySelector,
//   CountrySelector,
//   StateSelector,
// } from "volkeno-react-country-state-city";
import { ProprieteStepProps } from "../../../../../../../utils/api/propriete/propriete.type";
import { BtnSubmit, GoBackButton } from "../../../../../../common/Button";
import { FormError } from "../../../../../../common/CustomInputCheckbox";
import ErrorMessage from "../../../../../../common/ErrorMessage";
import GoogleInput from "../../../../../../common/GoogleInput";

function BasicInformation({
  register,
  errors,
  isLoading,
  address,
  onChangeAddress,
  handleCountrySelect,
  handleStateSelect,
  handleCitySelect,
  country,
  state,
  city,
  propriete,
}: ProprieteStepProps) {
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="no"
                        className="form-label form-label-modal-custom"
                      >
                        Numéro{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-modal-custom"
                        id="no"
                        placeholder="Numéro"
                        {...register("numero")}
                      />
                      {errors?.numero && (
                        <ErrorMessage message={errors?.numero?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="nom"
                        className="form-label form-label-modal-custom"
                      >
                        Titre de la propriété{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Titre de la propriété"
                        className="form-control form-control-modal-custom"
                        id="nom"
                        {...register("nom")}
                      />
                      {errors?.nom && (
                        <ErrorMessage message={errors?.nom?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="adresse"
                        className="form-label form-label-modal-custom"
                      >
                        Adresse{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      {/* <input
                        type="text"
                        placeholder="Adresse"
                        className="form-control form-control-modal-custom"
                        id="adresse"
                        {...register("adresse")}
                      />
                      {errors?.adresse && (
                        <ErrorMessage message={errors?.adresse?.message} />
                      )} */}
                      <GoogleInput
                        className="form-control form-control-modal-custom"
                        value={address}
                        onChange={onChangeAddress}
                      />
                      {errors?.adresse && <FormError error={errors?.adresse} />}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="rue"
                        className="form-label form-label-modal-custom"
                      >
                        Rue{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Rue"
                        className="form-control form-control-modal-custom"
                        id="rue"
                        {...register("rue")}
                      />
                      {errors?.rue && (
                        <ErrorMessage message={errors?.rue?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="pays"
                      className="form-label form-label-modal-custom"
                    >
                      Pays{" "}
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom"
                      placeholder="Pays"
                      value={country}
                      types={["country"]}
                      onChange={handleCountrySelect}
                    />
                    {/* <CountrySelector
                      onChange={handleCountrySelect}
                      name="pays"
                      placeholder={propriete?.pays || "Pays"}
                      value={country}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                    {errors?.pays && (
                      <FormError error={errors?.pays?.message} />
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="region"
                      className="form-label form-label-modal-custom"
                    >
                      Région{" "}
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom"
                      placeholder="Région"
                      value={state}
                      types={[
                        "administrative_area_level_1",
                        "administrative_area_level_2",
                      ]}
                      onChange={handleStateSelect}
                    />
                    {/* <StateSelector
                      country={country}
                      name="region"
                      value={state}
                      placeholder={
                        propriete?.region ? propriete?.region : "Region"
                      }
                      countryPlaceholder="Region"
                      onChange={handleStateSelect}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                    {errors?.region && (
                      <FormError error={errors?.region?.message} />
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="ville"
                      className="form-label form-label-modal-custom"
                    >
                      Ville
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom"
                      placeholder="Ville"
                      value={city}
                      types={["locality", "administrative_area_level_3"]}
                      onChange={handleCitySelect}
                    />
                    {/* <CitySelector
                      state={state}
                      name="ville"
                      value={city}
                      placeholder="Ville"
                      statePlaceholder={
                        propriete?.ville ? propriete?.ville : "Ville"
                      }
                      onChange={handleCitySelect}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                    {errors?.ville && (
                      <FormError error={errors?.ville?.message} />
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="titre"
                        className="form-label form-label-modal-custom"
                      >
                        Code postale
                      </label>
                      <input
                        type="text"
                        placeholder="Code postale"
                        className="form-control form-control-modal-custom"
                        id="titre"
                        {...register("code_postale")}
                      />
                      {errors?.code_postale && (
                        <ErrorMessage message={errors?.code_postale?.message} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="container-btn-modal row my-4">
                  <div className="col-md-4 offset-md-8">
                    <div className="d-flex gap-3">
                      <GoBackButton label="Annuler" />
                      <BtnSubmit label="SUIVANT" isLoading={isLoading} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicInformation;
