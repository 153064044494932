import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { OfferProps, OfferSkeleton } from "../Offres";
import { createMarkup, formatAmount } from "../../../../../utils/Utils";
import { NavLink } from "react-router-dom";
import { AlertInfo } from "../../../../common/Alert";

function AgenceOffreStep({ data, isLoading }: OfferProps) {
  return (
    <div className="offre-step-component">
      <div className="content-offre-step">
        <div className="row row-step-offre">
          {!!isLoading && <OfferSkeleton />}
          {!isLoading &&
            (data?.length ? (
              [...data]
                ?.sort((a, b) => a?.min_numb - b?.min_numb)
                ?.map((offer, i) => (
                  <div
                    className="col-md-3 col-step-offre mb-3 d-flex"
                    key={offer.id}
                  >
                    <div
                      className={`content-col-step-offre content-col-step-offre${
                        i + 1
                      }`}
                    >
                      <div className="content-offer">
                        <h4
                          className={`text-title-card-offre-step pb-3 text-title-card-offre-step${
                            i + 1
                          }`}
                        >
                          {" "}
                          {offer?.max_numb
                            ? offer?.min_numb + "-" + offer?.max_numb
                            : "Illimité"}{" "}
                        </h4>
                        <div className="container-btn-offre-price">
                          <span
                            className={`content-btn-price-offre-step content-btn-price-offre-step${
                              i + 1
                            }`}
                          >
                            {formatAmount(offer?.montant)}/
                            {offer?.max_numb ? "Mois & Locataire" : "Mois"}
                          </span>
                        </div>
                        <div
                          className="content-describe-card-offre-step mt-5"
                          dangerouslySetInnerHTML={createMarkup(
                            offer?.description
                          )}
                        />
                      </div>
                      <div className="content-btn-register-offre mt-5">
                        <NavLink
                          className="btn btn-register-offre"
                          to={"/inscription-agence"}
                          state={offer}
                        >
                          S’INSCRIRE
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <AlertInfo message="Aucune donnée trouvée" />
            ))}
        </div>
      </div>
    </div>
  );
}

export default AgenceOffreStep;

