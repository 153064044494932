import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Swal from "sweetalert2";
import { cleannerError, closeModal } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { ISubscriber, SubscriberFormaData } from "../../../../../utils/api/subscriber/subscriber.type";
import { useUpdateSubscriberMutation } from "../../../../../utils/api/subscriber/subscriber.api";
import { ChangeEvent, useEffect, useState } from "react";
import { format } from 'date-fns';





function UseAddSubscriber(modalId: string, subscriber?: ISubscriber) {
    const validationSchema = yup.object().shape({
        date_fin: yup
          .date() 
          .required("La date est obligatoire") 
      });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
  } = useForm<ISubscriber>({
    resolver: yupResolver(validationSchema),
  });

  const [sendData, { isLoading, isSuccess, isError, error }] =
  useUpdateSubscriberMutation();




console.log("subscriber not offre", subscriber)
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: 'Abonnement modifier avec succées',
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        closeModal(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  
  
  
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: SubscriberFormaData) => {
      await sendData(data);
  };
  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    setValue
  };
}

export default UseAddSubscriber;