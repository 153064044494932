import React from "react";
import { IEtatLieu } from "../../../utils/api/etatlieu/etatlieu.type";
import { ApiBaseUrl } from "../../../utils/http";
import { get_url_extension } from "../../../utils/Utils";
import { GoogleDocs } from "../agence/DocumentLitigeModal";

const DocumentVueModal = ({ item }: { item: IEtatLieu }) => {
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            {get_url_extension(item?.document) === "pdf" ? (
              <iframe
                src={ApiBaseUrl + item?.document}
                frameBorder="0"
                width={`100%`}
                height={800}
                allowFullScreen
                seamless
                title="doc"
              ></iframe>
            ) : (
              // <iframe
              //   src={`https://docs.google.com/gview?url=${
              //     ApiBaseUrl + item?.document
              //   }&embedded=true`}
              //   frameBorder="0"
              //   width={`100%`}
              //   height={800}
              //   title="doc"
              // ></iframe>
              <GoogleDocs file={ApiBaseUrl + item?.document} height={"800px"} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentVueModal;
