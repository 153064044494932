import React from "react";
import { useSearchParams } from "react-router-dom";
import "./AgencesPQNGPSB.css";
import LIsteAgencesPQNGPSB from "./LIsteAgencesPQNGPSB";

function AgencesPQNGPSB() {
  const [searchParams, setSearchParams] = useSearchParams();
  const word = searchParams.get("word");
  const location = searchParams.get("location");
  const handleFilterByTitre = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        word: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  const handleFilterByLocation = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        location: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="bg-white p-3 filter-and-table-container custom-kanimmo-dash-locataire-requetes-header mt-5">
          <div className="locataire-requete-filtre-container my-4">
            {/* <form className="row g-3 locataire-requete-filtre-form">
              <div className="col-auto">
                <label
                  htmlFor="filtreLogement"
                  className="locataire-requete-filtre-label"
                >
                  Filtrer
                </label>
              </div>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control locataire-requete-filtre-input"
                  id="filtreLogement"
                  placeholder="Entrer un nom"
                  onChange={handleFilterByTitre}
                />
              </div>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control locataire-requete-filtre-input"
                  id="filtreLogement"
                  placeholder="Localité"
                  onChange={handleFilterByLocation}
                />
              </div>
            </form> */}
          </div>
          <div className="p-0 p-md-4">
            <LIsteAgencesPQNGPSB word={word} location={location} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgencesPQNGPSB;
