import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../../utils/theme";
import { cleannerError, useLocationState } from "../../../../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import {
  IPersonnel,
  PersonnelFormData,
} from "../../../../../../utils/api/agence/agence.type";
import { useAddOrUpdatePersonnelMutation } from "../../../../../../utils/api/agence/agence.api";
import { Authorisations } from "../../../../../../utils/constant";

function UseCrudPersonnel() {
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Nom"),
    prenom: yup.string().required().label("Prenom"),
    telephone: yup.string().required().label("Téléphone"),
    email: yup.string().email().required().label("Email"),
    user_type: yup.string().required().label("Type d'utilisateur"),

    dashboard: yup.boolean(),
    options: yup.boolean(),
    administratif: yup.boolean(),
    message: yup.boolean(),
    client: yup.boolean(),
    new: yup.boolean(),
    recette: yup.boolean(),
    faq: yup.boolean(),
    lead: yup.boolean(),
    problemes: yup.boolean(),
    // agence_id: yup
    //   .number()
    //   .required()
    //   .label("Agence")
    //   .typeError("Agence est un champ obligatoire"),
  });
  const navigate = useNavigate();
  const user = useLocationState<IPersonnel>(undefined);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<PersonnelFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [addOrUpdateUser, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdatePersonnelMutation();

  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState<string>();

  const onChangeAddress = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !user
          ? "Collaborateur ajouté avec succès"
          : "Les informations ont été modifié avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate("/admin/options");
        if (user) {
          reset();
        }
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (user?.id) {
      let fields: (keyof IPersonnel)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "code_postal",
        "agence_id",
        "user_type",
        "dashboard",
        "options",
        "administratif",
        "message",
        "client",
        "new",
        "recette",
        "faq",
        "lead",
        "probleme",
        "type_requete",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
      }
      setValue("adresse", user?.adresse);
      setAddress(user?.adresse);
      setValue("agence_id", user?.agence_id?.id);
      setValue("user_type", user?.user_type);
      if (
        (user?.telephone?.substring(0, 2) === "77" ||
          user?.telephone?.substring(0, 2) === "76" ||
          user?.telephone?.substring(0, 2) === "78" ||
          user?.telephone?.substring(0, 2) === "70" ||
          user?.telephone?.substring(0, 2) === "75") &&
        !user?.telephone?.includes("221")
      ) {
        setPhone("221" + user.telephone);
      } else {
        setPhone(user.telephone);
      }
    }
  }, [user]);

  const onSubmit = (data: PersonnelFormData) => {
  // console.log("data", data);
    addOrUpdateUser({ slug: user?.slug, data: data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    user,
    address,
    onChangeAddress,
    phone,
    setPhone,
  };
}

export default UseCrudPersonnel;
