import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import eye from "../../../../assets/icons/eye.png";
import chaticon from "../../../../assets/icons/chaticon.png";
import { INews } from "../../../../utils/api/new/news.type";
import { getImage, useLocationState } from "../../../../utils/Utils";
import { CommentsNew } from "../../../user/NewsList/NewsItem/NewsComments";

function DetailsNewsAdmin() {
  const navigate = useNavigate();
  const item: INews = useLocationState<INews>(undefined);
  return (
    <div className="landing-page-container">
      <div className="container-fluid  px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            <BiArrowBack onClick={() => navigate(-1)} />
            Détails
          </h4>
        </div>
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="kanimmo-dash-locataire-param-main-page-container ps-0 py-3">
            <div className="dashboard-admin-component">
              <div className="row container-dashboard-page">
                <div className="col-md-12 pb-3 ">
                  <div className="col-left-dashboard col-left-dashboard-responsive custom-col-left-dashboard-responsive mb-3 p-1">
                    <div className="row px-md-3">
                      <div className="col-md-4 mb-3 dis-flex px-md-2">
                        <div className="item-stat-dashboad-view">
                          <div className="content-stat-dashboard-view">
                            <div className="content-item-icon-stat-dashboard-view">
                              <img
                                src={eye}
                                alt="icon"
                                className="icon-item-stat-dashboad-view"
                              />
                            </div>
                            <div className="content-text-item-stat-dashboad-view ms-3">
                              <div className="content-item-stat-dashboad-view mt-2">
                                <p className="titre-item-stat-dashboad-view mb-1">
                                  Total des vues
                                </p>
                                <p className="users-item-stat-dashboad-view mb-1">
                                  Cette semaine
                                </p>
                              </div>
                              <div className="icon-item-stat-dashboad-view">
                                <p className="chiffre-item-stat-dashboad-view mb-1">
                                  123
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mb-3 dis-flex px-md-2">
                        <div className="item-stat-dashboad-view">
                          <div className="content-stat-dashboard-view">
                            <div className="content-item-icon-stat-dashboard-view">
                              <img
                                src={chaticon}
                                alt="icon"
                                className="icon-item-stat-dashboad-view"
                              />
                            </div>
                            <div className="content-text-item-stat-dashboad-view ms-3">
                              <div className="content-item-stat-dashboad-view mt-2">
                                <p className="titre-item-stat-dashboad-view mb-1">
                                  Total des commentaires
                                </p>
                                <p className="users-item-stat-dashboad-view mb-1">
                                  Cette semaine
                                </p>
                              </div>
                              <div className="icon-item-stat-dashboad-view">
                                <p className="chiffre-item-stat-dashboad-view mb-1">
                                  {item?.comments?.length > 0
                                    ? item?.comments?.length
                                    : 0}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="landing-page-component">
                      <section className="page-content-body-container container">
                        <div className="partnership-item-page-content">
                          <div className="partnership-item-row row">
                            <div className="partnership-item-col col-md-12">
                              <div className="custom-container-col-left-news-item">
                                <div className="partnership-item-body mb-4">
                                  <div className="container-img-banner-news-item mb-3">
                                    <img
                                      src={getImage(item?.couverture)}
                                      alt="Banner"
                                      className="img-banner-news-item"
                                    />
                                  </div>
                                  <div className="partnership-item-description-container">
                                    <p
                                      className="comment-text"
                                      dangerouslySetInnerHTML={{
                                        __html: item.content || "",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <CommentsNew item={item} />
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsNewsAdmin;
