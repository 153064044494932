import React from "react";
import "./ComptabiliteAgence.css";
import Key from "../../../../assets/icons/key.png";
import Users from "../../../../assets/icons/users.png";
import Paper from "../../../../assets/icons/paper.png";
import DepenseComptabiliteTable from "../../../tables/agence/DepenseComptabiliteTable/DepenseComptabiliteTable";
import EntreeComptabiliteTable from "../../../tables/agence/EntreeComptabiliteTable/EntreeComptabiliteTable";
import { useAppSelector } from "../../../../redux/hooks";
import {
  useGetAgencesQuery,
  useGetComptabiliteByAgenceBySlugQuery,
} from "../../../../utils/api/agence/agence.api";
import {
  formatMontant,
  isAgence,
  isAgenceAgent,
  isAgenceJuriste,
  isAgenceMarketeur,
} from "../../../../utils/Utils";
import {
  MdCategory,
} from "react-icons/md";
import { BiExport } from "react-icons/bi";
import { ApiBaseUrl } from "../../../../utils/http";
import { AiFillPlusSquare } from "react-icons/ai";
import AddEntreeModal from "./AddEntreeModal";
import AddSortieModal from "./AddSortieModal";
import { NavLink } from "react-router-dom";
// import MyModal from "../../../modal/modalComponent/Modal";
// import { showModal } from "../../../../redux/store";
// import { useDispatch } from "react-redux";

function ComptabiliteAgence() {
  const agence = useAppSelector((s) => s.user.user);

  const { user } = useAppSelector((s) => s?.user);
  const { data: ListeAgence } = useGetAgencesQuery({});
  const AgenceSlug = ListeAgence?.results
    ?.filter((item) => item?.id === user?.agence_id)
    ?.map((itm) => itm?.slug);
  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));

  const { data: compta, isLoading } = useGetComptabiliteByAgenceBySlugQuery({
    slug: user?.agence_id?.slug ? user?.agence_id?.slug : user?.slug,
  });

  // const dispatch = useDispatch(); // Utilise useDispatch pour dispatcher des actions
  // const handleShow = () => {
  //   dispatch(showModal());
  // };
  // ---Test Change modal---

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-2">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Comptabilité 
          </h4>

          <NavLink
            className={`btn btn-see-more `}
            to="/agence/categorie"
            data-tooltip-content="Catégorie E/S"
          >
            <MdCategory />
            <span className="ps-2">Listes des Catégories</span>
          </NavLink>
        </div>
        <div className="kanimmo-comptabilite-container">
          <div className="comptabilite-component-container">
            <div className="row gy-3 px-md-">
              <div className="col-md-4 mb- dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Key}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Recouvrements
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          {/* Cette semaine */}
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {isLoading ? (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                              style={{ color: "#1F3CAD" }}
                            ></div>
                          ) : (
                            formatMontant(compta?.total_entrees_week)
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb- dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Users}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Total des dépenses
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          {/* Cette semaine */}
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {isLoading ? (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                              style={{ color: "#FCAE3B" }}
                            ></div>
                          ) : (
                            formatMontant(compta?.total_depenses_week)
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb- dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Paper}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Total des commissions perçues
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          {/* Cette semaine */}
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {isLoading ? (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                              style={{ color: "#1AA981" }}
                            ></div>
                          ) : (
                            formatMontant(compta?.total_revenus_week)
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-table-comptabilite">
            <div className="row">
              <div className="col-lg-6 col-md-12 d-flex flex-column mb-3">
                <div className="col-left-comptabilite">
                  <div className="content-title-page-comptabilite p-3">
                    <span className="title-page-comptabilite">Dépenses</span>
                    {!!compta?.depenses?.length && (
                      <div className="btn-export-ontainer">
                        <button
                          className="btn btn-see-more dropdown-toggle btn"
                          type="button"
                          onClick={(e) => {
                            e?.preventDefault();

                            window
                              ?.open(
                                `${ApiBaseUrl}/api/agence/${user?.slug}/export_depense`,
                                "_blank"
                              )
                              ?.focus();
                          }}
                        >
                          <div className="user-acount-dashboard-header-display g-2">
                            <BiExport style={{ fontSize: 22 }} />
                            <span className="ps-2">Exporter les dépenses</span>
                          </div>
                        </button>
                      </div>
                    )}

                    <button
                      className="btn btn-see-more"
                      title="Ajouter une entrée"
                      data-bs-toggle="modal"
                      data-bs-target="#AddSortieModal"
                    >
                      <AiFillPlusSquare style={{ fontSize: 22 }} />
                      <span className="ps-2">Ajouter</span>
                    </button>
                    {/* <button onClick={handleShow}>Launch demo modal</button> ---Test Change modal--- */}
                  </div>
                  <div className="content-table-depense-comptabilte my-3">
                    <DepenseComptabiliteTable />
                  </div>
                </div>
                <br />
                {/* <MyModal handleShow={handleShow} />  ---Test Change modal--- */}
                <div className="container-balance-table">
                  <div className="content-text-balance-libelle">
                    <p className="text-balance-libelle mb-0">
                      Balance des dépences:
                    </p>
                  </div>
                  <div className="content-text-balance-value">
                    <p className="text-balance-value mb-0">
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#1F3CAD" }}
                        ></span>
                      ) : (
                        formatMontant(compta?.total_sorties)
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 d-flex flex-column mb-3">
                <div className="col-right-comptabilite">
                  <div className="content-title-page-comptabilite p-3">
                    <span className="title-page-comptabilite">Revenus</span>
                    {!!compta?.entrees?.length && (
                      <div className="btn-export-ontainer">
                        <button
                          className="btn btn-see-more dropdown-toggle btn"
                          type="button"
                          onClick={(e) => {
                            e?.preventDefault();

                            window
                              ?.open(
                                `${ApiBaseUrl}/api/agence/${user?.slug}/export_recette`,
                                "_blank"
                              )
                              ?.focus();
                          }}
                        >
                          <div className="user-acount-dashboard-header-display g-2">
                            <BiExport style={{ fontSize: 22 }} />
                            <span className="ps-2">Exporter les revenus</span>
                          </div>
                        </button>
                      </div>
                    )}

                    <button
                      className="btn btn-see-more"
                      title="Ajouter une entrée"
                      data-bs-toggle="modal"
                      data-bs-target="#AddEntreeModal"
                    >
                      <AiFillPlusSquare style={{ fontSize: 22 }} />
                      <span className="ps-2">Ajouter</span>
                    </button>
                  </div>
                  <div className="content-table-depense-comptabilte my-3">
                    <EntreeComptabiliteTable />
                  </div>
                </div>
                <div className="container-balance-table">
                  <div className="content-text-balance-libelle">
                    <p className="text-balance-libelle mb-0">
                      Balance des revenus:
                    </p>
                  </div>
                  <div className="content-text-balance-value">
                    <p className="text-balance-value mb-0">
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#1F3CAD" }}
                        ></span>
                      ) : (
                        formatMontant(compta?.total_entrees)
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="container-balance-table">
                  <div className="content-text-balance-libelle">
                    <p className="text-balance-libelle mb-0">Balance Total:</p>
                  </div>
                  <div className="content-text-balance-value">
                    <p className="text-balance-value mb-0">
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#1F3CAD" }}
                        ></span>
                      ) : (
                        formatMontant(compta?.total_revenus)
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddEntreeModal"
        aria-labelledby="AddEntreeModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <AddEntreeModal modalId="AddEntreeModal" />
      </div>
      <div
        className="modal fade"
        id="AddSortieModal"
        aria-labelledby="AddSortieModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <AddSortieModal modalId="AddSortieModal" />
      </div>
    </div>
  );
}

export default ComptabiliteAgence;
