import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  cleannerError,
  isAgence,
  isAgenceAgent,
  isAgenceJuriste,
  onHide,
} from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import {
  ILitiges,
  LitigesFormData,
} from "../../../../../utils/api/litiges/litiges.type";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetLocataireByAgenceBySlugQuery } from "../../../../../utils/api/locataire/locataire.api";
import {
  useAddOrUpdateLitigeMutation,
  useDeleteLitigeMutation,
} from "../../../../../utils/api/litiges/litiges.api";
import { useGetAgencesQuery } from "../../../../../utils/api/agence/agence.api";

function useCrudLitige(item?: ILitiges) {
  const validationSchema = yup.object().shape({
    locataire: yup
      .number()
      .required()
      .label("Locataire")
      .typeError("Locataire est un champ obligatoire"),
    titre: yup
      .string()
      .required()
      .label("Titre")
      .typeError("Titre est un champ obligatoire"),
    type_litige: yup.string().label("Type litige").nullable(),
    description: yup.string().label("La note"),
    // statut: yup.string().label("Statut"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm<LitigesFormData>({
    resolver: yupResolver(validationSchema),
  });

  // console.log("item item item", item)

  const { user } = useAppSelector((s) => s?.user);
  const [option, setOption] = useState<any>();

  const { data } = useGetLocataireByAgenceBySlugQuery({
    slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
    limit: 10000,
  });

  const [addOrUpdateLitige, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateLitigeMutation();

  const [description, setDescription] = useState<string>("");

  const handleChangeDescription = useCallback((value: string) => {
    setDescription(value);
    setValue("description", value);
  }, []);

  const handleChangeLocataire = (e: any) => {
    setValue("locataire", e?.value);
    setOption(e);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Contentieux ${item ? "modifiée" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        reset();
        onHide(item ? "ModifierLitigesModal" : "AjouterLitigesModal");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      setValue("slug", item?.slug);
      setValue("locataire", item?.locataire?.id);
      setValue("statut", item?.statut);
      isAgenceJuriste(user) && setValue("type_litige", item?.type_litige);
      setValue("date", item?.date);
      setValue("titre", item?.titre);
      setDescription(item?.description);
    }
  }, [item]);

  const onSubmit = (data: LitigesFormData) => {
    // console.log("data submitted", data);
    if (!item) {
      data["agence"] = user?.agence_id?.slug ? user?.agence_id?.id : user?.id;
    }
    data["date"] = new Date();
    // console.log("data data", data);
    addOrUpdateLitige({ slug: item?.slug, data: data });
  };

  const handleReset = () => {
    setOption("");
    reset();
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    locataires: data?.results,
    handleChangeDescription,
    description,
    user,
    control,
    Controller,
    handleChangeLocataire,
    option,
    handleReset,
  };
}

export default useCrudLitige;

export function UseDeleteLitige(item: ILitiges) {
  const [deleteData] = useDeleteLitigeMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette contentieux ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      // console.log(result, "deleted");
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Contentieux supprimée avec succès!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
