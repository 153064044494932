export type ITypeRequete = {
	id: number;
	slug: string;
	titre: string;
	description: string;
    detail_type_requete: IDetailTypeRequete[];
};

export type TypeRequeteFormData = Partial<{
	titre: string;
	description: string;
	slug?: string;
}>;

export interface IDetailTypeRequete {
	id: number;
	slug: string;
	created_at: string;
	nom: string;
	type_requete: number;
	charge: string;
	active: boolean;
}

export type DetailTypeRequeteItemData = {
	nom: IDetailTypeRequete["nom"];
	type_requete?: IDetailTypeRequete["type_requete"];
	charge: IDetailTypeRequete["charge"];
	active: IDetailTypeRequete["active"];
};

export type EditDetailTypeRequeteItemData = {
	slug: IDetailTypeRequete["slug"];
	nom: IDetailTypeRequete["nom"];
	charge: IDetailTypeRequete["charge"]
	active: IDetailTypeRequete["active"]
};

export type DetailTypeRequeteFormValues = {
	detail_type_requetes: {
		nom: IDetailTypeRequete["nom"];
		type_requete?: IDetailTypeRequete["type_requete"];
		charge: IDetailTypeRequete["charge"];
		active: IDetailTypeRequete["active"]
	}[];
};

export enum ChargeRequeteType {
    agence = 'agence',
    locataire = 'locataire',
    proprietaire = 'proprietaire',
    admin = 'admin',
}