import React, { useState } from "react";
import EmptyHeader from "../../../landingPage/EmptyHeader";
import FrontFooter from "../../../landingPage/FrontFooter/FrontFooter";
import "./Offres.css";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import AgenceOffreStep from "./OffreStep/AgenceOffreStep";
import ProprietaireOffreStep from "./OffreStep/ProprietaireOffreStep";
import { useGetOffersByVisitorsQuery } from "../../../../utils/api/offer/offer.api";
import { Offer } from "../../../../utils/api/offer/offer.type";
import Skeleton from "react-loading-skeleton";

const steps = [
  { id: "agence", Component: AgenceOffreStep },
  { id: "proprietaire", Component: ProprietaireOffreStep },
];

export type OfferProps = {
  isLoading: boolean;
  data: Offer[];
};

const tabs = ["Une agence", "Un propriétaire"];

const Offres = () => {
  const [type, setType] = useState("agence");
  const { data = [], isLoading } = useGetOffersByVisitorsQuery({ type });
  const [step, setStep] = useState(0);

  const props = { data, isLoading };

  return (
    <div className="landing-page-component">
      <EmptyHeader />
      <div className="page-content">
        <section className="section-banner-landing-page-v2 py-5">
          <div className="banner-lading-page-v2-container">
            <div className="content-title-lading-page-v2 mt-5">
              <h2 className="title-lading-page-v2">Offres</h2>
              <div className="title-lading-page-v2-para-container mt-4 w-100">
                <p className="title-lading-page-v2-para justify-content-center">
                  Profitez de nos offres si vous êtes
                </p>
              </div>
            </div>
            <div className="content-btn-banner-landing-page-v2 my-5 text-center offres-page-content">
              <ul className="tabs-container-historique">
                {tabs.map((tab, key) => (
                  <li
                    className={`tab-item  ${step === key && "tab-active-item"}`}
                    key={key}
                    onClick={() => {
                      setStep(key);
                      if (key === 0) {
                        setType("agence");
                      } else {
                        setType("proprietaire");
                      }
                    }}
                    role="button"
                  >
                    {tab}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section className="section-about-kan-immo custom-padding">
          <div className="tabs-component-container">
            {step === 0 && <AgenceOffreStep {...props} />}
            {step === 1 && <ProprietaireOffreStep {...props} />}
          </div>
        </section>
      </div>

      <FrontFooter />
    </div>
  );
};

export default Offres;

export const OfferSkeleton = () => {
  return (
    <>
      {[...Array(4)]?.map((item, i) => (
        <div className="col-md-3 col-step-offre mb-3 d-flex" key={i}>
          <div
            className={`content-col-step-offre content-col-step-offre${i + 1}`}
          >
            <Skeleton className={`title-offer-skeleton`} />
            <div className="container-btn-offre-price">
              <Skeleton
                className={`content-btn-price-offre-step content-btn-price-offre-step${
                  i + 1
                }`}
              />
            </div>
            <div className="content-describe-card-offre-step mt-5">
              <Skeleton count={4} />
            </div>

            <div className="content-btn-register-offre mt-5">
              <Skeleton className="btn btn-register-offre" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
