import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import Swal from 'sweetalert2';
import ConditionApi from '../../../utils/api/condition/condition.api';
import { ConditionType, ICondition } from '../../../utils/api/condition/condition.type';
import { Color } from '../../../utils/theme';
import { BtnSubmit } from '../../common/Button';
import ErrorMessage from '../../common/ErrorMessage';
import { Input } from '../../common/Input';
import { SelectInput } from '../../common/SelectInput';
import UseCrudCondition from '../../TableauDebord/Admin/Options/CrudCondition/useForm/UseCrudCondition';


const typeConditionOption = [
    {
        value: ConditionType.condition_utilisation,
        label: "Conditions d’utilisation"
    },
    {
        value: ConditionType.besoin_compte,
        label: "Vous avez besoin d’un compte"
    },
    {
        value: ConditionType.contenu,
        label: "Votre contenu est le vôtre"
    },
    {
        value: ConditionType.droit_service,
        label: "Nos droits dans les services"
    },
    {
        value: ConditionType.droit_auteur,
        label: "Traiment des problèmes de droit d’auteur"
    },
    {
        value: ConditionType.ne_pas_faire,
        label: "Choses que vous devriez et ne devriez pas faire"
    },
    {
        value: ConditionType.suppression_compte,
        label: "Suppression de votre compte"
    },
    {
        value: ConditionType.avis_non_responsabilite,
        label: "Avis de non-responsabilité, limitations de responsabilité et indemnisation"
    },
    {
        value: ConditionType.diffusion_annonce,
        label: "Diffusion d'annonces"
    },
    {
        value: ConditionType.condition_generale,
        label: "Conditions générales"
    }
];


function AjouterConditionModal({
    condition,
    modalId,
  }: {
    condition?: ICondition;
    modalId: string;
  }) {
    
    const {
        register,
		errors,
		onSubmit,
		setValue,
		isLoading,
		reset,
        description, 
        setDescription,
        handleChange
      } = UseCrudCondition(modalId, condition)
     
    
      React.useEffect(() => {
        if (condition) {
          setValue('type', condition?.type)
          setValue('titre', condition?.titre)
          setValue('slug', condition?.slug)
          setValue('text', condition?.text)
          setDescription(condition?.text || '')
        }
      }, [condition])
    
    //   React.useEffect(() => {
    //     if (errors) {
    //       setTimeout(() => {
    //         Object.entries(errors).map(([key]: any) => {
    //           return clearErrors(key)
    //         })
    //       }, 3000)
    //     }
    //   }, [formState])
    
      return (
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-body">
                    <div className="container-form">
                        <form onSubmit={onSubmit}>
                            <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                <Input
                                    type="text"
                                    label="Titre"
                                    id="titre"
                                    placeholder="Titre"
                                    required
                                    {...register("titre")}
                                />
                                </div>
									</div>
                                <div className="col-md-12">
									<div className="mb-3">
                                        <ReactQuill
                                            className="editor-cgu"
                                            value={description}
                                            onChange={handleChange}
                                        />
                                        {errors.text && (
                                            <ErrorMessage
                                                message={
                                                    errors.text?.message
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-12">
									<div className="mb-3 flex-r">
                                        <BtnSubmit
                                            label={condition ? "Modifier" : "Ajouter"}
                                            isLoading={isLoading}
                                            style={{ padding: "5px 10px", fontSize: 14 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
      );
}

export default AjouterConditionModal