import React, { useState, useRef, useEffect } from "react";
// import moment from "moment";
import { DateRangePicker } from "react-dates";
import "./DashboardAgence.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./React_dates_overrides.css";
import Key from "../../../../assets/icons/key.png";
import Users from "../../../../assets/icons/users.png";
import Paper from "../../../../assets/icons/paper.png";
import LineChart from "./LineChart/LineChart";
import LocatairesNonPayeTable from "../../../tables/agence/LocatairesNonPayeTable/LocatairesNonPayeTable";
import { NavLink, useNavigate } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { MdNotifications } from "react-icons/md";
import Profil from "../../../../assets/appImages/profil.png";
import { useAppSelector } from "../../../../redux/hooks";
import {
  formatMontant,
  formattedDate,
  getAvatar,
  getName,
  isAgence,
  isAgenceAgent,
  isAgenceJuriste,
} from "../../../../utils/Utils";
import {
  useFiltreAgenceDataMutation,
  useGetAgenceDataQuery,
  useGetAgencesQuery,
} from "../../../../utils/api/agence/agence.api";
import { AlertInfo } from "../../../common/Alert";
import { IRequete } from "../../../../utils/api/requete/requete.type";
import moment from "moment";
import {
  ILitiges,
  TypesLitiges,
} from "../../../../utils/api/litiges/litiges.type";
import { CAFilterQuery } from "../../../../utils/api/admin/admin.type";
import {
  IAgenceData,
  IFiltreAgenceData,
} from "../../../../utils/api/user/user.type";

const DashboardAgence = () => {
  const { user } = useAppSelector((s) => s?.user);
  const [total_payer_mois_passer, setTotal_payer_mois_passer] = useState<any>();
  const [montant_total_impayes, setMontant_total_impayes] = useState<any>();
  const [nombre_locataires, setNombre_locataires] = useState<any>();
  const [filtreAgenceData] = useFiltreAgenceDataMutation();

  const [query, setQuery] = useState<IFiltreAgenceData>({
    date_du: "",
    date_au: "",
  });

  const onQueryChange = (
    field: keyof IFiltreAgenceData,
    value: string | number
  ) => {
    setQuery((old) => ({ ...old, [field]: value }));
  };

  const [donneesFiltrees, setDonneesFiltrees] = useState<any>(null);

  useEffect(() => {
    updateDonnees();
  }, [donneesFiltrees]);

  const updateDonnees = async () => {
    try {
      if (donneesFiltrees) {
        console.log({ donneesFiltrees });
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des données:", error);
    }
  };

  const handleSubmit = async (data: IFiltreAgenceData) => {
    try {
      const newData = await filtreAgenceData({
        data: data,
        type: "agence",
        slug: user.slug,
      });

      // Mettre à jour l'état 'donneesFiltrees' avec les données filtrées
      setDonneesFiltrees(newData);
    } catch (error) {
      console.error("Erreur lors du traitement des données filtrées:", error);
    }
  };
  var [showNotifications, setShowNotifications] = React.useState(false);

  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  // console.log("user", user);
  const { data: ListeAgence } = useGetAgencesQuery({});
  const AgenceData = ListeAgence?.results
    ?.filter((item) => item?.id === user?.agence_id)
    ?.map((itm) => itm?.slug);
  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));
  const { data, isLoading } = useGetAgenceDataQuery({
    type: "agence",
    slug: is_Agence
      ? user?.slug
      : isAgenceAgent(user) || isAgenceJuriste(user)
      ? user?.agence_id?.slug
      : AgenceData && AgenceData[0],
  });

  const navigate = useNavigate();
  React.useEffect(() => {
    if (!user?.dashboard && !is_Agence) {
      setTimeout(() => {
        navigate(-1);
      }, 50);
    }
  }, [user]);

  const Status = [
    {
      label: "Assignation d’Expulsion",
      value: TypesLitiges.assignationExpulsion,
    },
    {
      label: "Commandement de payer",
      value: TypesLitiges.commandementPayer,
    },
    {
      label: "Jugement",
      value: TypesLitiges.jugement,
    },
    {
      value: "non_regler",
      label: "Non réglée",
    },
    {
      value: "encours",
      label: "En cours de traitement",
    },
    {
      value: "regler",
      label: "Réglée",
    },
    {
      value: "revision",
      label: `${
        isAgenceJuriste(user)
          ? "Pour révision"
          : "Envoyé pour révision par le juriste"
      }`,
    },
  ];

  useEffect(() => {
    if (query?.date_du != "" && query?.date_au != "") {
      handleSubmit(query);
    }
  }, [query]);
  return (
    <div className="dashboard-admin-component">
      <div className="row container-dashboard-page">
        <div
          className={`${
            !isAgenceJuriste(user) ? "col-lg-8" : "col-lg-12"
          } col-md-12 pb-3 col-right-dashboard-view`}
        >
          <div className="col-left-dashboard mb-3">
            <div className="row px-md-2">
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Key}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    {!isAgenceJuriste(user) ? (
                      <div className="content-text-item-stat-dashboad-view ms-3">
                        <div className="content-item-stat-dashboad-view mt-2">
                          <p className="titre-item-stat-dashboad-view mb-1">
                            Total des biens
                          </p>
                          <p className="users-item-stat-dashboad-view mb-1">
                            {/* Cette semaine */}
                          </p>
                        </div>
                        <div className="icon-item-stat-dashboad-view">
                          <p className="chiffre-item-stat-dashboad-view mb-1">
                            {isLoading || data === undefined ? (
                              <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                                style={{ color: "#1F3CAD" }}
                              ></div>
                            ) : (
                              data?.total_bien
                            )}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="content-text-item-stat-dashboad-view ms-3">
                        <div className="content-item-stat-dashboad-view mt-2">
                          <p className="titre-item-stat-dashboad-view mb-1">
                            Nombre de contentieux total
                          </p>
                          <p className="users-item-stat-dashboad-view mb-1">
                            {/* Cette semaine */}
                          </p>
                        </div>
                        <div className="icon-item-stat-dashboad-view">
                          <p className="chiffre-item-stat-dashboad-view mb-1">
                            {isLoading || data === undefined ? (
                              <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                                style={{ color: "#1F3CAD" }}
                              ></div>
                            ) : (
                              data?.nbre_litiges
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Users}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    {!isAgenceJuriste(user) ? (
                      <div className="content-text-item-stat-dashboad-view ms-3">
                        <div className="content-item-stat-dashboad-view mt-2">
                          <p className="titre-item-stat-dashboad-view mb-1">
                            Total des locataires
                          </p>
                          <p className="users-item-stat-dashboad-view mb-1">
                            {/* Cette semaine */}
                          </p>
                        </div>
                        <div className="icon-item-stat-dashboad-view">
                          <p className="chiffre-item-stat-dashboad-view mb-1">
                            {isLoading || data === undefined ? (
                              <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                                style={{ color: "#FCAE3B" }}
                              ></div>
                            ) : (
                              data?.total_locataire
                            )}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="content-text-item-stat-dashboad-view ms-3">
                        <div className="content-item-stat-dashboad-view mt-2">
                          <p className="titre-item-stat-dashboad-view mb-1">
                            Nombre de contentieux en cours,
                          </p>
                          <p className="users-item-stat-dashboad-view mb-1">
                            {/* Cette semaine */}
                          </p>
                        </div>
                        <div className="icon-item-stat-dashboad-view">
                          <p className="chiffre-item-stat-dashboad-view mb-1">
                            {isLoading || data === undefined ? (
                              <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                                style={{ color: "#FCAE3B" }}
                              ></div>
                            ) : (
                              data?.nbre_litiges_en_cours
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Paper}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    {!isAgenceJuriste(user) ? (
                      <div className="content-text-item-stat-dashboad-view ms-3">
                        <div className="content-item-stat-dashboad-view mt-2">
                          <p className="titre-item-stat-dashboad-view mb-1">
                            Total des biens vacants
                          </p>
                          <p className="users-item-stat-dashboad-view mb-1">
                            {/* Cette semaine */}
                          </p>
                        </div>
                        <div className="icon-item-stat-dashboad-view">
                          <p className="chiffre-item-stat-dashboad-view mb-1">
                            {isLoading || data === undefined ? (
                              <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                                style={{ color: "#1AA981" }}
                              ></div>
                            ) : (
                              data?.biens_vacants
                            )}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="content-text-item-stat-dashboad-view ms-3">
                        <div className="content-item-stat-dashboad-view mt-2">
                          <p className="titre-item-stat-dashboad-view mb-1">
                            Nombre de contentieux réglés
                          </p>
                          <p className="users-item-stat-dashboad-view mb-1">
                            {/* Cette semaine */}
                          </p>
                        </div>
                        <div className="icon-item-stat-dashboad-view">
                          <p className="chiffre-item-stat-dashboad-view mb-1">
                            {isLoading || data === undefined ? (
                              <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                                style={{ color: "#1AA981" }}
                              ></div>
                            ) : (
                              data?.nbre_litiges_regler
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!isAgenceJuriste(user) && (
              <div className="bg-white p-3 mt-4">
                <LineChart data={data?.graph} />
              </div>
            )}
          </div>
          {!isAgenceJuriste(user) && (
            <div className="col-left-dashboard mb-3">
              <div className="container-title-card-dashbord-view mb-4">
                <h3 className="title-card-dashbord-view">
                  Locataires qui n’ont pas payé
                </h3>
              </div>
              <div className="container-table-locataires-non-paye">
                {isLoading || data === undefined ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                    style={{ color: "#1F3CAD" }}
                  ></div>
                ) : donneesFiltrees && query.date_du && query.date_au ? (
                  <LocatairesNonPayeTable
                    locataires_non_payant={
                      donneesFiltrees?.data?.locataires_non_payant
                    }
                    isLoading={isLoading}
                  />
                ) : (
                  <LocatairesNonPayeTable
                    locataires_non_payant={data?.locataires_non_payant}
                    isLoading={isLoading}
                  />
                )}
              </div>
            </div>
          )}

          {/* ================ JURISTE AGENCE =============== */}

          {isAgenceJuriste(user) && (
            <div className="col-left-dashboard mb-3">
              <div className="container-title-card-dashbord-view mb-4">
                <h3 className="title-card-dashbord-view">
                  contentieux en cours
                </h3>
              </div>
              {!isLoading && data?.litiges_en_cours?.length === 0 ? (
                <AlertInfo message="Aucun contentieux en cours" />
              ) : (
                data?.litiges_en_cours?.map((itm: ILitiges, index) => (
                  <div className="container-litige-dashboard-view" key={index}>
                    <div className="litige-card-item-dashboard-view">
                      <div className="content-text-litige-dashboard-view">
                        <p className="litige-text-dashboard-view">
                          {formattedDate(itm?.date)}
                        </p>
                      </div>
                      <div className="content-border-style">
                        <div
                          className={
                            itm?.type_litige === "assignation_expulsion"
                              ? "circle-item-green"
                              : itm?.type_litige === "commandement_payer"
                              ? "circle-item-blue"
                              : "circle-item-yellow"
                          }
                        ></div>
                        {index !== data?.litiges_en_cours?.length - 1 ? (
                          <span className="vertical-line"></span>
                        ) : null}
                      </div>
                      <div className="content-text-litige-dashboard-view">
                        <p className="litige-text-dashboard-view">
                          <span style={{ fontWeight: 600, fontSize: 18 }}>
                            {
                              Status?.find((val) => val?.value === itm?.statut)
                                ?.label
                            }
                          </span>{" "}
                          du locataire
                          <span style={{ fontWeight: 600, fontSize: 18 }}>
                            {" "}
                            {getName(itm?.locataire)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              )}
              {isLoading && (
                <div
                  className="spinner-border spinner-border-sm"
                  role="status"
                  style={{ color: "#1F3CAD" }}
                ></div>
              )}
            </div>
          )}

          {/* ================ FIN JURISTE AGENCE =============== */}
        </div>
        {!isAgenceJuriste(user) && (
          <div className="col-lg-4 col-md-12 pb-3 col-right-dashboard-view">
            <div className="col-left-dashboard mb-3">
              <div className="content-info-dashboard-view">
                {/* Ma formulaire pour filtre */}
                <div className="custom-date-range border-0 mb-3">
                  <DateRange
                    start={query.date_du}
                    end={query.date_du}
                    onChange={(start, end) => {
                      onQueryChange("date_du", start);
                      onQueryChange("date_au", end);
                    }}
                  />
                </div>
                {/* Ma formulaire pour filtre FIN */}

                <div className="content-info-item mb-3">
                  <div className="content-title-info-dashboard-view">
                    <p className="title-info-dashboard-view">
                      Total loyers recouvrés{" "}
                    </p>
                  </div>
                  <div className="flex-sb content-text-info-dashboard-view">
                    <p className="text-date-info-dashboard-view mb-0">
                      {/* Le mois dernier */}
                    </p>
                    <p className="text-chiffre-info-dashboard-view mb-0">
                      {isLoading || data === undefined ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#1F3CAD" }}
                        ></div>
                      ) : donneesFiltrees && query.date_du && query.date_au ? (
                        formatMontant(
                          donneesFiltrees?.data.total_payer_mois_passer
                        )
                      ) : (
                        formatMontant(data?.total_payer_mois_passer)
                      )}
                    </p>
                  </div>
                </div>
                <div className="content-info-item mb-3">
                  <div className="content-title-info-dashboard-view">
                    <p className="title-info-dashboard-view">
                      Total loyers non recouvrés{" "}
                    </p>
                  </div>
                  <div className="flex-sb content-text-info-dashboard-view">
                    <p className="text-date-info-dashboard-view mb-0">
                      {/* Le mois dernier */}
                    </p>
                    <p className="text-chiffre-info-dashboard-view mb-0">
                      {isLoading || data === undefined ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#1F3CAD" }}
                        ></div>
                      ) : donneesFiltrees && query.date_du && query.date_au ? (
                        formatMontant(
                          donneesFiltrees?.data.total_non_payer_mois_passer
                            ?.montant_total_impayes
                        )
                      ) : (
                        formatMontant(
                          data?.total_non_payer_mois_passer
                            ?.montant_total_impayes
                        )
                      )}
                    </p>
                  </div>
                </div>
                <div className="content-info-item mb-3">
                  <div className="content-title-info-dashboard-view">
                    <p className="title-info-dashboard-view">
                      Total des locataires n’ayant pas payés leurs loyer{" "}
                    </p>
                  </div>
                  <div className="flex-sb content-text-info-dashboard-view">
                    <p className="text-date-info-dashboard-view mb-0">
                      {/* Du 01/01 à aujourd’hui */}
                    </p>
                    <p className="text-chiffre-info-dashboard-view mb-0">
                      {isLoading || data === undefined ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#1F3CAD" }}
                        ></div>
                      ) : donneesFiltrees && query.date_du && query.date_au ? (
                        donneesFiltrees?.data.total_non_payer_mois_passer
                          ?.nombre_locataires
                      ) : (
                        data?.total_non_payer_mois_passer?.nombre_locataires
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-left-dashboard mb-3">
              <div className="container-title-card-dashbord-view mb-4">
                <h3 className="title-card-dashbord-view">
                  Contentieux en cours
                </h3>
              </div>
              {!isLoading && data?.litiges_en_cours?.length === 0 ? (
                <AlertInfo message="Aucun contentieux en cours" />
              ) : (
                data?.litiges_en_cours?.map((itm: ILitiges, index) => (
                  <div className="container-litige-dashboard-view" key={index}>
                    <div className="litige-card-item-dashboard-view custom-litige-card-item-dashboard-view">
                      <div className="content-text-litige-dashboard-view">
                        <p className="litige-text-dashboard-view custom-litige-text-dashboard-view">
                          {formattedDate(itm?.date)}
                        </p>
                      </div>
                      <div className="content-border-style">
                        <div
                          className={
                            itm?.type_litige === "assignation_expulsion"
                              ? "circle-item-green custom-circle-item-green"
                              : itm?.type_litige === "commandement_payer"
                              ? "circle-item-blue custom-circle-item-blue"
                              : "circle-item-yellow custom-circle-item-yellow"
                          }
                        ></div>
                        {index !== data?.litiges_en_cours?.length - 1 ? (
                          <span className="vertical-line custom-vertical-line"></span>
                        ) : null}
                      </div>
                      <div className="content-text-litige-dashboard-view">
                        <p className="litige-text-dashboard-view custom-litige-text-dashboard-view">
                          <span style={{ fontWeight: 600, fontSize: 14 }}>
                            {itm?.type_litige === "assignation_expulsion"
                              ? "Assignation d’expulsion"
                              : itm?.type_litige === "commandement_payer"
                              ? "Commandement de payer"
                              : "Jugement"}
                          </span>{" "}
                          du locataire
                          <span style={{ fontWeight: 600, fontSize: 14 }}>
                            {" "}
                            {getName(itm?.locataire)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              )}
              {isLoading && (
                <div
                  className="spinner-border spinner-border-sm"
                  role="status"
                  style={{ color: "#1F3CAD" }}
                ></div>
              )}
            </div>
            {/* <div
              className="content-info-item mb-3 position-relative"
              style={{ borderRadius: 10 }}
            >
              <div
                className="content-img-notif-requete linkable"
                onClick={() => onShowAndHideNotification()}
              >
                <MdNotifications className="icon-notif-requete" />
              </div>
              {showNotifications && (
                <div
                  className={"dashboard-view-hiddenable-notification-section"}
                >
                  <ul className="header-hiddenable-notification-ul">
                    {data?.requetes_en_attente?.length === 0 ? (
                      <AlertInfo message="Pas de données" />
                    ) : (
                      data?.requetes_en_attente?.map((itm: IRequete) => (
                        <li className="header-hiddenable-notification-li">
                          
                          <NavLink
                            to={`/agence/locataires/requete/${itm?.slug}`}
                            className="notification-item-link d-flex no-link"
                            state={itm}
                          >
                            <div className="content-img-profil-notif">
                              <img
                                src={getAvatar(itm?.locataire?.avatar)}
                                alt="img profil locataire"
                                className="img-profil-notif"
                              />
                            </div>
                            <div className="content-text-notif ps-2">
                              <p className="text-notif mb-1">
                                {itm?.locataire?.prenom +
                                  " " +
                                  itm?.locataire?.nom}{" "}
                                a soumis une requête: {itm?.requete}
                                {itm?.titre}
                              </p>
                              <p className="text-date-notif mb-0">
                                {formattedDate(itm?.created_at)} -{" "}
                                {moment(itm?.created_at).format("LT")}
                              </p>
                            </div>
                          </NavLink>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              )}
              <div className="content-title-info-dashboard-view ps-5">
                <p className="title-info-dashboard-view">
                  {data?.requetes_en_attente?.length} requêtes sont en attente
                  de traitement
                </p>
              </div>
              <div className="flex-r content-btn-see-more-info-dashboard-view">
                {!isLoading && data?.requetes_en_attente ? (
                  data?.requetes_en_attente?.length > 0 ? (
                    <NavLink
                      to="/agence/locataires"
                      className="btn btn-see-more"
                    >
                      <span className="pe-2">Voir</span>
                      <BsFillArrowRightCircleFill className="icon-see-more" />
                    </NavLink>
                  ) : (
                    ""
                  )
                ) : (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                    style={{ color: "#1F3CAD" }}
                  ></div>
                )}
              </div>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardAgence;

type DateRangeProps = {
  onChange: (start: string, end: string) => any;
  start?: string | null;
  end?: string | null;
  isDayBlocked?: (day: moment.Moment) => boolean;
  isOutsideRange?: (day: moment.Moment) => boolean;
  labels?: [string | undefined, string | undefined];
};
const isSame = (d1: moment.Moment, d2: moment.Moment) =>
  d1.format("L") === d2.format("L");
export function DateRange({
  start,
  end,
  onChange,
  isDayBlocked,
  labels,
  isOutsideRange,
}: DateRangeProps) {
  const [blockeds, setBlockeds] = useState<moment.Moment[]>([]);
  const [startDateObj, setStartDateObj] = useState<moment.Moment | null>(null);
  const [reload, setReload] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(start ? moment(start) : null);
  const [endDate, setEndDate] = useState<any>(end ? moment(end) : null);
  const [focusedInput, setFocusedInput] = useState<
    "startDate" | "endDate" | null
  >(null);
  const outside = (day: moment.Moment) => {
    return day < moment();
  };
  const isOutside = (day: moment.Moment) => {
    const ok = outside(day);
    if (isOutsideRange) return ok && isOutsideRange(day);
    return ok;
  };
  const blocked = (day: moment.Moment) => {
    if (isDayBlocked) {
      const bool = isDayBlocked(day);
      if (bool) {
        setBlockeds((old) => {
          const index = old.findIndex((b) => isSame(b, day));
          if (index === -1) return [...old, day];
          return old;
        });
      }
      return bool;
    }
    return false;
  };

  const ref = useRef();
  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="startDate"
      endDate={endDate}
      ref={ref}
      endDateId="endDate"
      startDatePlaceholderText={(labels && labels[0]) || "Du"}
      endDatePlaceholderText={(labels && labels[1]) || "Au"}
      isDayBlocked={blocked}
      onDatesChange={({
        startDate,
        endDate,
      }: {
        startDate: any;
        endDate: any;
      }) => {
        onChange(
          startDate ? moment(startDate).format("YYYY-MM-DD") : "",
          endDate ? moment(endDate).format("YYYY-MM-DD") : ""
        );
        setStartDate(startDate);
        setEndDate(endDate);
      }}
      focusedInput={focusedInput}
      showClearDates={true}
      onFocusChange={setFocusedInput}
      initialVisibleMonth={() => (start ? moment(start) : moment())}
      isOutsideRange={() => false}
    />
  );
}
