import React from "react";
import Avatar from "../../../../assets/appImages/avata.png";
import { NavLink, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { FaFileDownload } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import Om from "../../../../assets/icons/orange-money.png";
import Wave from "../../../../assets/icons/wave.png";
import Mc from "../../../../assets/icons/masterCard.png";
import { getAvatar, useLocationState } from "../../../../utils/Utils";
import { IProprietaire } from "../../../../utils/api/proprietaire/proprietaire.type";
import ListProprietesByProrietaire from "./ListProprietesByProrietaire";
import { isAdminUser } from "../../../../routes/router";
import { useAppSelector } from "../../../../redux/hooks";
import AgenceDocumentContratModal from "../../../modal/agence/AgenceDocumentContratModal";

function ProprietaireDetailsInfos() {
  const item = useLocationState<IProprietaire>(undefined);
  const navigate = useNavigate();
  // console.log("item", item)
  const { user } = useAppSelector((s) => s.user);
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        {/* <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4
            className="kanimmo-dash-locataire-requetes-header-titre"
            onClick={() =>
              isAdminUser(user) ? navigate("/admin/mes-clients") : navigate(-1)
            }
          >
            <BiArrowBack />
            <span className="ps-3">Détails Propriétaire</span>
          </h4>
        </div> */}
        <div className="kanimmo-proprietes-container">
          <div className="proprietaire-component-container">
            <div className="content-detail-proprietaire">
              <div className="row">
                {/* <div className="col-md-4">
                  <div className="col-left-detail-proprietaire">
                    <div className="content-title-detail-proprietaire">
                      <p className="title-detail-proprietaire">
                        Information du propriétaire
                      </p>
                    </div>
                    <div className="text-center pt-3">
                      <div className="content-pp-proprietaire pb-2">
                        <img
                          src={getAvatar(item?.avatar)}
                          alt="Avatar"
                          className="pp-proprietaire"
                        />
                      </div>
                      <p className="title-detail-proprietaire">
                        {item?.prenom + " " + item?.nom}
                      </p>
                      <p className="title-detail-proprietaire">
                        {item?.adresse}
                      </p>
                    </div>
                    <div className="content-info-proprietaire">
                      <div className="content-info-prprietaire-item mb-2">
                        <MdOutlineMailOutline />
                        <span className="ps-3">
                          <a className="no-link" href={`mailto:${item?.email}`}>
                            {item?.email}
                          </a>
                        </span>
                      </div>
                      <div className="content-info-prprietaire-item mb-2">
                        <IoCallOutline />
                        <span className="ps-3">
                          <a
                            className="no-link"
                            href={`tel:${item?.telephone}`}
                          >
                            {item?.telephone}
                          </a>
                        </span>
                      </div>
                      {!!item?.contrat && (
                        <div
                          className="content-info-prprietaire-item mb-2"
                          data-bs-toggle="modal"
                          data-bs-target={`#DocumentContratModal${item?.slug}`}
                        >
                          <FaFileDownload />
                          <span className="ps-3">Contrat de gérance</span>
                        </div>
                      )}
                      <div
                        className="modal fade"
                        id={`DocumentContratModal${item?.slug}`}
                        aria-labelledby="DocumentContratModalLabel"
                        aria-hidden="true"
                      >
                        <AgenceDocumentContratModal item={item} />
                      </div> */}

                {/* <div className="content-info-prprietaire-item mb-2">
                        <img
                          src={Om}
                          alt="moyen paiement"
                          className="img-moyen-paiement"
                        />
                        <span className="ps-3">+221 77 700 00 00</span>
                      </div>
                      <div className="content-info-prprietaire-item mb-2">
                        <img
                          src={Wave}
                          alt="moyen paiement"
                          className="img-moyen-paiement"
                        />
                        <span className="ps-3">+221 77 700 00 00</span>
                      </div>
                      <div className="content-info-prprietaire-item mb-2">
                        <img
                          src={Mc}
                          alt="moyen paiement"
                          className="img-moyen-paiement"
                        />
                        <span className="ps-3">5469 23** **** 4596</span>
                      </div> */}
                {/* </div>
                  </div>
                  <div className="container-renum mx-0 my-2">
                    <p className="text-renum mb-2">Taux de remunération</p>
                    <p className="text-renum mb-2">-</p>
                    <p className="text-renum mb-0">20% </p>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <ListProprietesByProrietaire slug={item.slug} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProprietaireDetailsInfos;
