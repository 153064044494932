import { Carousel } from "react-responsive-carousel";
import bienPopulaireImg from "../../../../assets/appImages/bienPopulaireImg.png";
import { IoBed } from "react-icons/io5";
import { FaHeart, FaShower } from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export function BienPopulaire() {
	return (
		// <div className="others-partnerships-col col-lg-4 col-md-12">
		<div className="container-col-right-news-item w-100">
			<div className="others-partnerships-content">
				<h4 className="other-partnerships-title">Biens populaires</h4>
				<Carousel showThumbs={false}>
					{[...Array(3)].map((item, index) => (
						<BienPopulaireItem key={index} />
					))}
				</Carousel>
			</div>
		</div>
		// </div>
	);
}

const BienPopulaireItem = () => {
	return (
		<div className="card">
			<img src={bienPopulaireImg} alt="img bien populaire" className="card-img-top" />
			<div className="card-body">
				<div className="custom-prix-bien-populaire">
					<p className="custom-card-text-prix mb-0">
						50 000 FCFA/{" "}
						<span className="custom-card-text-prix-span">Nuitée</span>
					</p>
					<FaHeart />
				</div>
				<h5 className="card-title custom-card-title-text">Appartement meublé</h5>
				<div className="custom-bien-populaire-footer">
					<div className="custom-bien-populaire-footer-detail1">
						<IoBed className="custom-bien-populaire-footer-icon" />
						<span>4</span>
					</div>
					<div className="custom-bien-populaire-footer-detail2">
						<FaShower className="custom-bien-populaire-footer-icon" />
						<span>2</span>
					</div>
				</div>
			</div>
		</div>
	);
};
