import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { TEditContactForm } from "../../../utils/api/contact/contact.type";
import { Color } from "../../../utils/theme";
import { cleannerError, onHide } from "../../../utils/Utils";
import { FormError } from "../../common/CustomInputCheckbox";
import * as yup from "yup";
import { useUpdateContactMutation } from "../../../utils/api/contact/contact.api";
import { BtnSubmit } from "../../common/Button";

const Status = [
	{
		value: "new",
		label: "Nouveau",
	},
	{
		value: "en_cours",
		label: "En cours",
	},
	{
		value: "traité",
		label: "Traité",
	},
];
function ChangeStatusMessageModal({ item }: { item?: any }) {
	useEffect(() => {
		if (item?.id) {
			setValue("slug", item?.slug);
			setValue("status", item?.status);
		}
	}, [item]);
	const validationSchema = yup.object().shape({
		status: yup.string().required().label("Status"),
	});
	const [changeStatus, { isSuccess, isError, error, isLoading }] = useUpdateContactMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<TEditContactForm>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (item?.id) {
			setValue("slug", item?.slug);
			setValue("status", item?.status);
		}
	}, [item]);

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);
	
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Statut changé avec  succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
				onHide("ChangeStatusMessageModal");
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: `${err ? err?.message : "An error occured"}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	const onSubmit = (data: TEditContactForm) => {
		console.log(data);
		changeStatus({ slug: data?.slug, data: data });
	};

	return (
		<div
			className="modal fade"
			id="ChangeStatusMessageModal"
			aria-labelledby="ChangeStatusMessageModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom"
							id="ChangeStatusMessageModal"
						>
							Change Status
						</h5>
						{/* <CloseModalBtn onClick={() => console.log("click")} /> */}
					</div>
					<div className="modal-body justify-content-start w-100">
						<form className="w-100" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12 mb-3">
									<div>
										<label className="form-label form-label-modal-custom">
											Statut
											<span className="text-danger">
												*
											</span>
										</label>
										<select
											className="form-control form-control-modal-custom py-3"
											id="name"
											{...register("status")}
										>
											<option value="" selected disabled>
												Choice a type
											</option>
											{Status.map((option) => (
												<option
													value={option.value}
													key={option.value}
												>
													{option.label}
												</option>
											))}
										</select>
										{errors?.status && (
											<FormError
												error={errors?.status}
											/>
										)}
									</div>
								</div>
							</div>
							
							<div className="container-btn-modal row ">
								<div className="col-md-3">
									<button
										className="btn btn-cancelled"
										data-bs-dismiss="modal"
										type="button"
									>
										Annuler
									</button>
								</div>
								<div className="col-md-6 offset-md-3 flex-r"
								data-bs-dismiss="modal"
								>
									<BtnSubmit
										label="Envoyer"
										isLoading={isLoading}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangeStatusMessageModal;
