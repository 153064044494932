import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { GraphType } from "../../../../../utils/api/user/user.type";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { CAFilterQuery } from "../../../../../utils/api/admin/admin.type";

function ClientChart({ data }: { data?: GraphType[] }) {
  const [clients, setClients] = useState(data);

  const [query, setQuery] = useState<CAFilterQuery>({
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    setClients(data);
  }, [data]);

  useEffect(() => {
    if (query?.start_date != "" && query?.end_date != "") {
      setClients(
        data?.filter(
          (item) =>
            moment(item.month).isSameOrAfter(moment(query?.start_date)) &&
            moment(query?.end_date).isSameOrAfter(moment(item?.month))
        )
      );
    } else if (query?.start_date != "") {
      setClients(
        data?.filter((item) =>
          moment(item.month).isSameOrAfter(moment(query?.start_date))
        )
      );
    } else if (query?.end_date != "") {
      setClients(
        data?.filter((item) =>
          moment(query?.end_date).isSameOrAfter(moment(item?.month))
        )
      );
    } else {
      setClients(data);
    }
  }, [data, query]);

  const series = [
    {
      data: clients?.map((item) => item?.agences),
      name: "Agences",
    },
    {
      data: clients?.map((item) => item?.proprietaires),
      name: "Propriétaires",
    },
  ];

  const options: any = {
    chart: {
      height: 280,
      type: "area",
      zoom: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#DFE0EB", "#FCAE3B"],
    fill: {
      type: "gradient",
      gradient: {
        type: "horizontal",
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.2,
        stops: [0, 100, 10],
      },
      colors: ["#C4C4C4", "#3751FF"],
    },
    xaxis: {
      categories: clients?.map(
        (item) =>
          moment(item?.month).format("MMM").charAt(0).toUpperCase() +
          moment(item?.month).format("MMM").substr(1)
      ),
      labels: {
        style: {
          colors: "#9FA2B4",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#9FA2B4",
        },
      },
    },
  };

  const onQueryChange = (
    field: keyof CAFilterQuery,
    value: string | number
  ) => {
    setQuery((old) => ({ ...old, [field]: value }));
  };

  return (
    <div className="chart-item-container">
      <div className="row mb-2 chart-infos-row">
        <div className="col-md-12 mb-2">
          <div className="barchart-titre-container">
            <h2 className="chart-item-title">Mes clients</h2>
            <div>
              <div className="d-flex align-items-center gap-3 custom-date-range">
                <span className="custom-recette-admin-input-date-span">
                  Filtrer
                </span>
                <DateRange
                  start={query.start_date}
                  end={query.start_date}
                  onChange={(start, end) => {
                    onQueryChange("start_date", start);
                    onQueryChange("end_date", end);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Chart
        className="chart-dashboard-marketplace"
        options={options}
        series={series}
        data="Ventes"
        type="area"
        width="100%"
        height="350"
      />
    </div>
  );
}

export default ClientChart;

type DateRangeProps = {
  onChange: (start: string, end: string) => any;
  start?: string | null;
  end?: string | null;
  isDayBlocked?: (day: moment.Moment) => boolean;
  isOutsideRange?: (day: moment.Moment) => boolean;
  labels?: [string | undefined, string | undefined];
};
const isSame = (d1: moment.Moment, d2: moment.Moment) =>
  d1.format("L") === d2.format("L");
export function DateRange({
  start,
  end,
  onChange,
  isDayBlocked,
  labels,
  isOutsideRange,
}: DateRangeProps) {
  const [blockeds, setBlockeds] = useState<moment.Moment[]>([]);
  const [startDateObj, setStartDateObj] = useState<moment.Moment | null>(null);
  const [reload, setReload] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(start ? moment(start) : null);
  const [endDate, setEndDate] = useState<any>(end ? moment(end) : null);
  const [focusedInput, setFocusedInput] = useState<
    "startDate" | "endDate" | null
  >(null);
  const outside = (day: moment.Moment) => {
    return day < moment();
  };
  const isOutside = (day: moment.Moment) => {
    const ok = outside(day);
    if (isOutsideRange) return ok && isOutsideRange(day);
    return ok;
  };
  const blocked = (day: moment.Moment) => {
    if (isDayBlocked) {
      const bool = isDayBlocked(day);
      if (bool) {
        setBlockeds((old) => {
          const index = old.findIndex((b) => isSame(b, day));
          if (index === -1) return [...old, day];
          return old;
        });
      }
      return bool;
    }
    return false;
  };

  const ref = useRef();
  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="startDate"
      endDate={endDate}
      ref={ref}
      endDateId="endDate"
      startDatePlaceholderText={(labels && labels[0]) || "Du"}
      endDatePlaceholderText={(labels && labels[1]) || "Au"}
      isDayBlocked={blocked}
      onDatesChange={({
        startDate,
        endDate,
      }: {
        startDate: any;
        endDate: any;
      }) => {
        onChange(
          startDate ? moment(startDate).format("YYYY-MM-DD") : "",
          endDate ? moment(endDate).format("YYYY-MM-DD") : ""
        );
        setStartDate(startDate);
        setEndDate(endDate);
      }}
      focusedInput={focusedInput}
      showClearDates={true}
      onFocusChange={setFocusedInput}
      initialVisibleMonth={() => (start ? moment(start) : moment())}
      isOutsideRange={() => false}
    />
  );
}
