import React from "react";
import Logo from "../../assets/appImages/logo.png";
import ErrorMessage from "../common/ErrorMessage";
import { BtnSubmit } from "../common/Button";
import UseForgetPasswordForm from "./useAuthForm/useForgetPassword";
import { NavLink } from "react-router-dom";

const ForgetPassword = () => {
	const { register, onSubmit, errors, isLoading } = UseForgetPasswordForm();
	return (
		<div className="auth-component">
			<div className="fixed-login-component">
				<div className="auth-container">
					<div className="auth-row row">
						<div className="col-md-6 offset-md-3 auth-col auth-right-side-col">
							<div className="auth-right-side-container">
								<div className="auth-form-container">
                                    <div className="flex-c py-4">
										<NavLink className="navbar-brand" to="/">
											<img src={Logo} alt="Logo" className="logo-app" />
										</NavLink>
                                    </div>
									<h1 className="auth-form-title">Mot de passe oublié?</h1>
									<h4 className="auth-form-message">Veuillez entrer votre email Nous vous enverrons un lien vers pour changer le mot de passed</h4>
									<div>
									<form onSubmit={onSubmit}>
										<div className="row auth-form-row">
											<div className="col-md-12 auth-input-col">
												<div className="form-group auth-form-group">
													<label
														htmlFor="email-or-username"
														className="form-label form-label-login"
													>
														Adresse email 
													</label>
													<input
														type={`email`}
														className="form-control auth-form-control"
														id="email-or-username"
														data-testid="emailId"
														{...register("email")}
													/>
													{errors?.email && (
														<ErrorMessage
															message={errors?.email?.message}
														/>
													)}
												</div>
											</div>

											<div className="col-md-6 offset-md-3 auth-submit-btn-container mt-4">
												<BtnSubmit 
												isLoading={isLoading} 
												label="Envoyer" />
											</div>
										</div>
									</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgetPassword;
