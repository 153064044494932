import React, { useEffect } from "react";
import {
  ChargeRequeteType,
  IDetailTypeRequete,
} from "../../../utils/api/typeRequete/typeRequete.type";
import { BtnCloseModal, BtnSubmit } from "../../common/Button";
import ErrorMessage from "../../common/ErrorMessage";
import { Input } from "../../common/Input";
import UseEditDetailTypeRequete from "../../TableauDebord/Admin/TypeRequetes/UseEditDetailTypeRequete";

function ModifierDetailTypeRequeteModal({
  item,
}: {
  item?: IDetailTypeRequete;
}) {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    reset,
    setValue,
    handleChangeCharge,
    charge,
    setCharge,
  } = UseEditDetailTypeRequete();
  useEffect(() => {
    if (item) {
      setValue("nom", item?.nom);
      setValue("charge", item?.charge);
      setValue("slug", item?.slug);
    // console.log("item", item);
      if (item?.charge) {
        setCharge({
          label: item?.charge,
          value: item?.charge,
        });
      } else {
        setCharge(null);
      }
    }
  }, [item]);
  return (
    <div className="modal-dialog modal-md modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header px-2 py-1">
          <h5
            className="modal-title"
            id="EditSousEquipementModal"
          >
            Modifier le nom
          </h5>
          <BtnCloseModal label="" onClick={reset} />
        </div>
        <form onSubmit={onSubmit}>
          {/* <div className="modal-body"> */}
            <div className="row py-4 px-3">
              <Input label="Nom" id="nom" {...register("nom")} />
              {errors?.nom && <ErrorMessage message={errors?.nom?.message} />}
              <div className="col-md-12 form-group mb-4 position-relative">
                <label
                    htmlFor="nom"
                    className="form-label form-label-modal-custom"
                >
                    Prise en charge
                </label>
                <select
                  id="type"
                  className="form-select form-select-modal-custom mb-2"
                  {...register("charge")}
                >
                  <option value="" disabled>
                    Selectionner le type
                  </option>
                  {chargeRequeteOption?.map((item) => (
                    <option value={item.value} key={item.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
                {errors?.charge && (
                  <span className="text-danger fw-400">
                    {errors?.charge?.message}
                  </span>
                )}
              </div>
                <div className="flex-r">
                    <BtnSubmit
                        label="Modifier"
                        isLoading={isLoading}
                        style={{ padding: "5px 10px", fontSize: 14 }}
                    />
                </div>
            </div>
        </form>
      </div>
    </div>
  );
}

export default ModifierDetailTypeRequeteModal;

const chargeRequeteOption = [
  {
    value: ChargeRequeteType.agence,
    label: "Agence",
  },
  {
    value: ChargeRequeteType.proprietaire,
    label: "Propriétaire",
  },
  {
    value: ChargeRequeteType.locataire,
    label: "Locataire",
  },
];
