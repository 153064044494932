import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, onHide } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { ITag, TagFormData } from "../../../../../utils/api/Tag/tag.type";
import { useAddOrUpdateTagMutation } from "../../../../../utils/api/Tag/tag.api";

export function useCrudTag(item?: ITag) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Tag"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<TagFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdateTag, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateTagMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Tag ${item ? "modifié" : "ajouté"} avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
				onHide("AddTagModal");
				reset();
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("nom", item?.nom);
		}
	}, [item]);
	const onSubmit = (data: TagFormData) => {
		console.log("data", data);
		addOrUpdateTag({ slug: item?.slug, data: data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
	};
}
