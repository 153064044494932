import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { onlogout } from "../../../../../redux/slice/User.slice";
import { useAddOrUpdateDeletedUserMutation } from "../../../../../utils/api/user/user.api";
import { SuppressionTypeFromData } from "../../../../../utils/api/user/user.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/Utils";
function UseDeleteAccountForm() {
  const user = useAppSelector((s) => s?.user?.user);
  const validationSchema = yup.object().shape({
    motif: yup.string().label("Le motif").required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<SuppressionTypeFromData>({
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      register("user");
      setValue("user", user?.id);
    }
  }, [user]);

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateDeletedUserMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Demande envoyée avec succés",
        text: "Votre demande de suppression a été envoyer et sera traitée dans les plus brefs délais.",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        showConfirmButton: true,
        // timer: 5000,
        allowOutsideClick: false,
      }).then(() => {
        reset();
        onHide("deleteAccountModal");
        dispatch(onlogout());
        window.location.pathname = "/connexion";
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isSuccess, isError]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const handleReset = () => {
    reset();
  };

  const onSubmit = async (data: SuppressionTypeFromData) => {
    data["user"] = user?.id;
    await sendData(data);
  };

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
  };
}

export default UseDeleteAccountForm;
