import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { usePersonnelFromLocation } from "../../../../../utils/api/agence/agence.api";
import { BtnSubmit } from "../../../../common/Button";
import { FormError } from "../../../../common/CustomInputCheckbox";
import ErrorMessage from "../../../../common/ErrorMessage";
import GoogleInput from "../../../../common/GoogleInput";
import useAddOrUpdateUserPQGSB from "./requestForm/useAddOrUpdateUserPQGSB";
import ConfirmAddPersonnel from "../../../../modal/agence/ConfirmAddPersonnel";

function AjouterUtilisateurPQGSB() {
  const [itemState, findBySlug] = usePersonnelFromLocation();

// console.log("itemState", itemState);
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    handleReset,
    modalIsOpen,
    setIsOpen,
    personne,
    onChangeAddress,
    address,
  } = useAddOrUpdateUserPQGSB(itemState);
  const navigate = useNavigate();
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            <BiArrowBack onClick={() => navigate(-1)} />{" "}
            {itemState ? "Modifier" : "Ajouter"} un utilisateur
          </h4>
        </div>
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="nom"
                        className="form-label form-label-modal-custom"
                      >
                        Nom
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-modal-custom"
                        id="nom"
                        placeholder="Nom"
                        {...register("nom")}
                      />
                      {errors?.nom && (
                        <ErrorMessage message={errors?.nom?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="prenom"
                        className="form-label form-label-modal-custom"
                      >
                        Prénom
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-modal-custom"
                        id="prenom"
                        placeholder="Prénom"
                        {...register("prenom")}
                      />
                      {errors?.prenom && (
                        <ErrorMessage message={errors?.prenom?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label form-label-modal-custom"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-modal-custom"
                        id="email"
                        placeholder="Email"
                        {...register("email")}
                      />
                      {errors?.email && (
                        <ErrorMessage message={errors?.email?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="phone"
                        className="form-label form-label-modal-custom"
                      >
                        Téléphone
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-modal-custom"
                        id="phone"
                        placeholder="Téléphone"
                        {...register("telephone")}
                      />
                      {errors?.telephone && (
                        <ErrorMessage message={errors?.telephone?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="adresse"
                        className="form-label form-label-modal-custom"
                      >
                        Adresse
                      </label>
                      <GoogleInput
                        className="form-control form-control-modal-custom form-control-params-custom"
                        value={address}
                        onChange={onChangeAddress}
                      />
                      <FormError error={errors?.adresse?.message} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="boite_postale"
                        className="form-label form-label-modal-custom"
                      >
                        Code postale
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-modal-custom"
                        id="boite_postale"
                        placeholder="Code postale"
                        {...register("code_postal")}
                      />
                      {errors?.code_postal && (
                        <ErrorMessage message={errors?.code_postal?.message} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="container-btn-modal row my-4">
                  <div className="col-md-6 offset-md-6">
                    <div className="d-flex gap-4">
                      <button
                        className="btn auth-submit-annuler"
                        onClick={() => navigate(-1)}
                      >
                        Annuler
                      </button>
                      <BtnSubmit
                        label={itemState ? "Modifier" : "Ajouter"}
                        isLoading={isLoading}
                      />
                      <ConfirmAddPersonnel
                        modalIsOpen={modalIsOpen}
                        setIsOpen={setIsOpen}
                        item={itemState}
                        personne={personne}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterUtilisateurPQGSB;
