import React, { useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { useGetUserByAdminQuery } from "../../../utils/api/admin/admin.api";
import { useGetAgencesQuery } from "../../../utils/api/agence/agence.api";
import { IUser } from "../../../utils/api/user/user.type";
import { getAvatar } from "../../../utils/Utils";

function NewDiscussionAdminModal({
  onSelect,
}: {
  onSelect: (user: IUser) => any;
}) {

  const [searchParams, setSearchParams] = useSearchParams();
	const name = searchParams.get("name");
	const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
		if (e.currentTarget.value) {
			setSearchParams({
				name: e.currentTarget.value,
			});
		} else {
			setSearchParams({});
		}
	};

  const { data, isLoading } = useGetUserByAdminQuery({name});

  return (
    <div className="modal-dialog">
      <div className="modal-content modal-content-message">
        <div className="title-modal-send-message px-2 mt-3">
          <div className="content-title-modal-send-message">
            <p className="mb-0 title-modal-send-message">Nouvelle discussion</p>
          </div>
          {/* <div className="content-btn-send-message">
          <button
            className="btn btn-send-message-modal disabled"
            onClick={openModal}
          >
            Suivant
          </button>
        </div> */}
        </div>
        <div className="pt-3">
          <div className="form-search-user-container position-relative">
            <input
              type="text"
              className="form-search-user form-control"
              placeholder="Rechercher des personnes"
              onChange={handleFilterByName}
            />
            <FiSearch
              style={{
                color: "#919EAB",
                fontSize: 22,
                position: "absolute",
                top: "25%",
                left: "2%",
              }}
            />
          </div>
        </div>
        <ul className="user-for-send-message-container mt-3  px-2">
          {isLoading && (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <FaSpinner />
            </div>
          )}
          {!isLoading &&
            data?.map((item: any) => (
              <li
                className="user-for-send-message  mb-3 px-3 py-1"
                // onClick={openModal}
                data-bs-dismiss="modal"
                key={item?.id}
                onClick={() => {
                // console.log("select item", item);
                  onSelect(item);
                  document
                    .getElementById("closeModal")
                    ?.dispatchEvent(new Event("click"));
                }}
              >
                <div className="d-flex align-items-center pb-1">
                  <BsFillPersonFill style={{ color: "#919EAB" }} />
                  <p className="mb-0 text-profil-user-modal">
                    {item?.user_type}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <img
                      src={getAvatar(item?.avatar)}
                      alt="user-avatar"
                      className="w-fluid img-profil-user-message"
                    />
                  </div>
                  <div className="user-for-send-message-infos">
                    <h3 className="mb-0">
                      {item?.prenom} {item?.nom}
                    </h3>
                    {/* <h4 className="mb-0">Online - Last seen, 2.02pm</h4> */}
                  </div>
                </div>
              </li>
            ))}
        </ul>
        {/* <MessageModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} /> */}
      </div>
    </div>
  );
}

export default NewDiscussionAdminModal;
