import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, useLocationState } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { INews, NewsFormData } from "../../../../../utils/api/new/news.type";
import { useAddOrUpdateNewMutation } from "../../../../../utils/api/new/news.api";
import { useGetTagsQuery } from "../../../../../utils/api/Tag/tag.api";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";

type SelectOptionType = { label: string; value: number };

export function useCrudNews() {
  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Titre"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
    control,
  } = useForm<NewsFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [addOrUpdateNews, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateNewMutation();
  const { data } = useGetTagsQuery({});

  const navigate = useNavigate();

  const [description, setDescription] = useState("");
  const [imageCouverture, setImageCouverture] = useState<string>("");
  const [tags, setTags] = useState<{ label: string; value: number }[]>([]);

  const currentNews = useLocationState<INews>(undefined);
  const { user: authUser } = useAppSelector((s) => s?.user);

  const onChangeDescription = (value: any) => {
    setDescription(value);
    setValue("content", value);
  };

  const onChangeTags = (selected: SelectOptionType[]) => {
    setTags(selected);
    setValue(
      "tag",
      selected?.map((item) => item?.value)
    );
  };

  const handleChangeImageCouverture = (e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img?.width <= 265 || img.height <= 375) {
        return setError("couverture", {
          message: `Veuillez uploader une image avec une taille 265x375`,
        });
      } else {
        setImageCouverture(URL.createObjectURL(file));
        setValue("couverture", file);
      }
    };
    // if (
    //   file?.type !== "image/jpeg" &&
    //   file?.type !== "image/png" &&
    //   file?.type !== "image/jpg" &&
    //   file?.type !== "image/gif" &&
    //   file?.type !== "image/tiff" &&
    //   file?.type !== "image/bmp"
    // ) {
    //   err = true;

    //   return setError("couverture", {
    //     message: "Le format de l'image est incorrect.",
    //   });
    // }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `News ${currentNews ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        reset();
        navigate("/admin/news");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (currentNews?.id) {
      setValue("titre", currentNews?.titre);
      setDescription(currentNews?.content);
      const tags = currentNews?.tag?.map((item) => {
        return { label: item?.nom, value: item?.id };
      });
      setTags(tags);
      setValue(
        "tag",
        currentNews?.tag?.map((item) => item?.id)
      );
    }
  }, [currentNews]);
  const onSubmit = (data: NewsFormData) => {
  // console.log("data", data);
    const newData = { ...data, auteur: authUser?.id };
    const fd = new FormData();
    for (let key of Object.keys(newData)) {
      let value = newData[key as keyof NewsFormData];
      if (Array.isArray(value) && value?.length) {
        for (let val of value) {
          fd.append(key, val);
        }
      } else {
        fd.append(key, value as keyof NewsFormData);
      }
    }
    addOrUpdateNews({ slug: currentNews?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    reset,
    description,
    onChangeDescription,
    handleChangeImageCouverture,
    imageCouverture,
    control,
    Controller,
    tagsList: data?.results,
    tags,
    onChangeTags,
    currentNews,
  };
}
