import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BsTrash } from "react-icons/bs";
import { IoMdSwitch } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetPersonnelByAgenceBySlugQuery } from "../../../../utils/api/agence/agence.api";
import { isProprietaireOwner } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import { UseDeletePersonnel } from "../../../TableauDebord/Agence/OptionAgence/requestForm/UseAddOrUpdateUserAgent";
import { GeneratePassword } from "../ProprietaireAgenceTable/ProprietaireAgenceTable";
import OptionUsersTableSkeleton from "./OptionUsersTableSkeleton";

function OptionUsersTable({nom}: {nom?: any}) {

	const { user } = useAppSelector((s) => s?.user);
    const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const agence = useAppSelector((s) => s.user.user);
	const { data = { results: [] }, isLoading } = useGetPersonnelByAgenceBySlugQuery({
	  slug: agence?.slug,
	  page,
	  limit: perPage,
	  nom,
	  type: user?.user_type
	});
  // console.log("donnée personnel", data)

	const nameFormatter = (cell: any, row: any) => {
		return (
			<div>{row?.prenom + " " + row?.nom}</div>
		);
	};


	const actionFormatter = (cell: any, row: any) => {
		return (
      <>
        <div className="table-actions-btn-container-commandes d-flex">
          {/* <div className="container-btn-action-icon-modal">
						<button
							className="btn btn-action-modal-icon with-tooltip"
                            // data-tooltip-content="Voir"
						>
							<IoMdSwitch />
						</button>
					</div> */}
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={
                isProprietaireOwner(user)
                  ? `/proprietaire/gerer-par-le-proprietaire/modifier-collaborateur/${cell}`
                  : "/agence/ajouter-utilisateur"
              }
              state={row}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
            >
              <MdModeEditOutline />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeletePersonnelBtn slug={row?.slug} />
          </div>
          <div className="container-btn-action-icon-modal">
            <GeneratePassword slug={row?.slug} />
          </div>
        </div>
      </>
    );
	};
	const columns = [
		{
			dataField: "nom",
			text: "Prénom & Nom ",
			formatter: (cell: any, row: any) => nameFormatter(cell, row),
		},
		{
			dataField: "profession",
			text: "Profession",
		},
		{
			dataField: "email",
			text: "Adresse mail",
		},
        {
			dataField: "telephone",
			text: "Téléphone",
		},
		{
			dataField: "slug",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <OptionUsersTableSkeleton />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvéé" />
						)}
					/>
					<CustomPagination
                        nbPages={data?.count}
                        page={page}
                        onChange={(page, perPage) => {
                            setPerPage(perPage);
                            setPage(page);
                        }}
                        perPage={perPage}
                    />
				</>
			)}
		</>
	);
}

export default OptionUsersTable;

function DeletePersonnelBtn({ slug }: { slug: string }) {
	const onDelete = UseDeletePersonnel(slug);

	return (
		<button
			className="btn btn-action-modal-icon with-tooltip"
			data-tooltip-content="Supprimer"
			onClick={() => onDelete()}
		>
			<BsTrash />
		</button>
		
	);
}