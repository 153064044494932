import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EtatLieuFormData, IEtatLieu } from "../../../../../../../utils/api/etatlieu/etatlieu.type";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { useAddOrUpdateEtatLieuMutation, useDeleteEtatLieuMutation } from "../../../../../../../utils/api/etatlieu/etatlieu.api";
import { Color } from "../../../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../../../utils/Utils";
function useCrudEtatLieuxLocataire(item?: IEtatLieu) {
    const validationSchema = yup.object().shape({
        document: yup.mixed().when("hasFile", {
          is: false,
          then: yup.mixed().required().label("Document"),
        }),
      });
    
      const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
        reset,
      } = useForm<EtatLieuFormData>({
        resolver: yupResolver(validationSchema),
      });
  
      const {user} = useAppSelector((s) => s.user);
// console.log('item', item)
  
  const [document, setDocument] = useState<File>();

  const handleChangeDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null) {
    // console.log(e.currentTarget.files[0]);
      setValue("document", e.currentTarget.files[0]);
      setDocument(e.currentTarget.files[0]);
    }
  };

  const [addOrUpdateEtatLieu, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateEtatLieuMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Etat de lieu ${item ? "modifiée" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        reset();
        setDocument(undefined)
        onHide("AjouterEtatLieuLocataireModal");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof EtatLieuFormData)[] = [
        "document",
      ];

      for (let field of fields) {
        setValue("document", item["document"])
        if (field === "document" && item["document"]) {
          setValue("hasFile", true);
        } else {
          setValue("hasFile", false);
        }
      }
    }
  }, [item]);
  const onSubmit = (data: EtatLieuFormData) => {
    user?.agence_id ? data['agence'] = user?.agence_id?.id : data['proprietaire'] = user?.proprietaire_id?.id
    data['propriete'] = user?.propriete?.id
    data['locataire'] = user?.id
    const fd = new FormData();
    
    for (let key of Object.keys(data)) {
      const val = data[key];

      if (key === "document") {
        if (val) {
          // fd.append(key, data[key]);
          fd.append(key, val);
        }
      } else {
        // fd.append(key, data[key]);
        fd.append(key, val);
      }
    }
  // console.log("data submit", data)
    addOrUpdateEtatLieu({ slug: item?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangeDocument,
    reset,
  };
}

export default useCrudEtatLieuxLocataire

export function UseDeleteEtatLieuLocataire(item: IEtatLieu) {
  const [deleteData] = useDeleteEtatLieuMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet état de lieu ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
    // console.log(result, "deleted");
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Etat de lieu supprimée avec succès!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}