import React from "react";
import ReactQuill from "react-quill";
import { IProbleme } from "../../../utils/api/probleme/probleme.type";
import { BtnCloseModal, BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import useResponseProbleme from "../../TableauDebord/Admin/Problèmes/useResponseProbleme";

function DetailProblemeModal({ item }: { item: IProbleme }) {
  const { errors, onSubmit, response, handleChangeMessage, isLoading } =
    useResponseProbleme(item);
  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <p><strong>Nom : </strong>{item?.prenom + " " + item?.nom}</p>
          <p><strong>Email : </strong>{item?.email}</p> 
          {/* <BtnCloseModal label="" /> */}
        </div>
        <div className="modal-header">
          <p><strong>Type : </strong>{item?.type_probleme.titre}</p>
          <p><strong>Inscrit sur la palateforme : </strong>{item?.inscrit ? "Oui" : "Non"}</p> 
        </div>
        
        <div className="form-container px-3">
          <div className="row border-bottom pb-2">
            <div className="col-md-12 form-group">
              <h2 className="modal-title-custom">Message</h2>
              <div className="admin-content-message">{item?.message}</div>
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <div className="row mb-4 mt-4">
              <div className="col-md-12 mb-3">
                <div className="mb-4 form-group">
                  <label className="form-label label-form-admin">Réponse</label>
                  {response?.length > 0 ? (
                    <ReactQuill
                      className="editor-cgu"
                      value={response}
                      onChange={handleChangeMessage}
                    />
                  ) : (
                    <ReactQuill
                      className="editor-cgu"
                      value={response}
                      onChange={handleChangeMessage}
                    />
                  )}
                  {errors?.response && <FormError error={errors?.response} />}
                </div>
              </div>
            </div>
            <div className="custom-container-btn-modal">
              <div className="">
                <button
                  className="btn btn-cancelled"
                  data-bs-dismiss="modal"
                  type="button"
                >
                  Retour
                </button>
              </div>
              <div className="">
                <BtnSubmit label="Envoyer" isLoading={isLoading} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DetailProblemeModal;
