import React from "react";
import UseSendAmount from "../request/UseSendAmount";
import { BtnCloseModal, BtnSubmit } from "../../../../common/Button";
import { getName } from "../../../../../utils/Utils";
import { Input } from "../../../../common/Input";
import ReactQuill from "react-quill";
import { FormError } from "../../../../common/CustomInputCheckbox";


function SendPaymentModal({
  modalId,
  item,
}: {
  modalId: string;
  item: any;
}) {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    description,
    onChangeDescription,
    handleReset,
  } = UseSendAmount(item, modalId);
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header px-2 py-1">
            <h5 className="modal-title" id="AddTagModalLabel">
              Transfèrer à {item?.nom_agence || getName(item)}
            </h5>
            <BtnCloseModal label="" onClick={() => handleReset()} />
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit}>
              <div className="row py-4 px-3">
                <div className="col-md-12 mb-3 ">
                  <Input
                    label="Montant"
                    id="min"
                    min={0}
                    type="number"
                    required
                    placeholder="Montant à transfèrer"
                    {...register("montant")}
                    error={errors?.montant?.message}
                  />
                </div>

                <div className="col-md-12 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor={"description"}
                      className="form-label form-label-modal-custom"
                      aria-labelledby={"description"}
                    >
                      Description
                    </label>
                    <ReactQuill
                      className="editor-cgu"
                      value={description}
                      onChange={(value: string) => onChangeDescription(value)}
                    />

                    {<FormError error={errors?.description?.message} />}
                  </div>
                </div>

                <div className="flex-r">
                  <BtnSubmit
                    label={"Envoyer"}
                    isLoading={isLoading}
                    style={{ padding: "5px 10px", fontSize: 14 }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendPaymentModal;
