import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { ChangeEvent, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  cleannerError,
  closeModal,
  isProprietaireOwner,
} from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { AbonnementFormData } from "../../../../../utils/api/user/user.type";
import { useCreateAbonnementFreeMutation, useGetOffersQuery } from "../../../../../utils/api/offer/offer.api";
import { Offer } from "../../../../../utils/api/offer/offer.type";
import { useGetAgencesQuery } from "../../../../../utils/api/agence/agence.api";
import { useGetProprietaireQuery } from "../../../../../utils/api/proprietaire/proprietaire.api";
import { useDeleteOneSubscriberMutation } from "../../../../../utils/api/subscriber/subscriber.api";
import {SubscriberFormaData } from "../../../../../utils/api/subscriber/subscriber.type";



function UseAddAbonnementForm(modalId: string) {
  const validationSchema = yup.object().shape(
    {
      offre: yup
        .number()
        .required()
        .label("L'offre")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
      user: yup
        .number()
        .required()
        .label("L'utilisateur")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),

      type: yup.string().required().label("Le type").nullable(),
    },
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm<AbonnementFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { data = { results: [] } } = useGetOffersQuery({ limit: 1000 });

  const [sendData, { isLoading, isSuccess, isError, error }] =
    useCreateAbonnementFreeMutation();
  const [type, setType] = useState("");
  const [offres, setOffres] = useState<Offer[]>([]);
  const [options, setOptions] = useState<{ label: string; value: any }[]>([]);
  const [option, setOption] = useState<any>();
  const [word, setWord] = useState("");
  const { data: agences = { results: [] } } = useGetAgencesQuery({
    word,
    limit: 20,
  });
  const { data: proprietaires = { results: [] } } = useGetProprietaireQuery({
    word,
    // proprio: "proprietairesqgb",
    limit: 100,
  });

  useEffect(() => {
    if (type === "agence" && agences?.results?.length) {
      setOptions(
        agences?.results?.map((item) => {
          return {
            label: item?.nom_agence || item?.prenom + " " + item?.nom,
            value: item?.id,
          };
        })
      );
    }
    console.log("proprietaire", proprietaires);
    if (type === "proprietaire" && proprietaires?.results?.length) {
      setOptions(
        proprietaires?.results
          ?.filter((el) => isProprietaireOwner(el))
          ?.map((item) => {
            return {
              label: item?.prenom + " " + item?.nom,
              value: item?.id,
            };
          })
      );
    }
  }, [type, agences, proprietaires]);

  useEffect(() => {
    if (data?.results?.length) {
      setOffres(
        data?.results?.filter((item) => item?.type === type && item?.gratuit)
      );
    }
  }, [type, data]);

  const handleReset = () => {
    setType("");
    reset();
    setOption(undefined);
    setWord("");
  };

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    // setType(e?.target?.value);
    setType((prev) => {
      if (prev !== e?.target?.value) {
        setWord("");
        setOption(null);
        setValue("user", NaN);
      }
      return e?.target?.value;
    });
    setValue("type", e?.target?.value);
  };

  const onSearchItem = (val: string) => {
    setWord(val);
  };

  const handleSelect = (val: { label: string; value: any }) => {
    setValue("user", val?.value);
    setOption(val);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `L'abonnement a été créé avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        closeModal(modalId);
        handleReset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: any) => {
    console.log("data", data);
    let fd = new FormData()

    fd.append("user",data?.user)
    fd.append("offre",data?.offre)
    await sendData(fd);
  };
  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    handleReset,
    type,
    handleChange,
    offres,
    handleSelect,
    onSearchItem,
    option,
    options,
    control,
    Controller,
  };
}

export default UseAddAbonnementForm;

export function UseDeleteSubscriber(item: SubscriberFormaData) {
  const [deleteData] = useDeleteOneSubscriberMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet abonement?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'offre a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
