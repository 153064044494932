import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, closeModal, onHide } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import {
  ITypeProbleme,
  TypeProblemeFormData,
} from "../../../../../utils/api/typeProbleme/typeProbleme.type";
import { useAddOrUpdateTypeProblemeMutation } from "../../../../../utils/api/typeProbleme/typeProbleme.api";

export function useCrudTypeProbleme(item?: ITypeProbleme) {
  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Titre"),
    description: yup.string().label("Description"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<TypeProblemeFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [addOrUpdateTypeProbleme, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateTypeProblemeMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Type ${item ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        closeModal(
          item ? `AddTypeProblemeModal${item?.slug}` : "AddTypeProblemeModal"
        );
        if (!item) {
          reset();
        }
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      setValue("titre", item?.titre);
      setValue("description", item?.description);
    }
  }, [item]);
  const onSubmit = (data: TypeProblemeFormData) => {
    console.log("data", data);
    addOrUpdateTypeProbleme({ slug: item?.slug, data: data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    reset,
  };
}
