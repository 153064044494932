import React from 'react'
import Skeleton from 'react-loading-skeleton';

function NoteSkeleton() {
  return (
    <>
      {Array(12)
        .fill(12)
        .map((shopCard, i) => (
          <div className="col-md-3 mb-3" key={i}>
            <div style={{ position: "relative" }}>
              <div className="card custom-card-container">
                <Skeleton className="custom-card-date" width={`60%`} />
                <div className="custom-card-titre-container mb-0 d-flex align-items-baseline">
                  <Skeleton width={10} height={10} circle />
                  <Skeleton className="custom-card-titre" height={15} width={`13vw`} />
                </div>
               
                  <Skeleton
                    className="custom-img-annonce"
                  />
               
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

export default NoteSkeleton