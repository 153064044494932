import React from 'react'
import { useAppSelector } from '../../../../../../redux/hooks';
import { isProprioJuriste } from '../../../../../../utils/Utils';
import { AlertInfo } from '../../../../../common/Alert';
import EtatLieuxTablePQGSB from '../../../../../tables/Proprietaire/ProprietaireGererParProprietaire/Administratif/EtatLieux/EtatLieuxTablePQGSB'

function EtatDesLieux({searchParams, setSearchParams}: {searchParams: any, setSearchParams: any}) {
    const { user } = useAppSelector((s) => s?.user);
    const word = searchParams.get("name")
    const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
        if (e.currentTarget.value) {
            setSearchParams({
                name: e.currentTarget.value,
            });
        } else {
            setSearchParams({});
        }
    }
    return (
      <div className="bg-white p-3">
        <div className="locataire-requete-filtre-container my-4">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                placeholder="Entrer un nom"
                onChange={handleFilterByName}
              />
            </div>
          </form>
        </div>
        <div>
          {!!isProprioJuriste(user) ? (
            <AlertInfo message="Vous n'avez pas accès au données de cette section !" />
          ) : (
            <EtatLieuxTablePQGSB name={word} />
          )}
        </div>
      </div>
    );
}

export default EtatDesLieux