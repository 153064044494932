import React, { useEffect, useRef, useState } from "react";
import "./DashboardPQNGPSB.css";
import LineChart from "./LineChart/LineChart";
import "./LineChart/LineChart.css";

import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import "../../ProprietaireGererParLeProprietaire/DashboardPQGSB/React_dates_overrides.css";

import Key from "../../../../../assets/icons/key.png";
import Users from "../../../../../assets/icons/users.png";
import Paper from "../../../../../assets/icons/paper.png";
import ApercuChart from "./LineChart/ApercuChart";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetInfosByProprietaireqnpgbQuery } from "../../../../../utils/api/proprietaire/proprietaire.api";
import { IFiltreAgenceData } from "../../../../../utils/api/user/user.type";
import { useFiltreAgenceDataMutation } from "../../../../../utils/api/agence/agence.api";
import { formatMontant } from "../../../../../utils/Utils";

function DashboardPQNGPSB() {
  var [showNotifications, setShowNotifications] = React.useState(false);

  const user = useAppSelector((s) => s.user.user);

  const { data } = useGetInfosByProprietaireqnpgbQuery({
    slug: user?.slug,
  });

  const [filtreAgenceData] = useFiltreAgenceDataMutation();

  const [query, setQuery] = useState<IFiltreAgenceData>({
    date_du: "",
    date_au: "",
  });

  const onQueryChange = (
    field: keyof IFiltreAgenceData,
    value: string | number
  ) => {
    setQuery((old) => ({ ...old, [field]: value }));
  };

  const [donneesFiltrees, setDonneesFiltrees] = useState<any>(null);

  useEffect(() => {
    updateDonnees();
  }, [donneesFiltrees]);

  const updateDonnees = async () => {
    try {
      if (donneesFiltrees) {
        console.log({ donneesFiltrees });
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des données:", error);
    }
  };

  const handleSubmit = async (data: IFiltreAgenceData) => {
    try {
      const newData = await filtreAgenceData({
        data: data,
        type: "proprietaireqngb",
        slug: user.slug,
      });

      // Mettre à jour l'état 'donneesFiltrees' avec les données filtrées
      setDonneesFiltrees(newData);
      console.log({ donneesFiltrees });
    } catch (error) {
      console.error("Erreur lors du traitement des données filtrées:", error);
    }
  };

  // console.log("données", data);
  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    if (query?.date_du != "" && query?.date_au != "") {
      handleSubmit(query);
    }
  }, [data, query]);

  return (
    <div className="dashboard-admin-component">
      <div className="row container-dashboard-page">
        <div className="col-xl-8 col-lg-12 col-md-12 pb-3 col-right-dashboard-view">
          <div className="col-left-dashboard col-left-dashboard-responsive custom-col-left-dashboard-responsive mb-3">
            <div className="row px-md-2">
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Key}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Total des biens
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          {/* Cette semaine */}
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.total_bien}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Users}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Total des agences
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          {/* Cette semaine */}
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.total_agence}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Paper}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Total des locataire
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          {/* Cette semaine */}
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.total_locataire}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-3 mt-4">
              <LineChart />
            </div>
          </div>
          {/* <div className="col-left-dashboard mb-3">
            <ApercuChart />
          </div> */}
        </div>
        <div className="col-xl-4 col-lg-12 col-md-12 pb-3 col-right-dashboard-view">
          <div className="col-left-dashboard mb-3">
            <div className="content-info-dashboard-view">
              {/* Ma formulaire pour filtre */}

              <div className="custom-date-range border-0 mb-3">
                <DateRange
                  start={query.date_du}
                  end={query.date_du}
                  onChange={(start, end) => {
                    onQueryChange("date_du", start);
                    onQueryChange("date_au", end);
                  }}
                />
              </div>

              {/* Ma formulaire pour filtre FIN */}

              <div className="content-info-item mb-3">
                <div className="content-title-info-dashboard-view">
                  <p className="title-info-dashboard-view custom-title-info-dashboard-view">
                    Total loyers recouvrés{" "}
                  </p>
                </div>
                <div className="d-flex justify-content-end content-text-info-dashboard-view">
                  <p className="text-chiffre-info-dashboard-view mb-0">
                    {donneesFiltrees && query.date_du && query.date_au
                      ? formatMontant(
                          donneesFiltrees?.data?.total_loyer_recouvres
                        )
                      : formatMontant(data?.total_loyer_recouvres)}
                  </p>
                </div>
              </div>
              <div className="content-info-item mb-3">
                <div className="content-title-info-dashboard-view">
                  <p className="title-info-dashboard-view custom-title-info-dashboard-view">
                    Total loyers non recouvrés{" "}
                  </p>
                </div>
                <div className="d-flex justify-content-end content-text-info-dashboard-view">
                  {/* <p className="text-date-info-dashboard-view custom-text-date-info-dashboard-view  mb-0">
                    Le mois dernier
                  </p> */}
                  <p className="text-chiffre-info-dashboard-view mb-0">
                    {donneesFiltrees && query.date_du && query.date_au
                      ? formatMontant(
                          donneesFiltrees?.data?.total_loyer_non_recouvres
                            .montant_total_impayes
                        )
                      : formatMontant(
                          data?.total_loyer_non_recouvres.montant_total_impayes
                        )}
                  </p>
                </div>
              </div>
              <div className="content-info-item mb-3">
                <div className="content-title-info-dashboard-view">
                  <p className="title-info-dashboard-view custom-title-info-dashboard-view">
                    Revenu à date{" "}
                  </p>
                </div>
                <div className="d-flex justify-content-end content-text-info-dashboard-view">
                  {/* <p className="text-date-info-dashboard-view custom-text-date-info-dashboard-view mb-0">
                    D'aujourd’hui
                  </p> */}
                  <p className="text-chiffre-info-dashboard-view mb-0">
                    {donneesFiltrees && query.date_du && query.date_au
                      ? formatMontant(donneesFiltrees?.data?.revenus)
                      : formatMontant(data?.revenus)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPQNGPSB;

type DateRangeProps = {
  onChange: (start: string, end: string) => any;
  start?: string | null;
  end?: string | null;
  isDayBlocked?: (day: moment.Moment) => boolean;
  isOutsideRange?: (day: moment.Moment) => boolean;
  labels?: [string | undefined, string | undefined];
};
const isSame = (d1: moment.Moment, d2: moment.Moment) =>
  d1.format("L") === d2.format("L");
export function DateRange({
  start,
  end,
  onChange,
  isDayBlocked,
  labels,
  isOutsideRange,
}: DateRangeProps) {
  const [blockeds, setBlockeds] = useState<moment.Moment[]>([]);
  const [startDateObj, setStartDateObj] = useState<moment.Moment | null>(null);
  const [reload, setReload] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(start ? moment(start) : null);
  const [endDate, setEndDate] = useState<any>(end ? moment(end) : null);
  const [focusedInput, setFocusedInput] = useState<
    "startDate" | "endDate" | null
  >(null);
  const outside = (day: moment.Moment) => {
    return day < moment();
  };
  const isOutside = (day: moment.Moment) => {
    const ok = outside(day);
    if (isOutsideRange) return ok && isOutsideRange(day);
    return ok;
  };
  const blocked = (day: moment.Moment) => {
    if (isDayBlocked) {
      const bool = isDayBlocked(day);
      if (bool) {
        setBlockeds((old) => {
          const index = old.findIndex((b) => isSame(b, day));
          if (index === -1) return [...old, day];
          return old;
        });
      }
      return bool;
    }
    return false;
  };

  const ref = useRef();
  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="startDate"
      endDate={endDate}
      ref={ref}
      endDateId="endDate"
      startDatePlaceholderText={(labels && labels[0]) || "Du"}
      endDatePlaceholderText={(labels && labels[1]) || "Au"}
      isDayBlocked={blocked}
      onDatesChange={({
        startDate,
        endDate,
      }: {
        startDate: any;
        endDate: any;
      }) => {
        onChange(
          startDate ? moment(startDate).format("YYYY-MM-DD") : "",
          endDate ? moment(endDate).format("YYYY-MM-DD") : ""
        );
        setStartDate(startDate);
        setEndDate(endDate);
      }}
      focusedInput={focusedInput}
      showClearDates={true}
      onFocusChange={setFocusedInput}
      initialVisibleMonth={() => (start ? moment(start) : moment())}
      isOutsideRange={() => false}
    />
  );
}
