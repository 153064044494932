/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetComptabiliteByAgenceBySlugQuery } from "../../../../utils/api/agence/agence.api";
import { formatMontant, formattedDate, getName } from "../../../../utils/Utils";
import DepenseComptabiliteTableSkeleton from "./DepenseComptabiliteTableSkeleton";
import paginationFactory from "react-bootstrap-table2-paginator";
import DetailsEntreeModal from "../../../modal/agence/DetailsEntreeModal";
import AddSortieModal from "../../../TableauDebord/Agence/ComptabiliteAgence/AddSortieModal";
import { MdEdit } from "react-icons/md";
import DetailsDepenseModal from "../../../modal/agence/DetailsDepenseModal";
import { IDepense } from "../../../../utils/api/depense/depense.type";
import { useDelete } from "../../../../utils/helpers";
import { useDeleteDepenseMutation } from "../../../../utils/api/depense/depense.api";
import { IoMdTrash } from "react-icons/io";

function DepenseComptabiliteTable() {
  const { user } = useAppSelector((s) => s?.user);

  const { data: compta = { depenses: [] }, isLoading } =
    useGetComptabiliteByAgenceBySlugQuery({
      slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
    });

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              data-bs-toggle="modal"
              data-bs-target={`#EditSortieModal${row?.slug}`}
            >
              <MdEdit />
            </button>
          </div>

          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              data-bs-toggle="modal"
              data-bs-target={`#depenseDetailsModal${row?.slug}`}
            >
              <AiFillEye />
            </button>
            {/* <DeleteDepense item={row} /> */}
          </div>
        </div>

        <div
          className="modal fade"
          id={`depenseDetailsModal${row?.slug}`}
          aria-labelledby={`depenseDetailsModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <DetailsDepenseModal item={row} />
        </div>
        <div
          className="modal fade"
          id={`EditSortieModal${row?.slug}`}
          aria-labelledby="AddSortieModalLabel"
          aria-hidden="true"
        >
          <AddSortieModal
            modalId={`EditSortieModal${row?.slug}`}
            depense={row}
          />
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "numero",
      text: "N°",
      formatter: (cell: any, row: any, rowIndex: number) => `#${rowIndex + 1}`,
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: any, row: any) => formattedDate(cell),
    },
    {
      dataField: "fullname",
      text: "Client",
      formatter: (cell: any, row: any) =>
        row?.locataire ? getName(row?.locataire) : row?.nom_proprieta,
    },
    {
      dataField: "montant",
      text: "Montant",
      formatter: (cell: any, row: any) => formatMontant(cell),
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      // headerStyle: () => {
      // 	return { width: "130px" };
      // },
    },
  ];

  return (
    <>
      {isLoading && <DepenseComptabiliteTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            // data={compta?.depenses}
            data={compta?.depenses
              ?.slice()
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table fw-bold"
            noDataIndication={() => "Aucune donnée disponible"}
            pagination={paginationFactory()}
          />
        </>
      )}
    </>
  );
}

export default DepenseComptabiliteTable;

export function DeleteDepense({ item }: { item: IDepense }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteDepenseMutation();
  const onDelete = useDelete<IDepense>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cette dépense?",
    successMessage: "dépense supprimée",
  });
  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <IoMdTrash />
    </button>
  );
}
