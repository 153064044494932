import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import EtatDesLieuxSkeleton from "./EtatDesLieuxSkeleton";
import { MdEdit } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import {
  formattedDate,
  isAgenceAgent,
  isAgenceJuriste,
  isAgenceMarketeur,
} from "../../../../../utils/Utils";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { AlertInfo } from "../../../../common/Alert";
import { useGetEtatLieuByAgenceQuery } from "../../../../../utils/api/etatlieu/etatlieu.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { HiDocumentDownload } from "react-icons/hi";
import { IEtatLieu } from "../../../../../utils/api/etatlieu/etatlieu.type";
import { UseDeleteEtatLieu } from "../../../../TableauDebord/Agence/Administratif/requestForm/useCrudEtatLieu";
import ViewDocModal from "../../../../modal/agence/ViewDocModal";
import AjouterEtatLieuModal from "../../../../modal/agence/AjouterEtatLieuModal";
import { CustomPagination } from "../../../../common/CustomPagination";

function EtatDesLieuxTable({
  locataire,
  propriete,
  AgenceSlug,
  isAgence,
}: {
  locataire?: any;
  propriete?: any;
  AgenceSlug?: string;
  isAgence?: boolean;
}) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const agence = useAppSelector((s) => s.user.user);
  const { user } = useAppSelector((s) => s.user);
  const { data = { results: [] }, isLoading } = useGetEtatLieuByAgenceQuery({
    slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
    page: page,
    limit: perPage,
    locataire,
    propriete,
  });
  const [item, setItem] = useState<IEtatLieu>();

  const actionFormatter: any = (cell: any, row: IEtatLieu) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes gap-2 d-flex">
          <button
            className="btn btn-action-modal-icon with-tooltip"
            data-tooltip-content="Modifier"
            data-bs-toggle="modal"
            data-bs-target="#editEtatLieuModal"
            onClick={() => setItem(row)}
          >
            <MdEdit />
          </button>
          <DeleteEtatdeLieu item={row} />
        </div>
        <AjouterEtatLieuModal item={row} modalId="editEtatLieuModal" />
      </>
    );
  };

  const documentFormatter: any = (cell: any, row: IEtatLieu) => {
    return (
      <div
        role="button"
        className="ps-3"
        data-bs-toggle="modal"
        data-bs-target="#ViewDocModal"
        onClick={() => setItem(row)}
      >
        <HiDocumentDownload style={{ color: "#1F3CAD", fontSize: 24 }} />
      </div>
    );
  };

  const columns = [
    {
      dataField: "created_at",
      text: "Date",
      style: { textAlign: "left" },
      formatter: (cell: string) => formattedDate(cell),
    },
    {
      dataField: "locataire",
      text: "Client",
      style: { textAlign: "left" },
      formatter: (cell: ILocataire) => cell?.prenom + " " + cell?.nom,
    },

    {
      dataField: "propriete",
      text: "Location",
      style: { textAlign: "left" },
      formatter: (cell: IPropriete) => cell?.nom,
    },
    {
      dataField: "document",
      text: "Document",
      formatter: (cell: any, row: IEtatLieu) => documentFormatter(cell, row),
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: IEtatLieu) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <EtatDesLieuxSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results
              ?.slice()
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message=" Aucune donnée trouvée " />
            )}
          />
          <CustomPagination
            nbPages={1}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
      <ViewDocModal item={item} />
      <AjouterEtatLieuModal modalId="AjouterEtatLieuModal" />
    </>
  );
}

export default EtatDesLieuxTable;

function DeleteEtatdeLieu({ item }: { item: IEtatLieu }) {
  const deleteEtatdeLieu = UseDeleteEtatLieu(item);
  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={deleteEtatdeLieu}
    >
      <IoMdTrash />
    </button>
  );
}
