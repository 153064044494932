import React from "react";
import { IDepense } from "../../../utils/api/depense/depense.type";
import { ApiBaseUrl } from "../../../utils/http";
import { getImage } from "../../../utils/Utils";
import { BtnCloseModal } from "../../common/Button";

function DetailDepenseModal({ depense }: { depense: IDepense }) {
  return (
    <div
      className="modal fade"
      id={`DetailDepenseModal${depense.slug}`}
      aria-labelledby="DetailDepenseModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontSize: 22 }}>
              Détail entrée
            </h5>
            <BtnCloseModal label="" />
          </div>
          <div className="modal-body">
            <div className="container-form">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3 flex-sb">
                    <p className="form-label form-label-modal-custom">
                      Client :{" "}
                    </p>
                    <p className="text-value-entree-comptale">
                      {depense?.occupant?.prenom +
                        "  " +
                        "  " +
                        depense?.occupant?.nom}
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 flex-sb">
                    <p className="form-label form-label-modal-custom">
                      Catégorie :{" "}
                    </p>
                    <p className="text-value-entree-comptale">
                      {depense?.categorie?.nom}
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 flex-sb">
                    <p className="form-label form-label-modal-custom">
                      Villa/Appartement loué :{" "}
                    </p>
                    <p className="text-value-entree-comptale">
                      {depense?.propriete?.nom}
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 flex-sb">
                    <p className="form-label form-label-modal-custom">
                      Montant :{" "}
                    </p>
                    <p className="text-value-entree-comptale">
                      {depense?.montant} FCFA
                    </p>
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <div className="mb-3 flex-sb">
                    <p className="form-label form-label-modal-custom">
                      Prise en charge :{" "}
                    </p>
                    <p className="text-value-entree-comptale">
                      {depense?.charge === "agence"
                        ? "Agence"
                        : depense?.charge === "locataire"
                        ? "Locataire"
                        : "Propriétaire"}
                    </p>
                  </div>
                </div> */}
                {!!depense?.specification && (
                  <div className="col-md-12">
                    <div className="mb-3">
                      <p className="form-label form-label-modal-custom">
                        Spécifications :{" "}
                      </p>
                      <p className="text-value-entree-comptale text-start">
                        {depense?.specification}
                      </p>
                    </div>
                  </div>
                )}
                {!!depense?.justificatif && (
                  <div className="detail-facture-infos-container mb-3 row text-start">
                    <p className="form-label form-label-modal-custom mb-2 col-md-12">
                      Justificatif
                    </p>
                    <div className="detail-facture-infos-value">
                      <iframe
                        src={ApiBaseUrl + depense?.justificatif}
                        frameBorder="0"
                        width={`100%`}
                        height={500}
                        allowFullScreen
                        seamless
                        title="doc"
                      ></iframe>
                    </div>
                  </div>
                )}
                {/* <div className="col-md-12">
                  <div className="mb-3">
                    <p className="form-label form-label-modal-custom mb-2">
                      Justificatif
                    </p>
                    <div>
                      {depense?.justificatif?.includes(".pdf") ? (
                        <iframe
                          src={`http://docs.google.com/gview?url=${
                            ApiBaseUrl + depense?.justificatif
                          }&embedded=true`}
                          width="100%"
                          height="500px"
                        ></iframe>
                      ) : (
                        <img
                          src={getImage(depense?.justificatif)}
                          alt="profil"
                          className="w-100"
                        />
                      )}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailDepenseModal;
