import React from "react";
import Avatar from "../../../assets/appImages/avatar.png";
import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { BsEmojiWink, BsFillMicFill } from "react-icons/bs";
import { HiOutlineCamera } from "react-icons/hi";
import { FiPaperclip } from "react-icons/fi";
import { IoClose, IoHomeSharp } from "react-icons/io5";
import { CustomInputCheckbox } from "../../common/CustomInputCheckbox";
import airconditioning from "../../../assets/icons/airconditioning.png";
import kitchencooker from "../../../assets/icons/kitchencooker.png";
import runningwater from "../../../assets/icons/runningwater.png";
import { useNavigate } from "react-router-dom";

function EtatLieuxModal() {
  const navigate = useNavigate();

  return (
    <div className="modal-dialog modal-dialog-centered modal-xl">
      <div className="modal-content custom-modal-etat-lieux">
        <div className="modal-header">
          <h5 className="modal-title"></h5>
          <button
            className="close-modal auth-submit-annuler"
            data-bs-dismiss="modal"
            type="button"
          >
            <IoClose />
          </button>
        </div>
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <div className="kanimmo-modal-etat-lieux-titre-container">
                <h4 className="kanimmo-modal-etat-lieux-titre">
                  état des lieux
                </h4>
                <div className="kanimmo-modal-etat-lieux-input-entre-container">
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox">
                    <label
                      className="form-check-label form-label-login ms-2 custom-form-label-login"
                      htmlFor="flexCheckDefault1"
                    >
                      Entrée
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      value="oui"
                      name="notif"
                      id="flexCheckDefault1"
                    />
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox">
                    <label
                      className="form-check-label form-label-login ms-2 custom-form-label-login"
                      htmlFor="flexCheckDefault2"
                    >
                      Sortie
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      value="oui"
                      name="notif"
                      id="flexCheckDefault2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="kanimmo-modal-etat-lieux-titre-container-right">
                <div className="custom-input-etat-lieux-date-entre-container">
                  <label
                    htmlFor="nom"
                    className="form-label form-label-modal-custom"
                  >
                    Date d’entrée:
                  </label>
                  <input
                    type="text"
                    className="form-control custom-input-etat-lieux-date-entre"
                    id="nom"
                    placeholder="../../.."
                  />
                </div>
                <div className="custom-input-etat-lieux-date-entre-container">
                  <label
                    htmlFor="nom"
                    className="form-label form-label-modal-custom"
                  >
                    Date de sortie:
                  </label>
                  <input
                    type="text"
                    className="form-control custom-input-etat-lieux-date-entre"
                    id="nom"
                    placeholder="../../.."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <p className="kanimmo-modal-etat-lieux-description-paragraph">
                L’état des lieux doit être établi entre les deux parties
                (locataire et propriétaire) lors de la remise des clés au
                locataire et lors de leur restitution en fin de bail. Selon
                l’article 3 de la Loi n°89-462 du 6 juillet 1989, l’état des
                lieux doit porter sur l’ensemble des locaux et équipements
                d’usage privatif mentionnés au contrat de location, et dont le
                locataire a la jouissance exclusive.
              </p>
            </div>
            <div className="mb-3 kanimmo-modal-etat-lieux-logement-container">
              <div className="kanimmo-modal-etat-lieux-logement-row-container mb-3">
                <div className="d-flex gap-2 align-items-center">
                  <IoHomeSharp className="kanimmo-modal-etat-lieux-logement-titre-icon" />
                  <div className="kanimmo-modal-etat-lieux-logement-titre">
                    LOGEMENT
                  </div>
                </div>
                <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox">
                  <label
                    className="form-check-label form-label-login ms-2 custom-form-label-login"
                    htmlFor="flexCheckDefault3"
                  >
                    Appartement:
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="appartement:"
                    name="notif"
                    id="flexCheckDefault3"
                  />
                </div>
                <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox">
                  <label
                    className="form-check-label form-label-login ms-2 custom-form-label-login"
                    htmlFor="flexCheckDefault4"
                  >
                    Maison:
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="appartement:"
                    name="notif"
                    id="flexCheckDefault4"
                  />
                </div>
                <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox">
                  <label
                    className="form-check-label form-label-login ms-2 custom-form-label-login"
                    htmlFor="flexCheckDefault5"
                  >
                    Autre:
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="appartement:"
                    name="notif"
                    id="flexCheckDefault5"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="custom-input-etat-lieux-date-entre-container">
                    <label
                      htmlFor="nom"
                      className="form-label form-label-modal-custom"
                    >
                      SURFACE:
                    </label>
                    <input
                      type="text"
                      className="form-control custom-input-etat-lieux-date-entre2"
                      id="nom"
                      placeholder="................."
                    />
                    <span className="">m2</span>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="custom-input-etat-lieux-date-entre-container-except">
                    <label
                      htmlFor="nom"
                      className="form-label form-label-modal-custom"
                    >
                      NOMBRE DE PIECES PRINCIPALES:
                    </label>
                    <input
                      type="text"
                      className="form-control custom-input-etat-lieux-date-entre2"
                      id="nom"
                      placeholder="..............................................................................."
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="custom-input-etat-lieux-date-entre-container-except">
                    <label
                      htmlFor="nom"
                      className="form-label form-label-modal-custom"
                    >
                      ADRESSE:
                    </label>
                    <textarea
                      className="form-control input-group-personnalisation custom-input-etat-lieux-date-entre2"
                      rows={4}
                      placeholder=".........................................................................................................................................................................................................................................................................................................................................................................................................................................................................................."
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <h4 className="kanimmo-modal-etat-lieux-bailleur-titre">
                LE BAILLEUR (OU SON MANDATAIRE)
              </h4>
              <div className="d-flex justify-content-between">
                <label
                  htmlFor=""
                  className="custom-form-label-etat-lieux ms-2 "
                >
                  CIVILITé:
                </label>
                <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox">
                  <label
                    className="form-check-label form-label-login ms-2 custom-form-label-login"
                    htmlFor="flexCheckDefaultMr"
                  >
                    Mr
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="appartement:"
                    name="notif"
                    id="flexCheckDefaultMr"
                  />
                </div>
                <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox">
                  <label
                    className="form-check-label form-label-login ms-2 custom-form-label-login"
                    htmlFor="flexCheckDefaultMme"
                  >
                    Mme
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="appartement:"
                    name="notif"
                    id="flexCheckDefaultMme"
                  />
                </div>
                <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox">
                  <label
                    className="form-check-label form-label-login ms-2 custom-form-label-login"
                    htmlFor="flexCheckDefaultMlle"
                  >
                    Mlle
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="appartement:"
                    name="notif"
                    id="flexCheckDefaultMlle"
                  />
                </div>
              </div>
              <div className="custom-input-etat-lieux-date-entre-container-except">
                <label
                  htmlFor="prenom"
                  className="form-label custom-form-label-etat-lieux ms-2"
                >
                  PRéNOM:
                </label>
                <input
                  type="text"
                  className="form-control custom-input-etat-lieux-date-entre2"
                  id="prenom"
                  placeholder="..............................................................................."
                />
              </div>
              <div className="custom-input-etat-lieux-date-entre-container-except">
                <label
                  htmlFor="nom"
                  className="form-label custom-form-label-etat-lieux ms-2"
                >
                  NOM:
                </label>
                <input
                  type="text"
                  className="form-control custom-input-etat-lieux-date-entre2"
                  id="nom"
                  placeholder="..............................................................................."
                />
              </div>
              <div className="custom-input-etat-lieux-date-entre-container-except">
                <label
                  htmlFor="adress"
                  className="form-label custom-form-label-etat-lieux ms-2"
                >
                  ADRESSE:
                </label>
                <textarea
                  id="adress"
                  className="form-control input-group-personnalisation custom-input-etat-lieux-date-entre2"
                  rows={4}
                  placeholder=".........................................................................................................................................................................................................................................................................................................................................................................................................................................................................................."
                ></textarea>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <h4 className="kanimmo-modal-etat-lieux-bailleur-titre">
                LE ( S) LOCATAIRE(S)
              </h4>
              <div className="d-flex justify-content-between">
                <label
                  htmlFor=""
                  className="custom-form-label-etat-lieux ms-2 "
                >
                  CIVILITé:
                </label>
                <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox">
                  <label
                    className="form-check-label form-label-login ms-2 custom-form-label-login"
                    htmlFor="flexCheckDefaultMrloc"
                  >
                    Mr
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="appartement:"
                    name="notif"
                    id="flexCheckDefaultMrloc"
                  />
                </div>
                <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox">
                  <label
                    className="form-check-label form-label-login ms-2 custom-form-label-login"
                    htmlFor="flexCheckDefaultMmeloc"
                  >
                    Mme
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="appartement:"
                    name="notif"
                    id="flexCheckDefaultMmeloc"
                  />
                </div>
                <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox">
                  <label
                    className="form-check-label form-label-login ms-2 custom-form-label-login"
                    htmlFor="flexCheckDefaultMlleloc"
                  >
                    Mlle
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="appartement:"
                    name="notif"
                    id="flexCheckDefaultMlleloc"
                  />
                </div>
              </div>
              <div className="custom-input-etat-lieux-date-entre-container-except">
                <label
                  htmlFor="prenomloc"
                  className="form-label custom-form-label-etat-lieux ms-2"
                >
                  PRéNOM:
                </label>
                <input
                  type="text"
                  className="form-control custom-input-etat-lieux-date-entre2"
                  id="prenomloc"
                  placeholder="..............................................................................."
                />
              </div>
              <div className="custom-input-etat-lieux-date-entre-container-except">
                <label
                  htmlFor="nomloc"
                  className="form-label custom-form-label-etat-lieux ms-2"
                >
                  NOM:
                </label>
                <input
                  type="text"
                  className="form-control custom-input-etat-lieux-date-entre2"
                  id="nomloc"
                  placeholder="..............................................................................."
                />
              </div>
              <div className="custom-input-etat-lieux-date-entre-container-except">
                <label
                  htmlFor="adressloc"
                  className="form-label custom-form-label-etat-lieux ms-2"
                >
                  ADRESSE:
                </label>
                <textarea
                  id="adressloc"
                  className="form-control input-group-personnalisation custom-input-etat-lieux-date-entre2"
                  rows={4}
                  placeholder=".........................................................................................................................................................................................................................................................................................................................................................................................................................................................................................."
                ></textarea>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="mb-3 kanimmo-modal-etat-lieux-logement-container">
              <div className="kanimmo-modal-etat-lieux-logement-row-container mb-3 row">
                <div className="col-md-4">
                  <div className="d-flex gap-2 align-items-center mb-3">
                    <img
                      src={airconditioning}
                      alt="airconditioning"
                      className="kanimmo-modal-etat-lieux-logement-titre-icon"
                    />
                    <div className="kanimmo-modal-etat-lieux-logement-titre">
                      CLIMATISEUR
                    </div>
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox mb-2">
                    <input
                      className="form-check-input ms-2"
                      type="checkbox"
                      value="appartement:"
                      name="notif"
                      id="flexCheckDefaultchambre1"
                    />
                    <label
                      className="form-check-label form-label-login custom-form-label-login"
                      htmlFor="flexCheckDefaultchambre1"
                    >
                      Chambre 1
                    </label>
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox mb-2">
                    <input
                      className="form-check-input ms-2"
                      type="checkbox"
                      value="appartement:"
                      name="notif"
                      id="flexCheckDefaultchambre2"
                    />
                    <label
                      className="form-check-label form-label-login custom-form-label-login"
                      htmlFor="flexCheckDefaultchambre2"
                    >
                      Chambre 2
                    </label>
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox mb-2">
                    <input
                      className="form-check-input ms-2"
                      type="checkbox"
                      value="appartement:"
                      name="notif"
                      id="flexCheckDefaultchambre3"
                    />
                    <label
                      className="form-check-label form-label-login custom-form-label-login"
                      htmlFor="flexCheckDefaultchambre3"
                    >
                      Chambre 3
                    </label>
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox mb-2">
                    <input
                      className="form-check-input ms-2"
                      type="checkbox"
                      value="appartement:"
                      name="notif"
                      id="flexCheckDefaultnbrclim"
                    />
                    <label
                      className="form-check-label form-label-login custom-form-label-login"
                      htmlFor="flexCheckDefaultnbrclim"
                    >
                      Nombre de cliamatiseur
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex gap-2 align-items-center mb-3">
                    <img
                      src={runningwater}
                      alt="airconditioning"
                      className="kanimmo-modal-etat-lieux-logement-titre-icon"
                    />
                    <div className="kanimmo-modal-etat-lieux-logement-titre">
                      EAU CHAUDE
                    </div>
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox mb-2">
                    <input
                      className="form-check-input ms-2"
                      type="checkbox"
                      value="appartement:"
                      name="notif"
                      id="flexCheckDefaultelectricite"
                    />
                    <label
                      className="form-check-label form-label-login custom-form-label-login"
                      htmlFor="flexCheckDefaultelectricite"
                    >
                      électricité
                    </label>
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox mb-2">
                    <input
                      className="form-check-input ms-2"
                      type="checkbox"
                      value="appartement:"
                      name="notif"
                      id="flexCheckDefaultgaz"
                    />
                    <label
                      className="form-check-label form-label-login custom-form-label-login"
                      htmlFor="flexCheckDefaultgaz"
                    >
                      Gaz naturel
                    </label>
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox mb-2">
                    <input
                      className="form-check-input ms-2"
                      type="checkbox"
                      value="appartement:"
                      name="notif"
                      id="flexCheckDefaultcollectif"
                    />
                    <label
                      className="form-check-label form-label-login custom-form-label-login"
                      htmlFor="flexCheckDefaultcollectif"
                    >
                      Collectif
                    </label>
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox mb-2">
                    <input
                      className="form-check-input ms-2"
                      type="checkbox"
                      value="appartement:"
                      name="notif"
                      id="flexCheckDefaultautre"
                    />
                    <label
                      className="form-check-label form-label-login custom-form-label-login"
                      htmlFor="flexCheckDefaultautre"
                    >
                      Autre:
                    </label>
                    <input
                      type="text"
                      className="form-control custom-input-etat-lieux-date-entre2"
                      id="autre"
                      placeholder="..............................................."
                      style={{ width: "75%" }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex gap-2 align-items-center mb-3">
                    <img
                      src={kitchencooker}
                      alt="airconditioning"
                      className="kanimmo-modal-etat-lieux-logement-titre-icon"
                    />
                    <div className="kanimmo-modal-etat-lieux-logement-titre">
                      CUISSON
                    </div>
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox mb-2">
                    <input
                      className="form-check-input ms-2"
                      type="checkbox"
                      value="appartement:"
                      name="notif"
                      id="flexCheckDefaultelectricitecuisson"
                    />
                    <label
                      className="form-check-label form-label-login custom-form-label-login"
                      htmlFor="flexCheckDefaultelectricitecuisson"
                    >
                      électricité
                    </label>
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox mb-2">
                    <input
                      className="form-check-input ms-2"
                      type="checkbox"
                      value="appartement:"
                      name="notif"
                      id="flexCheckDefaultgazcuisson"
                    />
                    <label
                      className="form-check-label form-label-login custom-form-label-login"
                      htmlFor="flexCheckDefaultgazcuisson"
                    >
                      Gaz naturel
                    </label>
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox mb-2">
                    <input
                      className="form-check-input ms-2"
                      type="checkbox"
                      value="appartement:"
                      name="notif"
                      id="flexCheckDefaultcollectifcuisson"
                    />
                    <label
                      className="form-check-label form-label-login custom-form-label-login"
                      htmlFor="flexCheckDefaultcollectifcuisson"
                    >
                      Collectif
                    </label>
                  </div>
                  <div className="checkbox custom-kanimmo-modal-etat-lieux-input-entre-checkbox mb-2">
                    <input
                      className="form-check-input ms-2"
                      type="checkbox"
                      value="appartement:"
                      name="notif"
                      id="flexCheckDefaultautrecuisson"
                    />
                    <label
                      className="form-check-label form-label-login custom-form-label-login"
                      htmlFor="flexCheckDefaultautrecuisson"
                    >
                      Autre:
                    </label>
                    <input
                      type="text"
                      className="form-control custom-input-etat-lieux-date-entre2"
                      id="autrecuisson"
                      placeholder="..............................................."
                      style={{ width: "75%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 offset-md-7">
            <p className="kanimmo-etat-lieux-signature">PARAPHES:</p>
          </div>
          <div className="container-btn-modal row my-4">
            <div className="col-md-4 offset-md-8">
              <div className="d-flex gap-3">
                <button
                  className="btn auth-submit-annuler"
                  onClick={() => navigate(-1)}
                >
                  Annuler
                </button>
                <button className="btn auth-submit-btn" type="submit">
                  Suivant
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EtatLieuxModal;
