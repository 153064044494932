import React, { useState } from "react";
import LocataireAdminSkeleton from "./LocataireAdminSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useGetAdminLocatairesQuery } from "../../../../utils/api/admin/admin.api";
import { formattedDate, getImage, showModal } from "../../../../utils/Utils";
import { Link } from "react-router-dom";
import { AlertInfo } from "../../../common/Alert";
import { AiFillEye } from "react-icons/ai";
import { IAgence } from "../../../../utils/api/agence/agence.type";
import { MdEdit, MdOutlinePayments } from "react-icons/md";
import { CustomPagination } from "../../../common/CustomPagination";
import { GeneratePassword } from "../../agence/ProprietaireAgenceTable/ProprietaireAgenceTable";
import { IoMdTrash } from "react-icons/io";
import { UseDeleteAgence } from "../../../TableauDebord/Admin/MesClients/CrudClient/useForm/useCrudAgence";
import SendPaymentModal from "../../../TableauDebord/Admin/MesClients/modal/SendPaymentModal";
export const logoFormatter: any = (cell: any, row: any) => {
  return (
    <img
      src={getImage(cell)}
      alt="img type document"
      className="kanimmo-img-avatar-table-admin"
    />
  );
};

function DeleteAgenceBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteAgence(slug);
  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <IoMdTrash />
    </button>
  );
}

function LocataireAdminTable({ word }: { word: string | null }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [] }, isLoading } = useGetAdminLocatairesQuery({
    word,
    page,
    limit: perPage,
  });

  const actionFormatter = (cell: string, row: IAgence) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes gap-2 d-flex">
          {/* <div>
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Paiements"
              onClick={() => showModal(`sendPaymentModal${cell}`)}
            >
              <MdOutlinePayments />
            </button>
          </div> */}
          <div>
            <Link
              className="btn btn-action-modal-icon with-tooltip"
              to={`/admin/details-agence/${row?.slug}`}
              state={row}
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </Link>
          </div>
          <div>
            <Link
              className="btn btn-action-modal-icon with-tooltip"
              to={`/admin/modifier-client-agence/${row?.slug}`}
              state={row}
              data-tooltip-content="Modifier"
            >
              <MdEdit />
            </Link>
          </div>

          <GeneratePassword slug={row?.slug} />
          <div>
            <DeleteAgenceBtn slug={cell} />
          </div>
        </div>
        <SendPaymentModal modalId={`sendPaymentModal${cell}`} item={row} />
      </>
    );
  };

  const columns = [
    {
      dataField: "logo",
      text: "Logo",
      formatter: (cell: any, row: any) => logoFormatter(cell, row),
    },
    {
      dataField: "prenom",
      text: "Nom du locataire",
      style: { textAlign: "left" },
    },
    {
      dataField: "email",
      text: "Email",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) => (
        <a className="no-link" href={`mailto:${cell}`}>
          {cell}
        </a>
      ),
    },

    {
      dataField: "telephone",
      text: "Contacts",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) => (
        <a className="no-link" href={`tel:${cell}`}>
          {cell}
        </a>
      ),
    },
    {
      dataField: "created_at",
      text: "Date d’inscription",
      style: { textAlign: "left" },
      formatter: (cell: string, row: any) => formattedDate(cell),
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: IAgence) => actionFormatter(cell, row),
      // headerStyle: () => {
      //   return { width: "80px", whiteSpace: "normal" };
      // },
    },
  ];
  return (
    <>
      {isLoading && <LocataireAdminSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
            rowStyle={{ cursor: "pointer" }}
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            perPage={perPage}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
          />
        </>
      )}
    </>
  );
}

export default LocataireAdminTable;