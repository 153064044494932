import React from "react";
import { useGetConditionsFrontQuery } from "../../../../utils/api/condition/condition.api";
import { createMarkup } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import EmptyHeader from "../../../landingPage/EmptyHeader";
import FrontFooter from "../../../landingPage/FrontFooter/FrontFooter";
import BreadCrumb from "../../../shared/BreadCrumb";
import "./Policy.css";

function Policy() {
  const { data, isLoading } = useGetConditionsFrontQuery({});
// console.log("condition", data);
  return (
    <div className="landing-page-component">
      <EmptyHeader />
      <div className="page-content">
        <BreadCrumb
          pageName="Conditions générales d’utilisations"
          title="Conditions générales d’utilisations"
        />
      </div>

      <section className="page-content-body-container container">
        <div className="online-courses-container">
          <div className="news-list-container pt-5">
            <div className="row">
              {data?.results?.length === 0 && (
                <div className="mb-3">
                  <AlertInfo message="Pas de données" />
                </div>
              )}
              {/* ============================ NE PAS SUPPRIMER ===================== */}
              <div className="col-md-4">
                <div className="container-menu-condition-generale">
                  <ul className="list-menu-condition-generale">
                    {data?.results?.map((item, index) => (
                      <li className="list-item-condition-generale">
                        <a
                          className={`ancre-condition-genrale ${
                            index === 0 && "active"
                          }`}
                          href={`#${item?.titre}`}
                        >
                          {item?.titre}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-8">
                <div className="content-text-condition-generale">
                  {data?.results?.map((itm, index) => (
                    <>
                      <section
                        className="section-content-condition-generale mb-3"
                        id={`${itm?.titre}`}
                      >
                        <div className="content-title-condition-generale mb-3">
                          <h5 className="title-condition-generale-page">
                            {itm?.titre}
                          </h5>
                        </div>
                        {/* {index === 0 && (
                          <div>
                            <p className="title-condition-generale-page">
                              Dernière version: 13/01/2022{" "}
                              <span
                                style={{
                                  color: "#1F3CAD",
                                  fontSize: 14,
                                  fontWeight: 400,
                                }}
                              >
                                (Cliquez ici pour voir la version précédente)
                              </span>
                            </p>
                          </div>
                        )} */}
                        <div>
                          <p
                            className="text-section-condition-generale"
                            dangerouslySetInnerHTML={createMarkup(itm?.text)}
                          />
                        </div>
                      </section>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FrontFooter />
    </div>
  );
}

export default Policy;
