import React, { useState } from 'react'
import ReactQuill from 'react-quill'

function Rappels() {
    const [value, setValue] = useState('');
    return (
        <div className="bg-white p-3 filter-and-table-container">
            <div className=''>
                <form>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label
                                    htmlFor="titre"
                                    className="form-label form-label-modal-custom"
                                >
                                    Titre du rappel
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-control-modal-custom"
                                    id="titre"
                                    placeholder='Titre'
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                            <label
                                htmlFor="email"
                                className="form-label form-label-modal-custom"
                            >
                                Message
                            </label>
                            <ReactQuill className='editeur-react-quill' value={value} onChange={setValue} />
                            </div>
                        </div>
                    </div>
                    <div className="container-btn-modal row my-4">
                        <div className="col-md-4 offset-md-8">
                            <div className="d-flex gap-3">
                                <button className="btn auth-submit-annuler">Annuler</button>
                                <button className="btn auth-submit-btn" type='submit'>Continuer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Rappels
