import React, { useState , useEffect} from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import Avatar from "../../../assets/appImages/avatar.png";
import { useAppSelector } from "../../../redux/hooks";
import { useGetUserByAgenceBySlugQuery } from "../../../utils/api/agence/agence.api";
import { useGetLocataireBySlugQuery } from "../../../utils/api/locataire/locataire.api";
import { IUser } from "../../../utils/api/user/user.type";
import { getAvatar, isLocataire } from "../../../utils/Utils";
import MessageModal from "./MessageModal";


const NewDiscussionModalLocataire = ({
  onSelect,
}: {
  onSelect: (user: IUser) => any;
}) => {
  const locataire = useAppSelector((s) => s.user.user);
  const [search, setSearch] = useState("");

  const userSlug = useAppSelector((s) => s.user.user?.slug);
  const { data: users } = useGetLocataireBySlugQuery(userSlug);
  const is_LocataireAgence = isLocataire(locataire) && locataire?.agence_id;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(e: any) {
    e.preventDefault();
    setIsOpen(true);
  }

    console.log ("les loc" ,users)


// console.log("users agence", users)
  return (
    <div className="modal-dialog">
      <div className="modal-content modal-content-message">
        <div className="title-modal-send-message px-2 mt-3">
          <div className="content-title-modal-send-message">
            <p className="mb-0 title-modal-send-message"></p>
          </div>
          {/* <div className="content-btn-send-message">
            <button
              className="btn btn-send-message-modal disabled"
              onClick={openModal}
            >
              Suivant
            </button>
          </div> */}
        </div>
        <div className="pt-3">
          <div className="form-search-user-container position-relative">
            <input
              type="text"
              className="form-search-user form-control"
              placeholder="Rechercher des personnes"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <FiSearch
              style={{
                color: "#919EAB",
                fontSize: 22,
                position: "absolute",
                top: "25%",
                left: "2%",
              }}
            />
          </div>
        </div>
        <ul className="user-for-send-message-container mt-3  px-2">
              <li
                className="user-for-send-message  mb-3 px-3 py-1"
                data-bs-dismiss="modal"
								onClick={() => {
                  // console.log("select item", users?.agence_id)
									onSelect(is_LocataireAgence ? users?.agence_id : users?.proprietaire_id
                    );
									document
										.getElementById("closeModal")
										?.dispatchEvent(new Event("click"));
								}}
              >
                <div className="d-flex align-items-center pb-1">
                  <BsFillPersonFill style={{ color: "#919EAB" }} />
                  <p className="mb-0 text-profil-user-modal">{ users?.agence_id?.user_type}</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <img
                      src={getAvatar(is_LocataireAgence ? users?.agence_id?.avatar : users?.proprietaire_id?.avatar)}
                      alt="user-avatar"
                      className="w-fluid img-profil-user-message"
                    />
                  </div>
                  <div className="user-for-send-message-infos">
                    <h3 className="mb-0">
                      {is_LocataireAgence ? (users?.agence_id?.prenom + " " + users?.agence_id?.nom) : (users?.proprietaire_id?.prenom + " " + users?.proprietaire_id?.nom)}
                      {/* {users?.agence_id?.prenom} {users?.agence_id?.nom} */}
                    </h3>
                    {/* <h4 className="mb-0">Online - Last seen, 2.02pm</h4> */}
                  </div>
                </div>
              </li>
        
        </ul>
        <MessageModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      </div>
    </div>
  );
};

export default NewDiscussionModalLocataire;
