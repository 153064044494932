import React from "react";
import { useStep } from "react-hooks-helper";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useStepType } from "../../../../../utils/type";
import HistoriquePaiementStep from "./LocataireSteps/HistoriquePaiementStep";
import HistoriqueRequetesStep from "./LocataireSteps/HistoriqueRequetesStep";
import "./Locataire.css";
import Aside from "./Aside";
import { useRequeteDetailsAgenceFromLocation } from "../../../../../utils/api/requete/requete.api";

const steps = [
  { id: "historique-paiement", Component: HistoriquePaiementStep },
  { id: "historique-requete", Component: HistoriqueRequetesStep },
];

const tabs = ["Historique paiements", "Historique des requêtes"];

function Locataire() {
  const navigate = useNavigate();

  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [itemState, findBySlug] = useRequeteDetailsAgenceFromLocation();

// console.log("itemState", itemState);
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            <BiArrowBack
              className="locataire-btn-arrow-back pe-2"
              onClick={() => navigate(-1)}
            />{" "}
            Locataires
          </h4>
        </div>

        <div className="row kanimmo-locataire-histo-row">
          <div className="col-md-4">
            <Aside />
          </div>
          <div className="col-md-8">
            <div className="kanimmo-tabs-container py-3">
              <ul className="tabs-container-historique">
                {tabs.map((tab, key) => (
                  <li
                    className={`tab-item  ${
                      index === key && "tab-active-item"
                    }`}
                    key={key}
                    onClick={() => go(key)}
                    role="button"
                  >
                    {tab}
                  </li>
                ))}
              </ul>
              <div className="tabs-component-container">
                <Component />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Locataire;
