import React, { useEffect, useState } from 'react'
import { IoMdCloudDownload } from 'react-icons/io';
import { IoPrint } from 'react-icons/io5';
import { Pagination } from 'reactstrap';
import ChargesLocativesDatas from './ChargesLocativesdatas';
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ChargesLocativesSkeleton from './ChargesLocativesSkeleton';
import { RiEdit2Fill } from 'react-icons/ri';
import { MdEdit } from 'react-icons/md';

function ChargesLocativesTable() {
    const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);

    const documentFormatter = (cell: any, row: any) => {
        return( 
        <div className="document-formatter-container d-flex justify-content-center">
            <IoMdCloudDownload className='document-formatter-icon-download' />
            <IoPrint className='document-formatter-icon-print' />
        </div> ) 
	};

    const actionFormatter: any = (cell: any, row: any) => {
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				<div>
                    <div>
                        <button className="btn btn-action-requete-locataire"><MdEdit /></button>
                    </div>
                </div>
			</div>
		);
	};
	const columns = [
		{
			dataField: "id",
			text: "N°",
			style: { textAlign: "left" },
            headerStyle: () => {
				return { width: "50px", whiteSpace: "normal" };
			},
		},
        {
			dataField: "date",
			text: "Date",
            // headerStyle: () => {
			// 	return { width: "400px", whiteSpace: "normal" };
			// },
		},
		{
			dataField: "client",
			text: "Client",
			style: { textAlign: "left" },
		},
		{
			dataField: "location",
			text: "Location",
			style: { textAlign: "left" },
		},
		
		{
			dataField: "type_paiement",
			text: "Type de paiement",
			style: { color: "#1AA981", textAlign: 'center' , textTransform: "capitalize" },
			// formatter: (cell: any, row: any) =>
            // statusFormatter(cell, row),
		},
        {
			dataField: "facture",
			text: "Facture",
			style: { textAlign: "left" },
		},
		{
			dataField: "recu_paiement",
			text: "Reçu de paiement",
			style: { textAlign: 'center' },
			formatter: (cell: any, row: any) =>
            documentFormatter(cell, row),
		},
        {
			dataField: "actions",
			text: "Actions",
            style: { textAlign: "center" },
			formatter: (cell: any, row: any) =>
				actionFormatter(cell, row),
            headerStyle: () => {
                return { width: "80px", whiteSpace: "normal" };
            },
		},
	];

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 0);
	}, []);

    return (
        <>
			{isLoading && <ChargesLocativesSkeleton />}
			{!isLoading && (
				<>
                    <BootstrapTable
                        keyField="id"
                        data={ChargesLocativesDatas}
                        columns={columns}
                        bordered={true}
                        condensed={false}
                        responsive
                        wrapperClasses="table-responsive locataire-table"
                    />
                    <CustomPagination
                        nbPages={8}
                        page={page}
                        onChange={(page, perPage) => {
                            setPerPage(perPage);
                            setPage(page);
                        }}
                        perPage={perPage}
                    />
                </>
			)}
		</>
    )
}

export default ChargesLocativesTable


type CustomPaginationProps = {
	nbPages: number | any;
	page: number;
	onChange: (page: number, perPage: number) => any;
	perPage: number;
};
export function CustomPagination({ nbPages, page, perPage, onChange }: CustomPaginationProps) {
	if (parseInt(nbPages) <= 0) return null;
	return (
		<div className="d-flex align-items-baseline justify-content-between">
			<div className="footer-form d-flex mt-3">
				<select
					className="form-control select-perpage"
					name="limit"
					id="limit"
					value={perPage}
					onChange={(e) => {
						onChange(1, parseInt(e.target.value, 10));
					}}
				>
					<option value="10">10</option>
					<option value="30">30</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>
			</div>

			{parseInt(nbPages) > 0 ? (
				<Pagination
					page={page}
					total={nbPages}
					perPage={perPage}
					onPageChange={(page: number) => onChange(page, perPage)}
				/>
			) : null}
		</div>
	);
}