import React, { useEffect, useState } from "react";
import "./HeaderAdmin.css";
// import Logo from "../../../../assets/appImages/kanimmoLogoNoir.png";
import Logo from "../../../../assets/appImages/kanimmoLogoNoirCouper.png";
// import Logo from "../../../../assets/appImages/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import Chat from "../../../../assets/icons/Chat.png";
import ClocheIcon from "../../../../assets/icons/Notification.png";
import { RiArrowDropDownFill } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { onlogout, onSetUser } from "../../../../redux/slice/User.slice";
import {
  createMarkup,
  getAvatar,
  isAgence,
  isAgenceAgent,
  isAgenceJuriste,
  isAgenceMarketeur,
  isComptable,
  isLocataire,
  isProprietaireAgence,
  isProprietaireOwner,
  isProprioGerant,
  showModal,
} from "../../../../utils/Utils";
import {
  useGetNotificationByProprietaireQuery,
  useUpdateReadNotificationMutation,
  useMarkAsReadNotificationMutation,
} from "../../../../utils/api/notification/notification.api";
import moment from "moment";
import ViewNotifModal from "./ViewNotifModal";
import { INotification } from "../../../../utils/api/notification/notification.type";
import { useSearchParams } from "react-router-dom";
import { useChatByUserQuery } from "../../../../utils/api/chat/chat.api";
import {
  isAdminAgent,
  isAdminUser,
  isSuperAdmin,
} from "../../../../routes/router";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import { IUser } from "../../../../utils/api/user/user.type";
import { MdSwapHoriz } from "react-icons/md";
import Swal from "sweetalert2";
import {
  IProprietaire,
  ProprietaireFormData,
} from "../../../../utils/api/proprietaire/proprietaire.type";
import { ProprietaireApi } from "../../../../utils/api/proprietaire/proprietaire.api";
import SwitchProfilModal from "../../../modal/user/SwitchProfilModal";
import SignalerProbleme from "../../../modal/user/SignalerProbleme";
import { Color } from "../../../../utils/theme";
import { useLazyConfirmProratatQuery } from "../../../../utils/api/locataire/locataire.api";
import OffersModal from "../../../modal/user/OffersModal";
import ChoicePaymentMethod from "../../locataire/Dashboard/MonLoyer/ChoicePaymentMethod";
import ChoichePayementMethodeMensualite from "../../locataire/Dashboard/MonLoyer/ChoichePayementMethodeMensualite";
import CheckStatusPaymentModal from "../../locataire/Dashboard/MonLoyer/CheckStatusPaymentModal";
import UseCreateAbonnement from "../../../modal/user/UseCreateAbonnement";
import UsePayAbonnementForm from "../../../modal/user/UsePayAbonnementForm";
import {
  useExpireAbonnementQuery,
  useUserCreatePersonelSubscriberQuery,
} from "../../../../utils/api/offer/expireataabonnement.api";
import { FiEdit } from "react-icons/fi";

import {
  useLoginwithisOnlineViewMutation,
  useMarkUserOflineMutation,
} from "../../../../utils/api/auth/auth.api";

function SwitchButton({ isChecked }: { isChecked: boolean }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((s) => s.user.user as IProprietaire);
  const [show, setShow] = useState(false);
  const [updateUser, { isSuccess, isError, error, data }] =
    ProprietaireApi.useUpdateMutation();
  const { est_gerer_par_proprio_agence, gerer_par_proprio_agence } = user;
  const next_est_gerer_par_proprio_agence = !est_gerer_par_proprio_agence;

  useEffect(() => {
    if (isSuccess) {
      // console.log(data);
      dispatch(onSetUser(data));
      Swal.hideLoading();
      Swal.close();
      // navigate("/connexion");
    }
    if (isError) {
      Swal.hideLoading();
      Swal.close();
      const err = error as any;
      // console.log(err);
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isSuccess, isError, data, dispatch]);

  useEffect(() => {
    if (isChecked) {
      const data: ProprietaireFormData = {
        est_gerer_par_proprio_agence: next_est_gerer_par_proprio_agence,
        gerer_par_proprio_agence: true,
        slug: user.slug,
      };
      Swal.showLoading();
      updateUser(data);
    }
  }, [isChecked]);
  const onSwitch = async () => {
    const data: ProprietaireFormData = {
      est_gerer_par_proprio_agence: next_est_gerer_par_proprio_agence,
      gerer_par_proprio_agence: true,
      slug: user.slug,
    };

    if (
      !gerer_par_proprio_agence &&
      !user?.offre &&
      !isProprietaireOwner(user)
    ) {
      showModal("offresModal");
    } else {
      Swal.showLoading();
      await updateUser(data);
    }
    // await updateUser(data);
    // dispatch(onSetUser({ ...user, ...data }));
  };
  return (
    <>
      <button
        onClick={() => {
          if (gerer_par_proprio_agence) {
            onSwitch();
          } else {
            setShow(true);
          }
        }}
        className="dropdown-item item-dropdown-custom flex-sb align-items-center"
        style={{ fontSize: 16 }}
      >
        <span>
          Biens gérés par {!isProprietaireOwner(user) ? "moi" : "l'agence"}
        </span>
        <MdSwapHoriz
          style={{
            color: "#1F3CAD",
            fontSize: 22,
          }}
        />
      </button>
      <SwitchProfilModal
        visible={show}
        onHide={(ok: boolean) => {
          if (ok === true) {
            onSwitch();
          }
          setShow(false);
        }}
      />
    </>
  );
}

const HeaderAdmin: React.FC = () => {
  // const {
  //   isLoading: loading,
  //   mode,
  //   onCheckStatus,
  //   onLoading,
  //   onPressSubmit,
  //   seconds,
  //   setOffer,
  //   isChecked,
  // } = UseCreateAbonnement("paymentMethod");

  const {
    isLoading: loading,
    mode,
    onLoading,
    onCheckStatus,
    onCheckStatusDif,
    isChecked,
    seconds,
    onPressSubmit,
    setOffer,
    disabled,
  } = UsePayAbonnementForm();
  const [showPaymentModal, setShowPaymentModal] = useState(false); // État pour gérer l'affichage du modal de paiement

  // Fonction pour afficher le modal de paiement
  const handleShowPaymentModal = () => {
    setShowPaymentModal(true);
  };

  // Fonction pour masquer le modal de paiement
  const handleClosePaymentModal = () => {
    setShowPaymentModal(false);
  };

  const handlePayerAbonnement = async () => {
    // Afficher le modal de paiement
    // handleShowPaymentModal();
    showModal("ChoichePayementMethodeMensualite");
  };

  const onSubmit = async (apiCash: string) => {
    // Appeler la fonction onPressSubmit avec le mode de paiement sélectionné
    await onPressSubmit(apiCash, null);
  };
  var [showNotifications, setShowNotifications] = React.useState(false);
  const [item, setItem] = useState<INotification>();
  const [updateNotif] = useUpdateReadNotificationMutation();
  const [updateNotifRead] = useMarkAsReadNotificationMutation();
  const [loginwithisOnlineView] = useLoginwithisOnlineViewMutation();
  const [markUserOfline] = useMarkUserOflineMutation();

  const [searchParams, setSearchParams] = useSearchParams();
  const name = searchParams.get("name");
  // const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
  //   if (e.currentTarget.value) {
  //     setSearchParams({
  //       name: e.currentTarget.value,
  //     });
  //   } else {
  //     setSearchParams({});
  //   }mmmmmmmmmmmmmmmmmmmm
  // };

  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((s) => s?.user);
  const proprietaire = useAppSelector((s) => s.user.user);
  // const handleLogout = () => {
  //   markUserOfline({ slug: user?.slug });
  //   dispatch(onlogout());
  //   window.location.pathname = "/connexion";
  // };
  const handleLogout = async () => {
    try {
      await markUserOfline({ slug: user?.slug });
      dispatch(onlogout());
      window.location.pathname = "/connexion";
    } catch (error) {
      console.error("Error marking user offline:", error);
    }
  };

  const [confirm] = useLazyConfirmProratatQuery();
  const {
    data: dataAbonnement,
    error,
    refetch,
  } = useExpireAbonnementQuery(user.slug);
  const isExpired = dataAbonnement?.is_expired ?? null;

  const { data: personnelAbonement } = useUserCreatePersonelSubscriberQuery(
    user.slug
  );

  const personnelIsExpire = personnelAbonement?.is_expired;

  const { data = { results: [], count: 0 }, isLoading } =
    useGetNotificationByProprietaireQuery({ slug: proprietaire?.slug });
  const { data: chatData = [] } = useChatByUserQuery({ name });
  const list = chatData
    .map((item) => item?.messages)
    .map((x) => x?.filter((r) => r?.is_read === false))
    ?.filter((itm) => itm?.length > 0)?.length;
  const totalUnreadCount = chatData.reduce(
    (total, chat) => total + chat.count,
    0
  );
  var onUpdateReadNotification = (notif: INotification) => {
    setShowNotifications(false);
    setItem(notif);
    if (notif?.type !== "proratat") {
      updateNotif({ slug: notif.slug, read: true });
    } else {
      if (!notif?.content?.includes("Rappel de paiement")) {
        Swal.fire({
          icon: "info",
          title: notif?.content,
          text: "Confirmez-vous le paiement du proratat ?",
          iconColor: Color.bleu,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Oui",
          allowOutsideClick: false,
          confirmButtonColor: Color.bleu,
          cancelButtonText: "Non",
          showLoaderOnConfirm: true,
        }).then(async (result) => {
          // AppLocalStorage.setItem("new_connection", false);
          if (result?.isConfirmed) {
            await confirm({ slug: notif?.data?.slug, statut: "valide" });
            await updateNotif({ slug: notif.slug, read: true });
            await navigate(
              isAgence(user)
                ? `/agence/liste-locataire/${notif?.data?.slug}`
                : isProprietaireOwner(user)
                ? `/proprietaire/gerer-par-le-proprietaire/locataire/${notif?.data?.slug}`
                : "",
              { state: notif?.data }
            );
          } else {
            await confirm({ slug: notif?.data?.slug, statut: "invalide" });
            await updateNotif({ slug: notif.slug, read: true });
            await navigate(
              isAgence(user)
                ? `/agence/liste-locataire/${notif?.data?.slug}`
                : isProprietaireOwner(user)
                ? `/proprietaire/gerer-par-le-proprietaire/locataire/${notif?.data?.slug}`
                : "",
              { state: notif?.data }
            );
          }
        });
      }
    }
  };
  useEffect(() => {
    // Lance une requête toutes les 5 secondes pour verifier l'etat d'abonnement du clien
    const pollingInterval = setInterval(() => {
      refetch();
    }, 5000);

    return () => {
      // Arrête le polling lorsque le composant est démonté
      clearInterval(pollingInterval);
    };
  }, [refetch]);

  const is_ProprietaireAgence = isProprietaireAgence(user);
  const is_ProprietaireOwner = isProprietaireOwner(user);
  const is_Comptable = isComptable(user);
  const is_Locataire = isLocataire(user);
  const is_Agence = isAgence(user);
  const is_Admin = isAdminUser(user);
  // const userType = user?.user_type;
  // const handleClick = () => {
  //   markUserOfline({ slug: user?.slug })
  //     .unwrap()
  //     .then((data) => {
  //       console.log("Success:", data);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };
  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering-admin">
          <div className="logo-item">
            <div className="logo-container dashboard-header-logo">
              <NavLink
                className="navbar-brand"
                to="/"
                style={{ width: "100px", height: "auto" }}
              >
                <img
                  src={Logo}
                  alt="data-gouvernance"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </NavLink>
              <div>
                {isExpired !== null &&
                  isExpired &&
                  dataAbonnement &&
                  dataAbonnement.days_until_expiration !== undefined &&
                  dataAbonnement.days_until_expiration === 0 && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "16px",
                        animation: "blinking 1s infinite",
                      }}
                    >
                      Votre abonnement expire dans moins de 24 heures. Merci de
                      vous réabonner.
                      <button
                        className="btn edit-offer-btn"
                        // onClick={() => showModal("PaymentMethod")}
                        onClick={handlePayerAbonnement} // Appel de la fonction handlePayerAbonnement
                      >
                        <FiEdit /> Payer
                      </button>
                    </p>
                  )}

                {isExpired !== null &&
                  isExpired &&
                  dataAbonnement &&
                  dataAbonnement.days_until_expiration &&
                  dataAbonnement.days_until_expiration <= 5 && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "16px",
                        animation: "blinking 1s infinite",
                      }}
                    >
                      Votre abonnement expire dans{" "}
                      <strong>
                        {dataAbonnement.days_until_expiration} jours{" "}
                      </strong>{" "}
                      merci de vous réabonner.
                      <button
                        className="btn edit-offer-btn"
                        onClick={handlePayerAbonnement} // Appel de la fonction handlePayerAbonnement
                      >
                        <FiEdit /> Payer
                      </button>
                    </p>
                  )}
              </div>
              <div></div>
            </div>
          </div>
          <div>
            {isExpired !== null &&
              !isExpired &&
              user.user_type !== "admin" &&
              user.user_type !== "comptable" &&
              user.user_type !== "locataire" &&
              user.user_type !== "gerant" &&
              !is_ProprietaireAgence &&
              user.user_type !== "superadmin" &&
              user.user_type !== "juriste" && (
                <div
                  style={{
                    color: "red",
                    alignItems: "center",
                    fontSize: 11,
                    paddingTop: 3,
                  }}
                >
                  Votre abonnement n’est pas a jour. Merci de le regler afin de
                  continuer de pouvoir utiliser votre plateforme.
                  {user.user_type !== "comptable" &&
                  user.user_type !== "gerant" ? (
                    <button
                      className="my-2 py-0 p-2 btn edit-offer-btn"
                      // onClick={() => showModal("PaymentMethod")}
                      onClick={handlePayerAbonnement}
                    >
                      <FiEdit />
                      Payer
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              )}
          </div>

          <div>
            {personnelIsExpire !== null &&
              !personnelIsExpire &&
              user.user_type !== "admin" &&
              user.user_type !== "locataire" &&
              user.user_type !== "agence" &&
              user.user_type !== "proprietaire" &&
              !is_ProprietaireAgence &&
              user.user_type !== "superadmin" && (
                <div
                  style={{
                    color: "red",
                    alignItems: "center",
                    fontSize: 11,
                    paddingTop: 3,
                  }}
                >
                  Votre abonnement n’est pas à jour. Merci de le régler avec{" "}
                  {user.agence_id !== null
                    ? `${user?.agence_id?.prenom} ${user?.agence_id?.nom}`
                    : `${user?.proprietaire_id?.prenom} ${user?.proprietaire_id?.nom}`}{" "}
                  afin de continuer de pouvoir utiliser votre plateforme.
                </div>
              )}
          </div>
          <div className="header-others-items">
            <div className="container-menu-header-admin gap-3">
              <div
                className="notification-item-container linkable position-relative"
                onClick={() => onShowAndHideNotification()}
              >
                <div className="notification-item-content">
                  <img src={ClocheIcon} className="notif-icon" />
                  {data?.results?.filter((x) => !x.read)?.length == 0 ? null : (
                    <span className="notification-counter"></span>
                  )}
                </div>
              </div>
              {/* <div className="sent-message-header-container position-relative">
                <NavLink
                  to={
                    is_ProprietaireAgence
                      ? "/proprietes/gerer-par-agence/messages"
                      : is_Agence ||
                        isAgenceAgent(user) ||
                        isAgenceJuriste(user) ||
                        isAgenceMarketeur(user)
                      ? "/agence/messagerie"
                      : is_ProprietaireOwner
                      ? "/proprietaire/gerer-par-le-proprietaire/messages"
                      : is_Comptable
                      ? "/comptable/messages"
                      : is_Locataire
                      ? "/locataire/messages"
                      : isAdminAgent(user)
                      ? "/admin/messages"
                      : "/admin/messages"
                  }
                  className=" no-link b-0 p-0"
                  onClick={() => updateNotifRead(user?.slug)}
                >
                  <img src={Chat} className="notif-icon" />
                  {data?.results?.filter((x) => !x.read)?.length == 0 ? null : (
                    <span className="notification-counter"></span>
                  )}
                 
                </NavLink>
              </div> */}
            </div>
            <div className="connected-user-container">
              <div className="connected-user-content ml-auto">
                <div className="dropdown dis-flex">
                  <button
                    className="user-acount-dashboard-header dropdown-toggle btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-acount-dashboard-header-display g-2">
                      <img
                        src={getAvatar(user?.avatar)}
                        className="user-avatar"
                        alt="User Avatar"
                      />
                      <span className="user-name-connect px-2 no-view-mobile">
                        {`${user?.prenom}`}
                      </span>
                      <RiArrowDropDownFill />
                    </div>
                  </button>
                  {/* <div
                    className="modal fade "
                    id="SignalerProbleme"
                    aria-labelledby="SignalerProbleme"
                    aria-hidden="true"
                  >
                    <SignalerProbleme />
                  </div> */}
                  <div
                    className="dropdown-menu dropdown-menu-custom"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <NavLink
                      to={
                        is_Agence ||
                        isAgenceAgent(user) ||
                        isAgenceJuriste(user) ||
                        isAgenceMarketeur(user)
                          ? "/agence/option"
                          : is_Locataire
                          ? "/locataire/parametre"
                          : is_ProprietaireAgence
                          ? "/proprietes/gerer-par-agence/parametres"
                          : is_ProprietaireOwner || isProprioGerant(user)
                          ? "/proprietaire/gerer-par-le-proprietaire/parametres"
                          : is_Comptable
                          ? "/comptable/options"
                          : isAdminAgent(user)
                          ? "/admin/dashboard"
                          : "/admin/options"
                      }
                      className="dropdown-item"
                      style={{ fontSize: 16 }}
                    >
                      {`${user?.prenom}`}
                    </NavLink>
                    <div className="dropdown-divider"></div>
                    {/* Biens gérés par propriétaire */}
                    {/* {user?.user_type === "proprietaire" && (
                      <>
                        <SwitchButton isChecked={isChecked} />
                        <div className="dropdown-divider"></div>
                      </>
                    )} */}

                    {(!is_Admin || !isSuperAdmin) && (
                      <>
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#SignalerProbleme"
                          className="dropdown-item"
                          style={{ fontSize: 16 }}
                        >
                          Signaler un problème
                        </button>
                        <div className="dropdown-divider"></div>
                      </>
                    )}
                    <button
                      type="button"
                      className="dropdown-item"
                      style={{ fontSize: 16 }}
                      onClick={handleLogout}
                    >
                      Deconnexion
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="hiddenable-nav-mobile-container no-view-desktop">
              <div className="hiddenable-nav-mobile-content">
                <div className="container-nav-mobile">
                  <SidebarMobile />
                </div>
              </div>
            </div>
          </div>
        </div>

        {showNotifications && (
          <div
            className={
              data?.results?.filter((el) => el?.read !== true)?.length
                ? "header-hiddenable-notification-section"
                : "header-hiddenable-notification-sect"
            }
          >
            <ul
              className={
                data?.results?.filter((el) => el?.read !== true)?.length
                  ? "header-hiddenable-notification-ul"
                  : "header-hiddenable-notification-ol"
              }
            >
              {data?.results?.filter((x) => x.read !== true)?.length == 0 ? (
                <li
                  key={item?.slug}
                  className="header-hiddenable-notification-li"
                  style={{ fontSize: 16 }}
                >
                  Il n'y a aucune notification .
                </li>
              ) : (
                data?.results
                  ?.filter((x) => x.read !== true)
                  ?.map((item) => (
                    <li
                      key={item?.slug}
                      className="header-hiddenable-notification-li"
                      onClick={() => onUpdateReadNotification(item)}
                    >
                      <NavLink
                        to={getRoute(item, user)}
                        className="notification-item-link d-flex no-link"
                        state={
                          item?.type !== "nouveau"
                            ? item?.type === "message"
                              ? ""
                              : item
                            : item?.data
                        }
                      >
                        {/* <div className="content-img-profil-notif">
                          <img
                            src={Profil}
                            alt="profil"
                            className="img-profil-notif"
                          />
                        </div> */}
                        <div className="content-text-notif ps-2">
                          <p
                            className="text-notif mb-1"
                            dangerouslySetInnerHTML={createMarkup(
                              item?.content
                            )}
                          />
                          <p className="text-date-notif mb-0">
                            {moment(item?.created_at).format(
                              "DD/MM/yy - HH:mm"
                            )}
                          </p>
                        </div>
                      </NavLink>
                    </li>
                  ))
              )}
            </ul>
          </div>
        )}
      </nav>
      <ViewNotifModal item={item} />
      <div
        className="modal fade"
        id="offresModal"
        aria-labelledby="offresModalLabel"
        aria-hidden="true"
        style={{ zIndex: 9999 }}
      >
        <OffersModal
          type="proprietaire"
          setOffer={setOffer}
          modalId="paymentMethod"
        />
      </div>
      <div
        className="modal fade"
        id="ChoichePayementMethodeMensualite"
        aria-labelledby="ChoichePayementMethodeMensualite"
        aria-hidden="true"
      >
        <ChoichePayementMethodeMensualite
          onPressSubmit={onSubmit} // Passer onSubmit comme fonction onPay
          isLoading={loading}
          mode={mode}
        />
      </div>
      <CheckStatusPaymentModal
        isLoading={onLoading}
        onCheckStatus={onCheckStatus}
        seconds={seconds}
        disabled={disabled}
        modalId="verifStatutPayment"
      />
      <CheckStatusPaymentModal
        isLoading={onLoading}
        onCheckStatus={onCheckStatusDif}
        seconds={seconds}
        disabled={disabled}
        modalId="verifStatutPaymentDif"
      />
    </div>
  );
};

export default HeaderAdmin;

function getRoute(item: INotification, user: IUser) {
  let route = "/connexion";
  if (item?.type === "message_contact") {
    route = "/admin/lead";
  }

  if (item?.type === "requete") {
    if (isLocataire(user)) {
      route = `/locataire/requetes/${item?.data?.slug}`;
    }

    if (isAgence(user)) {
      route = `/agence/locataires/requete/${item?.data?.slug}`;
    }
  }

  if (item?.type === "litige") {
    if (isLocataire(user)) {
      route = `/locataire/litige/${item?.data?.slug}`;
    }
    if (isAgence(user) || isAgenceJuriste(user)) {
      route = `/agence/locataires`;
    }
  }
  if (item?.type === "creation_locataire") {
    if (isAgence(user) || user?.agence_id) {
      route = `/agence/liste-locataire/${item?.data?.slug}`;
    }

    if (isProprietaireOwner(user) || user?.proprietaire_id) {
      route = `/proprietaire/gerer-par-le-proprietaire/locataires`;
    }
    // if (isLocataire(user)) {
    //   route = `/locataire/administratif`;
    // }
  }

  if (item?.type === "nouveau") {
    if (isLocataire(user)) {
      route = `/locataire/administratif`;
    }
  }

  if (item?.type === "message") {
    route = "/admin/messages";
    if (isLocataire(user)) {
      if (!item?.content?.includes("Rappel de paiement")) {
        route = `/locataire/messages`;
      } else {
        route = "";
      }
    }

    if (isAgence(user)) {
      route = `/agence/messagerie`;
    }

    if (isComptable(user)) {
      route = `/comptable/messagerie`;
    }

    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/messages`;
    }

    if (isProprietaireOwner(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/messages`;
    }
  }

  if (item?.type === "facture") {
    if (isLocataire(user)) {
      route = `/locataire/administratif`;
    }
  }

  if (item?.type === "depenses") {
    route = "/comptable/depense";
  }

  if (item?.type === "depense") {
    if (isAgence(user)) {
      route = `/agence/comptabilite`;
    }
    if (isComptable(user)) {
      route = `/comptable/depenses`;
    }
  }

  if (item?.type === "recette") {
    route = "/admin/recettes";
  }

  if (item?.type === "creation_proprietaire") {
    if (isAgence(user) || user?.agence_id) {
      route = `/agence/proprietaires`;
    }

    if (isAdminUser(user)) {
      route = `/admin/mes-clients`;
    }
  }

  return route;
}
