import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";
import {
  ChargeRequeteType,
  ITypeRequete,
} from "../../../utils/api/typeRequete/typeRequete.type";
import { BtnCloseModal, BtnSubmit } from "../../common/Button";
import UseCrudDetailTyperequete from "../../TableauDebord/Admin/TypeRequetes/UseCrudDetailTyperequete";

function AjoutDetailTypeRequeteModal({ item }: { item?: ITypeRequete }) {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    fields,
    append,
    remove,
    reset,
    defaultValue,
  } = UseCrudDetailTyperequete(item);
  return (
    <div className="modal-dialog modal-md modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            Ajouter un nom
          </h5>
          <BtnCloseModal label="" onClick={reset} />
        </div>
        <form action="" onSubmit={onSubmit}>
          <div className="row py-4 px-3">
            {fields.map((field, index) => {
              return (
                <React.Fragment key={field.id}>
                    <div className={`${fields?.length > 1 && "custombordermodal"} `}>

                            <div className="col-md-12 form-group mb-4 position-relative">
                                <label
                                    htmlFor="nom"
                                    className="form-label form-label-modal-custom"
                                >
                                    Nom
                                </label>
                                <input
                                id="nom"
                                placeholder="Nom"
                                type="text"
                                className="form-control form-select-modal-custom mb-1"
                                {...register(
                                    `detail_type_requetes.${index}.nom` as const
                                )}
                                />
                                {errors?.detail_type_requetes?.[index]?.nom && (
                                <span className="text-danger fw-400">
                                    {errors?.detail_type_requetes?.[index]?.nom?.message}
                                </span>
                                )}
                            </div>
                            <label
                                    htmlFor="charge"
                                    className="form-label form-label-modal-custom"
                                >
                                    Prise en charge
                                </label>
                            <div
                                className={`col-md-12 form-group mb-4 position-relative`}
                                key={field.id}
                            >
                                <select
                                id="charge"
                                className="form-select form-select-modal-custom mb-2"
                                {...register(
                                    `detail_type_requetes.${index}.charge` as const
                                )}
                                >
                                <option value="" selected disabled>
                                    Selectionner le type
                                </option>
                                {chargeRequeteOption?.map((item) => (
                                    <option value={item.value} key={item.value}>
                                    {item?.label}
                                    </option>
                                ))}
                                </select>
                                {errors?.detail_type_requetes?.[index]?.charge && (
                                <span className="text-danger fw-400">
                                    {errors?.detail_type_requetes?.[index]?.charge?.message}
                                </span>
                                )}
                            </div>
                    </div>
                  <div className="btn-box mt-3 gap-2 d-flex ">
                    {fields.length - 1 === index && (
                      <button
                        onClick={() => append(defaultValue)}
                        type="button"
                        className="btn btn-theme p-2"
                        style={{ fontSize: 22 }}
                      >
                        <IoMdAddCircleOutline />{" "}
                      </button>
                    )}
                    {index > 0 && (
                      <button
                        className="btn-delete-input btn p-2"
                        onClick={() => remove(index)}
                        type="button"
                      >
                        <AiOutlineCloseCircle />{" "}
                      </button>
                    )}
                  </div>
                </React.Fragment>
              );
            })}

            <div className="flex-r">
              <BtnSubmit
                label="Ajouter"
                isLoading={isLoading}
                style={{ padding: "5px 10px", fontSize: 14 }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AjoutDetailTypeRequeteModal;

const chargeRequeteOption = [
  {
    value: ChargeRequeteType.agence,
    label: "Agence",
  },
  {
    value: ChargeRequeteType.proprietaire,
    label: "Propriétaire",
  },
  {
    value: ChargeRequeteType.locataire,
    label: "Locataire",
  },
];
