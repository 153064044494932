import React from "react";
import PhoneInput from "react-phone-input-2";
import { BtnSubmit } from "../../../../common/Button";
import { FormError } from "../../../../common/CustomInputCheckbox";
import UseAddOrUpdateAccountInfo from "../requestForm/UseAddOrUpdateAccountInfo";
import SignatureUpload from "../AddSignatureModal";
import { useMeQuery } from "../../../../../utils/api/user/user.api";

function AccountInfo() {
  const {
    register,
    errors,
    phone,
    setPhone,
    onSubmit,
    mode,
    handleChangeMode,
    setValue,
    isLoading,
  } = UseAddOrUpdateAccountInfo();
  const { data } = useMeQuery();
  return (
    <div className="kanimmo-dash-locataire-param-main-page-container py-4 mb-3">
      <div className="kanimmo-dash-locataire-param-title mb-4">
        Informations de paiement
      </div>
      <div className="kanimmo-dash-locataire-param-password-container">
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  htmlFor="mode"
                  className="form-label form-label-modal-custom"
                >
                  Vous souhaitez recevoir vos paiement par ?{" "}
                  <span className="text-danger" style={{ fontSize: 24 }}>
                    *
                  </span>
                </label>
                <select
                  name="mode"
                  className="form-control form-control-modal-custom form-control-params-custom"
                  onChange={(e) => handleChangeMode(e)}
                  value={mode}
                  id="mode"
                >
                  <option value="">Selectionnez un mode de paiement</option>
                  <option value="ORANGE_SN_API_CASH_IN">Orange Money</option>
                  <option value="WAVE_SN_API_CASH_IN">Wave</option>
                  {/* <option value="FREE_SN_WALLET_CASH_IN">Free Money</option>
                  <option value="EXPRESSO_SN_WALLET_CASH_IN">
                    Expresso Money
                  </option>
                  <option value="BANK_TRANSFER_SN_API_CASH_IN">
                    Virement bancaire
                  </option> */}
                </select>

                <FormError error={errors?.mode?.message} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  htmlFor="tel"
                  className="form-label form-label-modal-custom"
                >
                  Téléphone{" "}
                  <span className="text-danger" style={{ fontSize: 24 }}>
                    *
                  </span>
                </label>
                <PhoneInput
                  defaultMask={".. ... .. .."}
                  containerClass="form-control auth-form-control mb-3 p-0"
                  inputClass="form-control form-control-modal-custom form-control-params-custom"
                  country={"sn"}
                  placeholder=""
                  inputProps={{
                    name: "telephone",
                    required: true,
                    autoFocus: true,
                  }}
                  countryCodeEditable={false}
                  enableAreaCodes={true}
                  prefix="+"
                  value={phone}
                  onChange={(phone, country: any) => {
                    setPhone(phone);

                    if (
                      country?.format?.match(/\./g)?.length === phone?.length
                    ) {
                      setValue("telephone", phone);
                    }
                  }}
                  inputStyle={{
                    width: "100%",
                    paddingBottom: "22px",
                    borderRadius: "10px",
                  }}
                />
                <FormError error={errors?.telephone?.message} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  htmlFor="prenomId"
                  className="form-label form-label-modal-custom"
                >
                  Prénom{" "}
                  <span className="text-danger" style={{ fontSize: 24 }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Prénom"
                  className="form-control form-control-modal-custom form-control-params-custom"
                  id="prenomId"
                  {...register("prenom")}
                />
                <FormError error={errors?.prenom?.message} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  htmlFor="nomId"
                  className="form-label form-label-modal-custom"
                >
                  Nom{" "}
                  <span className="text-danger" style={{ fontSize: 24 }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Nom"
                  className="form-control form-control-modal-custom form-control-params-custom"
                  id="nomid"
                  {...register("nom")}
                />
                <FormError error={errors?.nom?.message} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  htmlFor="emailId"
                  className="form-label form-label-modal-custom"
                >
                  Email{" "}
                  <span className="text-danger" style={{ fontSize: 24 }}>
                    *
                  </span>
                </label>
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control form-control-modal-custom form-control-params-custom"
                  id="emailId"
                  {...register("email")}
                />
                <FormError error={errors?.email?.message} />
              </div>
            </div>

            {mode === "BANK_TRANSFER_SN_API_CASH_IN" && (
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="rib"
                    className="form-label form-label-modal-custom"
                  >
                    RIB{" "}
                    <span className="text-danger" style={{ fontSize: 24 }}>
                      *
                    </span>
                  </label>
                  <input
                    type="number"
                    placeholder="RIB"
                    className="form-control form-control-modal-custom form-control-params-custom"
                    id="rib"
                    {...register("rib")}
                  />
                  <FormError error={errors?.rib?.message} />
                </div>
              </div>
            )}
          </div>
          {/* <SignatureUpload userSlug={data?.slug} /> */}
          <div className="container-btn-modal row my-4">
            <div className="col-md-12">
              <div className="d-flex gap-3 justify-content-end">
                {/* <BtnSubmit label="Enregistrer" isLoading={isLoading} /> */}
                <button
                  className="btn auth-submit-btn"
                  type="submit"
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    "Enregistrer"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>Chargement...</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AccountInfo;
