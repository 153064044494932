import React from "react";
import { Offer } from "../../../../../utils/api/offer/offer.type";
import { BtnCloseModal, BtnSubmit } from "../../../../common/Button";
import { Input } from "../../../../common/Input";
import { FormError } from "../../../../common/CustomInputCheckbox";
import ReactQuill from "react-quill";
import { SelectInput } from "../../../../common/SelectInput";
import UseCrudOfferForm from "../request/UseCrudOfferForm";
import ErrorMessage from "../../../../common/ErrorMessage";

let options = [
  {
    label: "Agences",
    value: "agence",
  },
  {
    label: "Propriétaires",
    value: "proprietaire",
  },
];

function CrudOfferModal({
  modalId,
  offer,
}: {
  modalId: string;
  offer?: Offer;
}) {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    description,
    onChangeDescription,
    handleReset,
    mode,
    handleChange,
  } = UseCrudOfferForm(modalId, offer);
  const optionsLibelle = [
    { value: "Mensuel", label: "Mensuel" },
    { value: "Trimestriel", label: "Trimestriel" },
    { value: "Semestriel", label: "Semestriel" },
    { value: "Annuel", label: "Annuel" },
  ];

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header px-2 py-1">
            <h5 className="modal-title" id="AddTagModalLabel">
              {offer ? "Modifier " : "Ajouter "} une offre
            </h5>
            <BtnCloseModal label="" onClick={() => handleReset()} />
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit}>
              <div className="row py-4 px-3">
                <div className="col-md-6 mb-3 ">
                  <Input
                    label="Minimum"
                    id="min"
                    min={0}
                    type="number"
                    required
                    placeholder="À partir de"
                    {...register("min_numb")}
                    error={errors?.min_numb?.message}
                  />
                </div>
                <div className="col-md-6 mb-3 ">
                  <Input
                    label="Maximum"
                    id="max"
                    type="number"
                    min={0}
                    placeholder="Jusqu'à"
                    {...register("max_numb")}
                    error={errors?.max_numb?.message}
                  />
                </div>

                <div className="col-md-12 auth-input-col">
                  <div className="form-group auth-form-group text-start">
                    <label className="form-label form-label-login">
                      Cette offre est-elle gratuite ?
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="d-flex align-items-center">
                      <div className="checkbox">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="gratuite"
                          name="gratuit"
                          checked={mode === "gratuite"}
                          id="flexCheckDefaulttrue"
                          onChange={(e) => handleChange(e)}
                        />
                        <label
                          className="form-check-label form-label-login ms-2"
                          htmlFor="flexCheckDefaulttrue"
                        >
                          OUI
                        </label>
                      </div>
                      <div className="checkbox ms-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="payante"
                          name="gratuit"
                          checked={mode === "payante"}
                          id="flexCheckDefaultfalse"
                          onChange={(e) => handleChange(e)}
                        />
                        <label
                          className="form-check-label form-label-login ms-2"
                          htmlFor="flexCheckDefaultfalse"
                        >
                          NON
                        </label>
                      </div>
                    </div>

                    <ErrorMessage message={errors?.gratuit?.message} />
                  </div>
                </div>
                {mode === "payante" && (
                  <div className="col-md-6 mb-3 ">
                    <Input
                      label="Montant"
                      id="prix"
                      type="number"
                      required
                      min={0}
                      placeholder="Montant"
                      {...register("montant")}
                      error={errors?.montant?.message}
                    />
                  </div>
                )}

                <div className="col-md-6 mb-3">
                  <SelectInput
                    options={options}
                    label="Type d'offre"
                    required
                    id="type"
                    {...register("type")}
                    error={errors?.type?.message}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <SelectInput
                    options={optionsLibelle}
                    label="Périodicité"
                    required
                    id="type"
                    {...register("libelle")}
                    error={errors?.libelle?.message}
                  />
                </div>
                <div className="col-md-6 mb-3 ">
                  <Input
                    label="Durée"
                    id="duree"
                    type="number"
                    min={1}
                    placeholder="La durée de l'offre en moi"
                    {...register("duree")}
                    error={errors?.duree?.message}
                  />
                </div>

                <div className="col-md-12 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor={"description"}
                      className="form-label form-label-modal-custom"
                      aria-labelledby={"description"}
                    >
                      Description
                    </label>
                    <ReactQuill
                      className="editor-cgu"
                      value={description}
                      onChange={(value: string) => onChangeDescription(value)}
                    />

                    {<FormError error={errors?.description?.message} />}
                  </div>
                </div>

                <div className="flex-r">
                  <BtnSubmit
                    label={offer ? "Modifier" : "Ajouter"}
                    isLoading={isLoading}
                    style={{ padding: "5px 10px", fontSize: 14 }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrudOfferModal;
