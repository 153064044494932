import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, closeModal, onHide, formatMontant } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import {
  ValidationFormData,
} from "../../../../../utils/api/validationGerant/validation.type";
import { useValdationCreateMutation } from "../../../../../utils/api/validationGerant/validation.api";
import { useAppSelector } from "../../../../../redux/hooks";



export function useValdation(item: any, dataResult: any) {

  const {
    register,
 
  } = useForm<ValidationFormData>({});
  const user = useAppSelector((s) => s.user.user);
  const [addValidationGerant, { isLoading, isSuccess, error, isError }] =
  useValdationCreateMutation();

  const onSubmit = () => {
    const data: ValidationFormData = {
      gerant: user.id,
      gerant_name: user.prenom + "" + user.nom,
      proprietaire_name: item.prenom + " " + item.nom,
      proprietaire: item.id,
      proprietaire_slug : item.slug,
      est_validee: false
    };

    addValidationGerant({ data: data });
};

  const onSendValidation = async () => {
    await Swal.fire({
      title: `Validaton de transfer.`,
      html: `     
      <div class="place-card__content">
        <div class="payment-details-container">
          <div class="payment-details-content">
            <p class="payment-details-title">
              <span class="card-text-logement"> Commission: </span>
            </p>
            <p class="payment-details-amount">
              ${
                dataResult?.results?.items[0]?.propriete?.commission_agence
              } %
            </p>
          </div>
          <div class="payment-details-content">
            <p class="payment-details-title">
              <span class="card-text-logement">Propriétaire </span>
            </p>
            <p class="payment-details-amount">
            ${item?.prenom + " " + item?.nom}
            </p>
          </div>
          <div class="payment-details-content">
            <p class="payment-details-title">
              <span class="card-text-logement"> Bien géré: </span>
            </p>
            <p class="payment-details-amount">
            ${
              dataResult?.results?.items[0]?.propriete.nom
            }
            </p>
          </div>
          <div class="payment-details-total">
            <div class="payment-details-content">
              <p class="payment-details-title">
                <span class="card-text-logement"> Dépenses: </span>
              </p>
              <p class="payment-details-amount">
              ${formatMontant(
                dataResult?.results?.total_depense
              )}
              </p>
            </div>
          </div>
          
          <div class="payment-details-total">
            <div class="payment-details-content">
              <p class="payment-details-title">
                <span class="card-text-logement"> Montant à transférer: </span>
              </p>
              <p class="payment-details-amount">
                 ${formatMontant(
                  dataResult?.results?.total_restant
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    `,
      text: "Veuillez cliquer sur envoyer si vous voulez continuer!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ENVOYER",
      cancelButtonText: "ANNULER",
      showLoaderOnConfirm: true,
      iconColor: Color.bleu,
      confirmButtonColor: Color.bleu,
      preConfirm: () => {
        return onSubmit();
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: "La demande de validation a été envoyer avec succès!",
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue lors du transfère.`,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  return {
    register,
    onSendValidation,
    isLoading,
  };
}