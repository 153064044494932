import React from "react";
import { AiFillPlusSquare } from "react-icons/ai";
import AjouterFactureModal from "../../../../modal/agence/AjouterFactureModal";
import FactureAgenceTable from "../../../../tables/agence/Administratif/Facture/FactureAgenceTable";

function Factures({
	searchParams,
	setSearchParams,
	AgenceSlug,
	is_Agence
}: {
	searchParams: any;
	setSearchParams: any;
	AgenceSlug: string;
	is_Agence: boolean
}) {
	const word = searchParams.get("name");
	const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
		if (e.currentTarget.value) {
			setSearchParams({
				name: e.currentTarget.value,
			});
		} else {
			setSearchParams({});
		}
	};
	return (
    <div className="bg-white p-3">
      <div className="locataire-requete-filtre-container flex-sb my-4">
        <form className="row g-3 locataire-requete-filtre-form">
          <div className="col-auto">
            <label
              htmlFor="filtreLogement"
              className="locataire-requete-filtre-label"
            >
              Filtrer
            </label>
          </div>
          <div className="col-auto">
            <input
              type="text"
              className="form-control locataire-requete-filtre-input"
              id="filtreLogement"
              placeholder="Entrer un nom"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleFilterByName(e)
              }
            />
          </div>
        </form>
        <button
          className="btn btn-see-more"
          data-bs-toggle="modal"
          data-bs-target="#AjouterFactureModal"
        >
          <AiFillPlusSquare style={{ fontSize: 22 }} />
          <span className="ps-2">Générer</span>
        </button>
      </div>
      <div>
        <FactureAgenceTable
          name={word}
          AgenceSlug={AgenceSlug}
          isAgence={is_Agence}
        />
      </div>
      <AjouterFactureModal />
    </div>
  );
}

export default Factures;
