import React from "react";
import { IEtatLieu } from "../../../../utils/api/etatlieu/etatlieu.type";
import { ApiBaseUrl } from "../../../../utils/http";

function LocataireDocumentEtatLieux({ item }: { item?: IEtatLieu }) {
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#AjouterEtatLieuLocataireModal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            <iframe
              src={`http://docs.google.com/gview?url=${
                ApiBaseUrl + item?.document
              }&embedded=true`}
              width="100%"
              height="500px"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocataireDocumentEtatLieux;
