import React, { useState } from "react";
import { useAppSelector } from "../../../../../../redux/hooks";
// import { useGetImmeublesByUserQuery } from "../../../../../../utils/api/immeubles/immeubles.api";
import { IImmeubles } from "../../../../../../utils/api/immeubles/immeubles.type";
// import { useGetImmeublesByUserQuery } from "../../../../../../utils/api/propriete/propriete.api";
import { useGetImmeublesByProprietaireQGSBQuery } from "../../../../../../utils/api/propriete/propriete.api";
import { IPropriete } from "../../../../../../utils/api/propriete/propriete.type";
import { AlertInfo } from "../../../../../common/Alert";
import Pagination from "../../../../../common/Pagination";
import ProprieteItem from "../../../../Agence/ProprietesAgence/ProprieteItem";
import { ProprieteCardSkeleton } from "../../../../Agence/ProprietesAgence/ProprietesAgence";
// import ProprietesListe from "../ProprietesListe";

function ImmeublesPQGSB({
  handleFilterByUpToDate,
  handleFilterByName,
  type_propriete,
  name,
}: {
  handleFilterByUpToDate: () => void;
  handleFilterByName: () => void;
  type_propriete: string;
  name: string;
}) {
  const { user } = useAppSelector((s) => s?.user);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(9);
  const { data: immeubles = { results: [] }, isLoading } =
    useGetImmeublesByProprietaireQGSBQuery({
      slug: user?.proprietaire_id?.slug
        ? user?.proprietaire_id?.slug
        : user?.slug,
      page: page,
      limit: perPage,
      type_propriete,
      name,
    });

  // console.log("immeubles proprietaire", immeubles)
  return (
    <div className="bg-white p-3 filter-and-table-container">
      <div className="d-flex gap-3">
        <div className="locataire-requete-filtre-container my-4">
          <div className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                placeholder="Entrer un nom"
                onChange={handleFilterByName}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="p-0 p-md-4">
            <ProprietesListe name={word} type_propriete={type_propriete} />
          </div> */}

      <div className="content-proprietes locataire-requete-filtre-container mt-5">
        <div className="row">
          {!isLoading &&
            immeubles?.results?.map((item: IPropriete | IImmeubles | any) => (
              <ProprieteItem item={item} />
            ))}
          {isLoading &&
            [...Array(6)]?.map((item, i) => <ProprieteCardSkeleton key={i} />)}
          {!isLoading &&
            // proprietes &&
            (immeubles?.results?.length === 0 || immeubles == undefined) && (
              <AlertInfo message="Aucune propriété trouvée" />
            )}
        </div>
        {immeubles && immeubles?.results?.length > perPage && (
          <div className="flex-r mb-t">
            <Pagination
              page={page}
              total={immeubles?.count}
              perPage={perPage}
              onPageChange={(page: number) => setPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ImmeublesPQGSB;
