import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../../utils/Utils";
import {
	useLazyFindNewsBySlugQuery,
	usePostResponseCommentMutation,
} from "../../../../../utils/api/new/news.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { IComment, ResponseCommentFormData } from "../../../../../utils/api/new/news.type";

export function usePostResponseComment(item?: IComment, newSlug?: string, isConnected?: boolean) {
	const validationSchema = yup.object().shape({
		content: yup.string().required("Ce champ est obligatoire"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
		setValue,
	} = useForm<ResponseCommentFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [response, setResponse] = useState<string>();
	const [postResponseComment, { isLoading, isSuccess, error, isError }] =
		usePostResponseCommentMutation();
	const { user: authUser } = useAppSelector((s) => s?.user);
	const [findBySlug] = useLazyFindNewsBySlugQuery();

	const handleChangeResponseCommentField = (value: string) => {
		setResponse(value);
		setValue("content", value);
	};
	useEffect(() => {
		if (isSuccess) {
			setResponse("");
			setValue("content", "");
			findBySlug(newSlug as string);
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (data: ResponseCommentFormData) => {
		const newData = {
			...data,
			comment: item?.id as number,
			auteur: isConnected ? authUser?.id as number : null,
		};
		postResponseComment(newData);
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		reset,
		handleChangeResponseCommentField,
		response,
	};
}
