import React from "react";
import Skeleton from "react-loading-skeleton";

function EtatDesLieuxSkeleton() {
	return (
		<div className="col-md-12">
			<table className="table table-striped">
				<thead>
					<tr>
						<th
							scope="col"
							style={{
								fontFamily: "Raleway",
								fontStyle: "normal",
								fontWeight: "500",
								fontSize: "16px",
								lineHeight: "19px",
								color: "#AAB2C8",
							}}
						>
							Date
						</th>
						<th
							scope="col"
							style={{
								fontFamily: "Raleway",
								fontStyle: "normal",
								fontWeight: "500",
								fontSize: "16px",
								lineHeight: "19px",
								color: "#AAB2C8",
							}}
						>
							Client
						</th>
						<th
							scope="col"
							style={{
								fontFamily: "Raleway",
								fontStyle: "normal",
								fontWeight: "500",
								fontSize: "16px",
								lineHeight: "19px",
								color: "#AAB2C8",
							}}
						>
							Locations
						</th>
						<th
							scope="col"
							style={{
								fontFamily: "Raleway",
								fontStyle: "normal",
								fontWeight: "500",
								fontSize: "16px",
								lineHeight: "19px",
								color: "#AAB2C8",
							}}
						>
							Document
						</th>
						<th
							scope="col"
							style={{
								fontFamily: "Raleway",
								fontStyle: "normal",
								fontWeight: "500",
								fontSize: "16px",
								lineHeight: "19px",
								color: "#AAB2C8",
							}}
						>
							Action
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row">
							<Skeleton />
						</th>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
					</tr>
					<tr>
						<th scope="row">
							<Skeleton />
						</th>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
					</tr>
					<tr>
						<th scope="row">
							<Skeleton />
						</th>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
					</tr>
					<tr>
						<th scope="row">
							<Skeleton />
						</th>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export default EtatDesLieuxSkeleton;
