import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, onHide } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import {
  CategoryFormData,
  ICategory,
} from "../../../../../utils/api/category/category.type";
import { useAddOrUpdateCategoryMutation } from "../../../../../utils/api/category/category.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { useLocation } from "react-router-dom";

function useCrudCaterie(
  modalId: string,
  item?: ICategory,
  handleToggle?: () => void,
  isAccountingPage?: boolean
) {
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Nom"),
  });

  const location = useLocation();
  const urlPageActuel = location.pathname;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<CategoryFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { user } = useAppSelector((state) => state.user);

  const [addOrUpdateCategorie, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateCategoryMutation();

  useEffect(() => {
    // if (isSuccess) {
    // 	Swal.fire({
    // 		icon: "success",
    // 		title: `Catégorie ${item ? "modifiée" : "ajoutée"} avec succès!`,
    // 		iconColor: Color.success,
    // 		showConfirmButton: false,
    // 		timer: 1200,
    // 	}).then(() => {
    // 		// onHide("AddCategorieModal");
    // 		// onHide(modalId);
    // 		if (isAccountingPage) {
    // 			onHide(modalId);
    // 		}
    // 		if(!item) {
    // 			reset()
    // 		};
    // 		if (!item && isAccountingPage && handleToggle) {
    //             reset();
    //             handleToggle();
    //         }
    // 	});
    // }

    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Catégorie ${item ? "modifiée" : "ajoutée"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        // if(urlPageActuel !== "/agence/comptabilite") {

        // 	onHide(modalId);
        // }

        // if(urlPageActuel !== "/proprietaire/gerer-par-le-proprietaire/comptabilite") {

        // 	onHide(modalId);
        // }

        if (
          urlPageActuel !== "/agence/comptabilite" &&
          urlPageActuel !==
            "/proprietaire/gerer-par-le-proprietaire/comptabilite" &&
          urlPageActuel !== "/comptable/depenses"
        ) {
          onHide(modalId);
        }

        if (!item) {
          reset();
        }
        if (isAccountingPage && handleToggle) {
          reset();
          handleToggle();
        }
      });
    }

    const err = error as any;
    if (isError) {
      const err = error as any;
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      }).then(() => {
        // Ajoutez ici le code pour gérer l'erreur si nécessaire
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      setValue("nom", item?.nom);
    }
  }, [item]);
  const onSubmit = (data: CategoryFormData) => {
    const dataWithUser: CategoryFormData = {
      ...data,
      user: user.id,
    };
    console.log("data", data);
    addOrUpdateCategorie({ slug: item?.slug, data: dataWithUser });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    reset,
  };
}

export default useCrudCaterie;
