import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EntreeFormData } from "../../../../../../utils/api/entree/entree.type";
import { cleannerError, onHide } from "../../../../../../utils/Utils";
import { useAppSelector } from "../../../../../../redux/hooks";
import { Color } from "../../../../../../utils/theme";
import { useGetProprietesByProprietaireQuery } from "../../../../../../utils/api/propriete/propriete.api";
import {
  useAddOrUpdateEntreePQGSBMutation,
  useGetLocataireByProprietaireBySlugQuery,
} from "../../../../../../utils/api/proprietaire/proprietaire.api";
import { useLazyGetLocataireByProprieteSlugQuery } from "../../../../../../utils/api/locataire/locataire.api";
import { ILocataire } from "../../../../../../utils/api/locataire/locataire.type";

function useEntreePQGSBForm(modalId: string, entree?: EntreeFormData) {
  const validationSchema = yup.object().shape({
    montant: yup.number().required().label("Montant"),
    propriete: yup.string().label("Villa"),
    categorie_entree: yup.string().label("Catégorie"),
    locataire: yup.string().label("Client"),
    justificatif: yup.mixed().nullable().label("Justificatif"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
    control,
  } = useForm<EntreeFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [message, setMessage] = useState<string>("");
  const [options, setOptions] = useState<any>([]);
  const [option, setOption] = useState<any>();
  const [locataireItem, setLocataireItem] = useState<any>();

  const [addOrUpdateEntree, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateEntreePQGSBMutation();
  const { user } = useAppSelector((s) => s?.user);
  const userID = user?.id;

  const [locataires, setLocataires] = useState<
    { label: string; value: number }[] | undefined
  >([]);

  const { data: locatProprio } = useGetLocataireByProprietaireBySlugQuery({
    slug:
      user?.user_type === "gerant" ? user?.proprietaire_id.slug : user?.slug,
  });

  const { data: proprieteProprio } = useGetProprietesByProprietaireQuery({
    slug:
      user?.user_type === "gerant" ? user?.proprietaire_id.slug : user?.slug,
    limit: 10000,
  });
  const [getLocataires, { data: locataire = { results: [] } }] =
    useLazyGetLocataireByProprieteSlugQuery();

  useEffect(() => {
    const propriete = proprieteProprio?.results?.filter(
      (item) => item?.type_propriete !== "immeuble"
    );
    setOptions(
      propriete?.map((item) => {
        return {
          label: item?.nom,
          value: item?.id,
          ...item,
        };
      })
    );
    //   setFilterProperties(propriete);
  }, [proprieteProprio?.results]);

  useEffect(() => {
    if (locatProprio?.results?.length) {
      // console.log("locataire?.results", locataire?.results);
      setLocataires(
        locatProprio?.results
          ?.filter((item: any) => item?.propriete?.id === option?.id)
          ?.map((el: any) => {
            return {
              label: el?.prenom + " " + el?.nom,
              value: el?.id,
              ...el,
            };
          })
      );

      let locataireEl: any = locatProprio?.results?.find(
        (item: any) => item?.propriete?.id === option?.id
      );
      if (locataireEl) {
        setValue("locataire", locataireEl?.id);
        setLocataireItem({
          label: locataireEl?.prenom + " " + locataireEl?.nom,
          value: locataireEl?.id,
          ...locataireEl,
        });
      } else {
        setValue("locataire", "");
        setLocataireItem("");
      }
    } else {
      setValue("locataire", "");
      setLocataires([]);
      setLocataireItem("");
    }
  }, [option, locataire?.results?.length]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: entree
          ? "Entrée modifiée avec succès !"
          : "Entrée ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!entree) {
          reset();
          setOption("");
          setLocataireItem("");
          setMessage("");
        }
        onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const handleChangePropriete = (e: any) => {
    setOption(e);
    setValue("propriete", e?.value);
    if (e?.value) {
      getLocataires({ slug: e?.slug });
    }
  };

  const handleChangeLocataire = (e: any) => {
    setValue("locataire", e?.value);
  };
  const handleJustificatif = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif" &&
      file?.type !== "application/pdf"
    ) {
      return setError("justificatif", {
        message: "Image format is incorrect.",
      });
    }
    if (file) {
      register("justificatif");
      setValue("justificatif", file);
    }
  }, []);

  useEffect(() => {
    if (entree?.id) {
      const fields: (keyof EntreeFormData)[] = [
        "montant",
        "categorie_entree",
        "propriete",
        "locataire",
      ];

      for (let field of fields) {
        register(field);
        if (
          field === "categorie_entree" ||
          field === "propriete" ||
          field === "justificatif"
        ) {
          setValue(field, entree[field]?.id);
        } else if (field === "locataire") {
          if (entree?.locataire?.id) {
            setValue("locataire", entree?.locataire?.id);
          } else if (entree?.occupant?.id) {
            setValue("locataire", entree?.occupant?.id);
          }
        } else {
          setValue(field, entree[field]);
        }
        if (field === "locataire") {
          setValue("locataire", entree?.locataire?.id);
          const locataires = proprieteProprio?.results
            ?.find((item) => item?.id === parseInt(entree?.propriete?.id))
            ?.locataires?.map((locataire) => {
              return {
                label: locataire?.prenom + " " + locataire?.nom,
                value: locataire?.id,
              };
            });
          setLocataires(locataires);
        }
      }
      if (entree?.propriete?.id) {
        getLocataires({ slug: entree?.propriete?.slug });
        setOption({
          label: entree?.propriete?.nom,
          value: entree?.propriete?.id,
          ...entree?.propriete,
        });
      }
    }
  }, [entree]);

  const onSubmit = (data: any) => {
    if (!entree) {
      data["proprietaire"] = user?.proprietaire_id
        ? user?.proprietaire_id?.id
        : user?.id;
    }

    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "justificatif") {
        if (data[key] && data[key] !== undefined) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    // console.log("data submitted", data);
    addOrUpdateEntree({ slug: entree?.slug, data: fd });
  };

  const handleReset = () => {
    if (!entree) {
      reset();
      setOption("");
      setLocataireItem("");
      setMessage("");
    }
  };

  const handleChangeMessage = useCallback((value: string) => {
    setMessage(value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    message,
    handleChangeMessage,
    handleReset,
    handleJustificatif,
    handleChangePropriete,
    locataires,
    proprietes: proprieteProprio?.results,
    Controller,
    control,
    option,
    options,
    locataireItem,
    handleChangeLocataire,
  };
}

export default useEntreePQGSBForm;
