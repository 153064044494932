import moment from "moment";
import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { IoMdTrash } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useGetLitigeByProprietaireBySlugQuery } from "../../../../../../utils/api/litiges/litiges.api";
import {
  ILitiges,
  TypesLitiges,
} from "../../../../../../utils/api/litiges/litiges.type";
import { createMarkup, isProprioJuriste } from "../../../../../../utils/Utils";
import { AlertInfo } from "../../../../../common/Alert";
import Pagination from "../../../../../common/Pagination";
import AjoutLitigesModalePQGSB from "../../../../../modal/agence/AjoutLitigesModalePQGSB";
import ChangerStatutLitigeModal from "../../../../../modal/agence/ChangerStatutLitigeModal";
import ChangerStatutRequeteModal from "../../../../../modal/agence/ChangerStatutRequeteModal";
import ChangeTypeLitigeModal from "../../../../../modal/agence/ChangeTypeLitigeModal";
import { UseDeleteLitige } from "../../../../Agence/Locataires/CRUDLocataire/useCrudLitige";
import { LitigeSkeleton } from "../../../../Agence/Locataires/ListeLocatairesTabs/ListeLitiges";

function Litiges() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const proprietaire = useAppSelector((s) => s.user.user);
  const { data = { results: [] }, isLoading } =
    useGetLitigeByProprietaireBySlugQuery({
      page,
      limit: perPage,
      slug: proprietaire?.proprietaire_id?.slug
        ? proprietaire?.proprietaire_id?.slug
        : proprietaire?.slug,
    });
  const [item, setItem] = useState<ILitiges>();
  const Status = [
    {
      label: "Assignation d’Expulsion",
      value: TypesLitiges.assignationExpulsion,
    },
    {
      label: "Commandement de payer",
      value: TypesLitiges.commandementPayer,
    },
    {
      label: "Jugement",
      value: TypesLitiges.jugement,
    },
    {
      value: "non_regler",
      label: "Non réglée",
    },
    {
      value: "encours",
      label: "En cours de traitement",
    },
    {
      value: "regler",
      label: "Réglée",
    },
    {
      value: "revision",
      label: `${
        isProprioJuriste(proprietaire)
          ? "Pour révision"
          : "Envoyé pour révision par le juriste"
      }`,
    },
  ];
  // console.log("liste litiges", data);
  return (
    <div className="bg-white py-3 pe-3">
      <div className="container-liste-litiges">
        {data?.results?.length === 0 && (
          <AlertInfo message="Aucune donnée trouvéé" />
        )}
        {!isLoading &&
          data?.results?.length > 0 &&
          data?.results?.map((item) => (
            <div
              className={`content-list-litiges custom-position-litige-container mb-2 ${
                item?.type_litige === "assignation_expulsion"
                  ? "content-list-litiges-assignation"
                  : item?.type_litige === "commandement_payer"
                  ? "content-list-litiges-commandement"
                  : "content-list-litiges-jugement"
              }`}
            >
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="table-actions-btn-container-commandes custom-btn-action-position gap-2 d-flex">
                    {/* {isAgenceJuriste(user) && ( */}
                    <div>
                      <NavLink
                        to={`/proprietaire/gerer-par-le-proprietaire/litige/${item?.slug}`}
                        className="btn btn-action-modal-icon"
                        title="Voir"
                        state={item}
                      >
                        <AiFillEye />
                      </NavLink>
                    </div>
                    {/* )} */}
                    {!isProprioJuriste(proprietaire) && (
                      <DeleteLocataireBtn item={item} />
                    )}
                  </div>

                  <div className="custom-btn-statut-pqgsb">
                    <button
                      className={`btn message-btn ${
                        item?.statut === "non_regler"
                          ? "bg-secondary"
                          : item?.statut === "regler"
                          ? "bg-success"
                          : "bg-secondary"
                      }`}
                      data-bs-toggle={`${
                        isProprioJuriste(proprietaire) && "modal"
                      }`}
                      data-bs-target={`${
                        isProprioJuriste(proprietaire) &&
                        "#ChangeStatusLitigeModal"
                      }`}
                      aria-expanded="false"
                      onClick={() => setItem(item)}
                    >
                      {
                        Status?.find((val) => val?.value === item?.statut)
                          ?.label
                      }
                    </button>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 md-pb-3 d-flex">
                  <div className="content-date-litige">
                    <div className="content-value-litige">
                      <p className="value-litige">
                        {moment(item?.created_at).format("DD/MM/YYYY à HH:mm")}
                      </p>
                    </div>
                    <div className="content-libelle-litige">
                      <p className="libelle-litige mb-0">Date d’ajout</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 md-pb-3 d-flex">
                  <div className="content-date-litige">
                    <div className="content-value-litige">
                      <p className="value-litige">
                        <NavLink
                          to={`/agence/liste-locataire/${item?.locataire?.slug}`}
                          state={item?.locataire}
                          className="no-link"
                        >
                          {item?.locataire?.prenom} {item?.locataire?.nom}
                        </NavLink>
                      </p>
                    </div>
                    <div className="content-libelle-litige">
                      <p className="libelle-litige mb-0">Locataire</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 md-pb-3 d-flex">
                  {!!item?.description && (
                    <div className="content-date-litige">
                      <div className="content-value-litige mb-3">
                        <div
                          className="custom-envoie-note-description"
                          dangerouslySetInnerHTML={createMarkup(
                            item?.description?.substring(0, 50) + "..."
                          )}
                        />
                      </div>
                      <div className="content-libelle-litige mb-2">
                        <p className="libelle-litige mb-0">Note</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-3 col-md-6 md-pb-3 flex-c">
                  <div className="content-date-litige">
                    <div className="content-value-litige">
                      <p className="value-litige-action">{item?.titre}</p>
                    </div>
                    <div className="content-libelle-litige">
                      <p className="libelle-litige mb-0">
                        Exécution du jugement
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {isLoading &&
          [...Array(6)].map((item, index) => {
            return <LitigeSkeleton key={index} />;
          })}
        {/* {data?.results?.length > perPage && ( */}
        <div className="flex-r">
          <Pagination
            page={page}
            total={data?.count}
            perPage={perPage}
            onPageChange={(page: number) => setPage(page)}
          />
        </div>
        <div
          className="modal fade"
          id="ChangeTypeRequeteModal"
          aria-labelledby="ChangeTypeRequeteModalLabel"
          aria-hidden="true"
        >
          <ChangeTypeLitigeModal
            modalId={"ChangeTypeRequeteModalLabel"}
            item={item}
          />
        </div>
        <div
          className="modal fade"
          id="ModifierLitigesModal"
          aria-labelledby="ModifierLitigesModalLabel"
          aria-hidden="true"
        >
          <AjoutLitigesModalePQGSB item={item} />
        </div>
        <div
          className="modal fade"
          id={`ChangeStatusLitigeModal`}
          aria-labelledby="ChangeStatusLitigeModalLabel"
          aria-hidden="true"
        >
          <ChangerStatutLitigeModal
            modalId={`ChangeStatusLitigeModal`}
            item={item}
          />
        </div>
      </div>
    </div>
  );
}

export default Litiges;

function DeleteLocataireBtn({ item }: { item: ILitiges }) {
  const onDelete = UseDeleteLitige(item);

  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
    >
      <IoMdTrash />
    </button>
  );
}
