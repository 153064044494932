import React, { useEffect, useState } from "react";
import "./SidebarAdmin.css";
import { AiFillSetting } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { IoDocumentText, IoLogOut } from "react-icons/io5";
import { GiInjustice, GiReceiveMoney } from "react-icons/gi";
import { BiCategory, BiGitPullRequest, BiNews } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { IoMdKey } from "react-icons/io";
import { BsInfoSquareFill } from "react-icons/bs";
import {
  ActivityIcon,
  SettingsIcon,
  PasswordIcon,
} from "../../../common/Button";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { onlogout } from "../../../../redux/slice/User.slice";
import {
  getAvatar,
  isAgenceAgent,
  isAgenceJuriste,
  isAgenceMarketeur,
  isProprietaireAgence,
  isProprietaireOwner,
  isProprioGerant,
  isProprioJuriste,
} from "../../../../utils/Utils";
import { isSuperAdmin } from "../../../../routes/router";
import {
  MdCategory,
  MdLocalOffer,
  MdRealEstateAgent,
  MdReportProblem,
} from "react-icons/md";
import VersionChecker from "../../../../versionChecker/VersionChecker";
import { useChatByUserQuery } from "../../../../utils/api/chat/chat.api";
import {
  useExpireAbonnementQuery,
  useUserCreatePersonelSubscriberQuery,
  usePqngpsbAgenceSubscribeQuery,
} from "../../../../utils/api/offer/expireataabonnement.api";
import {
  useNotificationNotReadQuery,
  useMarkAllAsReadMutation,
} from "../../../../utils/api/notification/notification.api";
// import ClocheIcon from "../../../../assets/icons/Notification.png";
import {
  useGetValdatonGerantsQuery,
  useReadDemmandeForAgenceMutation,
} from "../../../../utils/api/validationGerant/validation.api";

import {
  useMarkUserOflineMutation,
  useLoginwithisOnlineViewMutation,
} from "../../../../utils/api/auth/auth.api";

const SidebarAdmin = () => {
  const { user } = useAppSelector((state) => state.user);
  const userType = user?.user_type;
  const is_SuperAdmin = isSuperAdmin(user);
  const [count, setCount] = useState(0);
  const { data: chatData = [] } = useChatByUserQuery({});

  const { data, error, refetch, isLoading } = useExpireAbonnementQuery(
    user.slug
  );
  const { data: personnelAbonement } = useUserCreatePersonelSubscriberQuery(
    user.slug
  );

  const { data: pqgrsbAgence } = usePqngpsbAgenceSubscribeQuery(user.slug);

  const { data: notReadData, refetch: refetchNotReadData } =
    useNotificationNotReadQuery({ slug: user ? user.slug : "" });
  const [markAllasRead] = useMarkAllAsReadMutation();
  const [ReadDemmande] = useReadDemmandeForAgenceMutation();
  const [loginwithisOnlineView] = useLoginwithisOnlineViewMutation();

  // pour marquer le user connecte ofline lor de la deconnexion
  const [markUserOfline] = useMarkUserOflineMutation();

  const dispatch = useAppDispatch();

  const userSlug = user?.slug;
  const { data: donne } = useGetValdatonGerantsQuery({ slug: userSlug });
  const transactions = donne?.transactions || [];

  const demandesNonLues = transactions.filter(
    (transaction) => !transaction.is_read
  );
  const nombreDemandesNonLues = demandesNonLues.length;

  useEffect(() => {
    let newCount = 0;
    if (chatData?.length) {
      chatData.forEach((item) => {
        newCount += item?.messages?.filter(
          (el) => el?.recever?.id === user?.id && el?.is_read === false
        )?.length;
      });
    }
    setCount(newCount);
  }, [chatData]);

  // const handleLogout = () => {
  //   markUserOfline({ slug: user?.slug });
  //   dispatch(onlogout());
  //   window.location.pathname = "/connexion";
  // };
  const handleLogout = async () => {
    try {
      await markUserOfline({ slug: user?.slug });
      dispatch(onlogout());
      window.location.pathname = "/connexion";
    } catch (error) {
      console.error("Error marking user offline:", error);
    }
  };
  useEffect(() => {
    // Lance une requête toutes les 5 secondes pour vérifier l'état d'abonnement du client
    const pollingInterval = setInterval(() => {
      refetch();
    }, 5000);

    return () => {
      // Arrête le polling lorsque le composant est démonté
      clearInterval(pollingInterval);
    };
  }, [refetch]);

  useEffect(() => {
    // Lance une requête toutes les 10 minutes pour récupérer les données non lues
    const pollingInterval = setInterval(() => {
      refetchNotReadData();
    }, 5000);
    return () => {
      // Arrête le polling lorsque le composant est démonté
      clearInterval(pollingInterval);
    };
  }, [refetchNotReadData]);

  const handleMarkAllAsRead = async (modelType: string, slug: string) => {
    try {
      await markAllasRead({ modelType, slug });
    } catch (error) {}
  };
  useEffect(() => {
    setTimeout(() => {
      loginwithisOnlineView({ slug: user?.slug });
    }, 5000);
  }, []);

  return (
    <div className="navbar-vertical-customisation-v2 no-view-mobile">
      <div className="hoverable mt-4" id="scrollbar">
        <div className="content">
          <div className="move-left-side-menu"></div>
          <div className="container-user-connect-info pb-3">
            <div className="container-img-user-connect-nav-menu">
              <img
                className="img-user-connect-navmenu"
                src={getAvatar(user?.avatar)}
                alt="user connect"
              />
            </div>
            <div className="container-info-user-connect-navmenu ps-2">
              <p className="info-user-connect-navmenu mb-1">Bienvenue</p>
              <p
                className="info-user-connect-navmenu mb-0"
                style={{ fontWeight: "bold" }}
              >
                {`${user?.prenom} ${user?.nom}`}
              </p>
            </div>
          </div>
          <ul className="nav nav-items-custom flex-column">
            {userType === "agence" && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/agence/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <BiCategory />
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                      !data?.is_expired ? "disabled" : ""
                    }`}
                    to="/agence/proprietaires"
                    data-tooltip-content="Mes propriétaires"
                  >
                    <FaUsers />
                    <span className="hiddenable-navlink-label">
                      Mes propriétaires
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                      !data?.is_expired ? "disabled" : ""
                    }`}
                    to="/agence/proprietes"
                    data-tooltip-content="Mes propriétés"
                  >
                    <IoMdKey />
                    <span className="hiddenable-navlink-label">
                      Mes propriétés
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                      !data?.is_expired ? "disabled" : ""
                    }`}
                    to="/agence/locataires"
                    data-tooltip-content="Mes locataires"
                  >
                    <FaUsers />
                    <span className="hiddenable-navlink-label">
                      Mes locataires
                    </span>
                  </NavLink>
                </li>
                {/* <li className="nav-item nav-item-vertical-custom position-relative">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/agence/messagerie"
                    data-tooltip-content="Messagerie"
                  >
                    <HiMail />
                    <span className="hiddenable-navlink-label"
                      onClick={() => handleMarkAllAsRead('message', user.slug)}

                    >
                      Messagerie instantanée{" "}
                      
                    </span>
                    // ct commenter lors du  clonage
                    //  {count > 0 && (
                    //   <span className="message-counter">{count}</span>
                    // )} 
                    {notReadData && notReadData.message_count !== undefined && notReadData.message_count > 0 && (
                      <>
                      // ct commenter lors du  clonage
                      //  <img src={ClocheIcon} className="notif-icon mr-1" />
                      // <span className="notification-separe"></span> 
                      <span className="message-counter">{notReadData.message_count}</span>
                    </>
                  )}
                   
                  </NavLink>
                </li> */}

                {/* {user?.a_un_comptable !== true && (
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                        !data?.is_expired ? "disabled" : ""
                      }`}
                      to="/agence/categorie"
                      data-tooltip-content="Catégorie E/S"
                    >
                      <MdCategory />
                      //  <ActivityIcon set="bold" primaryColor="#B8BED9" /> 
                      <span className="hiddenable-navlink-label">
                        Catégorie E/S
                      </span>
                    </NavLink>
                    
                  </li>
                )} */}

                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                      !data?.is_expired ? "disabled" : ""
                    }`}
                    to="/agence/comptabilite"
                    data-tooltip-content="Comptabilité"
                  >
                    <IoDocumentText />
                    <span className="hiddenable-navlink-label">
                      Comptabilité
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/agence/administratif"
                    data-tooltip-content="Administratif"
                  >
                    <GiInjustice />
                    <span className="hiddenable-navlink-label">
                      Administratif
                    </span>
                  </NavLink>
                </li>

                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                      !data?.is_expired ? "disabled" : ""
                    }`}
                    to="/agence/validationadminforgerant"
                    data-tooltip-content="Administratif"
                  >
                    <GiInjustice />
                    <span
                      className="hiddenable-navlink-label"
                      onClick={() =>
                        user?.slug && ReadDemmande({ slug: user?.slug })
                      }
                    >
                      Demande
                    </span>
                    {nombreDemandesNonLues > 0 && (
                      <>
                        {/* <img src={ClocheIcon} className="notif-icon mr-1" />
                        <span className="notification-separe"></span> */}
                        <span className="message-counter">
                          {nombreDemandesNonLues}
                        </span>
                      </>
                    )}
                  </NavLink>
                </li>

                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/agence/option"
                    data-tooltip-content="Options"
                  >
                    <AiFillSetting />
                    <span className="hiddenable-navlink-label">Options</span>
                  </NavLink>
                </li>
              </>
            )}
            {(isAgenceAgent(user) ||
              isAgenceJuriste(user) ||
              isAgenceMarketeur(user)) && (
              <>
                {user?.dashboard && (
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                        !personnelAbonement?.is_expired ? "disabled" : ""
                      }`}
                      to="/agence/dashboard"
                      data-tooltip-content="Dashboard"
                    >
                      <BiCategory />
                      <span className="hiddenable-navlink-label">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                )}

                {user?.location && (
                  <li
                    className={`nav-item nav-item-vertical-custom ${
                      !personnelAbonement?.is_expired ? "disabled" : ""
                    }`}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom with-tooltip-menu"
                      to="/agence/proprietaires"
                      data-tooltip-content="Mes propriétaires"
                    >
                      <FaUsers />
                      <span className="hiddenable-navlink-label">
                        Mes propriétaires
                      </span>
                    </NavLink>
                  </li>
                )}
                {user?.bien && (
                  <li
                    className={`nav-item nav-item-vertical-custom ${
                      !personnelAbonement?.is_expired ? "disabled" : ""
                    }`}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom with-tooltip-menu"
                      to="/agence/proprietes"
                      data-tooltip-content="Mes propriétés"
                    >
                      <IoMdKey />
                      <span className="hiddenable-navlink-label">
                        Mes propriétés
                      </span>
                    </NavLink>
                  </li>
                )}
                {user?.locatair && (
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                        !personnelAbonement?.is_expired ? "disabled" : ""
                      }`}
                      to="/agence/locataires"
                      data-tooltip-content={
                        isAgenceJuriste(user) ? "contentieux" : "Mes locataires"
                      }
                    >
                      <FaUsers />
                      <span className="hiddenable-navlink-label">
                        {isAgenceJuriste(user)
                          ? "contentieux"
                          : "Mes locataires"}
                      </span>
                    </NavLink>
                  </li>
                )}
                {/* {user?.message && (
                  <li className="nav-item nav-item-vertical-custom position-relative">
                    <NavLink
                      className="nav-link nav-link-vertival-custom with-tooltip-menu"
                      to="/agence/messagerie"
                      data-tooltip-content="Messagerie"
                    >
                      <HiMail />
                      <span className="hiddenable-navlink-label"
                      onClick={() => handleMarkAllAsRead('message', user.slug)}

                    >
                      Messagerie instantanée{" "}
                      
                    </span>
                    // Commenter lor du clonage
                    //  {count > 0 && (
                    //   <span className="message-counter">{count}</span>
                    // )} 
                    {notReadData && notReadData.message_count !== undefined && notReadData.message_count > 0 && (
                      <>
                      // Commenter lor du clonage
                      // <img src={ClocheIcon} className="notif-icon mr-1" />
                      // <span className="notification-separe"></span> 
                      <span className="message-counter">{notReadData.message_count}</span>
                    </>
                  )}
                    </NavLink>
                  </li>
                )} */}
                {user?.paiement && (
                  <li
                    className={`nav-item nav-item-vertical-custom ${
                      !personnelAbonement?.is_expired ? "disabled" : ""
                    }`}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom with-tooltip-menu"
                      to="/agence/comptabilite"
                      data-tooltip-content="Comptabilité"
                    >
                      <IoDocumentText />
                      <span className="hiddenable-navlink-label">
                        Comptabilité
                      </span>
                    </NavLink>
                  </li>
                )}
                {user?.administratif && (
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                        !personnelAbonement?.is_expired ? "disabled" : ""
                      }`}
                      to="/agence/administratif"
                      data-tooltip-content="Administratif"
                    >
                      <GiInjustice />
                      <span className="hiddenable-navlink-label">
                        Administratif
                      </span>
                    </NavLink>
                  </li>
                )}
                {user?.parametre && (
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom with-tooltip-menu"
                      to="/agence/option"
                      data-tooltip-content="Options"
                    >
                      <AiFillSetting />
                      <span className="hiddenable-navlink-label">Options</span>
                    </NavLink>
                  </li>
                )}
              </>
            )}

            {isProprioGerant(user) && (
              <>
                {user?.dashboard && (
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                        !personnelAbonement?.is_expired ? "disabled" : ""
                      }`}
                      to="/proprietaire/gerer-par-le-proprietaire/dashboard"
                      data-tooltip-content="Dashboard"
                    >
                      <BiCategory />
                      <span className="hiddenable-navlink-label">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                )}
                {user?.bien && (
                  <li
                    className={`nav-item nav-item-vertical-custom ${
                      !personnelAbonement?.is_expired ? "disabled" : ""
                    }`}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom with-tooltip-menu"
                      to="/proprietaire/gerer-par-le-proprietaire/proprietes"
                      data-tooltip-content="Mes propriétés"
                    >
                      <IoMdKey />
                      <span className="hiddenable-navlink-label">
                        Mes propriétés
                      </span>
                    </NavLink>
                  </li>
                )}
                {user?.locatair && (
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                        !personnelAbonement?.is_expired ? "disabled" : ""
                      }`}
                      to="/proprietaire/gerer-par-le-proprietaire/locataires"
                      data-tooltip-content={
                        isProprioJuriste(user)
                          ? "contentieux"
                          : "Mes locataires"
                      }
                    >
                      <FaUsers />
                      <span className="hiddenable-navlink-label">
                        {isProprioJuriste(user)
                          ? "contentieux"
                          : "Mes locataires"}
                      </span>
                    </NavLink>
                  </li>
                )}
                {user?.paiement && (
                  <li
                    className={`nav-item nav-item-vertical-custom ${
                      !personnelAbonement?.is_expired ? "disabled" : ""
                    }`}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom with-tooltip-menu"
                      to="/proprietaire/gerer-par-le-proprietaire/comptabilite"
                      data-tooltip-content="Comptabilité"
                    >
                      <IoDocumentText />
                      <span className="hiddenable-navlink-label">
                        Comptabilité
                      </span>
                    </NavLink>
                  </li>
                )}
                {user?.administratif && (
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                        !personnelAbonement?.is_expired ? "disabled" : ""
                      }`}
                      to="/proprietaire/gerer-par-le-proprietaire/administratif"
                      data-tooltip-content="Administratif"
                    >
                      <GiInjustice />
                      <span className="hiddenable-navlink-label">
                        Administratif
                      </span>
                    </NavLink>
                  </li>
                )}
                {user?.parametre && (
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom with-tooltip-menu"
                      to="/proprietaire/gerer-par-le-proprietaire/parametres"
                      data-tooltip-content="Options"
                    >
                      <AiFillSetting />
                      <span className="hiddenable-navlink-label">Options</span>
                    </NavLink>
                  </li>
                )}
              </>
            )}

            {userType === "locataire" && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/locataire/mon-loyer"
                    data-tooltip-content="Mon Loyer"
                  >
                    <PasswordIcon />
                    <span className="hiddenable-navlink-label">Mon Loyer</span>
                  </NavLink>
                </li>
                {/* ============= NE PAS SUPPRIMER ============= */}

                {/* <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/locataire/requetes"
                    data-tooltip-content="Requêtes"
                  >
                    <BsFillChatDotsFill />
                    <span className="hiddenable-navlink-label">Requêtes</span>
                  </NavLink>
                </li> */}
                {/* <li className="nav-item nav-item-vertical-custom position-relative">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/locataire/messages"
                    data-tooltip-content="Messagerie"
                  >
                    <HiMail />
                    <span className="hiddenable-navlink-label"
                      onClick={() => handleMarkAllAsRead('message', user.slug)}

                    >
                      Messagerie instantanée{" "}
                      
                    </span>
                    // ============Ne pas suppprimer==========
                    //   {count > 0 && (
                    //   <span className="message-counter">{count}</span>
                    // )} 
                    {notReadData && notReadData.message_count !== undefined && notReadData.message_count > 0 && (
                      <>
                      // =======Ne pas supprimer=============
                      //  <img src={ClocheIcon} className="notif-icon mr-1" />
                      // <span className="notification-separe"></span> 
                      <span className="message-counter">{notReadData.message_count}</span>
                    </>
                  )}
                  </NavLink>
                </li> */}
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/locataire/administratif"
                    data-tooltip-content="Administratif"
                  >
                    <GiInjustice />
                    <span className="hiddenable-navlink-label">
                      Administratif
                    </span>
                  </NavLink>
                </li>
                {user?.agence_id?.id && (
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom with-tooltip-menu"
                      to="/locataire/mon-agence"
                      data-tooltip-content="Mon agence"
                      state={user?.agence_id}
                    >
                      <MdRealEstateAgent />
                      <span className="hiddenable-navlink-label">
                        Mon agence
                      </span>
                    </NavLink>
                  </li>
                )}
                {user?.proprietaire_id?.id && (
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom with-tooltip-menu"
                      to="/locataire/mon-proprietaire"
                      data-tooltip-content="Mon propriétataire"
                      state={user?.proprietaire_id}
                    >
                      <MdRealEstateAgent />
                      <span className="hiddenable-navlink-label">
                        Mon propriétaire
                      </span>
                    </NavLink>
                  </li>
                )}
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/locataire/parametre"
                    data-tooltip-content="Paramètres"
                  >
                    <SettingsIcon />

                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}

            {isProprietaireOwner(user) && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietaire/gerer-par-le-proprietaire/dashboard"
                    data-tooltip-content="Dashboard"
                    // hidden={
                    //   isProprioJuriste(user) && user?.dashboard === false
                    // }
                  >
                    <BiCategory />
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                      !data?.is_expired ? "disabled" : ""
                    }`}
                    to="/proprietaire/gerer-par-le-proprietaire/proprietes"
                    data-tooltip-content="Mes propriétés"
                    hidden={isProprioJuriste(user) && user?.dashboard === false}
                  >
                    <IoMdKey />
                    <span className="hiddenable-navlink-label">
                      Mes propriétés
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    // className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                      !data?.is_expired ? "disabled" : ""
                    }`}
                    to="/proprietaire/gerer-par-le-proprietaire/locataires"
                    data-tooltip-content="Mes locataires"
                  >
                    <FaUsers />
                    <span className="hiddenable-navlink-label">
                      Mes locataires
                    </span>
                  </NavLink>
                </li>
                {/* <li className="nav-item nav-item-vertical-custom position-relative">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietaire/gerer-par-le-proprietaire/messages"
                    data-tooltip-content="Messagerie"
                  >
                    <HiMail />
                    <span className="hiddenable-navlink-label"
                      onClick={() => handleMarkAllAsRead('message', user.slug)}

                    >
                      Messagerie instantanée{" "}
                      
                    </span>
                    
                    {notReadData && notReadData.message_count !== undefined && notReadData.message_count > 0 && (
                      <>
                      
                      <span className="message-counter">{notReadData.message_count}</span>
                    </>
                  )}
                  </NavLink>
                </li> */}

                {/* {user?.a_un_comptable !== true && ( */}
                {/* <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietaire/gerer-par-le-proprietaire/categorie"
                    data-tooltip-content="Catégorie E/S"
                  >
                    <MdCategory />
                    // <ActivityIcon set="bold" primaryColor="#B8BED9" /> 
                    <span className="hiddenable-navlink-label">
                      Catégorie E/S
                    </span>
                  </NavLink>
                </li> */}
                {/* )} */}

                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                      !data?.is_expired ? "disabled" : ""
                    }`}
                    to="/proprietaire/gerer-par-le-proprietaire/comptabilite"
                    data-tooltip-content="Comptabilité"
                  >
                    <IoDocumentText />
                    <span className="hiddenable-navlink-label">
                      Comptabilité
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                      !data?.is_expired ? "disabled" : ""
                    }`}
                    to="/proprietaire/gerer-par-le-proprietaire/administratif"
                    data-tooltip-content="Administratif"
                  >
                    <GiInjustice />
                    <span className="hiddenable-navlink-label">
                      Administratif
                    </span>
                  </NavLink>
                </li>
                {/* <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietaire/gerer-par-le-proprietaire/immeubles"
                    data-tooltip-content="Immeubles"
                  >
                    <BsBuilding />
                    <span className="hiddenable-navlink-label">Immeubles</span>
                  </NavLink>
                </li> */}
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietaire/gerer-par-le-proprietaire/parametres"
                    data-tooltip-content="Paramètres"
                  >
                    <SettingsIcon />

                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}
            {isProprioJuriste(user) && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                      !personnelAbonement?.is_expired ? "disabled" : ""
                    }`}
                    to="/proprietaire/gerer-par-le-proprietaire/dashboard"
                    data-tooltip-content="Dashboard"
                    // hidden={
                    //   isProprioJuriste(user) && user?.dashboard === false
                    // }
                  >
                    <BiCategory />
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>

                <li
                  className={`nav-item nav-item-vertical-custom ${
                    !personnelAbonement?.is_expired ? "disabled" : ""
                  }`}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietaire/gerer-par-le-proprietaire/litiges"
                    data-tooltip-content="Litiges"
                  >
                    <FaUsers />
                    <span className="hiddenable-navlink-label">
                      Contentieux
                    </span>
                  </NavLink>
                </li>
                {/* <li className="nav-item nav-item-vertical-custom position-relative">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietaire/gerer-par-le-proprietaire/messages"
                    data-tooltip-content="Messagerie"
                  >
                    <HiMail />
                    <span className="hiddenable-navlink-label"
                      onClick={() => handleMarkAllAsRead('message', user.slug)}

                    >
                      Messagerie instantanée{" "}
                      
                    </span>
                   
                    {notReadData && notReadData.message_count !== undefined && notReadData.message_count > 0 && (
                      <>
                     
                      <span className="message-counter">{notReadData.message_count}</span>
                    </>
                  )}
                  </NavLink>
                </li> */}

                {/* <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietaire/gerer-par-le-proprietaire/immeubles"
                    data-tooltip-content="Immeubles"
                  >
                    <BsBuilding />
                    <span className="hiddenable-navlink-label">Immeubles</span>
                  </NavLink>
                </li> */}
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietaire/gerer-par-le-proprietaire/parametres"
                    data-tooltip-content="Paramètres"
                  >
                    <SettingsIcon />

                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}
            {userType === "comptable" && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className={`nav-link nav-link-vertival-custom with-tooltip-menu ${
                      !personnelAbonement?.is_expired ? "disabled" : ""
                    }`}
                    to="/comptable/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <BiCategory />
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                {/* <li className="nav-item nav-item-vertical-custom position-relative">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/comptable/messages"
                    data-tooltip-content="Messagerie"
                  >
                    <HiMail />
                    <span className="hiddenable-navlink-label"
                      onClick={() => handleMarkAllAsRead('message', user.slug)}

                    >
                      Messagerie instantanée{" "}
                      
                    </span>
                   
                    {notReadData && notReadData.message_count !== undefined && notReadData.message_count > 0 && (
                      <>
                      
                      <span className="message-counter">{notReadData.message_count}</span>
                    </>
                  )}
                  </NavLink>
                </li> */}
                <li
                  className={`nav-item nav-item-vertical-custom ${
                    !personnelAbonement?.is_expired ? "disabled" : ""
                  }`}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/comptable/mes-entrees"
                    data-tooltip-content="Mes revenus"
                  >
                    <ActivityIcon />
                    <span className="hiddenable-navlink-label">
                      Mes revenus
                    </span>
                  </NavLink>
                </li>
                <li
                  className={`nav-item nav-item-vertical-custom ${
                    !personnelAbonement?.is_expired ? "disabled" : ""
                  }`}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/comptable/depenses"
                    data-tooltip-content="Dépenses"
                  >
                    <ActivityIcon />
                    {/* <ActivityIcon set="bold" primaryColor="#B8BED9" /> */}
                    <span className="hiddenable-navlink-label">Dépenses</span>
                  </NavLink>
                </li>
                {/* <li
                  className={`nav-item nav-item-vertical-custom ${
                    !personnelAbonement?.is_expired ? "disabled" : ""
                  }`}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/comptable/categorie"
                    data-tooltip-content="Categorie"
                  >
                    <MdCategory />
                    //  <ActivityIcon set="bold" primaryColor="#B8BED9" /> 
                    <span className="hiddenable-navlink-label">Categorie</span>
                  </NavLink>
                </li> */}
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/comptable/options"
                    data-tooltip-content="Options"
                  >
                    <SettingsIcon />

                    <span className="hiddenable-navlink-label">Options</span>
                  </NavLink>
                </li>
              </>
            )}
            {(userType === "admin" || userType === "superadmin") && (
              <>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["dashboard"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <BiCategory />
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                {/* <li
                  className="nav-item nav-item-vertical-custom position-relative"
                  hidden={!user["message"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/messages"
                    data-tooltip-content="Messagerie"
                  >
                    <HiMail />
                    <span className="hiddenable-navlink-label"
                      onClick={() => handleMarkAllAsRead('message', user.slug)}

                    >
                      Messagerie instantanée{" "}
                      
                    </span>
                   {notReadData && notReadData.message_count !== undefined && notReadData.message_count > 0 && (
                      <>
                      
                      <span className="message-counter">{notReadData.message_count}</span>
                    </>
                  )}
                  </NavLink>
                </li> */}
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["client"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/mes-clients"
                    data-tooltip-content="Mes clients"
                  >
                    <FaUsers />
                    <span className="hiddenable-navlink-label">
                      Mes clients
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/nos-offres"
                    data-tooltip-content="Nos offres"
                  >
                    <MdLocalOffer />
                    <span className="hiddenable-navlink-label">Nos offres</span>
                  </NavLink>
                </li>

                {/* <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/nos-abonnes"
                    data-tooltip-content="Nos Abonnes"
                  >
                    <MdLocalOffer />
                    <span className="hiddenable-navlink-label">Nos Abonnés</span>
                  </NavLink>
                </li> */}

                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["new"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/news"
                    data-tooltip-content="News"
                  >
                    <BiNews />
                    <span className="hiddenable-navlink-label">News</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["recette"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/recettes"
                    data-tooltip-content="Recettes"
                  >
                    <GiReceiveMoney />
                    <span className="hiddenable-navlink-label">Recettes</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["administratif"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/administratif"
                    data-tooltip-content="Administratif"
                  >
                    <GiInjustice />
                    <span className="hiddenable-navlink-label">
                      Administratif
                    </span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["lead"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/lead"
                    data-tooltip-content="Lead"
                  >
                    <BsInfoSquareFill />
                    <span
                      className="hiddenable-navlink-label"
                      onClick={() => handleMarkAllAsRead("contact", user.slug)}
                    >
                      Lead
                    </span>
                    {notReadData &&
                      notReadData.contact_count !== undefined &&
                      notReadData.contact_count > 0 && (
                        <>
                          {/* <img src={ClocheIcon} className="notif-icon mr-1" />
                        <span className="notification-separe"></span> */}
                          <span className="message-counter">
                            {notReadData.contact_count}
                          </span>
                        </>
                      )}
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["probleme"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/problemes"
                    data-tooltip-content="Problèmes signalés"
                  >
                    <MdReportProblem />

                    <span
                      className="hiddenable-navlink-label"
                      onClick={() => handleMarkAllAsRead("probleme", user.slug)}
                    >
                      Problèmes signalés
                    </span>
                    {notReadData &&
                      notReadData.probleme_count !== undefined &&
                      notReadData.probleme_count > 0 && (
                        <>
                          {/* <img src={ClocheIcon} className="notif-icon mr-1" />
                            <span className="notification-separe"></span> */}
                          <span className="message-counter">
                            {notReadData.probleme_count}
                          </span>
                        </>
                      )}
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["options"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/options"
                    data-tooltip-content="Options"
                  >
                    <SettingsIcon />

                    <span className="hiddenable-navlink-label">Options</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["faq"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/faq"
                    data-tooltip-content="FAQ"
                  >
                    <IoDocumentText />
                    <span className="hiddenable-navlink-label">FAQ</span>
                  </NavLink>
                </li>
                {/* <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["type_requete"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/types-requetes"
                    data-tooltip-content="Type de requetes"
                  >
                    <BiGitPullRequest />
                    <span className="hiddenable-navlink-label">
                      Type de requetes
                    </span>
                  </NavLink>
                </li> */}
              </>
            )}
            {/* {isAdminAgent(user) && (
              <>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["dashboard"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <BiCategory />
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom position-relative"
                  hidden={!user["message"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/messages"
                    data-tooltip-content="Messagerie"
                  >
                    <HiMail />
                    <span className="hiddenable-navlink-label">
                      Messagerie instantanée{" "}
                    </span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["client"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/mes-clients"
                    data-tooltip-content="Mes clients"
                  >
                    <FaUsers />
                    <span className="hiddenable-navlink-label">
                      Mes clients
                    </span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["new"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/news"
                    data-tooltip-content="News"
                  >
                    <BiNews />
                    <span className="hiddenable-navlink-label">News</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["recette"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/recettes"
                    data-tooltip-content="Recettes"
                  >
                    <GiReceiveMoney />
                    <span className="hiddenable-navlink-label">Recettes</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["administratif"] && !is_SuperAdmin}
                  data-tooltip-content="Administratif"
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/administratif"
                  >
                    <GiInjustice />
                    <span className="hiddenable-navlink-label">
                      Administratif
                    </span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["lead"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/lead"
                    data-tooltip-content="Lead"
                  >
                    <BsInfoSquareFill />
                    <span className="hiddenable-navlink-label">Lead</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["probleme"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/problemes"
                    data-tooltip-content="Problèmes"
                  >
                    <MdReportProblem />
                    <span className="hiddenable-navlink-label">Problèmes</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["options"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/options"
                    data-tooltip-content="Options"
                  >
                    <SettingsIcon />

                    <span className="hiddenable-navlink-label">Options</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["faq"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/faq"
                    data-tooltip-content="FAQ"
                  >
                    <IoDocumentText />
                    <span className="hiddenable-navlink-label">FAQ</span>
                  </NavLink>
                </li>
                <li
                  className="nav-item nav-item-vertical-custom"
                  hidden={!user["type_requete"] && !is_SuperAdmin}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/types-requetes"
                    data-tooltip-content="Types requêtes"
                  >
                    <BiGitPullRequest />
                    <span className="hiddenable-navlink-label">
                      Types requêtes
                    </span>
                  </NavLink>
                </li>
              </>
            )} */}

            {isProprietaireAgence(user) && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietes/gerer-par-agence/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <BiCategory />
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li
                  // className={`nav-item nav-item-vertical-custom ${!isExpired ? 'disabled' : ''}`}
                  className={`nav-item nav-item-vertical-custom ${
                    pqgrsbAgence && !pqgrsbAgence.is_expired ? "disabled" : ""
                  }`}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietes/gerer-par-agence/proprietes"
                    data-tooltip-content="Mes propriétés"
                  >
                    <IoMdKey />
                    <span className="hiddenable-navlink-label">
                      Mes propriétés
                    </span>
                  </NavLink>
                </li>
                <li
                  // className={`nav-item nav-item-vertical-custom ${!isExpired ? 'disabled' : ''}`}
                  className={`nav-item nav-item-vertical-custom ${
                    pqgrsbAgence && !pqgrsbAgence.is_expired ? "disabled" : ""
                  }`}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietes/gerer-par-agence/agences"
                    data-tooltip-content="Agences"
                  >
                    <MdRealEstateAgent />
                    <span className="hiddenable-navlink-label">Agences</span>
                  </NavLink>
                </li>
                {/* <li className="nav-item nav-item-vertical-custom position-relative">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietes/gerer-par-agence/messages"
                    data-tooltip-content="Messagerie"
                  >
                    <HiMail />
                    <span className="hiddenable-navlink-label">
                      Messagerie instantanée{" "}
                    </span>
                    {notReadData && notReadData.message_count !== undefined && notReadData.message_count > 0 && (
                      <>
                    
                      <span className="message-counter">{notReadData.message_count}</span>
                    </>
                  )}
                  </NavLink>
                </li> */}

                {/* <li
                            className="nav-item nav-item-vertical-custom"
                        >
                            <NavLink className="nav-link nav-link-vertival-custom with-tooltip-menu" to="/proprietaire/gerer-par-le-proprietaire/comptabilite">
                                <IoDocumentText />
                                <span className="hiddenable-navlink-label">Comptabilité</span>
                            </NavLink>
                        </li> */}
                <li
                  className={`nav-item nav-item-vertical-custom ${
                    pqgrsbAgence && !pqgrsbAgence.is_expired ? "disabled" : ""
                  }`}
                >
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietes/gerer-par-agence/administratif"
                    data-tooltip-content="Administratif"
                  >
                    <GiInjustice />
                    <span className="hiddenable-navlink-label">
                      Administratif
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/proprietes/gerer-par-agence/parametres"
                    data-tooltip-content="Paramètres"
                  >
                    <SettingsIcon />

                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}
          </ul>
          <div className="container-btn-logout">
            <button className="btn btn-logout" onClick={handleLogout}>
              <IoLogOut />
              <span className="ps-2">Déconnexion</span>
            </button>
            <div className="version-view-container">
              {"v" + VersionChecker()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarAdmin;
