import React from "react"
import VolkenoSkeleton from "volkeno-react-skeleton"

function NewsListSkeleton() {
  return (
    <div className="col-lg-4 col-md-6 pb-3 d-flex">
        <div className='news-card-item-component'>
            <div className='container-img-card-news'>
                <VolkenoSkeleton
                    variant='rect'
                    height={220}
                    // width={30}
                    style={{ borderRadius: "0px"}}
                />
            </div>
            <div className='content-text-card-news p-3'>
                <p className='text-date-card-news'>
                    <VolkenoSkeleton
                        variant='rect'
                        height={10}
                        width={130}
                        style={{ marginBottom: "0px"}}
                    />
                </p>
                <div className='container-title-card-news pt-3 pb-4'>
                    <h4 className='title-card-news'>
                          <VolkenoSkeleton
                            variant='rect'
                            height={10}
                            width={160}
                            style={{ marginTop: "0px", marginBottom: "0px"}}
                        />
                    </h4>
                </div>
                <div className="wrapper" id="wrapper">
                    <span>
                        <VolkenoSkeleton
                            variant='rect'
                            height={30}
                            width={200}
                            style={{ marginTop: "0px", borderRadius: "3px"}}
                        />
                    </span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewsListSkeleton