import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CitySelector,
  CountrySelector,
  StateSelector,
} from "volkeno-react-country-state-city";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useGetAgencesQuery } from "../../../../../../utils/api/agence/agence.api";
import {
  useGetImmeublesByUserQuery,
  useGetImmeublesQuery,
} from "../../../../../../utils/api/immeubles/immeubles.api";
import { useGetProprietairesByAgenceQuery } from "../../../../../../utils/api/proprietaire/proprietaire.api";
import { IProprietaire } from "../../../../../../utils/api/proprietaire/proprietaire.type";
import { useGetProprietesByAgenceQuery } from "../../../../../../utils/api/propriete/propriete.api";
import {
  IPropriete,
  ProprieteStepProps,
  TypePropriete,
} from "../../../../../../utils/api/propriete/propriete.type";
import { isAgence } from "../../../../../../utils/Utils";
import { GoBackButton } from "../../../../../common/Button";
import { FormError } from "../../../../../common/CustomInputCheckbox";
import GoogleInput from "../../../../../common/GoogleInput";
import { Input } from "../../../../../common/Input";
import { SelectInput } from "../../../../../common/SelectInput";

const typesProprietesOptions = [
  {
    label: "Villa",
    value: TypePropriete.villa,
  },
  {
    label: "Appartement",
    value: TypePropriete.appartement,
  },
  {
    label: "Immeuble",
    value: TypePropriete.immeuble,
  },
];

function InfoPropriete({
  register,
  onSubmit,
  errors,
  address,
  onChangeAddress,
  handleCountrySelect,
  handleStateSelect,
  handleCitySelect,
  country,
  state,
  city,
  propriete,
  setValue,
  handleChangeProprio,
  proprio,
}: ProprieteStepProps) {
  const [proprietaires, setProprietaires] = useState<IProprietaire[]>();
  const [proprietes, setProprietes] = useState<
    { label: string; value: number }[] | undefined
  >();
  const [immeublesList, setImmeublesList] = useState<
    { label: string; value: any }[] | undefined
  >();
  const agence = useAppSelector((s) => s.user.user);

  const { user } = useAppSelector((s) => s?.user);
  // console.log("userID", user?.id);

  const location: any = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location?.state?.type_propriete) {
      register("type_propriete");
      setValue("type_propriete", location.state.type_propriete);
    }
    if (location?.state?.immeuble) {
      register("immeuble");
      setValue("immeuble", location.state.immeuble);
    }
    // else {
    //   setTimeout(() => {
    //     navigate("/agence/proprietes");
    //   }, 50);
    // }
  }, [location]);

  const { data, isLoading: loading } = useGetProprietairesByAgenceQuery({
    slug: user?.agence_id ? agence?.agence_id?.slug : user?.slug,
  });
  useEffect(() => {
    if (data) {
      setProprietaires(data?.results);
    }
  }, [data]);

  // console.log("proprietaires proprietaires", proprietaires)

  const [showImmeubles, setShowImmeubles] = useState(false);

  const toggleShow = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value === "appartement") {
      setShowImmeubles(true);
    } else {
      setShowImmeubles(false);
    }
  };

  // const { data: immeubles } = useGetImmeublesQuery({});
  const { data: immeubles } = useGetImmeublesByUserQuery({
    slug: user?.slug,
  });
  // console.log('immeubles', immeubles)
  useEffect(() => {
    if (immeubles) {
      const ListeImb = immeubles?.immeubles?.map((item) => {
        return {
          label: item.nom,
          value: item.id,
        };
      });
      setImmeublesList(ListeImb);
    }
  }, [immeubles]);

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="nombre_stock"
                        className="form-label form-label-modal-custom"
                        aria-labelledby="proprietaire"
                      >
                        Sélectionner le propriétaire
                        <span
                          className="text-danger"
                          style={{
                            fontSize: 24,
                          }}
                        >
                          *
                        </span>
                      </label>
                      <select
                        id="proprietaire"
                        className="form-select form-select-modal-custom mb-2"
                        value={proprio}
                        // {...register("proprietaire")}
                        onChange={(e) => handleChangeProprio(e)}
                      >
                        {loading ? (
                          <option value={""} className="d-flex" hidden>
                            <span>Chargement...</span>{" "}
                          </option>
                        ) : (
                          <option value="" hidden>
                            Sélectionner le propriétaire
                          </option>
                        )}
                        {proprietaires?.map((item) => (
                          <option value={item.id} key={item.slug}>
                            {item.prenom + " " + item.nom}
                          </option>
                        ))}
                        <option value="agence">Nous même</option>
                      </select>

                      {<FormError error={errors.proprietaire?.message} />}
                    </div>
                  </div>
                  {/* <div className="col-md-6">
										<SelectInput
											label="Type de propriété"
											required
											options={typesProprietesOptions}
											{...register("type_propriete")}
											error={errors.type_propriete}
											onChange={toggleShow}
										/>
									</div>
									{showImmeubles && (
										<div className="col-md-6">
											<SelectInput
												label="Sélectionné l'immeuble"
												// required
												options={immeublesList}
												{...register("immeuble")}
												error={errors.immeuble}
											/>
										</div>
									)} */}
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Numéro"
                      id="numero"
                      placeholder="Numéro"
                      required
                      {...register("numero")}
                      error={errors?.numero?.message}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Nom de la proprété"
                      id="nom"
                      placeholder="Nom"
                      required
                      {...register("nom")}
                      error={errors?.nom?.message}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="adress"
                        className="form-label form-label-modal-custom"
                      >
                        Adresse{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <GoogleInput
                        className="form-control form-control-modal-custom form-control-params-custom"
                        value={address}
                        placeholder="Adresse"
                        onChange={onChangeAddress}
                      />
                      {errors?.adresse && (
                        <FormError error={errors?.adresse?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Rue"
                      id="rue"
                      placeholder="Rue"
                      // required
                      {...register("rue")}
                      error={errors?.rue?.message}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="pays"
                      className="form-label form-label-modal-custom"
                    >
                      Pays{" "}
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom"
                      placeholder="Pays"
                      value={country}
                      types={["country"]}
                      onChange={handleCountrySelect}
                    />

                    {errors?.pays && (
                      <FormError error={errors?.pays?.message} />
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="region"
                      className="form-label form-label-modal-custom"
                    >
                      Région{" "}
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom"
                      placeholder="Région"
                      value={state}
                      types={[
                        "administrative_area_level_1",
                        "administrative_area_level_2",
                      ]}
                      onChange={handleStateSelect}
                    />
                    {/* <StateSelector
                      country={country}
                      name="region"
                      value={state}
                      placeholder={
                        propriete?.region ? propriete?.region : "Region"
                      }
                      countryPlaceholder="Region"
                      onChange={handleStateSelect}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                    {errors?.region && (
                      <FormError error={errors?.region?.message} />
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="ville"
                      className="form-label form-label-modal-custom"
                    >
                      Ville
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom"
                      placeholder="Ville"
                      value={city}
                      types={["locality", "administrative_area_level_3"]}
                      onChange={handleCitySelect}
                    />
                    {/* <CitySelector
                      state={state}
                      name="ville"
                      value={city}
                      placeholder="Ville"
                      statePlaceholder={
                        propriete?.ville ? propriete?.ville : "Ville"
                      }
                      onChange={handleCitySelect}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                    {errors?.ville && (
                      <FormError error={errors?.ville?.message} />
                    )}
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Code postale"
                      id="code_postale"
                      placeholder="Code postale"
                      {...register("code_postale")}
                      error={errors?.code_postale?.message}
                    />
                  </div>
                  {propriete?.type_propriete !== "immeuble" &&
                    proprio !== "agence" && (
                      <div className="col-md-6">
                        <Input
                          type="number"
                          max={100}
                          min={0}
                          label="Taux de rémunération"
                          id="commission_agence"
                          placeholder="Taux de rémunération"
                          required
                          {...register("commission_agence")}
                          error={errors?.commission_agence?.message}
                        />
                      </div>
                    )}
                </div>
                <div className="container-btn-modal row my-4">
                  <div className="col-md-4 offset-md-8 flex-r">
                    <div className="d-flex gap-3">
                      <GoBackButton label="Annuler" />
                      <button className="btn auth-submit-btn" type="submit">
                        Continuer
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoPropriete;
