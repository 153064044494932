import React, { useEffect, useState } from "react";
import { ICategory } from "../../../utils/api/category/category.type";
import { BtnCloseModal, BtnSubmit } from "../../common/Button";
import { IoClose } from "react-icons/io5";
import { FormError } from "../../common/CustomInputCheckbox";
import useCrudCaterie from "../../TableauDebord/Comptable/Categories/useForm/useCrudCaterie";

function AjouterCategorieModal({
  modalId,
  item,
  isAccountingPage,
  handleToggle,
}: {
  modalId: string;
  item?: ICategory;
  isAccountingPage?: boolean;
  handleToggle?: () => void;
}) {
  const { register, onSubmit, errors, isLoading, reset, setValue } =
    useCrudCaterie(modalId, item);
  useEffect(() => {
    if (item) {
      setValue("slug", item?.slug);
      setValue("nom", item?.nom);
    }
  }, [item]);

  const [erreur, setErreur] = useState(false);
  const handleSubmitForm = async () => {
    await onSubmit();
    if (errors.nom?.message === "Nom est un champ obligatoire") {
      setErreur(true);
      console.log("voir");
    } else {
      // setErreur(false)
    }
  };

  useEffect(() => {
    if (errors.nom?.message === "Nom est un champ obligatoire") {
      handleSubmitForm();
    } else {
      // setErreur(false)
    }
  }, [errors.nom?.message]);

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {item ? "Modifier la" : "Ajouter un"} categorie
          </h5>
          {isAccountingPage ? (
            <button
              className="close-modal auth-submit-annuler"
              type="button"
              onClick={handleToggle}
            >
              <IoClose />
            </button>
          ) : (
            <BtnCloseModal label="" onClick={reset} />
          )}
        </div>
        {/* <form action="" onSubmit={onSubmit}> */}
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();

            if (isAccountingPage && handleToggle && !erreur) {
              console.log(erreur);
              setErreur(true);
              setTimeout(() => {
                handleToggle();
              }, 2000);
            }
          }}
        >
          <div className="row py-3 px-2">
            <div className="col-12 mb-4 ">
              <input
                type="text"
                placeholder="Nom"
                className="form-control form-control-modal-custom"
                id="titre"
                {...register("nom")}
              />
              {<FormError error={errors.nom} />}
            </div>

            <div className="flex-r">
              <BtnSubmit
                label={item ? "Modifier" : "Ajouter"}
                isLoading={isLoading}
                style={{ padding: "5px 10px", fontSize: 14 }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AjouterCategorieModal;
