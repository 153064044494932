import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { FiPaperclip } from "react-icons/fi";
import { RiSendPlaneFill } from "react-icons/ri";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/hooks";
import { useAddChatMutation } from "../../../../utils/api/chat/chat.api";
import { Chat, ChatData } from "../../../../utils/api/chat/chat.type";
import { ILocataire } from "../../../../utils/api/locataire/locataire.type";
import { IUser } from "../../../../utils/api/user/user.type";
import { Color } from "../../../../utils/theme";
import { getAvatar } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import { UseActiveOrDesactive } from "../OptionAgence/requestForm/UseEditUserFom";
import {
  useUpdateCHatMutation,
} from "../../../../utils/api/chat/chat.api";

function DetailsMessageTabsAdmin({
  chat,
  user,
}: {
  chat: ChatData | null;
  user: ILocataire | IUser | null;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [updateChat] = useUpdateCHatMutation();


  useEffect(() => {
    // Mettez à jour le message en tant que lu
    if (chat?.messages?.length && ref.current) {
      const promises = chat.messages
        .filter((message) => !message.is_read && message.sender.id !== chat.user.id)
        .map((message) => updateChat({ slug: message.slug, is_read: true }));

      Promise.all(promises).then((res) =>
        console.log("marked as read", res?.length)
      );
    }

    // Faites défiler vers le bas
    if (chat?.messages?.length && ref.current) {
      const element = ref.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [chat, ref, updateChat]);
  if (!user)
    return (
      <div
        className="dtails-messages-tabs-component"
        style={{ minHeight: "50%" }}
      >
        <AlertInfo message="Pas de discussion ouverte" />
      </div>
    );

  return (
    <div className="dtails-messages-tabs-component">
      <div className="row align-items-center border-bottom p-3">
        <div className="col-xl-1 col-lg-1 col-sm-1 col-md-1 px-0">
          <img
            src={getAvatar(user?.avatar)}
            className="image-profil-entete pe-0 image_responsive"
            alt="profil detail message"
            style={{ width: "60", height: "60" }}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 ps-0">
          <div className="msg-user-infos-container">
            <div className="d-flex align-items-center msg-user-name">
              <p className="profil-detail-message mb-0">
                {user.prenom} {user.nom}
              </p>
            </div>
            <div className="bloc-user-disconnect-time msg-user-lastonline">
              <p className="text-disconnect-time mb-0"></p>
            </div>
          </div>
        </div>
        {/* <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 ps-0 flrx-r">
          <ActiveOrDesactive item={user} />
        </div> */}
      </div>
      <div className="bloc-details pb-5" ref={ref}>
        {chat?.messages?.map((message) => {
          if (message.sender.id === user.id) {
            return <Message item={message} key={message.id} />;
          }
          return <Response item={message} key={message.id} />;
        })}
      </div>
      <ChatInput userId={user.id} />
    </div>
  );
}

export default DetailsMessageTabsAdmin;

function Message({ item }: { item: Chat }) {
  return (
    <div className="position-relative received-msg-item">
      <div className="bloc-message-recu p-3">
        <div className="text-message-recu">{item.content}</div>
      </div>
      <div className="d-flex align-items-center pb-2">
        <span className="time-detail-message ps-3">
          {moment(item.created_at).calendar()}
        </span>
      </div>
    </div>
  );
}

function Response({ item }: { item: Chat }) {
  return (
    <div className="bloc-reponse">
      <div className="position-relative sending-msg-item">
        <div className="bloc-message-envoyer p-3">
          <div className="text-message-envoyer">{item.content}</div>
        </div>
        <div className="">
          <div className="d-flex justify-content-end align-items-center">
            <span className="time-detail-message px-2">
              {moment(item.created_at).calendar()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function ChatInput({ userId }: { userId: number }) {
  const user = useAppSelector((s) => s.user.user);
  const [addChat, { isLoading, isError, isSuccess }] = useAddChatMutation();
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: "error",
        iconColor: Color.themeColor,
        confirmButtonColor: Color.themeColor,
        title: "Message non envoyé",
      });
    }
  }, [isError]);
  useEffect(() => {
    if (isSuccess) {
      setMessage("");
    }
  }, [isSuccess]);
  const onSubmit = useCallback(() => {
    if (message.trim()?.length > 1) {
      addChat({
        content: message,
        recever: userId,
        sender: user.id,
      });
    }
  }, [message, user, userId]);
  return (
    <div className="p-3 border-top">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="left-footer">
          <div className="left-footer-container">
            <div className="input-group">
              <div className="input-container">
                <div className="container-display-input-message">
                  <div className="share">
                    <FiPaperclip className="img-icon-chat" />
                  </div>
                  <div className="inp w-100">
                    <textarea
                      className="textarrea-message-custom form-control"
                      rows={1}
                      value={message}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                      name="reponse"
                    >
                      Écrivez votre message ici
                    </textarea>
                  </div>
                </div>
                {/* <div className="d-flex gap-2">
                  <div className="emoji">
                    <BsEmojiWink className="img-icon-chat" />
                  </div>
                  <div className="camera">
                    <HiOutlineCamera className="img-icon-chat" />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="btn-container custom-btn-chat-container">
              {/* <button>
                <BsFillMicFill className="img-icon-chat" />
              </button> */}
              {!isLoading && (
                <button type="submit" className="btn btn-send-message-tabs">
                  <RiSendPlaneFill />
                </button>
              )}
              {isLoading && (
                <button
                  disabled
                  type="button"
                  className="btn btn-send-message-tabs"
                >
                  <FaSpinner />
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

function ActiveOrDesactive({ item }: { item: ILocataire | IUser }) {
  const { user } = useAppSelector((s) => s?.user);
  const activeDesactive = UseActiveOrDesactive(user);
  return (
    <button
      className={`btn btn btn-see-more ${
        user?.app_notification == true ? "notif-enabled" : "notif-disabled"
      }`}
      onClick={activeDesactive}
    >
      {user?.app_notification == true
        ? "Désactiver les notifications "
        : "Activer les notifications "}
    </button>
  );
}
