import React from 'react'
import VolkenoSkeleton from 'volkeno-react-skeleton'

function ProprieteSkeleton() {
  return (
    <div className="col-lg-6 col-md-6 mb-3">
      <div className="proprietes-card-item card">
        <VolkenoSkeleton variant="rect" height="316px" width="100%" />
        <div className="card-body pb-0 mb-0">
          <VolkenoSkeleton variant="rect" height={10} width="100%" />
          <VolkenoSkeleton variant="rect" height={13} width="100%" />
          <div className="flex-sb mt-2">
            <div>
              <VolkenoSkeleton variant="rect" height={10} width={100} />
            </div>
            <div>
              <VolkenoSkeleton variant="rect" height={10} width={100} />
            </div>
            <div>
              <VolkenoSkeleton variant="rect" height={10} width={100} />
            </div>
          </div>
          <VolkenoSkeleton variant="rect" height={12} width="30%" />
        </div>
        <div className="flex-r pe-2">
          <VolkenoSkeleton variant="circle" height="20px" width="20px" />
        </div>
      </div>
    </div>
  )
}

export default ProprieteSkeleton
