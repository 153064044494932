import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, closeModal, onHide } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { IReseauSociaux, ReseauSociauxFormData } from "../../../../../utils/api/reseauSociaux/reseauSociaux.type";
import { useAddOrUpdateRSociauxMutation } from "../../../../../utils/api/reseauSociaux/reseauSociaux.api";

export function useCrudRsocial(item?: IReseauSociaux) {
	const validationSchema = yup.object().shape({
		url: yup.string().required().label("Url"),
		type_reseau: yup.string().required().label("Type de reseau"),
		description: yup.string().label("Description"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<ReseauSociauxFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdateRSocial, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateRSociauxMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Reseau ${item ? "modifié" : "ajouté"} avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
				closeModal(item ? `AddRSocialModal${item?.slug}` : "AddRSocialModal");
				if(!item) {
					reset();
				}
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("url", item?.url);
			setValue("description", item?.description);
			setValue("type_reseau", item?.type_reseau);
		}
	}, [item]);
	const onSubmit = (data: ReseauSociauxFormData) => {
		console.log("data", data);
		addOrUpdateRSocial({ slug: item?.slug, data: data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
	};
}
