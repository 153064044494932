/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { PersonnelFormData } from "../../../../../../utils/api/agence/agence.type";
import { useAppSelector } from "../../../../../../redux/hooks";
import { cleannerError } from "../../../../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { useAddOrUpdatePersonnelMutation } from "../../../../../../utils/api/agence/agence.api";

function useAddOrUpdateUserPQGSB(personnel?: PersonnelFormData) {
  const validationSchema = yup.object().shape({
    id: yup.number(),
    nom: yup.string().required().label("Nom"),
    prenom: yup.string().required().label("Prenom"),
    email: yup.string().required().label("Email"),
    telephone: yup.string().required().label("Telephone"),
    adresse: yup.string().label("Adresse"),
    profession: yup.string().label("Profession"),
    // user_type: yup.string().label("Type d'utilisateur"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<PersonnelFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [addOrUpdatePersonnel, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdatePersonnelMutation();

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [personne, setPersonne] = React.useState<string>();

  const [address, setAddress] = useState("");

  const onChangeAddress = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
  };

  useEffect(() => {
    if (isSuccess) {
      openModal();
      setPersonne(data?.prenom + " " + data?.nom);
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  function openModal() {
    setIsOpen(true);
  }

  const { user } = useAppSelector((s) => s?.user);

  const onSubmit = (data: any) => {
    // console.log("data submitted", data);
    data["proprietaire_id"] = user?.id;
    data["user_type"] = "comptable";
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    // console.log("data", data);
    addOrUpdatePersonnel({ slug: personnel?.slug, data: fd });
  };

  const handleReset = () => {
    if (!personnel) {
      reset();
    }
  };

  useEffect(() => {
    if (personnel?.id) {
      const fields: (keyof PersonnelFormData)[] = [
        "nom",
        "prenom",
        "email",
        "telephone",
        "adresse",
        "code_postal",
      ];
      // console.log("fields")

      for (let field of fields) {
        register(field);
        setValue(field, personnel[field]);
        setAddress(personnel?.adresse);
        // if (field === "user_type") {
        //   setValue(field, personnel[field] //?.id
        //   );
        // } else {
        //   setValue(field, personnel[field]);
        // }
      }
    }
  }, [personnel]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    modalIsOpen,
    setIsOpen,
    personne,
    onChangeAddress,
    address,
  };
}

export default useAddOrUpdateUserPQGSB;
