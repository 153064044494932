import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getAvatar,
  isAgenceAgent,
  isAgenceJuriste,
  isAgenceMarketeur,
  isProprietaireAgence,
  isProprietaireOwner,
} from "../../../../utils/Utils";
import { IoMdKey } from "react-icons/io";
import {
  BsFillChatDotsFill,
  BsFillFileEarmarkTextFill,
  BsInfoSquareFill,
} from "react-icons/bs";
import {
  ActivityIcon,
  SettingsIcon,
  PasswordIcon,
} from "../../../common/Button";
import { AiFillSetting } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { IoDocumentText, IoLogOut } from "react-icons/io5";
import { TbBrandAsana } from "react-icons/tb";
import { onlogout } from "../../../../redux/slice/User.slice";
import { isSuperAdmin } from "../../../../routes/router";
import { MdLocalOffer } from "react-icons/md";

import { useMarkUserOflineMutation } from "../../../../utils/api/auth/auth.api";

const SidebarMobile = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((s) => s?.user);
  const userType = user?.user_type;
  const is_SuperAdmin = isSuperAdmin(user);
  const is_ProprietaireAgence = isProprietaireAgence(user);
  const is_ProprietaireOwner = isProprietaireOwner(user);
  const [markUserOfline] = useMarkUserOflineMutation();

  // const handleLogout = () => {
  //   markUserOfline({ slug: user?.slug });
  //   dispatch(onlogout());
  //   window.location.pathname = "/connexion";
  // };
  const handleLogout = async () => {
    try {
      await markUserOfline({ slug: user?.slug });
      dispatch(onlogout());
      window.location.pathname = "/connexion";
    } catch (error) {
      console.error("Error marking user offline:", error);
    }
  };
  return (
    <div>
      <div className="container-nav">
        <input type="checkbox" id="check" />
        <label htmlFor="check">
          <AiOutlineMenuUnfold />
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className="sidebar" id="scrollbar-nav-mobile">
          <div className="content-nav-mobile">
            <div className="container-user-connect-info pb-3">
              <div className="container-img-user-connect-nav-menu">
                <img
                  className="img-user-connect-navmenu"
                  src={getAvatar(user?.avatar)}
                  alt="user connect"
                />
              </div>
              <div className="container-info-user-connect-navmenu-mobile ps-2">
                <p className="info-user-connect-navmenu mb-1">Bienvenue</p>
                <p
                  className="info-user-connect-navmenu mb-0"
                  style={{ fontWeight: "bold" }}
                >
                  {`${user?.prenom} ${user?.nom}`}
                </p>
              </div>
            </div>
            <ul className="nav nav-items-custom flex-column">
              {userType === "agence" && (
                <>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/agence/dashboard"
                    >
                      <BiCategory />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom position-relative">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/agence/messagerie"
                    >
                      <HiMail />
                      <span className="item-nav-menu drop-header">
                        Messages{" "}
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/agence/locataires"
                    >
                      <FaUsers />
                      <span className="item-nav-menu drop-header">
                        Mes locataires
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/agence/proprietes"
                    >
                      <IoMdKey />
                      <span className="item-nav-menu drop-header">
                        Mes propriétés
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/agence/proprietaires"
                    >
                      <FaUsers />
                      <span className="item-nav-menu drop-header">
                        Mes propriétaires
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/agence/comptabilite"
                    >
                      <IoDocumentText />
                      <span className="item-nav-menu drop-header">
                        Comptabilité
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/agence/administratif"
                    >
                      <TbBrandAsana />
                      <span className="item-nav-menu drop-header">
                        Administratif
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/agence/option"
                    >
                      <AiFillSetting />
                      <span className="item-nav-menu drop-header">Options</span>
                    </NavLink>
                  </li>
                </>
              )}
              {(isAgenceAgent(user) ||
                isAgenceJuriste(user) ||
                isAgenceMarketeur(user)) && (
                <>
                  {user?.dashboard && (
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom"
                        to="/agence/dashboard"
                      >
                        <BiCategory />
                        <span className="item-nav-menu drop-header">
                          Dashboard
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {user?.message && (
                    <li className="nav-item nav-item-vertical-custom position-relative">
                      <NavLink
                        className="nav-link nav-link-vertival-custom"
                        to="/agence/messagerie"
                      >
                        <HiMail />
                        <span className="item-nav-menu drop-header">
                          Messages{" "}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {user?.locatair && (
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom"
                        to="/agence/locataires"
                      >
                        <FaUsers />
                        <span className="item-nav-menu drop-header">
                          Mes locataires
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {user?.bien && (
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom"
                        to="/agence/proprietes"
                      >
                        <IoMdKey />
                        <span className="item-nav-menu drop-header">
                          Mes propriétés
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {user?.location && (
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom"
                        to="/agence/proprietaires"
                      >
                        <FaUsers />
                        <span className="item-nav-menu drop-header">
                          Mes propriétaires
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {user?.paiement && (
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom"
                        to="/agence/comptabilite"
                      >
                        <IoDocumentText />
                        <span className="item-nav-menu drop-header">
                          Comptabilité
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {user?.administratif && (
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom"
                        to="/agence/administratif"
                      >
                        <TbBrandAsana />
                        <span className="item-nav-menu drop-header">
                          Administratif
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {user?.options && (
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom"
                        to="/agence/option"
                      >
                        <AiFillSetting />
                        <span className="item-nav-menu drop-header">
                          Options
                        </span>
                      </NavLink>
                    </li>
                  )}
                </>
              )}

              {userType === "locataire" && (
                <>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/locataire/mon-loyer"
                    >
                      <PasswordIcon />
                      <span className="item-nav-menu drop-header">
                        Mon Loyer
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/locataire/requetes"
                    >
                      <BsFillChatDotsFill />
                      <span className="item-nav-menu drop-header">
                        Requêtes
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom position-relative">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/locataire/messages"
                    >
                      <HiMail />
                      <span className="item-nav-menu drop-header">
                        Messages{" "}
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/locataire/administratif"
                    >
                      <BsFillFileEarmarkTextFill />
                      <span className="item-nav-menu drop-header">
                        Administratif
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/locataire/parametre"
                    >
                      <SettingsIcon />

                      <span className="item-nav-menu drop-header">
                        Paramètre
                      </span>
                    </NavLink>
                  </li>
                </>
              )}

              {userType === "proprietaire" && user?.gerer_par_moi === true && (
                <>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietaire/gerer-par-le-proprietaire/dashboard"
                    >
                      <BiCategory />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom position-relative">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietaire/gerer-par-le-proprietaire/messages"
                    >
                      <HiMail />
                      <span className="item-nav-menu drop-header">
                        Messages{" "}
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietaire/gerer-par-le-proprietaire/proprietes"
                    >
                      <IoMdKey />
                      <span className="item-nav-menu drop-header">
                        Mes propriétés
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietaire/gerer-par-le-proprietaire/locataires"
                    >
                      <FaUsers />
                      <span className="item-nav-menu drop-header">
                        Locataires
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietaire/gerer-par-le-proprietaire/comptabilite"
                    >
                      <IoDocumentText />
                      <span className="item-nav-menu drop-header">
                        Comptabilité
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietaire/gerer-par-le-proprietaire/administratif"
                    >
                      <TbBrandAsana />
                      <span className="item-nav-menu drop-header">
                        Administratif
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietaire/gerer-par-le-proprietaire/parametres"
                    >
                      <SettingsIcon />

                      <span className="item-nav-menu drop-header">
                        Paramètre
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
              {userType === "comptable" && (
                <>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/comptable/dashboard"
                    >
                      <BiCategory />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom position-relative">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/comptable/messages"
                    >
                      <HiMail />
                      <span className="item-nav-menu drop-header">
                        Messages{" "}
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/comptable/mes-entrees"
                    >
                      <ActivityIcon />
                      <span className="item-nav-menu drop-header">
                        Mes revenus
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/comptable/depenses"
                    >
                      <ActivityIcon />
                      {/* <ActivityIcon set="bold" primaryColor="#B8BED9" /> */}
                      <span className="item-nav-menu drop-header">
                        Dépenses
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/comptable/categorie"
                    >
                      <ActivityIcon />
                      {/* <ActivityIcon set="bold" primaryColor="#B8BED9" /> */}
                      <span className="item-nav-menu drop-header">
                        Categorie
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/comptable/options"
                    >
                      <SettingsIcon />

                      <span className="item-nav-menu drop-header">Options</span>
                    </NavLink>
                  </li>
                </>
              )}
              {(userType === "admin" || userType === "superadmin") && (
                <>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    hidden={!user["dashboard"] && !is_SuperAdmin}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/dashboard"
                    >
                      <BiCategory />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom position-relative"
                    hidden={!user["message"] && !is_SuperAdmin}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/messages"
                    >
                      <HiMail />
                      <span className="item-nav-menu drop-header">
                        Messages{" "}
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    hidden={!user["client"] && !is_SuperAdmin}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/mes-clients"
                    >
                      <FaUsers />
                      <span className="item-nav-menu drop-header">
                        Mes clients
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    hidden={!user["client"] && !is_SuperAdmin}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/nos-offres"
                    >
                      <MdLocalOffer />
                      <span className="item-nav-menu drop-header">
                        Nos offres
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    hidden={!user["new"] && !is_SuperAdmin}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/news"
                    >
                      <IoDocumentText />
                      <span className="item-nav-menu drop-header">News</span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    hidden={!user["recette"] && !is_SuperAdmin}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/recettes"
                    >
                      <IoDocumentText />
                      <span className="item-nav-menu drop-header">
                        Recettes
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    hidden={!user["administratif"] && !is_SuperAdmin}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/administratif"
                    >
                      <IoDocumentText />
                      <span className="item-nav-menu drop-header">
                        Administratif
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    hidden={!user["lead"] && !is_SuperAdmin}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/lead"
                    >
                      <BsInfoSquareFill />
                      <span className="item-nav-menu drop-header">Lead</span>
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-item-vertical-custom"
                    hidden={!user["options"] && !is_SuperAdmin}
                  >
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/options"
                    >
                      <SettingsIcon />

                      <span className="item-nav-menu drop-header">Options</span>
                    </NavLink>
                  </li>
                </>
              )}

              {userType === "proprietaire" && user?.gerer_par_moi === false && (
                <>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietes/gerer-par-agence/dashboard"
                    >
                      <BiCategory />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom position-relative">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietes/gerer-par-agence/messages"
                    >
                      <HiMail />
                      <span className="item-nav-menu drop-header">
                        Messages{" "}
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietes/gerer-par-agence/agences"
                    >
                      <IoDocumentText />
                      <span className="item-nav-menu drop-header">Agences</span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietes/gerer-par-agence/proprietes"
                    >
                      <IoMdKey />
                      <span className="item-nav-menu drop-header">
                        Mes propriétés
                      </span>
                    </NavLink>
                  </li>
                  {/* <li
                            className="nav-item nav-item-vertical-custom"
                        >
                            <NavLink className="nav-link nav-link-vertival-custom" to="/proprietaire/gerer-par-le-proprietaire/comptabilite">
                                <IoDocumentText />
                                <span className="item-nav-menu drop-header">Comptabilité</span>
                            </NavLink>
                        </li> */}
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietes/gerer-par-agence/administratif"
                    >
                      <IoDocumentText />
                      <span className="item-nav-menu drop-header">
                        Administratif
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/proprietes/gerer-par-agence/parametres"
                    >
                      <SettingsIcon />

                      <span className="item-nav-menu drop-header">
                        Paramètre
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            <div className="container-btn-logout">
              <button className="btn btn-logout" onClick={handleLogout}>
                <IoLogOut />
                <span className="ps-2">Déconnexion</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
