import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import AdministrateursAdminSkeleton from "./AdministrateursAdminSkeleton";
import { MdEdit } from "react-icons/md";
import { IoMdSwitch, IoMdUnlock } from "react-icons/io";
import { FaLock, FaUnlock } from "react-icons/fa";
import { useAdminsListQuery } from "../../../../../utils/api/admin/admin.api";
import { CustomPagination } from "../../../../common/CustomPagination";
import { IAdmin } from "../../../../../utils/api/admin/admin.type";
import { getName } from "../../../../../utils/Utils";
import { Link } from "react-router-dom";
import { useToggleArchiveAdmin } from "../../../../TableauDebord/Admin/Options/CrudAdministrateur/useForm/UseCrudAdmin";
import { Color } from "../../../../../utils/theme";
import { AlertInfo } from "../../../../common/Alert";
import { UseGeneratePassword } from "../../../../TableauDebord/Agence/ProprietaireAgence/useForm/useCrudProprietaire";
import { TbArchive, TbArchiveOff } from "react-icons/tb";
// import { GeneratePassword } from "../../../agence/ProprietaireAgenceTable/ProprietaireAgenceTable";
export function GeneratePassword({ slug }: { slug: string }) {
  const onGenerate = UseGeneratePassword(slug);
  return (
    <button
      className="btn btn-action-modal-icon custom-btn-action-modal-icon with-tooltip"
      data-tooltip-content="Génération de mot de passe"
      onClick={onGenerate}
    >
      <IoMdUnlock />
    </button>
  );
}

function AdministrateursAdminTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading } = useAdminsListQuery({ limit: perPage, page });

  const actionFormatter: any = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes">
        <div>
          <div className=" gap-2 d-flex">
            <button
              className="btn btn-action-modal-icon custom-btn-action-modal-icon with-tooltip"
              data-tooltip-content="Switch"
            >
              <IoMdSwitch />
            </button>
            <Link
              to={`/admin/modifier-administrateur/${row?.slug}`}
              className="btn btn-action-modal-icon custom-btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdEdit />
            </Link>
            <DeleteAdmin item={row} />
            <GeneratePassword slug={row?.slug} />
          </div>
        </div>
      </div>
    );
  };

  const redirectFormatter = (cell: string, row: IAdmin) => {
    return (
      <Link
        to={`/admin/details-administrateur/${row?.slug}`}
        className="no-link"
        state={row}
      >
        {cell}
      </Link>
    );
  };

  const columns = [
    {
      dataField: "fullname",
      text: "Prénom & Nom ",
      formatter: (cell: any, row: IAdmin) => (
        <Link
          to={`/admin/details-administrateur/${row?.slug}`}
          className="no-link"
          state={row}
        >
          {getName(row)}
        </Link>
      ),
    },
    // {
    // 	dataField: "profession",
    // 	text: "Profession",
    // 	style: { textAlign: "left" },
    // },
    {
      dataField: "email",
      text: "Email",
      style: { textAlign: "left" },
      formatter: (cell: string, row: IAdmin) => redirectFormatter(cell, row),
    },
    {
      dataField: "telephone",
      text: "Téléphone",
      style: { textAlign: "left" },
      formatter: (cell: string, row: IAdmin) => redirectFormatter(cell, row),
    },
    {
      dataField: "actions",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <AdministrateursAdminSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée disponible" />
            )}
            rowStyle={{ cursor: "pointer" }}
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export function DeleteAdmin({ item }: { item: IAdmin }) {
  const onDelete = useToggleArchiveAdmin(item);
  return (
    <button
      className="btn btn-action-modal-icon custom-btn-action-modal-icon with-tooltip"
      data-tooltip-content={`${item?.is_archive ? "Désarchiver" : "Archiver"}`}
      onClick={onDelete}
    >
      {item?.is_archive ? (
        <TbArchive color={Color.success} />
      ) : (
        <TbArchiveOff />
      )}
    </button>
  );
}

export default AdministrateursAdminTable;
