import React from 'react'
import { BiEditAlt } from 'react-icons/bi'
import { IoMdTrash } from 'react-icons/io'
import { useGetConditionsQuery } from '../../../../../utils/api/condition/condition.api';
import { ICondition } from '../../../../../utils/api/condition/condition.type';
import { createMarkup, showModal } from '../../../../../utils/Utils'
import { AlertInfo } from '../../../../common/Alert';
import AjouterConditionModal from '../../../../modal/admin/AjouterConditionModal';
import { UseDeleteCondition } from '../CrudCondition/useForm/UseCrudCondition';

function ConditionsUtilisation() {
    const { data, isLoading } = useGetConditionsQuery({});
    // console.log("condition", data)
    return (
        <div className="bg-white p-3 filter-and-table-container">
            <div className='row'>
                <div className="col-md-12">
                    <div className='kanimmo-cond-utilisation-ol-container'>
                        <ol>
                            {data?.results?.length === 0 && <AlertInfo message='Pas de données' />}
                            {data?.results?.map(contenu => (
                                <li key={contenu?.id} className="mb-5 kanimmo-cond-utilisation-ol-custom-li">
                                    <span>
                                        {contenu?.titre && contenu?.titre}
                                    </span>
                                    <div className='kanimmo-cond-utilisation-ol-item-container'>
                                    <p
                                        className="text-cgu-admin pb-2"
                                        dangerouslySetInnerHTML={createMarkup(contenu?.text)}
                                    />
                                    </div>
                                    <div className='kanimmo-cond-utilisation-ol-btn-container'>
                                        <button 
                                            className='btn kanimmo-cond-utilisation-ol-btn-edit'
                                            // id={`editContent${contenu?.slug}`}
                                            // data-bs-toggle="modal"
                                            // data-bs-target={`#editContent${contenu?.slug}`}
                                            onClick={() => showModal(`editContent${contenu?.slug}`)}
                                        >
                                            <BiEditAlt />
                                        </button>
                                        <DeleteCondition item={contenu} />
                                    </div>

                                     <div
                                        className="modal fade"
                                        id={`editContent${contenu?.slug}`}
                                        aria-labelledby="editContentLabel"
                                        aria-hidden="true"
                                    >
                                        <AjouterConditionModal modalId={`editContent${contenu?.slug}`} condition={contenu} />
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConditionsUtilisation

function DeleteCondition({ item }: { item: ICondition }) {
	const deleteCondition = UseDeleteCondition(item);
	return (
        <button 
            className='btn kanimmo-cond-utilisation-ol-btn-delete'
            onClick={deleteCondition}
        >
            <IoMdTrash />
        </button>
	);
}

export const data = [
    {
        id: 1,
        titre: "Introduction",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis turpis hendrerit nisi felis. Tincidunt blandit tristique molestie sit tincidunt erat in turpis. Congue sed id nulla porta nunc tempus senectus tempor. Massa nibh eget condimentum vitae egestas donec sed. Viverra nisi, nisi ipsum id eu. Vestibulum blandit tincidunt vel enim proin. Ac sem dui, purus tincidunt nisl et. Neque congue nec metus, dictum vitae purus phasellus amet. Sed iaculis arcu turpis at pulvinar. Duis et tellus nulla sapien purus sit pellentesque. Gravida sem lorem habitasse neque arcu facilisis. Arcu vitae pellentesque enim et elit mauris sed. Viverra cursus mauris purus turpis.",
    },
    {
        id: 2,
        titre: "Informations collectées",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis turpis hendrerit nisi felis. Tincidunt blandit tristique molestie sit tincidunt erat in turpis. Congue sed id nulla porta nunc tempus senectus tempor. Massa nibh eget condimentum vitae egestas donec sed. Viverra nisi, nisi ipsum id eu. Vestibulum blandit tincidunt vel enim proin. Ac sem dui, purus tincidunt nisl et. Neque congue nec metus, dictum vitae purus phasellus amet. Sed iaculis arcu turpis at pulvinar. Duis et tellus nulla sapien purus sit pellentesque. Gravida sem lorem habitasse neque arcu facilisis. Arcu vitae pellentesque enim et elit mauris sed. Viverra cursus mauris purus turpis.",
    },
    {
        id: 3,
        titre: "Pourquoi la collection des données",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis turpis hendrerit nisi felis. Tincidunt blandit tristique molestie sit tincidunt erat in turpis. Congue sed id nulla porta nunc tempus senectus tempor. Massa nibh eget condimentum vitae egestas donec sed. Viverra nisi, nisi ipsum id eu. Vestibulum blandit tincidunt vel enim proin. Ac sem dui, purus tincidunt nisl et. Neque congue nec metus, dictum vitae purus phasellus amet. Sed iaculis arcu turpis at pulvinar. Duis et tellus nulla sapien purus sit pellentesque. Gravida sem lorem habitasse neque arcu facilisis. Arcu vitae pellentesque enim et elit mauris sed. Viverra cursus mauris purus turpis.",
    },
    {
        id: 4,
        titre: "Vos contrôles de confidentialité",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis turpis hendrerit nisi felis. Tincidunt blandit tristique molestie sit tincidunt erat in turpis. Congue sed id nulla porta nunc tempus senectus tempor. Massa nibh eget condimentum vitae egestas donec sed. Viverra nisi, nisi ipsum id eu. Vestibulum blandit tincidunt vel enim proin. Ac sem dui, purus tincidunt nisl et. Neque congue nec metus, dictum vitae purus phasellus amet. Sed iaculis arcu turpis at pulvinar. Duis et tellus nulla sapien purus sit pellentesque. Gravida sem lorem habitasse neque arcu facilisis. Arcu vitae pellentesque enim et elit mauris sed. Viverra cursus mauris purus turpis.",
    },
    {
        id: 5,
        titre: "Partage de vos informations",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis turpis hendrerit nisi felis. Tincidunt blandit tristique molestie sit tincidunt erat in turpis. Congue sed id nulla porta nunc tempus senectus tempor. Massa nibh eget condimentum vitae egestas donec sed. Viverra nisi, nisi ipsum id eu. Vestibulum blandit tincidunt vel enim proin. Ac sem dui, purus tincidunt nisl et. Neque congue nec metus, dictum vitae purus phasellus amet. Sed iaculis arcu turpis at pulvinar. Duis et tellus nulla sapien purus sit pellentesque. Gravida sem lorem habitasse neque arcu facilisis. Arcu vitae pellentesque enim et elit mauris sed. Viverra cursus mauris purus turpis.",
    },
    {
        id: 6,
        titre: "Sécurisation de vos informations",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis turpis hendrerit nisi felis. Tincidunt blandit tristique molestie sit tincidunt erat in turpis. Congue sed id nulla porta nunc tempus senectus tempor. Massa nibh eget condimentum vitae egestas donec sed. Viverra nisi, nisi ipsum id eu. Vestibulum blandit tincidunt vel enim proin. Ac sem dui, purus tincidunt nisl et. Neque congue nec metus, dictum vitae purus phasellus amet. Sed iaculis arcu turpis at pulvinar. Duis et tellus nulla sapien purus sit pellentesque. Gravida sem lorem habitasse neque arcu facilisis. Arcu vitae pellentesque enim et elit mauris sed. Viverra cursus mauris purus turpis.",
    },
    {
        id: 7,
        titre: "Exportation et suppression de vos informations",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis turpis hendrerit nisi felis. Tincidunt blandit tristique molestie sit tincidunt erat in turpis. Congue sed id nulla porta nunc tempus senectus tempor. Massa nibh eget condimentum vitae egestas donec sed. Viverra nisi, nisi ipsum id eu. Vestibulum blandit tincidunt vel enim proin. Ac sem dui, purus tincidunt nisl et. Neque congue nec metus, dictum vitae purus phasellus amet. Sed iaculis arcu turpis at pulvinar. Duis et tellus nulla sapien purus sit pellentesque. Gravida sem lorem habitasse neque arcu facilisis. Arcu vitae pellentesque enim et elit mauris sed. Viverra cursus mauris purus turpis.",
    },
]