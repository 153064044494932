import React from "react";
import { Offer } from "../../../../../utils/api/offer/offer.type";
import {
  closeModal,
  createMarkup,
  formatAmount,
} from "../../../../../utils/Utils";
import { BtnCloseModal } from "../../../../common/Button";
import "./OfferDetails.css"

function OfferDetailsModal({
  modalId,
  offer,
}: {
  modalId: string;
  offer: Offer;
}) {
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header px-2 py-1">
            <h5 className="modal-title" id="AddTagModalLabel">
              Détails d'une offre
            </h5>
            <BtnCloseModal label="" onClick={() => closeModal(modalId)} />
          </div>
          <div className="modal-body">
            <div className="offer-admin-containe">
              <div className="offer-admin-content">
                <div className="offer-admin-title">
                  <h4>
                    {" "}
                    {offer?.max_numb
                      ? offer?.min_numb + "-" + offer?.max_numb
                      : offer?.min_numb + "+"}{" "}
                  </h4>
                </div>
                <div className="offer-admin-price">
                  <span> {formatAmount(offer?.montant)} </span>
                </div>

                <div className="offer-admin-description text-start">
                  <p
                    dangerouslySetInnerHTML={createMarkup(offer?.description)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferDetailsModal;
