import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { IAgence } from "../agence/agence.type";
import { IProprietaire } from "../proprietaire/proprietaire.type";
import { prepareHeaders } from "../user/user.api";
import { AuthState, PaginationResults, TypeQuery } from "../user/user.type";
import {
  DemandebailFormData,
  IDemandebail,
  ILocataire,
  LocataireFormData,
} from "./locataire.type";

export const LocataireApi = createApi({
  reducerPath: "locataireApi",
  tagTypes: [
    "locataires",
    "locataire_slug",
    "auth",
    "demandebail",
    "demandebail_slug",
    "locataireBySlug",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    registerLocataire: build.mutation<
      AuthState["user"],
      LocataireFormData | FormData
    >({
      query: (data) => ({
        url: "locataire/inscriptions/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
      transformResponse: ({ data }) => data,
    }),

    addOrUpdateLocataire: build.mutation<
      ILocataire,
      { slug?: string; data: LocataireFormData | FormData }
    >({
      invalidatesTags: ["locataires", "locataire_slug"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `locataires/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `locataires/`,
          method: "POST",
          body: data,
        };
      },
    }),
    sendSlugs: build.mutation<void, { slug_user: string | undefined; locataire_slug: string | undefined }>({
      query: ({ slug_user, locataire_slug }) => ({
        url: `locataire_delete_history_archive/`,
        method: "POST",
        body: { slug_user, locataire_slug },
      }),
    }),
    deleteLocataire: build.mutation<LocataireFormData, string>({
      query: (slug) => ({
        url: `locataires/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["locataires", "locataire_slug"],
    }),
    getLocataireInfoBySlug: build.query<ILocataire | any, TypeQuery>({
      query: (slug) => `locataires/${slug}`,
      providesTags: ["locataireBySlug"],
    }),
    getLocataireArchiveBySlug: build.query<ILocataire | any, TypeQuery>({
      query: (slug) => `locataires_history_bailleur/${slug}`,
      providesTags: ["locataireBySlug"],
    }),
    getLocataireByAgenceBySlug: build.query<
      PaginationResults<ILocataire>,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`agence/${slug}/locataires/`, query),
      providesTags: ["locataires"],
    }),
    getBailleurInfosBySlug: build.query<
      IAgence | IProprietaire | any,
      TypeQuery
    >({
      query: (slug) => `locataire/${slug}/bailleur/`,
      providesTags: ["locataires"],
    }),
    locataireByAgenceBySlug: build.query<ILocataire | any, TypeQuery>({
      query: (slug) => `agence/${slug}/locataires/`,
      providesTags: ["locataires"],
    }),
    getLocataireBySlug: build.query<ILocataire | any, TypeQuery>({
      query: (slug) => `locataires/${slug}`,
      providesTags: ["locataire_slug"],
    }),
    getLocataireByProprieteSlug: build.query<ILocataire | any, TypeQuery>({
      query: ({ slug, ...query }) => `propriete/${slug}/locataires/`,
      providesTags: ["locataire_slug"],
    }),
    archiveLocataire: build.mutation<void, string>({
      query: (slug) => ({
        url: `locataire/${slug}/archive/`,
        method: "PUT",
      }),
      invalidatesTags: ["locataires", "locataire_slug"],
    }),
    sendLocataireEmail: build.mutation<void, { email: string }>({
      query: (data) => ({
        url: "locataire/send-email/",
        method: "POST",
        body: data,
      }),
    }),
    addOrUpdateDemandeBail: build.mutation<
      IDemandebail,
      { slug?: string; data: DemandebailFormData | FormData }
    >({
      invalidatesTags: ["demandebail", "demandebail_slug"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `demandebail/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `demandebail/`,
          method: "POST",
          body: data,
        };
      },
    }),
    confirmProratat: build.query<ILocataire | any, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`locataire/${slug}/proratat/`, query),
    }),
  }),
});

export const {
  useAddOrUpdateLocataireMutation,
  useSendSlugsMutation,
  useDeleteLocataireMutation,
  useRegisterLocataireMutation,
  useGetLocataireByAgenceBySlugQuery,
  useLazyGetLocataireByAgenceBySlugQuery,
  useLazyLocataireByAgenceBySlugQuery,
  useLocataireByAgenceBySlugQuery,
  useGetLocataireBySlugQuery,
  useLazyGetLocataireBySlugQuery,
  useGetLocataireInfoBySlugQuery,
  useLazyGetLocataireInfoBySlugQuery,
  useAddOrUpdateDemandeBailMutation,
  useArchiveLocataireMutation,
  useSendLocataireEmailMutation,
  useGetBailleurInfosBySlugQuery,
  useGetLocataireArchiveBySlugQuery,
  useGetLocataireByProprieteSlugQuery,
  useLazyGetLocataireByProprieteSlugQuery,
  useLazyConfirmProratatQuery,
} = LocataireApi;

export function useLocataireFromLocation(): [
  ILocataire,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<ILocataire | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  // const [findBySlug, { data, isError, isLoading }] = useLazyGetLocataireByAgenceBySlugQuery();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyGetLocataireBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      console.log("slug",slug)
      findBySlug(slug as any);
    }
  }, [itemState, slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/agence/dashboard", { replace: true });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data as any);
    }
  }, [data]);

  return [item as ILocataire, isLoading, slug as string, findBySlug as any];
}

export function useLocataireDetailsFromLocation(): [
  ILocataire,
  (slug: string) => any
] {
  const itemState = useLocationState<ILocataire | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading, error }] =
    useLazyGetLocataireBySlugQuery();

  useEffect(() => {
    if (itemState?.slug) {
      findBySlug(itemState?.slug as any);
    }
  }, [itemState]);
  useEffect(() => {
    if (isError && !itemState) {
      console.log("error", error);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("slug", data);
      setItem(data);
    }
  }, [data]);

  return [item as ILocataire, findBySlug as any];
}

export function useLocataireInfoFromLocation(): [
  ILocataire,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<ILocataire | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  // const [findBySlug, { data, isError, isLoading }] = useLazyGetLocataireByAgenceBySlugQuery();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyGetLocataireInfoBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as any);
    }
  }, [itemState, slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/agence/dashboard", { replace: true });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data as any);
    }
  }, [data]);

  return [item as ILocataire, isLoading, slug as string, findBySlug as any];
}

export function useLocataireInfoDetailsFromLocation(): [
  ILocataire,
  (slug: string) => any
] {
  const itemState = useLocationState<ILocataire | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading, error }] =
    useLazyGetLocataireInfoBySlugQuery();

  useEffect(() => {
    if (itemState?.slug) {
      findBySlug(itemState?.slug as any);
    }
  }, [itemState]);
  useEffect(() => {
    if (isError && !itemState) {
      console.log("error", error);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("slug", data);
      setItem(data);
    }
  }, [data]);

  return [item as ILocataire, findBySlug as any];
}
