import React from "react";
import { IContrat } from "../../../utils/api/contrat/contrat.type";
import { ApiBaseUrl } from "../../../utils/http";
import { get_url_extension } from "../../../utils/Utils";
import { AlertInfo } from "../../common/Alert";
import { GoogleDocs } from "../agence/DocumentLitigeModal";

const DocumentContratModal = ({ item }: { item: IContrat }) => {
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            {!item?.locataire?.contrat && (
              <div className="py-5">
                <AlertInfo message="Le fichier est indisponible!" />
              </div>
            )}
            {item?.locataire?.contrat &&
              (get_url_extension(item?.locataire?.contrat) === "pdf" ? (
                <iframe
                  src={ApiBaseUrl + item?.locataire?.contrat}
                  frameBorder="0"
                  width={`100%`}
                  height={500}
                  allowFullScreen
                  seamless
                  title="doc"
                ></iframe>
              ) : (
                <GoogleDocs
                  file={ApiBaseUrl + item?.locataire?.contrat}
                  height={"500px"}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentContratModal;
