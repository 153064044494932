import React from "react";
import Avatar from "../../../assets/appImages/avatar.png";
import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { BsEmojiWink, BsFillMicFill } from "react-icons/bs";
import { HiOutlineCamera } from "react-icons/hi";
import { FiPaperclip } from "react-icons/fi";

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -42%)",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      border: "none",
      zIndex: 1058,
      width: '50%',
      padding: 0,
    },
  };

const MessageModal = ({ modalIsOpen, setIsOpen } : {modalIsOpen: boolean, setIsOpen: any}) => {
    function closeModal() {
        setIsOpen(false);
    }
  return (
    <Modal
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Example Modal"
  >
    <div className="body-modal dtails-messages-tabs-component">
     <div className="content-modal-send-message">
        <div className="row align-items-center border-bottom p-3">
            <div className="col-xl-1 col-lg-2 col-sm-1 col-md-3 px-0">
                <img
                    src={Avatar}
                    className="image-profil-entete pe-0"
                    alt="profil detail message"
                />
            </div>
            <div className="col-xl-10 col-lg-9 col-md-10 col-sm-9 ps-0">
                <div className="msg-user-infos-container">
                    <div className="d-flex align-items-center msg-user-name">
                        <p className="profil-detail-message mb-0">
                            Soxna Ngoné
                        </p>
                    </div>
                    <div className="bloc-user-disconnect-time msg-user-lastonline">
                        <p className="text-disconnect-time mb-0">Online - Last seen, 2.02pm</p>
                    </div>
                </div>
            </div>
            <div className="col-md-1 flex-r" onClick={closeModal}>
                <IoMdClose style={{ color: '#FCAE3B' }} />
            </div>
        </div>
        <div className="bloc-details pb-5 custom-bloc-details">
				{/* <div className="bloc-reponse">
					<div className="position-relative sending-msg-item">
						<div className="bloc-message-envoyer p-3">
							<span className="text-message-envoyer"></span>
						</div>
						<div className="">
							<div className="d-flex justify-content-end align-items-center">
								<span className="time-detail-message px-2"></span>
							</div>
						</div>
					</div>
				</div> */}
			</div>
			<div className="p-3 border-top">
				<form>
                <div className="left-footer">
                <div className="left-footer-container">
                  <div className="input-group">
                    <div className="input-container">
                      <div className='container-display-input-message'>
                        <div className="share">
                          <FiPaperclip
                            className="img-icon-chat"
                          />
                        </div>
                        <div className="inp w-100">
                          <textarea className='textarrea-message-custom form-control' rows={1}>Type your message here...</textarea>
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                      <div className="emoji">
                        <BsEmojiWink
                          className="img-icon-chat"
                        />
                      </div>
                      <div className="camera">
                        <HiOutlineCamera
                          className="img-icon-chat"
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-container">
                    <button>
                      <BsFillMicFill
                        className="img-icon-chat"
                      />
                    </button>
                  </div>
                </div>
              </div>
				</form>
			</div>
     </div>
    </div>
  </Modal>
  );
};

export default MessageModal;
