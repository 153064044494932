import React from "react";
import "./OptionAgence.css";
import MonCompte from "./OptionsStep/MonCompte";
import { FiEdit } from "react-icons/fi";
import Personnalisation from "./OptionsStep/Personnalisation";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../utils/type";
import { NavLink } from "react-router-dom";
import { AiFillPlusSquare } from "react-icons/ai";
import Utilisateurs from "./TabsOptionAgence/Utilisateurs";
import Rappels from "./OptionsStep/Rappels";
import { Button } from "reactstrap";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetAgencesQuery } from "../../../../utils/api/agence/agence.api";
import { isAgence, isProprietaireOwner, onShow } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import ImmeublesAgence from "./TabsOptionAgence/ImmeublesAgence";
import ChangePasswordModal from "../../../modal/admin/ChangePasswordModal";
import RecettesAgence from "../../Admin/Recettes/RecettesAgence";
import OffrePaiement from "./OptionsStep/OffrePaiement";
import Securite from "./OptionsStep/Securite";
// import RecettesAdmin from "../../Admin/Recettes/RecettesAdmin";

const steps = [
  { id: "factures", Component: MonCompte },
  { id: "offresPaiement", Component: OffrePaiement },
  { id: "personnalisation", Component: Personnalisation },
  { id: "utilisateurs", Component: Utilisateurs },
  { id: "billing", Component: RecettesAgence },
  { id: "securite", Component: Securite },
  // { id: "immeubles", Component: ImmeublesAgence },
  // { id: "rappel", Component: Rappels },
];

function OptionAgence() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  const { user } = useAppSelector((s) => s?.user);
  const { data: ListeAgence } = useGetAgencesQuery({});

  // const [sendData] = useAddOrEditUserMutation();
  const AgenceSlug = ListeAgence?.results
    ?.filter((item) => item?.id === user?.agence_id)
    ?.map((itm) => itm?.slug);

  const tabs = [
    "Mon compte",
    isAgence(user) || isProprietaireOwner(user) ? "Offre et Paiement" : null,
    isAgence(user) || isProprietaireOwner(user) ? "Personnalisation" : null,
    isAgence(user) || isProprietaireOwner(user) ? "Collaborateurs" : null,
    isAgence(user) || isProprietaireOwner(user) ? "Billing" : null,
    isAgence(user) || isProprietaireOwner(user) ? "Sécurité" : null,
    // "Immeubles",
    // "Rappels",
  ];
  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));
  const props = { AgenceSlug, is_Agence };

  if (!!user.first_connexion && index !== 2 && index !== 3) {
    if (user.user_type === "gerant") {
      onShow("ChangePasswordModal");
    }
  }

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        {/* <div className="kanimmo-dash-locataire-requetes-header"> */}
        {/* <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Options
          </h4> */}

        {/* {index === 3 && (
            <div className="container-btn-add">
              <NavLink
                to={
                  isProprietaireOwner(user)
                    ? "/proprietaire/gerer-par-le-proprietaire/ajouter-collaborateur"
                    : "/agence/ajouter-utilisateur"
                }
                className="btn btn-see-more"
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter un collaborateur</span>
              </NavLink>
            </div>
          )} */}
        {/* {index === 4 && (
            <div className="container-btn-add">
              <Button
                data-bs-toggle="modal"
                data-bs-target="#AjouterRappelModal"
                className="btn btn-see-more"
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter un rappel</span>
              </Button>
            </div>
          )} */}
        {/* {index === 3 && (
            <div className="container-btn-add">
              <Button
                data-bs-toggle="modal"
                data-bs-target="#AjouterImmeubleModal"
                className="btn btn-see-more"
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter un immeuble</span>
              </Button>
            </div>
          )} */}
        {/* </div> */}
        {!!user.first_connexion && index !== 2 && index !== 3 && (
          <div className="container bg-white">
            <div className="alert-firstConnect">
              <AlertInfo message="Veuillez compléter vos informations!" />
            </div>
          </div>
        )}

        <div className="kanimmo-dash-locataire-requetes-header bg-transparent p-0">
          <div className="kanimmo-tabs-container py-3">
            {/* <ul className="tabs-container custom-tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component {...props} />
          </div> */}
            <div className="row ">
              <div className="col-xl-4 col-lg-12 col-md-12 bg-white h-50">
                <div className="col-left-detail-proprietaire ">
                  <h4 className="kanimmo-dash-locataire-requetes-header-titre mt-4 mb-5">
                    Options
                  </h4>
                  <ul className="tabs-container custom-tabs-container">
                    {tabs.map((tab, key) => (
                      <div className="me-5">
                        <li
                          className={`tab-item me-5 ${
                            index === key && "tab-active-item"
                          }`}
                          key={key}
                          onClick={() => go(key)}
                          role="button"
                        >
                          {tab}
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12">
                <div className="col-right-detail-proprietaire mb-3">
                  <div className="content-tabs-detail-propriete">
                    <div className="tabs-component-container">
                      <Component {...props} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OptionAgence;
