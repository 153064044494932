import React from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import OptionUsersTable from "../../../../tables/agence/OptionUsersTable/OptionUsersTable";
import { isProprietaireOwner } from "../../../../../utils/Utils";
import { AiFillPlusSquare } from "react-icons/ai";
import { useAppSelector } from "../../../../../redux/hooks";

const Utilisateurs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const nom = searchParams.get("nom");
  const { user } = useAppSelector((s) => s?.user);

  const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        nom: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };

  return (
    <div className="bg-white p-3 pt-0 filter-and-table-container my-3">
      <div className="d-flex justify-content-end ">
        <div className="container-btn-add">
          <NavLink
            to={
              isProprietaireOwner(user)
                ? "/proprietaire/gerer-par-le-proprietaire/ajouter-collaborateur"
                : "/agence/ajouter-utilisateur"
            }
            className="btn btn-see-more"
          >
            <AiFillPlusSquare style={{ fontSize: 22 }} />
            <span className="ps-2">Ajouter un collaborateur</span>
          </NavLink>
        </div>
      </div>
      <div className="locataire-requete-filtre-container my-4">
        <form className="row g-3 locataire-requete-filtre-form">
          <div className="col-auto">
            <label
              htmlFor="filtreLogement"
              className="locataire-requete-filtre-label"
            >
              Filtrer
            </label>
          </div>
          <div className="col-auto">
            <input
              type="text"
              className="form-control locataire-requete-filtre-input"
              id="filtreLogement"
              placeholder="Entrer un nom"
              onChange={handleFilterByName}
            />
          </div>
        </form>
      </div>
      <div>
        <OptionUsersTable nom={nom} />
      </div>
    </div>
  );
};

export default Utilisateurs;
