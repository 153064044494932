/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useGetAdminAgenceDataQuery , useExportUsersMutation} from "../../../../utils/api/admin/admin.api";
import AgenceAdminTable from "../../../tables/Admin/MesClients/AgenceAdminTable";
import LineChart from "./LineChart/LineChart";
import { BiExport } from "react-icons/bi";
import ExportUsersButton from "./ExportUsersButton";


function AgenceAdmin({
  searchParams,
  setSearchParams,
}: {
  searchParams: any;
  setSearchParams: any;
}) {
  const { data = { admin_graphe: [] } } = useGetAdminAgenceDataQuery({});


  const word = searchParams.get("nom_agence");
  const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        nom_agence: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  
  return (
    <>
      <div className="bg-white p-3 filter-and-table-container">
        <div className="locataire-requete-filtre-container my-4 d-flex justify-content-between align-items-md-center gap-3">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                placeholder="Entrer un nom"
                onChange={handleFilterByName}
              />
            </div>
          </form>
            <div className="user-acount-dashboard-header-display g-2">
                  <ExportUsersButton modelType="agence" /> {/* Utilisation du composant ExportUsersButton */}
            </div>
          {/* <a
            className="kanimmo-admin-client-btn-link-graph"
            href={`#graph-admin-agence`}
          >
            Accéder au graphique
          </a> */}
        </div>
        <div>
          <AgenceAdminTable word={word} />
        </div>
      </div>
      {/* <div
        className="bg-white p-5 filter-and-table-container"
        id="graph-admin-agence"
      >
        <LineChart data={data?.admin_graphe} />
      </div> */}
    </>
  );
}

export default AgenceAdmin;
