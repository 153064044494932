/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError, isComptable, onHide } from "../../../../../utils/Utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { DepenseFormData } from "../../../../../utils/api/depense/depense.type";
import {
  useAddOrUpdateDepenseMutation,
  useDeleteDepenseMutation,
} from "../../../../../utils/api/depense/depense.api";
import {
  useGetProprieteComptaByAgenceBySlugQuery,
} from "../../../../../utils/api/agence/agence.api";
import { useGetProprietesByProprietaireQuery } from "../../../../../utils/api/propriete/propriete.api";
import {
  useLazyGetLocataireByProprieteSlugQuery,
} from "../../../../../utils/api/locataire/locataire.api";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";

function useAddOrUpdateDepense(modalId: string, depense?: DepenseFormData) {
  const validationSchema = yup.object().shape({
    montant: yup.number().required().label("Montant"),
    propriete: yup.string().required().label("Villa"),
    categorie: yup.string().label("Catégorie"),
    locataire: yup.string().label("Client"),
    justificatif: yup.mixed().label("Justificatif"),
    charge: yup.string().label("Charge"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
    control,
  } = useForm<DepenseFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [message, setMessage] = useState<string>("");
  const [options, setOptions] = useState<any>([]);
  const [option, setOption] = useState<any>();
  const [locataireItem, setLocataireItem] = useState<any>();

  const [addOrUpdateDepense, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateDepenseMutation();

  const [getLocataires, { data: locataire = { results: [] } }] =
    useLazyGetLocataireByProprieteSlugQuery();
  const { user } = useAppSelector((s) => s?.user);
  const userID = user?.id;

  const isComptableAgence = isComptable(user) && user?.agence_id;

  const [locataires, setLocataires] = useState<
    { label: string; value: number }[] | undefined
  >([]);

  const comptabble = useAppSelector((s) => s.user.user);

  const { data: proprieteAgence } = useGetProprieteComptaByAgenceBySlugQuery({
    slug: comptabble?.agence_id?.slug,
  });
  const { data: proprieteProprio } = useGetProprietesByProprietaireQuery({
    slug: comptabble?.proprietaire_id?.slug,
  });

  useEffect(() => {
    if (locataire?.results?.length) {
      // console.log("locataire?.results", locataire?.results);
      setLocataires(
        locataire?.results
          ?.filter((item: ILocataire) => item?.propriete?.id === option?.id)
          ?.map((el: ILocataire) => {
            return {
              label: el?.prenom + " " + el?.nom,
              value: el?.id,
              ...el,
            };
          })
      );

      let locataireEl: ILocataire = locataire?.results?.find(
        (item: any) => item?.propriete?.id === option?.id
      );
      if (locataireEl) {
        setValue("locataire", locataireEl?.id);
        setLocataireItem({
          label: locataireEl?.prenom + " " + locataireEl?.nom,
          value: locataireEl?.id,
          ...locataireEl,
        });
      } else {
        setValue("locataire", "");
        setLocataireItem("");
      }
    } else {
      setValue("locataire", "");
      setLocataires([]);
      setLocataireItem("");
    }
  }, [option, locataire?.results?.length]);

  useEffect(() => {
    if (user?.agence_id) {
      const propriete = proprieteAgence?.results?.filter(
        (item) => item?.type_propriete !== "immeuble"
      );
      setOptions(
        propriete?.map((item) => {
          return {
            label: item?.nom,
            value: item?.id,
            ...item,
          };
        })
      );
      //   setFilterProperties(propriete);
    } else {
      const propriete = proprieteProprio?.results?.filter(
        (item) => item?.type_propriete !== "immeuble"
      );
      setOptions(
        propriete?.map((item) => {
          return {
            label: item?.nom,
            value: item?.id,
            ...item,
          };
        })
      );
    }
  }, [proprieteAgence?.results]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: depense
          ? "Dépense modifiée avec succès !"
          : "Dépense ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!depense) {
          reset();
          setOption("");
          setLocataireItem("");
          setMessage("");
        }
        onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const handleChangePropriete = (e: any) => {
    setOption(e);
    setValue("propriete", e?.value);
    if (e?.value) {
      getLocataires({ slug: e?.slug });
    }
  };

  const handleChangeLocataire = (e: any) => {
    setValue("locataire", e?.value);
  };
  const handleJustificatif = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif" &&
      file?.type !== "application/pdf"
    ) {
      return setError("justificatif", {
        message: "Image format is incorrect.",
      });
    }
    if (file) {
      register("justificatif");
      setValue("justificatif", file);
    }
  }, []);
  const onSubmit = (data: any) => {
    if (!depense) {
      if (user?.user_type === "agence") {
        data["agence"] = user?.id;
        data["charge"] = "agence";
      }

      if (user?.user_type === "proprietaire") {
        data["proprietaire"] = user?.id;
        data["charge"] = "agence";
      }

      if (isComptableAgence) {
        data["agence"] = user?.agence_id?.id;
        data["charge"] = "proprietaire";
      }

      if (user?.proprietaire_id) {
        data["proprietaire"] = user?.proprietaire_id?.id;
        data["charge"] = "proprietaire";
      }

      data["comptable"] = userID;
    }
      
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    // console.log("data submitted", data);
    addOrUpdateDepense({ slug: depense?.slug, data: fd });
  };

  const handleReset = () => {
    if (!depense) {
      reset();
      setOption("");
      setLocataireItem("");
      setMessage("");
    }
  };

  useEffect(() => {
    if (depense?.id) {
      const fields: (keyof DepenseFormData)[] = [
        "montant",
        "categorie",
        "propriete",
        "locataire",
        "specification",
        "numero",
        "comptable",
        "charge",
      ];
      // console.log("fields");
      for (let field of fields) {
        register(field);
        if (
          field === "categorie" ||
          field === "locataire" ||
          field === "propriete" ||
          field === "justificatif" ||
          field === "charge"
        ) {
          setValue(field, depense[field]?.id);
        } else {
          setValue(field, depense[field]);
        }
        if (field === "locataire") {
          setValue("locataire", depense?.locataire?.id);
          const locataires = isComptableAgence
            ? proprieteAgence?.results
                ?.find(
                  (item: any) => item?.id === parseInt(depense?.propriete?.id)
                )
                ?.locataires?.map((locataire) => {
                  return {
                    label: locataire?.prenom + " " + locataire?.nom,
                    value: locataire?.id,
                  };
                })
            : proprieteProprio?.results
                ?.find((item) => item?.id === parseInt(depense?.propriete?.id))
                ?.locataires?.map((locataire) => {
                  return {
                    label: locataire?.prenom + " " + locataire?.nom,
                    value: locataire?.id,
                  };
                });
          setLocataires(locataires);
        }
      }
      if (depense?.propriete?.id) {
        getLocataires({ slug: depense?.propriete?.slug });
        setOption({
          label: depense?.propriete?.nom,
          value: depense?.propriete?.id,
          ...depense?.propriete,
        });
      }
    }
  }, [depense]);

  const handleChangeMessage = useCallback((value: string) => {
    setMessage(value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    message,
    handleChangeMessage,
    handleReset,
    handleJustificatif,
    handleChangePropriete,
    locataires,
    proprietes: isComptableAgence
      ? proprieteAgence?.results
      : proprieteProprio?.results,
    Controller,
    control,
    option,
    options,
    locataireItem,
    handleChangeLocataire,
  };
}

export default useAddOrUpdateDepense;

export function UseDeleteDepense(
  slug: string
  // modalID: string
) {
  const [deleteData] = useDeleteDepenseMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette dépense ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        // console.log(modalID)
        Swal.fire({
          icon: "success",
          title: `Dépense supprimée avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
        // .then(() => {
        // onHide("EnvoieSuppressionNoteModal");
        // });
      }
    });
  };
  return onDelete;
}
