import React, { useEffect, useState } from 'react'
import { Pagination } from 'reactstrap';
import ListeFactureAdminDatas from './ListeFactureAdminDatas';
import ListeFactureAdminSkeleton from './ListeFactureAdminSkeleton';
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

function ListeFactureAdminTable() {
    const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);

    // const actionFormatter: any = (cell: any, row: any) => {
	// 	return (
	// 		<div className="table-actions-btn-container-commandes gap-1 d-flex">
	// 			<div>
    //                 <div className='d-flex gap-2'>
    //                     <button className="btn btn-action-administratif-admin-down"><BsCloudArrowDownFill /></button>
    //                     <button className="btn btn-action-administratif-admin-print"><IoPrint /></button>
    //                 </div>
    //             </div>
	// 		</div>
	// 	);
	// };
    const statutFormatter: any = (cell: any, row: any) => {
		return (
			<div className="table-actions-btn-container-commandes">
				{cell === "Payé" && (
                    <div className='custom-statut-facture-paye'>
                        {cell}
                    </div>
                )}
				{cell === "Non payé" && (
                    <div className='custom-statut-facture-non-paye'>
                        {cell}
                    </div>
                )}
			</div>
		);
	};

   

	const columns = [
		{
			dataField: "numero",
			text: "N°",
			style: { textAlign: "left" },
            headerStyle: () => {
				return { width: "110px", whiteSpace: "normal" };
			},
		},
        {
			dataField: "montant",
			text: "Montant de la facture",
            // formatter: (cell: any, row: any) =>
            // documentFormatter(cell, row),
            headerStyle: () => {
				return { width: "320px", whiteSpace: "normal" };
			},
            // style: { textAlign: "center" },
		},
		{
			dataField: "moyen_paiement",
			text: "Moyen de paiement",
            headerStyle: () => {
				return { width: "300px", whiteSpace: "normal" };
			},
			// style: { textAlign: "center" },
		},
		{
			dataField: "date",
			text: "Date",
            headerStyle: () => {
				return { width: "300px", whiteSpace: "normal" };
			},
			// style: { textAlign: "left" },
		},
		
		{
			dataField: "statut",
			text: "Statut",
            headerStyle: () => {
				return { width: "200px", whiteSpace: "normal" };
			},
			style: { color: "#1AA981", textAlign: 'left' },
            formatter: (cell: any, row: any) =>
            statutFormatter(cell, row),
		},
	];

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 0);
	}, []);

    return (
        <>
			{isLoading && <ListeFactureAdminSkeleton />}
			{!isLoading && (
				<>
                    <BootstrapTable
                        keyField="id"
                        // data={ListeFactureAdminDatas}
						data={ListeFactureAdminDatas.slice().sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())}
                        columns={columns}
                        bordered={true}
                        condensed={false}
                        responsive
                        wrapperClasses="table-responsive locataire-table"
                    />
                    <CustomPagination
                        nbPages={8}
                        page={page}
                        onChange={(page, perPage) => {
                            setPerPage(perPage);
                            setPage(page);
                        }}
                        perPage={perPage}
                    />
                </>
			)}
		</>
    )
}

export default ListeFactureAdminTable

type CustomPaginationProps = {
	nbPages: number | any;
	page: number;
	onChange: (page: number, perPage: number) => any;
	perPage: number;
};
export function CustomPagination({ nbPages, page, perPage, onChange }: CustomPaginationProps) {
	if (parseInt(nbPages) <= 0) return null;
	return (
		<div className="d-flex align-items-baseline justify-content-between">
			<div className="footer-form d-flex mt-3">
				<select
					className="form-control select-perpage"
					name="limit"
					id="limit"
					value={perPage}
					onChange={(e) => {
						onChange(1, parseInt(e.target.value, 10));
					}}
				>
					<option value="10">10</option>
					<option value="30">30</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>
			</div>

			{parseInt(nbPages) > 0 ? (
				<Pagination
					page={page}
					total={nbPages}
					perPage={perPage}
					onPageChange={(page: number) => onChange(page, perPage)}
				/>
			) : null}
		</div>
	);
}
