/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent } from "react";
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form";
import { IAgence, IUserAgence } from "../agence/agence.type";
import { ITypeCommodites } from "../commodites/commodites.type";
import { IImmeubles } from "../immeubles/immeubles.type";
import { ILocataire } from "../locataire/locataire.type";
import { IProprietaire } from "../proprietaire/proprietaire.type";

export interface ProprieteStepProps {
  register: UseFormRegister<ProprieteFormData>;
  unregister: UseFormUnregister<ProprieteFormData>;
  errors: any;
  description: string;
  pictures: File[];
  mandatPicture: File;
  isLoading: boolean;
  setValue: UseFormSetValue<ProprieteFormData>;
  onSubmit: () => void;
  handleChangeDescription: (value: string) => void;
  handleChangeMandatPicture: (e:ChangeEvent<HTMLInputElement>) => void;
  setPictures: React.Dispatch<React.SetStateAction<FileList>>;
  propriete: IPropriete;
  address: string;
  onChangeAddress: () => void;
  handleCountrySelect: () => void;
  handleStateSelect: () => void;
  handleCitySelect: () => void;
  country: any;
  state: any;
  city: any;
  proprieteItem: IPropriete;
  handleChangeNumber: any;
  nbChambre: number;
  nbCuisine: number;
  handleChangeNumberCuisine: any;
  nbSDB: number;
  handleChangeNumberSDB: any;
  nbSalon: number;
  handleChangeNumberSalon: any;
  nbClim: number;
  handleChangeNumberClim: any;
  commodites: any[];
  control: any;
  Controller: any;
  commoditesList: ITypeCommodites[];
  onChangeCommodite: () => void;
  itemState: IPropriete;
  agences: any;
  handleChangeAgence: any;
  option: any;
  navigation: any;
  handleChangeProprio: (e: ChangeEvent<HTMLSelectElement>) => void;
  proprio: any;
}

export type TImage = {
  images: {
    created_at: string;
    deleted: null;
    id: number;
    image: string;
    slug: string;
  }[];
};

export enum TypePropriete {
  villa = "villa",
  immeuble = "immeuble",
  appartement = "appartement",
}

export enum TypeGerance {
  partiel = "partiel",
  total = "total",
}

export interface IPropriete {
  [key: string]: any;
  id: number;
  slug: string;
  numero: string;
  nom: string;
  adresse: string;
  rue: string;
  pays: string;
  ville: string;
  code_postale: string;
  type_propriete: TypePropriete;
  // nb_chambre: number;
  // nb_cuisine: number;
  // nb_salle_de_bain: number;
  prix: number;
  proprietaire: IProprietaire | any;
  description: string;
  pictures: File | string | any;
  mandat: File | string;
  gerance: TypeGerance;
  disponibilite: boolean;
  agence: IAgence | any;
  immeuble: IImmeubles | any;
  commission_agence: number;
  locataires: ILocataire[];
  surface: string;
  // surface_chambre: string;
  // surface_cuisine: string;
  // surface_salle_de_bain: string;
  // surface_salon: string;
  climatiseurs: Climatiseur[];
  chambres: any;
  cuisines: any;
  salle_de_bains: any;
  salons: any;
  commodites: ITypeCommodites[];
  charges: { libelle: string; type: string; montant: number }[];
  agence_owner: boolean;
  nbre_chambres: number;
  nbre_cuisines: number;
  nbre_salle_de_bains: number;
  nbre_salon: number;
  nbre_climatiseurs: number;
  nbre_commodites: number;
  prix_base: number;
}

export interface IAbonnement {
  date_fin: string,
  date_fin_formatted: string,
}

export interface Chambre {
  id: number;
  slug: string;
  surface: string;
  image: string;
  description: string;
}

export interface Cuisine {
  id: number;
  slug: string;
  surface: string;
  image: string;
  description: string;
}

export interface SalleDeBain {
  id: number;
  slug: string;
  surface: string;
  image: string;
  description: string;
}

export interface Salon {
  id: number;
  slug: string;
  surface: string;
  image: string;
  description: string;
}

export interface Climatiseur {
  id: number;
  slug: string;
  surface: string;
}

export interface Commodite {
  id: number;
  slug: string;
  is_active: boolean;
}

export type ProprieteFormData = Partial<IPropriete> & { step: number };
