import React, { useState } from "react";
import word from "../../../../../assets/appImages/word.png";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ContratAdminSkeleton from "./ContratAdminSkeleton";
import { BsCloudArrowDownFill } from "react-icons/bs";
import { IoPrint } from "react-icons/io5";
import { useGetContratAdminQuery } from "../../../../../utils/api/contrat/contrat.api";
import { CustomPagination } from "../../../../common/CustomPagination";
import { AlertInfo } from "../../../../common/Alert";
import { IContrat } from "../../../../../utils/api/contrat/contrat.type";
import { formattedDate } from "../../../../../utils/Utils";
import ViewDocModal from "../../../../modal/admin/ViewDocModal";
import { ApiBaseUrl } from "../../../../../utils/http";
import { Link } from "react-router-dom";


function ContratAdmin({ nom }: { nom?: any }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading } = useGetContratAdminQuery({
    nom,
    page: page,
    limit: perPage,
  });
// console.log("contrat agence admin", data);

  const [item, setItem] = useState<IContrat>();

  const actionFormatter: any = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes gap-1 d-flex">
        <div>
          <div className="d-flex gap-2">
            {row?.document ?
              <Link to={ApiBaseUrl + (row?.document)?.slice(1)} target="_blank" className="btn btn-action-administratif-admin-down" download>
                <BsCloudArrowDownFill />
              </Link>
              :
              ""
            }
            
            <button
              className="btn btn-action-administratif-admin-print ps-3"
              disabled={row?.document ? false : true}
              data-bs-toggle="modal"
              data-bs-target="#ViewDocModal"
              onClick={() => setItem(row)}
            >
              <IoPrint />
            </button>
          </div>
        </div>
      </div>
    );
  };
  const documentFormatter: any = (
    cell:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined,
    row: any
  ) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <div>
          <div>
            {
              <img
                src={word}
                alt="img type document"
                className="kanimmo-img-type-document"
              />
            }{" "}
            {cell}
          </div>
        </div>
      </div>
    );
  };
  const voirContratFormatter: any = (
    cell:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined,
    row: any
  ) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <div>
          <div
            data-bs-toggle="modal"
            data-bs-target="#ViewDocModal"
            onClick={() => setItem(row)}
          >
            Voir le contrat
          </div>
        </div>
      </div>
    );
  };
  const typeClientFormatter: any = (
    cell:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined,
    row: IContrat
  ) => {
    return <div className="">{row?.agence ? "Agence" : "Proprietaire"}</div>;
  };

  const columns = [
    {
      dataField: "numero",
      text: "N°",
      style: { textAlign: "left" },
      headerStyle: () => {
        return { width: "50px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "nom_document",
      text: "Nom du document",
      formatter: (cell: any, row: any) => documentFormatter(cell, row),
    },
    {
      dataField: "type_client",
      text: "Type de client",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) => typeClientFormatter(cell, row),
    },
    // {
    // 	dataField: "propriete",
    // 	text: "Propriétés",
    // 	style: { textAlign: "left" },
    // },
    {
      dataField: "created_at",
      text: "Date de validation",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) => formattedDate(cell),
    },

    {
      dataField: "decouvrir",
      text: "Découvrir",
      style: { color: "#1AA981", textAlign: "left", cursor: "pointer" },
      formatter: (cell: any, row: any) => voirContratFormatter(cell, row),
    },
    {
      dataField: "actions",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <ContratAdminSkeleton />}
      {!isLoading && data?.results && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <ViewDocModal item={item} />
          <CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={perPage}
					/>
        </>
      )}
    </>
  );
}

export default ContratAdmin;
