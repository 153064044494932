import React from "react";
import { IPropriete } from "../../../utils/api/propriete/propriete.type";
import { ApiBaseUrl } from "../../../utils/http";
import { get_url_extension } from "../../../utils/Utils";

function ContratFileModal({ item }: { item?: IPropriete }) {
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            {get_url_extension(item?.mandat) === "pdf" ? (
              <iframe
                src={ApiBaseUrl + item?.mandat}
                frameBorder="0"
                width={`100%`}
                height={800}
                allowFullScreen
                seamless
                title="doc"
              ></iframe>
            ) : (
              <iframe
                src={`https://docs.google.com/gview?url=${
                  ApiBaseUrl + item?.mandat
                }&embedded=true`}
                frameBorder="0"
                width={`100%`}
                height={800}
                title="doc"
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContratFileModal;
