import moment from "moment";
import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { BiRadioCircle } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import avatarHistorique from "../../../../../assets/icons/avatarHistorique.png";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  ILitiges,
  TypesLitiges,
} from "../../../../../utils/api/litiges/litiges.type";
import { useLocataireInfoDetailsFromLocation } from "../../../../../utils/api/locataire/locataire.api";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import {
  formatMontant,
  formattedDate,
  isAgenceJuriste,
  isProprioJuriste,
  useLocationState,
} from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import RappelPaiementModal from "../../../../modal/agence/RappelPaiementModal";
import DetailsReceiptModal from "../../../../modal/admin/DetailsReceiptModal";
import ContratBailLocataire from "./ContratBailLocataire";
import { Color } from "../../../../../utils/theme";
import Swal from "sweetalert2";
import { useRappelPaiementAgenceMutation } from "../../../../../utils/api/rappelPaiement/rappelPaiement.api";
import { ArchiveLocataireBtn } from "../../../../tables/agence/LocataireTable/LocataireTable";
import { ArchiveLocataireBtn2 } from "../../../../tables/agence/LocataireTable/LocataireArchiveTable";

function Bail() {
  const [itemState, findBySlug] = useLocataireInfoDetailsFromLocation();

  console.log("itemState", itemState);
  const { user } = useAppSelector((s) => s?.user);
  const user_connect = user?.user_type   === "gerant" ? user?.proprietaire_id?.id : user?.id

  const Status = [
    {
      label: "Assignation d’Expulsion",
      value: TypesLitiges.assignationExpulsion,
    },
    {
      label: "Commandement de payer",
      value: TypesLitiges.commandementPayer,
    },
    {
      label: "Jugement",
      value: TypesLitiges.jugement,
    },
    {
      value: "non_regler",
      label: "Non réglée",
    },
    {
      value: "encours",
      label: "En cours de traitement",
    },
    {
      value: "regler",
      label: "Réglée",
    },
    {
      value: "revision",
      label: `${
        isProprioJuriste(user) || isAgenceJuriste(user)
          ? "Pour révision"
          : "Envoyé pour révision par le juriste"
      }`,
    },
  ];
  // let amount = 0;
  // if (
  //   itemState?.sequence_paiement === "Mensuel" ||
  //   itemState?.sequence_paiement === "mensuel"
  // ) {
  //   amount = itemState.prochain_montant_paiement * 1;
  // } else if (
  //   itemState?.sequence_paiement === "Trimestriel" ||
  //   itemState?.sequence_paiement === "trimestriel"
  // ) {
  //   amount = itemState.prochain_montant_paiement * 3;
  // } else if (
  //   itemState?.sequence_paiement === "Semestriel" ||
  //   itemState?.sequence_paiement === "semestriel"
  // ) {
  //   amount = itemState.prochain_montant_paiement * 6;
  // } else if (
  //   itemState?.sequence_paiement === "Annuel" ||
  //   itemState?.sequence_paiement === "annuel"
  // ) {
  //   amount = itemState.prochain_montant_paiement * 12;
  // }

  return (
    <>
      <div className="row">
        {!itemState.is_archive && (
          <>
            <div className="col-md-4 ">
              <div className="card-date-historique-locataire rounded-3 p-3 pb-1  mb-3">
                <div className="date-historique-locataire-container d-flex justify-content-between">
                  <div className="date-historique-locataire-titre">
                    Début du bail:
                  </div>
                  <div className="date-historique-locataire-date">
                    {formattedDate(itemState?.date_debut)}
                  </div>
                </div>
                <div className="date-historique-locataire-container d-flex justify-content-between my-3">
                  <div className="date-historique-locataire-titre">
                    Durée du bail:
                  </div>
                  <div className="date-historique-locataire-date">
                    {itemState?.bail &&
                      itemState?.bail?.duree_par_an !== 0 &&
                      itemState?.bail?.duree_par_an !== undefined &&
                      itemState?.bail?.duree_par_an + " an "}
                    {itemState?.bail &&
                      itemState?.bail?.duree_par_mois !== 0 &&
                      itemState?.bail?.duree_par_mois !== undefined &&
                      itemState?.bail?.duree_par_mois + " mois "}
                    {itemState?.bail &&
                      itemState?.bail?.duree_par_jour !== 0 &&
                      itemState?.bail?.duree_par_jour !== undefined &&
                      itemState?.bail?.duree_par_jour + " jours"}
                  </div>
                </div>
                <div className="date-historique-locataire-container d-flex justify-content-between">
                  <div className="date-historique-locataire-titre">
                    Fin du bail:
                  </div>
                  <div className="date-historique-locataire-date">
                    {formattedDate(itemState?.date_fin)}
                  </div>
                </div>
                <div className="date-historique-locataire-container d-flex justify-content-between my-3 mb-1">
                  <div className="date-historique-locataire-titre">
                    Fréquence de paiement:
                  </div>
                  <div className="date-historique-locataire-date">
                    {itemState?.sequence_paiement}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-proch-paie-historique-locataire d-flex flex-column align-items-center p-3 mb-3">
                <div className="">Prix de location</div>
                <div className="date-historique-locataire-date">-</div>
                <div className="date-historique-locataire-date mb-2">
                  {itemState?.propriete?.prix &&
                    formatMontant(itemState?.propriete?.prix)}
                </div>

                {itemState.contrat ? (
                  <div className="d-flex justify-content-center mt-3">
                    <button
                      className="btn btn-envoie-rappel-historique-locataire"
                      title="Contrat de bail"
                      data-bs-toggle="modal"
                      data-bs-target={`#contratBail${itemState?.slug}`}
                      // style={{ fontSize: "15px", padding: "8px 10%" }}
                    >
                      Contrat de bail
                    </button>
                    <div
                      className="modal fade "
                      id={`contratBail${itemState?.slug}`}
                      aria-labelledby={`contratBail${itemState?.slug}Label`}
                      aria-hidden="true"
                      data-bs-backdrop="static"
                    >
                      <ContratBailLocataire item={itemState} />
                    </div>
                  </div>
                ) : (
                  <p
                    className="pt-4 mb-0 fw-semibold text-center date-historique-locataire-date"
                    style={{ fontSize: "13px" }}
                  >
                    Ce propriétaire n'a pas de contrat de bail
                  </p>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="card-proch-paie-historique-locataire  p-3 pt-1 mb-4">
                <div className="date-historique-locataire-container d-flex justify-content-between my-3 mb-1">
                  <div className="date-historique-locataire-titre">
                    PROCHAIN PAIEMENT :
                  </div>
                  <div className="date-historique-locataire-date">
                    {formattedDate(itemState?.prochain_date_paiement) ||
                      "Non défini"}
                  </div>
                </div>
                <div className="date-historique-locataire-container d-flex justify-content-between my-3 mb-1">
                  <div className="date-historique-locataire-titre">
                    Montant :
                  </div>
                  <div className="date-historique-locataire-date">
                    {itemState?.prochain_montant_paiement &&
                      formatMontant(itemState?.prochain_montant_paiement)}
                  </div>
                </div>
                {/* <div className="card-proch-paie-historique-locataire-titre mb-3">
          PROCHAIN PAIEMENT
        </div> */}
                {/* <div className="d-flex justify-content-end mb-0">
          <div className="card-proch-paie-historique-locataire-info-date">
            {formattedDate(itemState?.prochain_date_paiement) || "Non défini"}
          </div>
        </div> 
        <div className="card-proch-paie-historique-montant-container mb-3">
          <div className="card-proch-paie-historique-montant-titre">
            Montant
          </div>
          <div className="card-proch-paie-historique-montant">
            {itemState?.prochain_montant_paiement &&
              formatMontant(itemState?.prochain_montant_paiement)}
          </div>
        </div>
        */}
                {!isProprioJuriste(user) && (
                  <div className="d-flex justify-content-between pt-4 mb-0">
                    {itemState?.propriete?.agence === user_connect ? (
                      <ArchiveLocataireBtn
                        slug={itemState?.slug}
                        row={itemState}
                      />
                    ) : (
                      // <ArchiveLocataireBtn2 slug={itemState?.slug} row={itemState} />
                      ''
                    )}
                    {itemState?.propriete?.proprietaire === user_connect ? (
                      <ArchiveLocataireBtn
                        slug={itemState?.slug}
                        row={itemState}
                      />
                    ) : (
                      ""
                    )}

                    {/* <button
              className="btn btn-envoie-rappel-historique-locataire"
              title="Envoyer un rappel"
              data-bs-toggle="modal"
              data-bs-target="#RappelPaiementModal"
            >
              Envoyer un rappel de paiement mais
            </button> */}

                    <RappelPaiementBtn slug={itemState?.slug} />
                    <div
                      className="modal fade"
                      id="RappelPaiementModal"
                      aria-labelledby="RappelPaiementModalLabel"
                      aria-hidden="true"
                    >
                      <RappelPaiementModal
                        modalId="RappelPaiementModal"
                        locataireData={itemState}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {itemState?.litiges?.length > 0 ? (
          itemState?.litiges?.map((item: ILitiges) => (
            <div className="card-asignation-historique-locataire p-3 mb-3">
              <div className="card-asignation-historique-locataire-icon">
                <BiRadioCircle
                  className={
                    item?.statut === "assignation_expulsion"
                      ? "card-asignation-icon"
                      : item?.statut === "commandement_payer"
                      ? "card-commandement-icon"
                      : "card-jugement-icon"
                  }
                />
              </div>
              <div className="card-asignation-historique-locataire-title mb-3">
                {Status?.find((val) => val?.value === item?.statut)?.label}
              </div>
              <div className="card-asignation-historique-locataire-date">
                Envoyé le {moment(item?.date).format("DD/MM/YYYY")}
              </div>
            </div>
          ))
        ) : (
          <AlertInfo message="Pas de contentieux en cours" />
        )}
      </div>

      {/* ============= NE PAS SUPPRIMER ============= */}

      {/* <div className='card-infolocataire-historique-locataire p-3 mb-3'>
                <div className="card-infolocataire-historique-locataire-title mb-3">Informations des locataires</div>
                <div className='card-infolocataire-user-container mb-3'>
                    <div className="card-infolocataire-user-info-container mb-3">
                        <img src={avatarHistorique} alt='user avatar' className='card-infolocataire-user-info-avatar' />
                        <div className="card-infolocataire-user-info-username">Fama Kebe</div>
                        <div className="card-infolocataire-user-info-localisation">-  Sénégal, Dakar Plateau</div>
                    </div>
                    <div className="card-infolocataire-user-info-btn-container mb-3">
                        <div className='card-infolocataire-user-info-btn-email'>
                            <AiOutlineMail />
                            <span>kebefa@gmail.com</span>
                        </div>
                        <div className='card-infolocataire-user-info-btn-email'>
                            <BsTelephone />
                            <span>+221 77 700 00 00</span>
                        </div>
                    </div>
                </div>
                <div className='card-infolocataire-user-container mb-3'>
                    <div className="card-infolocataire-user-info-container mb-3">
                        <img src={avatarHistorique} alt='user avatar' className='card-infolocataire-user-info-avatar' />
                        <div className="card-infolocataire-user-info-username">Fama Kebe</div>
                        <div className="card-infolocataire-user-info-localisation">-  Sénégal, Dakar Plateau</div>
                    </div>
                    <div className="card-infolocataire-user-info-btn-container mb-3">
                        <div className='card-infolocataire-user-info-btn-email'>
                            <AiOutlineMail />
                            <span>kebefa@gmail.com</span>
                        </div>
                        <div className='card-infolocataire-user-info-btn-email'>
                            <BsTelephone />
                            <span>+221 77 700 00 00</span>
                        </div>
                    </div>
                </div>
                <div className='card-infolocataire-user-container mb-3'>
                    <div className="card-infolocataire-user-info-container mb-3">
                        <img src={avatarHistorique} alt='user avatar' className='card-infolocataire-user-info-avatar' />
                        <div className="card-infolocataire-user-info-username">Fama Kebe</div>
                        <div className="card-infolocataire-user-info-localisation">-  Sénégal, Dakar Plateau</div>
                    </div>
                    <div className="card-infolocataire-user-info-btn-container mb-3">
                        <div className='card-infolocataire-user-info-btn-email'>
                            <AiOutlineMail />
                            <span>kebefa@gmail.com</span>
                        </div>
                        <div className='card-infolocataire-user-info-btn-email'>
                            <BsTelephone />
                            <span>+221 77 700 00 00</span>
                        </div>
                    </div>
                </div>

            </div> */}
      {/* </div> */}
    </>
  );
}

export default Bail;

function RappelPaiementBtn({ slug }: { slug: any }) {
  const [rappelPaiementAgence] = useRappelPaiementAgenceMutation();

  const onSendRappel = async () => {
    const result = await Swal.fire({
      title: `Êtes-vous sûr de vouloir envoyer un rappel de paiement?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.jaune,
      confirmButtonColor: Color.jaune,
      preConfirm: () => {
        return rappelPaiementAgence(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    if (result?.value) {
      Swal.fire({
        icon: "success",
        title: "Rappel envoyé avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "une erreur s'est produite",
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <button
      className="btn btn-envoie-rappel-historique-locataire"
      title="Envoyer un rappel"
      onClick={onSendRappel}
    >
      Envoyer un rappel de paiement
    </button>
  );
}
