import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EntreeFormData } from "../../../../../utils/api/entree/entree.type";
import { Color } from "../../../../../utils/theme";
import {
  useAddOrUpdateEntreeMutation,
  useDeleteEntreeMutation,
} from "../../../../../utils/api/entree/entree.api";
import { cleannerError, isComptable, onHide } from "../../../../../utils/Utils";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  useGetProprieteComptaByAgenceBySlugQuery,
} from "../../../../../utils/api/agence/agence.api";
import { useGetProprietesByProprietaireQuery } from "../../../../../utils/api/propriete/propriete.api";
import { useLazyGetLocataireByProprieteSlugQuery } from "../../../../../utils/api/locataire/locataire.api";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";

function useAddOrUpdateEntree(modalId: string, entree?: EntreeFormData) {
  const validationSchema = yup.object().shape({
    montant: yup.number().required().label("Montant"),
    propriete: yup.string().label("Villa"),
    categorie_entree: yup.string().label("Catégorie"),
    locataire: yup.string().label("Client"),
    justificatif: yup.mixed().label("Justificatif"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
    control,
  } = useForm<EntreeFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { user } = useAppSelector((s) => s?.user);
  const userID = user?.id;
  const [options, setOptions] = useState<any>([]);
  const [option, setOption] = useState<any>();
  const isComptableAgence = isComptable(user) && user?.agence_id;

  const [locataires, setLocataires] = useState<
    { label: string; value: number }[] | undefined
  >([]);

  const comptabble = useAppSelector((s) => s.user.user);
  // console.log("comptabble comptabble", comptabble)
  const [getLocataires, { data: locataire = { results: [] } }] =
    useLazyGetLocataireByProprieteSlugQuery();
  const { data: proprieteAgence } = useGetProprieteComptaByAgenceBySlugQuery({
    slug: comptabble?.agence_id?.slug,
  });
  const { data: proprieteProprio } = useGetProprietesByProprietaireQuery({
    slug: comptabble?.proprietaire_id?.slug,
  });

  const [message, setMessage] = useState<string>("");
  const [locataireItem, setLocataireItem] = useState<any>();
  const [addOrUpdateEntree, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateEntreeMutation();

  useEffect(() => {
    if (locataire?.results?.length) {
      // console.log("locataire?.results", locataire?.results);
      setLocataires(
        locataire?.results
          ?.filter((item: ILocataire) => item?.propriete?.id === option?.id)
          ?.map((el: ILocataire) => {
            return {
              label: el?.prenom + " " + el?.nom,
              value: el?.id,
              ...el,
            };
          })
      );

      let locataireEl: ILocataire = locataire?.results?.find(
        (item: any) => item?.propriete?.id === option?.id
      );
      if (locataireEl) {
        setValue("locataire", locataireEl?.id);
        setLocataireItem({
          label: locataireEl?.prenom + " " + locataireEl?.nom,
          value: locataireEl?.id,
          ...locataireEl,
        });
      } else {
        setValue("locataire", "");
        setLocataireItem("");
      }
    } else {
      setValue("locataire", "");
      setLocataires([]);
      setLocataireItem("");
    }
  }, [option, locataire?.results?.length]);

  useEffect(() => {
    if (user?.agence_id) {
      const propriete = proprieteAgence?.results?.filter(
        (item) => item?.type_propriete !== "immeuble"
      );
      setOptions(
        propriete?.map((item) => {
          return {
            label: item?.nom,
            value: item?.id,
            ...item,
          };
        })
      );
      //   setFilterProperties(propriete);
    } else {
      const propriete = proprieteProprio?.results?.filter(
        (item) => item?.type_propriete !== "immeuble"
      );
      setOptions(
        propriete?.map((item) => {
          return {
            label: item?.nom,
            value: item?.id,
            ...item,
          };
        })
      );
    }
  }, [proprieteAgence?.results]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: entree
          ? "Entrée modifiée avec succès !"
          : "Entrée ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!entree) {
          reset();
          setMessage("");
          setOption("");
          setLocataireItem("");
        }
        onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const handleChangePropriete = (e: any) => {
    setOption(e);
    setValue("propriete", e?.value);
    if (e?.value) {
      getLocataires({ slug: e?.slug });
    }
  };

  const handleChangeLocataire = (e: any) => {
    setValue("locataire", e?.value);
  };

  // console.log("LocatAgence", locatAgence);
  const handleJustificatif = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif" &&
      file?.type !== "application/pdf"
    ) {
      return setError("justificatif", {
        message: "Image format is incorrect.",
      });
    }
    if (file) {
      register("justificatif");
      setValue("justificatif", file);
    }
  }, []);
  const onSubmit = (data: any) => {
    if (!entree) {
      // isComptableAgence
      //   ? (data["agence"] = user?.agence_id?.id)
      //   : (data["proprietaire"] = user?.proprietaire_id?.id);
      // data["comptable"] = userID;
      if (user?.user_type === "agence") {
        data["agence"] = user?.id;
      }

      if (user?.user_type === "proprietaire") {
        data["proprietaire"] = user?.id;
      }

      if (isComptableAgence) {
        data["agence"] = user?.agence_id?.id;
      }

      if (user?.proprietaire_id) {
        data["proprietaire"] = user?.proprietaire_id?.id;
      }

      data["comptable"] = userID;
    }

    const fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    console.log("data submitted", data);
    addOrUpdateEntree({ slug: entree?.slug, data: fd });
  };

  const handleReset = () => {
    if (!entree) {
      reset();
      setOption("");
      setLocataireItem("");
      setMessage("");
    }
  };

  useEffect(() => {
    if (entree?.id) {
      const fields: (keyof EntreeFormData)[] = [
        "montant",
        "categorie_entree",
        "propriete",
        "locataire",
      ];
      // console.log("fields", entree);
      for (let field of fields) {
        register(field);
        if (
          field === "categorie_entree" ||
          field === "propriete" ||
          field === "justificatif"
        ) {
          setValue(field, entree[field]?.id);
        } else if (field === "locataire") {
          if (entree?.locataire?.id) {
            setValue("locataire", entree?.locataire?.id);
          } else if (entree?.occupant?.id) {
            setValue("locataire", entree?.occupant?.id);
          }
        } else {
          setValue(field, entree[field]);
        }
        
      }
      if (entree?.propriete?.id) {
        getLocataires({ slug: entree?.propriete?.slug });
        setOption({
          label: entree?.propriete?.nom,
          value: entree?.propriete?.id,
          ...entree?.propriete,
        });
      }
    }
  }, [entree]);

  const handleChangeMessage = useCallback((value: string) => {
    setMessage(value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    message,
    handleChangeMessage,
    handleReset,
    handleJustificatif,
    handleChangePropriete,
    locataires,
    proprietes: isComptableAgence
      ? proprieteAgence?.results
      : proprieteProprio?.results,
    Controller,
    control,
    option,
    options,
    locataireItem,
    handleChangeLocataire,
  };
}

export default useAddOrUpdateEntree;

export function UseDeleteEntree(
  slug: string
  // modalID: string
) {
  const [deleteData] = useDeleteEntreeMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette entrée ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        // console.log(modalID)
        Swal.fire({
          icon: "success",
          title: `Entrée supprimée avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
        // .then(() => {
        // onHide("EnvoieSuppressionNoteModal");
        // });
      }
    });
  };
  return onDelete;
}
