import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/appImages/kanimmoLogoNoir.png";
import Business from "../../../assets/icons/IoBusinessSharp.png";
import User from "../../../assets/icons/user-register.png";
import { onHide } from "../../../utils/Utils";
import { IoClose } from "react-icons/io5";

function RegisterModal() {
  return (
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container-form">
            <div className="flex-sb">
              <div className="container-logo-page-register">
                <NavLink className="navbar-brand" to="/">
                  <img src={Logo} alt="Logo" className="logo-app" 
                  style={{ maxWidth: '250px', height: '150px' }}
                  />
                </NavLink>
              </div>
              <button
                className="close-modal auth-submit-annuler"
                data-bs-dismiss="modal"
                type="button"
              >
                <IoClose />
              </button>
            </div>
            <div className="container-card-register">
              <div className="content-title-page-register-animate mt-4">
                <p className="title-page-register-animate text-center">
                  Vous êtes
                </p>
              </div>
              <div className="row">
                {/* <div className="col-md-4 mb-3">
                  <div className="card-animate-register-item animate__animated animate__fadeInTopLeft animate__delay-400ms">
                    <div className="content-card-animate-register-item">
                      <div className="content-title-card-animate">
                        <h5 className="title-card-animate">locataire</h5>
                      </div>
                      <div className="content-icon-card-animate-register">
                        <img
                          src={Key}
                          alt="icon card register animate"
                          className="icon-card-register-animate"
                        />
                      </div>

                      <div className="content-text-card-animate">
                        <p className="text-card-animate">
                          Jouissez pleinement d'une application mobile ou de la
                          plateforme pour payer votre loyer, faire des demandes
                          de réparation, digitaliser et répertorier toutes les
                          documentations relatives à votre bail.
                        </p>
                      </div>
                      <div
                        className="content-btn-register-card-animate"
                        onClick={() => onHide("RegisterModal")}
                      >
                        <NavLink
                          to="/inscription-locataire"
                          className="btn btn-animate-card-register"
                        >
                          S’inscrire
                          <span className="span-register-animate"></span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-6 mb-3">
                  <div className="card-animate-register-item animate__animated animate__fadeInUpBig animate__delay-600ms">
                    <div className="content-card-animate-register-item">
                      <div className="content-title-card-animate">
                        <h5 className="title-card-animate">AGENCE</h5>
                      </div>
                      <div className="content-icon-card-animate-register">
                        <img
                          src={Business}
                          alt="icon card register animate"
                          className="icon-card-register-animate"
                        />
                      </div>

                      <div className="content-text-card-animate">
                        <p className="text-card-animate">
                          Bénéficiez d'un outil unique "one stop shop" pour
                          toutes vos opérations de gestion, inclusif et
                          intuitif, pour un travail collaboratif, plus rapide,
                          et peu coûteux.
                        </p>
                      </div>
                      <div
                        className="content-btn-register-card-animate"
                        onClick={() => onHide("RegisterModal")}
                      >
                        <NavLink
                          to="/inscription-agence"
                          className="btn btn-animate-card-register"
                        >
                          S’inscrire
                          <span className="span-register-animate"></span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card-animate-register-item animate__animated animate__fadeInBottomRight animate__delay-800ms">
                    <div className="content-card-animate-register-item">
                      <div className="content-title-card-animate">
                        <h5 className="title-card-animate">PROPRIETAIRE</h5>
                      </div>
                      <div className="content-icon-card-animate-register">
                        <img
                          src={User}
                          alt="icon card register animate"
                          className="icon-card-register-animate"
                        />
                      </div>

                      <div className="content-text-card-animate">
                        <p className="text-card-animate">
                          Gardez un œil informé sur toutes les opérations
                          effectuées sur vos bien confiés à des tiers, ou gérez
                          vos biens immobiliers sans intermédiaire.
                        </p>
                      </div>
                      <div
                        className="content-btn-register-card-animate"
                        onClick={() => onHide("RegisterModal")}
                      >
                        <NavLink
                          to="/inscription-proprietaire"
                          className="btn btn-animate-card-register"
                        >
                          S’inscrire
                          <span className="span-register-animate"></span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterModal;
