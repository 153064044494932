import React, { useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useAppSelector } from "../../../../redux/hooks";
import { IoMdTrash } from "react-icons/io";
import { UseDeleteLocataire } from "../../../TableauDebord/Agence/Locataires/CRUDLocataire/useAddOrUpdateLocataire";
import { AlertInfo } from "../../../common/Alert";
import VillasTableSkeleton from "./VillasTableSkeleton";
import {
  isAgence,
  isAgenceAgent,
  isAgenceJuriste,
  isProprietaireOwner,
  isProprioJuriste,
} from "../../../../utils/Utils";
import {
  useGetAgenceDataQuery,
  useGetAgencesQuery,
} from "../../../../utils/api/agence/agence.api";

function LocataireEnLitigeTable({
  name,
  uptodate,
  type,
}: {
  name?: string | null;
  uptodate?: string | null;
  type?: string | null;
}) {
  const { user } = useAppSelector((s) => s?.user);
  const userType = user?.user_type;

  const { data: ListeAgence } = useGetAgencesQuery({});
  const AgenceData = ListeAgence?.results
    ?.filter((item) => item?.id === user?.agence_id)
    ?.map((itm) => itm?.slug);
  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));
  const is_Proprio = useAppSelector((s) => isProprietaireOwner(s.user?.user));
  const { data = { locataires_encours_litiges: [] }, isLoading } =
    useGetAgenceDataQuery({
      slug:
        is_Agence || is_Proprio
          ? user?.slug
          : user?.agence_id?.slug || user?.proprietaire_id?.slug,
      type:
        is_Agence || isAgenceAgent(user) || isAgenceJuriste(user)
          ? "agence"
          : "proprietaire",
    });

  // console.log("data test", data);

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        {userType !== "juriste" && (
          <div className="table-actions-btn-container-commandes gap-2 d-flex">
            <div>
              <NavLink
                to={`/agence/modifier-locataire`}
                className="btn btn-action-modal-icon"
                title="Modifier"
                state={row}
              >
                <MdModeEditOutline />
              </NavLink>
            </div>
            <DeleteLocataireBtn slug={row?.slug} />
          </div>
        )}
      </>
    );
  };
  const seeDetailFormatter = (cell: any, row: any) => {
    return (
      <>
        {/* {userType !== "juriste" && */}
        <div className="table-actions-btn-container-commandes d-flex">
          <div>
            <NavLink
              className="btn btn-see-list"
              title="Voir"
              to={
                isProprioJuriste(user)
                  ? `/proprietaire/gerer-par-le-proprietaire/locataire/${row?.slug}`
                  : `/agence/liste-locataire/${row?.slug}`
              }
              state={row}
            >
              Voir
            </NavLink>
          </div>
        </div>
        {/* } */}
      </>
    );
  };

  const proprieteFormatter = (cell: any, row: any) => <div>{cell?.nom}</div>;
  const fullnameFormatter = (cell: any, row: any) => (
    <div>{row?.prenom + " " + row?.nom}</div>
  );

  const columns = [
    {
      dataField: "nom",
      text: "Nom du locataire",
      formatter: (cell: any, row: any) => fullnameFormatter(cell, row),
    },
    {
      dataField: "telephone",
      text: "Téléphone",
      formatter: (cell: any, row: any) => (
        <a className="no-link" href={`tel:${cell}`}>
          {cell}
        </a>
      ),
    },
    {
      dataField: "propriete",
      text: "Propriete",
      formatter: (cell: any, row: any) => proprieteFormatter(cell, row),
    },
    {
      dataField: "",
      text: "Détails",
      formatter: (cell: any, row: any) => seeDetailFormatter(cell, row),
      style: { textAlign: "center" },
    },
    userType !== "juriste" && {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <VillasTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            pagination={paginationFactory()}
            data={data?.locataires_encours_litiges || []}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          {/* <CustomPagination
            nbPages={data?.count}
            page={page}
            perPage={perPage}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
          /> */}
        </>
      )}
    </>
  );
}

export default LocataireEnLitigeTable;

function DeleteLocataireBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteLocataire(slug);

  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
    >
      <IoMdTrash />
    </button>
  );
}
