import React from "react";
import {
  IReseauSociaux,
  TypeReseau,
} from "../../../utils/api/reseauSociaux/reseauSociaux.type";
import { BtnCloseModal, BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import ErrorMessage from "../../common/ErrorMessage";
import { Input } from "../../common/Input";
import { useCrudRsocial } from "../../TableauDebord/Admin/Options/CrudRSociaux/UseCrudRSociaux";

const typeReseau = [
  {
    value: TypeReseau.facebook,
    label: "Facebook",
  },
  {
    value: TypeReseau.instagram,
    label: "Instagram",
  },
  {
    value: TypeReseau.linkedin,
    label: "Linkedin",
  },
  {
    value: TypeReseau.twitter,
    label: "Twitter",
  },
];

function AjoutRsociauxModal({ item }: { item?: IReseauSociaux }) {
  const { register, onSubmit, errors, isLoading, reset } = useCrudRsocial(item);
  return (
    <div className="modal-dialog modal-md modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            {item ? "Modifier " : "Ajouter "} un réseau social
          </h5>
          <BtnCloseModal label="" onClick={reset} />
        </div>
        <form action="" onSubmit={onSubmit}>
          <div className="row py-4 px-3">
            <div className="col-md-12">
              <div className="mb-3">
                <select
                  id="type_reseau"
                  className="form-select form-select-modal-custom mb-2"
                  {...register("type_reseau")}
                >
                  <option value="" selected disabled>
                    Selectionner le type de réseau
                  </option>
                  {typeReseau?.map((item) => (
                    <option value={item.value} key={item.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
                {errors.type_reseau && (
                  <ErrorMessage message={errors.type_reseau?.message} />
                )}
              </div>
            </div>
            <div className="col-12 mb-3 ">
              <Input
                label="Url"
                id="tag"
                type="text"
                placeholder="Url"
                {...register("url")}
                error={errors?.url}
                className="form-control"
              />
            </div>
            <div className="col-12 mb-4 ">
              <label
                htmlFor="content"
                className="form-label form-label-modal-custom"
              >
                Description
              </label>
              <textarea
                className="form-control form-control-modal-custom"
                id="message"
                rows={3}
                {...register("description")}
              ></textarea>
              {<FormError error={errors.description} />}
            </div>

            <div className="flex-r">
              <BtnSubmit
                label={item ? "Modifier" : "Ajouter"}
                isLoading={isLoading}
                style={{ padding: "5px 10px", fontSize: 14 }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AjoutRsociauxModal;
