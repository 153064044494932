import React, { useState }  from 'react'
import { useGetLitigeByProprietaireQuery } from '../../../../../../utils/api/litiges/litiges.api'
import moment from "moment";
import { AlertInfo } from "../../../../../common/Alert";
import { ILitiges } from "../../../../../../utils/api/litiges/litiges.type";
import { useAppSelector } from "../../../../../../redux/hooks";



function LitigesPQNGPSB() {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const proprietaire = useAppSelector((s) => s.user.user);
    const { data = { results: [] }, isLoading } =
    useGetLitigeByProprietaireQuery({
        page,
        limit: perPage,
        slug: proprietaire?.slug,
      });
    
    const [item, setItem] = useState<ILitiges>();

  
    return (
    <div className="bg-white py-3 pe-3">
      <div className="container-liste-litiges">
        {data?.results?.length === 0 && <AlertInfo message="Aucune donnée trouvéé" />}
        {data?.results?.map((item) => (
          <div
            className={`content-list-litiges custom-position-litige-container mb-2 ${
              item?.type_litige === "assignation_expulsion"
                ? "content-list-litiges-assignation"
                : item?.type_litige === "commandement_payer"
                ? "content-list-litiges-commandement"
                : "content-list-litiges-jugement"
            }`}
          >
            <div className="row align-items-center">
             
              <div className="col-lg-3 col-md-6 md-pb-3 d-flex">
                <div className="content-date-litige">
                  <div className="content-value-litige">
                    <p className="value-litige">
                      {moment(item?.date).format("LL")}
                    </p>
                  </div>
                  <div className="content-libelle-litige">
                    <p className="libelle-litige mb-0">Date d’ajout</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 md-pb-3 d-flex">
                <div className="content-date-litige">
                  <div className="content-value-litige">
                    <p className="value-litige">
                      {item?.locataire?.prenom} {item?.locataire?.nom}
                    </p>
                  </div>
                  <div className="content-libelle-litige">
                    <p className="libelle-litige mb-0">Locataire</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 md-pb-3 d-flex">
                <div className="content-date-litige">
                  <div className="content-value-litige mb-3">
                    <div
                      className={`custom-type-litige-container ${
                        item?.type_litige === "assignation_expulsion"
                          ? "litige-assignation"
                          : item?.type_litige === "commandement_payer"
                          ? "litige-commandement"
                          : "litige-jugement"
                      }`}
                      
                      onClick={() => setItem(item)}
                    >
                      {item?.type_litige === "assignation_expulsion"
                        ? "Assignation d’expulsion"
                        : item?.type_litige === "commandement_payer"
                        ? "Commandement de payer"
                        : "Jugement"}
                    </div>
                  </div>
                  <div className="content-libelle-litige mb-2">
                    <p className="libelle-litige mb-0">Type de litige</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 md-pb-3 flex-c">
                <div className="content-date-litige">
                  <div className="content-value-litige">
                    <p className="value-litige-action">{item?.titre}</p>
                  </div>
                  <div className="content-libelle-litige">
                    <p className="libelle-litige mb-0">Exécution du jugement</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

 
      </div>
    </div>
    )
}

export default LitigesPQNGPSB
