import React, { useEffect } from "react";
import {
  ILitiges,
  LitigesFormData,
} from "../../../utils/api/litiges/litiges.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEditLitigeMutation } from "../../../utils/api/litiges/litiges.api";
import { cleannerError, onHide } from "../../../utils/Utils";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { optionsTypeLitige } from "./AjoutLitigesModale";
import ErrorMessage from "../../common/ErrorMessage";
import { IoClose } from "react-icons/io5";

function ChangeTypeLitigeModal({
  modalId,
  item,
  reload,
}: {
  item?: ILitiges;
  modalId: any;
  reload?: boolean;
}) {
  const validationSchema = yup.object().shape({
    type_litige: yup.string().required().label("Status"),
  });

  const [changeTypeLitige, { isSuccess, isError, error, isLoading }] =
    useEditLitigeMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm<LitigesFormData>({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    if (item?.id) {
      setValue("slug", item?.slug);
      setValue("type_litige", item?.type_litige);
    }
  }, [item]);

  useEffect(() => {
    if (errors) {
      return cleannerError(errors, clearErrors);
    }
  }, [errors]);
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Changement de type réussi !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        reset();
        onHide("ChangeStatusRequeteModal");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: `${err ? err?.message : "Une erreur s'est produite"}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  const onSubmit = (data: any) => {
  // console.log(data);
    changeTypeLitige({ slug: data?.slug, data: data });
  };
  useEffect(() => {
    if (isSuccess && reload) {
      location.reload();
    }
  }, [isLoading]);
  return (
    <div className="modal-dialog" id="ChangeStatusRequeteModal">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title"></h5>
          <button
            className="close-modal auth-submit-annuler"
            data-bs-dismiss="modal"
            type="button"
          >
            <IoClose />
          </button>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="titre"
                      className="form-label form-label-modal-custom"
                    >
                      Type litige
                    </label>
                    <select
                      className="form-control form-control-input-admin py-3"
                      id="type_litige"
                      {...register("type_litige")}
                    >
                      <option value="" selected disabled>
                        Type litige
                      </option>
                      {optionsTypeLitige?.map((option: any) => (
                        <option value={option?.value} key={option.value}>
                          {option?.label}
                        </option>
                      ))}
                    </select>
                    {errors?.type_litige && (
                      <ErrorMessage message={errors?.type_litige?.message} />
                    )}
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row mt-5">
                <div className="col-md-3">
                  <button
                    className="btn btn-cancelled"
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-md-6 offset-md-3 flex-r">
                  <BtnSubmit label="Changer" isLoading={isLoading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeTypeLitigeModal;
