/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from "react";
import {
  formatAmount,
  formatMontant,
  getAvatar,
  getImage,
  useLocationState,
} from "../../../../utils/Utils";
import { MdOutlineMailOutline, MdOutlinePayments } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { BiArrowBack } from "react-icons/bi";
import {
  Gerance,
  IProprietaire,
} from "../../../../utils/api/proprietaire/proprietaire.type";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import Villa1 from "../../../../assets/appImages/villa1.png";
import Key from "../../../../assets/icons/key.png";
import Users from "../../../../assets/icons/users.png";
import Paper from "../../../../assets/icons/paper.png";
import { GiPayMoney } from "react-icons/gi";
import { RiSendPlaneFill } from "react-icons/ri";
import {
  useGetPaiementProprioQuery,
  useSendPaymentMutation,
  useGetCreateCompteRenduMutation,
} from "../../../../utils/api/proprietaire/proprietaire.api";
import { AlertInfo } from "../../../common/Alert";
import { Color } from "../../../../utils/theme";
import Swal from "sweetalert2";
import { useValdation } from "./valdationGerant/validation";

function PaymentProprietaire() {
  const item = useLocationState<IProprietaire>(undefined);
  const navigate = useNavigate();
  // console.log("item", item)
  const { user } = useAppSelector((s) => s.user);
  const {
    data = {
      results: { items: [], total_depense: 0, total_restant: 0 },
      count: 0,
    },
    isLoading,
  } = useGetPaiementProprioQuery({
    slug: item?.slug,
  });
  const [sendData] = useSendPaymentMutation();
  console.log("count", data?.count);
  

  const { register, onSendValidation } = useValdation(item, data);
  const [comteRenduCreate] = useGetCreateCompteRenduMutation();

  const onSend = async () => {
    await Swal.fire({
      title: `Paiements pour le propriétaire:`,
      html: `     
      <div class="place-card__content">
        <div class="payment-details-container">
          <div class="payment-details-content">
            <p class="payment-details-title">
              <span class="card-text-logement"> Commission: </span>
            </p>
            <p class="payment-details-amount">
              ${data?.results?.items[0]?.propriete?.commission_agence} %
            </p>
          </div>
          <div class="payment-details-content">
            <p class="payment-details-title">
              <span class="card-text-logement">Propriétaire </span>
            </p>
            <p class="payment-details-amount">
            ${item?.prenom + " " + item?.nom}
            </p>
          </div>
          <div class="payment-details-content">
            <p class="payment-details-title">
              <span class="card-text-logement"> Bien géré: </span>
            </p>
            <p class="payment-details-amount">
            ${data?.results?.items[0]?.propriete.nom}
            </p>
          </div>
          <div class="payment-details-total">
            <div class="payment-details-content">
              <p class="payment-details-title">
                <span class="card-text-logement"> Dépenses: </span>
              </p>
              <p class="payment-details-amount">
              ${formatMontant(data?.results?.total_depense)}
              </p>
            </div>
          </div>
          
          <div class="payment-details-total">
            <div class="payment-details-content">
              <p class="payment-details-title">
                <span class="card-text-logement"> Montant à transférer: </span>
              </p>
              <p class="payment-details-amount">
                 ${formatMontant(data?.results?.total_restant)}
              </p>
            </div>
          </div>
        </div>
      </div>
    `,
      text: "Veuillez cliquer sur envoyer si vous voulez continuer!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ENVOYER",
      cancelButtonText: "ANNULER",
      showLoaderOnConfirm: true,
      iconColor: Color.bleu,
      confirmButtonColor: Color.bleu,
      preConfirm: async () => {
        try {
          await comteRenduCreate(item?.slug); // Attendre que la création du compte rendu soit terminée
          return sendData(item?.slug); // Envoyer les données
        } catch (error) {
          throw error; // Propager l'erreur si quelque chose se passe mal
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: "Le montant a été envoyé avec succès!",
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue lors du transfert.`,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  // useEffect(() => {
  //   console.log("data", data);
  // }, [data]);
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4
            className="kanimmo-dash-locataire-requetes-header-titre"
            onClick={() => navigate(-1)}
          >
            <BiArrowBack />
            <span className="ps-3">Paiements pour le propriétaire</span>
          </h4>
        </div>
        <div className="comptabilite-component-container">
          <div className="row px-md-2 align-items-center">
            <div className="col-md-3 mb-3 dis-flex px-md-2">
              <div className="item-stat-dashboad-view">
                <div className="content-stat-dashboard-view">
                  <div className="content-item-icon-stat-dashboard-view">
                    <img
                      src={Key}
                      alt="icon"
                      className="icon-item-stat-dashboad-view"
                    />
                  </div>
                  <div className="content-text-item-stat-dashboad-view ms-3">
                    <div className="content-item-stat-dashboad-view mt-2">
                      <p className="titre-item-stat-dashboad-view mb-2">
                        Total des dépenses
                      </p>
                    </div>
                    <div className="icon-item-stat-dashboad-view">
                      <p className="chiffre-item-stat-dashboad-view mb-1">
                        {isLoading ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                            style={{ color: "#1F3CAD" }}
                          ></div>
                        ) : (
                          formatMontant(data?.results?.total_depense)
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3 dis-flex px-md-2">
              <div className="item-stat-dashboad-view">
                <div className="content-stat-dashboard-view">
                  <div className="content-item-icon-stat-dashboard-view">
                    <img
                      src={Paper}
                      alt="icon"
                      className="icon-item-stat-dashboad-view"
                    />
                  </div>
                  <div className="content-text-item-stat-dashboad-view ms-3">
                    <div className="content-item-stat-dashboad-view mt-2">
                      <p className="titre-item-stat-dashboad-view mb-2">
                        Montant à transfèrer
                      </p>
                      {/* <p className="users-item-stat-dashboad-view mb-1">
                        Cette semaine
                      </p> */}
                    </div>
                    <div className="icon-item-stat-dashboad-view">
                      <p className="chiffre-item-stat-dashboad-view mb-1">
                        {isLoading ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                            style={{ color: "#FCAE3B" }}
                          ></div>
                        ) : (
                          formatMontant(data?.results?.total_restant)
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3 flex-r px-md-2">
              {user.user_type === "agence" && (
                <>
                  {data?.count > 0 && data?.results?.total_restant > 0 && (
                    <div className="container-btn-add">
                      <button
                        className="payment-details-btn"
                        onClick={() => onSend()}
                        disabled={isLoading}
                      >
                        <RiSendPlaneFill style={{ fontSize: 22 }} />
                        <span className="ps-2">Transférer</span>
                      </button>
                    </div>
                  )}
                </>
              )}

              <div className="col-md-6 mb-3 flex-r px-md-2">
                {user.user_type === "gerant" && (
                  <>
                    {data?.count > 0 && (
                      <div className="container-btn-add">
                        <button
                          className="payment-details-btn"
                          onClick={() => onSendValidation()}
                          disabled={isLoading}
                        >
                          <RiSendPlaneFill style={{ fontSize: 22 }} />
                          <span className="ps-2">Envoyer pour validation</span>
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="kanimmo-proprietes-container">
          <div className="proprietaire-component-container">
            <div className="content-detail-proprietaire">
              <div className="row">
                <div className="col-md-4">
                  <div className="col-left-detail-proprietaire">
                    <div className="content-title-detail-proprietaire">
                      <p className="title-detail-proprietaire">
                        Information du propriétaire
                      </p>
                    </div>
                    <div className="text-center pt-3">
                      <div className="content-pp-proprietaire pb-2">
                        <img
                          src={getAvatar(item?.avatar)}
                          alt="Avatar"
                          className="pp-proprietaire"
                        />
                      </div>
                      <p className="title-detail-proprietaire">
                        {item?.prenom + " " + item?.nom}
                      </p>
                      <p className="title-detail-proprietaire">
                        {item?.adresse}
                      </p>
                    </div>
                    <div className="content-info-proprietaire">
                      <div className="content-info-prprietaire-item mb-2">
                        <MdOutlineMailOutline />
                        <span className="ps-3">
                          <a className="no-link" href={`mailto:${item?.email}`}>
                            {item?.email}
                          </a>
                        </span>
                      </div>
                      <div className="content-info-prprietaire-item mb-2">
                        <IoCallOutline />
                        <span className="ps-3">
                          <a
                            className="no-link"
                            href={`tel:${item?.telephone}`}
                          >
                            {item?.telephone}
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  {/* <ListProprietesByProrietaire slug={item.slug} /> */}
                  <div
                    className="col-right-detail-proprietaire"
                    style={{ minHeight: "100vh" }}
                  >
                    {!!isLoading && <p>Loading...</p>}
                    {!isLoading &&
                      (data?.count > 0 ? (
                        data?.results?.items?.map((el, i) => (
                          <CardPaymentItem key={i} item={el} />
                        ))
                      ) : (
                        <AlertInfo message="Aucun paiement disponible pour le moment" />
                      ))}
                    {/* {[...Array(4)].map((item, i) => (
                      <CardPaymentItem key={i} />
                    ))} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentProprietaire;

const CardPaymentItem = ({ item }: { item: Gerance }) => {
  return (
    <div className="place-card">
      <div className="place-card__img">
        <img
          src={getImage(item?.propriete?.pictures[0]?.image)}
          className="place-card__img-thumbnail"
          alt="Thumbnail"
        />
      </div>
      <div className="place-card__content">
        <div className="place-card-container-header">
          <h2 className="place-card__content_header place-title">
            {item?.propriete?.nom}{" "}
          </h2>
          <p className="text-tag-card color-theme">
            {" "}
            {item?.propriete?.type_propriete}
          </p>
        </div>

        <p className="adreese">
          <i className="fa fa-map-marker-alt m-0"></i>{" "}
          <span className="card-text-logement">
            {" "}
            {item?.propriete?.adresse}{" "}
          </span>
        </p>
        <div className="payment-details-container">
          <div className="payment-details-content">
            <p className="payment-details-title">
              <GiPayMoney />
              {/* <i className="fa fa-map-marker-alt m-0"></i>{" "} */}
              <span className="card-text-logement"> Location: </span>
            </p>
            <p className="payment-details-amount">
              {" "}
              {formatAmount(item?.details?.location)}{" "}
            </p>
          </div>
          <div className="payment-details-content">
            <p className="payment-details-title">
              <MdOutlinePayments />
              <span className="card-text-logement"> Dépenses: </span>
            </p>
            <p className="payment-details-amount">
              {" "}
              {formatAmount(item?.details?.depense)}{" "}
            </p>
          </div>
          <div className="payment-details-total">
            <div className="payment-details-content">
              <p className="payment-details-title">
                <RiSendPlaneFill />
                <span className="card-text-logement"> Montant à envoyer: </span>
              </p>
              <p className="payment-details-amount">
                {" "}
                {formatAmount(item?.details?.restant)}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
