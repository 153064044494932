/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone, FileWithPath } from "react-dropzone";
import { BiTrash } from "react-icons/bi";
import { MdAddAPhoto } from "react-icons/md";
import { useDeletePictureMutation } from "../../utils/api/propriete/propriete.api";
import { TImage } from "../../utils/api/propriete/propriete.type";
import { getImage } from "../../utils/Utils";

function MyDropzone({ setImages }: { setImages: any }) {
  const [maxFileError, setMaxFileError] = useState<string>("");
  const onDrop = useCallback((acceptedFiles: any, fileRejections: any[]) => {
    if (acceptedFiles) {
      setImages((prevImages: any = []) => [...prevImages, ...acceptedFiles]);
    }
    fileRejections.forEach((file: { errors: any[] }) => {
      file.errors.forEach((err: { code: string }) => {
        if (err.code === "too-many-files") {
          setMaxFileError("Maximum 4 images");
        }
        if (err.code === "file-invalid-type") {
          setMaxFileError("Types de fichiers non prises en charge");
        }
      });
    });
  }, []);

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      onDrop,
      maxFiles: 4,
      multiple: true,
      maxSize: 209715200,
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
      // maxSize: 1000,
    });
  // let acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
  // 	<div className="col-3" key={file.path}>
  // 		<div className="image-item">
  // 			<img src={URL.createObjectURL(file)} alt={file.path} loading="lazy" />
  // 		</div>
  // 	</div>
  // ));
  const fileRejectionItems = fileRejections.map(({ file, errors }, index) => {
    return (
      <div key={index}>
        {/* {file.size} bytes */}
        <ul className="p-0">
          {errors.map((e) => (
            <li
              key={e.code}
              style={{ listStyle: "none" }}
              className="text-danger"
            >
              {e.code === "file-too-large" &&
                `L'image ${index + 1} est trop grande`}
            </li>
          ))}
        </ul>
      </div>
    );
  });

  useEffect(() => {
    setTimeout(() => setMaxFileError(""), 3000);
  });
  return (
    <section className="dropzone">
      {/* <label htmlFor="pro-images" className="espace-client-form-label">
				label
			</label> */}
      <label
        htmlFor="pro-images"
        style={{
          border: ".2px solid #959595",
          overflow: "hidden",
          opacity: 1,
          borderRadius: "7px",
          display: "flex",
          justifyContent: "center",
          padding: "3rem 15px",
          background: "rgb(243, 243, 243)",
          cursor: "pointer",
        }}
      >
        <div className="start-label-input-choose-file">
          <div className="flex-c">
            <MdAddAPhoto style={{ color: "#666666", fontSize: "36px" }} />
          </div>
          <span className="first-label-input-choose-file">
            Déposez une image ici ou sélectionnez un fichier.
          </span>
          <span className="second-label-input-choose-file">
            Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
          </span>
        </div>
        <input
          type="file"
          id="pro-images"
          name="pro-images"
          accept="image/*"
          style={{ display: "none" }} // Cacher l'input
          onChange={(e) => onDrop(e.target.files, [])}
        />
      </label>

      {/* {maxFileError && <ErrorMessage message={maxFileError} />} */}
      <div className="row pt-4">
        {/* {acceptedFileItems} */}
        {!maxFileError && fileRejectionItems}
      </div>
    </section>
  );
}

export default MyDropzone;

type TImageGrid = {
  images: File[] | [];
};

type ImageType = { slug: string; image: string; id: number };

export const ImageGrid = ({ images }: TImageGrid) => {
  return (
    <>
      {images.length
        ? images.map((file: FileWithPath) => (
            <div className="col-md-3 mb-1" key={file.path}>
              <div className="image-item">
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.path}
                  loading="lazy"
                  className="img-thumbnail"
                  id="overlay"
                />
              </div>
            </div>
          ))
        : ""}
    </>
  );
};

export const ImagesView = ({
  images,
  setImages,
}: {
  images: any;
  setImages: any;
}) => {
  const [deleteImage] = useDeletePictureMutation();
  const deleteFile = (index: number, item: any) => {
    const newArr = [...images];
    newArr.splice(index, 1);
    setImages(newArr);

    if (item && item?.image) {
      deleteImage(item?.slug);
    }
  };
  return (
    <div className="row">
      {images.length
        ? images.map((file: any, i: number) => (
            <div className="col-md-3 mb-1" key={file?.slug || i}>
              <div className="image-item properti-img-card">
                <button
                  className="no-style-btn del-product-img"
                  title="Supprimer"
                  type="button"
                  onClick={() => deleteFile(i, file)}
                >
                  <BiTrash />
                </button>
                <img
                  src={
                    file?.id ? getImage(file?.image) : URL.createObjectURL(file)
                  }
                  alt={file.slug}
                  loading="lazy"
                  className="img-proprety"
                  //   id="overlay"
                  //   style={{
                  //     width: "100%",
                  //     height: "15vw",
                  //     objectFit: "fill",
                  //   }}
                />
              </div>
            </div>
          ))
        : ""}
    </div>
  );
};
