import React, { SetStateAction } from "react";
import { IEtatLieu } from "../../../utils/api/etatlieu/etatlieu.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import { Input } from "../../common/Input";
import { useCrudEtatLieu } from "../../TableauDebord/Agence/Administratif/requestForm/useCrudEtatLieu";
import Select from "react-select";
import makeAnimated from "react-select/animated";


function AjouterEtatLieuModal(props: {
  item?: IEtatLieu;
  setItem?: React.Dispatch<SetStateAction<IEtatLieu | undefined>>;
  modalId: string;
}) {
 
  const {
    register,
    onSubmit,
    errors,
    locataires,
    handleChangePropriete,
    handleChangeDocument,
    isLoading,
    reset,
    Controller,
    control,
    options,
    option,
    handleChangeLocataire,
    locataireItem,
    proprietes,
  } = useCrudEtatLieu(props.modalId, props.item);
 
  const animatedComponents = makeAnimated();
  const resetForm = () => {
    if (!props.item) {
      reset();
    }

    // setItem && setTimeout(() => setItem(undefined), 50);
  };

  return (
    <div
      className="modal fade"
      id={props.modalId}
      aria-labelledby="AjouterEtatLieuModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {props.item ? "Modifier l'" : "Ajouter un"} état de lieu
            </h5>
          </div>
          <div className="modal-body text-start">
            <div className="container-form pt-3">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <Controller
                        name="propriete"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              options={options}
                              placeholder="Selectionner une propriété"
                              inputClass="form-select form-select-modal-custom mb-2"
                              classNamePrefix="select-comodite"
                              onChange={(e: any) => handleChangePropriete(e)}
                              value={option}
                            />
                          );
                        }}
                      />
                      {errors?.propriete && (
                        <FormError error={errors?.propriete?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-3">
                      <label
                        htmlFor="nombre_stock"
                        className="form-label form-label-modal-custom"
                        aria-labelledby="propriete"
                      >
                        Client
                        <span
                          className="text-danger"
                          style={{
                            fontSize: 24,
                          }}
                        >
                          *
                        </span>
                      </label>
                      <Controller
                        name="locataire"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              options={locataires}
                              placeholder="Selectionner une locataire"
                              inputClass="form-select form-select-modal-custom mb-2"
                              classNamePrefix="select-comodite"
                              onChange={handleChangeLocataire}
                              value={locataireItem}
                            />
                          );
                        }}
                      />
                      {locataires && locataires?.length === 0 && (
                        <small className="badge text-bg-info px-2">
                          Aucun locataire trouvé pour cette propriété!
                        </small>
                      )}
                      {<FormError error={errors.locataire} />}
                    </div>
                  </div>
                  <div className="col-12">
                    <Input
                      type="file"
                      label="Document"
                      id="document"
                      required
                      error={errors.document}
                      onChange={handleChangeDocument}
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                  </div>
                </div>
                <div className="container-btn-modal row mt-5">
                  <div className="col-md-3">
                    <button
                      className="btn btn-cancelled"
                      data-bs-dismiss="modal"
                      type="button"
                      onClick={resetForm}
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="col-md-6 offset-md-3 flex-r">
                    <BtnSubmit
                      label={props.item ? "Modifier" : "Envoyer"}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterEtatLieuModal;
