import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import EmptyHeader from "../../../landingPage/EmptyHeader";
import FrontFooter from "../../../landingPage/FrontFooter/FrontFooter";
import BreadCrumb from "../../../shared/BreadCrumb";
import "./Faq.css";

function Faq() {
  return (
    <div className="landing-page-component">
      {/* <EmptyHeader />
      <div className="page-content">
        <BreadCrumb pageName="FAQ" title="FAQ" />
      </div> */}

      <section className="page-content-body-container container">
        <div className="online-courses-container">
          <div className="news-list-container pt-5">
            <div className="content-title-page-faq text-center">
              <h3 className="title-page-faq">Demandez-nous n'importe quoi</h3>
              <p className="sub-title-faq">
                Avez-vous des questions? Nous sommes là pour vous aider.
              </p>
              <div className="flex-c mt-3 mb-4">
                <div className="search-box-form-container"> 
                  <form id="search-box-form">
                    <div className="search-box-form-content">
                      <div className="search-box-input-group">
                        <input
                          placeholder="Rechercher ici"
                          type="text"
                          className="form-control input-search-form-control"
                        />
                        <div className="search-box-form-icon-container">
                          <FiSearch className="search-box-form-icon" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="content-page-faq mt-5">
              <div className="collapse-faq-item mb-4">
                <p>
                  <a
                    className="btn btn-title-collapsed-faq"
                    data-bs-toggle="collapse"
                    href="#collapsefaq1"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapsefaq1"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </a>
                </p>
                <div className="collapse" id="collapsefaq1">
                  <div className="card card-body card-body-text-collapse">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Commodo sapien leo id interdum mi mattis ullamcorper. Odio
                    nisl tempus amet fringilla. Porta dignissim morbi morbi diam
                    aliquam non. Cras nibh suspendisse scelerisque metus, nunc
                    turpis. Ultrices purus non odio pellentesque bibendum.
                    Volutpat velit eget id amet ornare duis eu. Aliquam pretium
                    morbi et posuere etiam pellentesque massa facilisi. Netus
                    dictum at pellentesque ipsum felis, nunc. Placerat felis,
                    sem nam egestas quam ullamcorper adipiscing pulvinar quis.
                    Sed pulvinar tristique placerat dignissim sapien platea
                    ipsum. Convallis dolor non consequat vulputate congue
                    suspendisse egestas. Facilisi suspendisse accumsan,
                    venenatis amet mauris. Lacus et et urna dignissim dapibus
                    placerat sagittis. Pharetra facilisis eget tortor, sed lacus
                    in nunc, et. Tincidunt in in malesuada quis. At massa
                    aliquam et consequat varius. Sed habitasse scelerisque
                    malesuada aliquam elementum ac urna sodales tempor. Varius
                    nam vitae sed hac ultrices velit eget. Libero ipsum
                    porttitor nunc vitae nulla cum dictum lorem quisque. Dictum
                    euismod est turpis molestie velit nam et eu sagittis.
                  </div>
                </div>
              </div>
              <div className="collapse-faq-item mb-4">
                <p>
                  <a
                    className="btn btn-title-collapsed-faq"
                    data-bs-toggle="collapse"
                    href="#collapsefaq2"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapsefaq2"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </a>
                </p>
                <div className="collapse" id="collapsefaq2">
                  <div className="card card-body card-body-text-collapse">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Commodo sapien leo id interdum mi mattis ullamcorper. Odio
                    nisl tempus amet fringilla. Porta dignissim morbi morbi diam
                    aliquam non. Cras nibh suspendisse scelerisque metus, nunc
                    turpis. Ultrices purus non odio pellentesque bibendum.
                    Volutpat velit eget id amet ornare duis eu. Aliquam pretium
                    morbi et posuere etiam pellentesque massa facilisi. Netus
                    dictum at pellentesque ipsum felis, nunc. Placerat felis,
                    sem nam egestas quam ullamcorper adipiscing pulvinar quis.
                    Sed pulvinar tristique placerat dignissim sapien platea
                    ipsum. Convallis dolor non consequat vulputate congue
                    suspendisse egestas. Facilisi suspendisse accumsan,
                    venenatis amet mauris. Lacus et et urna dignissim dapibus
                    placerat sagittis. Pharetra facilisis eget tortor, sed lacus
                    in nunc, et. Tincidunt in in malesuada quis. At massa
                    aliquam et consequat varius. Sed habitasse scelerisque
                    malesuada aliquam elementum ac urna sodales tempor. Varius
                    nam vitae sed hac ultrices velit eget. Libero ipsum
                    porttitor nunc vitae nulla cum dictum lorem quisque. Dictum
                    euismod est turpis molestie velit nam et eu sagittis.
                  </div>
                </div>
              </div>
              <div className="collapse-faq-item mb-4">
                <p>
                  <a
                    className="btn btn-title-collapsed-faq"
                    data-bs-toggle="collapse"
                    href="#collapsefaq3"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapsefaq3"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </a>
                </p>
                <div className="collapse" id="collapsefaq3">
                  <div className="card card-body card-body-text-collapse">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Commodo sapien leo id interdum mi mattis ullamcorper. Odio
                    nisl tempus amet fringilla. Porta dignissim morbi morbi diam
                    aliquam non. Cras nibh suspendisse scelerisque metus, nunc
                    turpis. Ultrices purus non odio pellentesque bibendum.
                    Volutpat velit eget id amet ornare duis eu. Aliquam pretium
                    morbi et posuere etiam pellentesque massa facilisi. Netus
                    dictum at pellentesque ipsum felis, nunc. Placerat felis,
                    sem nam egestas quam ullamcorper adipiscing pulvinar quis.
                    Sed pulvinar tristique placerat dignissim sapien platea
                    ipsum. Convallis dolor non consequat vulputate congue
                    suspendisse egestas. Facilisi suspendisse accumsan,
                    venenatis amet mauris. Lacus et et urna dignissim dapibus
                    placerat sagittis. Pharetra facilisis eget tortor, sed lacus
                    in nunc, et. Tincidunt in in malesuada quis. At massa
                    aliquam et consequat varius. Sed habitasse scelerisque
                    malesuada aliquam elementum ac urna sodales tempor. Varius
                    nam vitae sed hac ultrices velit eget. Libero ipsum
                    porttitor nunc vitae nulla cum dictum lorem quisque. Dictum
                    euismod est turpis molestie velit nam et eu sagittis.
                  </div>
                </div>
              </div>
              <div className="collapse-faq-item mb-4">
                <p>
                  <a
                    className="btn btn-title-collapsed-faq"
                    data-bs-toggle="collapse"
                    href="#collapsefaq4"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapsefaq4"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Turpis neque magnis adipiscing.
                  </a>
                </p>
                <div className="collapse" id="collapsefaq4">
                  <div className="card card-body card-body-text-collapse">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Commodo sapien leo id interdum mi mattis ullamcorper. Odio
                    nisl tempus amet fringilla. Porta dignissim morbi morbi diam
                    aliquam non. Cras nibh suspendisse scelerisque metus, nunc
                    turpis. Ultrices purus non odio pellentesque bibendum.
                    Volutpat velit eget id amet ornare duis eu. Aliquam pretium
                    morbi et posuere etiam pellentesque massa facilisi. Netus
                    dictum at pellentesque ipsum felis, nunc. Placerat felis,
                    sem nam egestas quam ullamcorper adipiscing pulvinar quis.
                    Sed pulvinar tristique placerat dignissim sapien platea
                    ipsum. Convallis dolor non consequat vulputate congue
                    suspendisse egestas. Facilisi suspendisse accumsan,
                    venenatis amet mauris. Lacus et et urna dignissim dapibus
                    placerat sagittis. Pharetra facilisis eget tortor, sed lacus
                    in nunc, et. Tincidunt in in malesuada quis. At massa
                    aliquam et consequat varius. Sed habitasse scelerisque
                    malesuada aliquam elementum ac urna sodales tempor. Varius
                    nam vitae sed hac ultrices velit eget. Libero ipsum
                    porttitor nunc vitae nulla cum dictum lorem quisque. Dictum
                    euismod est turpis molestie velit nam et eu sagittis.
                  </div>
                </div>
              </div>
              <div className="collapse-faq-item mb-4">
                <p>
                  <a
                    className="btn btn-title-collapsed-faq"
                    data-bs-toggle="collapse"
                    href="#collapsefaq5"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapsefaq5"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Turpis neque magnis adipiscing.
                  </a>
                </p>
                <div className="collapse" id="collapsefaq5">
                  <div className="card card-body card-body-text-collapse">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Commodo sapien leo id interdum mi mattis ullamcorper. Odio
                    nisl tempus amet fringilla. Porta dignissim morbi morbi diam
                    aliquam non. Cras nibh suspendisse scelerisque metus, nunc
                    turpis. Ultrices purus non odio pellentesque bibendum.
                    Volutpat velit eget id amet ornare duis eu. Aliquam pretium
                    morbi et posuere etiam pellentesque massa facilisi. Netus
                    dictum at pellentesque ipsum felis, nunc. Placerat felis,
                    sem nam egestas quam ullamcorper adipiscing pulvinar quis.
                    Sed pulvinar tristique placerat dignissim sapien platea
                    ipsum. Convallis dolor non consequat vulputate congueRevenus
                    suspendisse egestas. Facilisi suspendisse accumsan,
                    venenatis amet mauris. Lacus et et urna dignissim dapibus
                    placerat sagittis. Pharetra facilisis eget tortor, sed lacus
                    in nunc, et. Tincidunt in in malesuada quis. At massa
                    aliquam et consequat varius. Sed habitasse scelerisque
                    malesuada aliquam elementum ac urna sodales tempor. Varius
                    nam vitae sed hac ultrices velit eget. Libero ipsum
                    porttitor nunc vitae nulla cum dictum lorem quisque. Dictum
                    euismod est turpis molestie velit nam et eu sagittis.
                  </div>
                </div>
              </div>
              <div className="collapse-faq-item mb-4">
                <p>
                  <a
                    className="btn btn-title-collapsed-faq"
                    data-bs-toggle="collapse"
                    href="#collapsefaq6"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapsefaq6"
                  >
                    Ligula donec dictum ac pulvinar arcu eget.
                  </a>
                </p>
                <div className="collapse" id="collapsefaq6">
                  <div className="card card-body card-body-text-collapse">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Commodo sapien leo id interdum mi mattis ullamcorper. Odio
                    nisl tempus amet fringilla. Porta dignissim morbi morbi diam
                    aliquam non. Cras nibh suspendisse scelerisque metus, nunc
                    turpis. Ultrices purus non odio pellentesque bibendum.
                    Volutpat velit eget id amet ornare duis eu. Aliquam pretium
                    morbi et posuere etiam pellentesque massa facilisi. Netus
                    dictum at pellentesque ipsum felis, nunc. Placerat felis,
                    sem nam egestas quam ullamcorper adipiscing pulvinar quis.
                    Sed pulvinar tristique placerat dignissim sapien platea
                    ipsum. Convallis dolor non consequat vulputate congue
                    suspendisse egestas. Facilisi suspendisse accumsan,
                    venenatis amet mauris. Lacus et et urna dignissim dapibus
                    placerat sagittis. Pharetra facilisis eget tortor, sed lacus
                    in nunc, et. Tincidunt in in malesuada quis. At massa
                    aliquam et consequat varius. Sed habitasse scelerisque
                    malesuada aliquam elementum ac urna sodales tempor. Varius
                    nam vitae sed hac ultrices velit eget. Libero ipsum
                    porttitor nunc vitae nulla cum dictum lorem quisque. Dictum
                    euismod est turpis molestie velit nam et eu sagittis.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FrontFooter />
    </div>
  );
}

export default Faq;
