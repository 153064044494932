/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  formatMontant,
  getName,
  isAgence,
  isProprietaireOwner,
} from "../../../utils/Utils";
import { useAppSelector } from "../../../redux/hooks";
import { useGetProprietaireQuery } from "../../../utils/api/proprietaire/proprietaire.api";
import { IProprietaire } from "../../../utils/api/proprietaire/proprietaire.type";
import { ApiBaseUrl } from "../../../utils/http";
import { IEntree } from "../../../utils/api/entree/entree.type";

function DetailsEntreeModal({
  item,
  modalId,
  entree,
  depense,
}: {
  item: any;
  modalId: string;
  entree?: boolean;
  depense?: boolean;
}) {
  const { user } = useAppSelector((s) => s?.user);
  const [proprietaire, setProprietaire] = useState<IProprietaire | undefined>();

  const { data: Proprios } = useGetProprietaireQuery({
    slug: user?.slug,
  });

  useEffect(() => {
    const Proprietaires = Proprios?.results?.find(
      (itm: any) => itm?.id === parseInt(item?.id)
    );
    setProprietaire(Proprietaires);
  }, []);

  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container-form custom-container-form">
            <div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex flex-column align-items-start mb-3">
                    <div className="custom-envoie-note-date">
                      Détails {entree ? "entrée" : "dépense"} du{" "}
                      <span className="agence-detail-facture-titre">
                        {moment(item?.created_at).format("LL")}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn-close custom-btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <hr />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    {item?.locataire?.id && (
                      <div className="detail-facture-infos-container mb-3">
                        <div className="detail-facture-infos-name">
                          Locataire:
                        </div>
                        <div className="detail-facture-infos-value">
                          {item?.locataire?.prenom + " " + item?.locataire?.nom}
                        </div>
                      </div>
                    )}
                    <div className="detail-facture-infos-container mb-3">
                      <div className="detail-facture-infos-name">
                        Catégorie:
                      </div>
                      <div className="detail-facture-infos-value">
                        {item?.categorie_entree?.titre ||
                          item?.categorie?.nom ||
                          (item?.type_entree === "mensualite"
                            ? "Mensualité"
                            : item?.type_entree)}
                      </div>
                    </div>
                    <div className="detail-facture-infos-container mb-3">
                      <div className="detail-facture-infos-name">
                        Propriété:
                      </div>
                      <div className="detail-facture-infos-value">
                        {item?.propriete?.nom}
                      </div>
                    </div>
                    <div className="detail-facture-infos-container mb-3">
                      <div className="detail-facture-infos-name">
                        Numéro de la propriété:
                      </div>
                      <div className="detail-facture-infos-value">
                        {item?.propriete?.numero}
                      </div>
                    </div>
                    {proprietaire?.id && (
                      <div className="detail-facture-infos-container mb-3">
                        <div className="detail-facture-infos-name">
                          Propriétaire:
                        </div>
                        <div className="detail-facture-infos-value">
                          {getName(proprietaire)}
                        </div>
                      </div>
                    )}
                    <div className="detail-facture-infos-container mb-3">
                      <div className="detail-facture-infos-name">
                        Loyer Hors Charges:
                      </div>
                      <div className="detail-facture-infos-value">
                        {formatMontant(item?.propriete?.prix_base)}
                      </div>
                    </div>
                    {!!entree && (
                      <div className="detail-facture-infos-container mb-3">
                        <div className="detail-facture-infos-name">
                          Charges Locatives:
                        </div>
                        <div className="detail-facture-infos-value">
                          {formatMontant(item?.charges)}
                        </div>
                      </div>
                    )}

                    <div className="detail-facture-infos-container mb-3">
                      <div className="detail-facture-infos-name">
                        Loyer Charges Comprises:
                      </div>
                      <div className="detail-facture-infos-value">
                        {formatMontant(item?.propriete?.prix)}
                      </div>
                    </div>

                    {!isProprietaireOwner(user) && !!entree && (
                      <div className="detail-facture-infos-container mb-3">
                        <div className="detail-facture-infos-name">
                          Part de l'agence:
                        </div>
                        <div className="detail-facture-infos-value">
                          {formatMontant(item?.agence_part)}
                        </div>
                      </div>
                    )}
                    {/* {!!entree && (
                      <div className="detail-facture-infos-container mb-3">
                        <div className="detail-facture-infos-name">
                          Part de Kanimmo:
                        </div>
                        <div className="detail-facture-infos-value">
                          {formatMontant(item?.admin_part)}
                        </div>
                      </div>
                    )} */}
                    {!!item?.justificatif && (
                      <div className="detail-facture-infos-container mb-3 row text-start">
                        <div className="detail-facture-infos-name col-md-12">
                          Justificatif:
                        </div>
                        <div className="detail-facture-infos-value">
                          <iframe
                            src={ApiBaseUrl + item?.justificatif}
                            frameBorder="0"
                            width={`100%`}
                            height={500}
                            allowFullScreen
                            seamless
                            title="doc"
                          ></iframe>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsEntreeModal;

const getMontant = (item: IEntree) => {
  let { montant, admin_part = 0, agence_part = 0, proprio_part = 0 } = item;
  // if (item?.type_entree === "caution" && item?.locataire?.id) {
  //   return item?.locataire?.montant_caution;
  // }
  // if (item?.type_entree === "mensualite") {
  //   return item?.propriete?.prix;
  // }

  return montant + admin_part + agence_part + proprio_part;
};

const getMontantLoyer = (item: IEntree) => {
  let {
    montant,
    charges = 0,
    admin_part = 0,
    agence_part = 0,
    proprio_part = 0,
  } = item;
  // if (item?.type_entree === "caution" && item?.locataire?.id) {
  //   return item?.locataire?.montant_caution;
  // }
  // if (item?.type_entree === "mensualite") {
  //   return item?.propriete?.prix;
  // }

  return montant + admin_part + agence_part + charges + proprio_part;
};
