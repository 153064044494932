import React from 'react'
import NewsletterTable from '../../../../tables/Admin/Administratif/Newsletter/NewsletterTable';

function Newsletter() {
    return (
        <div className="bg-white p-3 filter-and-table-container">
          <div>
            <NewsletterTable />
          </div>
        </div>
    );
}

export default Newsletter