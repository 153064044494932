import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  IAdmin,
  IAdminFormData,
} from "../../../../../../utils/api/admin/admin.type";
import {
  useAddOrUpdateAdminMutation,
  useEditAdminMutation,
} from "../../../../../../utils/api/admin/admin.api";
import { Color } from "../../../../../../utils/theme";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { onSetUser } from "../../../../../../redux/slice/User.slice";
import { cleannerError } from "../../../../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { Authorisations } from "../../../../../../utils/constant";

function UseCrudAdmin(user?: IAdmin) {
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Nom"),
    prenom: yup.string().required().label("Prenom"),
    telephone: yup.string().required().label("Téléphone"),
    email: yup.string().email().required().label("Email"),
  });
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<IAdminFormData>({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useAppDispatch();
  const [addOrUpdateUser, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdateAdminMutation();
  const { user: authUser } = useAppSelector((s) => s.user);
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState<string>();

  const onChangeAddress = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
  };

  useEffect(() => {
    if (isSuccess) {
      
      if (data?.id) {
        if (parseInt(authUser?.id) === data?.id) {
			console.log("data", data?.id === authUser?.id);
          dispatch(onSetUser(data));
        }
      }

      Swal.fire({
        icon: "success",
        title: !user
          ? "Admin ajouté avec succès"
          : "L'admin a été modifié avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate("/admin/options");
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (user?.id) {
		console.log("here")
      let fields: (keyof IAdminFormData)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "adresse",
        "dashboard",
        "message",
        "client",
        "lead",
        "options",
        "administratif",
        "recette",
        "new",
        "probleme",
        "faq",
        "type_requete",
      ];
      if (user?.user_type !== "admin") {
        fields = [
          "nom",
          "prenom",
          "telephone",
          "email",
          "adresse",
          "code_postal",
        ];
      }
      setAddress(user?.adresse);
      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
      }
    } else {
      Authorisations.forEach((item) => {
        setValue(item?.field, true);
      });
    }
  }, [user]);

  const onSubmit = (data: IAdminFormData) => {
  // console.log("data", data);
    addOrUpdateUser({
      slug: user?.slug,
      user_type: user?.user_type,
      data: data,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    address,
    onChangeAddress,
    phone,
    setPhone,
  };
}

export default UseCrudAdmin;
export function useToggleArchiveAdmin(user: IAdmin) {
  const [editData] = useEditAdminMutation();
  const onArchive = async () => {
    let data: IAdminFormData = {
      is_active: user.is_archive,
      is_archive: !user.is_archive,
    };
    await Swal.fire({
      title: `Êtes-vous sure de vouloir  ${
        user?.is_archive === true ? "désarchiver" : "archiver"
      } cet admin ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return editData({ slug: user.slug, data: data });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
    // console.log(result, "archiver");
      if (result?.value?.data) {
        Swal.fire({
          icon: "success",
          title: `Admin ${
            result?.value?.data?.is_archive ? "archivé " : "désarchivé"
          } avec succèss!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onArchive;
}
