import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ErrorMessage from "../../common/ErrorMessage";
import { BtnSubmit } from "../../common/Button";
import { useGetAgencesQuery } from "../../../utils/api/agence/agence.api";
import GoogleInput from "../../common/GoogleInput";
import { FormError } from "../../common/CustomInputCheckbox";

import "volkeno-react-country-state-city/dist/index.css";
import { RegisterStepPropsType } from "../Agence/AddInfoAgency";

// export type RegisterStepPropsType = {
//   navigation: any;
//   personnalInfo?: UserType;
//   setPersonnalInfo?: () => void;
//   register: any;
//   onSubmit: any;
//   setValue: any;
//   errors: any;
//   isLoading: boolean;
//   handelAvatarChange: (e: any) => void;
//   previewAvatar: any;
//   addressAgence: any,
//   onChangeAddressAgence: (e:any) => void,
// };

const AddInfoLocataire = ({
  register,
  setValue,
  errors,
  isLoading,
  addressAgence,
  onChangeAddressAgence,
  handleCountrySelect,
  handleStateSelect,
  country,
  state,
}: RegisterStepPropsType) => {
  const { data = { results: [] } } = useGetAgencesQuery({});

  // console.log("list agence", data?.results);
  const [propriete, setPropriete] = useState<
    { label: string; value: number; numero: string }[] | undefined
  >([]);
  const [number, setNumber] = useState<string | undefined>();
  const [showAutre, setShowAutre] = useState(false);

  const handleChangePropriete = (e: any) => {
    if (e.target.value) {
      const agences = data?.results
        ?.find((item) => item?.id === parseInt(e.target.value))
        ?.proprietes?.map((propriete) => {
          return {
            label: propriete?.nom,
            value: propriete?.id,
            numero: propriete?.numero,
          };
        });
      setPropriete(agences);
      if (e.target.value === "autres") {
        setShowAutre(true);
      } else {
        setShowAutre(false);
      }
    }
  };

  const handleChangeProprieteNumber = (e: any) => {
    if (e.target.value) {
      const numb = propriete?.find(
        (item) => item?.value === parseInt(e.target.value)
      );
      // console.log("numb", numb?.numero)
      return setNumber(numb?.numero);
    }
  };

  // console.log("data number", propriete)

  // const [country, setCountry] = useState<any>("");
  // const [state, setState] = useState<any>("");

  // const handleCountrySelect = (option: any) => {
  //   setCountry(option);
  //   setValue("pays", option.name);
  // };

  // const handleStateSelect = (option: any) => {
  //   if (option.label) {
  //     setState(option);
  //     setValue("region", option.label);
  //   }
  // };

  return (
    <div id="auth-form">
      <div className="row auth-form-row">
        {/* Si l'agence existe */}

        <div className="col-md-12 auth-input-col">
          <div className="form-group auth-form-group">
            <label className="form-label form-label-login">
              Choisissez votre agence <span style={{ color: "red" }}>*</span>
            </label>
            <select
              id="agence_id"
              className="form-select auth-form-select"
              {...register("agence_id")}
              onChange={handleChangePropriete}
            >
              <option value="" selected disabled>
                Choisissez votre agence
              </option>
              {data?.results?.map((item, index) => (
                <option value={item?.id} key={item?.slug}>
                  {item?.nom_agence}
                </option>
              ))}
              <option value="autres">
                Mon agence n’est pas sur cette liste
              </option>
            </select>
          </div>
        </div>
        {errors?.nom_agence && (
          <ErrorMessage message={errors?.nom_agence?.message} />
        )}
        <div className={showAutre ? "d-none" : "d-block"}>
          <div className="col-md-12 auth-input-col">
            <div className="form-group auth-form-group">
              <label className="form-label form-label-login">
                Immeuble/Villa
              </label>
              <select
                id="civilite"
                className="form-select auth-form-select"
                {...register("propriete")}
                onChange={handleChangeProprieteNumber}
              >
                <option value="" selected disabled>
                  Choisissez la propriété
                </option>
                {propriete &&
                  propriete?.length >= 0 &&
                  propriete?.map((pro) => (
                    <option value={pro?.value} key={pro?.value}>
                      {pro?.label}
                    </option>
                  ))}
              </select>
              {propriete && propriete?.length === 0 && (
                <small className="badge text-bg-info px-2">
                  Aucune propriété trouvée pour cette agence!
                </small>
              )}
              {errors?.propriete && (
                <ErrorMessage message={errors?.propriete?.message} />
              )}
            </div>
          </div>
          <div className="col-md-12 auth-input-col">
            <div className="form-group auth-form-group">
              <label className="form-label form-label-login">
                Numéro de l’appartement ou de la villa
              </label>
              <select
                id="civilite"
                className="form-select auth-form-select"
                {...register("numero")}
              >
                <option value="" selected disabled>
                  Sélectionner le numéro de l’appartement ou de la villa
                </option>
                <option value={number}>{number}</option>
              </select>
              {errors?.numero && (
                <ErrorMessage message={errors?.numero?.message} />
              )}
            </div>
          </div>

          <div className="col-md-12 auth-input-col">
            <div className="form-group auth-form-group">
              <label className="form-label form-label-login">
                Début du bail
              </label>
              <input
                type="date"
                className="form-control auth-form-control"
                id="date_debut"
                {...register("date_debut")}
              />
              {errors?.date_debut && (
                <ErrorMessage message={errors?.date_debut?.message} />
              )}
            </div>
          </div>
          <div className="col-md-12 auth-input-col">
            <div className="form-group auth-form-group">
              <label className="form-label form-label-login">Fin du bail</label>
              <input
                type="date"
                className="form-control auth-form-control"
                id="date_fin"
                {...register("date_fin")}
              />
              {errors?.date_fin && (
                <ErrorMessage message={errors?.date_fin?.message} />
              )}
            </div>
          </div>
        </div>

        {/* Si l'agence n'existe pas */}
        <div className={showAutre ? "d-block" : "d-none"}>
          <div className="col-md-12 auth-input-col">
            <div className="form-group auth-form-group">
              <label className="form-label form-label-login">
                Nom de l’agence
              </label>
              <input
                type="text"
                className="form-control auth-form-control"
                id="nom_agence"
                placeholder="Nom de l’agence"
                {...register("nom_agence")}
              />
              {errors?.nom_agence && (
                <ErrorMessage message={errors?.nom_agence?.message} />
              )}
            </div>
          </div>
          <div className="col-md-12 auth-input-col">
            <div className="form-group auth-form-group">
              <label className="form-label form-label-login">
                Adresse de l’agence immobilière
              </label>
              <GoogleInput
                className="form-control form-control-modal-custom form-control-params-custom"
                value={addressAgence}
                onChange={onChangeAddressAgence}
              />
              <FormError error={errors?.adresse_agence?.message} />
            </div>
          </div>
        </div>
        <div className="col-md-12 auth-input-col">
          <div className="form-group auth-form-group">
            <label htmlFor="pays" className="form-label form-label-login">
              Pays{" "}
            </label>
            <GoogleInput
              className="form-control auth-form-control"
              placeholder="Pays"
              value={country}
              types={["country"]}
              onChange={handleCountrySelect}
            />

            {errors?.pays && <FormError error={errors?.pays?.message} />}
          </div>
        </div>
        <div className="col-md-12 auth-input-col">
          <div className="form-group auth-form-group">
            <label htmlFor="region" className="form-label form-label-login">
              Région{" "}
            </label>
            <GoogleInput
              className="form-control auth-form-control"
              placeholder="Région"
              value={state}
              types={[
                "administrative_area_level_1",
                "administrative_area_level_2",
              ]}
              onChange={handleStateSelect}
            />

            {errors?.region && <FormError error={errors?.region?.message} />}
          </div>
        </div>

        {/* <div className="col-md-12 auth-input-col">
          <div className="form-group auth-form-group">
            <label className="form-label form-label-login">Pays</label>
            
            <CountrySelector
                onChange={handleCountrySelect}
                name="pays"
                placeholder="Pays"
                value={country}
                containerClass="tx country-select-container p-0"
              />
            {errors?.pays && <ErrorMessage message={errors?.pays?.message} />}
          </div>
        </div>
        <div className="col-md-12 auth-input-col">
          <div className="form-group auth-form-group">
            <label className="form-label form-label-login">Région</label>
            <StateSelector
                country={country}
                name="region"
                value={state}
                placeholder="Region"
                countryPlaceholder="Region"
                onChange={handleStateSelect}
                containerClass="tx country-select-container p-0"
              />
            
            {errors?.region && (
              <ErrorMessage message={errors?.region?.message} />
            )}
          </div>
        </div> */}
        <div className="col-md-12 auth-input-col">
          <div className="form-group auth-form-group">
            <label className="form-label form-label-login">Code postal</label>
            <input
              type="text"
              className="form-control auth-form-control"
              id="code_postal"
              placeholder="Code postal"
              {...register("code_postal")}
            />
            {errors?.code_postal && (
              <ErrorMessage message={errors?.code_postal?.message} />
            )}
          </div>
        </div>
        <div className="py-4 col-md-4 offset-md-4 auth-submit-btn-container">
          <BtnSubmit label="SUIVANT" isLoading={isLoading} />
        </div>
        <div className="flex-c align-items-center my-4 text-right-auth">
          <p className="text-inscription mb-0">Déjà inscrit?</p>
          <NavLink to="/connexion" className="btn-inscription-color ps-2">
            Connectez-vous
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default AddInfoLocataire;
