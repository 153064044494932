import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import "./DetailsAgence.css";
import Facebook from "../../../../assets/icons/facebook.png";
import Twitter from "../../../../assets/icons/twitter.png";
import Linkedin from "../../../../assets/icons/linkedin.png";
import Youtube from "../../../../assets/icons/youtube.png";
import Whatsapp from "../../../../assets/icons/Whatsapp.png";
import { AiFillEye } from "react-icons/ai";
import word from "../../../../assets/appImages/word.png";
import { MdLocationOn, MdMail } from "react-icons/md";
import { BsFillTelephoneFill, BsGlobe } from "react-icons/bs";
import ListeBiens from "./ListeBiens";
import {
  createMarkup,
  getAvatar,
  getImage,
  useLocationState,
} from "../../../../utils/Utils";
import { IAgence } from "../../../../utils/api/agence/agence.type";
import { AlertInfo } from "../../../common/Alert";
import { IPropriete } from "../../../../utils/api/propriete/propriete.type";

function DetailsAgence() {
  const navigate = useNavigate();
  const item = useLocationState<IAgence>(undefined);
// console.log("item", item);
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            <BiArrowBack onClick={() => navigate("/admin/mes-clients")} />
            Détail {item?.nom_agence}
          </h4>
        </div>
      </div>
      <DetailAgenceProprietaire
        banniere={item?.banniere}
        logo={item?.logo}
        name={item?.nom_agence}
        description={item?.description}
        telephone={item?.telephone}
        email={item?.email}
        whatsapp={item?.whatsapp}
        site={item?.site}
        facebook={item?.facebook}
        twitter={item?.twitter}
        linkedin={item?.linkedin}
        youtube={item?.youtube}
        proprietes={item?.proprietes}
        commission={item?.commission_admin}
      />
    </div>
  );
}

interface Props {
  banniere?: string;
  logo?: string;
  name?: string | null;
  description?: string;
  telephone?: string;
  email?: string;
  whatsapp?: string;
  site?: string;
  facebook?: string;
  twitter?: string;
  linkedin?: string;
  youtube?: string;
  proprietes?: IPropriete[];
  commission?: string | number
}
export function DetailAgenceProprietaire({
  banniere,
  logo,
  name,
  description,
  telephone,
  whatsapp,
  email,
  site,
  facebook,
  twitter,
  linkedin,
  youtube,
  proprietes,
  commission
}: Props) {
  return (
    <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
      <div className="tabs-component-container locataire-requete-filtre-container">
        <div className="kanimmo-dash-locataire-param-main-page-container ps-0 py-3">
          <div className="container-img-option-agence position-relative mb-4">
            <div className="container-img-couverture-option-agence">
              <div className="content-img-couverture">
                <img
                  src={getImage(banniere)}
                  alt="pp couverture"
                  className="img-couverture-option-agence"
                />
              </div>
            </div>
            <div className="container-logo-option-agence">
              <div className="content-logo">
                <img
                  src={getAvatar(logo)}
                  alt="pp couverture"
                  className="logo w-100"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
            {commission && (
              <div className="container-contrat-details-agence">
                {/* ========== NE PAS SUPPRIMER =========== */}

                {/* <div className="contrat-container">
                <div className="nom-imageword-container">
                  <img src={word} alt="img word" className="custom-img-word" />
                  <span className="custom-nom-contrat">Nom du contrat</span>
                </div>
                <div className="custom-contrat-icon">
                  <AiFillEye />
                </div>
              </div>
              <hr className="custom-divider" /> */}
                {commission && (
                  <div className="taux-container">
                    <div className="taux-remuneration-titre">
                      Taux de remunération
                    </div>
                    <div className="taux-remuneration">{commission}%</div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="row my-5 agence-infos-mine-container">
            <div className="col-md-12 mb-3">
              <h4 className="details-admin-titre-agence">{name}</h4>
              <hr className="custom-divider" />
              <div className="detail-agence-infos-sup-container">
                <div className="detail-agence-infos-sup-item">
                  <MdLocationOn className="detail-agence-infos-sup-icon" />
                  <span className="detail-agence-infos-sup-titre">{name}</span>
                </div>
                <div className="detail-agence-infos-sup-item">
                  <BsFillTelephoneFill className="detail-agence-infos-sup-icon" />
                  <span className="detail-agence-infos-sup-titre">
                    {telephone}{" "}
                    {!!whatsapp && whatsapp !== "null" && `/${whatsapp}`}
                  </span>
                </div>
                <div className="detail-agence-infos-sup-item">
                  <MdMail className="detail-agence-infos-sup-icon" />
                  <span className="detail-agence-infos-sup-titre">{email}</span>
                </div>
                {!!site && site !== "null" && (
                  <div className="detail-agence-infos-sup-item">
                    <BsGlobe className="detail-agence-infos-sup-icon" />
                    <span className="detail-agence-infos-sup-titre">
                      {site}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!!description && description !== "null" && (
            <div className="row mb-4">
              <div
                className="description-container"
                dangerouslySetInnerHTML={createMarkup(description)}
              />
            </div>
          )}
          <div className="row mb-4">
            <div className="btn-social-media-container">
              {!!facebook && facebook !== "null" && (
                <a
                  className="input-group-text input-group-text-img"
                  href={facebook}
                >
                  <img
                    src={Facebook}
                    alt="social media"
                    className="social-media-contact"
                  />
                </a>
              )}
              {!!twitter && twitter !== "null" && (
                <a
                  className="input-group-text input-group-text-img"
                  href={twitter}
                >
                  <img
                    src={Twitter}
                    alt="social media"
                    className="social-media-contact"
                  />
                </a>
              )}
              {!!linkedin && linkedin !== "null" && (
                <a
                  className="input-group-text input-group-text-img"
                  href={linkedin}
                >
                  <img
                    src={Linkedin}
                    alt="social media"
                    className="social-media-contact"
                  />
                </a>
              )}
              {!!youtube && youtube !== "null" && (
                <a
                  className="input-group-text input-group-text-img"
                  href={youtube}
                >
                  <img
                    src={Youtube}
                    alt="social media"
                    className="social-media-contact"
                  />
                </a>
              )}
              {!!whatsapp && whatsapp !== "null" && (
                <a
                  className="input-group-text input-group-text-img"
                  href={`https://wa.me/${whatsapp}`}
                >
                  <img
                    src={Whatsapp}
                    alt="social media"
                    className="social-media-contact"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="row mb-5">
            <h5 className="custom-titre-biens-geres">Biens gérés</h5>
            {proprietes?.length ? (
              <div className="listes-proprietes">
                <ListeBiens proprietes={proprietes} />
              </div>
            ) : (
              <AlertInfo message="Aucun bien trouvé!" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsAgence;
