import React, { useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import {
  ILitiges,
  LitigesFormData,
  StatusLitiges,
  TypesLitiges,
} from "../../../utils/api/litiges/litiges.type";
import {
  cleannerError,
  isAgenceJuriste,
  isProprioJuriste,
  onHide,
} from "../../../utils/Utils";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import { Input } from "../../common/Input";
import { SelectInput } from "../../common/SelectInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEditLitigeMutation } from "../../../utils/api/litiges/litiges.api";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { useAppSelector } from "../../../redux/hooks";

function AjoutLitigesDocumentModal({
  item,
  modalId,
}: {
  item?: ILitiges;
  modalId: any;
}) {
  const validationSchema = yup.object().shape({
    statut: yup.string().required().nullable().label("Status"),
    type_litige: yup.string().nullable().label("Type de litige"),
    constat: yup.string().required().nullable().label("Constat"),
    document: yup.mixed().label("Le document").nullable(),
  });

  const { user } = useAppSelector((s) => s.user);

  const optionsStatutLitige = [
    {
      label: "Assignation d’Expulsion",
      value: TypesLitiges.assignationExpulsion,
    },
    {
      label: "Commandement de payer",
      value: TypesLitiges.commandementPayer,
    },
    {
      label: "Jugement",
      value: TypesLitiges.jugement,
    },
    {
      label: "Non régler",
      value: StatusLitiges.nonRegler,
    },
    {
      label: "En cours de traitement",
      value: StatusLitiges.enCours,
    },
    {
      label: "Régler",
      value: StatusLitiges.regler,
    },
    {
      value: "revision",
      label: `${
        isAgenceJuriste(user) || isProprioJuriste(user)
          ? "Pour révision"
          : "Envoyé pour révision par le juriste"
      }`,
    },
  ];

  const [addJustificatif, { isSuccess, isError, error, isLoading }] =
    useEditLitigeMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm<LitigesFormData>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (item?.id) {
      setValue("slug", item?.slug);
      setValue("statut", item?.statut);
      setValue("type_litige", item?.type_litige);
    }
  }, [item]);

  useEffect(() => {
    if (errors) {
      return cleannerError(errors, clearErrors);
    }
  }, [errors]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Ajout de justificatif réussi !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        reset();
        onHide(modalId);

        // onHide("ChangeStatusRequeteModal");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: `${err ? err?.message : "Une erreur s'est produite"}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  const [constat, setConstat] = useState<string>("");
  const handleChangeConstat = useCallback((value: string) => {
    setConstat(value);
    setValue("constat", value);
  }, []);

  const [document, setDocument] = useState<File>();
  const handleChangeDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null) {
      setValue("document", e.currentTarget.files[0]);
      setDocument(e.currentTarget.files[0]);
    }
  };

  const onSubmit = (data: any) => {
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    // console.log(data);
    addJustificatif({ slug: data?.slug, data: fd });
  };
  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Ajouter un justificatif
          </h5>
        </div>
        <div className="modal-body">
          <div className="container-form pt-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                {/* <div className="col-12">
                  <SelectInput
                    label="Type litige"
                    required
                    options={optionsTypeLitige}
                    {...register("type_litige")}
                    error={errors.type_litige?.message}
                  />
                </div> */}
                <div className="col-12">
                  <SelectInput
                    label="Statut"
                    required
                    options={optionsStatutLitige}
                    {...register("statut")}
                    error={errors.statut?.message}
                  />
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="description"
                      className="form-label form-label-modal-custom"
                    >
                      Note{" "}
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    <ReactQuill
                      className="editeur-react-quill"
                      value={constat}
                      onChange={handleChangeConstat}
                    />
                    <FormError error={errors?.description?.message} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label
                      htmlFor="rue"
                      className="form-label form-label-modal-custom"
                    >
                      Fichier
                    </label>
                    <input
                      type="file"
                      className="form-control form-control-modal-custom"
                      id="rue"
                      onChange={handleChangeDocument}
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                    {/* <FormError error={errors.mandat} /> */}
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row mt-5">
                <div className="col-md-3">
                  <button
                    className="btn btn-cancelled"
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-md-6 offset-md-3 flex-r">
                  <BtnSubmit label="Ajouter" isLoading={false} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutLitigesDocumentModal;
