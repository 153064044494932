import { ChangeEvent, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import {
  AccountInfo,
  AccountInfoFormData,
} from "../../../../../utils/api/accountInfo/accountInfo.type";
import {
  useAddOrUpdateAccountInfoMutation,
  useGetAccountInfoByUserQuery,
} from "../../../../../utils/api/accountInfo/accountInfo.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { Color } from "../../../../../utils/theme";
import { clean, cleannerError, onHide } from "../../../../../utils/Utils";
import { useAddOrEditUserMutation } from "../../../../../utils/api/user/user.api";

function UseAddOrUpdateAccountInfo() {
  const validationSchema = yup.object().shape({
    isCart: yup.boolean(),
    nom: yup.string().required().label("Nom").nullable(),
    prenom: yup.string().required().label("Prénom").nullable(),
    email: yup.string().email().required().label("Email").nullable(),
    telephone: yup.string().required().label("Téléphone").nullable(),
    rib: yup
      .string()
      .when("isCart", {
        is: true,
        then: yup.string().required().label("RIB"),
      })
      .nullable(),
    mode: yup.string().required().label("Le mode de paiement"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<AccountInfoFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [info, setInfo] = useState<AccountInfo | undefined>();
  const [sendDataFirst_conection] = useAddOrEditUserMutation();
  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdateAccountInfoMutation();
  let user = useAppSelector((s) => s?.user?.user);
  const { data: accountInfo = { results: [], count: 0 } } =
    useGetAccountInfoByUserQuery({
      slug: user?.slug,
      type: user?.user_type,
    });
  const [phone, setPhone] = useState<string>();
  const [mode, setMode] = useState<string>("");

  const handleChangeMode = (e: ChangeEvent<HTMLSelectElement>) => {
    setMode(e.target.value);
    setValue("mode", e.target.value);
    if (e.target.value === "BANK_TRANSFER_SN_API_CASH_IN") {
      setValue("isCart", true);
    } else {
      setValue("isCart", false);
    }
  };

  useEffect(() => {
    // console.log("data", accountInfo);
    if (accountInfo?.count && accountInfo?.count > 0) {
      let item: any = accountInfo?.results?.find((el) => el?.user === user?.id);
      const fields: (keyof AccountInfoFormData)[] = [
        "nom",
        "prenom",
        "email",
        "telephone",
        "mode",
        "rib",
      ];
      if (item) {
        setInfo(item);
        for (let field of fields) {
          register(field);
          setValue(field, item[field]);
        }
        if (item?.mode === "BANK_TRANSFER_SN_API_CASH_IN") {
          setValue("isCart", true);
        } else {
          setValue("isCart", false);
        }
        setPhone(item?.telephone);
        setMode(item?.mode);
      }
    }
  }, [accountInfo, user]);

  useEffect(() => {
    if (isSuccess) {
      console.log("data", data);
      if (user?.first_connexion) {
        sendDataFirst_conection({
          slug: user?.slug,
          userType: user?.user_type,
          data: { first_connexion: false },
        });
      }
      Swal.fire({
        icon: "success",
        title: `Informations ${info ? "modifiées" : "ajoutées"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
      onHide("AccountInfoModal");
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading, isSuccess, isError]);

  useEffect(() => {
    if (user?.id && accountInfo?.count === 0) {
      const fields: (keyof AccountInfoFormData)[] = [
        "nom",
        "prenom",
        "email",
        "telephone",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
      }
      if (
        (user?.telephone?.substring(0, 2) === "77" ||
          user?.telephone?.substring(0, 2) === "76" ||
          user?.telephone?.substring(0, 2) === "78" ||
          user?.telephone?.substring(0, 2) === "70" ||
          user?.telephone?.substring(0, 2) === "75") &&
        !user?.telephone?.includes("221")
      ) {
        setPhone("221" + user?.telephone);
      } else {
        setPhone(user?.telephone);
      }
    }
  }, [user, accountInfo?.count]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);
  const onSubmit = async (data: any) => {
    let dataSend = clean(data);

    dataSend["user"] = user?.id;
    // console.log("data",dataSend)
    await sendData({ slug: dataSend?.slug, data: dataSend });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    phone,
    setPhone,
    mode,
    handleChangeMode,
  };
}

export default UseAddOrUpdateAccountInfo;
