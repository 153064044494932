import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { AiFillPlusSquare } from "react-icons/ai";
import { showModal } from "../../../../utils/Utils";
import { useDelete } from "../../../../utils/helpers";
import FaqAdminModal from "./FaqAdminModal";
import { useFaqsQuery , useDeleteFaqMutation} from "../../../../utils/api/faq/faq.api";
import { CustomPagination } from "../../../common/CustomPagination";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import { FaqFormData } from "../../../../utils/api/faq/faq.type";
import { formatAmount } from "../../../../utils/Utils";
import { useSearchParams } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";













function FaqAdmin() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
    const [perPage, setPerPage] = useState(10);
  const { data, isLoading, isError, error } = useFaqsQuery({page: page,
    limit: perPage,});
    useEffect(() => {
    }, [data, isLoading, isError, error]);
    
    const [searchParams, setSearchParams] = useSearchParams();
    const question = searchParams.get("question");
    const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
      if (e.currentTarget.value) {
        setSearchParams({
          question: e.currentTarget.value,
        });
      } else {
        setSearchParams({});
      }
    };

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0 bg-white">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">Faq</h4>
          <div className="container-btn-add ms-auto">
            <>
              <button className="btn btn-see-more"
               onClick={() => showModal("AddTypeFaqModal")}
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter un faq</span>
              </button>
              <div
                className="modal fade"
                id="AddTypeFaqModal"
                aria-labelledby="AddTypeProblemeModalLabel"
                aria-hidden="true"
              >
                <FaqAdminModal/>
              </div>
              </>
            </div>
        </div>
        <div className="filter-and-table-container filter-and-table-container-lead-admin ms-5 me-4 mt-3">
          <div className="news-list-container pb-5">
            <div className="content-title-page-faq text-center">
              <div className="d-flex mt-3 mb-4">
                <div className="search-box-form-container">
                  <form id="search-box-form">
                    <div className="search-box-form-content">
                      <div className="search-box-input-group">
                        <input
                          placeholder="Rechercher ici"
                          type="search"
                          className="form-control input-search-form-control"
                          onChange={handleFilterByName}
                        />
                        <div className="search-box-form-icon-container">
                          <FiSearch className="search-box-form-icon" />
                        </div>
                        
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {data?.results.map((faq) => (
            <div className="content-page-faq mt-5">
              <div className="mb-3">
                <div className="collapse-faq-item px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
                  <div className="row w-100 align-items-center">
                    <div className="col-md-9">
                      <div
                        className="btn-title-collapsed-faq btn p-0"
                        style={{
                          textOverflow: "ellipsis",
                        }}
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapsefaq${faq.id}`}
                        aria-expanded="false"
                        aria-controls="collapsefaq3"
                      >
                        {faq.question}
                      </div>
                    </div>
                    <div className="col-md-3 flex-r">
                      <p className="admin-message-contact-card-description mb-0">
                      {faq.user}
                      </p>

                      <div className="table-actions-btn-container-commandes gap-2 d-flex">
                      <button
                        className="btn btn-action-modal-icon with-tooltip"
                        data-tooltip-content="Modifier"
                        // data-bs-toggle="modal"
                        // data-bs-target={`#AddTypeProblemeModal${row?.slug}`}
                        onClick={() => showModal(`AddTypeFaqModal${faq?.slug}`)}
                      >
                        <MdEdit />
                      </button>
                      <Deletefaq item={faq}/>

                      <div
                        className="modal fade"
                        id={`AddTypeFaqModal${faq.slug}`}
                        aria-labelledby={`#AddTypeFaqModal${faq?.slug}Label`}
                        aria-hidden="true"
                      >
                        <FaqAdminModal item={faq} />
                      </div>
                    </div>

                    </div>
                  </div>
                  <button
                    className="no-style-btn accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapsefaq${faq.id}`}
                    aria-expanded="false"
                    aria-controls="collapsefaq3"
                  >
                    <BiChevronDown />{" "}
                  </button>
                </div>
                <div className="collapse" id={`collapsefaq${faq.id}`}>
                  <div className="card card-body card-body-text-collapse border">
                  {faq.reponse}
                  </div>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>

    <>            
      {!isLoading && (
        <>
          {/* <BootstrapTable
            keyField="id"
            bordered={true}
            condensed={false}
            responsive
            rowStyle={{ cursor: "pointer" }}
          /> */}
          <CustomPagination
            nbPages={data?.count}
            page={page}
            perPage={limit}
            onChange={(page, perPage) => {
              setLimit(perPage);
              setPage(page);
            }}
          />
        </>
      )}
    </>
    </div>
  );

 
}


export default FaqAdmin;

export function Deletefaq({ item }: { item: FaqFormData }) {
  const [deleteItem, { isSuccess, isError, error }] =
  useDeleteFaqMutation();
  const onDelete = useDelete<FaqFormData>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce type de problème?",
    successMessage: "Type de problème supprimé",
  });
  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <IoMdTrash />
    </button>
  );
}
