import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Logo from "../../../assets/appImages/logo.png";
import Avatars from "../../../assets/appImages/Avatars.png";
import { BtnUnsubscribe } from "../../common/Button";
import { UseDesabonnerUserNewsletter } from "./useNewsLetter";


function UnsubscribeNewsletter() {
  const navigate = useNavigate();
  const { email } = useParams<{ email: string }>();


  return (
    <div className="auth-component">
      <div className="fixed-login-component">
        <div className="auth-container">
          <div className="auth-row row">
            <div className="col-md-6 offset-md-3 auth-col auth-right-side-col">
              <div className="auth-right-side-container">
                <div className="auth-form-container">
                  <div className="flex-c py-4">
                    <NavLink className="navbar-brand" to="/">
                      <img src={Logo} alt="Logo" className="logo-app" />
                    </NavLink>
                  </div>
                  <div className="unsubscribe-user-infos-container">
                    <img
                      src={Avatars}
                      alt="user avatar"
                      className="unsubscrib-user-avatar"
                    />
                    <div className="unsubscribe-user-name-container">
                      {/* <div className="unsubscribe-user-name">Djibril</div> */}
                      <div className="unsubscribe-user-email">
                        {email}
                      </div>
                    </div>
                  </div>
                  <div className="unsubscribe-newsletter-custom-title-container mb-3">
                    <div>se désabonner de</div>
                    <h1 className="unsubscribe-newsletter-custom-title">
                      {" "}
                      Newsletter hebdomadaire et mises à jour par e-mail de
                      Kanimmo.
                    </h1>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    {/* <BtnUnsubscribe label="Confirmer" isLoading={false}/> */}
                    <DesabonnerUser email={`${email}`} />
                    <button
                      className="btn cancel-unsubscribe-submit-btn"
                      onClick={() => navigate('/')}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnsubscribeNewsletter;

function DesabonnerUser({ email }: { email: string }) {

  return (
    <button
      className="btn unsubscribe-submit-btn"
      onClick={UseDesabonnerUserNewsletter(email)}
    >
      Confirmer
    </button>
  );
}
