import React from "react";
import { AiFillPlusSquare } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import { Button } from "reactstrap";
import AjouterImmeubleModal from "../../../../modal/agence/AjouterImmeubleModal";
import ImmeublesProprietaireTable from "../../../../tables/Proprietaire/ProprietaireGererParProprietaire/Immeubles/ImmeublesProprietaireTable";

function ImmeublesProprietaire() {
	const [searchParams, setSearchParams] = useSearchParams();
	const name = searchParams.get("name");
	const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
		if (e.currentTarget.value) {
			setSearchParams({
				name: e.currentTarget.value,
			});
		} else {
			setSearchParams({});
		}
	};
  return (
		<div className="landing-page-container">
			<div className="admin-page-title-header-container">
				<div className="kanimmo-dash-locataire-requetes-header mb-3">
					<h4 className="kanimmo-dash-locataire-requetes-header-titre">
						Mes Immeubles
					</h4>
                    <div className="container-btn-add">
                        <Button
                            data-bs-toggle="modal"
                            data-bs-target="#AjouterImmeubleModal"
                            className="btn btn-see-more"
                        >
                            <AiFillPlusSquare style={{ fontSize: 22 }} />
                            <span className="ps-2">Ajouter un immeuble</span>
                        </Button>
					</div>
				</div>
				<div className="kanimmo-proprietes-container">
					<div className="proprietes-component-container">
					<div className='d-flex gap-3'>
						<div className='locataire-requete-filtre-container my-4'>
							<div className="row g-3 locataire-requete-filtre-form">
								<div className="col-auto">
									<label
										htmlFor="filtreLogement"
										className="locataire-requete-filtre-label"
									>
										Filtrer
									</label>
								</div>
								<div className="col-auto">
									<input
										type="text"
										className="form-control locataire-requete-filtre-input"
										id="filtreLogement"
										placeholder="Entrer un nom"
										onChange={handleFilterByName}
									/>
								</div>
							</div>
						</div>
					</div>
						<div className="content-proprietes mt-5">
							<ImmeublesProprietaireTable
                                name={name}
                            />
						</div>
                        <div className="modal fade" id="AjouterImmeubleModal" aria-labelledby="AjouterImmeubleModalLabel" aria-hidden="true">
                            <AjouterImmeubleModal modalId='AjouterImmeubleModal' />
                        </div>
					</div>
				</div>
			</div>
		</div>
  );
}

export default ImmeublesProprietaire;