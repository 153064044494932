export interface ICondition {
    id: number
    slug: string,
    text: string,
    type: string
    titre: string
}

export type ConditionFormdata = {
    id: number
    slug: string,
    text: string,
    type: string
    titre: string
}

export enum ConditionType {
    condition_utilisation = 'condition_utilisation',
    besoin_compte = 'besoin_compte',
    contenu = 'contenu',
    droit_service = 'droit_service',
    droit_auteur = 'droit_auteur',
    ne_pas_faire = 'ne_pas_faire',
    suppression_compte = 'suppression_compte',
    avis_non_responsabilite = 'avis_non_responsabilite',
    diffusion_annonce = 'diffusion_annonce',
    condition_generale = 'condition_generale',
  }