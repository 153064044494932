import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../../utils/Utils";
import { CommentFormData, INews } from "../../../../../utils/api/new/news.type";
import {
	useLazyFindNewsBySlugQuery,
	usePostCommentMutation,
} from "../../../../../utils/api/new/news.api";
import { useAppSelector } from "../../../../../redux/hooks";

export function usePostComment(item?: INews, isConnected?: boolean) {
	const validationSchema = yup.object().shape({
		content: yup.string().required("Ce champ est obligatoire"),
		prenom: yup.string(),
		nom: yup.string(),
		email: yup.string(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
		setValue,
	} = useForm<CommentFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [comment, setComment] = useState<string>();
	const [postComment, { isLoading, isSuccess, error, isError }] = usePostCommentMutation();
	const { user: authUser } = useAppSelector((s) => s?.user);
	const [findBySlug] = useLazyFindNewsBySlugQuery();

	const handleChangeCommentField = (value: any) => {
		setComment(value);
		setValue("content", value);
	};
	useEffect(() => {
		if (isSuccess) {
			findBySlug(item?.slug as string);
			setComment("");
			setValue("content", "");
			reset()
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (data: CommentFormData) => {
		const newData = { ...data, new: item?.id as number, auteur: isConnected ? authUser?.id as number : null };
		console.log("data", newData);
		postComment(newData);
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		reset,
		handleChangeCommentField,
		comment,
	};
}
