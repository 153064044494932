import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import EntreeComptabiliteTableSkeleton from "../../agence/EntreeComptabiliteTable/EntreeComptabiliteTableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useAppSelector } from "../../../../redux/hooks";
import { AlertInfo } from "../../../common/Alert";
import { useDeleteEntreeMutation } from "../../../../utils/api/entree/entree.api";
import { ILocataire } from "../../../../utils/api/locataire/locataire.type";
import { formatMontant, formattedDate, getName } from "../../../../utils/Utils";
import { IEntree } from "../../../../utils/api/entree/entree.type";
import AjouterEntreeModal from "../../../modal/comptabilite/AjouterEntreeModal";
import { CustomPagination } from "../../../common/CustomPagination";
import { IoMdTrash } from "react-icons/io";
import { useDelete } from "../../../../utils/helpers";
import DetailsEntreeModal from "../../../modal/agence/DetailsEntreeModal";

function EntreesTableComptable({
  data,
  isLoading,
  perPage,
  page,
  setPage,
  setPerPage,
  count,
}: {
  data: IEntree[];
  isLoading: boolean;
  perPage: number;
  page: number;
  setPage: any;
  setPerPage: any;
  count?: number;
}) {
  // const [page, setPage] = useState(1);
  // const [perPage, setPerPage] = useState(10);
  const comptabble = useAppSelector((s) => s.user.user);
  // const { data = { results: [], count: 0 }, isLoading } =
  //   useGetEntreeByComptableBySlugQuery({
  //     slug: comptabble?.slug,
  //     page: page,
  //     limit: perPage,
  //   });
  // console.log({ data });
  const [item, setItem] = useState<IEntree>();

  const actionFormatter = (cell: any, row: IEntree) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex">
          <div className="container-btn-action-icon-modal d-flex">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              data-bs-toggle="modal"
              data-bs-target={`#ModifierEntreeModal${row?.slug}`}
              //   onClick={() => setItem(row)}
            >
              <MdEdit />
            </button>
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              data-bs-toggle="modal"
              data-bs-target={`#detailsEntreeModal${row?.slug}`}
              //   onClick={() => setItem(row)}
            >
              <AiFillEye />
            </button>
            <DeleteEntree item={row} />
          </div>
          {/* <div>
            <DetailEntreeModal entree={row} />
          </div> */}
          <div
            className="modal fade"
            id={`detailsEntreeModal${row?.slug}`}
            aria-labelledby={`detailsEntreeModal${row?.slug}Label`}
            aria-hidden="true"
          >
            <DetailsEntreeModal
              item={row}
              modalId={`detailsEntreeModal${row?.slug}`}
              entree
            />
          </div>
          <div
            className="modal fade"
            id={`ModifierEntreeModal${row?.slug}`}
            aria-labelledby="AjoutEntreeModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
          >
            <AjouterEntreeModal
              modalId={`ModifierEntreeModal${row?.slug}`}
              entree={row}
            />
          </div>
          <div>
            {/* <ModifierEntreeModal
              modalId={`#ModifierEntreeModal${cell}`}
              entree={row}
            /> */}
          </div>
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "id",
      text: "N°",
      formatter: (cell: any, row: any, rowIndex: number) => `#${rowIndex + 1}`,
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: string) => formattedDate(cell),
    },
    {
      dataField: "locataire",
      text: "Client",
      formatter: (cell: ILocataire) => getName(cell),
    },
    {
      dataField: "montant",
      text: "Montant",
      formatter: (cell: any) => formatMontant(cell),
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: IEntree) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "130px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <EntreeComptabiliteTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data
              ?.slice()
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          {count && (
            <CustomPagination
              nbPages={1}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          )}
        </>
      )}
    </>
  );
}

export default EntreesTableComptable;

export function DeleteEntree({ item }: { item: IEntree }) {
  const [deleteItem, { isSuccess, isError, error }] = useDeleteEntreeMutation();
  const onDelete = useDelete<IEntree>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cette dépense?",
    successMessage: "dépense supprimée",
  });
  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <IoMdTrash />
    </button>
  );
}
