import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TagsAdminSkeleton from "./TagsAdminSkeleton";
import { BiEditAlt } from "react-icons/bi";
import { IoMdTrash } from "react-icons/io";
import { CustomPagination } from "../../../common/CustomPagination";
import { useDeleteTagMutation, useGetTagsQuery } from "../../../../utils/api/Tag/tag.api";
import AddTagModal from "../../../modal/admin/AddTagModal";
import { ITag } from "../../../../utils/api/Tag/tag.type";
import { useDelete } from "../../../../utils/helpers";
import { formattedDate } from "../../../../utils/Utils";

function TagsAdminTable() {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { data, isLoading } = useGetTagsQuery({ limit: perPage, page });
	const [item, setItem] = useState<ITag>();

	const actionFormatter: any = (cell: string, row: ITag) => {
		return (
			<div className="table-actions-btn-container-commandes gap-1 d-flex">
				<button
					className="btn btn-action-modal-icon with-tooltip"
					data-tooltip-content="Modifier"
					data-bs-toggle="modal"
					data-bs-target="#AddTagModal"
					onClick={() => setItem(row)}
				>
					<BiEditAlt />
				</button>
				<DeleteTag item={row} />
			</div>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: "Tag",
			style: { textAlign: "left" },
		},
		{
			dataField: "created_at",
			text: "Date d’ajout",
			style: { textAlign: "left" },
			formatter: (cell: string, row: ITag) => formattedDate(cell),
		},
		{
			dataField: "slug",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: ITag) => actionFormatter(cell, row),
			headerStyle: () => {
				return { width: "80px", whiteSpace: "normal" };
			},
		},
	];

	return (
		<>
			{isLoading && <TagsAdminSkeleton />}
			{!isLoading && data?.results && data?.results && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive
						wrapperClasses="table-responsive locataire-table"
						noDataIndication={() => "Aucune donnée disponible"}
					/>
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={perPage}
					/>
				</>
			)}
			<AddTagModal item={item} />
		</>
	);
}

export default TagsAdminTable;

export function DeleteTag({ item }: { item: ITag }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteTagMutation();
	const onDelete = useDelete<ITag>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "ce tag?",
		successMessage: "Tag supprimé",
	});
	return (
		<button
			className="btn btn-action-administratif-proprietaire with-tooltip"
			data-tooltip-content="Supprimer"
			onClick={onDelete}
		>
			<IoMdTrash />
		</button>
	);
}
