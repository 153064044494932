import moment from "moment";
import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useGetLitigeByLocataireQuery } from "../../../../../../utils/api/litiges/litiges.api";
import {
  ILitiges,
  TypesLitiges,
} from "../../../../../../utils/api/litiges/litiges.type";
import { createMarkup } from "../../../../../../utils/Utils";
import { AlertInfo } from "../../../../../common/Alert";
import Pagination from "../../../../../common/Pagination";
import { LitigeSkeleton } from "../../../../Agence/Locataires/ListeLocatairesTabs/ListeLitiges";

const Status = [
  {
    label: "Assignation d’Expulsion",
    value: TypesLitiges.assignationExpulsion,
  },
  {
    label: "Commandement de payer",
    value: TypesLitiges.commandementPayer,
  },
  {
    label: "Jugement",
    value: TypesLitiges.jugement,
  },
  {
    value: "non_regler",
    label: "Non réglée",
  },
  {
    value: "encours",
    label: "En cours de traitement",
  },
  {
    value: "regler",
    label: "Réglée",
  },
  {
    value: "revision",
    label: "Envoyé pour révision par le juriste",
  },
];
function LitigesTabs() {
  const [page, setPage] = useState(1);
  const { user } = useAppSelector((s) => s.user);
  const { data = { results: [] }, isLoading } = useGetLitigeByLocataireQuery({
    page,
    limit: 10,
    slug: user?.slug,
  });
  return (
    <div className="bg-white py-3 px-1">
      <div className="locataire-requete-filtre-container my-4">
        <div className="row">
          {/* <Facturestable /> */}
          <div className="bg-white py-3 pe-3">
            <div className="container-liste-litiges">
              {!isLoading && data?.results?.length === 0 && (
                <AlertInfo message="Aucune donnée trouvéé" />
              )}
              {!isLoading &&
                data?.results?.length > 0 &&
                data?.results?.map((item) => (
                  <React.Fragment key={item?.id}>
                    <LitigeItem item={item} />
                  </React.Fragment>
                ))}
              {isLoading &&
                [...Array(6)].map((item, index) => {
                  return <LitigeSkeleton key={index} />;
                })}
              {/* {data?.results?.length > perPage && ( */}
              <div className="flex-r">
                <Pagination
                  page={page}
                  total={data?.count}
                  perPage={10}
                  onPageChange={(page: number) => setPage(page)}
                />
              </div>
              {/* )} */}
              <div
                className="modal fade"
                id="ChangeStatusRequeteModal"
                aria-labelledby="ChangeStatusRequeteModalLabel"
                aria-hidden="true"
              >
                {/* <ChangeTypeLitigeModal
                  modalId={"ChangeStatusRequeteModalLabel"}
                  item={item}
                /> */}
              </div>
              <div
                className="modal fade"
                id={`ChangeStatusLitigeModal`}
                aria-labelledby="ChangeStatusLitigeModalLabel"
                aria-hidden="true"
              >
                {/* <ChangerStatutLitigeModal
                  modalId={`ChangeStatusLitigeModal`}
                  item={item}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LitigesTabs;

const LitigeItem = ({ item }: { item: ILitiges }) => {
  const [perPage, setPerPage] = useState(10);
  const agence = useAppSelector((s) => s.user.user);

  // const [item, setItem] = useState<ILitiges>();

  // console.log("liste litiges", data);

  return (
    <div
      className={`content-list-litiges custom-position-litige-container mb-2 ${
        item?.type_litige === "assignation_expulsion"
          ? "content-list-litiges-assignation"
          : item?.type_litige === "commandement_payer"
          ? "content-list-litiges-commandement"
          : "content-list-litiges-jugement"
      }`}
    >
      <div className="row align-items-center">
        <div className="col-md-12">
          <div className="table-actions-btn-container-commandes custom-btn-action-position gap-2 d-flex">
            {/* {isAgenceJuriste(user) && ( */}
            <div>
              <NavLink
                to={`/locataire/litige/${item?.slug}`}
                className="btn btn-action-modal-icon"
                title="Voir"
                state={item}
              >
                <AiFillEye />
              </NavLink>
            </div>
            {/* )} */}
            {/* <DeleteLocataireBtn item={item} /> */}
          </div>

          <div className="custom-btn-statut-pqgsb">
            <button
              className={`btn message-btn ${
                item?.statut === "non_regler"
                  ? "bg-secondary"
                  : item?.statut === "regler"
                  ? "bg-success"
                  : "bg-secondary"
              }`}
            >
              {Status?.find((val) => val?.value === item?.statut)?.label}
            </button>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 md-pb-3 d-flex">
          <div className="content-date-litige">
            <div className="content-value-litige">
              <p className="value-litige">{moment(item?.created_at).format("DD/MM/YYYY à HH:mm")}</p>
            </div>
            <div className="content-libelle-litige">
              <p className="libelle-litige mb-0">Date d’ajout</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 md-pb-3 d-flex">
          <div className="content-date-litige">
            <div className="content-value-litige">
              <p className="value-litige">
                {item?.agence?.id
                  ? item?.agence?.prenom + " " + item?.agence?.nom
                  : item?.proprietaire?.id
                  ? item?.proprietaire?.prenom + " " + item?.proprietaire?.nom
                  : ""}
                {/* {item?.locataire?.prenom} {item?.locataire?.nom} */}
              </p>
            </div>
            <div className="content-libelle-litige">
              <p className="libelle-litige mb-0">
                {item?.agence?.id
                  ? "Agence"
                  : item?.proprietaire?.id
                  ? "propriètaire"
                  : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 md-pb-3 d-flex">
          {item?.description && (
            <div className="content-date-litige">
              <div className="content-value-litige mb-3">
                <div
                  className="custom-envoie-note-description"
                  dangerouslySetInnerHTML={createMarkup(
                    item?.description?.substring(0, 100)
                  )}
                />
              </div>
              <div className="content-libelle-litige mb-2">
                <p className="libelle-litige mb-0">Note</p>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-3 col-md-6 md-pb-3 flex-c">
          <div className="content-date-litige">
            <div className="content-value-litige">
              <p className="value-litige-action">{item?.titre}</p>
            </div>
            <div className="content-libelle-litige">
              <p className="libelle-litige mb-0">Titre</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
