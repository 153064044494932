import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { Pagination } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import RecetteAdminSkeleton from "./RecetteAdminSkeleton";
import adminTableProfil from "../../../../assets/appImages/adminTableProfil.png";
import { FiEye } from "react-icons/fi";
import RecetteAdminDatas from "./RecetteAdminDatas";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";

import {
  Abonnement,
  IUser,
  PaginationResults,
} from "../../../../utils/api/user/user.type";
import { formatMontant, getAvatar, getName } from "../../../../utils/Utils";
import { NavLink } from "react-router-dom";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import { IAdminRecette } from "../../../../utils/api/admin/admin.type";
import DetailRecetteModal from "../../../modal/admin/DetailRecetteModal";
import { Offer } from "../../../../utils/api/offer/offer.type";
import DetailsReceiptModal from "../../../modal/admin/DetailsReceiptModal";

function RecetteAdminTable({
  data,
  isLoading,
  page,
  setPage,
  limit,
  setLimit,
}: {
  data: PaginationResults<Abonnement>;
  isLoading: boolean;
  page: number;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
}) {
  // const [page, setPage] = useState(1);
  // const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  const [item, setItem] = useState<IAdminRecette>();

  const actionFormatter: any = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes gap-2 d-flex">
          <button
            className="btn btn-action-modal-icon with-tooltip"
            data-tooltip-content="Voir"
            data-bs-toggle="modal"
            data-bs-target={`#DetailsReceiptModal${row?.slug}`}
            // onClick={() => {
            // 	setItem(row)
            // }}
          >
            <AiOutlineEye />
          </button>
          {/* <button
					className="btn btn-action-modal-icon with-tooltip"
					data-tooltip-content="Modifier"
					data-bs-toggle="modal"
					data-bs-target="#AjouterFactureModal"
					onClick={() => {
						setItem(row)
					}}
				>
					<MdEdit />
				</button> */}
          {/* <DeleteFacture item={row} /> */}
          <div
            className="modal fade"
            id={`DetailsReceiptModal${row?.slug}`}
            aria-labelledby={`DetailsReceiptModal${row?.slug}Label`}
            aria-hidden="true"
          >
            <DetailsReceiptModal item={row} />
          </div>
          {/* <AjouterFactureModal item={row} /> */}
        </div>
      </>
    );
  };

  const logoFormatter: any = (cell: IUser) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <div>
          <div>
            <img
              src={getAvatar(
                cell?.user_type === "agence" ? cell?.logo : cell?.avatar
              )}
              alt="img type document"
              className="kanimmo-img-avatar-table-admin"
            />
          </div>
        </div>
      </div>
    );
  };
  const nameFormatter = (cell: IUser) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 ">
        <div>
          {cell?.user_type === "agence" ? cell?.nom_agence : getName(cell)}
        </div>
        <p className="fs-13 fw-400">
          {" "}
          {cell?.user_type === "agence" ? "Agence" : "Propriétaire"}{" "}
        </p>
      </div>
    );
  };

  const statusFormatter = (cell: boolean) => {
    if (cell) {
      return <div className="custom-statut-facture-paye">Payé</div>;
    }

    return <div className="custom-statut-facture-non-paye">Non payé</div>;
  };

  const nameFormatterOffer = (cell: Offer) => {
    return cell?.max_numb
      ? cell?.min_numb + "-" + cell?.max_numb
      : cell?.min_numb + "+";
  };

  const columns = [
    {
      dataField: "adherant",
      text: "Logo",
      formatter: (cell: IUser) => logoFormatter(cell),
    },
    {
      dataField: "adherant",
      text: "Nom de l’agence/propriétaire",
      style: { textAlign: "left" },
      formatter: (cell: IUser) => nameFormatter(cell),
    },
    {
      dataField: "offre",
      text: "Offre",
      formatter: (cell: Offer) => nameFormatterOffer(cell),
    },
    {
      dataField: "offre",
      text: "Montant",
      style: { textAlign: "left" },
      formatter: (cell: Offer) => formatMontant(cell?.montant),
    },
    {
      dataField: "paid",
      text: "Statut",
      formatter: (cell: boolean) => statusFormatter(cell),
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: Abonnement) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  // const tableRowEvents = {
  // 	onClick: () => navigate("/admin/details-agence/:slug"),
  // }

  return (
    <>
      {isLoading && <RecetteAdminSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results
              ?.slice()
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
            // rowEvents={ tableRowEvents }
            // rowStyle={ { cursor: 'pointer' } }
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setLimit(perPage);
              setPage(page);
            }}
            perPage={limit}
          />
        </>
      )}
    </>
  );
}

export default RecetteAdminTable;
