import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../redux/hooks";
import { useResponseContactMutation } from "../../../../utils/api/contact/contact.api";
import { IContact, TResponseContact } from "../../../../utils/api/contact/contact.type";
import { useResponseProblemeMutation } from "../../../../utils/api/probleme/probleme.api";
import { IProbleme, TProblemeForm, TResponseProbleme } from "../../../../utils/api/probleme/probleme.type";
import { Color } from "../../../../utils/theme";
import { cleannerError, closeModal, onHide } from "../../../../utils/Utils";

const useResponseProbleme = (item: IProbleme) => {
	const validationSchema = yup.object().shape({
		response: yup.string().required().label("La réponse").transform((val: any) => val.replace(/<p>(?!\s*<\/p>)(.*?)<\/p>/gs, '$1\n\n')),
	});
	const { user: authUser } = useAppSelector((s) => s.user);
	const [response, setResponse] = useState<string>("");
	const [sendMessage, { isLoading, isError, isSuccess, error }] = useResponseProblemeMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		resetField,
		clearErrors,
		setValue,
	} = useForm<TResponseProbleme>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Message envoyé avec succés!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				setResponse("")
				closeModal('DetailsMessageModal' + item?.slug)
				resetField("response")
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status || "inconnue"} est survenue`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);
	// useEffect(() => {
	// 	if (item?.id) {
	// 		const response = item?.responses[0]?.response;
	// 		setResponse(response);
	// 		console.log({ response });
	// 	}
	// });
	const handleChangeMessage = (value: string) => {
		setResponse(value);
		setValue("response", value);
	};
	const onsubmit = (data: TResponseProbleme) => {
		sendMessage({
			...data, user: authUser?.id, probleme: item?.id
		});
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
		response,
		handleChangeMessage, 
	};
};

export default useResponseProbleme;

