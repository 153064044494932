import React, { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useProprieteFromLocation } from "../../../../../../utils/api/propriete/propriete.api";
import { useStepType } from "../../../../../../utils/type";
import StepperHorizontal from "../../../../../common/StepperHorizontal";
import useCrudPropriete from "../requestPropriete/UseCrudPropriete";
import "./AjoutPropriete.css";
import DetailsPropriete from "./AjoutProprieteStep/DetailsPropriete";
import GestionPropriete from "./AjoutProprieteStep/GestionPropriete";
import InfoPropriete from "./AjoutProprieteStep/InfoPropriete";

const steps = [
	{
		id: "info-propriete",
		Component: InfoPropriete,
	},
	{
		id: "details-propriete",
		Component: DetailsPropriete,
	},
	{
		id: "gestion-propriete",
		Component: GestionPropriete,
	},
];

function AjouterProprietesPQNGPSB() {
	const { step, navigation, index }: useStepType = useStep({
		initialStep: 0,
		steps,
	});
	const [proprieteItem] = useProprieteFromLocation();
	const {
    register,
    onSubmit,
    errors,
    setValue,
    description,
    pictures,
    mandatPicture,
    handleChangeDescription,
    setPictures,
    handleChangeMandatPicture,
    isLoading,
    address,
    onChangeAddress,
    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    handleChangeNumber,
    nbChambre,
    nbCuisine,
    handleChangeNumberCuisine,
    nbSDB,
    handleChangeNumberSDB,
    nbSalon,
    handleChangeNumberSalon,
    nbClim,
    handleChangeNumberClim,
    control,
    Controller,
    commoditesList,
    commodites,
    onChangeCommodite,
    agences,
    handleChangeAgence,
    option,
    unregister
  } = useCrudPropriete(navigation, proprieteItem);

	const { Component } = step;
	const props = {
    unregister,
    navigation,
    register,
    onSubmit,
    errors,
    description,
    agences,
    pictures,
    mandatPicture,
    handleChangeDescription,
    setPictures,
    handleChangeMandatPicture,
    isLoading,
    setValue,
    propriete: proprieteItem,
    address,
    onChangeAddress,
    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    handleChangeNumber,
    nbChambre,
    nbCuisine,
    handleChangeNumberCuisine,
    nbSDB,
    handleChangeNumberSDB,
    nbSalon,
    handleChangeNumberSalon,
    nbClim,
    handleChangeNumberClim,
    control,
    Controller,
    commoditesList,
    commodites,
    onChangeCommodite,
    handleChangeAgence,
    option,
  };
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(10, 10);
		setValue("step", index + 1);
	}, [index]);

	return (
		<div className="landing-page-container">
			<div className="admin-page-title-header-container">
				<div className="kanimmo-dash-locataire-requetes-header mb-3">
					<div className="w-100">
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<h4 className="kanimmo-dash-locataire-requetes-header-titre d-flex align-items-baseline gap-2">
									<BiArrowBack
										className="locataire-btn-arrow-back pe-2"
										onClick={() => navigate(-1)}
									/>{" "}
									{proprieteItem?.slug ? "Modifier" : "Ajouter"}{" "}
									une propriété
									<span className="kanimmo-ajout-propriete-titre-span">
										{index === 0
											? "Infos de la propriété"
											: index === 1
											? "Détails de la propriété"
											: "Gestion"}
									</span>
								</h4>
							</div>
							<StepperHorizontal index={index} tabNum={3} />
						</div>
					</div>
				</div>
				<div className="bg-white p-3 filter-and-table-container">
					<div className="stepper-proprietes-form-container">
						<Component {...props} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default AjouterProprietesPQNGPSB;
