import React from 'react'
import { useAddOrUpdateCommoditesMutation } from '../../../utils/api/commodites/commodites.api';
import { ITypeCommodites } from '../../../utils/api/commodites/commodites.type';
import { BtnCloseModal, BtnSubmit } from '../../common/Button';
import { FormError } from '../../common/CustomInputCheckbox';
import { Input } from '../../common/Input';
import UseCrudCommodites from '../../TableauDebord/Admin/Options/CrudCommodites/UseCrudCommodites';

function AjouterCommoditesModal({ item }: { item?: ITypeCommodites }) {
    const { register, onSubmit, errors, isLoading, reset } = UseCrudCommodites(item);
  return (
    <div className="modal-dialog modal-md modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header px-2 py-1">
						<h5 className="modal-title" id="AddTagModalLabel">
							{item ? "Modifier " : "Ajouter " } un type de problème
						</h5>
						<BtnCloseModal label="" onClick={reset} />
					</div>
					<form action="" onSubmit={onSubmit}>
						<div className="row py-4 px-3">
							<div className="col-12 mb-3 ">
								<Input
									label="Nom"
									id="tag"
									type="text"
									placeholder="Nom"
									{...register("nom")}
									error={errors?.nom}
									className="form-control"
								/>
							</div>
							{/* <div className="col-12 mb-4 ">
							<label
								htmlFor="content"
								className="form-label form-label-modal-custom"
							>
								Description
							</label>
                            <textarea
                                className="form-control form-control-modal-custom"
                                id="message"
                                rows={3}
                                {...register("description")}
                            ></textarea>
                            {<FormError error={errors.description} />}
							</div> */}

							<div className="flex-r">
								<BtnSubmit
									label={item ? "Modifier" : "Ajouter"}
									isLoading={isLoading}
									style={{ padding: "5px 10px", fontSize: 14 }}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
  )
}

export default AjouterCommoditesModal