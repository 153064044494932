import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ChangeEvent, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, closeModal } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { PaymentManuelFormData } from "../../../../../utils/api/paiement/paiement.type";
import { useSendPaymentMutation } from "../../../../../utils/api/paiement/paiement.api";
import { IUser } from "../../../../../utils/api/user/user.type";
import { IAgence } from "../../../../../utils/api/agence/agence.type";
import { IProprietaire } from "../../../../../utils/api/proprietaire/proprietaire.type";

function UseSendAmount(item: IUser | IAgence | IProprietaire, modalId: string) {
  const validationSchema = yup.object().shape(
    {
      montant: yup
        .number()
        .required()
        .label("Le montant")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
      description: yup.string().nullable().label("La description"),
    },
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    resetField,
    setError,
  } = useForm<PaymentManuelFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [description, setDescription] = useState("");
  const [sendData, { isLoading, isSuccess, isError, error, data }] =
    useSendPaymentMutation();

  const onChangeDescription = (value: any) => {
    setDescription(value);
    setValue("description", value);
  };

  const handleReset = () => {
    setDescription("");
    resetField("description");
    resetField("montant");
  };

  useEffect(() => {
    if (isSuccess) {
      let msg = "Transfère effectué avec succès!"
      Swal.fire({
        icon: "success",
        title: msg,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        closeModal(modalId);
        handleReset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: PaymentManuelFormData) => {
    if (item?.user_type === "agence") {
      data["agence"] = item?.id;
    } else {
      data["proprietaire"] = item?.id;
    }
    await sendData(data);
  };
  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    description,
    handleReset,
    onChangeDescription,
  };
}

export default UseSendAmount;
