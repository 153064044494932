import React from "react";
import { ILocataire } from "../../../utils/api/locataire/locataire.type";
import { IProprietaire } from "../../../utils/api/proprietaire/proprietaire.type";
import { ApiBaseUrl } from "../../../utils/http";
import { get_url_extension } from "../../../utils/Utils";
import { GoogleDocs } from "./DocumentLitigeModal";

const AgenceDocumentContratModal = ({
  item,
}: {
  item: ILocataire | IProprietaire;
}) => {
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            {/* <iframe
              src={`http://docs.google.com/gview?url=${
                ApiBaseUrl + item?.contrat
              }&embedded=true`}
              width="100%"
              height="500px"
            ></iframe> */}
            {get_url_extension(item?.contrat) === "pdf" ? (
              <iframe
                src={ApiBaseUrl + item?.contrat}
                frameBorder="0"
                width={`100%`}
                height={500}
                allowFullScreen
                seamless
                title="doc"
              ></iframe>
            ) : (
              <GoogleDocs file={ApiBaseUrl + item?.contrat} height={"500px"} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgenceDocumentContratModal;
