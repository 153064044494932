import React from "react";
import { AlertInfo } from "../../../../common/Alert";
import { IoClose } from "react-icons/io5";
import { useGetOffersByVisitorsQuery } from "../../../../../utils/api/offer/offer.api";
import { OfferSkeleton } from "../../../../user/PageFooter/Offres/Offres";
import { createMarkup, formatAmount } from "../../../../../utils/Utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { Offer } from "../../../../../utils/api/offer/offer.type";
import { IUser } from "../../../../../utils/api/user/user.type";
import { UseChangeOffer } from "../requestForm/UseEditUserFom";
import { useState } from "react";
import { SelectInput } from "../../../../common/SelectInput";

export function EditBtn({ item, user }: { item: Offer; user: IUser }) {
  const onChange = UseChangeOffer(user, item);
  return (
    <button
      className="btn btn-register-offre"
      disabled={user?.offre?.id === item?.id}
      onClick={onChange}
    >
      CHOISIR
    </button>
  );
}
function ChangeOfferModal({ type }: { type: "agence" | "proprietaire" }) {
  const user = useAppSelector((s) => s?.user?.user);
  const [selectedPeriodicity, setSelectedPeriodicity] = useState("Mensuel");
  const { data = [], isLoading } = useGetOffersByVisitorsQuery({ type });
  // Gestionnaire d'événements onChange pour le SelectInput
  const handlePeriodicityChange = (event: any) => {
    setSelectedPeriodicity(event.target.value);
  };

  // Filtrer les données en fonction de la périodicité sélectionnée
  const filteredData = data.filter(
    (offer) => offer.libelle === selectedPeriodicity
  );

  const optionsLibelle = [
    { value: "Mensuel", label: "Mensuel" },
    { value: "Trimestriel", label: "Trimestriel" },
    { value: "Semestriel", label: "Semestriel" },
    { value: "Annuel", label: "Annuel" },
  ];

  return (
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container-form">
            <div className="flex-sb">
              <div className="container-logo-page-register">
                Modification offre
              </div>
              <button
                className="close-modal auth-submit-annuler"
                data-bs-dismiss="modal"
                type="button"
              >
                <IoClose />
              </button>
            </div>
            <div className="row">
              <div className="col-md-12 offset-md-12 mb-4 d-flex justify-content-end">
                <SelectInput
                  options={optionsLibelle}
                  label="Périodicité  :"
                  required
                  id="type"
                  value={selectedPeriodicity}
                  onChange={handlePeriodicityChange} // Appel de la fonction lorsqu'une nouvelle périodicité est sélectionnée
                />
              </div>
            </div>
            <div className="container-card-offer">
              <div className="content-title-page-register-animate mt-4">
                <p className="title-page-register-animate text-center mb-5">
                  Veuillez choisir une offre
                </p>
              </div>
              <div className="row row-step-offre">
                {!!isLoading && <OfferSkeleton />}

                {!isLoading &&
                  (filteredData?.length ? (
                    [...filteredData]
                      ?.sort((a, b) => a?.min_numb - b?.min_numb)
                      ?.map((offer, i) => (
                        <div
                          className="col-md-3 col-step-offre mb-3 d-flex"
                          key={offer.id}
                        >
                          <div
                            className={`content-col-step-offre content-col-step-offre${
                              i + 1
                            }`}
                          >
                            <div className="content-offer">
                              <h4
                                className={`text-title-card-offre-step pb-3 text-title-card-offre-step${
                                  i + 1
                                }`}
                              >
                                {" "}
                                {offer?.max_numb
                                  ? offer?.min_numb + "-" + offer?.max_numb
                                  : "Illimité"}{" "}
                              </h4>
                              <div className="container-btn-offre-price">
                                <span
                                  className={`content-btn-price-offre-step content-btn-price-offre-step${
                                    i + 1
                                  }`}
                                >
                                  {formatAmount(offer?.montant)}/
                                  {offer?.libelle}
                                </span>
                              </div>
                              <div
                                className="content-describe-card-offre-step mt-5"
                                dangerouslySetInnerHTML={createMarkup(
                                  offer?.description
                                )}
                              />
                            </div>
                            <div
                              className="content-btn-register-offre mt-5"

                              //   onClick={() => onHide("offersModal")}
                            >
                              <EditBtn item={offer} user={user} />
                              {/* <button
                                className="btn btn-register-offre"
                                disabled={user?.offre?.id === offer?.id}
                              >
                                CHOISIR
                              </button> */}
                            </div>
                          </div>
                        </div>
                      ))
                  ) : (
                    <AlertInfo message="Aucune donnée trouvée" />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeOfferModal;
