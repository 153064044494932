import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/appImages/logo.png";
import { BtnSubmit } from "../common/Button";
import ErrorMessage from "../common/ErrorMessage";
import { UseResetPasswordForm } from "./useAuthForm/useResetPassword";

const ResetPassword = () => {
	const {
    register,
    onSubmit,
    errors,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
  } = UseResetPasswordForm();
	return (
    <div className="auth-component">
      <div className="fixed-login-component">
        <div className="auth-container">
          <div className="auth-row row">
            <div className="col-md-6 offset-md-3 auth-col auth-right-side-col">
              <div className="auth-right-side-container">
                <div className="auth-form-container">
                  <div className="flex-c py-4">
                    <img src={Logo} alt="Logo" className="logo-app" />
                  </div>

                  <div className="mb-4">
                    <h1 className="auth-form-title">Change password</h1>
                    <h4 className="auth-form-message">Create a new password</h4>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label
                            className="form-label form-label-login"
                            htmlFor="code"
                          >
                            Received Code
                          </label>
                          <input
                            type={`text`}
                            className="form-control auth-form-control"
                            id="code"
                            placeholder="Enter received code by email"
                            {...register("code")}
                          />
                          {errors?.code && (
                            <ErrorMessage message={errors?.code?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label
                            className="form-label form-label-login"
                            htmlFor="password"
                          >
                            Password
                          </label>
                          <input
                            type={`password`}
                            className="form-control auth-form-control"
                            id="password"
                            placeholder="Enter new password"
                            onChange={passwordHandleChange}
                          />
                          {errors?.new_password && (
                            <ErrorMessage
                              message={errors?.new_password?.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col">
                        <div className="auth-form-group">
                          <label
                            htmlFor="confirm-new-password"
                            aria-labelledby="confirm-password"
                            className="form-label form-label-login"
                          >
                            Confirm password
                          </label>
                          <input
                            type="password"
                            className="form-control auth-form-control"
                            id="confirm-new-password"
                            placeholder="Confirm password"
                            {...register("new_password_confirm")}
                          />
                          {errors?.new_password_confirm && (
                            <ErrorMessage
                              message={errors?.new_password_confirm?.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 offset-md-4 auth-submit-btn-container mt-4">
                        <BtnSubmit
                          label="réinitialiser"
                          isLoading={isLoading}
                        />
                      </div>
                      <div className="col-md-12">
                        <div className="input-group my-2 password-validation-terms-row">
                          <div className="password-validation-terms-container">
                            <h4 className="password-validation-terms-title">
                              Votre mot de passe doit contenir :{" "}
                            </h4>
                            <ul className="password-validation-terms-ul p-l-14">
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinCharacter && "text-success")
                                }
                              >
                                Au minimum 8 caractères
                              </li>
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinUppercase && "text-success")
                                }
                              >
                                Au minimum 1 caractère en majuscule
                              </li>
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinLowercase && "text-success")
                                }
                              >
                                Au minimum 1 caractère en minuscule
                              </li>
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinNumber && "text-success")
                                }
                              >
                                Au minimum 1 nombre
                              </li>
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinSpecialCharacter && "text-success")
                                }
                              >
                                Au minimum 1 caractère spéciale
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div className="auth-component">
    // 	<div className="empty-header fixed-top">
    // 		<NavLink to={"/"}>
    // 			<img src={Logo} alt="Logo" className="logo-app" />
    // 		</NavLink>
    // 	</div>
    // 	<div className="fixed-after-header">
    // 		<div className="auth-container">
    // 			<div className="auth-row row">
    // 				<div className="col-md-6 offset-md-3 auth-col auth-right-side-col">
    // 					<div className="auth-right-side-container">
    // 						<div className="auth-form-container">
    // 							<div className="mb-4">
    // 								<h1 className="auth-form-title">Change password</h1>
    // 								<h4 className="auth-form-message">Create a new password</h4>
    // 							</div>
    // 							<form
    // 							// onSubmit={onSubmit}
    // 							>
    // 								<div className="row auth-form-row">
    // 									<div className="col-md-12 auth-input-col mb-3">
    // 										<div className="auth-form-group">
    // 											<label
    // 												className="form-label form-label-login"
    // 												htmlFor="code"
    // 											>
    // 												Received Code
    // 											</label>
    // 											<input
    // 												type={`text`}
    // 												className="form-control auth-form-control"
    // 												id="code"
    // 												placeholder="Enter received code by email"
    // 												// {...register("code")}
    // 											/>
    // 											{/* {errors?.code && (
    // 												<ErrorMessage
    // 													message={errors?.code?.message}
    // 												/>
    // 											)} */}
    // 										</div>
    // 									</div>
    // 									<div className="col-md-12 auth-input-col mb-3">
    // 										<div className="auth-form-group">
    // 											<label
    // 												className="form-label form-label-login"
    // 												htmlFor="password"
    // 											>
    // 												Password
    // 											</label>
    // 											<input
    // 												type={`password`}
    // 												className="form-control auth-form-control"
    // 												id="password"
    // 												placeholder="Enter new password"
    // 												// {...register("new_password")}
    // 											/>
    // 											{/* {errors?.new_password && (
    // 												<ErrorMessage
    // 													message={errors?.new_password?.message}
    // 												/>
    // 											)} */}
    // 										</div>
    // 									</div>
    // 									<div className="col-md-12 auth-input-col">
    // 										<div className="auth-form-group">
    // 											<label
    // 												htmlFor="confirm-new-password"
    // 												aria-labelledby="confirm-password"
    // 												className="form-label form-label-login"
    // 											>
    // 												Confirm password
    // 											</label>
    // 											<input
    // 												type="password"
    // 												className="form-control auth-form-control"
    // 												id="confirm-new-password"
    // 												placeholder="Confirm password"
    // 												// {...register("new_password_confirm")}
    // 											/>
    // 											{/* {errors?.new_password_confirm && (
    // 												<ErrorMessage
    // 													message={
    // 														errors?.new_password_confirm
    // 															?.message
    // 													}
    // 												/>
    // 											)} */}
    // 										</div>
    // 									</div>
    // 									<div className="col-md-4 offset-md-4 auth-submit-btn-container mt-4">
    // 										<BtnSubmit label="RESET" isLoading={false} />
    // 									</div>
    // 								</div>
    // 							</form>
    // 						</div>
    // 					</div>
    // 				</div>
    // 			</div>
    // 		</div>
    // 	</div>
    // </div>
  );
};

export default ResetPassword;
