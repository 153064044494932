import React, { useEffect, useState, useRef } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import { useLocataireFromLocation } from "../../../../../utils/api/locataire/locataire.api";
import { useGetProprietesByAgenceQuery } from "../../../../../utils/api/propriete/propriete.api";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { BtnSubmit } from "../../../../common/Button";
import ErrorMessage from "../../../../common/ErrorMessage";
import AgenceDocumentContratModal from "../../../../modal/agence/AgenceDocumentContratModal";
import useAddOrUpdateLocataire from "./useAddOrUpdateLocataire";
import { SelectInput } from "../../../../common/SelectInput";

function AjouterLocataire() {
  const [itemState] = useLocataireFromLocation();

  console.log("itemState", itemState);
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    handelAvatarChange,
    setValue,
    phone,
    setPhone,
    handleChecked,
    isChecked,
    handleDateDebutChange,
    isCheckedCaution,
    handleCheckedCaution,
    dateDebut,
  } = useAddOrUpdateLocataire(itemState);

  const { user } = useAppSelector((s) => s?.user);

  const { data = { results: [] } } = useGetProprietesByAgenceQuery({
    slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
    limit: 10000,
  });

  const [filterProperties, setFilterProperties] = useState<
    IPropriete[] | undefined
  >();
  useEffect(() => {
    const propriete = data?.results?.filter(
      (item: any) => item?.type_propriete !== "immeuble"
    );
    setFilterProperties(propriete);
  }, [isLoading]);

  const navigate = useNavigate();

  const [showAutre, setShowAutre] = useState(false);
  const handleChangeCaution = (e: any) => {
    if (e.target.value) {
      if (e.target.value === "true") {
        setShowAutre(true);
      } else {
        setShowAutre(false);
      }
    }
  };
  useEffect(() => {
    if (itemState) {
      setShowAutre(true);
    }
  }, [itemState]);

  const ref = useRef<any>();

  useEffect(() => {
    if (errors?.telephone?.message) {
      ref.current.scrollIntoView();
    }
  }, [errors?.telephone]);

  const filteredProperties = data?.results?.filter(
    (item) => item?.type_propriete !== "immeuble"
  );
  const filteredPropertiesLocataireArchive = data?.results?.filter(
    (item) =>
      item?.type_propriete !== "immeuble" && item?.disponibilite !== false
  );

  const optionsLibelle = [
    { value: "Mensuel", label: "Mensuel" },
    { value: "Trimestriel", label: "Trimestriel" },
    { value: "Semestriel", label: "Semestriel" },
    { value: "Annuel", label: "Annuel" },
  ];

  const formData = {
    slug_user: user?.slug,
    locataire_slug: itemState?.slug,
  };

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            <AiOutlineArrowLeft
              className=" no-link linkable"
              onClick={() => navigate(-1)}
            />
            {itemState
              ? itemState?.is_archive
                ? "Desarchivage du locataire"
                : "Modification de locataire"
              : "Ajout de locataire"}
          </h4>
        </div>
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label form-label-modal-custom"
                      >
                        Selectionner un bien
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <select
                        className="form-select form-select-modal-custom"
                        aria-label="Default select example"
                        {...register("propriete")}
                      >
                        <option value="">Selectionner un bien</option>
                        {!itemState?.is_archive
                          ? filteredProperties.map((item) => (
                              <option value={item?.id} key={item?.slug}>
                                {item?.nom}
                              </option>
                            ))
                          : filteredPropertiesLocataireArchive.map((item) => (
                              <option value={item?.id} key={item?.slug}>
                                {item?.nom}
                              </option>
                            ))}
                      </select>
                    </div>
                    {errors?.propriete && (
                      <ErrorMessage message={errors?.propriete?.message} />
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="tel"
                        className="form-label form-label-modal-custom"
                      >
                        Contrat de bail (pdf/word)
                      </label>
                      <input
                        type="file"
                        className="form-control form-control-modal-custom"
                        id="contrat_de_bail"
                        onChange={(e) => handelAvatarChange(e)}
                        accept="application/msword, application/pdf"
                      />
                      {itemState?.contrat ? (
                        <button
                          className="custom-btn-voir-contrat btn"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target={`#DocumentContratModal${itemState?.slug}`}
                        >
                          Afficher le contrat
                        </button>
                      ) : null}
                      <div
                        className="modal fade"
                        id={`DocumentContratModal${itemState?.slug}`}
                        aria-labelledby="DocumentContratModalLabel"
                        aria-hidden="true"
                      >
                        <AgenceDocumentContratModal item={itemState} />
                      </div>
                      {errors?.contrat && (
                        <ErrorMessage message={errors?.contrat?.message} />
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <SelectInput
                        options={optionsLibelle}
                        label="Fréquence de Paiement"
                        required
                        id="type"
                        {...register("sequence_paiement")}
                        // value={sequence_paiement}
                        // onChange={handlePeriodicityChange} // Appel de la fonction lorsqu'une nouvelle périodicité est sélectionnée */}
                      />

                      {errors?.date_debut && (
                        <ErrorMessage message={errors?.date_debut?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="debut_bail"
                        className="form-label form-label-modal-custom"
                      >
                        Début du bail{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <input
                        type="date"
                        placeholder="I"
                        className="form-control form-control-modal-custom"
                        id="date_debut"
                        {...register("date_debut")}
                        onChange={handleDateDebutChange}
                      />
                      {errors?.date_debut && (
                        <ErrorMessage message={errors?.date_debut?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="fin_bail"
                        className="form-label form-label-modal-custom"
                      >
                        Fin du bail{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <input
                        type="date"
                        placeholder="I"
                        className="form-control form-control-modal-custom"
                        id="date_fin"
                        min={dateDebut}
                        {...register("date_fin")}
                      />
                      {errors?.date_fin && (
                        <ErrorMessage message={errors?.date_fin?.message} />
                      )}
                    </div>
                  </div>

                  {/* MODIFICATIO */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="fin_bail"
                        className="form-label form-label-modal-custom"
                      >
                        Date du prochain paiement{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <input
                        type="date"
                        placeholder="I"
                        className="form-control form-control-modal-custom"
                        id="date_prochaine_paiement"
                        // min={dateDebut}
                        {...register("date_prochaine_paiement")}
                      />
                      {errors?.date_prochaine_paiement && (
                        <ErrorMessage
                          message={errors?.date_prochaine_paiement?.message}
                        />
                      )}
                    </div>
                  </div>
                  {/* MODIFICATIO FIN */}

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="nom"
                        className="form-label form-label-modal-custom"
                      >
                        Nom du locataire{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Nom du locataire"
                        className="form-control form-control-modal-custom"
                        id="nom"
                        {...register("nom")}
                      />
                      {errors?.nom && (
                        <ErrorMessage message={errors?.nom?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="prenom"
                        className="form-label form-label-modal-custom"
                      >
                        Prénom(s) du locataire{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Prénom(s) du locataire"
                        className="form-control form-control-modal-custom"
                        id="prenom"
                        {...register("prenom")}
                      />
                      {errors?.prenom && (
                        <ErrorMessage message={errors?.prenom?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="tel"
                        className="form-label form-label-modal-custom"
                        ref={ref}
                      >
                        Téléphone du locataire{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      {/* <input
												type="text"
												placeholder="Téléphone du locataire"
												className="form-control form-control-modal-custom"
												id="telephone"
												{...register("telephone")}
											/> */}
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        containerClass="form-control auth-form-control mb-3 p-0"
                        inputClass="form-control form-control-modal-custom"
                        country={"sn"}
                        placeholder=""
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        countryCodeEditable={false}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        onChange={(phone, country: any) => {
                          setPhone(phone);

                          if (
                            country?.format?.match(/\./g)?.length ===
                            phone?.length
                          ) {
                            setValue("telephone", phone);
                          }
                        }}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      {errors?.telephone && (
                        <ErrorMessage message={errors?.telephone?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label form-label-modal-custom"
                      >
                        Email du locataire{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-modal-custom"
                        id="email"
                        placeholder="Email du locataire"
                        {...register("email")}
                      />
                      {errors?.email && (
                        <ErrorMessage message={errors?.email?.message} />
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="date_naissance"
                        className="form-label form-label-modal-custom"
                      >
                        Date de naissance
                      </label>
                      <input
                        type="date"
                        placeholder="Date de naissance"
                        className="form-control form-control-modal-custom"
                        id="date_de_naissance"
                        {...register("date_de_naissance")}
                      />
                      {errors?.date_de_naissance && (
                        <ErrorMessage
                          message={errors?.date_de_naissance?.message}
                        />
                      )}
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="date_naissance"
                        className="form-label form-label-modal-custom"
                      >
                        Doit-il payer une caution?
                      </label>
                      <div className="d-flex align-items-center">
                        <div className="checkbox">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={`${true}`}
                            // id="flexCheckDefaultnon"
                            name="cautionnement"
                            onClick={(e) => handleCheckedCaution(e)}
                          />
                          <label
                            className="form-check-label form-label-login ms-2"
                            htmlFor="flexCheckDefaultoui"
                          >
                            OUI
                          </label>
                        </div>
                        <div className="checkbox ms-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={`${false}`}
                            // id="flexCheckDefaultnon"
                            name="cautionnement"
                            onClick={(e) => handleCheckedCaution(e)}
                            defaultChecked
                          />
                          <label
                            className="form-check-label form-label-login ms-2"
                            htmlFor="flexCheckDefaultnon"
                          >
                            NON
                          </label>
                        </div>
                      </div>
                      {errors?.cautionnement && (
                        <ErrorMessage
                          message={errors?.cautionnement?.message}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="date_naissance"
                        className="form-label form-label-modal-custom"
                      >
                        Doit-il payer un prorata?
                      </label>
                      <div className="d-flex align-items-center">
                        <div className="checkbox">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={`${true}`}
                            name="pay_proratat"
                            // id="flexCheckDefaultoui"
                            onClick={(e) => handleChecked(e)}
                            // checked={isChecked === true}
                          />
                          <label
                            className="form-check-label form-label-login ms-2"
                            htmlFor="flexCheckDefaultoui"
                          >
                            OUI
                          </label>
                        </div>
                        <div className="checkbox ms-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={`${false}`}
                            // id="flexCheckDefaultnon"
                            name="pay_proratat"
                            onClick={(e) => handleChecked(e)}
                            // checked={isChecked === false}
                            defaultChecked
                          />
                          <label
                            className="form-check-label form-label-login ms-2"
                            htmlFor="flexCheckDefaultnon"
                          >
                            NON
                          </label>
                        </div>
                      </div>
                      {errors?.cautionnement && (
                        <ErrorMessage
                          message={errors?.cautionnement?.message}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={isCheckedCaution ? "d-block" : "d-none"}>
                      <div className="col-md-12 auth-input-col">
                        <div className="form-group auth-form-group">
                          <label className="form-label form-label-login">
                            Montant de la caution
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            min={0}
                            className="form-control form-control-modal-custom"
                            id="nombre"
                            placeholder="Montant de la caution"
                            {...register("montant_caution")}
                          />
                          {errors?.montant_caution && (
                            <ErrorMessage
                              message={errors?.montant_caution?.message}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={isChecked ? "d-block" : "d-none"}>
                      <div className="col-md-12 auth-input-col">
                        <div className="form-group auth-form-group">
                          <label className="form-label form-label-login">
                            Montant du prorata
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            min={0}
                            step="any"
                            className="form-control form-control-modal-custom"
                            id="nombre"
                            placeholder="Montant du prorata"
                            {...register("proratat")}
                          />
                          {errors?.proratat && (
                            <ErrorMessage message={errors?.proratat?.message} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-btn-modal row my-4">
                  <div className="flex-r">
                    <BtnSubmit
                      label={
                        itemState
                          ? itemState?.is_archive
                            ? "Désarchiver"
                            : "Modifier"
                          : "Envoyer"
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterLocataire;
