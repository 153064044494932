import React from "react";
import ConfirmDeleteAccountModal from "../ConfirmDeleteAccountModal";

function DeleteAccountSection() {
  return (
    <section className="kanimmo-dash-locataire-param-main-page-container py-4 mb-3 text-end">
      <button
        className="delete-account"
        data-bs-toggle="modal"
        data-bs-target="#deleteAccountModal"
      >
        Supprimer mon compte
      </button>
      <div
        className="modal fade"
        id="deleteAccountModal"
        data-bs-backdrop="static"
        aria-labelledby="deleteAccountModalLabel"
        aria-hidden="true"
      >
        <ConfirmDeleteAccountModal />
      </div>
    </section>
  );
}

export default DeleteAccountSection;
