import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { ILitiges } from "../../../utils/api/litiges/litiges.type";
import { ApiBaseUrl } from "../../../utils/http";
import { get_url_extension } from "../../../utils/Utils";

function DocumentLitigeModal({ item }: { item: ILitiges }) {
// console.log("item", item);
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            {get_url_extension(item?.document) === "pdf" ? (
              <iframe
                src={ApiBaseUrl + item?.document}
                frameBorder="0"
                width={`100%`}
                height={500}
                allowFullScreen
                seamless
                title="doc"
              ></iframe>
            ) : (
              // <iframe
              //   src={`https://docs.google.com/gview?url=${ApiBaseUrl +item?.document}&embedded=true`}
              //   frameBorder="0"
              //   width={`100%`}
              //   height={500}
              //   title="doc"
              // ></iframe>
              <GoogleDocs file={ApiBaseUrl + item?.document} height={"700px"} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentLitigeModal;

export function GoogleDocs({ file,height }:{file: string,height?: string}) {
  const [loaded, setLoaded] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (reload === false) {
      setTimeout(() => {
        setLoaded((loaded) => {
          if (!loaded) {
          // console.log("reload");
            setReload(true);
            setTimeout(() => {
              setReload(false);
            }, 400);
          }
          return loaded;
        });
      }, 10 * 1000);
    }
  }, [reload]);
  if (reload) return null;
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {!loaded && (
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <div className="w-100">
            <Skeleton height={height || 500} width={`100`} />
          </div>
        </div>
      )}

      <iframe
        onLoad={(e) => {
          setLoaded(true);
        }}
        onAbort={() => {
        // console.log("abort");
        }}
        src={`https://docs.google.com/viewer?embedded=true&url=${file}`}
        style={{ width: "100%", height: height || "100%", border: "none" }}
      ></iframe>
    </div>
  );
}