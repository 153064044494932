import React from "react";
import { FiSearch } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { AlertInfo } from "../../common/Alert";
import EmptyHeader from "../../landingPage/EmptyHeader";
import FrontFooter from "../../landingPage/FrontFooter/FrontFooter";
import BreadCrumb from "../../shared/BreadCrumb";
import "./NewsList.css";
import NewsListSkeleton from "./NewsListSkeleton";
import { useGetNewsForVisitorsQuery } from "../../../utils/api/new/news.api";
import { INews } from "../../../utils/api/new/news.type";
import { getImage } from "../../../utils/Utils";
import moment from "moment";
import { ITag } from "../../../utils/api/Tag/tag.type";

function NewsList() {
  // const [isLoading, setIsLoading] = useState(true);
  const { data, isLoading } = useGetNewsForVisitorsQuery({});
// console.log({ data });

  return (
    <div className="landing-page-component">
      <EmptyHeader />
      <div className="page-content">
        <BreadCrumb pageName="News" title="News" />
      </div>

      <section className="page-content-body-container container">
        <div className="online-courses-container">
          <div className="news-list-container py-5">
            <div className="row">
              {isLoading &&
                [...Array(6)].map((item, index) => {
                  return <NewsListSkeleton key={index} />;
                })}
              {!isLoading &&
                (data?.results?.length ? (
                  data?.results?.map((item, index) => (
                    <NewItem item={item} key={item?.slug} />
                  ))
                ) : (
                  <div style={{ padding: "2rem" }}>
                    <AlertInfo message="No new available" />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>

      <FrontFooter />
    </div>
  );
}

export default NewsList;

export const NewItem = ({ item }: { item: INews }) => {
  return (
    <div className="col-lg-4 col-md-6 pb-4 px-3 d-flex" key={item.id}>
      <NavLink className="no-link w-100" to={`/news/${item.slug}`} state={item}>
        <div className="news-card-item-component">
          <div className="container-img-card-news">
            <img
              src={getImage(item?.couverture)}
              alt="News"
              className="img-card-news"
            />
          </div>
          <div className="content-text-card-news p-3">
            <div className="test">
              <p className="text-date-card-news">
                {moment(item?.created_at).format("DD MMMM YYYY")}
              </p>
              <div className="d-flex gap-1">
                {item.tag.map((tag: ITag) => (
                  <span className="custom-btn-tag" key={tag?.slug}>
                    {tag?.nom}
                  </span>
                ))}
              </div>
            </div>

            <div className="container-title-card-news">
              <h4 className="title-card-news">{item?.titre}</h4>
              {/* <p className="author-card-news">Par : Alpha Diallo</p> */}
            </div>
            <div className="wrapper custom-wrapper" id="wrapper">
              <NavLink className="cta" to={`/news/${item.slug}`} state={item}>
                <span className="custom-btn-en-savoir-plus">
                  En savoir plus
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
};
