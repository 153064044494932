import { is } from "immer/dist/internal";
import React from "react";
import { MdModeEditOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { IPropriete } from "../../../../utils/api/propriete/propriete.type";
import { IUser } from "../../../../utils/api/user/user.type";
import {
  formatMontant,
  formatPlurial,
  getImage,
  isAgence,
  isAgenceAgent,
  isAgenceJuriste,
  isAgenceMarketeur,
  isProprietaireAgence,
  isProprietaireOwner,
  isProprioJuriste,
} from "../../../../utils/Utils";
import { DeletePropriete } from "../../Proprietaire/ProprietaireGererParLeProprietaire/Proprietes/ProprietesListe";

function ProprieteItem({ item }: { item: IPropriete }) {
  const user = useAppSelector((s) => s.user.user as IUser);
  return (
    <div className="col-lg-4 col-md-6 mb-3 d-flex" key={item?.slug}>
      <div className="proprietes-card-item card">
        <Link to={getRoute(item, user)} className="inherit-link" state={item}>
          <div className="card-img-top-propriete-container">
            <img
              src={getImage(item?.pictures[0]?.image)}
              className="card-img-top card-img-top-propriete"
              alt={item?.nom}
            />
          </div>
          <div className="card-body pb-0 mb-0">
            <p className="card-text text-adresse-propriete mb-1">
              {item?.type_propriete + " - " + item?.adresse}
            </p>
            <p className="text-nom-propriete mb-0">{item?.nom}</p>
            {item?.type_propriete !== "immeuble" ? (
              <div className="flex-sb mt-2">
                <div>
                  <p className="text-description-propriete fs-12">
                    {formatPlurial(item.nbre_chambres, "chambre")}
                  </p>
                </div>
                <div>
                  <p className="text-description-propriete fs-12">
                    {formatPlurial(item?.nbre_cuisines, "cuisine")}
                  </p>
                </div>
                <div>
                  <p className="text-description-propriete fs-12">
                    {item?.nbre_salle_de_bains}
                    {item?.nbre_salle_de_bains <= 1
                      ? " salle de bain"
                      : " salles de bains"}
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex-sb mt-2">
                <div>
                  <p className="text-description-propriete">
                    {formatPlurial(item?.nbre_appart, "appartement")}
                  </p>
                </div>
              </div>
            )}
            {item?.type_propriete !== "immeuble" && (
              <div className="flex-sb">
                <div className="content-prix-propriete">
                  <p className="text-prix-propriete">
                    {formatMontant(item?.prix)}
                  </p>
                </div>
              </div>
            )}
          </div>
        </Link>
        <div className="content-edit-propriete d-flex gap-3 flex-r pb-2 pe-2">
          <Link
            to={getEditRoute(item, user)}
            className="btn btn-edit-propriete"
            state={item}
          >
            <MdModeEditOutline />
          </Link>
          <DeletePropriete item={item} />
        </div>
      </div>
    </div>
  );
}

export default ProprieteItem;

export function getRoute(item: IPropriete, user: IUser) {
  let route = "/";
  if (
    item?.type_propriete === "villa" ||
    item?.type_propriete === "appartement"
  ) {
    if (
      isAgence(user) ||
      isAgenceAgent(user) ||
      isAgenceJuriste(user) ||
      isAgenceMarketeur(user)
    ) {
      route = `/agence/proprietes/${item?.slug}`;
    }

    if (isProprietaireOwner(user) || isProprioJuriste(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/proprietes/${item?.slug}`;
    }

    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/proprietes/${item?.slug}`;
    }
  }

  if (item?.type_propriete === "immeuble") {
    if (
      isAgence(user) ||
      isAgenceAgent(user) ||
      isAgenceJuriste(user) ||
      isAgenceMarketeur(user)
    ) {
      route = `/agence/immeuble/${item?.slug}`;
    }
    if (isProprietaireOwner(user) || isProprioJuriste(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/immeuble/${item?.slug}`;
    }
    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/immeuble/${item?.slug}`;
    }
  }

  return route;
}

export function getEditRoute(item: IPropriete, user: IUser) {
  let route = "/";
  if (
    item?.type_propriete === "villa" ||
    item?.type_propriete === "appartement"
  ) {
    if (user?.user_type === "agence") {
      route = `/agence/modifier-propriete/${item?.slug}`;
    }

    if (isProprietaireOwner(user) || isProprioJuriste(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/modifier-propriete/${item?.slug}`;
    }

    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/modifier-propriete/${item?.slug}`;
    }
  }

  if (item?.type_propriete === "immeuble") {
    if (user?.user_type === "agence") {
      route = `/agence/modifier-propriete-immeuble/${item?.slug}`;
    }

    if (isProprietaireOwner(user) || isProprioJuriste(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/modifier-propriete-immeuble/${item?.slug}`;
    }

    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/modifier-propriete-immeuble/${item?.slug}`;
    }
  }

  return route;
}
