import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { IAdmin } from "../../../../../utils/api/admin/admin.type";
import { Authorisations } from "../../../../../utils/constant";
import {
  getAvatar,
  getName,
  useLocationState,
} from "../../../../../utils/Utils";
import { InputIcon } from "../../../../common/Input";
import UseCrudAdmin from "../CrudAdministrateur/useForm/UseCrudAdmin";
import "./DetailsAdministrateur.css";

function DetailsAdministrateur() {
  const navigate = useNavigate();
  const user = useLocationState<IAdmin>(undefined);
  const { register } = UseCrudAdmin(user);
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            <BiArrowBack onClick={() => navigate(-1)} />
            Détails de l’administrateur
          </h4>
        </div>
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="kanimmo-dash-locataire-param-main-page-container ps-0 py-3">
              <div className="container-profil-user-admin py-5 text-center position-relative">
                <div className="container-img-profil-user mb-4">
                  <img
                    src={getAvatar(user?.avatar)}
                    alt="Profil"
                    className="img-profil-user-admin"
                  />
                </div>
                <div className="content-text-name-user">
                  <h2 className="name-user-admin">{getName(user)}</h2>
                </div>
              </div>

              <div className="page-content-container mt-4">
                <div className="parent-table-container">
                  {/* <form action="" onSubmit={onSubmit}> */}
                  <div className="row">
                    <p className="custom-titre-details-parametre">
                      Informations personnelles
                    </p>
                    <div className="row d-flex align-items-center  mb-5">
                      <div className="col-md-6">
                        <InputIcon
                          type="text"
                          label="Prénoms"
                          id="prenom"
                          placeholder="Prénoms"
                          {...register("prenom")}
                        />
                      </div>
                      <div className="col-md-6">
                        <InputIcon
                          type="text"
                          label="Nom"
                          id="nom"
                          placeholder="Nom"
                          {...register("nom")}
                        />
                      </div>
                      <div className="col-md-6">
                        <InputIcon
                          type="email"
                          label="Email"
                          id="email"
                          placeholder="Email"
                          {...register("email")}
                        />
                      </div>
                      <div className="col-md-6">
                        <InputIcon
                          type="text"
                          label="Adresse"
                          id="adresse"
                          placeholder="Adresse"
                          {...register("adresse")}
                        />
                      </div>
                      <div className="col-md-6">
                        <InputIcon
                          type="text"
                          label="Numéro de téléphone"
                          id="phone"
                          placeholder="Téléphone"
                          {...register("telephone")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <p className="custom-titre-details-parametre">
                          Permissions
                        </p>
                        <div className="container-checkbox-input">
                          <div className="form-checkbox-input checkbox-input">
                            <div className="custom-permission-container">
                              {Authorisations?.map((item) => (
                                <label key={item?.field}>
                                  <input
                                    type="checkbox"
                                    {...register(item?.field)}
                                    id={`checkbox-${item?.field}`}
                                  />
                                  <span>{item?.label}</span>
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsAdministrateur;
