import React from "react";
import { useStep } from "react-hooks-helper";
import { AiFillPlusSquare } from "react-icons/ai";
import { useStepType } from "../../../../utils/type";
import Contrat from "./AdministratifStep/Contrat";
import "./AdministratifAdmin.css";
import AjouterContratModal from "../../../modal/admin/AjouterContratModal";
import AjouterNoteModal from "../../../modal/admin/AjouterNoteModal";
import AjouterAnnonceModal from "../../../modal/admin/AjouterAnnonceModal";
import AjouterContratProprioModal from "../../../modal/admin/AjouterContratProprioModal";
import Newsletter from "./AdministratifStep/Newsletter";
import { BiExport } from "react-icons/bi";
import { ApiBaseUrl } from "../../../../utils/http";

const steps = [
  { id: "contrat", Component: Contrat },
  // { id: "notes-annonces", Component: NotesAnnonces },
  { id: "newsletter", Component: Newsletter },
];

const tabs = [
  "Contrat",
  // "Notes et Annonces",
  "Newsletter",
];

function AdministratifAdmin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Administratif
          </h4>
          {tabs.map((tab, key) => {
            if (index === key && tab === "Contrat") {
              return (
                <>
                  <div className="dropdown dis-flex">
                    <button
                      className="btn btn-see-more dropdown-toggle btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="user-acount-dashboard-header-display g-2">
                        <AiFillPlusSquare style={{ fontSize: 22 }} />
                        <span className="ps-2">Ajouter un contrat</span>
                      </div>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-custom"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        className="btn btn-see-more"
                        title="Agence"
                        data-bs-toggle="modal"
                        data-bs-target="#AjouterContratAgenceModal"
                      >
                        <span className="ps-2">Agence</span>
                      </button>
                      <button
                        className="btn btn-see-more"
                        title="Propriétaire"
                        data-bs-toggle="modal"
                        data-bs-target="#AjouterContratProprioModal"
                      >
                        <span className="ps-2">Propriétaire</span>
                      </button>
                    </div>
                    <div
                      className="modal fade"
                      id="AjouterContratAgenceModal"
                      aria-labelledby="AjouterContratAgenceModal"
                      aria-hidden="true"
                    >
                      <AjouterContratModal />
                    </div>
                    <div
                      className="modal fade"
                      id="AjouterContratProprioModal"
                      aria-labelledby="AjouterContratProprioModalLabel"
                      aria-hidden="true"
                    >
                      <AjouterContratProprioModal
                        modalId={"AjouterContratProprioModal"}
                      />
                    </div>
                  </div>

                  {/* <div className="container-btn-add">
									<button
										className="btn btn-see-more"
										title="Ajouter une entrée"
										data-bs-toggle="modal"
										data-bs-target="#AjouterContratModal"
									>
										<AiFillPlusSquare
											style={{ fontSize: 22 }}
										/>
										<span className="ps-2">
											Ajouter un contrat
										</span>
									</button>
									<div
										className="modal fade"
										id="AjouterContratModal"
										aria-labelledby="AjouterContratModalLabel"
										aria-hidden="true"
									>
										<AjouterContratModal />
									</div>
								</div> */}
                </>
              );
            }
            if (index === key && tab === "Notes et Annonces") {
              return (
                <div className="dropdown dis-flex">
                  <button
                    className="btn btn-see-more dropdown-toggle btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-acount-dashboard-header-display g-2">
                      <AiFillPlusSquare style={{ fontSize: 22 }} />
                      <span className="ps-2">Ajouter</span>
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      className="btn btn-see-more"
                      title="Note"
                      data-bs-toggle="modal"
                      data-bs-target="#AjouterNoteModal"
                    >
                      <span className="ps-2">Note</span>
                    </button>
                    <button
                      className="btn btn-see-more"
                      title="Annonce"
                      data-bs-toggle="modal"
                      data-bs-target="#AjouterAnnonceModal"
                    >
                      <span className="ps-2">Annonce</span>
                    </button>
                  </div>
                  <div
                    className="modal fade"
                    id="AjouterNoteModal"
                    aria-labelledby="AjouterNoteModalLabel"
                    aria-hidden="true"
                  >
                    <AjouterNoteModal modalId="AjouterNoteModal" />
                  </div>
                  <div
                    className="modal fade"
                    id="AjouterAnnonceModal"
                    aria-labelledby="AjouterAnnonceModalLabel"
                    aria-hidden="true"
                  >
                    <AjouterAnnonceModal modalId="AjouterAnnonceModal" />
                  </div>
                </div>
              );
            }

            if (index === key && tab === "Newsletter") {
              return (
                <div className="dropdown dis-flex">
                  <button
                    className="btn btn-see-more dropdown-toggle btn"
                    type="button"
                    onClick={(e) => {
                      e?.preventDefault();
                      window
                        ?.open(`${ApiBaseUrl}/api/export_newsletter`, "_blank")
                        ?.focus();
                    }}
                  >
                    <div className="user-acount-dashboard-header-display g-2">
                      <BiExport style={{ fontSize: 22 }} />
                      <span className="ps-2">Exporter les newsletters</span>
                    </div>
                  </button>
                </div>
              );
            }
          })}
        </div>
        <div className="kanimmo-tabs-container py-3">
          <ul className="tabs-container custom-tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdministratifAdmin;
