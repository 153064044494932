import React, { useEffect ,useState} from "react";
import { useStep } from "react-hooks-helper";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useProprieteFromLocation } from "../../../../../utils/api/propriete/propriete.api";
import { useStepType } from "../../../../../utils/type";
import StepperHorizontal from "../../../../common/StepperHorizontal";
import useCrudPropriete from "../requestPropriete/UseCrudPropriete";
import "./AjoutPropriete.css";
import DetailsPropriete from "./AjoutProprieteStep/DetailsPropriete";
import GestionPropriete from "./AjoutProprieteStep/GestionPropriete";
import InfoPropriete from "./AjoutProprieteStep/InfoPropriete";
import ChangeOfferModal from "../../../Agence/OptionAgence/OptionsStep/ChangeOfferModal";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  showModal,
} from "../../../../../utils/Utils";




const steps = [
  {
    id: "info-propriete",
    Component: InfoPropriete,
  },
  {
    id: "details-propriete",
    Component: DetailsPropriete,
  },
  {
    id: "gestion-propriete",
    Component: GestionPropriete,
  },
];

function AjoutPropriete() {
  const { step, navigation, index }: useStepType = useStep({
    initialStep: 0,
    steps,
  });
  const [proprieteItem] = useProprieteFromLocation();

  const {
    register,
    onSubmit,
    errors,
    setValue,
    description,
    pictures,
    mandatPicture,
    handleChangeDescription,
    setPictures,
    handleChangeMandatPicture,
    isLoading,
    address,
    onChangeAddress,
    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    handleChangeNumber,
    nbChambre,
    nbCuisine,
    handleChangeNumberCuisine,
    nbSDB,
    handleChangeNumberSDB,
    nbSalon,
    handleChangeNumberSalon,
    nbClim,
    handleChangeNumberClim,
    control,
    Controller,
    commoditesList,
    commodites,
    onChangeCommodite,
    unregister,
    handleChangeProprio,
    proprio,
    showChangeOffre,
  } = useCrudPropriete(navigation, proprieteItem);
  const user = useAppSelector((s) => s?.user?.user);


  const { Component } = step;
  const props = {
    navigation,
    register,
    onSubmit,
    errors,
    description,
    pictures,
    mandatPicture,
    handleChangeDescription,
    setPictures,
    handleChangeMandatPicture,
    isLoading,
    setValue,
    propriete: proprieteItem,
    address,
    onChangeAddress,
    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    handleChangeNumber,
    nbChambre,
    nbCuisine,
    handleChangeNumberCuisine,
    nbSDB,
    handleChangeNumberSDB,
    nbSalon,
    handleChangeNumberSalon,
    nbClim,
    handleChangeNumberClim,
    control,
    Controller,
    commoditesList,
    commodites,
    onChangeCommodite,
    handleChangeProprio,
    proprio,
    unregister,
  };
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(10, 10);
    setValue("step", index + 1);
    console.log(index);
  }, [index, step]);
//   useEffect(() => {
//     if (formData) {
//       for (let key of Object.keys(formData)) {
//         const val = formData[key];
//         if (key === "proprietaire" && val) {
//           setValue(key, parseInt(val));
//         } else {
//           setValue(key, val);
//         }

//         // console.log("here", key, val);
//       }
//     }
//   }, [formData, index]);

if (showChangeOffre != "") {
  showModal("changeOfferModal")
}


  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="kanimmo-dash-locataire-requetes-header-titre d-flex align-items-baseline gap-2">
                  <BiArrowBack
                    className="locataire-btn-arrow-back pe-2"
                    onClick={() => navigate(-1)}
                  />{" "}
                  {proprieteItem?.slug ? "Modifier" : "Ajouter"} une propriété
                  <span className="kanimmo-ajout-propriete-titre-span">
                    {index === 0
                      ? "Infos de la propriété"
                      : index === 1
                      ? "Détails de la propriété"
                      : "Gestion"}
                  </span>
                </h4>
              </div>
              <StepperHorizontal index={index} tabNum={3} />
            </div>
          </div>
        </div>
        <div className="bg-white p-3 filter-and-table-container">
          <div className="stepper-proprietes-form-container">
            <Component {...props} />
          </div>

          <div
            className="modal fade"
            id="changeOfferModal"
            aria-labelledby="changeOfferModalLabel"
            aria-hidden="true"
          >
            <ChangeOfferModal
              type={user?.user_type === "agence" ? "agence" : "proprietaire"}
            />
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default AjoutPropriete;
