import React, { useEffect, useState } from "react";
import { BsCalendar3 } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import moment from "moment";

import EmptyHeader from "../../../landingPage/EmptyHeader";
import FrontFooter from "../../../landingPage/FrontFooter/FrontFooter";
import "./NewsItem.css";
import NewsItemSkeleton from "./NewsItemSkeleton";
import { getImage } from "../../../../utils/Utils";
import { ITag } from "../../../../utils/api/Tag/tag.type";
import { BienPopulaire } from "./BienPopulaires";
import { ArticlesSimulaire } from "./ArticlesSimilaires";
import { CommentsNew } from "./NewsComments";
import BreadCrumbNews from "../../../shared/BreadCrumbNews";
import { useNewsFromLocation } from "../../../../utils/api/new/news.api";

function NewsItem() {
	const [item, isLoading] = useNewsFromLocation();
	return (
		<div className="landing-page-component">
			<EmptyHeader />
			<div className="page-content">
				<BreadCrumbNews title="News" pageName={item?.titre} />
			</div>
			{isLoading && <NewsItemSkeleton />}
			{!isLoading && (
				<section className="page-content-body-container container">
					<div className="partnership-item-page-content">
						<div className="partnership-item-row row">
							<div className="partnership-item-col col-lg-8 col-md-12">
								<div className="container-col-left-news-item">
									<div className="partnership-item-body mb-4">
										<div className="d-flex gap-1">
											{item.tag.map((tag: ITag) => (
												<span
													className="custom-btn-tag"
													key={tag?.slug}
												>
													{tag?.nom}
												</span>
											))}
										</div>
										<h1 className="partnership-item-title">
											{item.titre}
										</h1>
										<div className="news-item-content-header py-3 flex-sb-mobile">
											{/* <div className="d-flex align-items-center">
												<div className="container-img-profil-news-item">
													<img
														src="https://i.pravatar.cc/300"
														alt="profil"
														className="img-profil-news-item"
													/>
												</div>
												<div className="container-text-news-item ps-2">
													<span>
														Alpha Diallo
													</span>
												</div>
											</div> */}
											<div className="d-flex align-items-center">
												<div className="container-img-profil-news-item">
													<BsCalendar3
														style={{
															color: "#1F3CAD",
														}}
													/>
												</div>
												<div className="container-text-news-item ps-2">
													<span>
														{moment(
															item?.created_at
														).format(
															"DD MMMM YYYY"
														)}
													</span>
												</div>
											</div>
										</div>
										<div className="container-img-banner-news-item mb-3">
											<img
												src={getImage(
													item?.couverture
												)}
												alt="Banner"
												className="img-banner-news-item"
											/>
										</div>
										<div className="partnership-item-description-container">
											<p
												className="comment-text"
												dangerouslySetInnerHTML={{
													__html:
														item.content ||
														"",
												}}
											/>
										</div>
									</div>
									<div className="news-item-body flex-sb-mobile">
										<div className="content-share-news-item">
											<p className="text-share-news-item pb-3">
												Mots clés associés
											</p>
											<div className="content-related-tags gap-2">
												{item.tag.map(
													(item: ITag) => (
														<Link
															className="related-tags-item"
															key={
																item?.slug
															}
															to={`/news/tag/${item.slug}`}
															state={
																item
															}
														>
															{
																item?.nom
															}
														</Link>
													)
												)}
											</div>
										</div>
										<div className="content-share-news-item">
											<p className="text-share-news-item pb-3">
												Partager
											</p>
											<div className="content-share-on-social-media">
												<section>
													<div className="wrapper d-flex gap-2">
														<div className="custon-btn-social-share">
															<FaLinkedinIn />
														</div>
														<div className="custon-btn-social-share">
															<FaTwitter />
														</div>
														<div className="custon-btn-social-share">
															<FaFacebookF />
														</div>
													</div>
												</section>
											</div>
										</div>
									</div>
								</div>
								<CommentsNew item={item} />
							</div>
							<div className="others-partnerships-col col-lg-4 col-md-12">
								<ArticlesSimulaire />
								<BienPopulaire />
							</div>
						</div>
					</div>
				</section>
			)}

			<FrontFooter />
		</div>
	);
}

export default NewsItem;



