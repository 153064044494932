import React from "react";
import { useStep } from "react-hooks-helper";
import { AiFillPlusSquare } from "react-icons/ai";
import { NavLink, useSearchParams } from "react-router-dom";
import { Button } from "reactstrap";
import { useAppSelector } from "../../../../../redux/hooks";
import { useStepType } from "../../../../../utils/type";
import { isProprioJuriste, showModal } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import AjouterProprietesModal from "../../../../modal/agence/AjouterProprietesModal";
import ProprietesListe from "./ProprietesListe";
import "./ProprietesPQGSB.css";
import ImmeublesPQGSB from "./proprietesTabs/ImmeublesPQGSB";
import VillaAppartementPQGSB from "./proprietesTabs/VillaAppartementPQGSB";
import ChangeOfferModal from "../../../Agence/OptionAgence/OptionsStep/ChangeOfferModal";
import Swal from "sweetalert2";
import { useCompareNpPropieteAndMaxOffreQuery } from "../../../../../utils/api/offer/expireataabonnement.api";

const callChangeOffreModal = () => {
  Swal.fire({
    icon: "error",
    title:
      "Veuillez modifier votre abonnement pour pouvoir effectuer cette opération.",
    showConfirmButton: false,
    timer: 5000,
  });
  showModal("changeOfferModal");
};

const steps = [
  { id: "villa-appartement", Component: VillaAppartementPQGSB },
  { id: "immeubles", Component: ImmeublesPQGSB },
];

const tabs = ["Villa et Appartement", "Immeubles"];

function ProprietesPQGSB() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  const { user } = useAppSelector((s) => s?.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const word = searchParams.get("name");
  const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        name: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };

  const type_propriete = searchParams.get("type_propriete");
  const { data, error, refetch } = useCompareNpPropieteAndMaxOffreQuery(
    user.slug
  );

  const handleFilterByUpToDate = (
    e: React.FormEvent<HTMLSelectElement>
  ): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        type_propriete: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };

  const props = {
    handleFilterByUpToDate,
    handleFilterByName,
    type_propriete,
    word,
  };

  if (isProprioJuriste(user) && !user?.bien) {
    return (
      <div className="dashboard-admin-component kanimmo-tabs-container container-form-add-locataire no-access-apge">
        <div className="row container-dashboard-page">
          <AlertInfo message="Vous n'avez pas accès au données de cette page !" />
        </div>
      </div>
    );
  }

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Mes propriétés
          </h4>
          {data?.can_add_proprete && (
            <>
              {index === 1 ? (
                <NavLink
                  to="/proprietaire/gerer-par-le-proprietaire/ajouter-immeuble"
                  state={{ type_propriete: "immeuble" }}
                  className="btn btn-see-more"
                >
                  <AiFillPlusSquare style={{ fontSize: 22 }} />
                  <span className="ps-2">Ajouter un immeuble</span>
                </NavLink>
              ) : (
                <Button
                  data-bs-toggle="modal"
                  data-bs-target="#AjouterProprietesModal"
                  className="btn btn-see-more"
                >
                  <AiFillPlusSquare style={{ fontSize: 22 }} />
                  <span className="ps-2">Ajouter une propriété</span>
                </Button>
              )}
            </>
          )}

          {!data?.can_add_proprete && (
            <Button
              data-bs-toggle="modal"
              // data-bs-target="#AjouterProprietesModald"
              className="btn btn-see-more"
              onClick={() => callChangeOffreModal()}
            >
              <AiFillPlusSquare style={{ fontSize: 22 }} />
              <span className="ps-2">Ajouter une propriété</span>
            </Button>
          )}
        </div>
        <div
          className="modal fade"
          id="AjouterProprietesModal"
          aria-labelledby="AjouterProprietesModalLabel"
          aria-hidden="true"
        >
          <AjouterProprietesModal modalId="AjouterProprietesModal" />
        </div>
      </div>
      <div className="kanimmo-tabs-locataire-container">
        <ul className="tabs-container pt-5 tabs-container-agence">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              onClick={() => go(key)}
              role="button"
            >
              {tab}
            </li>
          ))}
        </ul>
        <div className="tabs-component-container">
          <Component {...props} />
        </div>
      </div>
      <div
        className="modal fade"
        id="changeOfferModal"
        aria-labelledby="changeOfferModalLabel"
        aria-hidden="true"
      >
        <ChangeOfferModal
          type={user?.user_type === "agence" ? "agence" : "proprietaire"}
        />
      </div>
    </div>
  );
}

export default ProprietesPQGSB;
