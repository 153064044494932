import React, { useEffect, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { useAppSelector } from "../../../../../redux/hooks";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { useGetBiensByProprietaireQNGPSBQuery } from "../../../../../utils/api/propriete/propriete.api";
import { UseDeletePropriete } from "./requestPropriete/UseCrudPropriete";
import { Link } from "react-router-dom";
import { formatMontant, formatPlurial, getImage } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import Pagination from "../../../../common/Pagination";
import { ProprieteCardSkeleton } from "../../../Agence/ProprietesAgence/ProprietesAgence";

function ProprietesListe({
  name,
  type_propriete,
}: {
  name?: any;
  type_propriete?: any;
}) {
  const proprietaire = useAppSelector((s) => s.user.user);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(9);
  const { data = { results: [] }, isLoading } =
    useGetBiensByProprietaireQNGPSBQuery({
      slug: proprietaire?.slug,
      name,
      type_propriete,
      page: page,
      limit: perPage,
    });

  return (
    <div className="row">
      {!isLoading &&
        data?.results?.map((item) => (
          <div className="col-xl-4 col-lg-6 col-md-6" key={item.id}>
            <div className="card kanimmo-proprietes-list-card mb-4 p-0 p-md-2">
              <Link
                to={`/proprietes/gerer-par-agence/proprietes/${item.slug}`}
                className="inherit-link"
                state={item}
              >
                <img
                  src={getImage(item?.pictures[0]?.image)}
                  className="card-img-top card-img-top-propriete"
                  alt={item.nom}
                />
              </Link>
              <div className="card-body">
                <h6 className="card-subtitle mb-2 kanimmo-proprietes-list-localisation">
                  {item.type_propriete + " - " + item.adresse}
                </h6>
                <h5 className="card-title kanimmo-proprietes-list-nom">
                  {item.nom}
                </h5>
                <div className="d-flex justify-content-between">
                  <p className="card-text kanimmo-proprietes-list-chambre">
                    {formatPlurial(item.nbre_chambres, "chambre")}
                  </p>
                  <p className="card-text kanimmo-proprietes-list-chambre">
                    {formatPlurial(item?.nbre_cuisines, "cuisine")}
                  </p>
                  <p className="card-text kanimmo-proprietes-list-chambre">
                    {item.nbre_salle_de_bains}
                    {item?.nbre_salle_de_bains <= 1
                      ? " salle de bain"
                      : " salles de bains"}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="kanimmo-proprietes-list-prix">
                    {formatMontant(item.prix)}
                  </div>
                  <div className="d-flex gap-3">
                    <Link
                      to={`/proprietes/gerer-par-agence/modifier-propriete/${item?.slug}`}
                      className="btn kanimmo-proprietes-list-btn-edit"
                      state={item}
                    >
                      <MdEdit />
                    </Link>
                    <DeletePropriete item={item} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {isLoading &&
        [...Array(6)]?.map((item, i) => <ProprieteCardSkeleton key={i} />)}
      {!isLoading && data?.results && data?.results?.length === 0 && (
        <AlertInfo message="Aucune propriété trouvée" />
      )}
      <div className="flex-r">
        <Pagination
          page={page}
          total={data?.count}
          perPage={perPage}
          onPageChange={(page: number) => setPage(page)}
        />
      </div>
    </div>
  );
}

export default ProprietesListe;

function DeletePropriete({ item }: { item: IPropriete }) {
  const onDelete = UseDeletePropriete(item?.slug, item);
  return (
    <button
      className="btn kanimmo-proprietes-list-btn-supprimer"
      onClick={onDelete}
    >
      <FiTrash2 />
    </button>
  );
}
