import React, { useEffect, useRef } from "react";
import { BiArrowBack } from "react-icons/bi";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { useGetAgencesQuery } from "../../../../../utils/api/agence/agence.api";
import { UserType } from "../../../../../utils/api/user/user.type";
import { Authorisations } from "../../../../../utils/constant";
import { getName } from "../../../../../utils/Utils";
import { BtnSubmit } from "../../../../common/Button";
import { FormError } from "../../../../common/CustomInputCheckbox";
import GoogleInput from "../../../../common/GoogleInput";
import { Input } from "../../../../common/Input";
import { SelectInput } from "../../../../common/SelectInput";
import UseCrudPersonnel from "./useForm/UseCrudPersonnel";

const userTypeOptions = [
  {
    label: "Comptable",
    value: UserType.comptable,
  },
  {
    label: "Gestionnaire de compte",
    value: UserType.gerant,
  },
  {
    label: "Community manager",
    value: UserType.marketeur,
  },
  {
    label: "Juriste",
    value: UserType.juriste,
  },
];

function AjouterUtilisateurAdmin() {
  const navigate = useNavigate();
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    address,
    onChangeAddress,
    user,
    phone,
    setPhone,
    setValue,
  } = UseCrudPersonnel();
  const { data: agences } = useGetAgencesQuery({});

  const ref = useRef<any>();

  useEffect(() => {
    if (errors?.telephone?.message) {
      ref.current.scrollIntoView();
    }
  }, [errors?.telephone]);

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            <BiArrowBack onClick={() => navigate(-1)} />
            {user ? "Modifier l'" : "Ajouter un"} collaborateur
          </h4>
        </div>
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Nom"
                      id="nom"
                      placeholder="Nom"
                      required
                      {...register("nom")}
                      error={errors?.nom}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Prénom"
                      id="prenom"
                      placeholder="Prénom"
                      required
                      {...register("prenom")}
                      error={errors?.prenom}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="email"
                      label="Email"
                      id="email"
                      placeholder="Email"
                      required
                      {...register("email")}
                      error={errors?.email}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="phone"
                        className="form-label form-label-modal-custom"
                        aria-labelledby="phone"
                      >
                        Téléphone
                        <span
                          ref={ref}
                          className="text-danger"
                          style={{ fontSize: 24 }}
                        >
                          *
                        </span>
                      </label>
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        containerClass="form-control auth-form-control mb-3 p-0"
                        inputClass="form-control form-control-modal-custom"
                        country={"sn"}
                        placeholder=""
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        countryCodeEditable={false}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        onChange={(phone, country: any) => {
                          setPhone(phone);

                          if (
                            country?.format?.match(/\./g)?.length ===
                            phone?.length
                          ) {
                            setValue("telephone", phone);
                          }
                        }}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      <FormError error={errors?.telephone?.message} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="adress"
                        className="form-label form-label-modal-custom"
                      >
                        Adresse
                      </label>
                      <GoogleInput
                        className="form-control form-control-modal-custom form-control-params-custom"
                        value={address}
                        onChange={onChangeAddress}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Code postale"
                      id="boite_postale"
                      placeholder="Code postale"
                      {...register("code_postal")}
                      error={errors?.code_postal}
                    />
                  </div>
                  <div className="col-md-6">
                    <SelectInput
                      label="Type d'utilisateur"
                      required
                      hiddenFirstOption={true}
                      // hiddenFirstOption
                      options={userTypeOptions}
                      {...register("user_type")}
                      error={errors.user_type}
                    />
                  </div>
                  {/* <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="type_client"
                        className="form-label form-label-modal-custom"
                      >
                        Agence représentée
                        <span
                          className="text-danger"
                          style={{
                            fontSize: 24,
                          }}
                        >
                          *
                        </span>
                      </label>
                      <select
                        className="form-select form-select-modal-custom"
                        aria-label="Default select example"
                        {...register("agence_id")}
                      >
                        {agences?.results?.map((item) => (
                          <option value={item.id} key={item.slug}>
                            {getName(item)}
                          </option>
                        ))}
                      </select>
                      {<FormError error={errors.agence_id} />}
                    </div>
                  </div> */}
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <p className="custom-titre-details-parametre">
                        Permissions
                      </p>
                      <div className="container-checkbox-input">
                        <div className="form-checkbox-input checkbox-input">
                          <div className="custom-permission-container">
                            {Authorisations?.map((item: any) => (
                              <label key={item?.field}>
                                <input
                                  type="checkbox"
                                  {...register(item?.field)}
                                  id={`checkbox-${item?.field}`}
                                />
                                <span>{item?.label}</span>
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-btn-modal row my-4">
                  <div className="col-md-6 offset-md-6">
                    <div className="flex-r gap-4">
                      <button
                        className="btn auth-submit-annuler"
                        onClick={() => navigate(-1)}
                      >
                        Annuler
                      </button>
                      <BtnSubmit label="Enregistrer" isLoading={isLoading} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterUtilisateurAdmin;
