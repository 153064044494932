import * as React from "react";
import "./LandingPage.css";
import InMacBook1 from "../../assets/appImages/img-service1.png";
import InMacBook2 from "../../assets/appImages/img-service2.png";
import InMacBook3 from "../../assets/appImages/img-service3.png";
import InMacBook4 from "../../assets/appImages/img-service4.png";
import Imgbg from "../../assets/appImages/Imgbg.png";
import EmptyHeader from "./EmptyHeader";
import FrontFooter from "./FrontFooter/FrontFooter";
import ReactPlayer from "react-player";
import Carousel from "react-multi-carousel";
import PlayImg from "../../assets/icons/Play.png";
import imgservicetrois from "../../assets/appImages/imgservicetrois.png";
import imgservicequatre from "../../assets/appImages/imgservicequatre.png";
import video from "../../assets/video/kanimmo2.mp4";
import { NavLink } from "react-router-dom";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    itemsToScroll: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    itemsToScroll: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    itemsToScroll: 1,
  },
};
const LandingPageV2 = () => {
  return (
    <div className="landing-page-component">
      <EmptyHeader />
      <div className="page-content">
        <section className="section-banner-landing-page-v2 py-5">
          <div className="banner-lading-page-v2-container">
            <div className="content-title-lading-page-v2 mt-5">
              <h2 className="title-lading-page-v2">
                La gestion immobilière <br /> en toute simplicité
              </h2>
              <div className="title-lading-page-v2-para-container mt-4">
                <p className="title-lading-page-v2-para">
                  Kanimmo est une plateforme complète de gestion locative conçue
                  pour rationaliser les tâches administratives, améliorer la
                  communication avec les locataires et assurer un suivi
                  financier transparent. Avec Kanimmo, les propriétaires et les
                  agences immobilières peuvent accroître leur efficacité,
                  réduire les frustrations et augmenter leurs revenus.
                </p>
              </div>
            </div>
            <div className="content-btn-banner-landing-page-v2 my-5 text-center">
              <button
                className="btn btn-join-kan-immo"
                data-bs-toggle="modal"
                data-bs-target="#RegisterModal"
              >
                Nous rejoindre
              </button>
            </div>
          </div>
        </section>
        <section className="section-container-img-macbook">
          <div className="container-carousel-events">
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={true}
              responsive={responsive}
              ssr={false}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              arrows={false}
              customTransition="transform 1000ms ease-in-out"
              transitionDuration={2000}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {DatasCaroussel?.map((item) => (
                <div className="container-slide-img" key={item?.id}>
                  <div className="container-img-macbook">
                    <img
                      src={item?.img}
                      alt="mackbook"
                      className="img-macbook"
                    />
                  </div>
                  <div>
                    <h3 className="title-section-img-macbook">{item?.titre}</h3>
                    <div className="text-section-img-macbook-container">
                      <p className="text-section-img-macbook">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </section>
        <section className="section-about-kan-immo">
          <div className="row d-flex align-items-center mb-5">
            <div className="col-md-6 mb-3">
              <div className="col-left-about-kan-immo">
                <h2 className="title-col-about-kan-immo">
                  Vous êtes une agence immobilière ?
                </h2>
                <div className="content-text-description-about-kan-immo">
                  <p className="text-description-about-kan-immo">
                    La plateforme Kanimmo aide les agences immobilières à
                    optimiser leurs opérations de gestion en automatisant
                    jusqu'à 85% de votre activité, réduisant ainsi les tâches
                    administratives chronophages journalières et les ressources
                    nécessaires pour les exécuter. Notre mission est de vous
                    faire gagner du temps et de l'argent pour vous permettre de
                    vous concentrer sur ce qui compte le plus pour vous:
                    développer votre activité, fidéliser vos bailleurs, et
                    agrandir votre parc locatif.
                  </p>
                </div>
                <div className="content-btn-banner-landing-page-v2 my-3">
                  <NavLink
                    className="btn btn-join-kan-immo no-link"
                    to={"/inscription-agence"}
                  >
                    INSCRIvez-vous
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="col-right-about-kan-immo position-relative">
                <div className="container-col-img-right">
                  <img
                    src={imgservicetrois}
                    alt="KanImmo"
                    className="img-col-right"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-row-about-kan-immo">
            <div className="row d-flex align-items-center">
              <div className="col-md-6 mb-3">
                <div className="col-right-about-kan-immo position-relative">
                  <div className="container-col-img-right">
                    <img
                      src={imgservicequatre}
                      alt="KanImmo"
                      className="img-col-left"
                    />
                  </div>
                  {/* <div className="content-img-absolute">
                    <img
                      src={Capture2}
                      className="img-absolute-capture2"
                      alt="capture"
                    />
                  </div> */}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="col-left-about-kan-immo">
                  <h2 className="title-col-about-kan-immo">
                    Vous êtes propriétaire ?
                  </h2>
                  <div className="content-text-description-about-kan-immo">
                    <p className="text-description-about-kan-immo">
                      La gestion de vos biens immobiliers doit être facile, sans
                      tracas et rentable. Kanimmo propose une gamme complète
                      d'outils et de services conçus pour simplifier la gestion
                      de vos biens immobiliers. Gagnez du temps et de l'argent,
                      et garantissez la satisfaction de vos locataires.
                    </p>
                  </div>
                  <div className="content-btn-banner-landing-page-v2 my-3">
                    <NavLink
                      className="btn btn-join-kan-immo no-link"
                      to={"/inscription-proprietaire"}
                    >
                      INSCRIVEZ-VOUS
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="video-kan-immo">
          <div className="container-video-kan-immo">
            <h2 className="title-video-kan-immo mb-3">Explorez Kanimmo</h2>
          </div>
          <div className="content-video-player">
            <ReactPlayer
              className="about-video-kan-immo"
              light={Imgbg}
              controls
              url={video}
              playing
              playIcon={<img src={PlayImg} alt="video" />}
              width={1000}
              height={500}
            />
          </div>
          {/* <div className="content-video-player">
            <ReactPlayer
              className="about-video-kan-immo"
              light={Imgbg}
              controls
              url="Volkeno.mp4"
              playing
              playIcon={<img src={PlayImg} alt="video" />}
              width={1000}
              height={500}
            />
          </div> */}
        </section>
      </div>
      <FrontFooter />
    </div>
  );
};

export default LandingPageV2;

const DatasCaroussel = [
  {
    id: 1,
    img: InMacBook1,
    titre: "Gestion de biens locatifs",
    description:
      "Kanimmo offre aux propriétaires et aux agences immobilières une solution adaptée à leurs besoins en matière de gestion des biens locatifs.",
  },
  {
    id: 2,
    img: InMacBook2,
    titre: "Gestion des relations locataires",
    description:
      "Nous vous aidons à rester en contact avec vos locataires et à entretenir une relation positive avec eux pour une meilleure rétention et une moindre fréquence des contentieux.",
  },
  {
    id: 3,
    img: InMacBook3,
    titre: "Gestion financière",
    description:
      "Kanimmo vous aide à suivre vos transactions financières et à assurer la transparence de vos revenus et de vos dépenses.",
  },
  {
    id: 4,
    img: InMacBook4,
    titre: "Rapports et analyses",
    description:
      "Générez des rapports détaillés et des analyses pour vous aider à prendre des décisions éclairées concernant vos propriétés.",
  },
];
