const LocatairesNonPayeTableData: any = [
	
	// {
	// 	id: 1,
	// 	num: "#124",
	// 	nom: "Fama Kébé",
    //     villa: 'Villa Soxna Mai',
    //     montant: 150000
	// },
	// {
	// 	id: 2,
	// 	num: "#124",
	// 	nom: "Fama Kébé",
    //     villa: 'Villa Soxna Mai',
    //     montant: 150000

	// },
	// {
	// 	id: 3,
	// 	num: "#124",
	// 	nom: "Fama Kébé",
    //     villa: 'Villa Soxna Mai',
    //     montant: 150000
	// },
	// {
	// 	id: 4,
	// 	num: "#124",
	// 	nom: "Fama Kébé",
    //     villa: 'Villa Soxna Mai',
    //     montant: 150000
	// },
];

export default LocatairesNonPayeTableData