import React from "react";
import { useStep } from "react-hooks-helper";
import { AiFillPlusSquare } from "react-icons/ai";
import { NavLink, useSearchParams } from "react-router-dom";
import { useStepType } from "../../../../utils/type";
import AgenceAdmin from "./AgenceAdmin";
import ProprietaireAdmin from "./ProprietaireAdmin";
import LocataireAdmin from "./LocataireAdmin";

import "./MesClients.css";
import AccountManager from "./AccountManager";

const steps = [
  { id: "agence", Component: AgenceAdmin },
  { id: "proprietaire", Component: ProprietaireAdmin },
  { id: "Locataires", Component: LocataireAdmin },
  { id: "account-remove", Component: AccountManager },
];

const tabs = ["Agence", "Proprietaire", "Locataires", "Suppressions de compte"];  

function MesClients() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const props = { searchParams, setSearchParams };
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Mes clients
          </h4>
          <div className="container-btn-add">
            {index === 0 ? (
              <NavLink
                to="/admin/ajouter-client-agence"
                className="btn btn-see-more"
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter une agence</span>
              </NavLink>
            ) : index === 1 ? (
              <NavLink
                to="/admin/ajouter-client-proprietaire"
                className="btn btn-see-more"
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter un propriétaire</span>
              </NavLink>
            ) : null}
          </div>
        </div>
        <div className="kanimmo-tabs-locataire-container">
          <ul className="tabs-container pt-5 tabs-container-agence">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => {
                  go(key);
                  setSearchParams({});
                }}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MesClients;
