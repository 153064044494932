import moment from "moment";
import React, { useState ,useEffect } from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useGetContactMessagesQuery } from "../../../../utils/api/contact/contact.api";
import { createMarkup } from "../../../../utils/Utils";
import ChangeStatusMessageModal from "../../../modal/admin/ChangeStatusMessageModal";
import DetailsMessageModal from "../../../modal/admin/DetailsMessageModal";
import { IContact } from "../../../../utils/api/contact/contact.type";
import "./Lead.css";
import Pagination from "../../../common/Pagination";


function Lead() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [item, setItem] = useState<IContact>();
  const { data = { results: [] }, isLoading } = useGetContactMessagesQuery({
    page: page,
    limit: perPage,
  });
  // console.log('datas', data);


  return (
    <div className="landing-page-container">
      <div className="container-fluid  bg-white">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Message de contacts
          </h4>
        </div>
        <div className="filter-and-table-container filter-and-table-container-lead-admin ms-5 me-4 mt-3">
          {!isLoading && data?.results?.length > 0
            ? data?.results?.map((item) => (
                <div className="mb-3" key={item?.slug}>
                  <div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
                    <div className="row w-100 align-items-center">
                      <div className="col-md-7">
                        <div
                          className="admin-message-contact-card-description d-flex btn p-0"
                          style={{
                            textOverflow: "ellipsis",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target={`#DetailsMessageModal${item?.id}`}
                          role="button"
                          dangerouslySetInnerHTML={createMarkup(
                            item.message,
                            true,
                            50
                          )}
                          onClick={() => setItem(item)}
                        />
                        <p className="admin-message-contact-card-frequence fz-14 mb-0">
                          {moment(item?.created_at).format(
                            "DD/MM/YYYY à HH:MM"
                          )}
                        </p>
                      </div>
                      <div className="col-md-2">
                        <button
                          className={`btn message-btn ${
                            item.status === "new"
                              ? "bg-status"
                              : item.status === "traité"
                              ? "bg-success"
                              : "bg-secondary"
                          }`}
                          data-bs-toggle="modal"
                          data-bs-target="#ChangeStatusMessageModal"
                          aria-expanded="false"
                          onClick={() => setItem(item)}
                        >
                          {item.status === "new"
                            ? "Nouveau"
                            : item.status === "traité"
                            ? "Traité"
                            : "En cours"}
                        </button>
                      </div>
                      <div className="col-md-3 flex-r">
                        <p className="admin-message-contact-card-description mb-0">
                          {item?.user?.slug ? item?.name : null}
                        </p>
                      </div>
                    </div>
                    <button
                      className="no-style-btn accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#responseContact${item.slug}`}
                      aria-expanded="false"
                      aria-controls="responseContact1"
                    ></button>
                  </div>
                  <div
                    className="modal fade"
                    id={`DetailsMessageModal${item?.id}`}
                    aria-labelledby="DetailsMessageModalLabel"
                    aria-hidden="true"
                  >
                    <DetailsMessageModal
                      item={item}
                      modalId={`DetailsMessageModal${item?.id}`}
                    />
                  </div>
                </div>
              ))
            : ""}
          {isLoading &&
            [...Array(5)].map((item, i) => (
              <MessageContactItemSkeleton key={i} />
            ))}
          <div className="flex-r">
            <Pagination
              page={page}
              total={data?.count}
              perPage={perPage}
              onPageChange={(page: number) => setPage(page)}
            />
          </div>
        </div>
      </div>

      <ChangeStatusMessageModal item={item} />
    </div>
  );
}

export default Lead;

export function MessageContactItemSkeleton() {
  return (
    <div className="mb-3">
      <div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
        <div className="row w-100 align-items-center">
          <div className="col-md-7">
            <VolkenoSkeleton variant="rect" width="100%" height={10} />
            <VolkenoSkeleton variant="rect" width="100%" height={10} />
          </div>
          <div className="col-md-2">
            <VolkenoSkeleton variant="rect" width="100%" height={10} />
          </div>
          <div className="col-md-3">
            <VolkenoSkeleton variant="rect" width="100%" height={10} />
          </div>
        </div>
      </div>
    </div>
  );
}
