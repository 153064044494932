import React from "react";
import { ITag } from "../../../utils/api/Tag/tag.type";
import { BtnCloseModal, BtnSubmit } from "../../common/Button";
import { Input } from "../../common/Input";
import { useCrudTag } from "../../TableauDebord/Admin/News/useForm/useCrudTag";

function AddTagModal({ item }: { item?: ITag }) {
  const { register, onSubmit, errors, isLoading, reset } = useCrudTag(item);
  return (
    <div
      className="modal fade"
      id="AddTagModal"
      aria-labelledby="AddTagModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header px-2 py-1">
            <h5 className="modal-title" id="AddTagModalLabel">
              Ajouter un tag
            </h5>
            <BtnCloseModal label="" onClick={reset} />
          </div>
          <form action="" onSubmit={onSubmit}>
            <div className="row py-3 px-2">
              <div className="col-12 mb-4 ">
                
                <Input
                  label="Tag"
                  id="tag"
                  type="text"
                  placeholder="tag"
                  {...register("nom")}
                  error={errors?.nom}
                  className="form-control"
                />
              </div>

              <div className="flex-r">
                <BtnSubmit
                  label={item ? "Modifier" : "Ajouter"}
                  isLoading={isLoading}
                  style={{ padding: "5px 10px", fontSize: 14 }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddTagModal;
