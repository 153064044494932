/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import ValidationManuelTable from "./ValidationManuelTable";
function ValidationManuel() {
  return (
    <div className="kanimmo-proprietes-container">
      <div className="proprietes-component-container">
        <ValidationManuelTable />
      </div>
    </div>
  );
}

export default ValidationManuel;
