import React from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../../utils/type";
import ContratPQNGPSB from "./AdministratifStep/ContratPQNGPSB";
import EtatDesLieuxPQNGPSB from "./AdministratifStep/EtatDesLieuxPQNGPSB";
import LitigesPQNGPSB from "./AdministratifStep/LitigesPQNGPSB";
import "./AdministratifPQNGPSB.css";
import Rappels from "../../../Admin/Options/OptionsAdminSteps/Rappels";
import { useAppSelector } from "../../../../../redux/hooks";
import CompteRenduDeGestion from "../../ProprietaireGererParLeProprietaire/Administratif/AdministratifStep/CompteRenduDeGestion";

function AdministratifPQNGPSB() {
  const { user } = useAppSelector((s) => s?.user);
  const userType = user?.user_type;
  const steps = [
    { id: "Compte rendu de gestion", Component: CompteRenduDeGestion },
    { id: "etat-des-lieux", Component: EtatDesLieuxPQNGPSB },
    { id: "contrat", Component: ContratPQNGPSB },
    { id: "litige", Component: LitigesPQNGPSB },
    { id: "rappel", Component: Rappels },
  ];

  const tabs = [
    "Compte rendu de gestion",
    "Etat des lieux",
    "Contrats",
    userType !== "juriste" || userType !== "agence" ? "" : "Contentieux",
    // "Rappels",
  ];
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Administratif
          </h4>
        </div>
        <div className="kanimmo-tabs-container py-3">
          <ul className="tabs-container custom-tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdministratifPQNGPSB;
