import React from "react";
import { useStep } from "react-hooks-helper";
import { AiFillPlusSquare } from "react-icons/ai";
import { useStepType } from "../../../../utils/type";
import AjouterCategorieEntreeModal from "../../../modal/comptabilite/AjouterCategorieEntreeModal";
import AjouterCategorieModal from "../../../modal/comptabilite/AjouterCategorieModal";
import CategorieDepense from "./steps/CategorieDepense";
import CategorieEntree from "./steps/CategorieEntree";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

const steps = [
  { id: "depense", Component: CategorieDepense },
  { id: "entree", Component: CategorieEntree },
];

const tabs = ["Dépense", "Revenus"];

function Categorie() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const navigate = useNavigate();

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          {/* <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Catégorie
          </h4> */}
          <h4
            className="kanimmo-dash-locataire-requetes-header-titre"
            onClick={() => navigate(-1)}
          >
            <BiArrowBack />
            <span className="ps-3">Catégorie</span>
          </h4>
          {index === 0 ? (
            <>
              <button
                className="btn btn-see-more"
                title="Ajouter une dépense"
                data-bs-toggle="modal"
                data-bs-target="#AddCategorieModal"
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter une catégorie</span>
              </button>
              <div
                className="modal fade"
                id="AddCategorieModal"
                aria-labelledby="AddCategorieModalLabel"
                aria-hidden="true"
              >
                <AjouterCategorieModal isAccountingPage={false} modalId="AddCategorieModal" />
              </div>
            </>
          ) : (
            <>
              <button
                className="btn btn-see-more"
                title="Ajouter une entrée"
                data-bs-toggle="modal"
                data-bs-target="#AddCategorieEntreeModal"
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter une catégorie</span>
              </button>
              <div
                className="modal fade"
                id="AddCategorieEntreeModal"
                aria-labelledby="AddCategorieEntreeModalLabel"
                aria-hidden="true"
              >
                <AjouterCategorieEntreeModal modalId="AddCategorieEntreeModal" />
              </div>
            </>
          )}
        </div>

        <div className="kanimmo-tabs-container py-3">
          <ul className="tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Categorie;
