import React, {  } from "react";
import { BtnSubmit } from "../../common/Button";
import { Input } from "../../common/Input";
import { locataireExistant } from "../../TableauDebord/Agence/Administratif/requestForm/locataireExistant";

function LocataireExistantModal() {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    handleReset
  } = locataireExistant();

  return (
    <div
      className="modal fade"
      id="locataireExistantModal"
      aria-labelledby="locataireExistantModal"
      data-bs-backdrop="static"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
                Ajouter  un locataire existant
            </h5>
          </div>
          <div className="modal-body">
            <div className="container-form pt-3">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-12">
                    <Input
                      type="email"
                      label="email du locataire"
                      min={1}
                      id="email"
                      placeholder="email du locataire"
                      required
                      {...register("email")}
                      error={errors.email}
                    />
                  </div>
                </div>
                <div className="container-btn-modal row mt-5">
                  <div className="col-md-3">
                    <button
                      className="btn btn-cancelled"
                      data-bs-dismiss="modal"
                      type="button"
                      onClick={() => handleReset()}
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="col-md-6 offset-md-3 flex-r">
                    <BtnSubmit
                      label= "Ajouter"
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocataireExistantModal;
