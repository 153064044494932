import React from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../../utils/type";
import Contrat from "./AdministratifTabs/Contrat";
import EtatDesLieux from "./AdministratifTabs/EtatDesLieux";
import HistoriquePaiement from "./AdministratifTabs/HistoriquePaiement";
import "./Administratif.css";
import NotesAnnoncesLocataire from "./AdministratifTabs/NotesAnnoncesLocataire";
import { AiFillPlusSquare } from "react-icons/ai";
import QuittanceLocataire from "./AdministratifTabs/QuittanceLocataire";
import FacturesTabs from "./AdministratifTabs/FacturesTabs";
import LitigesTabs from "./AdministratifTabs/LitigesTabs";

const steps = [
  { id: "contrat", Component: Contrat },
  { id: "historique-paiement", Component: HistoriquePaiement },
  // { id: "historique-requetes", Component: HistoriqueRequetes },
  { id: "etat-lieux", Component: EtatDesLieux },
  { id: "notes-annonces", Component: NotesAnnoncesLocataire },
  { id: "quittance", Component: QuittanceLocataire },
  { id: "facture", Component: FacturesTabs },
  { id: "litige", Component: LitigesTabs },
];

const tabs = [
  "Contrat",
  "Historique des paiement",
  // "Historique des requetes ",  NE PAS SUPPRIMER
  "Etat des lieux",
  "Notes et annonces",
  "Quittances",
  "factures",
  "Contentieux",
];

function Administratif() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Administratif
          </h4>
          {/* {tabs.map((tab, key) => {
                        return(
                            <>
                                {(index === key && tab !== "Contrat" && tab !== "Etat des lieux") && <button className='btn btn-upload-locataire' key={key}><MdCloudUpload className='btn-upload-locataire-icon' /> Upload la condition des lieux</button>}
                                {(index === key && tab === "Etat des lieux") && (
                                    <button
                                    className="btn btn-see-more dropdown-toggle btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#AjouterEtatDesLieux"
                                    role="button"
                                >
                                    <div className="user-acount-dashboard-header-display g-2">
                                        <span className='ps-2'>Remplir l’état des lieux</span>
                                    </div>
                                </button>
                                )}
                            </>
                        )
                    })} */}
        </div>
        <div className="kanimmo-tabs-container py-3">
          <ul className="tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Administratif;
