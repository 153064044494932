import React from 'react'
import './LineChart.css'
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js'
import { GraphType } from '../../../../../utils/api/user/user.type';
import moment from 'moment';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )
const LineChart = ({ data }: { data?: GraphType[] }) => {
// console.log("data data", data)
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart',
          },
        },
        stroke: {
        curve: 'smooth',
        },
      };
      
      const labels = data?.map((item) => moment(item?.month).format("MMM"));
      
      const datas = {
        labels,
        datasets: [
          {
            label: 'Revenus',
            data: data?.map((item) => item?.entree),
            borderColor: '#FCAE3B',
            backgroundColor: '#FCAE3B',
          },
          {
            label: 'Dépenses',
            data: data?.map((item) => item?.depense),
            borderColor: '#1F3CAD',
            backgroundColor: '#1F3CAD',
          },
        ],
      };
    return (
        <div className="chart-item-container">
                <div className="row mb-2 chart-infos-row">
                    <div className="col-md-6 mb-2">
                        <h2 className="chart-item-title">Aperçu</h2>
                    </div>
                </div>
                <Line
                    data={datas}
                    option={options}
                />
            </div>
    )
}

export default LineChart