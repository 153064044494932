import React from "react";
import AccountManagerTable from "../../../tables/Admin/AccountManagerTable/AccountManagerTable";

function AccountManager() {
  return (
    <div className="bg-white p-3 filter-and-table-container">
      <div className="flex-sb mb-2 px-4">
        <div>
          <h2 className="chart-item-admin-title">
            Demandes de suppréssion de compte
          </h2>
        </div>
      </div>
      <div>
        <AccountManagerTable />
      </div>
    </div>
  );
}

export default AccountManager;
