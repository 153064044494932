import React from "react";
import Chart from "react-apexcharts";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useGetInfosByProprietaireqnpgbQuery } from "../../../../../../utils/api/proprietaire/proprietaire.api";
import { graph } from "../../../../../../utils/api/proprietaire/proprietaire.type";
import "./LineChart.css";

const LineChart = () => {
  var [showNotifications, setShowNotifications] = React.useState(false);

  const proprietaire = useAppSelector((s) => s.user.user);

  const { data } = useGetInfosByProprietaireqnpgbQuery({
    slug: proprietaire?.slug,
  });

  const montant_recouverts = data?.graph?.map((item: graph) => item.montant);

  const locataires = data?.graph?.map((item: graph) => item.locataire);
  const series = [
    {
      data: montant_recouverts,
      name: "Montant recouvrés",
    },
    // {
    //   data: locataires,
    //   name: "Locataires",
    // },
  ];
  const options: any = {
    chart: {
      height: 280,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#1AA981", "#FCAE3B"],
    fill: {
      type: "gradient",
      gradient: {
        type: "horizontal",
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.2,
        stops: [0, 100, 10],
      },
      colors: ["#C4C4C4", "#3751FF"],
    },
    xaxis: {
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Avr",
        "Mai",
        "Jui",
        "Jui",
        "Aou",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          colors: "#9FA2B4",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#9FA2B4",
        },
      },
    },
  };
  console.log("testecosole", montant_recouverts);
  console.log("montant", data);

  return (
    <div className="chart-item-container">
      <div className="row mb-2 chart-infos-row">
        <div className="col-md-6 mb-2">
          <h2 className="chart-item-title">Aperçu</h2>
          <div>{/* <p>À partir de 20 juillet 2022, à 09:00</p> */}</div>
        </div>
      </div>
      <Chart
        className="chart-dashboard-marketplace"
        options={options}
        series={series}
        data="Ventes"
        type="area"
        width="100%"
        height="350"
      />
    </div>
  );
};

export default LineChart;
