import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  AnnonceFormData,
  IAnnonce,
} from "../../../../../utils/api/annonces/annonce.type";
import {
  cleannerError,
  getImage,
  isAgence,
  isProprietaireOwner,
  onHide,
} from "../../../../../utils/Utils";
import {
  useAddOrUpdateAnnonceMutation,
  useDeleteAnnonceMutation,
} from "../../../../../utils/api/annonces/annonce.api";
import { useNavigate } from "react-router-dom";
import { Color } from "../../../../../utils/theme";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetAgencesQuery } from "../../../../../utils/api/agence/agence.api";

function useAnnonceForm(modalId: string, annonce?: IAnnonce) {
  const validationSchema = yup.object().shape({
    hasFile: yup.boolean(),
    titre: yup.string().required().label("Titre"),
    content: yup
      .string()
      .label("Content")
      .transform((val: any) =>
        val.replace(/<p>(?!\s*<\/p>)(.*?)<\/p>/gs, "$1\n\n")
      ),
    // image: yup.mixed().required().label("Image")
    // .when("hasFile", {
    //   is: false,
    //   then: yup.mixed().required().label("Image"),
    // }),
    image: yup.mixed().when("hasFile", {
      is: false,
      then: yup.mixed().required().label("Image"),
    }),
  });

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
  } = useForm<AnnonceFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [content, setContent] = useState<string>("");
  const [previewImage, setPreviewImage] = useState(
    annonce?.image ? getImage(annonce.image) : null
  );

  const [addOrUpdateAnnonce, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateAnnonceMutation();

  const navigate = useNavigate();

  const handelImageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("image", { message: "Image format is incorrect." });
    }
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      register("image");
      setValue("image", file);
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: annonce
          ? "Annonce modifiée avec succès !"
          : "Annonce ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!annonce) {
          reset();
          setPreviewImage(null);
          setContent("");
        }
        onHide(modalId);
        // navigate("/agence/locataires");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const { user } = useAppSelector((s) => s?.user);

  const onSubmit = (data: any) => {
    if (!annonce) {
      data["auteur"] = user?.agence_id?.slug ? user?.agence_id?.id : user?.id;
      data["type"] = "annonce";
    }

    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "image") {
        if (data[key] && data[key] !== undefined) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    // console.log("data submitted", data);
    addOrUpdateAnnonce({ slug: annonce?.slug, data: fd });
  };

  const handleReset = () => {
    if (!annonce) {
      reset();
    }
  };

  useEffect(() => {
    if (annonce?.id) {
      const fields: (keyof AnnonceFormData)[] = ["titre", "content", "image"];

      for (let field of fields) {
        if (field !== "image") {
          register(field);
          setValue(field, annonce[field]);
        }

        if (field === "image" && annonce["image"]) {
          setValue("hasFile", true);
        } else {
          setValue("hasFile", false);
        }
        if (field === "content") {
          setContent(annonce[field] || "");
        }
      }
    }
  }, [annonce]);

  const handleChangeContent = useCallback((value: string) => {
    setContent(value);
    setValue("content", value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    content,
    handleChangeContent,
    handleReset,
    handelImageChange,
    previewImage,
  };
}

export default useAnnonceForm;

export function UseDeleteAnnonce(slug: string, modalID: string, item: any) {
  const [deleteData] = useDeleteAnnonceMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: !item?.image
        ? `Êtes-vous sûr de vouloir supprimer cette note ?`
        : `Êtes-vous sûr de vouloir supprimer cette annonce ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        // console.log(modalID)
        Swal.fire({
          icon: "success",
          title: !item?.image
            ? `Note supprimée avec succès!`
            : `Annonce supprimée avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          onHide("EnvoieSuppressionNoteModal");
        });
      }
    });
  };
  return onDelete;
}
