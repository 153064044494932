import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import { IAnnonce } from "../../../utils/api/annonces/annonce.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import useAnnonceForm from "../../TableauDebord/Agence/Administratif/requestForm/useAnnonceForm";

function AjouterAnnonceModal({
  annonce,
  modalId,
}: {
  annonce?: IAnnonce;
  modalId: string;
}) {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    setValue,
    handelImageChange,
    handleChangeContent,
    previewImage,
    content
  } = useAnnonceForm(modalId, annonce);

  useEffect(() => {
    if (annonce) {
      setValue("slug", annonce?.slug);
      setValue("titre", annonce?.titre);
      setValue("content", annonce?.content);
    }
  }, [annonce]);

  return (
		<div className="modal-dialog">
			<div className="modal-content">
				<div className="modal-body">
					<div className="container-form">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-12">
									<div className="mb-3">
										<label
											htmlFor="titre"
											className="form-label form-label-modal-custom"
										>
											Titre de l’annonce
										</label>
										<input
											type="text"
											placeholder="Titre"
											className="form-control form-control-modal-custom"
											id="titre"
											{...register("titre")}
										/>
										{errors?.titre && (
											<FormError
												error={
													errors?.titre?.message
												}
											/>
										)}
									</div>
								</div>
								<div className="col-md-12">
									<div className="mb-3">
										<label
											htmlFor="image"
											className="form-label form-label-modal-custom"
										>
											Ajouter une image
										</label>
										<input
											type="file"
											placeholder="Ajouter une image"
											className="form-control form-control-modal-custom"
											id={`image${annonce?.slug}`}
											onChange={(e) =>
												handelImageChange(e)
											}
											accept="image/*"
											name="image"
										/>
										{errors?.image && (
											<FormError
												error={
													errors?.image?.message
												}
											/>
										)}
										{previewImage && (
											<div className="img-preview-container">
												<img
													src={previewImage}
													className="type-img-preview"
												/>
											</div>
										)}
									</div>
								</div>
								<div className="col-md-12">
									<div className="mb-3">
										<label
											htmlFor="content"
											className="form-label form-label-modal-custom"
										>
											Note
										</label>
										{/* <textarea
                      className="form-control form-control-modal-custom"
                      id="content"
                      rows={4}
                      onChange={(val: any) => handleChangeContent(val)}
                    ></textarea> */}
										<ReactQuill
											className="editor-cgu"
											value={content}
											onChange={(value) =>
												handleChangeContent(value)
											}
										/>
									</div>
								</div>
							</div>
							<div className="container-btn-modal row">
								<div className="col w-100 d-flex justify-content-between align-items-center">
									<div className="btn-cancelled-container">
										<button
											className="btn btn-cancelled"
											data-bs-dismiss="modal"
											type="button"
										>
											Annuler
										</button>
									</div>
									<div className="btn-submit-container">
										<BtnSubmit
											label={annonce? "Modifier" : "Envoyer"}
											isLoading={isLoading}
										/>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
  );
}

export default AjouterAnnonceModal;
