import React from "react";
import {
  IPropriete,
  ProprieteFormData,
} from "../../../../../utils/api/propriete/propriete.type";
import { Input } from "../../../../common/Input";
import { SelectInput } from "../../../../common/SelectInput";
import { FiTrash2 } from "react-icons/fi";
import { BsPlusSquare } from "react-icons/bs";
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form";
import { useProprieteFromLocation } from "../../../../../utils/api/propriete/propriete.api";

type ChargeProps = {
  register: UseFormRegister<ProprieteFormData>;
  unregister: UseFormUnregister<ProprieteFormData>;
  errors: any;
  setValue: UseFormSetValue<ProprieteFormData>;
};
function ChargesComponent({
  register,
  errors,
  unregister,
  setValue,
}: ChargeProps) {
  const [item] = useProprieteFromLocation();
  const [inputs, setInputs] = React.useState([
    { libelle: "", type: "", montant: "" },
  ]);

  React.useEffect(() => {
    let data: any = [];
    if (item?.id && item?.charges?.length) {
      item?.charges?.forEach((el, i) => {
        setValue(`charges.${i}.libelle`, el?.libelle);
        setValue(`charges.${i}.type`, el?.type);
        setValue(`charges.${i}.montant`, el?.montant);
        data.push({ libelle: "", type: "", montant: "" });
      });

      if (data?.length) {
        setInputs(data);
      }
    }
  }, [item]);

  const handleAddInput = () => {
    const newInput = { libelle: "", type: "", montant: "" };
    setInputs([...inputs, newInput]);
  };

  const handleRemoveInput = (index: number) => {
    const updatedInputs = inputs.filter((input, i) => i !== index);
    setInputs(updatedInputs);
    unregister(`charges.${index}.libelle`);
    unregister(`charges.${index}.type`);
    unregister(`charges.${index}.montant`);
  };
  return (
    <div className="col-md-12">
      <h4 className="charge-titre py-3 fs-17 text-dark fw-600">
        Structuration du loyer
      </h4>
      <div className="row">
        <div className="col-md-12">
          <Input
            type="number"
            label="Loyer Hors Charges"
            id="prix_location"
            placeholder="Prix du loyer hors charges"
            min={0}
            required
            {...register("prix_base")}
            error={errors.prix_base}
          />
        </div>
      </div>
      <p className="charge-label py-3 fs-15 text-dark fw-400">
        Charges Locatives
      </p>
      {inputs?.map((item, i) => (
        <div className="col-md-12" key={i}>
          <div className="row">
            <div className="col-md-4">
              <Input
                type="text"
                label="Libellé"
                id="libelle"
                // step={"any"}
                // min={0}
                placeholder="Libellé"
                {...register(`charges.${i}.libelle`)}
                error={
                  errors?.charges &&
                  errors?.charges[i]?.libelle &&
                  errors?.charges[i]?.libelle?.message
                }
              />
            </div>
            <div className="col-md-4">
              <SelectInput
                label="Type"
                options={[
                  { label: "Pourcentage", value: "pourcentage" },
                  { label: "Montant", value: "montant" },
                ]}
                id="type"
                {...register(`charges.${i}.type`)}
                error={
                  errors?.charges &&
                  errors?.charges[i]?.type &&
                  errors?.charges[i]?.type?.message
                }
              />
            </div>
            <div className="col-md-4 d-flex justify-content-between align-items-center">
              <div className="col-md-11">
                <Input
                  type="number"
                  label="Valeur"
                  id="montant"
                  step={"any"}
                  min={0}
                  placeholder="Valeur"
                  {...register(`charges.${i}.montant`)}
                  error={
                    errors?.charges &&
                    errors?.charges[i]?.montant &&
                    errors?.charges[i]?.montant?.message
                  }
                />
              </div>

              {i !== 0 && (
                // <button className="custom-position-btn-remove col-md-1">
                <FiTrash2
                  onClick={() => handleRemoveInput(i)}
                  className="text-danger col-md-1"
                />
                // </button>
              )}
            </div>
          </div>
        </div>
      ))}
      <button
        className="btn btn-add-tarif w-auto"
        type="button"
        onClick={handleAddInput}
      >
        <BsPlusSquare className="btn-add-tarif-icon linkable" />
        Ajouter une autre charge
      </button>
    </div>
  );
}

export default ChargesComponent;
