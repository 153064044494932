import React,{useState, useEffect , useRef} from "react";
import useContactForm from "./useContactForm";
import { RequiredText } from "../../../common/Input";
import { FormError } from "../../../common/CustomInputCheckbox";
import { BtnSubmit } from "../../../common/Button";
import PhoneInput from "react-phone-input-2";

const ContactForm = () => {
  const { register, onSubmit, errors, isLoading, authUser, phone, setPhone, setValue } =
    useContactForm();
  
  const [showAutre, setShowAutre] = useState(false);

  const handleChangeTypeRequete = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value === "visiteur") {
      setShowAutre(true);
    } else {
      setShowAutre(false);
    }
  };

  const ref = useRef<any>()

  useEffect(() => {
    if(errors?.phone?.message)
    {
      ref.current.scrollIntoView() 
    }
  }, [errors?.phone]);



  return (
    <form action="" onSubmit={onSubmit}>
      <div className="auth-input-col mb-3 row">
        <div className="auth-form-group col-md-6">
          <label htmlFor="name" className="form-label form-label-contact-us">
            Nom et prénom
            <RequiredText />
          </label>
          <input
            type="text"
            className="form-control contact-us-form-control"
            id="name"
            placeholder="Noms"
            defaultValue={
              authUser ? authUser?.prenom + " " + authUser?.nom : ""
            }
            {...register("name")}
          />
          {errors?.name && <FormError error={errors?.name} />}
        </div>
        <div className="auth-form-group col-md-6">
          <label
            htmlFor="email-or-username"
            className="form-label form-label-contact-us"
          >
            Email
            <RequiredText />
          </label>
          <input
            type="email"
            className="form-control contact-us-form-control"
            id="email-or-username"
            placeholder="Email"
            defaultValue={authUser?.email}
            {...register("email")}
          />
          {errors?.email && <FormError error={errors?.email} />}
        </div>
      </div>

      <div className="auth-input-col mb-3 row">
        <div className="auth-form-group col-md-6">
          <label
            htmlFor="user_type"
            className="form-label form-label-contact-us"
          >
            Qui est vous
            <RequiredText />
          </label>
          <select
            id="user_type"
            className="form-select auth-form-select form-control contact-us-form-control"
            {...register("user_type")}
            onChange={handleChangeTypeRequete}
            defaultValue={authUser?.user_type}
          >
            <option value="" selected disabled>Qui êtes-vous</option>
            <option value="agence">Agence</option>
            <option value="locataire">Locataire</option>
            <option value="proprietaire">Proprietaire</option>
            <option value="visiteur">Autre</option>
          </select>
          <div className={showAutre ? "d-block mt-3" : "d-none"}>
            <input
              type="text"
              className="form-control contact-us-form-control mb-3"
              id="autre"
              placeholder="Préciser"
              {...register("autre")}
            />
          </div>
          
          {errors?.user_type && <FormError error={errors?.user_type} />}
        </div>
        <div className="auth-form-group col-md-6">
          <label ref={ref} htmlFor="phone" className="form-label form-label-contact-us">
            Téléphone
            <RequiredText />
          </label>
          {/* <input
            type="text"
            className="form-control contact-us-form-control"
            id="hone"
            placeholder="telephone"
            defaultValue={authUser?.phone}
            {...register("phone")}
          /> */}
          <PhoneInput
            defaultMask={".. ... .. .."}
            containerClass="form-control auth-form-control mb-3 p-0"
            inputClass="form-control contact-us-form-control"
            country={"sn"}
            placeholder=""
            inputProps={{
              name: "telephone",
              required: true,
              autoFocus: true,
            }}
            countryCodeEditable={false}
            enableAreaCodes={true}
            prefix="+"
            value={phone}
            onChange={(phone, country: any) => {
              setPhone(phone);

              if (country?.format?.match(/\./g)?.length === phone?.length) {
                setValue("phone", phone);
              }
            }}
            inputStyle={{
              width: "100%",
              paddingBottom: "22px",
              borderRadius: "10px",
            }}
          />
          {errors?.phone && <FormError error={errors?.phone} />}
        </div>
      </div>
      <div className="auth-input-col mb-3">
        <div className="auth-form-group">
          <label htmlFor="subject" className="form-label form-label-contact-us">
            Sujet
            <RequiredText />
          </label>
          <input
            type="text"
            className="form-control contact-us-form-control"
            id="subject"
            placeholder="Sujet"
            defaultValue={authUser?.subject}
            {...register("subject")}
          />
          {errors?.subject && <FormError error={errors?.subject} />}
        </div>
      </div>
      <div className="auth-input-col mb-3">
        <div className="auth-form-group">
          <label className="form-label form-label-contact-us">Message</label>
          <textarea
            className="form-control contact-us-form-control"
            rows={4}
            {...register("message")}
            placeholder="Message"
          />
          {errors?.message && <FormError error={errors?.message} />}
        </div>
      </div>
      <div className="flex-r mt-4">
        <BtnSubmit isLoading={isLoading} label="ENVOYER" />
      </div>
    </form>
  );
};

export default ContactForm;
