import React, { useEffect, useState } from "react";
import { useGetConditionsFrontQuery } from "../../../utils/api/condition/condition.api";
import { createMarkup, onHide } from "../../../utils/Utils";
import { AlertInfo } from "../../common/Alert";
import Logo from "../../../assets/appImages/logo.png";
import { NavLink } from "react-router-dom";
import { IoClose } from "react-icons/io5";

function ConditionsModal({ onValid }: { onValid: any }) {
  const { data } = useGetConditionsFrontQuery({});
  const [disabled, setDisabled] = useState(true);

  const handleScroll = () => {
    var el = document.getElementById("CguId");

    if (el && el.scrollTop + el.offsetHeight + 1 >= el.scrollHeight) {
      setDisabled(false);
    }
  };
  document
    .getElementById("CguId")
    ?.addEventListener("scroll", handleScroll, false);

  // window.addEventListener("scroll", handleScroll);
  return (
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div
          className="modal-body"
          // onScroll={(e:any) => {
          // console.log(e.target.scrollHeight);
          // }}
        >
          <div className="container-form">
            <div className="flex-sb">
              <div className="container-logo-page-register">
                <NavLink className="navbar-brand" to="/">
                  <img src={Logo} alt="Logo" className="logo-app" />
                </NavLink>
              </div>
              <button
                className="close-modal auth-submit-annuler"
                data-bs-dismiss="modal"
                type="button"
                onClick={(e) => onHide("ConditionsModal")}
              >
                <IoClose />
              </button>
            </div>
            <div className="container-card-register mx-3 my-4">
              <div className="content-title-page-register-animate mt-4">
                <p className="title-page-register-animate text-center">
                  Conditions Générales d'Utilisation
                </p>
              </div>
              <div
                className="row"
                id="CguId"
                style={{ height: "700px", overflow: "auto" }}
              >
                <div className="col-md-12">
                  <div className="kanimmo-cond-utilisation-ol-container">
                    <ol>
                      {data?.results?.length === 0 && (
                        <AlertInfo message="Les conditions générales d'utilisation ne sont pas encore disponible" />
                      )}
                      {data?.results?.map((contenu) => (
                        <li
                          key={contenu?.id}
                          className="mb-5 kanimmo-cond-utilisation-ol-custom-li"
                        >
                          <div className="kanimmo-cond-utilisation-ol-item-container">
                            <p
                              className="text-cgu-admin pb-2"
                              dangerouslySetInnerHTML={createMarkup(
                                contenu?.text
                              )}
                            />
                          </div>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <div
                  className="content-btn-register-card-animate"
                  // onClick={() => onHide("RegisterModal")}
                >
                  <button
                    type="button"
                    disabled={disabled}
                    onClick={async (e) => {
                      await onValid(e);
                      await onHide("ConditionsModal");
                    }}
                    className="btn btn-animate-card-register"
                  >
                    J'accepte
                    <span className="span-register-animate"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConditionsModal;
