import moment from "moment";
import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FiClock } from "react-icons/fi";
import { IoCalendarOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useRequeteDetailsFromLocation } from "../../../../../utils/api/requete/requete.api";
import { getAvatar } from "../../../../../utils/Utils";

function DetailsRequeteLocataire() {
  const navigate = useNavigate();

  const [itemState, findBySlug] = useRequeteDetailsFromLocation();

// console.log("itemState", itemState);
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4
            className="kanimmo-dash-locataire-requetes-header-titre no-link linkable"
            onClick={() => navigate(-1)}
          >
            <AiOutlineArrowLeft />
            <span className="ps-2">Locataires</span>
          </h4>
        </div>
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <div className="container-requete-page">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="container-profil-locataire-requete mb-md-0 mb-3">
                      <div className="container-img-pp-locataire">
                        <img
                          src={getAvatar(
                            itemState?.agence
                              ? itemState?.agence?.avatar
                              : itemState?.proprietaire?.avatar
                          )}
                          alt="profil"
                          className="img-pp-locataire-requete"
                        />
                      </div>
                      <div className="container-info-requete-locataire">
                        <div className="container-name-profil-locataire">
                          <p className="name-profil-locataire mb-2 pb-1">
                            {itemState?.agence
                              ? itemState?.agence?.prenom +
                                " " +
                                itemState?.agence?.nom
                              : itemState?.proprietaire?.prenom +
                                " " +
                                itemState?.proprietaire?.nom}
                          </p>
                        </div>
                        <div className="container-name-profil-locataire">
                          <p className="name-profil-locataire mb-2 pb-1">
                            <span style={{ fontWeight: 300 }}>Villa</span>{" "}
                            {itemState?.locataire?.propriete?.nom}
                          </p>
                        </div>
                        <div className="container-info-date-requete-locataire">
                          <div className="content-date-requete">
                            <IoCalendarOutline />
                            <span className="date-requete-locataire">
                              {moment(itemState?.created_at).format("ll")}
                            </span>
                          </div>
                          <div className="content-date-requete">
                            <FiClock />
                            <span className="date-requete-locataire">
                              {moment(itemState?.created_at).format("LT")}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="container-col-info-locataire-requete mb-md-0 mb-3">
                      <div className="container-name-profil-locataire">
                        <p className="date-requete-locataire">
                          Type de requête
                        </p>
                        <p className="name-profil-locataire mb-2 pb-1">
                          {itemState?.type_requete?.titre}:{" "}
                          {itemState?.type_requete?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6">
                    <div className="container-col-info-locataire-requete mb-md-0 mb-3">
                      <div className="container-name-profil-locataire">
                        <p className="date-requete-locataire">Responsabilité</p>
                        <p className="name-profil-locataire mb-2 pb-1">
                          {itemState?.charge}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="container-col-info-locataire-requete mb-md-0 mb-3">
                      <div className="container-name-profil-locataire">
                        <p className="date-requete-locataire">Statut</p>
                        {itemState?.statut === "non_regler" && (
                          <p className="name-profil-locataire mb-2 pb-1">
                            Non réglée
                          </p>
                        )}
                        {itemState?.statut === "encours" && (
                          <p className="name-profil-locataire mb-2 pb-1">
                            En cours
                          </p>
                        )}
                        {itemState?.statut === "regler" && (
                          <p className="name-profil-locataire mb-2 pb-1">
                            Réglée
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-text-requete mt-5">
                  <p className="date-requete-locataire">{itemState?.requete}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsRequeteLocataire;
