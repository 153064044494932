import { ILocataire } from "../locataire/locataire.type";
import { IPropriete } from "../propriete/propriete.type";

export enum StatusFacture {
  mensualite = "mensualite",
  caution = "caution",
  commission = "commission",
  other = "other",
}

export interface IFacture {
  // [key: string]: any;
  slug: string;
  id: number;
  montant: number;
  statut: string; //StatusFacture;
  locataire: ILocataire | any;
  propriete: IPropriete | any;
  pdf: string;
  created_at: Date;
  date: Date;
  autres: string
}

export interface Gquittence {
  email: string;
}

export type FactureFormData = Partial<IFacture>;

export interface IRecette {
  id: number;
  offre: {
  };
  adherant: {
  };
  slug: string;
  mode_paiement: string;
  gratuit: boolean;
  paid: boolean;
  date_debut: string;
  date_fin: string; 
  created_at: string;
  deleted_by_cascade: any; 
  pdf: string | null;
}

