import React from 'react'
import AdministrateursAdminTable from '../../../../tables/Admin/Options/Administrateurs/AdministrateursAdminTable'

function Administrateur() {
    return (
		<div className="bg-white p-3 filter-and-table-container">
			<div>
				<AdministrateursAdminTable />
			</div>
		</div>
    );
}

export default Administrateur
