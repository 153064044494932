import React from "react";
import SliderTable from "../../../../tables/Admin/Options/SliderTable/SliderTable";

const CrudSliderHomepage = () => {
  return (
    <div className="bg-white p-3 filter-and-table-container">
      <div>
        <SliderTable />
      </div>
    </div>
  );
};

export default CrudSliderHomepage;
