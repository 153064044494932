import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../redux/hooks";
import { useResponseContactMutation } from "../../../../utils/api/contact/contact.api";
import {
  IContact,
  TResponseContact,
} from "../../../../utils/api/contact/contact.type";
import { Color } from "../../../../utils/theme";
import { cleannerError, closeModal, onHide } from "../../../../utils/Utils";

const useResponseContactForm = (modalId: string, item?: IContact) => {
  const validationSchema = yup.object().shape({
    response: yup
      .string()
      .required()
      .label("Le réponse")
      .transform((val: any) => val.replace(/<p>(?!\s*<\/p>)(.*?)<\/p>/gs, '$1\n\n')),
  });
  const { user: authUser } = useAppSelector((s) => s.user);
  const [response, setResponse] = useState<string>("");
  const [sendMessage, { isLoading, isError, isSuccess, error }] =
    useResponseContactMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    clearErrors,
    setValue,
  } = useForm<TResponseContact>({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Message envoyé avec succés!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        closeModal(modalId);
        setResponse("");
        resetField("response")
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status || "inconnue"} est survenue`,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);
  useEffect(() => {
    if (item?.id) {
      // const response = item?.responses[0]?.response;
      // setResponse(response);
      console.log({ response });
    }
  });

  const handleChangeMessage = (value: string) => {

  
setResponse(value);           
    setValue("response", value);
};          
  const onsubmit = (data: TResponseContact) => {
    sendMessage({ ...data, user: authUser?.id, message: item?.id as number });
  };
  return {
    register,
    onSubmit: handleSubmit(onsubmit),
    errors,
    isLoading,
    setValue,
    response,
    handleChangeMessage,
    responses: item?.responses,
  };
};

export default useResponseContactForm;
