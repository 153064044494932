import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { RiCheckDoubleFill } from 'react-icons/ri';
import Profil from '../../../../assets/appImages/avatar.png'
import { ChatData } from '../../../../utils/api/chat/chat.type';
import { getAvatar } from '../../../../utils/Utils';
import { useAppSelector } from '../../../../redux/hooks';

function Sommaire({
    item,
    onClick,
    active,
  }: {
    item: ChatData;
    onClick: () => any;
    active: boolean;
  }) {
    const { user } = useAppSelector((s) => s?.user);
    const send = item.lastMessage.sender.id !== item.user.id;
    const receive = item.lastMessage.recever.id !== item.user.id;
    const [notRead, setNotRead] = useState([{}]);
    useEffect(() => {
      setNotRead(
        item.messages?.filter(
          (itm) => itm?.is_read === false && itm?.sender?.id !== user?.id
        )
      );
    }, [item?.messages]);
  return (

    <li
      onClick={onClick}
      className={`list-group-item list-group-item-meessage linkable ${
        active && "active"
      }`}
    >
      <div>
        <div className="bloc-profil-contact">
          <div className="d-flex align-items-center w-100">
            <img
              src={getAvatar(item.user?.avatar)}
              className="img-messge-contact"
              alt="image profil contact"
            />
            <div className='"d-flex flex-column justify-content-center'>

                <p
                className={`nom-contact ps-3 mb-0 ${active && "active"}`}
                // style={{
                //     textOverflow: "ellipsis",
                //     overflow: "hidden",
                //     whiteSpace: "nowrap",
                // }}
                >
                {item.user.prenom} {item.user.nom}
                </p>
                <div>
                    <p className="time-message-contact ps-3 mb-0">
                    {moment(item.lastMessage.created_at).calendar()}
                    </p>
                </div>
            </div>
          </div>
          {/* <div>
            <p className="time-message-contact mb-0">
              {moment(item.lastMessage.created_at).calendar()}
            </p>
          </div> */}
        </div>
        <div className="bloc-message-contact">
          <div className="w-85">
            <p
              className="contenu-message-contact ps-5 linkable mb-0"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {item.lastMessage.content.slice(0, 100)}
            </p>
          </div>
          <div className="">
          {receive && notRead?.length > 0 && (
              <span>
                <span className="statut-message-tabs-non-lu">
                  {notRead?.length}
                </span>
              </span>
            )}
            {/* {item.count > 0 && (
              <span>
                <span className="statut-message-tabs-non-lu">{item.count}</span>
              </span>
            )} */}
            {send && (
              <span className="">
                <span
                  className={
                    notRead?.length === 0
                      ? "statut-message-tabs-traite"
                      : "statut-message-tabs-non-traite"
                  }
                >
                  <RiCheckDoubleFill />
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </li>
  )
}

export default Sommaire