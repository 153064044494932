import React from 'react'
import Facturestable from '../../../../../tables/Locataire/Administratif/Factures/Facturestable';

function FacturesTabs() {
  return (
    <div className="bg-white py-3 px-1">
      <div className="locataire-requete-filtre-container my-4">
        <div className="row">
          <Facturestable />
        </div>
      </div>
    </div>
  );
}

export default FacturesTabs