import React from 'react'
import Skeleton from "react-loading-skeleton";

function RequetesSkeleton() {
    return (
		<div className="col-md-12">
			<table className="table table-striped">
				<thead>
					<tr>
						<th
							scope="col"
							style={{
                                fontFamily: 'Raleway',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#AAB2C8',
							}}
						>
							N°
						</th>
						<th
							scope="col"
							style={{
								fontFamily: 'Raleway',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#AAB2C8',
							}}
						>
							Agence/propriétaire
						</th>
						<th
							scope="col"
							style={{
								fontFamily: 'Raleway',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#AAB2C8',
							}}
						>
							Date
						</th>
						<th
							scope="col"
							style={{
								fontFamily: 'Raleway',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#AAB2C8',
							}}
						>
							Requettes
						</th>
						<th
							scope="col"
							style={{
								fontFamily: 'Raleway',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#AAB2C8',
							}}
						>
							Status
						</th>
						<th
							scope="col"
							style={{
								fontFamily: 'Raleway',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#AAB2C8',
							}}
						>
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row">
							<Skeleton />
						</th>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
					</tr>
					<tr>
						<th scope="row">
							<Skeleton />
						</th>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
					</tr>
					<tr>
						<th scope="row">
							<Skeleton />
						</th>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
					</tr>
					<tr>
						<th scope="row">
							<Skeleton />
						</th>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export default RequetesSkeleton