import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../../../redux/hooks";
import { cleannerError } from "../../../../utils/Utils";
import { BtnCloseModal, BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/CustomInputCheckbox";
import { useAddSignatureMutation } from "../../../../utils/api/signature/signature.api";

interface SignatureUploadProps {
  userSlug: string;
}

const SignatureUpload: React.FC<SignatureUploadProps> = ({ userSlug }) => {
  const [file, setFile] = React.useState<File | null>(null);
  const [addSignature, { isLoading }] = useAddSignatureMutation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setErrorMessage("Veuillez sélectionner un fichier");
      return;
    }

    const formData = new FormData();
    formData.append("slug", userSlug); // Assurez-vous que 'slug' est le bon nom
    formData.append("signature", file);

    try {
      await addSignature(formData); // Utilisez directement FormData
      setSuccessMessage("Signature téléchargée avec succès!");
    } catch (error) {
      setErrorMessage("Erreur lors du téléchargement de la signature.");
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setErrorMessage("");
      setSuccessMessage("");
    }, 5000);

    return () => clearTimeout(timeoutId); // Nettoyer le timeout lorsque le composant est démonté
  }, [errorMessage, successMessage]);

  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap pb-4">
      <div
      // style={{
      //   border: ".2px solid #959595",
      //   overflow: "hidden",
      //   opacity: 1,
      //   borderRadius: "7px",
      //   display: "flex",
      //   justifyContent: "start",
      //   padding: "1rem 0",
      //   // width: "48.5%",
      //   background: "rgb(243, 243, 243)",
      //   cursor: "pointer",
      // }}
      >
        {errorMessage !== "" && (
          <span style={{ color: "red" }}>{errorMessage}</span>
        )}
        {successMessage !== "" && (
          <span style={{ color: "green" }}>{successMessage}</span>
        )}
        <div className="mb-3">
          {/* <label className="form-label">Default file input example</label> */}
          <input
            className="form-control mt-3"
            type="file"
            id="formFile"
            onChange={handleFileChange}
          />
        </div>
        {/* <input
          className="d-flex justify-content-between align-content-center"
          type="file"
          onChange={handleFileChange}
        /> */}
      </div>

      <button
        onClick={handleUpload}
        disabled={isLoading}
        className="btn edit-offer-btn"
      >
        Télécharger la signature
      </button>
    </div>
  );
};

export default SignatureUpload;
