import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  cleannerError,
  isAgence,
  isProprietaireOwner,
  onHide,
} from "../../../../../utils/Utils";
import {
  useAddOrUpdateFactureMutation,
  useDeleteFactureMutation,
} from "../../../../../utils/api/facture/facture.api";
import {
  FactureFormData,
  IFacture,
} from "../../../../../utils/api/facture/facture.type";
import { Color } from "../../../../../utils/theme";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  useGetProprietesByAgenceQuery,
  useGetProprietesByProprietaireQuery,
  useLazyGetAppartmentByProprieteQuery,
} from "../../../../../utils/api/propriete/propriete.api";
import { useGetAgencesQuery } from "../../../../../utils/api/agence/agence.api";
import { useFindProprieteBySlugQuery } from "../../../../../utils/api/propriete/propriete.api";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { useLazyGetLocataireByProprieteSlugQuery } from "../../../../../utils/api/locataire/locataire.api";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";
import { useGetLocataireByProprietaireBySlugQuery } from "../../../../../utils/api/proprietaire/proprietaire.api";
export function useCrudFacture(item?: IFacture) {
  const validationSchema = yup.object().shape({
    montant: yup
      .number()
      .required()
      .label("Montant")
      .typeError("Montant est un champ obligatoire"),
    date: yup.string().required().label("La date d'échéance"),
    statut: yup.string().required().label("Statut"),
    autres: yup
      .string()
      .when("statut", {
        is: (val: string) => val === "other",
        then: yup.string().required().label("Statut de la facture").nullable(),
      })
      .nullable(),
    locataire: yup
      .number()
      .required()
      .label("Locataire")
      .typeError("Locataire est un champ obligatoire"),
    propriete: yup
      .number()
      .label("Propriéte")
      .typeError("Propriéte est un champ obligatoire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
    watch,
  } = useForm<FactureFormData>({
    resolver: yupResolver(validationSchema),
  });
  const watchAllFields = watch();
  const [options, setOptions] = useState<any>([]);
  const [option, setOption] = useState<any>();

  const { user } = useAppSelector((s) => s?.user);
  const { data: ListeAgence } = useGetAgencesQuery({});
  const [locataires, setLocataires] = useState<
    { label: string; value: number }[] | undefined
  >([]);
  const [locataireItem, setLocataireItem] = useState<any>();
  const [proprieteSlug, setProprieteSlug] = useState("");
  const { data: biens = { results: [] } } = useGetProprietesByProprietaireQuery(
    {
      limit: 10000,
      slug: user?.slug,
    }
  );
  const { data: locataireProprio = { results: [] } } =
    useGetLocataireByProprietaireBySlugQuery({
      limit: 10000,
      slug: user?.slug,
    });

  const { data } = useGetProprietesByAgenceQuery({
    slug: user?.slug,
    limit: 10000,
  });
  // const { data: proprieteDetail } = useFindProprieteBySlugQuery(proprieteSlug);

  const [getAppartments] =
    useLazyGetAppartmentByProprieteQuery();

  const [getLocataires, { data: locataire = { results: [] } }] =
    useLazyGetLocataireByProprieteSlugQuery();

  const [filterProperties, setFilterProperties] = useState<
    IPropriete[] | undefined
  >();
  useEffect(() => {
    if (user?.user_type === "agence") {
      if (locataire?.results?.length) {
        setLocataires(
          locataire?.results
            ?.filter((item: ILocataire) => item?.propriete?.id === option?.id)
            ?.map((el: ILocataire) => {
              return {
                label: el?.prenom + " " + el?.nom,
                value: el?.id,
                ...el,
              };
            })
        );

        let locataireEl: ILocataire = locataire?.results?.find(
          (item: any) => item?.propriete?.id === option?.id
        );
        if (locataireEl) {
          setValue("locataire", locataireEl?.id);
          setLocataireItem({
            label: locataireEl?.prenom + " " + locataireEl?.nom,
            value: locataireEl?.id,
            ...locataireEl,
          });
        } else {
          setValue("locataire", "");
          setLocataireItem("");
        }
      } else {
        setValue("locataire", "");
        setLocataires([]);
        setLocataireItem("");
      }
    } else if (isProprietaireOwner(user)) {
      if (locataireProprio?.results?.length) {
        setLocataires(
          locataireProprio?.results
            ?.filter((item: ILocataire) => item?.propriete?.id === option?.id)
            ?.map((el: ILocataire) => {
              return {
                label: el?.prenom + " " + el?.nom,
                value: el?.id,
                ...el,
              };
            })
        );

        let locataireEl: any = locataireProprio?.results?.find(
          (item: any) => item?.propriete?.id === option?.id
        );
        if (locataireEl) {
          setValue("locataire", locataireEl?.id);
          setLocataireItem({
            label: locataireEl?.prenom + " " + locataireEl?.nom,
            value: locataireEl?.id,
            ...locataireEl,
          });
        } else {
          setValue("locataire", "");
          setLocataireItem("");
        }
      } else {
        setValue("locataire", "");
        setLocataires([]);
        setLocataireItem("");
      }
    }
  }, [option, locataire?.results?.length, locataireProprio?.results?.length]);
  useEffect(() => {
    if (!isProprietaireOwner(user)) {
      const propriete = data?.results?.filter(
        (item) => item?.type_propriete !== "immeuble"
      );
      setOptions(
        propriete?.map((item) => {
          return {
            label: item?.nom,
            value: item?.id,
            ...item,
          };
        })
      );
      setFilterProperties(propriete);
    } else {
      const propriete = biens?.results?.filter(
        (item) => item?.type_propriete !== "immeuble"
      );
      setOptions(
        propriete?.map((item) => {
          return {
            label: item?.nom,
            value: item?.id,
            ...item,
          };
        })
      );
      setFilterProperties(propriete);
    }
  }, [data?.results, biens?.results]);

  const handleChangePropriete = (e: any) => {
    // console.log("option", e);
    setOption(e);
    setValue("propriete", e?.value);
    if (e?.value) {
      getLocataires({ slug: e?.slug });
    }
    if (e?.currentTarget?.value) {
      const proprieteSlg: any = data?.results?.find(
        (item) => item?.id === parseInt(e.currentTarget.value)
      )?.slug;
      setProprieteSlug(proprieteSlg);
      // const locataires = proprieteDetail?.locataires?.map((locataire) => {
      //   return {
      //     label: locataire?.prenom + " " + locataire?.nom,
      //     value: locataire?.id,
      //   };
      // });
      // getAppartments({ slug: proprieteDetail?.slug as string });

      // setLocataires(locataires);
    }
  };

  const handleChangeLocataire = (e: any) => {
    setValue("locataire", e?.value);
  };

  const [addOrUpdateFacture, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateFactureMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Facture ${item ? "modifiée" : "créée"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        handleReset();
        onHide("AjouterFactureModal");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      let fields: (keyof FactureFormData)[] = ["montant", "statut", "slug","autres"];
      for (let field of fields) {
        setValue(field, item[field]);
      }
      setValue("propriete", item?.propriete?.id);
      setValue("locataire", item?.locataire?.id);

      const locataires = data?.results
        ?.find((itm) => itm?.id === parseInt(item?.propriete?.id))
        ?.locataires?.map((locataire: any) => {
          return {
            label: locataire?.prenom + " " + locataire?.nom,
            value: locataire?.id,
          };
        });
      setLocataires(locataires);
    }
  }, [item?.id]);

  const onSubmit = async (data: any) => {
    // if (data?.statut === "other") {
    //   data.statut = ""
    // }
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      const val: any = data[key];
      fd.append(key, val);
    }
    // console.log("data sub", data)
    await addOrUpdateFacture({ slug: item?.slug, data: fd });
  };

  const handleReset = () => {
    reset();
    setLocataireItem("");
    setLocataires([]);
    setOption("");
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangePropriete,
    handleChangeLocataire,
    proprietes: data?.results,
    filterProperties,
    locataires,
    control,
    Controller,
    options,
    option,
    locataireItem,
    handleReset,
    watchAllFields,
  };
}

export function UseDeleteFacture(item: IFacture) {
  const [deleteData] = useDeleteFactureMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette facture ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      // console.log(result, "deleted");
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Facture supprimée avec succès!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
