import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import {
  Abonnement,
  AbonnementFormData,
  PaginationResults,
  TypeQuery,
} from "../user/user.type";
import { Offer, OfferFormData } from "./offer.type";

export const OfferApi = createApi({
  reducerPath: "offerApi",
  tagTypes: ["offerList", "offerItem", "similarOffer", "abonnementList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getOffers: build.query<PaginationResults<Offer>, TypeQuery>({
      query: (query) => QueryUrl(`offres/`, query),
      providesTags: ["offerList"],
    }),
    getOffersByVisitors: build.query<Offer[], TypeQuery>({
      query: (query) => QueryUrl(`visitors/offres`, query),
      providesTags: ["offerList"],
    }),
    addOrUpdateOffer: build.mutation<Offer, OfferFormData>({
      invalidatesTags: ["offerList"],
      query: ({ slug, ...data }) => {
        if (slug) {
          return {
            url: `offres/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `offres/`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: ({ data }) => data,
    }),
    findOfferBySlug: build.query<Offer, string>({
      query: (slug) => `offres/${slug}/`,
      providesTags: ["offerItem"],
    }),
    deleteOffer: build.mutation<Offer, string>({
      query: (slug) => ({
        url: `offres/${slug}`,
        method: "Delete",
      }),
      invalidatesTags: ["offerList", "offerItem"],
    }),
    getAbonments: build.query<PaginationResults<Abonnement>, TypeQuery>({
      query: (query) => QueryUrl(`abonnements/`, query),
      providesTags: ["abonnementList"],
    }),
    getAbonmentsAgence: build.query<PaginationResults<Abonnement>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`showsubscriberagenceproprio/${slug}`, query),
      providesTags: ["abonnementList"],
    }),
    createAbonnementFree: build.mutation<
      AbonnementFormData,
      AbonnementFormData | FormData
    >({
      query: (data) => ({
        url: `abonnement/gratuit/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["abonnementList"],
    }),
  }),
});

export const {
  useAddOrUpdateOfferMutation,
  useDeleteOfferMutation,
  useGetOffersByVisitorsQuery,
  useGetOffersQuery,
  useLazyFindOfferBySlugQuery,
  useGetAbonmentsQuery,
  useGetAbonmentsAgenceQuery,
  useCreateAbonnementFreeMutation,
} = OfferApi;
