import React from "react";
import Avatar from "../../../../assets/appImages/avata.png";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { FaFileDownload } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import Om from "../../../../assets/icons/orange-money.png";
import Wave from "../../../../assets/icons/wave.png";
import Mc from "../../../../assets/icons/masterCard.png";
import { getAvatar, isAgence, useLocationState } from "../../../../utils/Utils";
import { IProprietaire } from "../../../../utils/api/proprietaire/proprietaire.type";
import ListProprietesByProrietaire from "./ListProprietesByProrietaire";
import { isAdminUser } from "../../../../routes/router";
import { useAppSelector } from "../../../../redux/hooks";
import AgenceDocumentContratModal from "../../../modal/agence/AgenceDocumentContratModal";
import Locataires from "../Locataires/ListeLocatairesTabs/Locataires";
import Factures from "../AdministratifAgence/TabsAdministratif/Factures";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import { useGetAgencesQuery } from "../../../../utils/api/agence/agence.api";
import DetailProprietaire from "./DetailProprietaire";
import AjoutPropriete from "../ProprietesAgence/AjoutPropriete/AjoutPropriete";
import AjouterProprietaire from "./AjouterProprietaire";
import PaymentProprietaire from "./PaymentProprietaire";
import AjoutInfos from "./AjoutInfos";
import ProprietaireDetailsInfos from "./ProprietaireDetailsInfos";
import PayementsInfos from "./PayementsInfos";

const steps = [
  { id: "Infos Propriétaire", Component: AjoutInfos },
  { id: "Biens", Component: ProprietaireDetailsInfos },
  { id: "Gestion de Paiement", Component: PayementsInfos },
];

const tabs = ["Infos Propriétaire", "Biens", "Gestion de Paiement"];
function ProprietaireInfos() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  const item = useLocationState<IProprietaire>(undefined);
  const navigate = useNavigate();

  const { user } = useAppSelector((s) => s?.user);
  const { data: ListeAgence } = useGetAgencesQuery({});
  const AgenceSlug = ListeAgence?.results
    ?.filter((item) => item?.id === user?.agence_id)
    ?.map((itm) => itm?.slug);
  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));

  const [searchParams, setSearchParams] = useSearchParams();
  const props = { searchParams, setSearchParams, AgenceSlug, is_Agence };
  // console.log("item", item)
  return (
    <div className="landing-page-container">
      <div className="">
        <div className="kanimmo-dash-locataire-requetes-header py-3">
          <h4
            className="kanimmo-dash-locataire-requetes-header-titre"
            onClick={() =>
              isAdminUser(user) ? navigate("/admin/mes-clients") : navigate(-1)
            }
          >
            <BiArrowBack />
            <span className="ps-3">Propriétaires</span>
          </h4>
        </div>
      </div>
      <div className="kanimmo-tabs-container py-3">
        <ul className="tabs-container">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              onClick={() => go(key)}
              role="button"
            >
              {tab}
            </li>
          ))}
        </ul>
        <div className="tabs-component-container">
          <Component {...props} />
        </div>
      </div>
    </div>
  );
}

export default ProprietaireInfos;
