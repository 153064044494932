import React, { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import useRegisterProprietaire from "../useAuthForm/useRegisterProprietaire";
import AddPersonnalInfoProprietaire from "./AddPersonnalInfoProprietaire";
import AddProprietaireInfo from "./AddProprietaireInfo";
import Logo from "../../../assets/appImages/kanimmoLogoNoir.png";
import { NavLink } from "react-router-dom";
import ConditionsModal from "../../modal/user/ConditionsModal";
import ChoicePaymentMethod from "../../TableauDebord/locataire/Dashboard/MonLoyer/ChoicePaymentMethod";
import CheckStatusPaymentModal from "../../TableauDebord/locataire/Dashboard/MonLoyer/CheckStatusPaymentModal";
import OffersModal from "../../modal/user/OffersModal";

const steps = [
  {
    id: "add-info-personnel-proprietaire",
    Component: AddPersonnalInfoProprietaire,
  },
  {
    id: "add-info-proprietaire",
    Component: AddProprietaireInfo,
  },
];
const PropritaireRegister = () => {
  const {
    register,
    onSubmit,
    setValue,
    errors,
    isLoading,
    level,
    dataForm,
    handelAvatarChange,
    previewAvatar,
    onChangeAddressAgence,
    addressAgence,
    onRegister,
    handleCountrySelect,
    handleStateSelect,
    country,
    state,
    type,
    handleChangeType,
    mode,
    onLoading,
    onCheckStatus,
    seconds,
    onPressSubmit
  } = useRegisterProprietaire();
  const { step, navigation, index }: useStepType = useStep({
    initialStep: 0,
    steps,
  });
  const { Component } = step;
  const props = {
    navigation,
    register,
    onSubmit,
    setValue,
    errors,
    isLoading,
    level,
    dataForm,
    handelAvatarChange,
    previewAvatar,
    onChangeAddressAgence,
    addressAgence,
    handleCountrySelect,
    handleStateSelect,
    country,
    state,
    type,
    handleChangeType,
  };

  useEffect(() => {
    setValue("step", index + 1);
  }, [index]);

  useEffect(() => {
    if (level === 1 && index === 0) {
      navigation.go("add-info-proprietaire");
    }
  }, [level, dataForm]);

  return (
    <div className="agency-register-component">
      <div className="auth-component">
        <div className="nav-empty-header-login">
          <NavLink className="navbar-brand" to="/">
            <img src={Logo} alt="Logo" className="logo-app"
            style={{ maxWidth: '200px', height: '170px' }}
             />
          </NavLink>
        </div>
        <div className="fixed-login-component">
          <div className="auth-container">
            <div className="auth-row row">
              <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 auth-col auth-right-side-col">
                <div className="auth-right-side-container">
                  <div className="auth-form-container">
                    <div className="py-4">
                      <h1 className="auth-form-title">Créer votre compte</h1>
                    </div>
                    <form onSubmit={onSubmit}>
                      <Component {...props} />
                    </form>
                    <div
                      className="modal fade"
                      id="ConditionsModal"
                      aria-labelledby="ConditionsModalLabel"
                      aria-hidden="true"
                    >
                      {/* 1er etape */}
                      <ConditionsModal onValid={onRegister} />
                    </div>
                    <div
                      className="modal fade"
                      id="choicePaymentMethod"
                      aria-labelledby="choicePaymentMethodLabel"
                      aria-hidden="true"
                    >
                      {/* 2r etape */}
                      <ChoicePaymentMethod
                        onPay={onPressSubmit}
                        isLoading={isLoading}
                        mode={mode}
                      />
                    </div>
                    {/* 3e etape */}
                    <CheckStatusPaymentModal
                      isLoading={onLoading}
                      onCheckStatus={onCheckStatus}
                      seconds={seconds}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="offersModal"
              aria-labelledby="offersModalLabel"
              aria-hidden="true"
            >
              <OffersModal type="proprietaire" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropritaireRegister;
