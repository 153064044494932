/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IoMdTrash } from "react-icons/io";
import word from "../../../../../../assets/appImages/word.png";
import EtatLieuxSkeletonPQGSB from "./EtatLieuxSkeletonPQGSB";
import { useGetEtatLieuByProprietaireQuery } from "../../../../../../utils/api/etatlieu/etatlieu.api";
import { useAppSelector } from "../../../../../../redux/hooks";
import { IEtatLieu } from "../../../../../../utils/api/etatlieu/etatlieu.type";
import { MdEdit } from "react-icons/md";
import { UseDeleteEtatLieu } from "../../../../../TableauDebord/Agence/Administratif/requestForm/useCrudEtatLieu";
import { formattedDate } from "../../../../../../utils/Utils";
import ViewDocModal from "../../../../../modal/agence/ViewDocModal";
import AjoutEtatLieuxModalPQGSB from "../../../../../modal/proprietaireQGSB/AjoutEtatLieuxModalPQGSB";
import { AlertInfo } from "../../../../../common/Alert";
import { CustomPagination } from "../../../../../common/CustomPagination";

function EtatLieuxTablePQGSB({ name }: { name?: string }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const proprietaire = useAppSelector((s) => s.user.user);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetEtatLieuByProprietaireQuery({
      slug: proprietaire?.proprietaire_id
        ? proprietaire?.proprietaire_id?.slug
        : proprietaire?.slug,
      page: page,
      limit: perPage,
      name,
    });
  // console.log("data data", proprietaire);
  const [item, setItem] = useState<IEtatLieu>();

  const actionFormatter = (cell: any, row: IEtatLieu) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes gap-2 d-flex">
          <button
            className="btn btn-action-modal-icon with-tooltip"
            data-tooltip-content="Modifier"
            data-bs-toggle="modal"
            data-bs-target={`#editEtatLieuModal${row?.slug}`}
            onClick={() => setItem(row)}
          >
            <MdEdit />
          </button>
          <DeleteEtatdeLieu item={row} />

          {/* <AjoutEtatLieuxModalPQGSB /> */}
        </div>
        <div
          className="modal fade"
          id={`editEtatLieuModal${row?.slug}`}
          aria-labelledby="editEtatLieuModalLabel"
          aria-hidden="true"
        >
          <AjoutEtatLieuxModalPQGSB
            item={row}
            modalId={`editEtatLieuModal${row?.slug}`}
          />
        </div>
      </>
    );
  };

  const voirDocumentFormatter = (cell: any, row: IEtatLieu) => {
    return (
      <div
        role="button"
        className="ps-3"
        data-bs-toggle="modal"
        data-bs-target="#ViewDocModal"
        onClick={() => setItem(row)}
      >
        Voir l’etat des lieux
      </div>
    );
  };
  const villaFormatter = (cell: any, row: IEtatLieu) => {
    return <div className="">{row?.propriete?.nom}</div>;
  };
  const locataireFormatter = (cell: any, row: IEtatLieu) => {
    return (
      <div className="">
        {row?.locataire?.prenom + " " + row?.locataire?.nom}
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "N°",
      style: { textAlign: "left" },
      headerStyle: () => {
        return { width: "50px", whiteSpace: "normal" };
      },
      formatter: (cell: number) => "#" + cell,
    },
    // {
    // 	dataField: "document",
    // 	text: "Nom du document",
    //     formatter: (cell: any, row: any) =>
    //     documentFormatter(cell, row),
    // },
    {
      dataField: "nom_locataire",
      text: "Nom du locataire",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) => locataireFormatter(cell, row),
    },
    {
      dataField: "propriete",
      text: "Propriétés",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) => villaFormatter(cell, row),
    },
    {
      dataField: "created_at",
      text: "Date de validation",
      style: { textAlign: "left" },
      formatter: (cell: string) => formattedDate(cell),
    },

    {
      dataField: "decouvrir",
      text: "Découvrir",
      style: { color: "#1AA981", textAlign: "left" },
      formatter: (cell: any, row: any) => voirDocumentFormatter(cell, row),
    },
    {
      dataField: "actions",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <EtatLieuxSkeletonPQGSB />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
      <ViewDocModal item={item} />

      
     
    </>
  );
}

export default EtatLieuxTablePQGSB;

function DeleteEtatdeLieu({ item }: { item: IEtatLieu }) {
  const deleteEtatdeLieu = UseDeleteEtatLieu(item);
  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={deleteEtatdeLieu}
    >
      <IoMdTrash />
    </button>
  );
}
