import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ListeLocatairePQGSBSkeleton from "./ListeLocatairePQGSBSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetLocataireByProprietaireBySlugQuery } from "../../../../utils/api/proprietaire/proprietaire.api";
import { CustomPagination } from "../../../common/CustomPagination";
import { AlertInfo } from "../../../common/Alert";
import { isProprioJuriste } from "../../../../utils/Utils";
import { MdArchive, MdUnarchive } from "react-icons/md";
import { useGetLocataireArchiveBySlugQuery } from "../../../../utils/api/locataire/locataire.api";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";

function ListeLocataireArchiveTablePQGSB({
  name,
  uptodate,
}: {
  name?: string;
  uptodate?: string;
}) {
  const { user } = useAppSelector((s) => s?.user);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [refreshKey, setRefreshKey] = useState(0);
  // const { data = { results: [] }, isLoading, refetch } =
  //   useGetLocataireByProprietaireBySlugQuery({
  //     page,
  //     limit: perPage,
  //     slug: user?.proprietaire_id?.slug
  //       ? user?.proprietaire_id?.slug
  //       : user?.slug,
  //     name,
  //     uptodate,
  //   });

  const {
    data = { results: [] },
    error,
    isLoading,
    refetch,
  } = useGetLocataireArchiveBySlugQuery(
    user?.proprietaire_id?.slug ? user?.proprietaire_id?.slug : user?.slug
  );

  useEffect(() => {
    refetch();
  }, [refreshKey, refetch]);

  const handleActionCompleted = () => {
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex">
          <ArchiveLocataireBtn
            slug={row?.slug}
            row={row}
            onActionCompleted={handleActionCompleted}
          />
        </div>
      </>
    );
  };

  const profilFormatter = (cell: any, row: any) => {
    return (
      <>
        <NavLink
          to={`/proprietaire/gerer-par-le-proprietaire/locataire/${row?.slug}`}
          state={row}
          className="no-link"
          style={{ color: "#1AA981", textAlign: "left", fontWeight: "600" }}
        >
          Voir
        </NavLink>
      </>
    );
  };

  const proprieteFormatter = (cell: any) => <div>{cell?.nom}</div>;

  const litigeFormatter = (cell: any, row: any) => {
    return (
      <>
        {cell === "expulsion" && (
          <div className="table-actions-btn-container-commandes d-flex">
            <form>
              <select
                className="form-select form-select-table-expulsion"
                aria-label="Default select example"
              >
                <option value="" selected disabled>
                  Sélectionner un contentieux
                </option>
                <option selected>Expulsion </option>
                <option value="1">Jugement</option>
                <option value="2">Commandement de payer</option>
                <option value="3">Aucun contentieux</option>
              </select>
            </form>
          </div>
        )}
        {cell === "jugement" && (
          <div className="table-actions-btn-container-commandes d-flex">
            <form>
              <select
                className="form-select form-select-table-jugement"
                aria-label="Default select example"
              >
                <option value="" selected disabled>
                  Sélectionner un contentieux
                </option>
                <option selected>Jugement</option>
                <option value="1">Expulsion </option>
                <option value="2">Commandement de payer</option>
                <option value="3">Aucun contentieux</option>
              </select>
            </form>
          </div>
        )}
        {cell === "commandement_payer" && (
          <div className="table-actions-btn-container-commandes d-flex">
            <form>
              <select
                className="form-select form-select-table-commandement_payer"
                aria-label="Default select example"
              >
                <option value="" disabled selected>
                  Commandement de payer
                </option>
                <option value="1">Jugement</option>
                <option value="2">Expulsion </option>
                <option value="3">Aucun contentieux</option>
              </select>
            </form>
          </div>
        )}
        {cell === "aucun_litige" && (
          <div className="table-actions-btn-container-commandes d-flex">
            <form>
              <select
                className="form-select form-select-table-aucun_litige"
                aria-label="Default select example"
              >
                <option value="" selected disabled>
                  Sélectionner un contentieux
                </option>
                <option selected>Aucun contentieux</option>
                <option value="1">Commandement de payer</option>
                <option value="2">Jugement</option>
                <option value="3">Expulsion </option>
              </select>
            </form>
          </div>
        )}
      </>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Nom du locataire",
      formatter: (cell: any, row: any) => row?.prenom + " " + row?.nom,
    },
    {
      dataField: "telephone",
      text: "Téléphone",
      formatter: (cell: any, row: any) => (
        <a className="no-link" href={`tel:${cell}`}>
          {cell}
        </a>
      ),
    },
    // {
    //   dataField: "propriete",
    //   text: "Propriete",
    //   formatter: (cell: any) => proprieteFormatter(cell),
    // },
    {
      dataField: "",
      text: "Détails",
      formatter: (cell: any, row: any) => profilFormatter(cell, row),
      style: { color: "#1AA981", textAlign: "left" },
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      hidden: isProprioJuriste(user) ? true : false,
    },
  ];

  return (
    <>
      {isLoading && <ListeLocatairePQGSBSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results
              ?.slice()
              .sort(
                (a: any, b: any) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune Locataire désarchivé trouvé" />
            )}
          />
          <CustomPagination
            nbPages={data.count}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export default ListeLocataireArchiveTablePQGSB;

export function ArchiveLocataireBtn({
  slug,
  row,
  onActionCompleted,
}: {
  slug: string;
  row: any;
  onActionCompleted: () => void;
}) {
  const onActif = () => {
    Swal.fire({
      title: `Cette locataire est actif dans le plateform`,
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };
  const navigate = useNavigate();
  const onDeArchive = () => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir désarchiver ce locataire?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(
          `/proprietaire/gerer-par-le-proprietaire/locataires/modifier-locataire/${row?.slug}`,
          { state: row }
        );
      }
    });
  };

  return (
    <>
      {!row?.is_archive ? (
        <button
          className="btn btn-action-administratif-proprietaire with-tooltip"
          data-tooltip-content="Archiver"
          // disabled={row?.is_archive}
          onClick={() => {
            onActif();
            setTimeout(() => {
              onActionCompleted();
            }, 5000);
          }}
        >
          <MdArchive />
        </button>
      ) : (
        <button
          className="btn btn-action-administratif-proprietaire with-tooltip"
          data-tooltip-content="Désarchiver"
          onClick={() => onDeArchive()}
        >
          <MdUnarchive />
        </button>
      )}
    </>
  );
}
