import moment from "moment";
import React, { useState } from "react";
import { useGetProblemeMessagesQuery } from "../../../../utils/api/probleme/probleme.api";
import { IProbleme } from "../../../../utils/api/probleme/probleme.type";
import { createMarkup } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import Pagination from "../../../common/Pagination";
import DetailProblemeModal from "../../../modal/admin/DetailProblemeModal";
import { MessageContactItemSkeleton } from "../Lead/Lead";

function Problemes() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [item, setItem] = useState<IProbleme>();
  const { data = { results: [] }, isLoading } = useGetProblemeMessagesQuery({
    page,
    limit: perPage,
  });
// console.log("data", data);

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0  bg-white">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Problèmes signalés
          </h4>
        </div>
        <div className="filter-and-table-container filter-and-table-container-lead-admin padding-filter-and-table-container mt-3">
          {!isLoading && data?.results?.length > 0
            ? data?.results?.map((item) => (
                <div className="mb-3" key={item?.slug}>
                  <div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
                    <div className="row w-100 align-items-center">
                      <div className="col-md-7">
                        <div
                          className="admin-message-contact-card-description d-flex btn p-0"
                          style={{
                            textOverflow: "ellipsis",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target={"#DetailsMessageModal" + item?.slug}
                          role="button"
                          dangerouslySetInnerHTML={createMarkup(
                            item.message,
                            true,
                            50
                          )}
                          onClick={() => setItem(item)}
                        />
                        <p className="admin-message-contact-card-frequence fz-14 mb-0">
                          {moment(item?.created_at).format(
                            "DD/MM/YYYY à HH:MM"
                          )}
                        </p>
                      </div>
                      <div className="col-md-2">
                        {/* <button
                        className={`btn message-btn ${
                          item.status === "new"
                            ? "bg-status"
                            : item.status === "traité"
                            ? "bg-success"
                            : "bg-secondary"
                        }`}
                        data-bs-toggle="modal"
                        data-bs-target="#ChangeStatusMessageModal"
                        aria-expanded="false"
                        onClick={() => setItem(item)}
                      >
                        {item.status === "new"
                          ? "Nouveau"
                          : item.status === "traité"
                          ? "Traité"
                          : "En cours"}
                      </button> */}
                      </div>
                      <div className="col-md-3 flex-r">
                        <p className="admin-message-contact-card-description mb-0">
                          {item?.slug ? item?.prenom + " " + item?.nom : null}
                        </p>
                      </div>
                    </div>
                    <button
                      className="no-style-btn accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#responseContact${item.slug}`}
                      aria-expanded="false"
                      aria-controls="responseContact1"
                    ></button>
                  </div>
                  <div
                    className="modal fade"
                    id={"DetailsMessageModal" + item?.slug}
                    aria-labelledby="DetailsMessageModalLabel"
                    aria-hidden="true"
                  >
                    <DetailProblemeModal item={item} />
                  </div>
                </div>
              ))
            : !isLoading && <AlertInfo message="Pas de données" />}
          {isLoading &&
            [...Array(5)].map((item, i) => (
              <MessageContactItemSkeleton key={i} />
            ))}
          <div className="flex-r">
            <Pagination
              page={page}
              total={data?.count}
              perPage={perPage}
              onPageChange={(page: number) => setPage(page)}
            />
          </div>
        </div>
      </div>

      {/* <ChangeStatusMessageModal item={item} /> */}
    </div>
  );
}

export default Problemes;
