import React, { useEffect } from "react";
import { BtnCloseModal, BtnSubmit } from "../../../../common/Button";
import { Input } from "../../../../common/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ErrorMessage from "../../../../common/ErrorMessage";
import { cleannerError, onHide } from "../../../../../utils/Utils";
import { IoClose } from "react-icons/io5";

export type Infos = {
  cardCVC: string;
  cardExpireMonth: string;
  cardExpireYear: string;
  cardNumber: string;
  cardType: string;
  customerFirstName: string;
  customerLastName: string;
};
// "cardType doit avoir une des ses valeurs: VISA,MASTERCARD,VISA_ELECTRON"
const typeCard = [
  {
    value: "VISA",
    label: "VISA",
  },
  {
    value: "MASTERCARD",
    label: "MASTERCARD",
  },
  {
    value: "VISA_ELECTRON",
    label: "VISA ELECTRONIQUE",
  },
];

// var monthNames = [
//   "Janvier",
//   "Février",
//   "Mars",
//   "Avril",
//   "Mai",
//   "Juin",
//   "Juillet",
//   "Août",
//   "Septembre",
//   "Octobre",
//   "Novembre",
//   "Décembre",
// ];
function InfosCard({onSubmit}:{onSubmit: (data: Infos) => void}) {
  const validationSchema = yup.object().shape({
    cardCVC: yup.string().required().min(3).max(3).label("CVC"),
    cardExpireMonth: yup
      .string()
      .required()
      .min(2)
      .max(2)
      .label("Mois d'expiration"),
    cardExpireYear: yup
      .string()
      .required()
      .min(2)
      .max(2)
      .label("Année d'expiration"),
    cardNumber: yup.string().required().label("Numéro de la carte"),
    cardType: yup.string().required().label("Type de la carte"),
    customerFirstName: yup.string().required().label("Prénom du titulaire"),
    customerLastName: yup.string().required().label("Nom du titulaire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<Infos>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  return (
    <div className="modal-dialog modal-md modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            Ajouter les informations bancaire
          </h5>
          <button
            className="close-modal auth-submit-annuler"
            // data-bs-dismiss="modal"
            onClick={() => onHide("infosCard")}
            type="button"
          >
            <IoClose />
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row py-4 px-3">
            <div className="col-md-12">
              <div className="mb-3">
                <label
                  htmlFor="cardType"
                  className="form-label form-label-modal-custom"
                >
                  Type de carte
                  <span className="text-danger" style={{ fontSize: 24 }}>
                    *
                  </span>
                </label>
                <select
                  id="cardType"
                  className="form-select py-2"
                  {...register("cardType")}
                >
                  <option value="" selected disabled>
                    Selectionner le type de carte
                  </option>
                  {typeCard?.map((item) => (
                    <option value={item.value} key={item.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
                {errors.cardType && (
                  <ErrorMessage message={errors.cardType?.message} />
                )}
              </div>
            </div>
            <div className="col-6 mb-3 ">
              <Input
                label="Prénom du titulaire"
                id="tag"
                type="text"
                placeholder="Prénom du titulaire"
                {...register("customerFirstName")}
                error={errors?.customerFirstName}
                required
                className="form-control"
              />
            </div>
            <div className="col-6 mb-3 ">
              <Input
                label="Nom du titulaire"
                id="tag"
                type="text"
                required
                placeholder="Nom du titulaire"
                {...register("customerLastName")}
                error={errors?.customerLastName}
                className="form-control"
              />
            </div>
            <div className="col-6 mb-3 ">
              <Input
                label="Numéro de la carte"
                id="tag"
                type="text"
                required
                placeholder="Numéro de la carte"
                {...register("cardNumber")}
                error={errors?.cardNumber}
                className="form-control"
              />
            </div>

            <div className="col-6 mb-3 ">
              <Input
                label="CVA"
                id="tag"
                type="text"
                required
                placeholder="CVA"
                {...register("cardCVC")}
                error={errors?.cardCVC}
                className="form-control"
              />
            </div>

            <div className="col-6 mb-3 ">
              <Input
                label="Mois d'expiration"
                id="tag"
                type="number"
                required
                placeholder="Mois d'expiration"
                {...register("cardExpireMonth")}
                error={errors?.cardExpireMonth}
                className="form-control"
              />
            </div>

            <div className="col-6 mb-3 ">
              <Input
                label="Année d'expiration"
                id="tag"
                type="number"
                required
                placeholder="Année d'expiration"
                {...register("cardExpireYear")}
                error={errors?.cardExpireYear}
                className="form-control"
              />
            </div>

            <div className="flex-r">
              <BtnSubmit
                label={"Envoyer"}
                isLoading={false}
                style={{ padding: "5px 10px", fontSize: 14 }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default InfosCard;
