import React from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useAppSelector } from "../../../../../redux/hooks";
import { isAgence, isProprioJuriste } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import QuittanceTable from "../../../../tables/agence/Administratif/Quittance/QuittanceTable";
import { AiFillPlusSquare } from "react-icons/ai";
import GenererateQuittenceLocataire from "../../../../modal/agence/GenerateQuittenceModale";



function Quittance() {
  const { user } = useAppSelector((s) => s?.user);
  return (
    <div className="bg-white py-3 px-1">
      <div className="locataire-requete-filtre-container flex-sb my-4">
        <form className="row g-3 locataire-requete-filtre-form">
          <div className="col-auto">
            <label
              htmlFor="filtreLogement"
              className="locataire-requete-filtre-label"
            >
              Filtrer
            </label>
          </div>
          <div className="col-auto">
            <input
              type="text"
              className="form-control locataire-requete-filtre-input"
              id="filtreLogement"
              placeholder="Entrer un nom"
              // onChange={(e: React.FormEvent<HTMLInputElement>) =>
              //   handleFilterByName(e)
              // }
            />
          </div>
        </form>
        <button
          className="btn btn-see-more"
          data-bs-toggle="modal"
          data-bs-target="#generateQuittenceModal"
        >
          <AiFillPlusSquare style={{ fontSize: 22 }} />
          <span className="ps-2">Générer</span>
        </button>
      </div>

      <GenererateQuittenceLocataire />

      <div className="locataire-requete-filtre-container my-4">

        <div className="row">
          
          {!!isProprioJuriste(user) ? (
            <AlertInfo message="Vous n'avez pas accès au données de cette section !" />
          ) : (
            <QuittanceTable />
          )}
        </div>
      </div>
    </div>
  );
}

export default Quittance;

export function QuittanceSkeleton() {
  const user = useAppSelector((s) => s.user.user);
  return (
    <div className="col-md-6 mb-3">
      <div style={{ position: "relative" }}>
        <div className="card custom-card-container3">

          <div className="custom-card-date">
            <VolkenoSkeleton variant="rect" height={10} width={120} />
          </div>
          {isAgence(user) &&
          <div className="custom-card-voir">
            <VolkenoSkeleton variant="circle" height={20} width={20} />
          </div>
          }
          <div className="custom-card-titre-container mb-3 d-flex justify-content-between">
            <div className="custom-card-titre">
              <VolkenoSkeleton variant="rect" height={10} width={90} />
            </div>
            <div className="custom-card-titre">
              {isAgence(user) ? (
                <VolkenoSkeleton variant="rect" height={10} width={90} />
              ) : (
                <VolkenoSkeleton variant="circle" height={20} width={20} />
              )}
            </div>
          </div>
          <VolkenoSkeleton variant="rect" height={200} />
        </div>
      </div>
    </div>
  );
}
