import React, { SetStateAction } from "react";
import { IoClose } from "react-icons/io5";
import { IEtatLieu } from "../../../utils/api/etatlieu/etatlieu.type";
import { BtnSubmit } from "../../common/Button";
import { Input } from "../../common/Input";
import useCrudEtatLieuxLocataire from "../../TableauDebord/locataire/Dashboard/Administratif/AdministratifTabs/AjouterEtatDesLieux/useCrudEtatLieuxLocataire";
import LocataireDocumentEtatLieux from "./AjouterEtatDesLieux/LocataireDocumentEtatLieux";

function AjoutEtatLieuxLocataireModal(props: {
  item?: IEtatLieu;
  setItem?: React.Dispatch<SetStateAction<IEtatLieu | undefined>>;
}) {
  const { item, setItem } = props;
  const { onSubmit, errors, handleChangeDocument, isLoading, reset } =
    useCrudEtatLieuxLocataire(item);
  const resetForm = () => {
    reset();
    setItem && setTimeout(() => setItem(undefined), 50);
  };

  return (
    <div
      className="modal fade"
      id="AjouterEtatLieuLocataireModal"
      aria-labelledby="AjouterEtatLieuLocataireModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header flex-sb">
            <h5 className="modal-title">
              {item ? "Modifier l'" : "Ajouter un"} état de lieu
            </h5>
            <button
              className="close-modal auth-submit-annuler"
              data-bs-dismiss="modal"
              type="button"
            >
              <IoClose />
            </button>
          </div>
          <div className="modal-body">
            <div className="container-form pt-3">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-12">
                    <Input
                      type="file"
                      label="Document"
                      id="document"
                      required
                      error={errors?.document}
                      onChange={handleChangeDocument}
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                    {item?.document ? (
                      <button
                        className="custom-btn-voir-contrat btn"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target={`#DocumentContratModal${item?.slug}`}
                      >
                        Afficher l'état des lieux
                      </button>
                    ) : null}
                    <div
                      className="modal fade"
                      id={`DocumentContratModal${item?.slug}`}
                      aria-labelledby="DocumentContratModalLabel"
                      aria-hidden="true"
                    >
                      <LocataireDocumentEtatLieux item={item} />
                    </div>
                  </div>
                </div>
                <div className="container-btn-modal row mt-5">
                  <div className="col-md-3">
                    <button
                      className="btn btn-cancelled"
                      data-bs-dismiss="modal"
                      type="button"
                      onClick={resetForm}
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="col-md-6 offset-md-3 flex-r">
                    <BtnSubmit label="Envoyer" isLoading={isLoading} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutEtatLieuxLocataireModal;
