import React, { useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  IImmeubles,
  ImmeublesFormData,
} from "../../../../../utils/api/immeubles/immeubles.type";
import {
  clean,
  cleannerError,
  isAgence,
  isProprietaireAgence,
  isProprietaireOwner,
  isProprioJuriste,
} from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { useNavigate } from "react-router-dom";
import { useAddOrUpdateImmeublesMutation } from "../../../../../utils/api/immeubles/immeubles.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { countries } from "../../../../../utils/Countries";
import { useGetAgencesQuery } from "../../../../../utils/api/agence/agence.api";
import { useGetProprietairesByAgenceQuery } from "../../../../../utils/api/proprietaire/proprietaire.api";
import { IProprietaire } from "../../../../../utils/api/proprietaire/proprietaire.type";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { Data } from "../../../../common/GoogleInput";

function UseAddOrUpdateImmeuble(immeuble?: IImmeubles | IPropriete) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    isOwner: yup.boolean(),
    agence: yup.string().when("isOwner", {
      is: false,
      then: yup.string().required().label("L'agence"),
    }),
    nom: yup.string().required().label("Titre"),
    numero: yup.string().required().label("Numero"),
    description: yup.string().required().label("Description"),
    // prix: yup.string().label("Prix"),
    adresse: yup.string().required().label("Adresse"),
    rue: yup.string().required().label("Rue"),
    code_postale: yup.string().label("Code postal").nullable(),
    ville: yup.string().required().label("Ville").nullable(),
    region: yup.string().required().label("Region").nullable(),
    pays: yup.string().required().label("Pays").nullable(),
    nbre_appart: yup.string().required().label("Nombre d'appartement"),
    url_youtube: yup.string().url().label("Lien vers la video"),
    // pictures: yup.array().max(4).required("Au moins une image"),
    pictures: yup.mixed().when("hasEdit", {
      is: false,
      then: yup
        .mixed()
        .required("Veuillez uploader au moins une image.")
        .nullable(),
    }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    control,
  } = useForm<ImmeublesFormData>({
    resolver: yupResolver(validationSchema),
  });
  // const inputRef = useRef<any>()
  const [option, setOption] = useState<any>(null);
  const [message, setMessage] = useState<string>("");
  const [address, setAddress] = useState("");
  let [pictures, setPictures] = useState<File[]>();
  const [addOrUpdateImmeuble, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateImmeublesMutation();
  const [proprietaires, setProprietaires] = useState<IProprietaire[]>();
  const navigate = useNavigate();
  const { user } = useAppSelector((s) => s?.user);
  const userID = user?.proprietaire_id?.slug
    ? user?.proprietaire_id?.id
    : user?.id;
  // let pays: any = countries?.find((el) => el?.name === immeuble?.pays);
  const [country, setCountry] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [city, setCity] = useState<any>("");

  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));

  const { data: ListProprietaire } = useGetProprietairesByAgenceQuery({
    slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
  });

  useEffect(() => {
    if (pictures?.length) {
      setValue(
        "pictures",
        pictures?.filter((el: any) => !el?.slug)
      );
    }
  }, [pictures]);

  const handleChangeAgence = (selected: any) => {
    setValue("agence", selected?.value);

    setOption(selected);
  };
  const handleCountrySelect = (option: Data) => {
    setCountry(option?.country);
    setValue("pays", option?.country);
    console.log("val country", option);
  };

  const handleStateSelect = (option: Data) => {
    console.log("val region", option);
    if (option?.rue) {
      setState(option?.region);
      setValue("region", option?.rue);
    }
  };
  const handleCitySelect = (option: Data) => {
    console.log("val city", option);
    setCity(option?.ville);
    setValue("ville", option.ville);
  };

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
    if (val?.country) {
      setValue("pays", val?.country);
      setCountry(val?.country);
    }

    if (val?.region) {
      setValue("region", val?.region);
      setState(val?.region);
    }

    if (val?.ville) {
      setValue("ville", val?.ville);
      setCity(val?.ville);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: immeuble?.slug
          ? "Immeuble modifié avec succès !"
          : "Immeuble ajouté avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (user?.user_type === "agence") {
          navigate("/agence/proprietes", { replace: true });
        } else if (
          user?.user_type === "proprietaire" ||
          isProprioJuriste(user)
        ) {
          if (isProprietaireOwner(user) || isProprioJuriste(user)) {
            navigate("/proprietaire/gerer-par-le-proprietaire/proprietes", {
              replace: true,
            });
          } else {
            navigate("/proprietes/gerer-par-agence/proprietes", {
              replace: true,
            });
          }
        }
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (immeuble?.id) {
      setValue("hasEdit", true);
      const fields: (keyof IImmeubles)[] = [
        "nom",
        "numero",
        "adresse",
        "rue",
        "pays",
        "ville",
        "region",
        "code_postale",
        "nbre_appart",
        "proprietaire",
        "description",
        "agence",
        "url_youtube",
      ];
      // console.log("object", immeuble);
      // setValue("hasEdit", true);
      for (let field of fields) {
        register(field);
        if (field === "proprietaire" || field === "agence") {
          if (immeuble[field]) {
            setValue(field, immeuble[field]?.id);
          } else {
            if (field !== "agence") {
              setValue(field, "agence");
            }
          }
        } else {
          if (immeuble[field]) {
            setValue(field, immeuble[field]);
          }
        }
      }
      if (immeuble?.agence?.id) {
        setOption({
          value: immeuble?.agence?.id,
          label: immeuble?.agence?.nom_agence,
        });
      }
      setPictures(immeuble?.pictures);
      setMessage(immeuble?.description);
      setAddress(immeuble?.adresse);
      setCountry(immeuble?.pays);
      setState(immeuble?.region);
      setCity(immeuble?.ville);
    } else {
      setValue("hasEdit", false);
    }
  }, [immeuble]);
  useEffect(() => {
    // console.log("selected err", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (isProprietaireAgence(user)) {
      setValue("isOwner", false);
    } else {
      setValue("isOwner", true);
    }
  }, [user]);

  useEffect(() => {
    if (ListProprietaire) {
      setProprietaires(ListProprietaire?.results);
    }
  }, [ListProprietaire]);

  const onSubmit = async (data: any) => {
    if (!immeuble?.id) {
      data["created_by"] = userID;
      if (user?.user_type === "agence") {
        data["agence"] = user?.id;
      }
      if (user?.user_type === "proprietaire") {
        data["proprietaire"] = user?.id;
      }

      if (user?.agence_id || user?.proprietaire_id) {
        if (user?.agence_id?.id) {
          data["agence"] = user?.agence_id?.id;
        }

        if (user?.proprietaire_id?.id) {
          data["proprietaire"] = user?.proprietaire_id?.id;
        }
      }
    }

    // data["agence"] = is_Agence ? user?.id : AgenceData && AgenceData[0];
    const fd = new FormData();
    if (pictures?.length && pictures?.filter((el: any) => !el?.slug)?.length) {
      for (const picture of pictures) {
        fd.append("pictures", picture);
      }
    }
    if (data["proprietaire"] === "agence") {
      data["agence_owner"] = true;
    }

    let dataSend = clean(data);
    for (let key of Object.keys(dataSend)) {
      const val = dataSend[key];
      if (key !== "pictures") {
        if (key === "proprietaire") {
          if (dataSend[key] !== "agence") {
            fd.append(key, val);
          } else {
            // data["agence_owner"] = true;
            let agence_owner: any = true;
            fd.append("agence_owner", agence_owner);
            // fd.append(key, "");
          }
        } else {
          fd.append(key, val);
          let agence_owner: any = false;
          fd.append("agence_owner", agence_owner);
        }
      }
    }
    // console.log("data submitted", dataSend);
    await addOrUpdateImmeuble({ slug: immeuble?.slug, data: fd });
  };

  const handleReset = () => {
    if (!immeuble) {
      reset();
    }
  };

  // useEffect(() => {
  //   if (immeuble?.id) {
  //     const fields: (keyof ImmeublesFormData)[] = [
  //       "nom",
  //       "description",
  //       "adresse",
  //       "code_postale",
  //       "ville",
  //       "pays",
  //       "region",
  //       "url_youtube",
  //     ];

  //     for (let field of fields) {
  //       if (field === "description") {
  //         setMessage(immeuble[field] || "");
  //       }
  //     }
  //     setAddress(immeuble?.adresse);
  //     setValue("pictures", immeuble.pictures);
  //   }
  // }, [immeuble]);

  const handleChangeMessage = useCallback((value: string) => {
    setMessage(value);
    setValue("description", value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    message,
    handleChangeMessage,
    handleReset,
    address,
    onChangeAddress,
    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    setCountry,
    city,
    pictures,
    setPictures,
    is_Agence,
    proprietaires,
    handleChangeAgence,
    option,
    Controller,
    control,
  };
}

export default UseAddOrUpdateImmeuble;
