/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import Swal from "sweetalert2";
import { DepenseFormData } from "../../../../../utils/api/depense/depense.type";
import { useAddOrUpdateDepenseMutation } from "../../../../../utils/api/depense/depense.api";
import { Color } from "../../../../../utils/theme";
import {
  cleannerError,
  isProprietaireOwner,
  onHide,
  useLocationState,
} from "../../../../../utils/Utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { useLazyGetLocataireByProprieteSlugQuery } from "../../../../../utils/api/locataire/locataire.api";
import {
  useGetLocataireByAgenceBySlugQuery,
  useGetProprieteComptaByAgenceBySlugQuery,
} from "../../../../../utils/api/agence/agence.api";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";
import { IProprietaire } from "../../../../../utils/api/proprietaire/proprietaire.type";
import {
  useGetPaiementProprioQuery,
  useGetProprietairesByAgenceQuery,
} from "../../../../../utils/api/proprietaire/proprietaire.api";

function UseCrudSortieForm(
  modalId: string,
  depense?: DepenseFormData,
  slugPro?: string
) {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const validationSchema = yup.object().shape({
    montant: yup.string().required().label("Montant").nullable(),
    propriete: yup
      .string()
      .when("categorie", {
        is: (val: string) =>
          selectedCategory !== "paiement_proprietaire_manuel",
        then: yup.string().required().label("Villa"),
        otherwise: yup.string().nullable(),
      })
      .nullable(),
    proprietaire: yup
      .string()
      .when("categorie", {
        is: (val: string) =>
          selectedCategory === "paiement_proprietaire_manuel",
        then: yup.string().required().label("Propriétaire"),
        otherwise: yup.string().nullable(),
      })
      .nullable(),
    categorie: yup.string().label("Catégorie").nullable(),
    locataire: yup
      .string()
      .when("categorie", {
        is: (val: string) =>
          selectedCategory !== "paiement_proprietaire_manuel",
        then: yup.string().required().label("Client"),
        otherwise: yup.string().nullable(),
      })
      .nullable(),
    justificatif: yup.mixed().label("Justificatif").nullable(),
    charge: yup.string().label("Charge").nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
    control,
  } = useForm<DepenseFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [message, setMessage] = useState<string>("");
  const [options, setOptions] = useState<any>([]);
  const [option, setOption] = useState<any>();

  const [addOrUpdateDepense, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateDepenseMutation();

  const [getLocataires, { data: locataire = { results: [] } }] =
    useLazyGetLocataireByProprieteSlugQuery();
  const { user } = useAppSelector((s) => s?.user);

  const [locataires, setLocataires] = useState<
    { label: string; value: number }[] | undefined
  >([]);

  const { data: locatAgence } = useGetLocataireByAgenceBySlugQuery({
    slug: user?.user_type === "gerant" ? user?.agence_id.slug : user?.slug,
  });

  const { data: proprieteAgence } = useGetProprieteComptaByAgenceBySlugQuery({
    slug: user?.user_type === "gerant" ? user?.agence_id.slug : user?.slug,
    limit: 10000,
  });

  const { data: proprietaires = { results: [] } } =
    useGetProprietairesByAgenceQuery({
      slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
      limit: 10000,
    });
  const [locataireItem, setLocataireItem] = useState<any>();
  const [slugPropri, setSlugPropri] = useState<number>();

  useEffect(() => {
    if (user?.user_type === "agence" || user?.user_type === "gerant") {
      if (locatAgence?.results?.length) {
        setLocataires(
          locatAgence?.results
            ?.filter((item: any) => item?.propriete?.id === option?.id)
            ?.map((el: any) => {
              return {
                label: el?.prenom + " " + el?.nom,
                value: el?.id,
                ...el,
              };
            })
        );

        let locataireEl: any = locatAgence?.results?.find(
          (item: any) => item?.propriete?.id === option?.id
        );
        if (locataireEl) {
          setValue("locataire", locataireEl?.id);
          setLocataireItem({
            label: locataireEl?.prenom + " " + locataireEl?.nom,
            value: locataireEl?.id,
            ...locataireEl,
          });
        } else {
          setValue("locataire", "");
          setLocataireItem("");
        }
      } else {
        setValue("locataire", "");
        setLocataires([]);
        setLocataireItem("");
      }
    }
  }, [option, locataire?.results?.length]);

  useEffect(() => {
    if (!isProprietaireOwner(user)) {
      const propriete = proprieteAgence?.results?.filter(
        (item) => item?.type_propriete !== "immeuble"
      );
      setOptions(
        propriete?.map((item) => {
          return {
            label: item?.nom,
            value: item?.id,
            ...item,
          };
        })
      );
      //   setFilterProperties(propriete);
    }
    // else {
    //   const propriete = biens?.results?.filter(
    //     (item) => item?.type_propriete !== "immeuble"
    //   );
    //   setOptions(
    //     propriete?.map((item) => {
    //       return {
    //         label: item?.nom,
    //         value: item?.id,
    //         ...item,
    //       };
    //     })
    //   );
    //   setFilterProperties(propriete);
    // }
  }, [proprieteAgence?.results]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: depense
          ? "Dépense modifiée avec succès !"
          : "Dépense ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!depense) {
          reset();
          setMessage("");
          setOption("");
          setLocataireItem("");
          setValue("proprietaire", "");
          setValue("categorie", "");
        }
        onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      // console.log({ err });

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : err?.originalStatus === 500
          ? `Error status ${err?.status}`
          : err?.data,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const handleChangePropriete = (e: any) => {
    setOption(e);
    setValue("propriete", e?.value);
    if (e?.value) {
      getLocataires({ slug: e?.slug });
    }
  };

  const handleChangeLocataire = (e: any) => {
    setValue("locataire", e?.value);
  };

  const handleJustificatif = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif" &&
      file?.type !== "application/pdf"
    ) {
      return setError("justificatif", {
        message: "Image format is incorrect.",
      });
    }
    if (file) {
      register("justificatif");
      setValue("justificatif", file);
    }
  }, []);

  const proprietaireTrouve = proprietaires.results?.find(
    (proprietaire: any) => proprietaire.id === slugPropri
  );

  const slugProprietaire = proprietaireTrouve ? proprietaireTrouve.slug : null;

  const {
    data: propsrio = {
      results: { items: [], total_depense: 0, total_restant: 0, montant_gerance: 0 },
      count: 0,
    },
  } = useGetPaiementProprioQuery({
    slug: slugProprietaire ? slugProprietaire : undefined,
  });

  console.log(propsrio?.results);
  

  const onSubmit = (data: any) => {
    const identifiantProprietaire = Number(data.proprietaire);
    setSlugPropri(identifiantProprietaire);

    const submitForm = () => {
      if (!depense) {
        data["agence"] = user?.agence_id?.slug ? user?.agence_id?.id : user?.id;
        data["charge"] = "agence";
      }

      if (
        user?.user_type === "gerant" &&
        selectedCategory === "paiement_proprietaire_manuel"
      ) {
        data["gerant"] = user?.id;
      }

      if (selectedCategory === "paiement_proprietaire_manuel") {
        data["locataire"] = null;
        data["propriete"] = null;
      } else {
        data["proprietaire"] = null;
      }

      const fd = new FormData();
      // console.log({ user });
      for (let key of Object.keys(data)) {
        if (data[key] !== null) {
          fd.append(key, data[key]);
        }
      }

      addOrUpdateDepense({ slug: depense?.slug, data: fd });
    };

    if (slugProprietaire) {
      if (
        selectedCategory === "paiement_proprietaire_manuel" &&
        propsrio?.results?.total_restant > 0
      ) {
        Swal.fire({
          title: `Paiements manuel propriétaire:`,
          html: `     
        <div class="place-card__content">
          <div class="payment-details-container">
            <div class="payment-details-content">
              <p class="payment-details-title">
                <span class="card-text-logement"> Commission : </span>
              </p>
              <p class="payment-details-amount">
             ${propsrio?.results?.montant_gerance} FCFA
              </p>
            </div>  
            <div class="payment-details-content">
              <p class="payment-details-title">
                <span class="card-text-logement"> Du au propriétaire : </span>
              </p>
              <p class="payment-details-amount">
              ${propsrio?.results?.total_restant} FCFA
              </p>
            </div>
            <div class="payment-details-total">
              <div class="payment-details-content">
                <p class="payment-details-title">
                  <span class="card-text-logement"> Dépenses : </span>
                </p>
                <p class="payment-details-amount">
                ${propsrio?.results?.total_depense} FCFA
                </p>
              </div>
            </div>
            
            </div>
            <div class="payment-details-total">
              <div class="payment-details-content">
                <p class="payment-details-title">
                  <span class="card-text-logement"> Montant transférer : </span>
                </p>
                <p class="payment-details-amount">
                  ${data.montant} FCFA
                </p>
              </div>
            </div>
            <div class="payment-details-total">
              <div class="payment-details-content">
                <p class="payment-details-title">
                  <span class="card-text-logement"> Montant restant : </span>
                </p>
                <p class="payment-details-amount">
                  ${propsrio?.results?.total_restant - data.montant} FCFA
                </p>
              </div>
            </div>
        </div>
      `,
          text: "Veuillez cliquer sur envoyer si vous voulez continuer!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ENVOYER",
          cancelButtonText: "ANNULER",
          showLoaderOnConfirm: true,
          iconColor: Color.bleu,
          confirmButtonColor: Color.bleu,
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result: any) => {
          if (result?.isConfirmed) {
            if (result?.value && !result?.value?.error) {
              submitForm();
            } else {
              let err = result?.value?.error;
              Swal.fire({
                icon: "error",
                title: err?.data?.message
                  ? err?.data?.message
                  : `Une erreur de statut ${err?.status} est survenue lors du transfère.`,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        });
      } else {
        submitForm();
      }
    }
  };

  const handleReset = () => {
    if (!depense) {
      reset();
    }
  };

  useEffect(() => {
    if (depense?.id) {
      const fields: (keyof DepenseFormData)[] = [
        "montant",
        "categorie",
        "proprietaire",
        "propriete",
        "locataire",
        "specification",
        "numero",
        "charge",
      ];
      // console.log("fields");
      for (let field of fields) {
        register(field);
        if (
          field === "categorie" ||
          field === "locataire" ||
          field === "propriete" ||
          field === "charge"
        ) {
          setValue(field, depense[field]?.id);
        } else {
          setValue(field, depense[field]);
        }
      }
      if (depense?.propriete?.id) {
        getLocataires({ slug: depense?.propriete?.slug });
        setOption({
          label: depense?.propriete?.nom,
          value: depense?.propriete?.id,
          ...depense?.propriete,
        });
      }
    }
  }, [depense]);

  const handleChangeMessage = useCallback((value: string) => {
    setMessage(value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    setSlugPropri,
    isLoading,
    message,
    handleChangeMessage,
    handleReset,
    handleJustificatif,
    handleChangePropriete,
    locataires,
    proprietes: proprieteAgence?.results,
    Controller,
    control,
    selectedCategory,
    setSelectedCategory,
    option,
    options,
    locataireItem,
    handleChangeLocataire,
  };
}

export default UseCrudSortieForm;
