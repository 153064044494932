import React from 'react'
import TypeProblemeTable from '../../../../tables/Admin/Options/TypeProbleme/TypeProblemeTable';

function TypeProbleme() {
    return (
        <div className="bg-white p-3 filter-and-table-container">
          <div>
            <TypeProblemeTable />
          </div>
        </div>
    );
}

export default TypeProbleme