import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DetailTypeRequeteFormValues, ITypeRequete } from "../../../../utils/api/typeRequete/typeRequete.type";
import { useAddDetailTypeRequeteMutation } from "../../../../utils/api/typeRequete/typerequete.api";
import { Color } from "../../../../utils/theme";
import { cleannerError, onHide } from "../../../../utils/Utils";

function UseCrudDetailTyperequete(item?: ITypeRequete) {
    const validationSchema = yup.object().shape({
		detail_type_requetes: yup
			.array()
			.of(
				yup.object().shape({
					nom: yup.string().required().label("Le nom de l'élement"),
                    charge: yup.string().nullable(),
				})
			)
			.required(),
	});

	const defaultValue = { nom: "", type_requete: item?.id, charge: "", active: false };

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		control,
		setValue,
	} = useForm<DetailTypeRequeteFormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			detail_type_requetes: [defaultValue],
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "detail_type_requetes",
	});

	const [addDetailTypeRequete, { isLoading, isSuccess, error, isError }] =
		useAddDetailTypeRequeteMutation();
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Item ajouté avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				onHide("AddDetailTypeRequete");
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Error status ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (formValues: DetailTypeRequeteFormValues) => {
		const data = formValues?.detail_type_requetes?.map((elem) => {
			return {
				nom: elem?.nom,
				type_requete: item?.id,
                active: true,
                charge: elem?.charge
			};
		});
		console.log({ detail_type_requetes: data });

		addDetailTypeRequete({ detail_type_requetes: data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		reset,
		fields,
		append,
		remove,
		defaultValue,
	};
}

export default UseCrudDetailTyperequete
