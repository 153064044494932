import React from "react";
import "../ProprietesAgence/ProprietesAgence.css";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../utils/type";
import useProprieteAgence from "../ProprietesAgence/requestPropriete/useProprieteAgence";
import ValidationList from "../validation/validationListe";
import ValidationManuel from "../validation/validationManuel";

const steps = [
  { id: "Validation-List", Component: ValidationList },
  { id: "immeubles", Component: ValidationManuel },
];

const tabs = ["paiements", "paiements manuel"];

function DemandeAgence() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  // const {
  //   proprietes,
  //   isLoading,
  //   handleFilterByTypeGerance,
  //   handleFilterByTypePropriete,
  //   perPage,
  //   setPage,
  //   page,
  //   proprieteCount,
  //   immeubles,
  //   handleFilterByName,
  //   handleFilterByNameImmo,
  //   immoCount,
  //   query,
  //   setPageImmo,
  //   pageImmo,
  // } = useProprieteAgence();
  // const props = {
  //   handleFilterByTypeGerance,
  //   proprietes,
  //   isLoading,
  //   handleFilterByTypePropriete,
  //   perPage,
  //   setPage,
  //   page,
  //   proprieteCount,
  //   immeubles,
  //   handleFilterByName,
  //   handleFilterByNameImmo,
  //   query,
  //   setPageImmo,
  //   pageImmo,
  //   immoCount,
  // };

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <div className="custom-filter-titre-select-container">
            <h4 className="kanimmo-dash-locataire-requetes-header-titre">
              Demande de validation paiement propriétaire
            </h4>
          </div>
        </div>
        <div className="kanimmo-tabs-locataire-container">
          <ul className="tabs-container pt-5 tabs-container-agence">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component  />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DemandeAgence;
