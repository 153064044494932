import React from 'react'
import Skeleton from 'react-loading-skeleton';

function AccountManagerSkeleton() {
  return (
    <div className="col-md-12">
      <table className="table table-striped">
        <thead>
          <tr>
            <th
              scope="col"
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#AAB2C8",
              }}
            >
              Nom
            </th>
            <th
              scope="col"
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#AAB2C8",
              }}
            >
              Email
            </th>
            <th
              scope="col"
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#AAB2C8",
              }}
            >
              N° téléphone
            </th>
            <th
              scope="col"
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#AAB2C8",
              }}
            >
              Date d'envoie
            </th>
            <th
              scope="col"
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#AAB2C8",
              }}
            >
              Statut
            </th>
            <th
              scope="col"
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#AAB2C8",
              }}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AccountManagerSkeleton