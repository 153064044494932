import moment from "moment";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useGetNewsletterQuery } from "../../../../../utils/api/newsletter/newsletter.api";
import { INewsletter } from "../../../../../utils/api/newsletter/newsletter.type";
import { AlertInfo } from "../../../../common/Alert";
import { CustomPagination } from "../../../../common/CustomPagination";
import NewsletterSkeleton from "./NewsletterSkeleton";

function NewsletterTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading } = useGetNewsletterQuery({
    page: page,
    limit: perPage,
  });

  const columns = [
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "created_at",
      text: "Date de souscription",
      style: { textAlign: "left" },
      formatter: (cell: Date, row: INewsletter) =>
        moment(cell).format("DD/MM/YYYY à HH:mm"),
    },
  ];

  return (
    <>
      {isLoading && <NewsletterSkeleton />}
      {!isLoading && data?.results && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => <AlertInfo message="Aucune donnée disponible" />}
            rowStyle={{ cursor: "pointer" }}
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export default NewsletterTable;
