/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect, ChangeEvent } from "react";
import { AiFillEyeInvisible } from "react-icons/ai";
import { RiEyeLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { UserType } from "../../../utils/api/user/user.type";
import { BtnSubmit } from "../../common/Button";
import ErrorMessage from "../../common/ErrorMessage";
import { FormError } from "../../common/CustomInputCheckbox";
import GoogleInput, { Data } from "../../common/GoogleInput";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export type RegisterStepPropsType = {
  navigation: any;
  personnalInfo?: UserType;
  setPersonnalInfo?: () => void;
  register: any;
  onSubmit: any;
  setValue: any;
  errors: any;
  isLoading: boolean;
  handelAvatarChange: (e: any) => void;
  previewAvatar: any;
  type: string;
  onChangeAddressAgence: (e: any) => void;
  addressAgence: any;
  handleChangeType: (e: ChangeEvent<HTMLSelectElement>) => void;
};

const AddPersonnalInfoProprietaire = ({
  register,
  setValue,
  errors,
  isLoading,
  handelAvatarChange,
  previewAvatar,
  type,
  handleChangeType,
  addressAgence,
  onChangeAddressAgence,
}: RegisterStepPropsType) => {
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);

  const [PasswordInput, setPasswordInput] = React.useState();

  const [phone, setPhone] = useState<string>();
  const [phoneWhatshap, setPhoneWhatshap] = useState<string>();

  const countUppercase = (str: any) => {
    if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str: any) => {
    if (str.replace(/[^a-z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str: any) => {
    if (str.replace(/[^0-9]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str: any) => {
    if (str.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str: any) => {
    const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e: any) => {
    var password = e.target.value;
    setPasswordInput(password);
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }

    setValue("password", e.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };
  const ref = useRef<any>();

  // useEffect(() => {
  //   if (errors?.telephone?.message) {
  //     ref.current.scrollIntoView();
  //   }
  // }, [errors?.telephone]);

  return (
    <div id="auth-form">
      <div className="row auth-form-row">
        <div className="col-md-12 auth-input-col">
          <div className="form-group auth-form-group">
            <label className="form-label form-label-login">
              Êtes-vous une personne physique ou morale ?
              <span style={{ color: "red" }}>*</span>
            </label>
            <select
              id="agence_id"
              className="form-select auth-form-select"
              value={type}
              onChange={(e) => handleChangeType(e)}
            >
              <option value="">Choisir</option>

              <option value="physique">Personne physique</option>
              <option value="morale">Personne morale</option>
            </select>
            {errors?.type_proprietaire && (
              <ErrorMessage message={errors?.type_proprietaire?.message} />
            )}
          </div>
        </div>

        {!!type && (
          <>
            <div className={type !== "morale" ? "d-none" : "d-block"}>
              <div className="col-md-12 auth-input-col">
                <div className="form-group auth-form-group">
                  <label className="form-label form-label-login">
                    Nom de la structure <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control auth-form-control"
                    id="nom_structure"
                    placeholder="Nom de la structure"
                    {...register("nom_structure")}
                  />
                  {errors?.nom_structure && (
                    <ErrorMessage message={errors?.nom_structure?.message} />
                  )}
                </div>
              </div>
              <div className="col-md-12 auth-input-col">
                <div className="form-group auth-form-group">
                  <label className="form-label form-label-login">
                    Ninea <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control auth-form-control"
                    id="ninea"
                    placeholder="Ninea"
                    {...register("ninea")}
                  />
                  {errors?.ninea && (
                    <ErrorMessage message={errors?.ninea?.message} />
                  )}
                </div>
              </div>
              <div className="col-md-12 auth-input-col">
                <div className="form-group auth-form-group">
                  <label className="form-label form-label-login">
                    Registre de commerce <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control auth-form-control"
                    id="register"
                    placeholder="Register de commerce"
                    {...register("registre")}
                  />
                  {errors?.registre && (
                    <ErrorMessage message={errors?.registre?.message} />
                  )}
                </div>
              </div>
              <div className="col-md-12 auth-input-col">
                <div className="form-group auth-form-group">
                  <label className="form-label form-label-login">
                    Mandat de location (pdf/word)
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control auth-form-control"
                    id="mandat_location"
                    accept=".doc, .docx, .pdf"
                    placeholder="Mandat de location"
                    // {...register("mandat_location")}
                    onChange={(e) => {
                      let file = e?.target?.files && e?.target?.files[0];
                      setValue("contrat", file);
                    }}
                  />
                  {errors?.contrat && (
                    <ErrorMessage message={errors?.contrat?.message} />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Genre <span style={{ color: "red" }}>*</span>
                </label>
                <select id="civilite" className="form-select auth-form-select">
                  <option value="" selected disabled>
                    Genre
                  </option>
                  <option value="homme">Homme</option>
                  <option value="femme">Femme</option>
                </select>
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Prénom(s) <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control auth-form-control"
                  id="prenom"
                  placeholder="Prenom"
                  {...register("prenom")}
                />
                {errors?.prenom && (
                  <ErrorMessage message={errors?.prenom?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Nom <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control auth-form-control"
                  id="nom"
                  placeholder="Nom"
                  {...register("nom")}
                />
                {errors?.nom && <ErrorMessage message={errors?.nom?.message} />}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Adresse email <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  className="form-control auth-form-control"
                  id="email"
                  placeholder="exemple@gmail.com"
                  {...register("email")}
                />
                {errors?.email && (
                  <ErrorMessage message={errors?.email?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">Adresse</label>
                <GoogleInput
                  className="form-control auth-form-control"
                  value={addressAgence}
                  onChange={onChangeAddressAgence}
                />
                <FormError error={errors?.adresse_agence?.message} />
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Téléphone{" "}
                  <span style={{ color: "red" }} ref={ref}>
                    *
                  </span>
                </label>
                <div className="input-group mb-3">
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="form-control auth-form-control mb-3 p-0"
                    inputClass="react-custom-tel-input"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) => {
                      setPhone(phone);

                      if (
                        country?.format?.match(/\./g)?.length === phone?.length
                      ) {
                        setValue("telephone", phone);
                      }
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
                {errors?.telephone && (
                  <ErrorMessage message={errors?.telephone?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">Whatsapp</label>
                <div className="input-group mb-3">
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="form-control auth-form-control mb-3 p-0"
                    inputClass="react-custom-tel-input"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phoneWhatshap}
                    onChange={(phone, country: any) => {
                      setPhoneWhatshap(phone);

                      if (
                        country?.format?.match(/\./g)?.length === phone?.length
                      ) {
                        setValue("whatsapp", phone);
                      }
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
                {errors?.whatsapp && (
                  <ErrorMessage message={errors?.whatsapp?.message} />
                )}
              </div>
            </div>
            {/* <div className="col-md-12 auth-input-col">
          <div className="form-group auth-form-group">
            <label className="form-label form-label-login">
              Souhaitez-vous recevoir des notifications via whatsapp?
              <span style={{ color: "red" }}>*</span>
            </label>
            <div className="d-flex align-items-center">
              <div className="checkbox">
                <input
                  className="form-check-input"
                  type="radio"
                  value="true"
                  // name="notif"
                  id="flexCheckDefaulttrue"
                  {...register("notification_whatsapp")}
                />
                <label
                  className="form-check-label form-label-login ms-2"
                  htmlFor="flexCheckDefaulttrue"
                >
                  OUI
                </label>
              </div>
              <div className="checkbox ms-3">
                <input
                  className="form-check-input"
                  type="radio"
                  value="false"
                  // name="notif"
                  id="flexCheckDefaultfalse"
                  {...register("notification_whatsapp")}
                />
                <label
                  className="form-check-label form-label-login ms-2"
                  htmlFor="flexCheckDefaultfalse"
                >
                  NON
                </label>
              </div>
            </div>
            {errors?.notification_whatsapp && (
              <ErrorMessage message={errors?.notification_whatsapp?.message} />
            )}
          </div>
        </div> */}
            {/* <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Choisissez votre nom d’utilisateur
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control auth-form-control"
                  id="usename"
                  placeholder="Nom d’utilisateur"
                  {...register("username")}
                />
                {errors?.username && (
                  <ErrorMessage message={errors?.username?.message} />
                )}
              </div>
            </div> */}
            <div className="col-md-12 auth-input-col position-relative">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Créer votre mot de passe{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  className="form-control auth-form-control"
                  id="password"
                  placeholder="**********"
                  onChange={passwordHandleChange}
                />
                {errors?.password && (
                  <ErrorMessage message={errors?.password?.message} />
                )}
              </div>
              <span className="span-eye-password" onClick={toggleShowPassword}>
                {!showPassword ? <RiEyeLine /> : <AiFillEyeInvisible />}
              </span>
            </div>
            {/* ============= Validation password ============= */}

            {PasswordInput && (
              <div className="col-md-12">
                <div className="input-group my-2 password-validation-terms-row">
                  <div className="password-validation-terms-container">
                    <h4 className="password-validation-terms-title">
                      Votre mot de passe doit contenir :{" "}
                    </h4>
                    <ul className="password-validation-terms-ul p-l-14">
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinCharacter && "text-success")
                        }
                      >
                        Au minimum 8 caractères
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinUppercase && "text-success")
                        }
                      >
                        Au minimum 1 caractère en majuscule
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinLowercase && "text-success")
                        }
                      >
                        Au minimum 1 caractère en minuscule
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinNumber && "text-success")
                        }
                      >
                        Au minimum 1 nombre
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinSpecialCharacter && "text-success")
                        }
                      >
                        Au minimum 1 caractère spéciale
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            <div className="col-md-12 auth-input-col position-relative">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Confirmez le mot de passe{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="password"
                  className="form-control auth-form-control"
                  id="confirm-password"
                  placeholder="**********"
                  {...register("confirmPassword")}
                />
                {errors?.confirmPassword && (
                  <ErrorMessage message={errors?.confirmPassword?.message} />
                )}
              </div>
              <span className="span-eye-password">
                <AiFillEyeInvisible />{" "}
              </span>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">Photo</label>
                <input
                  type="file"
                  className="form-control auth-form-control"
                  id="avatar"
                  accept="image/*"
                  onChange={(e) => handelAvatarChange(e)}
                  name="avatar"
                />
                {errors?.avatar && (
                  <ErrorMessage message={errors?.avatar?.message} />
                )}
                {previewAvatar && (
                  <div className="img-preview-container mb-3">
                    <img src={previewAvatar} className="type-img-preview" />
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <div className="py-4 col-md-4 offset-md-4 auth-submit-btn-container">
          <BtnSubmit
            label={type === "" ? "SUIVANT" : "S’inscrire"}
            isLoading={isLoading}
          />
        </div>
        <div className="flex-c align-items-center my-4 text-right-auth">
          <p className="text-inscription mb-0">Déjà inscrit?</p>
          <NavLink to="/connexion" className="btn-inscription-color ps-2">
            Connectez-vous
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default AddPersonnalInfoProprietaire;
