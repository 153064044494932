import React, { useEffect } from "react";
import "./MonLoyer.css";
import moneyBag from "../../../../../assets/icons/moneybag.png";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  formatMontant,
  formattedDate,
  onShow,
} from "../../../../../utils/Utils";
import usePaiementForm from "./requestForm/usePaiementForm";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { useLazyGetLocataireBySlugQuery } from "../../../../../utils/api/locataire/locataire.api";
import ChoicePaymentMethod from "./ChoicePaymentMethod";
import CheckStatusPaymentModal from "./CheckStatusPaymentModal";
import PaymentInvoiceModal from "./PaymentInvoiceModal";

function MonLoyer() {
  const { user } = useAppSelector((s) => s?.user);
  const [getData, { data: locataire }] = useLazyGetLocataireBySlugQuery();
  const {
    onSubmit,
    isLoading,
    mode,
    onLoading,
    onCheckStatus,
    isChecked,
    disabled,
    seconds,
  } = usePaiementForm(locataire);
  const navigate = useNavigate();
  const { search } = useLocation();

  // const getConsole = () => {
  //   console.log("teste interval time");
  // };
  useEffect(() => {
    if (user?.slug) {
      getData(user?.slug);
    }
  }, [user, isChecked]);

  useEffect(() => {
    // console.log("locataire", locataire);
    if (search?.includes("status=success")) {
      Swal.fire({
        icon: "success",
        title: `Paiement effectué avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(async () => {
        // await onSubmit()
        await navigate("/locataire/mon-loyer");
      });
    }
    if (search?.includes("status=error")) {
      Swal.fire({
        icon: "error",
        title: `Le paiement a échoué!`,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        navigate("/locataire/mon-loyer");
      });
    }
  }, [search]);

  useEffect(() => {
    // console.log("locataire", locataire);
    if (locataire && locataire?.cautionnement) {
      Swal.fire({
        icon: "info",
        title: `Merci de payer votre caution qui s'élève à ${locataire?.montant_caution} F CFA`,
        iconColor: Color.bleu,
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: "Payer ma caution",
        allowOutsideClick: false,
        confirmButtonColor: Color.bleu,
      }).then((result) => {
        if (result?.isConfirmed) {
          onShow("choicePaymentMethod");
        }
      });
    }
  }, [locataire]);

  return (
    <div className="landing-page-container custom-landing-page-container">
      <div className="container-fluid px-0  kanimmo-hp-container">
        <div className="dash-locataire-couverture pb-2">
          <div className="kanimmo-dash-locataire-info-bail-container">
            <div className="row">
              <div className="col-12">
                <div className="kanimmo-dash-locataire-info-bail-titre">
                  Fin du bail dans...
                </div>
              </div>
              <div className="col-md-5 col-md-offset-7">
                <div className="kanimmo-dash-locataire-info-bail-titre">
                  {locataire?.annee_restant !== 0 && (
                    <>
                      <span className="kanimmo-dash-locataire-info-bail-span">
                        {locataire?.annee_restant}
                      </span>
                      année{" "}
                    </>
                  )}
                  {locataire?.mois_restant !== 0 && (
                    <>
                      <span className="kanimmo-dash-locataire-info-bail-span">
                        {locataire?.mois_restant}
                      </span>
                      mois{" "}
                    </>
                  )}
                  {locataire?.mois_restant !== 0 && (
                    <>
                      <span className="kanimmo-dash-locataire-info-bail-span">
                        {locataire?.jour_restant}
                      </span>
                      jours
                    </>
                  )}
                </div>
              </div>
              {/* <div className="col-md-7">
                <div className="kanimmo-dash-locataire-btn-info-bail-btn-container">
                  <button
                    className="btn kanimmo-dash-locataire-btn-arret-bail"
                    title="Résilier mon bail"
                    data-bs-toggle="modal"
                    data-bs-target="#ResilierBailModal"
                  >
                    <RiCloseFill className="btn-arret-bail-close" /> Arrêter mon
                    bail
                  </button>
                  <button
                    className="btn kanimmo-dash-locataire-btn-rallonge-bail"
                    title="Rallonger mon bail"
                    data-bs-toggle="modal"
                    data-bs-target="#RallongerBailModal"
                  >
                    <RiEdit2Fill className="btn-arret-bail-edit" /> Rallonger
                    mon bail
                  </button>

                  <div
                    className="modal fade"
                    id="RallongerBailModal"
                    aria-labelledby="RallongerBailModalLabel"
                    aria-hidden="true"
                  >
                    <RallongerBailModal modalId="RallongerBailModal" />
                  </div>
                  <div
                    className="modal fade"
                    id="ResilierBailModal"
                    aria-labelledby="ResilierBailModalLabel"
                    aria-hidden="true"
                  >
                    <ResilierBailModal modalId="ResilierBailModal" />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="kanimmo-dash-locataire-info-paiment-container my-4">
            <div className="kanimmo-dash-locataire-info-paiment-icon">
              <img src={moneyBag} alt="money icon" />
            </div>
            <div className="row">
              <div className="col-12">
                <div className="kanimmo-dash-locataire-info-paiment-titre">
                  Dernier paiement
                </div>
                <div className="kanimmo-dash-locataire-detail-info-container">
                  <div className="kanimmo-dash-locataire-detail-info">
                    <div className="kanimmo-dash-locataire-detail-info-titre">
                      Montant
                    </div>
                    <div className="kanimmo-dash-locataire-detail-info-montant">
                      {formatMontant(locataire?.dernier_montant_paiement)}
                    </div>
                  </div>
                  <div className="kanimmo-dash-locataire-detail-info-date">
                    {locataire?.dernier_date_paiement !== null
                      ? formattedDate(locataire?.dernier_date_paiement)
                      : "Non défini"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="kanimmo-dash-locataire-info-paiment-container mb-4">
            <div className="kanimmo-dash-locataire-info-paiment-icon-2">
              <img src={moneyBag} alt="money icon" />
            </div>
            <div className="row">
              <div className="col-12">
                <div className="kanimmo-dash-locataire-info-paiment-titre">
                  Prochain paiement
                </div>
                <div className="kanimmo-dash-locataire-detail-info-container">
                  <div className="kanimmo-dash-locataire-detail-info">
                    <div className="kanimmo-dash-locataire-detail-info-titre">
                      Montant
                    </div>
                    <div className="kanimmo-dash-locataire-detail-info-montant">
                      {formatMontant(locataire?.prochain_montant_paiement)}
                    </div>
                  </div>
                  <div className="kanimmo-dash-locataire-detail-info-date">
                    {locataire?.prochain_date_paiement !== null
                      ? formattedDate(locataire?.prochain_date_paiement)
                      : "Non défini"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="kanimmo-dash-locataire-boutton-payer-container mb-4">
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn kanimmo-dash-locataire-boutton-payer"
                  title="Payer mon Loyer"
                  disabled={isLoading}
                  type="submit"
                  // onClick={onTestPayment}
                  data-bs-toggle="modal"
                  data-bs-target={`#choicePaymentMethod`}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>Paiement...</span>
                    </>
                  ) : (
                    "Payer mon loyer"
                  )}
                </button>

                <div
                  className="modal fade"
                  id="choicePaymentMethod"
                  aria-labelledby="choicePaymentMethodLabel"
                  aria-hidden="true"
                >
                  <ChoicePaymentMethod
                    onPay={onSubmit}
                    isLoading={isLoading}
                    mode={mode}
                  />
                </div>
                <CheckStatusPaymentModal
                  isLoading={onLoading}
                  onCheckStatus={onCheckStatus}
                  seconds={seconds}
                  disabled={disabled}
                  modalId="verifStatutPaymentLoyer"
                />
              </div>
              {/* <div className="col-md-6">
                <button
                  className="btn kanimmo-dash-locataire-boutton-payer-facture"
                  title="Payer mon Loyer"
                  disabled={isLoading}
                  type="submit"
                  data-bs-toggle="modal"
                  data-bs-target={`#paymentInvoiceModal`}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>Paiement...</span>
                    </>
                  ) : (
                    "Payer mes factures"
                  )}
                </button>

                <PaymentInvoiceModal modalId="paymentInvoiceModal" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonLoyer;
