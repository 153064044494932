/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { IoMdCloudDownload } from "react-icons/io";
import { IoEyeSharp } from "react-icons/io5";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import HistoriquePaiementSkeleton from "./HistoriquePaiementSkeleton";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetPaiementByLocataireBySlugQuery } from "../../../../../utils/api/paiement/paiement.api";
import { AlertInfo } from "../../../../common/Alert";
import { formatMontant, formattedDate, getName } from "../../../../../utils/Utils";
import { CustomPagination } from "../../../../common/CustomPagination";
import DocumentQuittanceModal from "../../../../modal/locataire/DocumentQuittanceModal";
import { ApiBaseUrl } from "../../../../../utils/http";

function HistoriquePaiementTable({date}: {date?: any}) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  const locataire = useAppSelector((s) => s?.user?.user);
  const { data = { results: [] }, isLoading } = useGetPaiementByLocataireBySlugQuery({
    slug: locataire?.slug,
    page: page,
    limit: perPage,
    date
  });
  const documentFormatter = (cell: any, row: any) => {
    return (
      <div className="document-formatter-container">
        {(row?.quittance !== "undefined" && row?.quittance !== null) ? (
          <>
            <a href={ApiBaseUrl + row?.quittance} target="_blank" className="btn btn-action-administratif-admin-down" download>
              <IoMdCloudDownload className="document-formatter-icon-download" />
            </a>
            <button
              className="document-formatter-container btn"
              role="button"
              data-bs-toggle="modal"
              data-bs-target={`#DocumentQuittanceModal${row?.slug}`}
            >
              <IoEyeSharp className="document-formatter-icon-print" />
            </button>
          </>
        ) : <span className="document-formatter-pas-quittance">Pas de quittance</span>}
        <div
          className="modal fade"
          id={`DocumentQuittanceModal${row?.slug}`}
          aria-labelledby="DocumentQuittanceModalLabel"
          aria-hidden="true"
        >
          <DocumentQuittanceModal item={row} />
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "locataire",
      text: "Locataire",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) => cell?.prenom + " " + cell?.nom,
    },
    {
      dataField: "date_du",
      text: "Date",
      formatter: (cell: string) => formattedDate(cell),
    },
    {
      dataField: "type_paiement",
      text: "Type de paiement",
      style: { textAlign: "left", textTransform: "capitalize" },
    },
    {
      dataField: "montant",
      text: "Montant",
      formatter: (cell: string) => formatMontant(cell),
    },
    {
      dataField: "created_at",
      text: "Date de paiement",
      formatter: (cell: string) => formattedDate(cell),
    },
    {
      dataField: "quittance",
      text: "Quittance",
      formatter: (cell: any, row: any) => documentFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <HistoriquePaiementSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            rowStyle={{ cursor: "pointer" }}
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={perPage}
					/>
        </>
      )}
    </>
  );
}

export default HistoriquePaiementTable;
