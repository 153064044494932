import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, isAgence, onHide } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetProprietesByAgenceQuery } from "../../../../../utils/api/propriete/propriete.api";
import {
  EtatLieuFormData,
  IEtatLieu,
} from "../../../../../utils/api/etatlieu/etatlieu.type";
import {
  useAddOrUpdateEtatLieuMutation,
  useDeleteEtatLieuMutation,
} from "../../../../../utils/api/etatlieu/etatlieu.api";
import { useGetAgencesQuery } from "../../../../../utils/api/agence/agence.api";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import {
  useGetLocataireByProprieteSlugQuery,
  useLazyGetLocataireByProprieteSlugQuery,
} from "../../../../../utils/api/locataire/locataire.api";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";

export function useCrudEtatLieu(modalId:string, item?: IEtatLieu) {
  const validationSchema = yup.object().shape({
    locataire: yup
      .number()
      .required()
      .label("Locataire")
      .typeError("Locataire est un champ obligatoire"),
    propriete: yup
      .number()
      .label("Propriéte")
      .typeError("Propriéte est un champ obligatoire"),
    document: yup.mixed().when("hasFile", {
      is: false,
      then: yup.mixed().required().label("Document"),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm<EtatLieuFormData>({
    resolver: yupResolver(validationSchema),
  });

  const { user } = useAppSelector((s) => s.user);

  const { data: ListeAgence } = useGetAgencesQuery({});
  const AgenceData = ListeAgence?.results?.filter(item => item?.id === user?.id)?.map(itm => itm);
  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));
  const agence = useAppSelector((s) => s.user.user);
  const { data } = useGetProprietesByAgenceQuery({ slug: is_Agence ? agence?.slug : AgenceData && AgenceData[0]?.slug, limit: 10000, });
  const [getLocataires, { data: locataire = { results: [] } }] =
    useLazyGetLocataireByProprieteSlugQuery();
  const [options, setOptions] = useState<any>([]);
  const [option, setOption] = useState<any>();
  const [locataireItem, setLocataireItem] = useState<any>();




  useEffect(() => {
    if (user?.user_type === "agence") {
      if (locataire?.results?.length) {
        setLocataires(
          locataire?.results
            ?.filter((item: ILocataire) => item?.propriete?.id === option?.id)
            ?.map((el: ILocataire) => {
              return {
                label: el?.prenom + " " + el?.nom,
                value: el?.id,
                ...el,
              };
            })
        );

        let locataireEl: ILocataire = locataire?.results?.find(
          (item: any) => item?.propriete?.id === option?.id
        );
        if (locataireEl) {
          setValue("locataire", locataireEl?.id);
          setLocataireItem({
            label: locataireEl?.prenom + " " + locataireEl?.nom,
            value: locataireEl?.id,
            ...locataireEl,
          });
        } else {
          setValue("locataire", "");
          setLocataireItem("");
        }
      } else {
        setValue("locataire", "");
        setLocataires([]);
        setLocataireItem("");
      }
    }
  }, [option, locataire?.results?.length]);

  useEffect(() => {
    if (data?.results?.length) {
      const propriete = data?.results?.filter(
        (item) => item?.type_propriete !== "immeuble"
      );
      setOptions(
        propriete?.map((item) => {
          return {
            label: item?.nom,
            value: item?.id,
            ...item,
          };
        })
      );
    }
  }, [data?.results]);

  const [locataires, setLocataires] = useState<
    { label: string; value: number }[] | undefined
  >([]);
  const [proprieteSlug, setProprieteSlug] = useState<string | undefined>("");
  const [document, setDocument] = useState<File>();

  const handleChangePropriete = (e: any) => {
    setOption(e);
    setValue("propriete", e?.value);
    if (e?.value) {
      getLocataires({ slug: e?.slug });
    }
  };

  const handleChangeLocataire = (e: any) => {
    setValue("locataire", e?.value);
  };


  const handleChangeDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null) {
      setValue("document", e.currentTarget.files[0]);
      setDocument(e.currentTarget.files[0]);
    }
  };

  const [addOrUpdateEtatLieu, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateEtatLieuMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Etat de lieu ${item ? "modifiée" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
       onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof EtatLieuFormData)[] = [
        "propriete",
        "locataire",
        "document",
      ];

      for (let field of fields) {
        if (field === "document" && item["document"]) {
          setValue("hasFile", true);
        } else {
          setValue("hasFile", false);
        }
        if (field === "propriete") {
          setValue("propriete", item?.propriete?.id);
        }
        if (field === "locataire") {
          setValue("locataire", item?.locataire?.id);
        }
      }
      if (item?.propriete?.id) {
        getLocataires({ slug: item?.propriete?.slug });
        setOption({
          label: item?.propriete?.nom,
          value: item?.propriete?.id,
          ...item?.propriete,
        });
      }
    }
  }, [item]);
  const onSubmit = (data: EtatLieuFormData) => {
    if (!item) {
      data["agence"] = user?.agence_id?.slug ? user?.agence_id?.id : user?.id;
    }
    const fd = new FormData();
    // if (agence) {
    //   fd.append("agence", is_Agence ? agence?.id : AgenceData && AgenceData[0]?.id);
    // }
    for (let key of Object.keys(data)) {
      const val = data[key];
      fd.append(key, val);
    }
    addOrUpdateEtatLieu({ slug: item?.slug, data: fd });
   
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangePropriete,
    proprietes: data?.results,
    locataires,
    handleChangeDocument,
    reset,
    Controller,
    control,
    option,
    options,
    locataireItem,
    handleChangeLocataire,
  };
}

export function UseDeleteEtatLieu(item: IEtatLieu) {
  const [deleteData] = useDeleteEtatLieuMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet état de lieu ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      // console.log(result, "deleted");
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Etat de lieu supprimée avec succès!`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
