import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import { IImmeubles } from "../../../utils/api/immeubles/immeubles.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import GoogleInput from "../../common/GoogleInput";
import UseAddOrUpdateImmeuble from "../../TableauDebord/Agence/OptionAgence/requestForm/UseAddOrUpdateImmeuble";
import {
  CitySelector,
  CountrySelector,
  StateSelector,
} from "volkeno-react-country-state-city";

function AjouterImmeubleModal({
  immeuble,
  modalId,
}: {
  immeuble?: IImmeubles;
  modalId: string;
}) {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    setValue,
    handleChangeMessage,
    message,
    address,
    onChangeAddress,
    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    setCountry,
  } = UseAddOrUpdateImmeuble(immeuble);
  useEffect(() => {
    if (immeuble) {
      setValue("slug", immeuble?.slug);
      setValue("nom", immeuble?.nom);
      setValue("description", immeuble?.description);
      setValue("code_postale", immeuble?.code_postale);
      setValue("adresse", immeuble?.adresse);
      setValue("ville", immeuble?.ville);
      setValue("region", immeuble?.region);
      setValue("pays", immeuble?.pays);
      setValue("nbre_appart", immeuble?.nbre_appart);
    }
  }, [immeuble]);

  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="nom"
                      className="form-label form-label-modal-custom"
                    >
                      Nom de l'immeuble
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-modal-custom"
                      id="nom"
                      placeholder="Nom"
                      {...register("nom")}
                    />
                    {errors?.nom && <FormError error={errors?.nom?.message} />}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="nbappart"
                      className="form-label form-label-modal-custom"
                    >
                      Nombre d'appartement
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-modal-custom"
                      id="nbappart"
                      placeholder="Nombre d'appartement"
                      {...register("nbre_appart")}
                    />
                    {errors?.nbre_appart && (
                      <FormError error={errors?.nbre_appart?.message} />
                    )}
                  </div>
                </div>

                {/* <div className="col-md-12">
                        <div className="mb-3">
                            <label
                                htmlFor="pays"
                                className="form-label form-label-modal-custom"
                            >
                                Pays
                            </label>
                           
                            <CountrySelector
                                onChange={handleCountrySelect}
                                name="pays"
                                placeholder={immeuble?.pays || "Pays"}
                                value={country}
                                containerClass="tx country-select-container custom-country-select-container p-0"
                            />
                            {errors?.pays && (
                                <FormError error={errors?.pays?.message} />
                            )}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label
                                htmlFor="region"
                                className="form-label form-label-modal-custom"
                            >
                                Région
                            </label>
                            

                            <StateSelector
                                country={country}
                                name="region"
                                value={state}
                                placeholder={immeuble?.region ? immeuble?.region : "Region"}
                                countryPlaceholder="Region"
                                onChange={handleStateSelect}
                                containerClass="tx country-select-container custom-country-select-container p-0"
                            />
                            {errors?.region && (
                                <FormError error={errors?.region?.message} />
                            )}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label
                                htmlFor="ville"
                                className="form-label form-label-modal-custom"
                            >
                                Ville
                            </label>
                            
                            <CitySelector
                                state={state}
                                name='city'
                                value={city}
                                statePlaceholder={immeuble?.ville ? immeuble?.ville : "Ville"}
                                onChange={handleCitySelect}
                                containerClass="tx country-select-container custom-country-select-container p-0"
                            />
                            {errors?.ville && (
                                <FormError error={errors?.ville?.message} />
                            )}
                        </div>
                    </div> */}
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="addresse"
                      className="form-label form-label-modal-custom"
                    >
                      Adresse
                    </label>

                    <GoogleInput
                      className="form-control form-control-modal-custom "
                      value={address}
                      onChange={onChangeAddress}
                    />
                    {errors?.adresse && (
                      <FormError error={errors?.adresse?.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="pays"
                    className="form-label form-label-modal-custom"
                  >
                    Pays{" "}
                    <span className="text-danger" style={{ fontSize: 24 }}>
                      *
                    </span>
                  </label>
                  <GoogleInput
                    className="form-control form-control-modal-custom"
                    placeholder="Pays"
                    value={country}
                    types={["country"]}
                    onChange={handleCountrySelect}
                  />
                  {/* <CountrySelector
                      onChange={handleCountrySelect}
                      name="pays"
                      placeholder={propriete?.pays || "Pays"}
                      value={country}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                  {errors?.pays && <FormError error={errors?.pays?.message} />}
                </div>
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="region"
                    className="form-label form-label-modal-custom"
                  >
                    Région{" "}
                    <span className="text-danger" style={{ fontSize: 24 }}>
                      *
                    </span>
                  </label>
                  <GoogleInput
                    className="form-control form-control-modal-custom"
                    placeholder="Région"
                    value={state}
                    types={[
                      "administrative_area_level_1",
                      "administrative_area_level_2",
                    ]}
                    onChange={handleStateSelect}
                  />
                  {/* <StateSelector
                      country={country}
                      name="region"
                      value={state}
                      placeholder={
                        propriete?.region ? propriete?.region : "Region"
                      }
                      countryPlaceholder="Region"
                      onChange={handleStateSelect}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                  {errors?.region && (
                    <FormError error={errors?.region?.message} />
                  )}
                </div>
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="ville"
                    className="form-label form-label-modal-custom"
                  >
                    Ville
                    <span className="text-danger" style={{ fontSize: 24 }}>
                      *
                    </span>
                  </label>
                  <GoogleInput
                    className="form-control form-control-modal-custom"
                    placeholder="Ville"
                    value={city}
                    types={["locality", "administrative_area_level_3"]}
                    onChange={handleCitySelect}
                  />
                  {/* <CitySelector
                      state={state}
                      name="ville"
                      value={city}
                      placeholder="Ville"
                      statePlaceholder={
                        propriete?.ville ? propriete?.ville : "Ville"
                      }
                      onChange={handleCitySelect}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                  {errors?.ville && (
                    <FormError error={errors?.ville?.message} />
                  )}
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="code_postal"
                      className="form-label form-label-modal-custom"
                    >
                      Code postale
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-modal-custom"
                      id="code_postal"
                      placeholder="code_postal"
                      {...register("code_postale")}
                    />
                    {errors?.code_postale && (
                      <FormError error={errors?.code_postale?.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="description"
                      className="form-label form-label-modal-custom"
                    >
                      Message
                    </label>
                    <ReactQuill
                      className="editor-cgu"
                      value={message}
                      onChange={(value) => handleChangeMessage(value)}
                    />
                    {errors?.description && (
                      <FormError error={errors?.description?.message} />
                    )}
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row mt-5">
                <div className="col-md-3">
                  <button
                    className="btn btn-cancelled"
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-md-6 offset-md-3 flex-r">
                  <BtnSubmit
                    label={immeuble ? "Modifier" : "Ajouter"}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterImmeubleModal;
