import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  IRequete,
  RequeteFormData,
  UrgenceRequete,
} from "../../../../../../utils/api/requete/requete.type";
import { cleannerError, onHide } from "../../../../../../utils/Utils";
import { useAppSelector } from "../../../../../../redux/hooks";
import { Color } from "../../../../../../utils/theme";
import { useAddOrUpdateRequeteMutation, useDeleteRequeteMutation } from "../../../../../../utils/api/requete/requete.api";


function useAddOrUpdaterequete(modalId: string, requete?: IRequete) {
  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Titre"),
    requete: yup.string().required().label("Requête"),
    type_requete: yup.string().required().label("Type"),
    detail_type_requete: yup.string().nullable().label("Détail type"),
    urgence: yup.string().nullable().label("Urgence"),
    charge: yup.string().nullable().label("Charge"),
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
  } = useForm<RequeteFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [message, setMessage] = useState<string>("");
  const [addOrUpdateRequete, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateRequeteMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: requete
          ? "Requête modifiée avec succès !"
          : "Requête ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        // if (!requete) {
          reset();
          setMessage("");
        // }
        onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const { user } = useAppSelector((s) => s?.user);
  const userID = user?.id;

// console.log("data user", user);

  const onSubmit = (data: any) => {
    data["locataire"] = userID;
    data["email"] = user?.email;
    data["telephone"] = user?.telephone;
    user?.agence_id ?
    data["agence"] = user?.agence_id?.id : data["proprietaire"] = user?.proprietaire_id?.id;
    
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "charge") {
        if (data[key] && data[key] !== undefined) {
          fd.append(key, data[key]);
        }
      } else if (key === "detail_type_requete") {
        if (data[key] && data[key] !== "autre") {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
  // console.log("data submitted", data);
    addOrUpdateRequete({ slug: requete?.slug, data: fd });
  };

  const handleReset = () => {
    if (!requete) {
      reset();
    }
  };

  useEffect(() => {
    if (requete?.id) {
      const fields: (keyof RequeteFormData)[] = [
        "titre",
        "requete",
        "type_requete",
        "detail_type_requete",
        "urgence",
        "charge"
      ];

      for (let field of fields) {
        if (field === "requete") {
          setMessage(requete[field] || "");
        }
      }
    }
  }, [requete]);

  const handleChangeMessage = useCallback((value: string) => {
    setMessage(value);
    setValue("requete", value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    message,
    handleChangeMessage,
    handleReset,
  };
}

export default useAddOrUpdaterequete;

export function UseDeleteRequete(
    slug: string, 
    // modalID: string
    ) {
	const [deleteData] = useDeleteRequeteMutation();
	const onDelete = async () => {
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer cette requête ?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			confirmButtonColor: Color.themeColor,
			preConfirm: () => {
				return deleteData(slug);
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			if (result?.value) {
        // console.log(modalID)
            Swal.fire({
                icon: "success",
                title: `Requête supprimée avec succès!`,
                iconColor: Color.themeColor,
                showConfirmButton: false,
                timer: 1500,
            })
            // .then(() => {
            // onHide("EnvoieSuppressionNoteModal");
            // });
			}
		});
	};
	return onDelete;
}

