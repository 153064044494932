import React, {  } from "react";
import Chart from "react-apexcharts";
import { useGetAdminDataQuery } from "../../../../../utils/api/admin/admin.api";

function AparcuChart() {
  const { data = { pourcentage_proprietaires: 0, pourcentage_agences: 0 } } =
    useGetAdminDataQuery({});
  const series = [data?.pourcentage_proprietaires, data?.pourcentage_agences];
  const options: any = {
    chart: {
      width: 380,
      type: "donut",
    },
    labels: ["Propriétaires", "Agences"],
    colors: [
      function ({ value, seriesIndex, w }: any) {
        // console.log('testy', w, value, seriesIndex)
        if (seriesIndex === 0) {
          return "#FCAE3B";
        } else {
          return "#1AA981";
        }
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: false,
              fontSize: 24,
              color: "#06152B",
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: 28,
              formatter: () => "100%",
            },
            value: {
              formatter: (val:any) => val + "%",
            },
          },
        },
        customScale: 0.8,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      fontFamily: "Helvetica, Arial",
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
    },
    title: {
      //   text: ''
    },
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       chart: {
    //         width: 200,
    //       },
    //       legend: {
    //         position: "left",
    //         //   show: false
    //       },
    //     },
    //   },
    // ],
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val: any) {
          return val + "%";
        },
      },
    },
  };

  return (
    <div className="chart-item-container">
      <div className="row mb-2">
        <div className="col-md-6 mb-2">
          <h2 className="chart-item-admin-title">Aperçu</h2>
        </div>
        {/* <div
            className="col-xl-4 offset-xl-2 col-md-5 offset-md-1 col-sm-6 mb-2 dis-flex"
            style={{ justifyContent: "flex-end" }}
            >
                <MdMoreHoriz />
            </div> */}
      </div>
      <Chart
        className="chart-dashboard-marketplace"
        options={options}
        series={series}
        type="donut"
        width={300}
        height={470}
      />
    </div>
  );
}

export default AparcuChart;
