import React from 'react'
import AccountInfo from "../../TableauDebord/Agence/OptionAgence/OptionsStep/AccountInfo";

function InfosPaiementModal() {
  return (
    <div
      className="modal fade"
      id="AccountInfoModal"
      data-bs-backdrop="static"
      aria-labelledby="AccountInfoModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header px-2 py-1">
            <h5 className="modal-title" id="AccountInfoModalLabel">
              Informations de paiement
            </h5>
            
          </div>
          <div className="modal-body">
            <AccountInfo />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfosPaiementModal