/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Facebook from "../../../../../assets/icons/facebook.png";
import Twitter from "../../../../../assets/icons/twitter.png";
import Linkedin from "../../../../../assets/icons/linkedin.png";
import bed from "../../../../../assets/icons/bed.png";
import bathtub from "../../../../../assets/icons/bathtub.png";
import kitchen from "../../../../../assets/icons/kitchen.png";
import plant from "../../../../../assets/icons/plant.png";
import pool from "../../../../../assets/icons/pool.png";
// import key from "../../../../../assets/icons/key.png";
import carpark from "../../../../../assets/icons/carpark.png";
import officechair from "../../../../../assets/icons/officechair.png";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import "./AgencesPQNGPSB.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  createMarkup,
  formatMontant,
  getAvatar,
  getImage,
  isProprietaireOwner,
  useLocationState,
} from "../../../../../utils/Utils";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";
import moment from "moment";
import { AlertInfo } from "../../../../common/Alert";
import { useFindProprieteBySlugQuery } from "../../../../../utils/api/propriete/propriete.api";
import { FaFileDownload } from "react-icons/fa";
import ContratFileModal from "../../../../modal/agence/ContratFileModal";
import { IconArmchair2, IconFan } from "../../../../common/Button";
import { Link } from "react-router-dom";
import { AiFillPlusSquare } from "react-icons/ai";

function InfosDetails() {
  const { user } = useAppSelector((state) => state.user);
  const itemState = useLocationState<IPropriete>(undefined);

  const navigate = useNavigate();

  const { data: propriete } = useFindProprieteBySlugQuery(itemState?.slug);
  console.log({ propriete });

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-proprietes-container">
          <div className="proprietaire-component-container">
            <div className="content-detail-proprietaire">
              <div className="row">
                {user?.user_type === "agence" && (
                  <div className="col-xl-6 col-lg-12 col-md-12 mb-4">
                    <div className="col-left-detail-proprietaire">
                      <div className="content-title-detail-proprietaire">
                        <p className="title-detail-proprietaire text-center">
                          Informations du propriétaire{" "}
                        </p>
                      </div>
                      <div className="text-center pt-3">
                        <div className="content-pp-proprietaire pb-2">
                          {propriete?.proprietaire?.avatar ||
                          propriete?.proprietaire?.avatar === null ? (
                            <img
                              src={getAvatar(propriete?.proprietaire?.avatar)}
                              alt="Avatar"
                              className="pp-proprietaire"
                            />
                          ) : (
                            <img
                              src={getAvatar(propriete?.agence?.avatar)}
                              alt="Avatar"
                              className="pp-proprietaire"
                            />
                          )}
                        </div>
                        <p className="title-detail-proprietaire">
                          {(propriete?.proprietaire?.prenom ||
                            propriete?.agence?.prenom) +
                            " " +
                            (propriete?.proprietaire?.nom ||
                              propriete?.agence?.prenom)}
                        </p>
                        <p className="title-detail-proprietaire">
                          {propriete?.proprietaire?.adresse ||
                            propriete?.agence?.adresse}
                        </p>
                      </div>
                      {/* <div className="custom-btn-social-media-container my-2">
                        <span className="input-group-text input-group-text-img">
                          <img
                            src={Facebook}
                            alt="social media"
                            className="social-media-contact"
                          />
                        </span>
                        <span className="input-group-text input-group-text-img">
                          <img
                            src={Linkedin}
                            alt="social media"
                            className="social-media-contact"
                          />
                        </span>
                        <span className="input-group-text input-group-text-img">
                          <img
                            src={Twitter}
                            alt="social media"
                            className="social-media-contact"
                          />
                        </span>
                      </div> */}
                      <div className="content-info-proprietaire">
                        <div className="content-info-prprietaire-item mb-2">
                          <MdOutlineMailOutline />
                          <span className="ps-3">
                            <a
                              className="no-link"
                              href={`mailto:${
                                propriete?.proprietaire?.email ||
                                propriete?.agence?.email
                              }`}
                            >
                              {propriete?.proprietaire?.email ||
                                propriete?.agence?.email}
                            </a>
                          </span>
                        </div>
                        <div className="content-info-prprietaire-item mb-2">
                          <IoCallOutline />
                          <span className="ps-3">
                            <a
                              className="no-link"
                              href={`tel:${
                                propriete?.proprietaire?.telephone ||
                                propriete?.agence?.telephone
                              }`}
                            >
                              {propriete?.proprietaire?.telephone ||
                                propriete?.agence?.telephone}
                            </a>
                          </span>
                        </div>
                        {propriete?.proprietaire && (
                          <div className="text-chiffre-info-dashboard-view mb-0">
                            <Link
                              to={`/agence/proprietaireinfos/${propriete?.proprietaire?.slug}`}
                              className="d-flex justify-content-end text-decoration-none  user-acount-dashboard-header-display g-2"
                              state={propriete?.proprietaire}
                            >
                              <span className=" ps-2">Visiter le profil</span>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/*  */}
                <div
                  className={
                    user?.user_type === "agence"
                      ? `col-xl-6 col-lg-12 col-md-12`
                      : `col-12`
                  }
                >
                  {propriete?.locataire.nom !== "" ? (
                    <div className="col-left-detail-proprietaire ">
                      <div className="content-title-detail-proprietaire">
                        <p className="title-detail-proprietaire text-center">
                          Information du locataire{" "}
                          {/* {userType !== "agence"
                          ? "du propriétaire"
                          : "de l'agence"} */}
                        </p>
                      </div>
                      <div className="text-center pt-3">
                        <div className="content-pp-proprietaire pb-2">
                          <img
                            src={getAvatar(propriete?.locataire?.avatar)}
                            alt="Avatar"
                            className="pp-proprietaire"
                          />
                        </div>
                        <p className="title-detail-proprietaire">
                          {propriete?.locataire?.prenom +
                            " " +
                            propriete?.locataire?.nom}
                        </p>
                        <p className="title-detail-proprietaire">
                          {propriete?.proprietaire?.adresse ||
                            propriete?.agence?.adresse}
                        </p>
                      </div>
                      {/* <div className="custom-btn-social-media-container my-2">
                        <span className="input-group-text input-group-text-img">
                          <img
                            src={Facebook}
                            alt="social media"
                            className="social-media-contact"
                          />
                        </span>
                        <span className="input-group-text input-group-text-img">
                          <img
                            src={Linkedin}
                            alt="social media"
                            className="social-media-contact"
                          />
                        </span>
                        <span className="input-group-text input-group-text-img">
                          <img
                            src={Twitter}
                            alt="social media"
                            className="social-media-contact"
                          />
                        </span>
                      </div> */}
                      <div className="content-info-proprietaire">
                        <div className="content-info-prprietaire-item mb-2">
                          <MdOutlineMailOutline />
                          <span className="ps-3">
                            <a
                              className="no-link"
                              href={`mailto:${propriete?.locataire?.email}`}
                            >
                              {propriete?.locataire?.email}
                            </a>
                          </span>
                        </div>
                        <div className="content-info-prprietaire-item mb-2">
                          <IoCallOutline />
                          <span className="ps-3">
                            <a
                              className="no-link"
                              href={`tel:${propriete?.locataire?.telephone}`}
                            >
                              {propriete?.locataire?.telephone}
                            </a>
                          </span>
                        </div>
                        <div className="text-chiffre-info-dashboard-view mb-0">
                          <Link
                            to={
                              user?.user_type === "agence"
                                ? `/agence/liste-locataire/${propriete?.locataire?.slug}`
                                : `/proprietaire/gerer-par-le-proprietaire/locataire/${propriete?.locataire?.slug}`
                            }
                            className="d-flex justify-content-end text-decoration-none  user-acount-dashboard-header-display g-2"
                            state={propriete?.locataire}
                          >
                            <span className=" ps-2">Visiter le profil</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <AlertInfo message="Cette propriété n'as pas de locataire" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfosDetails;

function CaracteristiqueCard({
  image,
  title,
  number,
}: {
  image: string;
  title: string;
  number: number;
}) {
  return (
    <div className="kanimmo-detail-appart-caracteristiques-image-item">
      <img
        src={image}
        alt="img illustration"
        className="kanimmo-detail-appart-caracteristiques-image"
      />
      <p className="kanimmo-detail-appart-caracteristiques-titre-image">
        {number} {title}
      </p>
    </div>
  );
}

const CardIcon = ({
  Image,
  title,
  number,
}: {
  Image: any;
  title: string;
  number: number;
}) => {
  return (
    <div className="kanimmo-detail-appart-caracteristiques-image-item">
      <Image className="kanimmo-detail-appart-caracteristiques-image app-svg-img" />
      <p className="kanimmo-detail-appart-caracteristiques-titre-image">
        {number} {title}
      </p>
    </div>
  );
};
