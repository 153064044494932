import React, { useState } from "react";
import { AiFillPlusSquare } from "react-icons/ai";
import OffresTable from "./table/OffresTable";
import CrudOfferModal from "./modal/CrudOfferModal";
import { showModal } from "../../../../utils/Utils";
// import AbonnementTable from "./table/AbonnementTable";
import AddAbonnementModal from "./modal/AddAbonnementModal";

// const tabs = ["Offres", "Abonnements"];
function OffersList() {
  // const [index, setIndex] = useState(0);
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Nos offres
          </h4>

          <div className="container-btn-add">
            <button
              className="btn btn-see-more"
              onClick={() => showModal("addAbonnementModal")}
            >
              <AiFillPlusSquare style={{ fontSize: 22 }} />
              <span className="ps-2">Ajouter un abonnement gratuit</span>
            </button>
            <button
              className="btn btn-see-more"
              onClick={() => showModal("addOfferModal")}
            >
              <AiFillPlusSquare style={{ fontSize: 22 }} />
              <span className="ps-2">Ajouter une offre</span>
            </button>
          </div>

          {/* {index === 1 && (
            <div className="container-btn-add">
              <button
                className="btn btn-see-more"
                onClick={() => showModal("addAbonnementModal")}
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter un abonnement gratuit</span>
              </button>
            </div>
          )} */}
        </div>
        <div className="kanimmo-tabs-locataire-container">
          {/* <ul className="tabs-container custom-tabs-container py-3">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => setIndex(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul> */}
          <div className="tabs-component-container">
            {/* <Component {...props} /> */}

            <div className="bg-white p-3 filter-and-table-container">
              <div>
                <OffresTable />
                {/* {index === 1 && <AbonnementTable />} */}
              </div>
            </div>
          </div>
        </div>
        <CrudOfferModal modalId="addOfferModal" />
        <AddAbonnementModal modalId="addAbonnementModal" />
      </div>
    </div>
  );
}

export default OffersList;
