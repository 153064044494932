import React from "react";
import { FormAjoutProprietaire } from "../../../Agence/ProprietaireAgence/AjouterProprietaire";
import { useCrudProprietaire } from "../../../Agence/ProprietaireAgence/useForm/useCrudProprietaire";

function AjouterClient() {
  const { item } = useCrudProprietaire();
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            {item ? "Modifier" : "Ajouter"} un client
          </h4>
        </div>
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <FormAjoutProprietaire redirectPath="/admin/mes-clients" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterClient;
