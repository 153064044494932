import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { useGetAgencesQuery } from "../../../../../../../utils/api/agence/agence.api";
import { IAgence } from "../../../../../../../utils/api/agence/agence.type";
import { ITypeCommodites } from "../../../../../../../utils/api/commodites/commodites.type";
import { useGetImmeublesByUserQuery } from "../../../../../../../utils/api/immeubles/immeubles.api";
import { useGetAgenceByProprietaireQuery } from "../../../../../../../utils/api/proprietaire/proprietaire.api";
import {
  ProprieteStepProps,
  TypePropriete,
} from "../../../../../../../utils/api/propriete/propriete.type";
import { GoBackButton } from "../../../../../../common/Button";
import { FormError } from "../../../../../../common/CustomInputCheckbox";
import { Input } from "../../../../../../common/Input";
import MyDropzone, {
  ImageGrid,
  ImagesView,
} from "../../../../../../common/MyDropzone";
import { SelectInput } from "../../../../../../common/SelectInput";
import ProprieteContent from "../../../../../Agence/ProprietesAgence/proporieteTabs/ProprieteContent";

const typesProprietesOptions = [
  {
    label: "Villa",
    value: TypePropriete.villa,
  },
  {
    label: "Appartement",
    value: TypePropriete.appartement,
  },
];

function DetailsPropriete({
  register,
  onSubmit,
  errors,
  description,
  pictures,
  handleChangeDescription,
  setPictures,
  propriete,
  handleChangeNumber,
  nbChambre,
  nbCuisine,
  handleChangeNumberCuisine,
  nbSDB,
  handleChangeNumberSDB,
  nbSalon,
  handleChangeNumberSalon,
  nbClim,
  handleChangeNumberClim,
  control,
  Controller,
  handleChangeAgence,
  option,
  commoditesList,
  commodites,
  onChangeCommodite,
}: ProprieteStepProps) {
  const { user } = useAppSelector((s) => s?.user);
  const animatedComponents = makeAnimated();
  const { data: ListeAgence, isLoading: loading } = useGetAgencesQuery({});

  const [showImmeubles, setShowImmeubles] = useState(false);
  const [immeublesList, setImmeublesList] = useState<
    { label: string; value: any }[] | undefined
  >();

  const toggleShow = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value === "appartement") {
      setShowImmeubles(true);
    } else {
      setShowImmeubles(false);
    }
  };

  // const { data: immeubles } = useGetImmeublesQuery({});
  const { data: immeubles } = useGetImmeublesByUserQuery({
    slug: user?.slug,
  });
  useEffect(() => {
    if (immeubles) {
      const ListeImb = immeubles?.immeubles?.map((item) => {
        return {
          label: item.nom,
          value: item.id,
        };
      });
      setImmeublesList(ListeImb);
    }
  }, [immeubles]);
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <form onSubmit={onSubmit}>
                <div className="row">
                  {/* <div className="col-md-6">
                    <SelectInput
                      label="Type de propriété"
                      required
                      options={typesProprietesOptions}
                      {...register("type_propriete")}
                      error={errors.type_propriete?.message}
                      onChange={toggleShow}
                    />
                  </div>
                  {/* {showImmeubles && (
                    <div className="col-md-6">
                      <SelectInput
                        label="Sélectionné l'immeuble"
                        // required
                        options={immeublesList}
                        {...register("immeuble")}
                        error={errors.immeuble?.message}
                      />
                    </div>
                  )} */}

                  <div className="col-md-12">
                    <Input
                      type="text"
                      label="Surface de la propriété (en mètre carré)"
                      id="surface"
                      min={1}
                      placeholder="Surface"
                      required
                      {...register("surface")}
                      error={errors.surface?.message}
                    />
                  </div>
                  <ProprieteContent register={register} errors={errors} />
                  {/* <div className="col-md-6">
                    <Input
                      type="number"
                      label="Prix de la location"
                      id="prix_location"
                      placeholder="Prix de la location"
                      min={1}
                      required
                      {...register("prix")}
                      error={errors.prix?.message}
                    />
                  </div> */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="nombre_stock"
                        className="form-label form-label-modal-custom"
                        aria-labelledby="proprietaire"
                      >
                        Sélectionner l'agence
                        <span
                          className="text-danger"
                          style={{
                            fontSize: 24,
                          }}
                        >
                          *
                        </span>
                      </label>
                      <Controller
                        name="agence"
                        control={control}
                        render={({ field }: any) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={false}
                              placeholder="Sélectionner l'agence"
                              components={animatedComponents}
                              options={ListeAgence?.results?.map((item) => {
                                return {
                                  value: item?.id,
                                  label: item?.nom_agence,
                                };
                              })}
                              inputClass="form-select form-select-modal-custom mb-2"
                              classNamePrefix="select-comodite"
                              isLoading={loading}
                              onChange={handleChangeAgence}
                              value={option}
                            />
                          );
                        }}
                      />
                      {/* <select
                        id="agence"
                        className="form-select form-select-modal-custom mb-2"
                        {...register("agence")}
                      >
                        <option value="" selected disabled>
                          Sélectionner l'agence
                        </option>
                        {agenceOptions?.map((item) => (
                          <option value={item.id} key={item.slug}>
                            {item.nom_agence}
                          </option>
                        ))}
                      </select> */}

                      {<FormError error={errors.agence?.message} />}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="number"
                      max={100}
                      min={0}
                      label="Taux de rémunération"
                      id="commission_agence"
                      placeholder="Taux de rémunération"
                      required
                      {...register("commission_agence")}
                      error={errors?.commission_agence}
                    />
                  </div>
                  {/* ================= COMMODITES=============== */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="type_propriété"
                        className="form-label form-label-modal-custom"
                      >
                        Commodités
                      </label>
                      <Controller
                        name="commodites"
                        control={control}
                        render={({ field }: any) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              options={commoditesList?.map(
                                (com: ITypeCommodites) => ({
                                  value: com?.id,
                                  label: com?.nom,
                                })
                              )}
                              inputClass="form-control-modal-custom"
                              classNamePrefix="select-comodite"
                              onChange={onChangeCommodite}
                              value={commodites}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="proprietaire"
                        className="form-label form-label-modal-custom"
                      >
                        Description
                        <span
                          className="text-danger"
                          style={{
                            fontSize: 24,
                          }}
                        >
                          *
                        </span>
                      </label>
                      <ReactQuill
                        className="editeur-react-quill"
                        value={description}
                        onChange={handleChangeDescription}
                      />
                      <FormError error={errors?.description?.message} />
                    </div>
                  </div>
                  <div className="col-12">
                    <MyDropzone setImages={setPictures} />
                    {/* {pictures?.length && (
                      <div className="row">
                        <ImageGrid images={pictures} />
                      </div>
                    )} */}
                    {pictures && (
                      <ImagesView setImages={setPictures} images={pictures} />
                    )}
                    <FormError error={errors.pictures?.message} />
                  </div>
                </div>
                <div className="container-btn-modal row my-4">
                  <div className="col-md-4 offset-md-8">
                    <div className="flex-r gap-3">
                      <GoBackButton label="Annuler" />

                      <button className="btn auth-submit-btn" type="submit">
                        Continuer
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsPropriete;
