import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { cleannerError, onHide } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { CategoryEntreeFormData, ICategoryEntree } from "../../../../../utils/api/category/category.type";
import { useAddOrUpdateCategoryEntreeMutation } from "../../../../../utils/api/category/category.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { useLocation } from "react-router-dom";


function useCrudCategorieEntree(modalId: string, item?: ICategoryEntree, handleToggle?: () => void, isAccountingPage?: boolean) {
    const validationSchema = yup.object().shape({
		titre: yup.string().required().label("Titre"),
		// description: yup.string().required().label("Description"),
	});

	const { user } = useAppSelector((state) => state.user);

	const location = useLocation();
  const urlPageActuel = location.pathname;

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<CategoryEntreeFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdateCategorie, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateCategoryEntreeMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Catégorie ${item ? "modifiée" : "ajoutée"} avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
				// onHide("AddCategorieModal");
				// onHide(modalId);
				// if(!item) {
				// 	reset()
				// };

				// if(urlPageActuel !== "/agence/comptabilite") {

				// 	onHide(modalId);
				// }

				if(urlPageActuel !== "/agence/comptabilite" && urlPageActuel !== "/proprietaire/gerer-par-le-proprietaire/comptabilite" && urlPageActuel !== "/comptable/mes-entrees") {

					onHide(modalId);
				}
				
				if(!item) {
					reset()
				};
				if (isAccountingPage && handleToggle) {
					reset();
					handleToggle(); 
				}
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("titre", item?.titre);
			// setValue("description", item?.description);
		}
	}, [item]);
	const onSubmit = (data: CategoryEntreeFormData) => {
		console.log("data", data);
		const dataWithUser: CategoryEntreeFormData = {
			...data,
			user: user.id,
		  };
		console.log("data", data)
		addOrUpdateCategorie({ slug: item?.slug, data: dataWithUser });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
	};
}

export default useCrudCategorieEntree