import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults } from "./validation.type";
import { IAValidation, ValidationFormData } from "./validation.type";  // Assurez-vous d'importer le bon type ici.

// ... (autres imports)

export const ValidationApi = createApi({
  reducerPath: "validationGerantApi",  
  tagTypes: ["validationGerant", "valdationGerant_slug"],   
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,  
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getValdatonGerants: build.query<PaginationResults<IAValidation>, { slug?: string }>({
      query: ({ slug }) => {
        console.log("Slug value:", slug);  // Ajoutez ce log pour débogage
        return `validatetransactons/${slug}/`;
      },
      providesTags: ["validationGerant"],
    }),
    getValidationManuelGerants: build.query<PaginationResults<IAValidation>, { slug?: string }>({
      query: ({ slug }) => {
        console.log("Slug value:", slug);  // Ajoutez ce log pour débogage
        return `demande_paiement_manuel/${slug}/`;
      },
      providesTags: ["validationGerant"],
    }),
    valdationCreate: build.mutation<ValidationFormData, {data: ValidationFormData }>({
      invalidatesTags: ["validationGerant"],
      query: ({data}) => {
				return {
					url: `validatetransacton/`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: ({ data }) => data,
    }),
    readDemmandeForAgence: build.mutation<void, { slug: string }>({
      query: ({ slug }) => ({
        url: `updateValidationIsRead/${slug}/`,
        method: 'POST',
      }),
      invalidatesTags: ["validationGerant"],
    }),
  }),
});

export const { useValdationCreateMutation, useGetValdatonGerantsQuery, useGetValidationManuelGerantsQuery,  useReadDemmandeForAgenceMutation } = ValidationApi;