import React from 'react'
import { IContrat } from '../../../utils/api/contrat/contrat.type'
import { ApiBaseUrl } from '../../../utils/http'
import { BtnCloseModal } from '../../common/Button'

function ViewDocModal({ item }: { item?: IContrat }) {
  return (
    <div
        className="modal fade"
        id="ViewDocModal"
        aria-labelledby="ViewDocModalLabel"
        aria-hidden="true"
    >
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="ViewDocModalLabel">
                        {item?.nom_document}
                    </h5>
                    <BtnCloseModal label="" />
                </div>
                <iframe src={ApiBaseUrl + item?.document} height={600}></iframe>
            </div>
        </div>
    </div>
  )
}

export default ViewDocModal