import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import UseAddOrUpdateImmeuble from "../../OptionAgence/requestForm/UseAddOrUpdateImmeuble";
import {
  CitySelector,
  CountrySelector,
  StateSelector,
} from "volkeno-react-country-state-city";
import GoogleInput from "../../../../common/GoogleInput";
import { FormError } from "../../../../common/CustomInputCheckbox";
import { BtnSubmit } from "../../../../common/Button";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../../../../common/Input";
import MyDropzone, { ImagesView } from "../../../../common/MyDropzone";
import { useProprieteFromLocation } from "../../../../../utils/api/propriete/propriete.api";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  isProprietaireOwner,
  isProprioJuriste,
} from "../../../../../utils/Utils";
import { useGetAgencesQuery } from "../../../../../utils/api/agence/agence.api";

function AjoutImmeubles() {
  const [proprieteItem] = useProprieteFromLocation();
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    setValue,
    handleChangeMessage,
    message,
    address,
    onChangeAddress,
    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    pictures,
    setPictures,
    proprietaires,
    handleChangeAgence,
    option,
    Controller,
    control,
  } = UseAddOrUpdateImmeuble(proprieteItem);
  const animatedComponents = makeAnimated();
  const { user } = useAppSelector((s) => s?.user);
  const { data: ListeAgence, isLoading: loading } = useGetAgencesQuery({});
  // const { data = { results: [] } } = useGetAgenceByProprietaireQuery({
  //   word: "",
  //   location: "",
  //   slug: user?.slug,
  // });

  const location: any = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(location);
    if (location?.state?.type_propriete) {
      register("type_propriete");
      setValue("type_propriete", location.state.type_propriete);
    } else {
      setTimeout(() => {
        navigate(-1);
      }, 100);
    }
  }, [location]);

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="kanimmo-dash-locataire-requetes-header-titre d-flex align-items-baseline gap-2">
                  <BiArrowBack
                    className="locataire-btn-arrow-back pe-2"
                    onClick={() => navigate(-1)}
                  />{" "}
                  {proprieteItem?.slug ? "Modifier" : "Ajouter"} un immeuble
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-3 filter-and-table-container">
          <div className="stepper-proprietes-form-container">
            <div className="landing-page-container">
              <div className="container-fluid px-0">
                <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
                  <div className="tabs-component-container locataire-requete-filtre-container">
                    <div className="pt-4 pb-5">
                      <form onSubmit={onSubmit}>
                        <div className="row">
                          {user?.user_type !== "proprietaire" &&
                          !isProprietaireOwner(user) &&
                          !isProprioJuriste(user) ? (
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="nombre_stock"
                                  className="form-label form-label-modal-custom"
                                  aria-labelledby="proprietaire"
                                >
                                  Sélectionner le propriétaire
                                  <span
                                    className="text-danger"
                                    style={{
                                      fontSize: 24,
                                    }}
                                  >
                                    *
                                  </span>
                                </label>
                                <select
                                  id="proprietaire"
                                  className="form-select form-select-modal-custom mb-2"
                                  {...register("proprietaire")}
                                >
                                  <option value="" selected disabled>
                                    Sélectionner le propriétaire
                                  </option>
                                  {proprietaires?.map((item: any) => (
                                    <option value={item.id} key={item.slug}>
                                      {item.prenom + " " + item.nom}
                                    </option>
                                  ))}
                                  <option value="agence">Nous même</option>
                                </select>

                                {<FormError error={errors.proprietaire} />}
                              </div>
                              <FormError error={errors.proprietaire?.message} />
                            </div>
                          ) : !isProprietaireOwner(user) &&
                            !isProprioJuriste(user) ? (
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="nombre_stock"
                                  className="form-label form-label-modal-custom"
                                  aria-labelledby="proprietaire"
                                >
                                  Gérer par
                                  <span
                                    className="text-danger"
                                    style={{
                                      fontSize: 24,
                                    }}
                                  >
                                    *
                                  </span>
                                </label>
                                <Controller
                                  name="agence"
                                  control={control}
                                  render={({ field }: any) => {
                                    return (
                                      <Select
                                        {...field}
                                        closeMenuOnSelect={false}
                                        placeholder="Sélectionner l'agence"
                                        components={animatedComponents}
                                        options={ListeAgence?.results?.map(
                                          (item) => {
                                            return {
                                              value: item?.id,
                                              label: item?.nom_agence,
                                            };
                                          }
                                        )}
                                        inputClass="form-select form-select-modal-custom mb-2"
                                        classNamePrefix="select-comodite"
                                        isLoading={loading}
                                        onChange={handleChangeAgence}
                                        value={option}
                                      />
                                    );
                                  }}
                                />
                              </div>
                              {<FormError error={errors.agence?.message} />}
                            </div>
                          ) : null}

                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="nom"
                                className="form-label form-label-modal-custom"
                              >
                                Nom de l'immeuble
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: 24,
                                  }}
                                >
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-modal-custom"
                                id="nom"
                                placeholder="Nom"
                                {...register("nom")}
                              />
                              {errors?.nom && (
                                <FormError error={errors?.nom?.message} />
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <Input
                              type="text"
                              label="Numero"
                              id="numero"
                              required
                              placeholder="Numero"
                              {...register("numero")}
                              error={errors.numero?.message}
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="nbappart"
                                className="form-label form-label-modal-custom"
                              >
                                Nombre d'appartement
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: 24,
                                  }}
                                >
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-modal-custom"
                                id="nbappart"
                                placeholder="Nombre d'appartement"
                                {...register("nbre_appart")}
                              />
                              {errors?.nbre_appart && (
                                <FormError
                                  error={errors?.nbre_appart?.message}
                                />
                              )}
                            </div>
                          </div>

                          {/* <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="pays"
                                className="form-label form-label-modal-custom"
                              >
                                Pays{" "}
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: 24,
                                  }}
                                >
                                  *
                                </span>
                              </label>
                              <CountrySelector
                                onChange={handleCountrySelect}
                                name="pays"
                                placeholder={proprieteItem?.pays || "Pays"}
                                value={country}
                                containerClass="tx country-select-container custom-country-select-container p-0"
                              />
                              {errors?.pays && (
                                <FormError error={errors?.pays?.message} />
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="region"
                                className="form-label form-label-modal-custom"
                              >
                                Région{" "}
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: 24,
                                  }}
                                >
                                  *
                                </span>
                              </label>
                              <StateSelector
                                country={country}
                                name="region"
                                value={state}
                                placeholder={
                                  proprieteItem?.region
                                    ? proprieteItem?.region
                                    : "Region"
                                }
                                countryPlaceholder="Region"
                                onChange={handleStateSelect}
                                containerClass="tx country-select-container custom-country-select-container p-0"
                              />
                              {errors?.region && (
                                <FormError error={errors?.region?.message} />
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="ville"
                                className="form-label form-label-modal-custom"
                              >
                                Ville{" "}
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: 24,
                                  }}
                                >
                                  *
                                </span>
                              </label>
                              <CitySelector
                                state={state}
                                name="city"
                                value={city}
                                placeholder={
                                  proprieteItem?.ville
                                    ? proprieteItem?.ville
                                    : "Ville"
                                }
                                statePlaceholder={
                                  proprieteItem?.ville
                                    ? proprieteItem?.ville
                                    : "Ville"
                                }
                                onChange={handleCitySelect}
                                containerClass="tx country-select-container custom-country-select-container p-0"
                              />
                              {errors?.ville && (
                                <FormError error={errors?.ville?.message} />
                              )}
                            </div>
                          </div> */}
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="addresse"
                                className="form-label form-label-modal-custom"
                              >
                                Adresse{" "}
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: 24,
                                  }}
                                >
                                  *
                                </span>
                              </label>
                              <GoogleInput
                                className="form-control form-control-modal-custom "
                                value={address}
                                onChange={onChangeAddress}
                              />
                              {errors?.adresse && (
                                <FormError error={errors?.adresse?.message} />
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              htmlFor="pays"
                              className="form-label form-label-modal-custom"
                            >
                              Pays{" "}
                              <span
                                className="text-danger"
                                style={{ fontSize: 24 }}
                              >
                                *
                              </span>
                            </label>
                            <GoogleInput
                              className="form-control form-control-modal-custom"
                              placeholder="Pays"
                              value={country}
                              types={["country"]}
                              onChange={handleCountrySelect}
                            />
                            {/* <CountrySelector
                      onChange={handleCountrySelect}
                      name="pays"
                      placeholder={propriete?.pays || "Pays"}
                      value={country}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                            {errors?.pays && (
                              <FormError error={errors?.pays?.message} />
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              htmlFor="region"
                              className="form-label form-label-modal-custom"
                            >
                              Région{" "}
                              <span
                                className="text-danger"
                                style={{ fontSize: 24 }}
                              >
                                *
                              </span>
                            </label>
                            <GoogleInput
                              className="form-control form-control-modal-custom"
                              placeholder="Région"
                              value={state}
                              types={[
                                "administrative_area_level_1",
                                "administrative_area_level_2",
                              ]}
                              onChange={handleStateSelect}
                            />
                            {/* <StateSelector
                      country={country}
                      name="region"
                      value={state}
                      placeholder={
                        propriete?.region ? propriete?.region : "Region"
                      }
                      countryPlaceholder="Region"
                      onChange={handleStateSelect}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                            {errors?.region && (
                              <FormError error={errors?.region?.message} />
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              htmlFor="ville"
                              className="form-label form-label-modal-custom"
                            >
                              Ville
                              <span
                                className="text-danger"
                                style={{ fontSize: 24 }}
                              >
                                *
                              </span>
                            </label>
                            <GoogleInput
                              className="form-control form-control-modal-custom"
                              placeholder="Ville"
                              value={city}
                              types={[
                                "locality",
                                "administrative_area_level_3",
                              ]}
                              onChange={handleCitySelect}
                            />
                            {/* <CitySelector
                      state={state}
                      name="ville"
                      value={city}
                      placeholder="Ville"
                      statePlaceholder={
                        propriete?.ville ? propriete?.ville : "Ville"
                      }
                      onChange={handleCitySelect}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                            {errors?.ville && (
                              <FormError error={errors?.ville?.message} />
                            )}
                          </div>
                          <div className="col-md-6">
                            <Input
                              type="text"
                              label="Rue"
                              id="rue"
                              required
                              placeholder="Rue"
                              {...register("rue")}
                              error={errors.rue?.message}
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="code_postale"
                                className="form-label form-label-modal-custom"
                              >
                                Code postale
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-modal-custom"
                                id="code_postale"
                                placeholder="Code postale"
                                {...register("code_postale")}
                              />
                              {errors?.code_postale && (
                                <FormError
                                  error={errors?.code_postale?.message}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <Input
                              type="url"
                              label="Lien vers la video"
                              id="url_youtube"
                              placeholder="Lien youtube"
                              {...register("url_youtube")}
                              error={errors.url_youtube?.message}
                            />
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label
                                htmlFor="description"
                                className="form-label form-label-modal-custom"
                              >
                                Description{" "}
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: 24,
                                  }}
                                >
                                  *
                                </span>
                              </label>
                              <ReactQuill
                                className="editor-cgu"
                                defaultValue={
                                  proprieteItem?.description || message
                                }
                                onChange={(value) => handleChangeMessage(value)}
                              />
                              {errors?.description && (
                                <FormError
                                  error={errors?.description?.message}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-12">
                            <label
                              htmlFor="description"
                              className="form-label form-label-modal-custom"
                            >
                              Images{" "}
                              <span
                                className="text-danger"
                                style={{
                                  fontSize: 24,
                                }}
                              >
                                *
                              </span>
                            </label>
                            <MyDropzone setImages={setPictures} />
                            {/* {pictures?.length ? (
                              <div className="row">
                                <ImageGrid images={pictures} />
                              </div>
                            ) : null} */}
                            {pictures && (
                              <ImagesView
                                setImages={setPictures}
                                images={pictures}
                              />
                            )}
                            <FormError error={errors.pictures?.message} />
                          </div>
                        </div>
                        <div className="container-btn-modal row mt-5">
                          <div className="col-md-3">
                            <button
                              className="btn btn-cancelled"
                              onClick={() => navigate(-1)}
                              type="button"
                            >
                              Annuler
                            </button>
                          </div>
                          <div className="col-md-6 offset-md-3 flex-r">
                            <BtnSubmit
                              label={proprieteItem?.id ? "Modifier" : "Ajouter"}
                              isLoading={isLoading}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutImmeubles;
