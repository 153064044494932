import React from 'react';
import { ApiBaseUrl } from "../../../../utils/http";
import { useExportUsersMutation } from '../../../../utils/api/admin/admin.api';
import { BiExport } from 'react-icons/bi';

interface ExportUsersButtonProps {
  modelType: string;
}

const ExportUsersButton: React.FC<ExportUsersButtonProps> = ({ modelType }) => {
  const [exportUsers, { isLoading }] = useExportUsersMutation();

  const handleExportUsers = () => {
    // let query = ''; // Initialisez la chaîne de requête
    // Créez la chaîne de requête en fonction du modelType
    // if (modelType) {
    //   query = `${modelType}`;
    // }

    // Ouvrez une nouvelle fenêtre avec l'URL d'exportation des utilisateurs
    window.open(`${ApiBaseUrl}/api/exporter_utilisateur/${modelType}/`, '_blank')?.focus();
  };

  return (
    <div className="dropdown dis-flex mt-3">
      <button
        className="btn btn-see-more dropdown-toggle btn"
        type="button"
        onClick={handleExportUsers}
      >
        <div className="user-acount-dashboard-header-display g-2">
          <BiExport style={{ fontSize: 22 }} />
          <span className="ps-2">Exporter les {modelType}s</span>
        </div>
      </button>
    </div>
  );
};

export default ExportUsersButton;

