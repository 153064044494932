import React from "react";
import UseAddAbonnementForm from "../request/UseAddAbonnementForm";
import { BtnCloseModal, BtnSubmit } from "../../../../common/Button";
import { Input } from "../../../../common/Input";
import { FormError } from "../../../../common/CustomInputCheckbox";
import { SelectInput } from "../../../../common/SelectInput";
import Select from "react-select";
import makeAnimated from "react-select/animated";

let types = [
  {
    label: "Agences",
    value: "agence",
  },
  {
    label: "Propriétaires",
    value: "proprietaire",
  },
];
function AddAbonnementModal({ modalId }: { modalId: string }) {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    handleReset,
    type,
    handleChange,
    options,
    option,
    handleSelect,
    onSearchItem,
    control,
    Controller,
    offres,
  } = UseAddAbonnementForm(modalId);
  const animatedComponents = makeAnimated();
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header px-2 py-1">
            <h5 className="modal-title" id="AddTagModalLabel">
              Ajouter un abonnement gratuit
            </h5>
            <BtnCloseModal label="" onClick={() => handleReset()} />
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit}>
              <div className="row py-4 px-3">
                <div className="col-md-12 mb-3">
                  <SelectInput
                    options={types}
                    label="Type de profil"
                    required
                    id="type"
                    value={type}
                    // {...register("type")}
                    onChange={(e) => handleChange(e)}
                    error={errors?.type?.message}
                  />
                </div>
                {!!type && (
                  <>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor={"user"}
                        className="form-label form-label-modal-custom"
                        aria-labelledby={"user"}
                      >
                        Utilisateur
                      </label>
                      <Controller
                        name="user"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              // isMulti
                              options={options}
                              onInputChange={onSearchItem}
                              classNamePrefix="react-select-offer"
                              onChange={handleSelect}
                              value={option}
                            />
                          );
                        }}
                      />
                      {<FormError error={errors?.user?.message} />}
                    </div>
                    <div className="col-md-12 mb-3">
                      <SelectInput
                        options={
                          offres?.map((el) => {
                            return {
                              label: el?.max_numb
                                ? el?.min_numb + "-" + el?.max_numb
                                : "Illimité",
                              value: el?.id,
                            };
                          }) || []
                        }
                        label="Offre"
                        required
                        id="offre"
                        {...register("offre")}
                        error={errors?.offre?.message}
                      />
                    </div>
                  </>
                )}

                <div className="flex-r">
                  <BtnSubmit
                    label={"Ajouter"}
                    isLoading={isLoading}
                    style={{ fontSize: 14 }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAbonnementModal;
