import React from "react";
import { NavLink } from "react-router-dom";
import Check from "../../../assets/icons/check.png";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    border: "none",
    textAlign: "center",
  },
};

const RegisterOk = ({
  modalIsOpen,
  setIsOpen,
}: {
  modalIsOpen: boolean;
  setIsOpen: any;
}) => {
  const userType = window.sessionStorage.getItem("userType");
  function closeModal() {
    setIsOpen(false);
  }

  return (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div className="body-modal">
				<div className="container-img-icon-check-modal">
					<img src={Check} alt="Icon check" className="icon-check-modal" />
				</div>
				<div className="container-text-message-modal py-4">
					<p className="text-message-modal">Inscription réussie</p>
				</div>
				<div className="container-btn-modal">
					<NavLink
						to={
							userType === "locataire"
								? "/locataire/mon-loyer"
								: userType === "agence"
								? "/agence/dashboard"
								: userType === "proprietaire"
								? "/proprietaire/gerer-par-le-proprietaire/dashboard"
								: userType === "admin"
								? "/admin/dashboard"
								: userType === "comptable"
								? "/comptable/dashboard"
								: userType === "proprietaireAgence"
								? "/proprietes/gerer-par-agence/dashboard"
								: ""
						}
						className="btn auth-submit-btn"
					>
						OK
					</NavLink>
				</div>
			</div>
		</Modal>
  );
};

export default RegisterOk;
