import React from 'react'
import Skeleton from 'react-loading-skeleton'

function ImmeubleAgenceSkeleton() {
    return (
        <div className="col-md-12">
          <table className="table table-striped">
            <thead>
              <tr>
                <th
                  scope="col"
                  style={{
                    fontFamily: "Raleway",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#AAB2C8",
                  }}
                >
                  Nom
                </th>
                <th
                  scope="col"
                  style={{
                    fontFamily: "Raleway",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#AAB2C8",
                  }}
                >
                  Adresse
                </th>
                <th
                  scope="col"
                  style={{
                    fontFamily: "Raleway",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#AAB2C8",
                  }}
                >
                  Nombre d'appartement
                </th>
                <th
                  scope="col"
                  style={{
                    fontFamily: "Raleway",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#AAB2C8",
                  }}
                >
                  Pays
                </th>
                <th
                  scope="col"
                  style={{
                    fontFamily: "Raleway",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#AAB2C8",
                  }}
                >
                  Région
                </th>
                <th
                  scope="col"
                  style={{
                    fontFamily: "Raleway",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#AAB2C8",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <Skeleton />
                </th>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <Skeleton />
                </th>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <Skeleton />
                </th>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <Skeleton />
                </th>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
}

export default ImmeubleAgenceSkeleton
