import React, { useState, useRef, useEffect } from "react";
import { AiFillEyeInvisible } from "react-icons/ai";
import RegisterOk from "../../modal/agence/RegisterOk";
import { IoIosArrowBack } from "react-icons/io";
import { UserType } from "../../../utils/api/user/user.type";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../common/ErrorMessage";
import { RiEyeLine } from "react-icons/ri";
import { BtnSubmit } from "../../common/Button";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export type RegisterStepPropsType = {
  navigation: any;
  personnalInfo?: UserType;
  setPersonnalInfo?: () => void;
  register: any;
  onSubmit: any;
  setValue: any;
  errors: any;
  isLoading: boolean;
  handelAvatarChange: (e: any) => void;
  previewAvatar: any;
};

export const choices = [
  {
    id: 1,
    value: "celibataire",
    display_name: "Célibataire",
  },
  {
    value: "marier",
    display_name: "Marié(e)",
  },
  {
    value: "divorces",
    display_name: "Divorcé(e)",
  },
];

const AddInfoPersoLoacataire = ({
  register,
  setValue,
  errors,
  isLoading,
  handelAvatarChange,
  previewAvatar,
  navigation,
}: RegisterStepPropsType) => {
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);
  const [PasswordInput, setPasswordInput] = React.useState();

  const [phone, setPhone] = useState<string>("");
  const [phoneWhatshap, setPhoneWhatshap] = useState<string>();

  const countUppercase = (str: any) => {
    if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str: any) => {
    if (str.replace(/[^a-z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str: any) => {
    if (str.replace(/[^0-9]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str: any) => {
    if (str?.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str: any) => {
    const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
    let count = 0;
    for (let i = 0; i < str?.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e: any) => {
    var password = e.target.value;
    setPasswordInput(password);
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }

    setValue("password", e.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(e: any) {
    e.preventDefault();
    window.sessionStorage.setItem("userType", "locataire");
    setIsOpen(true);
  }

  const ref = useRef<any>()

  useEffect(() => {
    if(errors?.telephone?.message)
    {
      ref.current.scrollIntoView() 
    }
  }, [errors?.telephone]);

  // const navigate = useNavigate()
  return (
    <div className="auth-form-container">
      <div className="py-4">
        <button
          className="btn btn-go-back-step"
          onClick={() => navigation.previous()}
        >
          <IoIosArrowBack />
          <span className="ps-2">Retour</span>
        </button>
      </div>
      <div>
        <div id="auth-form">
          <div className="row auth-form-row">
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">Genre</label>
                <select
                  id="civilite"
                  className="form-select auth-form-select"
                  {...register("civilite")}
                >
                  <option value="" selected disabled >Genre</option>
                  <option value="homme">Homme</option>
                  <option value="femme">Femme</option>
                </select>
                {errors?.civilite && (
                  <ErrorMessage message={errors?.civilite?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Prénom(s) <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control auth-form-control"
                  id="prenom"
                  placeholder="Prenom"
                  {...register("prenom")}
                />
                {errors?.prenom && (
                  <ErrorMessage message={errors?.prenom?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Nom <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control auth-form-control"
                  id="nom"
                  placeholder="Nom"
                  {...register("nom")}
                />
                {errors?.nom && <ErrorMessage message={errors?.nom?.message} />}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Adresse email <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  className="form-control auth-form-control"
                  id="email"
                  placeholder="exemple@gmail.com"
                  {...register("email")}
                />
                {errors?.email && (
                  <ErrorMessage message={errors?.email?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Téléphone <span style={{ color: "red" }} ref={ref}>*</span>
                </label>
                <div className="input-group mb-3">
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="form-control auth-form-control mb-3 p-0"
                    inputClass="react-custom-tel-input"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) => {
                      setPhone(phone);

                      if (
                        country?.format?.match(/\./g)?.length === phone?.length
                      ) {
                        setValue("telephone", phone);
                      }
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
                {errors?.telephone && (
                  <ErrorMessage message={errors?.telephone?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">Whatsapp</label>
                <div className="input-group mb-3">
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="form-control auth-form-control mb-3 p-0"
                    inputClass="react-custom-tel-input"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phoneWhatshap}
                    onChange={(phone, country: any) => {
                      setPhoneWhatshap(phone);

                      if (
                        country?.format?.match(/\./g)?.length === phone?.length
                      ) {
                        setValue("whatsapp", phone);
                      }
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
                {errors?.whatsapp && (
                  <ErrorMessage message={errors?.whatsapp?.message} />
                )}
              </div>
            </div>
            {/* <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Souhaitez-vous recevoir des notifications via whatsapp?
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div className="d-flex align-items-center">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="true"
                      id="flexCheckDefault1"
                      {...register("notification_whatsapp")}
                    />
                    <label
                      className="form-check-label form-label-login ms-2"
                      htmlFor="flexCheckDefault1"
                    >
                      OUI
                    </label>
                  </div>
                  <div className="checkbox ms-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="false"
                      {...register("notification_whatsapp")}
                      id="flexCheckDefault2"
                      checked
                    />
                    <label
                      className="form-check-label form-label-login ms-2"
                      htmlFor="flexCheckDefault2"
                    >
                      NON
                    </label>
                  </div>
                  {errors?.notification_whatsapp && (
                    <ErrorMessage
                      message={errors?.notification_whatsapp?.message}
                    />
                  )}
                </div>
              </div>
            </div> */}
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Date de naissance
                </label>
                <input
                  type="date"
                  className="form-control auth-form-control"
                  id="date"
                  {...register("date_de_naissance")}
                />
                {errors?.date_de_naissance && (
                  <ErrorMessage message={errors?.date_de_naissance?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Nationalité{" "}
                </label>
                <input
                  type="text"
                  className="form-control auth-form-control"
                  id="nationalite"
                  placeholder="Nationalité"
                  {...register("nationalite")}
                />
                {errors?.nationalite && (
                  <ErrorMessage message={errors?.nationalite?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Photo de profil
                </label>
                <input
                  type="file"
                  className="form-control auth-form-control"
                  id="avatar"
                  accept="image/*"
                  onChange={(e) => handelAvatarChange(e)}
                  name="avatar"
                />
                {errors?.avatar && (
                  <ErrorMessage message={errors?.avatar?.message} />
                )}
                {previewAvatar && (
                  <div className="img-preview-container mb-3">
                    <img src={previewAvatar} className="type-img-preview" />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Situation matrimoniale{" "}
                </label>
                <select
                  id="situation_matrimoniale"
                  className="form-select auth-form-select"
                  {...register("situation_matrimoniale")}
                >
                  <option value="" selected disabled >Situation matrimoniale</option>
                  {choices?.map((item) => (
                    <option value={item?.value} key={item?.id}>
                      {item?.display_name}
                    </option>
                  ))}
                </select>
                {errors?.situation_matrimoniale && (
                  <ErrorMessage
                    message={errors?.situation_matrimoniale?.message}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Précisez le nombre de personnes qui vivront avec vous
                </label>
                <input
                  type="text"
                  className="form-control auth-form-control"
                  id="nombre"
                  placeholder="3"
                  {...register("collocataire")}
                />
                {errors?.collocataire && (
                  <ErrorMessage message={errors?.collocataire?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Quelle est votre fonction?
                </label>
                <input
                  type="text"
                  className="form-control auth-form-control"
                  id="fonction"
                  placeholder="Fonction"
                  {...register("fonction")}
                />
                {errors?.fonction && (
                  <ErrorMessage message={errors?.fonction?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Votre lieu de travail
                </label>
                <input
                  type="text"
                  className="form-control auth-form-control"
                  id="lieu_de_travail"
                  placeholder="Lieu de travail"
                  {...register("lieu_de_travail")}
                />
                {errors?.lieu_de_travail && (
                  <ErrorMessage message={errors?.lieu_de_travail?.message} />
                )}
              </div>
            </div>
            {/* <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Choisissez votre nom d’utilisateur
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control auth-form-control"
                  id="username"
                  placeholder="Nom d’utilisateur"
                  {...register("username")}
                />
                {errors?.username && (
                  <ErrorMessage message={errors?.username?.message} />
                )}
              </div>
            </div> */}
            <div className="col-md-12 auth-input-col position-relative">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Créer votre mot de passe{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  className="form-control auth-form-control"
                  id="password"
                  placeholder="**********"
                  onChange={passwordHandleChange}
                />
                {errors?.password && (
                  <ErrorMessage message={errors?.password?.message} />
                )}
              </div>
              <span className="span-eye-password" onClick={toggleShowPassword}>
                {!showPassword ? <RiEyeLine /> : <AiFillEyeInvisible />}
              </span>
            </div>
            {/* ============= Validation password ============= */}

            {PasswordInput && (
              <div className="col-md-12">
                <div className="input-group my-2 password-validation-terms-row">
                  <div className="password-validation-terms-container">
                    <h4 className="password-validation-terms-title">
                      Votre mot de passe doit contenir :{" "}
                    </h4>
                    <ul className="password-validation-terms-ul p-l-14">
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinCharacter && "text-success")
                        }
                      >
                        Au minimum 8 caractères
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinUppercase && "text-success")
                        }
                      >
                        Au minimum 1 caractère en majuscule
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinLowercase && "text-success")
                        }
                      >
                        Au minimum 1 caractère en minuscule
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinNumber && "text-success")
                        }
                      >
                        Au minimum 1 nombre
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinSpecialCharacter && "text-success")
                        }
                      >
                        Au minimum 1 caractère spéciale
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-12 auth-input-col position-relative">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Confirmez le mot de passe{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="password"
                  className="form-control auth-form-control"
                  id="confirm-password"
                  placeholder="**********"
                  {...register("confirmPassword")}
                />
                {errors?.confirmPassword && (
                  <ErrorMessage message={errors?.confirmPassword?.message} />
                )}
              </div>
            </div>

            <div className="py-4 col-md-4 offset-md-4 auth-submit-btn-container">
              <BtnSubmit label="S’inscrire" isLoading={isLoading} />
              <RegisterOk modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInfoPersoLoacataire;
