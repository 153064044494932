import React, { useState } from "react";
import { useGetValdatonGerantsQuery } from "../../../../utils/api/validationGerant/validation.api";
import BootstrapTable from "react-bootstrap-table-next";
import {
  ValidationFormData,
  IAValidation,
} from "../../../../utils/api/validationGerant/validation.type";

import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";

import TableSkeleton from "../../../common/TableSkeleton";
import { useAppSelector } from "../../../../redux/hooks";
import { Color } from "../../../../utils/theme";
import {
  useSendPaymentMutation,
  useGetCreateCompteRenduMutation,
} from "../../../../utils/api/proprietaire/proprietaire.api";
import Swal from "sweetalert2";

// function DeleteButton({ item }: { item: ValidationFormData }) {
//   const onDelete = UseDeleteOffer(item);

//   return (
//     <button
//       className="btn btn-action-administratif-proprietaire with-tooltip"
//       data-tooltip-content="Supprimer"
//       onClick={() => onDelete()}
//     >
//       <IoMdTrash />
//     </button>
//   );
// }
function ValidationsTable() {
  const { user } = useAppSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const userSlug = user?.slug;
  const { data, isLoading } = useGetValdatonGerantsQuery({ slug: userSlug });
  const transactions = data?.transactions || [];
  const [sendData] = useSendPaymentMutation();

  const [comteRenduCreate] = useGetCreateCompteRenduMutation();

  const onSend = async (slug: string, nom: string) => {
    await Swal.fire({
      title: `Vous êtes sur le point de transfèrer  à ${nom}.`,
      text: "Veuillez cliquer sur envoyer si vous voulez valider la transaction!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ENVOYER",
      cancelButtonText: "ANNULER",
      showLoaderOnConfirm: true,
      iconColor: Color.bleu,
      confirmButtonColor: Color.bleu,
      preConfirm: () => {
        comteRenduCreate(slug);
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: "Le montant a été envoyer avec succès!",
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue lors du transfère.`,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  const actionFormatter = (cell: string, row: IAValidation) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes gap-2 d-flex">
          <div>
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              disabled={row?.est_validee}
              onClick={() =>
                onSend(row?.proprietaire_slug, row?.proprietaire_name)
              }
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-2 0H3V6h14v8zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm13 0v11c0 1.1-.9 2-2 2H4v-2h17V7h2z"></path>
              </svg>
            </button>
          </div>

          <div>{/* <DeleteButton item={row} /> */}</div>
        </div>
        {/* <CrudOfferModal modalId={`editOfferModal${cell}`} offer={row} />
        <OfferDetailsModal modalId={`showOfferModal${cell}`} offer={row} /> */}
      </>
    );
  };

  const columns = [
    {
      dataField: "gerant_name",
      text: "gerants",
      formatter: (cell: number, row: ValidationFormData) => cell,
    },
    {
      dataField: "proprietaire_name",
      text: "proprietaire",
      formatter: (cell: number) => cell,
    },
    {
      dataField: "est_validee",
      text: "Etat",
      formatter: (cell: boolean) => (cell ? "Validé" : "En attente"),
      style: (cell: boolean) => ({
        color: cell ? "green" : "orange", // Choisir vos couleurs ici
      }),
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: IAValidation) =>
        actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  console.log("mes demande", transactions);

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={transactions}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
            rowStyle={{ cursor: "pointer" }}
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            perPage={limit}
            onChange={(page, perPage) => {
              setLimit(perPage);
              setPage(page);
            }}
          />
        </>
      )}
    </>
  );
}

export default ValidationsTable;
