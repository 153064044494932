import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";

import moment from "moment";
import { LocataireFormData } from "../../../../../../utils/api/locataire/locataire.type";
import { cleannerError, getImage } from "../../../../../../utils/Utils";
import {
  useAddOrUpdateLocataireMutation,
  useDeleteLocataireMutation,
} from "../../../../../../utils/api/locataire/locataire.api";
import { useNavigate } from "react-router-dom";
import { Color } from "../../../../../../utils/theme";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useSendSlugs } from "../../../../Agence/Locataires/CRUDLocataire/useAddOrUpdateLocataire";

function useAddOrUpdateLocataire(locataire?: LocataireFormData) {
  const validationSchema = yup.object().shape({
    id: yup.number(),
    hasFile: yup.boolean(),
    nom: yup.string().required().label("Nom"),
    prenom: yup.string().required().label("Prenom"),
    // date_de_naissance: yup.string().label("Date de naissance"),

    email: yup.string().required().label("Email"),
    telephone: yup.string().required().label("Telephone"),
    duree: yup.string().label("Duree du bail"),
    date_debut: yup
      .string()
      .nullable()
      .default(moment(new Date()).format("YYYY-MM-DD"))
      .label("Debut du bail"),
    date_fin: yup.string().label("Fin du bail"),
    date_prochaine_paiement: yup
      .string()
      .required()
      .label("Date du prochain paiement"),
    sequence_paiement: yup.string().required().label("Fréquence de Paiement"),
    propriete: yup.string().required().label("Bien"),
    contrat: yup.mixed().nullable().label("Contrat de bail"),
    cautionnement: yup.boolean().nullable().label("Cautionnement"),
    // montant_caution: yup.string().label("Montant caution"),
    montant_caution: yup
      .string()
      .when("cautionnement", {
        is: true,
        then: yup.string().required().label("Montant caution").nullable(),
      })
      .nullable(),
    pay_proratat: yup.boolean().nullable().default(false),
    proratat: yup
      .number()
      .when("pay_proratat", {
        is: true,
        then: yup
          .number()
          .required()
          .label("Montant du prorata")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .transform((value) => (isNaN(value) ? null : value))
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
    control,
  } = useForm<LocataireFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [previewAvatar, setPreviewAvatar] = useState(
    locataire?.avatar ? getImage(locataire.avatar) : null
  );
  const [isChecked, setIsChecked] = useState(false);
  const [addOrUpdateLocataire, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateLocataireMutation();

  const navigate = useNavigate();
  const [isCheckedCaution, setIsCheckedCaution] = useState(false);
  const { user } = useAppSelector((s) => s?.user);

  const handelAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "application/pdf" &&
      file?.type !==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
      file?.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      file?.type !== "application/application/msword" &&
      file?.type !== "application/vnd.ms-excel"
    ) {
      return setError("contrat", { message: "Format du fichier incorrect." });
    }
    if (file) {
      register("contrat");
      setValue("contrat", file);
    }
  }, []);

  const handleChecked = (e: any) => {
    if (e?.target?.value === "true") {
      setIsChecked(true);
      setValue("pay_proratat", true);
    } else {
      setIsChecked(false);
      setValue("pay_proratat", false);
    }
  };

  const handleCheckedCaution = (e: any) => {
    if (e?.target?.value === "true") {
      setIsCheckedCaution(true);
      setValue("cautionnement", true);
    } else {
      setIsCheckedCaution(false);
      setValue("cautionnement", false);
    }
  };

  const formData = {
    slug_user: user?.slug,
    locataire_slug: locataire?.slug,
  };

  const sendSlugsToServer = useSendSlugs();

  const handleSendSlugs = () => {
    sendSlugsToServer(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: locataire
          ? locataire?.is_archive === true
            ? "Locataire desarchivé avec succès !"
            : "Locataire modifié avec succès !"
          : "Locataire ajouté avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!locataire) {
          reset();
          setPreviewAvatar(null);
        }
        if (locataire?.is_archive === true) {
          handleSendSlugs();
        }
        navigate("/proprietaire/gerer-par-le-proprietaire/locataires");
      });
    }

    const err = error as any;
    if (isError) {
      if (err?.data?.montant_caution && err.data.montant_caution[0]) {
        // Afficher l'erreur spécifique "montant_caution"
        Swal.fire({
          icon: "error",
          title: err?.data?.montant_caution[0],
          showConfirmButton: false,
          timer: 5000,
        });
      } else if (
        err?.data?.email?.includes("user with this email already exists.")
      ) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Error status ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    console.log("errors", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: any) => {
    console.log("data submitted", data);
    // data["proprietaire_id"] = user?.id;
    if (!locataire) {
      data["proprietaire_id"] = user?.proprietaire_id
        ? user?.proprietaire_id?.id
        : user?.id;
    }
    if (data["cautionnement"] == false) {
      if (data["montant_caution"] == 0 || data["montant_caution"] == null) {
        data["montant_caution"] = 0;
      }
    }
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "contrat") {
        if (data[key] && data[key] !== undefined) {
          fd.append(key, data[key]);
        }
      } else if (key === "proratat") {
        if (data?.pay_proratat === true) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    console.log("data", data);
    addOrUpdateLocataire({ slug: locataire?.slug, data: fd });
  };

  const handleReset = () => {
    if (!locataire) {
      reset();
    }
  };

  const [option, setOption] = useState<any>(null);
  const handleChangePropriete = (selected: any) => {
    setValue("propriete", selected?.value);

    setOption(selected);
  };

  useEffect(() => {
    if (locataire?.id) {
      const fields: (keyof LocataireFormData)[] = [
        "nom",
        "prenom",
        "contrat",
        "sequence_paiement",
        // "date_de_naissance",
        "email",
        "telephone",
        "date_debut",
        "date_fin",
        "date_prochaine_paiement",
        "propriete",
        "cautionnement",
        "montant_caution",
        "proratat",
      ];
      console.log("fields");

      for (let field of fields) {
        register(field);
        if (field !== "contrat") {
          register(field);
          if (field === "propriete") {
            setValue(field, locataire[field]?.id);
          } else {
            setValue(field, locataire[field]);
          }
        }

        if (locataire?.propriete) {
          setOption({
            value: locataire?.propriete,
            label: locataire?.propriete?.nom,
          });
        }
      }
    }
  }, [locataire]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    handelAvatarChange,
    previewAvatar,
    control,
    handleChangePropriete,
    option,
    isChecked,
    handleChecked,
    isCheckedCaution,
    handleCheckedCaution,
  };
}

export default useAddOrUpdateLocataire;

export function UseDeleteLocataire(slug: string) {
  const [deleteData] = useDeleteLocataireMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce locataire ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Locataire supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
