import React from "react";
import { NavLink } from "react-router-dom";
import Check from "../../../assets/icons/check.png";
import Modal from "react-modal";
import { IPersonnel } from "../../../utils/api/agence/agence.type";
import { isProprietaireOwner } from "../../../utils/Utils";
import { useAppSelector } from "../../../redux/hooks";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    border: "none",
    textAlign: "center",
    minWidth: "225px",
  },
};

function ConfirmAddPersonnel({
  modalIsOpen,
  setIsOpen,
  personne,
  item,
  from,
}: {
  modalIsOpen: boolean;
  setIsOpen: any;
  personne?: string;
  item?: IPersonnel;
  from?: string;
}) {
  const { user } = useAppSelector((s) => s?.user);
  const closeModal = () => {
    setIsOpen(false);
  };
  // console.log("perso", item);
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="body-modal">
        <div className="container-img-icon-check-modal">
          <img src={Check} alt="Icon check" className="icon-check-modal" />
        </div>
        <div className="container-text-message-modal py-4">
          <p className="custom-text-message-modal">
            {personne} {item ? "modifé" : "ajouté"} !!!
          </p>
          {!item && (
            <p className="custom-text-message-modal">
              Email envoyé avec succés
            </p>
          )}
        </div>
        <div className="container-btn-modal">
          {item?.agence_id || from === "add_user" ? (
            <NavLink
              to={
                isProprietaireOwner(user)
                  ? "/proprietaire/gerer-par-le-proprietaire/parametres"
                  : "/agence/option"
              }
              className="btn custom-btn-success-add"
            >
              OK
            </NavLink>
          ) : (
            <NavLink
              to="/proprietaire/gerer-par-le-proprietaire/administratif"
              className="btn custom-btn-success-add"
            >
              OK
            </NavLink>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmAddPersonnel;
