import React from "react";
import LocataireEnLitigeTable from "../../../../tables/agence/LocataireTable/LocataireEnLitigeTable";

function LocataireEnCoursLitige() {
    // const word = searchParams.get("name");
    // const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    //   if (e.currentTarget.value) {
    //     setSearchParams({
    //       name: e.currentTarget.value,
    //     });
    //   } else {
    //     setSearchParams({});
    //   }
    // };
  
    // const uptodate = searchParams.get("uptodate");
  
    // const handleFilterByUpToDate = (
    //   e: React.FormEvent<HTMLSelectElement>
    // ): void => {
    //   if (e.currentTarget.value) {
    //     setSearchParams({
    //       uptodate: e.currentTarget.value,
    //     });
    //   } else {
    //     setSearchParams({});
    //   }
    // };
    // const type = searchParams.get("type");
  
    // const handleFilterByType = (
    //   e: React.FormEvent<HTMLSelectElement>
    // ): void => {
    //   if (e.currentTarget.value) {
    //     setSearchParams({
    //       type: e.currentTarget.value,
    //     });
    //   } else {
    //     setSearchParams({});
    //   }
    // };
  
    return (
      <div className="bg-white p-3 filter-and-table-container">
        <div className="bloc-filter-locataitaires-agence">
         
        </div>
        <div>
          <LocataireEnLitigeTable/>
        </div>
      </div>
    );
}

export default LocataireEnCoursLitige
