import { NavLink } from "react-router-dom";
import { useGetSimilarNewsQuery } from "../../../../utils/api/new/news.api";
import { INews } from "../../../../utils/api/new/news.type";
import { getImage } from "../../../../utils/Utils";

export function ArticlesSimulaire() {
	const { data: similarNews } = useGetSimilarNewsQuery();
	return (
		<div className="container-col-right-news-item w-100">
			<div className="others-partnerships-content">
				<h4 className="other-partnerships-title">Articles similaires</h4>
				{similarNews &&
					similarNews?.length > 0 &&
					similarNews?.map((item: INews) => (
						<NavLink
							className="no-link"
							to={`/news/${item.slug}`}
							key={item?.slug}
						>
							<div className="other-partnership-item-container others-news-item-container mb-3">
								<div className="container-others-news-item-img">
									<img
										src={getImage(item?.couverture)}
										alt="others news"
										className="others-news-item-img"
									/>
								</div>
								<div className="others-news-items-content-text">
									<div className="other-partnership-item">
										{item?.titre}
									</div>
									{/* <div className="other-partnership-item-moment">
										Par{" "}
										<span style={{ fontWeight: 600 }}>
											{item.auteur?.prenom}
										</span>
									</div> */}
								</div>
							</div>
						</NavLink>
					))}
			</div>
		</div>
		// </div>
	);
}
