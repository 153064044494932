import React, { useState, useEffect } from "react";
import { INotification } from "../../../../utils/api/notification/notification.type";
import { ApiBaseUrl } from "../../../../utils/http";
import { BtnCloseModal } from "../../../common/Button";
import { useUpdateReadNotificationMutation } from '../../../../utils/api/notification/notification.api'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";




function ViewNotifModal({ item }: { item?: INotification }) {

	return (
		<div
			className="modal fade"
			id="ViewNotifModal"
			aria-labelledby="ViewNotifModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<p className="modal-title" id="ViewDocModalLabel">
							Notification
						</p>
						<BtnCloseModal label="" />
					</div>
					<div className="modal-body">
                      <p className="text-notif mb-1">
                        {item?.content}
                      </p>
                    </div>
					<div className="modal-footer">
						<p className="text-date-notif mb-0">{moment(item?.created_at).format("DD/MM/yy - HH:mm")}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ViewNotifModal;
