import React from "react";
import ReactQuill from "react-quill";
import {
  ILitiges,
  StatusLitiges,
  TypesLitiges,
} from "../../../utils/api/litiges/litiges.type";
import { isAgenceJuriste } from "../../../utils/Utils";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import { Input } from "../../common/Input";
import { SelectInput } from "../../common/SelectInput";
import useCrudLitige from "../../TableauDebord/Agence/Locataires/CRUDLocataire/useCrudLitige";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export const optionsTypeLitige = [
  {
    label: "Assignation d’Expulsion",
    value: TypesLitiges.assignationExpulsion,
  },
  {
    label: "Commandement de payer",
    value: TypesLitiges.commandementPayer,
  },
  {
    label: "Jugement",
    value: TypesLitiges.jugement,
  },
];

export const optionsStatutLitige = [
  {
    label: "En révision",
    value: StatusLitiges.revision,
  },
  {
    label: "Non régler",
    value: StatusLitiges.nonRegler,
  },
  {
    label: "En cours",
    value: StatusLitiges.enCours,
  },
  {
    label: "Régler",
    value: StatusLitiges.regler,
  },
];

function AjoutLitigesModale({ item }: { item?: ILitiges }) {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    locataires,
    handleChangeDescription,
    description,
    user,
    control,
    Controller,
    handleChangeLocataire,
    option,
    handleReset,
  } = useCrudLitige(item);
  const animatedComponents = makeAnimated();
  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {item ? "Modifier le" : "Déclarer un"} contentieux
          </h5>
        </div>
        <div className="modal-body">
          <div className="container-form pt-3">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label
                      htmlFor="nombre_stock"
                      className="form-label form-label-modal-custom"
                      aria-labelledby="propriete"
                    >
                      Locataire
                      <span
                        className="text-danger"
                        style={{
                          fontSize: 24,
                        }}
                      >
                        *
                      </span>
                    </label>
                    {/* <select
											id="locataire"
											className="form-select form-select-modal-custom mb-2"
											{...register("locataire")}
										>
											<option value="" selected disabled >
												Selectionner un locataire
											</option>
											{locataires?.map((item) => (
												<option
													value={item.id}
													key={item.slug}
												>
													{item?.prenom +
														" " +
														item?.nom}
												</option>
											))}
										</select> */}
                    <Controller
                      // name="locataire"
                      control={control}
                      {...register("locataire")}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={locataires?.map((item) => {
                              return {
                                label: item?.prenom + " " + item?.nom,
                                value: item?.id,
                              };
                            })}
                            placeholder="Selectionner un locataire"
                            inputClass="form-select form-select-modal-custom mb-2"
                            classNamePrefix="select-comodite"
                            onChange={handleChangeLocataire}
                            value={option}
                          />
                        );
                      }}
                    />

                    {<FormError error={errors.locataire} />}
                  </div>
                </div>
                <div className="col-12">
                  <Input
                    type="text"
                    label="Titre"
                    id="titre"
                    required
                    {...register("titre")}
                    error={errors.titre}
                  />
                </div>
                {isAgenceJuriste(user) && (
                  <div className="col-12">
                    <SelectInput
                      label="Type litige"
                      options={optionsTypeLitige}
                      {...register("type_litige")}
                      error={errors.type_litige}
                    />
                  </div>
                )}

                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="description"
                      className="form-label form-label-modal-custom"
                    >
                      Note
                    </label>
                    <ReactQuill
                      className="editeur-react-quill"
                      value={description}
                      onChange={handleChangeDescription}
                    />
                    <FormError error={errors?.description} />
                  </div>
                </div>

                {/* <div className="col-12">
									<SelectInput
										label="Statut"
										options={optionsStatutLitige}
										{...register("statut")}
										error={errors.statut}
									/>
								</div> */}
              </div>
              <div className="container-btn-modal row mt-5">
                <div className="col-md-3">
                  <button
                    className="btn btn-cancelled"
                    data-bs-dismiss="modal"
                    type="button"
                    onClick={() => handleReset()}
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-md-6 offset-md-3 flex-r">
                  <BtnSubmit
                    label={item ? "Modifier" : "Déclarer"}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutLitigesModale;
