import React from "react";
import { IPropriete } from "../../../../utils/api/propriete/propriete.type";
import {
  formatMontant,
  formatPlurial,
  getImage,
} from "../../../../utils/Utils";

function ListeBiens({ proprietes }: { proprietes?: IPropriete[] }) {
  return (
    <div className="tab-content mt-2" id="myTabContent">
      <div
        className="tab-pane fade show active"
        id="villa"
        role="tabpanel"
        aria-labelledby="villa-tab"
      >
        <div className="row">
          {proprietes?.map((item, index) => (
            <div className="col-md-4 mb-3 d-flex" key={index}>
              <div className="detail-proprietes-card-item card">
                <div className="position-relative">
                  <img
                    src={getImage(item?.pictures[0]?.image)}
                    className="card-img-top card-img-top-propriete"
                    alt={item?.nom}
                  />
                </div>
                <div className="content-prix-propriete-detail">
                  <p className="text-prix-propriete-detail mb-0">
                    {formatMontant(item?.prix)}
                  </p>
                </div>
                <div className="card-body-detail-propriete">
                  <div className="p-3 content-info-propriete-card">
                    <p className="text-nom-propriete mb-1">
                      {item?.type_propriete} - {item?.nom}
                    </p>
                    <p className="card-text text-adresse-propriete mb-0">
                      {item?.adresse}
                    </p>
                  </div>
                  {item?.type_propriete !== "immeuble" ? (
                    <div className="flex-sb mt-3 b-t-1 align-items-center">
                      <div className="b-rh-1 p-3-custom">
                        <p className="text-description-propriete mb-0">
                          {formatPlurial(item.nbre_chambres, "chambre")}
                        </p>
                      </div>
                      <div className="b-rh-1 p-3-custom text-start">
                        <p className="text-description-propriete mb-0">
                          {formatPlurial(item?.nbre_cuisines, "cuisine")}
                        </p>
                      </div>
                      <div className="p-3-custom text-start">
                        <p className="text-description-propriete mb-0">
                          {item?.nbre_salle_de_bains}
                          {item?.nbre_salle_de_bains <= 1
                            ? " salle de bain"
                            : " salles de bains"}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="flex-sb mt-3 b-t-1 align-items-center">
                      <div className="p-3-custom text-start">
                        <p className="text-description-propriete mb-0">
                          {formatPlurial(item?.nbre_appart, "appartement")}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ListeBiens;
