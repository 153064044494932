import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AlertInfo } from "../../../common/Alert";
import RappelPaiementModal from "../../../modal/agence/RappelPaiementModal";
import LocatairesNonPayeTableData from "./LocatairesNonPayeTableData";
import LocatairesNonPayeTableSkeleton from "./LocatairesNonPayeTableSkeleton";
import "./Table.css";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { useRappelPaiementAgenceMutation } from "../../../../utils/api/rappelPaiement/rappelPaiement.api";
import { formattedDate } from "../../../../utils/Utils";

function LocatairesNonPayeTable({
  locataires_non_payant,
  isLoading,
}: {
  locataires_non_payant?: any;
  isLoading: boolean;
}) {
  const [page, setPage] = useState(LocatairesNonPayeTableData);

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex">
          <div>
            <RappelPaiementBtn slug={row?.slug_locataire} />
          </div>
          <div
            className="modal fade"
            id="RappelPaiementModal"
            aria-labelledby="RappelPaiementModalLabel"
            aria-hidden="true"
          >
            <RappelPaiementModal
              locataireData={row}
              modalId="RappelPaiementModal"
            />
          </div>
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "non_locataire",
      text: "Nom",
      formatter: (cell: any, row: any) =>
        `${row.prenom_locataire} ${row.non_locataire}`,
    },
    {
      dataField: "nom_propriete",
      text: "Propriétés",
    },
    {
      dataField: "prix_ttc",
      text: "Montant à payer",
      formatter: (cell: any, row: any) =>
        `${new Intl.NumberFormat("fr-FR").format(cell)} frs`,
    },
    {
      dataField: "date_prochaine_paiement",
      text: "Prochain paiement",
      formatter: (cell: any, row: any) =>
        `${formattedDate(row?.date_prochaine_paiement) || "Non défini"}`,
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      // headerStyle: () => {
      // 	return { width: "130px" };
      // },
    },
  ];

  return (
    <>
      {isLoading && <LocatairesNonPayeTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={locataires_non_payant}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
        </>
      )}
    </>
  );
}

export default LocatairesNonPayeTable;

function RappelPaiementBtn({ slug }: { slug: any }) {
  const [rappelPaiementAgence] = useRappelPaiementAgenceMutation();

  const onSendRappel = async () => {
    const result = await Swal.fire({
      title: `Êtes-vous sûr de vouloir envoyer un rappel de paiement?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.jaune,
      confirmButtonColor: Color.jaune,
      preConfirm: () => {
        return rappelPaiementAgence(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    if (result?.value) {
      Swal.fire({
        icon: "success",
        title: "Rappel envoyé avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "une erreur s'est produite",
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <button
      className="btn btn-send-rappel"
      title="Envoyer un rappel"
      onClick={onSendRappel}
    >
      Envoyer un rappel
    </button>
  );
}
