import React from 'react'
import CategorieTableComptable from '../../../../tables/Comptables/Categorie/CategorieTableComptable';

function CategorieDepense() {
    return (
		<div className="bg-white p-3">
			{/* <div className="locataire-requete-filtre-container flex-sb my-4">
				<form className="row g-3 locataire-requete-filtre-form">
					<div className="col-auto">
						<label
							htmlFor="filtreLogement"
							className="locataire-requete-filtre-label"
						>
							Filtrer
						</label>
					</div>
					<div className="col-auto">
						<input
							type="text"
							className="form-control locataire-requete-filtre-input"
							id="filtreLogement"
							placeholder="Entrer un nom"
							onChange={handleFilterByName}
						/>
					</div>
				</form>
				<button
					className="btn btn-see-more"
					data-bs-toggle="modal"
					data-bs-target="#AjouterFactureModal"
				>
					<AiFillPlusSquare style={{ fontSize: 22 }} />
					<span className="ps-2">Envoyer une facture</span>
				</button>
			</div> */}
			<div>
				<CategorieTableComptable />
			</div>
			{/* <AjouterFactureModal /> */}
		</div>
	);
}

export default CategorieDepense