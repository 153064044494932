import React from "react";
import "./RequetesLocataire.css";
import { BsFillPlusSquareFill } from "react-icons/bs";
import RequetesTable from "../../../../tables/Locataire/Requetes/RequetesTable";
import AjoutRequetesModal from "../../../../modal/locataire/AjoutRequetesModal";
import { useAppSelector } from "../../../../../redux/hooks";
import { useSearchParams } from "react-router-dom";

function RequetesLocataire() {
  const { user } = useAppSelector((s) => s?.user);
// console.log("user user", user);

  const [searchParams, setSearchParams] = useSearchParams();
	const word = searchParams.get("titre");
	const handleFilterByTitre = (e: React.FormEvent<HTMLInputElement>): void => {
		if (e.currentTarget.value) {
			setSearchParams({
				titre: e.currentTarget.value,
			});
		} else {
			setSearchParams({});
		}
	};
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Requêtes
          </h4>
          <button
            className="btn kanimmo-dash-locataire-requetes-header-button"
            title="Faire une requête"
            data-bs-toggle="modal"
            data-bs-target="#AjoutRequetesModal"
          >
            <BsFillPlusSquareFill /> Faire une requête
          </button>
          <div
            className="modal fade"
            id="AjoutRequetesModal"
            aria-labelledby="AjoutRequetesModalLabel"
            aria-hidden="true"
          >
            <AjoutRequetesModal modalId="AjoutRequetesModal" />
          </div>
        </div>
        <div className="bg-white p-3 filter-and-table-container">
          <div className="locataire-requete-filtre-container my-4">
            <form className="row g-3 locataire-requete-filtre-form">
              <div className="col-auto">
                <label
                  htmlFor="filtreLogement"
                  className="locataire-requete-filtre-label"
                >
                  Filtrer
                </label>
              </div>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control locataire-requete-filtre-input"
                  id="filtreLogement"
                  placeholder="Entrer un nom"
                  onChange={handleFilterByTitre}
                />
              </div>
            </form>
          </div>
          <div>
            <RequetesTable titre={word} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequetesLocataire;
