import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import {
  useGenerateQuittanceMutation,
} from "../../../../../utils/api/facture/facture.api";
import {
    cleannerError,
    onHide,
  } from "../../../../../utils/Utils";
import {
  Gquittence,
} from "../../../../../utils/api/facture/facture.type";
import { Color } from "../../../../../utils/theme";

export function generateQuiittence(){
    const validationSchema = yup.object().shape({
        email: yup.string().required().label("Email locataire"),
      });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm<Gquittence>({
    resolver: yupResolver(validationSchema),
  });



  const [ generateQuittance, { isLoading, isSuccess, error, isError }] = useGenerateQuittanceMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Quittance generer avec sucess`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        handleReset();
        onHide("generateQuittenceModal");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: any) => {
    console.log("email locataire", data.email);
    await generateQuittance({email: data.email});
  };


  const handleReset = () => {
    reset();
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    handleReset
  };
}

