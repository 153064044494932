// import React from "react";
import "./DashboardComptable.css";
import Key from "../../../../assets/icons/key.png";
import Users from "../../../../assets/icons/users.png";
import Paper from "../../../../assets/icons/paper.png";
import BarChart from "./Chart/BarChart";
import LineChart from "./Chart/LineChart";
import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { useGetComptableDataQuery } from "../../../../utils/api/comptable/comptable.api";
import { formatMontant, isComptable } from "../../../../utils/Utils";
import React, { useState } from 'react';

function DashboardComptable() {
  const { user } = useAppSelector((s) => s?.user);
  const navigate = useNavigate();
  const [dataType, setDataType] = useState<string | null>(null);
  const is_Comptable = useAppSelector((s) => isComptable(s.user?.user));
 
  const handleTypeChange = (newType: string) => {
    setDataType(newType);
  };
  const { data, isLoading } = useGetComptableDataQuery({ slug: user?.slug, type: dataType });
  
  React.useEffect(() => {
    // Vérifiez si les données sont disponibles et que l'utilisateur et les autres conditions sont remplies
    if (data && !user?.dashboard && !is_Comptable && !isLoading) {
      setTimeout(() => {
        navigate(-1);
      }, 50);
    }
  }, [data, user, is_Comptable, isLoading]);
// console.log("donné du comptable", data);
  return (
    <div className="dashboard-admin-component">
      <div className="row container-dashboard-page">
        <div className="col-md-12 pb-3">
          <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
            <div className="row px-md-2">
              <div className="col-xl-3 col-lg-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Key}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Recouvrements
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          Cette semaine
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {formatMontant(data?.total_entrees)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Users}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Total des dépenses
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          Cette semaine
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {formatMontant(data?.total_depenses)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Paper}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Total des revenus
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          Cette semaine
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {formatMontant(data?.profit)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-3 mt-4">
              <BarChart data={data} onTypeChange={handleTypeChange}/>
            </div>
          </div>
          <div className="col-left-dashboard mb-3">
            {/* <div className="container-title-card-dashbord-view mb-4">
                            <h3 className="title-card-dashbord-view">Locataires qui n’ont pas payé</h3>
                        </div> */}
            {/* <div className="container-table-locataires-non-paye">
              <LineChart data={data?.graph} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardComptable;
