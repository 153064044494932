import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  useGetBienByAgenceQuery,
  useGetImmeublesByAgenceQuery,
} from "../../../../../utils/api/propriete/propriete.api";

function useProprieteAgence() {
  const [searchParams, setSearchParams] = useSearchParams();
  const type_propriete = searchParams.get("type_propriete");
  const type_gerance = searchParams.get("type_gerance");
  const name = searchParams.get("name");
  const query = searchParams.get("query");

  const { user } = useAppSelector((s) => s?.user);

  const [page, setPage] = useState(1);
  const [pageImmo, setPageImmo] = useState(1);
  const [perPage, setPerPage] = useState(9);
  const { data, isLoading } = useGetBienByAgenceQuery({
    slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
    type_propriete: type_propriete,
    page: page,
    limit: perPage,
    name,
  });

  const { data: immeubles = { results: [] } } = useGetImmeublesByAgenceQuery({
    slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
    page: pageImmo,
    limit: perPage,
    name: query,
  });

  const handleFilterByNameImmo = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    if (e.currentTarget.value && !type_gerance) {
      setSearchParams({
        query: e.currentTarget.value,
      });
    } else if (type_gerance && e.currentTarget.value) {
      setSearchParams({
        type_gerance,
        query: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value && !type_gerance && !type_propriete) {
      setSearchParams({
        name: e.currentTarget.value,
      });
    } else if ((type_gerance || type_propriete) && e.currentTarget.value) {
      if (type_propriete && type_gerance) {
        setSearchParams({
          type_gerance,
          name: e.currentTarget.value,
          type_propriete,
        });
      } else if (!type_propriete && type_gerance) {
        setSearchParams({
          type_gerance,
          name: e.currentTarget.value,
        });
      } else if (type_propriete && !type_gerance) {
        setSearchParams({
          type_propriete,
          name: e.currentTarget.value,
        });
      }
    } else {
      setSearchParams({});
    }
  };
  const handleFilterByTypePropriete = (
    e: React.FormEvent<HTMLSelectElement>
  ): void => {
    if (e.currentTarget.value && !type_gerance && !name) {
      setSearchParams({
        type_propriete: e.currentTarget.value,
      });
    } else if ((type_gerance || name) && e.currentTarget.value) {
      if (name && type_gerance) {
        setSearchParams({
          type_gerance,
          type_propriete: e.currentTarget.value,
          name,
        });
      } else if (!name && type_gerance) {
        setSearchParams({
          type_gerance,
          type_propriete: e.currentTarget.value,
        });
      } else if (name && !type_gerance) {
        setSearchParams({
          name,
          type_propriete: e.currentTarget.value,
        });
      }
    } else {
      setSearchParams({});
    }
    // if (e.currentTarget.value && type_gerance) {
    //   setSearchParams({
    //     type_gerance,
    //     type_propriete: e.currentTarget.value,
    //   });
    // } else if (e.currentTarget.value && !type_gerance) {
    //   setSearchParams({
    //     type_propriete: e.currentTarget.value,
    //   });
    // } else if (!e.currentTarget.value && type_gerance) {
    //   setSearchParams({
    //     type_gerance,
    //   });
    // } else {
    //   setSearchParams({});
    // }
  };
  const handleFilterByTypeGerance = (
    e: React.FormEvent<HTMLSelectElement>
  ): void => {
    if (e.currentTarget.value && !type_propriete && !name && !query) {
      setSearchParams({
        type_gerance: e.currentTarget.value,
      });
    } else if ((type_propriete || name || query) && e.currentTarget.value) {
      if (name && type_propriete && query) {
        setSearchParams({
          type_propriete,
          type_gerance: e.currentTarget.value,
          name,
          query,
        });
      } else if (!name && type_propriete && query) {
        setSearchParams({
          type_propriete,
          type_gerance: e.currentTarget.value,
          query,
        });
      } else if (name && !type_propriete && query) {
        setSearchParams({
          name,
          type_gerance: e.currentTarget.value,
          query,
        });
      } else if (!name && !type_propriete && query) {
        setSearchParams({
          type_gerance: e.currentTarget.value,
          query,
        });
      } else if (name && !type_propriete && !query) {
        setSearchParams({
          name,
          type_gerance: e.currentTarget.value,
        });
      } else if (!name && type_propriete && !query) {
        setSearchParams({
          type_propriete,
          type_gerance: e.currentTarget.value,
        });
      }
    } else {
      setSearchParams({});
    }
    // if (e.currentTarget.value && type_propriete) {
    //   setSearchParams({
    //     type_propriete,
    //     type_gerance: e.currentTarget.value,
    //   });
    // } else if (e.currentTarget.value && !type_propriete) {
    //   setSearchParams({
    //     type_gerance: e.currentTarget.value,
    //   });
    // } else if (!e.currentTarget.value && type_propriete) {
    //   setSearchParams({
    //     type_propriete,
    //   });
    // } else {
    //   setSearchParams({});
    // }
  };
  
  return {
    proprietes: data?.results,
    isLoading,
    data,
    handleFilterByTypePropriete,
    handleFilterByTypeGerance,
    perPage,
    setPage,
    page,
    setPageImmo,
    pageImmo,
    proprieteCount: data?.count,
    immoCount: immeubles?.count,
    immeubles: immeubles?.results,
    handleFilterByName,
    handleFilterByNameImmo,
    query,
    name,
    type_propriete,
    type_gerance,
  };
}

export default useProprieteAgence;
