import React from "react";
import LocataireArchiveTable from "../../../../tables/agence/LocataireTable/LocataireArchiveTable";

function LocataireArchives({
  searchParams,
  setSearchParams,
  AgenceSlug,
  is_Agence,
}: {
  searchParams: any;
  setSearchParams: any;
  AgenceSlug: string;
  is_Agence: boolean;
}) {
  const word = searchParams.get("name");
  const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        name: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };

  const uptodate = searchParams.get("uptodate");

  const type = searchParams.get("type");

  return (
    <div className="bg-white p-3 filter-and-table-container">
      <div className="bloc-filter-locataitaires-agence">
        <div className="locataire-requete-filtre-container">
          <div className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                placeholder="Entrer un nom"
                onChange={handleFilterByName}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <LocataireArchiveTable
          name={word}
          uptodate={uptodate}
          type={type}
          AgenceSlug={AgenceSlug}
          isAgence={is_Agence}
        />
      </div>
    </div>
  );
}

export default LocataireArchives;
