import React from "react";
import { BtnCloseModal, BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/CustomInputCheckbox";
import UseDeleteAccountForm from "./requestForm/UseDeleteAccountForm";

function ConfirmDeleteAccountModal() {
  const { register, errors, onSubmit, isLoading, handleReset } =
    UseDeleteAccountForm();
  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content text-start">
        <div className="modal-header">
          <div className="py-1">
            <p className="certification-compte-texte fw-500">
              Pourquoi voulez-vous supprimer votre compte ?
            </p>
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-4">
                  <label
                    htmlFor={"motif"}
                    className="custom-form-label mb-2"
                    aria-labelledby={"motif"}
                  >
                    Motif
                  </label>
                  <textarea
                    className="form-control espace-client-form-control"
                    id="motif"
                    {...register("motif")}
                    cols={30}
                    rows={6}
                  />
                  <FormError error={errors?.motif?.message} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-r gap-3 p-3">
            <BtnCloseModal label="Annuler" onClick={() => handleReset()} />
            <BtnSubmit label="Confirmer" isLoading={isLoading} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ConfirmDeleteAccountModal;
