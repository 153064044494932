import moment from "moment";
import React from "react";
import { IFacture } from "../../../utils/api/facture/facture.type";
import { ApiBaseUrl } from "../../../utils/http";
import { get_url_extension } from "../../../utils/Utils";
import { GoogleDocs } from "./DocumentLitigeModal";

function DetailsfactureModal({
  item
}: {
  item: IFacture;
}) {
  // console.log("item", item)
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container-form custom-container-form">
            <div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex flex-column align-items-start mb-3">
                    <div className="custom-envoie-note-date">
                      Facture du{" "}
                      <span className="agence-detail-facture-titre">
                        {moment(item?.created_at).format("LL")}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn-close custom-btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <hr />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    {/* <div className="detail-facture-infos-container mb-3">
                      <div className='detail-facture-infos-name'>Locataire:</div>
                      <div className='detail-facture-infos-value'>{item?.locataire?.prenom + " " + item?.locataire?.nom}</div>
                    </div>
                    <div className="detail-facture-infos-container mb-3">
                      <div className='detail-facture-infos-name'>Propriété:</div>
                      <div className='detail-facture-infos-value'>{item?.propriete?.nom}</div>
                    </div>
                    <div className="detail-facture-infos-container mb-3">
                      <div className='detail-facture-infos-name'>Numéro de la propriété:</div>
                      <div className='detail-facture-info-value'>{item?.propriete?.numero}</div>
                    </div> */}
                    <div>
                      {get_url_extension(item?.pdf) === "pdf" ? (
                        <iframe
                          src={ApiBaseUrl + item?.pdf}
                          frameBorder="0"
                          width={`100%`}
                          height={500}
                          allowFullScreen
                          seamless
                          title="doc"
                        ></iframe>
                      ) : (
                        <GoogleDocs
                          file={ApiBaseUrl + item?.pdf}
                          height={"700px"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsfactureModal;
