import React, { useEffect } from "react";
import {
  IRequete,
  RequeteFormData,
} from "../../../utils/api/requete/requete.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  useAddOrUpdateRequeteMutation,
  useEditRequeteMutation,
} from "../../../utils/api/requete/requete.api";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, isAgenceJuriste, isProprioJuriste, onHide } from "../../../utils/Utils";
import { Color } from "../../../utils/theme";
import Swal from "sweetalert2";
import ErrorMessage from "../../common/ErrorMessage";
import {
  ILitiges,
  LitigesFormData,
  TypesLitiges,
} from "../../../utils/api/litiges/litiges.type";
import { useEditLitigeMutation } from "../../../utils/api/litiges/litiges.api";
import { IoClose } from "react-icons/io5";
import { useAppSelector } from "../../../redux/hooks";

function ChangerStatutLitigeModal({
  modalId,
  item,
  reload
}: {
  item?: ILitiges;
  modalId: any;
  reload?: Boolean
}) {
  const {user} = useAppSelector((s) => s.user)
  
  const Status = [
    {
      label: "Assignation d’Expulsion",
      value: TypesLitiges.assignationExpulsion,
    },
    {
      label: "Commandement de payer",
      value: TypesLitiges.commandementPayer,
    },
    {
      label: "Jugement",
      value: TypesLitiges.jugement,
    },
    {
      value: "non_regler",
      label: "Non réglée",
    },
    {
      value: "encours",
      label: "En cours de traitement",
    },
    {
      value: "regler",
      label: "Réglée",
    },
    {
      value: "revision",
      label: `${
        isAgenceJuriste(user) || isProprioJuriste(user)
          ? "Pour révision"
          : "Envoyé pour révision par le juriste"
      }`,
    },
  ];

  const validationSchema = yup.object().shape({
    statut: yup.string().required().label("Status"),
  });

  const [changeStatus, { isSuccess, isError, error, isLoading }] =
    useEditLitigeMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm<LitigesFormData>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (item?.id) {
      setValue("slug", item?.slug);
      setValue("statut", item?.statut);
    }
  }, [item]);

  useEffect(() => {
    if (errors) {
      return cleannerError(errors, clearErrors);
    }
  }, [errors]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Changement de statut réussi !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        reset();
        onHide(modalId);
        if (reload) {
          window.location.reload()
        }
        // onHide("ChangeStatusRequeteModal");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: `${err ? err?.message : "Une erreur s'est produite"}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  const onSubmit = (data: any) => {
  // console.log(data);
    changeStatus({ slug: data?.slug, data: data });
  };

  return (
    <div className="modal-dialog" id="ChangeStatusLitigeModal">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title"></h5>
          <button
            className="close-modal auth-submit-annuler"
            data-bs-dismiss="modal"
            type="button"
          >
            <IoClose />
          </button>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="titre"
                      className="form-label form-label-modal-custom"
                    >
                      Statut
                    </label>
                    <select
                      className="form-control form-control-input-admin py-3"
                      id="statut"
                      {...register("statut")}
                    >
                      <option value="" selected disabled>
                        Statut
                      </option>
                      {Status?.map((option: any) => (
                        <option value={option?.value} key={option.value}>
                          {option?.label}
                        </option>
                      ))}
                    </select>
                    {errors?.titre && (
                      <ErrorMessage message={errors?.titre?.message} />
                    )}
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row">
                <div className="col w-100 d-flex justify-content-between align-items-center">
                  <div className="btn-cancelled-container">
                    <button
                      className="btn btn-cancelled"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="btn-submit-container">
                    <BtnSubmit label="Changer" isLoading={isLoading} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangerStatutLitigeModal;
