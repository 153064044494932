import moment from "moment";
import React from "react";
import { AiFillPlusSquare, AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { useLitigeFromLocation } from "../../../../utils/api/litiges/litiges.api";
import { TypesLitiges } from "../../../../utils/api/litiges/litiges.type";
import {
  createMarkup,
  getName,
  isAgenceJuriste,
  isLocataire,
  isProprioJuriste,
} from "../../../../utils/Utils";
import AjoutLitigesDocumentModal from "../../../modal/agence/AjoutLitigesDocumentModal";
import ChangerStatutLitigeModal from "../../../modal/agence/ChangerStatutLitigeModal";
import DocumentLitigeModal from "../../../modal/agence/DocumentLitigeModal";

function DetailsLitiges() {
  const [item] = useLitigeFromLocation();
  //   const item = useLocationState<ILitiges>(undefined);
  const { user } = useAppSelector((s) => s.user);
  const navigate = useNavigate();
  console.log("item litige", item);
  const Status = [
    {
      label: "Assignation d’Expulsion",
      value: TypesLitiges.assignationExpulsion,
    },
    {
      label: "Commandement de payer",
      value: TypesLitiges.commandementPayer,
    },
    {
      label: "Jugement",
      value: TypesLitiges.jugement,
    },
    {
      value: "non_regler",
      label: "Non réglée",
    },
    {
      value: "encours",
      label: "En cours de traitement",
    },
    {
      value: "regler",
      label: "Réglée",
    },
    {
      value: "revision",
      label: `${
        isAgenceJuriste(user) || isProprioJuriste(user)
          ? "Pour révision"
          : "Envoyé pour révision par le juriste"
      }`,
    },
  ];

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4
            className="kanimmo-dash-locataire-requetes-header-titre no-link linkable"
            onClick={() => navigate(-1)}
          >
            <AiOutlineArrowLeft />
            <span className="ps-2">Détails contentieux</span>
          </h4>
        </div>
        <div className="kanimmo-proprietes-container">
          <div className="proprietes-component-container">
            <div className="content-proprietes mt-5 custom-content-details-litige">
              <div className="container-btn-add custom-container-btn-add">
                {(isAgenceJuriste(user) || isProprioJuriste(user)) && (
                  <button
                    className="btn btn-see-more custom-btn-see-more"
                    title="Ajouter"
                    data-bs-toggle={`${
                      (isAgenceJuriste(user) || isProprioJuriste(user)) &&
                      "modal"
                    }`}
                    data-bs-target={`${
                      (isAgenceJuriste(user) || isProprioJuriste(user)) &&
                      "#ModifierLitigesModal"
                    }`}
                    aria-expanded="false"
                  >
                    Ajouter une note{" "}
                    <AiFillPlusSquare style={{ fontSize: 22 }} />
                  </button>
                )}
                <div
                  className="modal fade"
                  id="ModifierLitigesModal"
                  aria-labelledby="ModifierLitigesModalLabel"
                  aria-hidden="true"
                >
                  <AjoutLitigesDocumentModal
                    modalId={`ModifierLitigesModal`}
                    item={item}
                  />
                </div>

                <div
                  className="modal fade"
                  id={`ChangeStatusLitigeModal`}
                  aria-labelledby="ChangeStatusLitigeModalLabel"
                  aria-hidden="true"
                >
                  <ChangerStatutLitigeModal
                    modalId={`ChangeStatusLitigeModal`}
                    item={item}
                    reload
                  />
                </div>
              </div>
              <div className="details-litige-titre-container mb-3">
                <span>Statut: </span>
                <button
                  className={`btn message-btn ${
                    item?.statut === "non_regler"
                      ? "bg-secondary"
                      : item?.statut === "regler"
                      ? "bg-success"
                      : "bg-secondary"
                  }`}
                  data-bs-toggle={`${
                    (isAgenceJuriste(user) || isProprioJuriste(user)) && "modal"
                  }`}
                  data-bs-target={`${
                    (isAgenceJuriste(user) || isProprioJuriste(user)) &&
                    "#ChangeStatusLitigeModal"
                  }`}
                  aria-expanded="false"
                >
                  {/* {item?.statut === "non_regler"
                    ? "Non réglée"
                    : item?.statut === "regler"
                    ? "Réglée"
                    : item?.statut === "revision"
                    ? `${
                        isAgenceJuriste(user)
                          ? "Pour révision"
                          : "Envoyé pour révision par le juriste"
                      }`
                    : "En cours de traitement"} */}
                  {Status?.find((val) => val?.value === item?.statut)?.label}
                </button>
              </div>
              <div className="details-litige-titre-container mb-3">
                <span>Titre: </span>
                {item?.titre}
              </div>
              <div className="details-litige-locataire-container d-flex">
                <span className="mr-1">Note: </span>
                <p
                  dangerouslySetInnerHTML={createMarkup(
                    item?.description || ""
                  )}
                />
              </div>
              {!isLocataire(user) ? (
                <div className="details-litige-locataire-container">
                  <span>Locataire: </span>
                  {getName(item?.locataire)}
                </div>
              ) : (
                <div className="details-litige-locataire-container">
                  <span>
                    {item?.agence?.id
                      ? "Agence :"
                      : item?.proprietaire?.id
                      ? "propriètaire :"
                      : ""}{" "}
                  </span>
                  {item?.agence?.id
                    ? getName(item?.agence)
                    : item?.proprietaire?.id
                    ? getName(item?.proprietaire)
                    : ""}
                </div>
              )}

              <div className="details-litige-historique-container mt-4">
                <div className="details-litige-historique">
                  <span>Historique: </span>
                </div>
                <div className="accordion w-100" id="accordionExample">
                  {item?.document && (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button w-100 custom-accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Document
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          {item?.document && (
                            <button
                              className="document-formatter-container btn"
                              role="button"
                              data-bs-toggle="modal"
                              data-bs-target={`#DocumentlitigeModal${item?.slug}`}
                            >
                              Voir le document
                            </button>
                          )}

                          <div
                            className="modal fade"
                            id={`DocumentlitigeModal${item?.slug}`}
                            aria-labelledby="DocumentlitigeModalLabel"
                            aria-hidden="true"
                          >
                            <DocumentLitigeModal item={item} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!!item?.historiques?.length &&
                    item?.historiques
                      .slice()
                      ?.sort((a, b) => b?.id - a?.id)
                      ?.map((el: any) => (
                        <div className="accordion-item" key={el?.id}>
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button custom-accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseTwo${el?.slug}`}
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Note du{" "}
                              {moment(el?.created_at).format(
                                "DD/MM/YYYY à HH:mm:ss"
                              )}
                            </button>
                          </h2>
                          <div
                            id={`collapseTwo${el?.slug}`}
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="details-litige-titre-container mb-3">
                                <span>Statut: </span>
                                <button
                                  className={`btn message-btn ${
                                    el?.statut === "non_regler"
                                      ? "bg-secondary"
                                      : el?.statut === "regler"
                                      ? "bg-success"
                                      : "bg-secondary"
                                  }`}
                                >
                                  {
                                    Status?.find(
                                      (val) => val?.value === el?.statut
                                    )?.label
                                  }
                                </button>
                              </div>

                              <div className="details-litige-locataire-container d-flex">
                                <span className="mr-1">Constat: </span>
                                <p
                                  dangerouslySetInnerHTML={createMarkup(
                                    el?.constat
                                  )}
                                />
                              </div>

                              {el?.document && (
                                <button
                                  className="document-formatter-container btn"
                                  role="button"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#DocumentlitigeModal${el?.slug}`}
                                >
                                  Voir le document
                                </button>
                              )}

                              <div
                                className="modal fade"
                                id={`DocumentlitigeModal${el?.slug}`}
                                aria-labelledby="DocumentlitigeModalLabel"
                                aria-hidden="true"
                              >
                                <DocumentLitigeModal item={el} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsLitiges;
