import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../../utils/theme";

import { useLocation, useNavigate } from "react-router-dom";
import {
  Chambre,
  Cuisine,
  IPropriete,
  ProprieteFormData,
  SalleDeBain,
  Salon,
  TypePropriete,
} from "../../../../../../utils/api/propriete/propriete.type";
import {
  useAddOrUpdateProprieteMutation,
  useDeleteProprieteMutation,
} from "../../../../../../utils/api/propriete/propriete.api";
import {
  cleannerError,
  isProprietaireAgence,
  useLocationState,
} from "../../../../../../utils/Utils";
import { useAppSelector } from "../../../../../../redux/hooks";
import { countries } from "../../../../../../utils/Countries";
import { useGetCommoditesQuery } from "../../../../../../utils/api/commodites/commodites.api";
import { useGetAgenceByProprietaireQuery } from "../../../../../../utils/api/proprietaire/proprietaire.api";
import { Data } from "../../../../../common/GoogleInput";

type SelectOptionType = { label: any; value: number };

function useCrudPropriete(navigation: any, proprieteItem?: IPropriete) {
  const [nbChambre, setNbChambre] = useState<number>(1);
  const [nbCuisine, setNbCuisine] = useState<number>(1);
  const [nbSalon, setNbSalon] = useState<number>(1);
  const [nbSDB, setNbSDB] = useState<number>(1);
  const [nbClim, setNbClim] = useState<number>(1);
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    hasChambre: yup.boolean().default(false),
    step: yup.number().default(1),
    hasEdit: yup.boolean(),
    isOwner: yup.boolean(),
    adresse: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("L'adresse"),
    }),
    numero: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Le numero"),
    }),
    nom: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Le nom"),
    }),
    rue: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("La rue"),
    }),
    pays: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Le pays"),
    }),
    ville: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("La ville"),
    }),
    code_postale: yup
      .string()
      .when("step", {
        is: 1,
        then: yup.string().label("Le code postal").nullable(),
      })
      .nullable(),
    type_propriete: yup.string().when("step", {
      is: 2,
      then: yup.string().required().label("Type de propriété"),
    }),
    surface: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .label("La surface de la propriété")
          .required()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_chambres: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le nombre de chambres")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_cuisines: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le nombre de cuisines")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_salle_de_bains: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le nombre de salles de bain")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_salon: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le nombre de salons")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_climatiseurs: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .label("Le nombre de climatisseurs")
          .nullable()
          .transform((value) => (isNaN(value) ? 0 : value))
          .default(0),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? 0 : value))
      .default(0),
    surface_climatiseur: yup.number().when("step", {
      is: 2,
      then: yup.number().label("La surface de la climatiseur"),
    }),

    description: yup.string().when("step", {
      is: 2,
      then: yup.string().required().label("La description"),
    }),
    pictures: yup.mixed().when("hasEdit", {
      is: false,
      then: yup.mixed().when("step", {
        is: 2,
        then: yup
          .mixed()
          .required("Veuillez uploader au moins une image.")
          .nullable(),
      }),
    }),
    agence: yup.string().when("isOwner", {
      is: false,
      then: yup
        .string()
        .when("step", {
          is: 2,
          then: yup.string().required().label("L'agence").nullable(),
        })
        .nullable(),
    }),
    commission_agence: yup.string().when("isOwner", {
      is: false,
      then: yup
        .string()
        .when("step", {
          is: 2,
          then: yup.string().required().label("Le taux de rémunération"),
        })
        .nullable(),
    }),
    url_youtube: yup
      .string()
      .url()
      .when("step", {
        is: 2,
        then: yup.string().url().label("Lien vers la video").nullable(),
      })
      .nullable(),
    gerance: yup
      .string()
      .when("step", {
        is: 3,
        then: yup.string().label("Type de gerance").nullable(),
      })
      .nullable(),
    mandat: yup.mixed().label("Le mandat").nullable(),
    prix_base: yup
      .number()
      .when("step", {
        is: 3,
        then: yup
          .number()
          .required()
          .label("Le Loyer Hors Charges")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    charges: yup
      .array()
      .of(
        yup.object().shape(
          {
            libelle: yup.string().when(["type", "montant"], {
              is: (a: string, b: number) => {
                console.log(a, b, "test");
                return a || b;
              },
              then: yup.string().required().nullable().label("Le libellé"),
            }),
            type: yup.string().when(["libelle", "montant"], {
              is: (a: string, b: number) => a || b,
              then: yup.string().required().nullable().label("Le type"),
            }),
            montant: yup
              .number()
              .when(["libelle", "type"], {
                is: (a: string, b: string) => a || b,
                then: yup
                  .number()
                  .nullable()
                  .required()
                  .label("La valeur")
                  .transform((value) => (isNaN(value) ? null : value))
                  .nullable(),
              })
              .transform((value) => (isNaN(value) ? null : value))
              .nullable(),
          },
          [
            ["libelle", "type"],
            ["type", "montant"],
            ["libelle", "montant"],
          ]
        )
      )
      .nullable(),
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    getValues,
    control,
  } = useForm<ProprieteFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [formData, setFormData] = useState<ProprieteFormData>({
    step: 1,
    slug: "",
    numero: "",
    adresse: "",
    rue: "",
    pays: "",
    ville: "",
    code_postale: "",
    type_propriete: TypePropriete.immeuble,
    nb_chambre: 0,
    nb_cuisine: 0,
    nb_salle_de_bain: 0,
    prix: 0,
    proprietaire: undefined,
    description: undefined,
    pictures: undefined,
  });
  const [option, setOption] = useState<any>(null);
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState<any>("");

  const [state, setState] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [description, setDescription] = useState<string>("");
  const [pictures, setPictures] = useState<File[]>();
  const [mandatPicture, setMandatPicture] = useState<File>();
  const [commodites, setCommodites] = useState<
    { label: string; value: number }[] | undefined
  >([]);
  const { user } = useAppSelector((s) => s?.user);
  const { data: agences = { results: [] }, isLoading: loading } =
    useGetAgenceByProprietaireQuery({
      word: "",
      location: "",
      slug: user?.proprietaire_id ? user?.proprietaire_id?.slug : user?.slug,
    });
  const authUser = useAppSelector((s) => s.user);
  const [addOrUpdatePropriete, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdateProprieteMutation();

  useEffect(() => {
    if (pictures?.length) {
      setValue(
        "pictures",
        pictures?.filter((el: any) => !el?.slug)
      );
    }
  }, [pictures]);
  useEffect(() => {
    if (proprieteItem?.type_propriete) {
      setValue("type_propriete", proprieteItem?.type_propriete);
    }
  }, [proprieteItem]);
  useEffect(() => {
    cleannerError(errors, clearErrors, 5000);
  }, [errors]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Propriété ${
          !proprieteItem?.slug ? "ajoutée" : "modifée"
        } avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (proprieteItem?.immeuble?.id) {
          navigate(
            `/proprietes/gerer-par-agence/immeuble/${proprieteItem?.immeuble?.slug}`,
            {
              replace: true,
              state: proprieteItem?.immeuble,
            }
          );
        } else {
          navigate("/proprietes/gerer-par-agence/proprietes", {
            replace: true,
          });
        }
      });
    }

    if (isError) {
      const err = error as any;
      // console.log("error", err);
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (proprieteItem?.id) {
      setValue("hasEdit", true);
      setValue("slug", proprieteItem["slug"]);
      setValue("numero", proprieteItem["numero"]);
      setValue("ville", proprieteItem["ville"]);
      setCity(proprieteItem["ville"]);
      setValue("region", proprieteItem["region"]);
      setState(proprieteItem["region"]);
      setValue("nom", proprieteItem["nom"]);
      setValue("adresse", proprieteItem["adresse"]);
      if (proprieteItem["url_youtube"]) {
        setValue("url_youtube", proprieteItem["url_youtube"]);
      }

      setValue("rue", proprieteItem["rue"]);
      setValue("pays", proprieteItem["pays"]);
      setCountry(proprieteItem["pays"]);
      setValue("code_postale", proprieteItem["code_postale"]);
      setValue("type_propriete", proprieteItem["type_propriete"]);
      setValue("surface", proprieteItem["surface"]);
      setValue("nbre_salon", proprieteItem["nbre_salon"]);
      setValue("nbre_chambres", proprieteItem["nbre_chambres"]);
      setValue("nbre_cuisines", proprieteItem["nbre_cuisines"]);
      setValue("nbre_salle_de_bains", proprieteItem["nbre_salle_de_bains"]);
      setValue("nbre_climatiseurs", proprieteItem["nbre_climatiseurs"]);
      setValue("prix_base", proprieteItem["prix_base"]);
      if (proprieteItem?.type_propriete === "appartement") {
        if (typeof proprieteItem?.immeuble === "number") {
          setValue("immeuble", proprieteItem["immeuble"]);
        } else {
          setValue("immeuble", proprieteItem["immeuble"]?.id);
        }
      }

      if (proprieteItem?.agence?.id) {
        setOption({
          value: proprieteItem?.agence?.id,
          label: proprieteItem?.agence?.nom_agence,
        });
      }

      setValue("nb_salle_de_bain", proprieteItem["nb_salle_de_bain"]);
      setValue("prix", proprieteItem["prix"]);
      if (proprieteItem.proprietaire) {
        if (typeof proprieteItem?.proprietaire === "number") {
          setValue("proprietaire", proprieteItem.proprietaire);
        } else {
          setValue("proprietaire", proprieteItem.proprietaire?.id);
        }
      } else {
        setValue("proprietaire", "agence");
      }

      setValue("description", proprieteItem["description"]);
      setValue("commission_agence", proprieteItem["commission_agence"] || 0);
      // setValue("pictures", proprieteItem.pictures);
      setPictures(proprieteItem?.pictures);
      setValue("gerance", proprieteItem.gerance);
      // setCity(proprieteItem?.ville)
      // setState(proprieteItem?.region)
      if (proprieteItem?.agence) {
        if (typeof proprieteItem?.agence === "number") {
          setValue("agence", proprieteItem["agence"]);
        } else {
          setValue("agence", proprieteItem["agence"]?.id);
        }
      }

      setDescription(proprieteItem.description);
      setAddress(proprieteItem["adresse"]);
      if (proprieteItem?.commodites?.length) {
        setValue(
          "commodites",
          proprieteItem.commodites?.map((item: any) => item?.id)
        );
        setCommodites(
          proprieteItem?.commodites?.map((item) => {
            return {
              label: item.nom,
              value: item?.id,
            };
          })
        );
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [proprieteItem]);

  useEffect(() => {
    register("hasChambre");
    if (proprieteItem && proprieteItem !== undefined) {
      if (nbChambre >= proprieteItem?.chambres?.length) {
        setValue("hasChambre", false);
      } else {
        setValue("hasChambre", true);
      }
    } else {
      setValue("hasChambre", false);
    }
  }, [nbChambre, proprieteItem]);

  useEffect(() => {
    if (isProprietaireAgence(user)) {
      setValue("isOwner", false);
    } else {
      setValue("isOwner", true);
    }
  }, [user]);

  const handleChangeAgence = (selected: any) => {
    setValue("agence", selected?.value);

    setOption(selected);
  };

  const handleChangeDescription = useCallback((value: string) => {
    setDescription(value);
    setValue("description", value);
  }, []);

  const handleChangeMandatPicture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget.files !== null) {
      // console.log(e.currentTarget.files[0]);
      setValue("mandat", e.currentTarget.files[0]);
      setMandatPicture(e.currentTarget.files[0]);
    }
  };

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
    console.log("val", val);
    if (val?.country) {
      setValue("pays", val?.country);
      setCountry(val?.country);
    }

    if (val?.region) {
      setValue("region", val?.region);
      setState(val?.region);
    }

    if (val?.ville) {
      setValue("ville", val?.ville);
      setCity(val?.ville);
    }
  };

  const handleCountrySelect = (option: Data) => {
    setCountry(option?.country);
    setValue("pays", option?.country);
    console.log("val country", option);
  };

  const handleStateSelect = (option: Data) => {
    console.log("val region", option);
    if (option?.rue) {
      setState(option?.region);
      setValue("region", option?.rue);
    }
  };
  const handleCitySelect = (option: Data) => {
    console.log("val city", option);
    setCity(option?.ville);
    setValue("ville", option.ville);
  };

  const onChangeCommodite = (selected: SelectOptionType[]) => {
    setCommodites(selected);
    setValue(
      "commodites",
      selected?.map((item: any) => item?.value)
    );
  };

  const handleChangeNumber = (e: any) => {
    unregister("chambres");

    if (e.target.value && parseInt(e.target.value) > 0) {
      if (
        proprieteItem?.id &&
        proprieteItem?.chambres?.length &&
        parseInt(e.target.value) >= 1
      ) {
        setNbChambre(
          parseInt(e.target.value) - proprieteItem?.chambres?.length
        );
      } else {
        setNbChambre(parseInt(e.target.value));
      }
      let number =
        proprieteItem && proprieteItem?.chambres?.length
          ? parseInt(e.target.value) - proprieteItem?.chambres?.length
          : parseInt(e.target.value);
      // console.log(getValues("chambres"), number);
      if (number <= 0) {
        register(`chambres[${0}].slug`);
        register(`chambres[${0}].surface`);
      } else {
        for (let index = 0; index < number; index++) {
          register(`chambres[${index}].surface`);
        }
      }
      setValue("nb_chambre", e.target.value);
    } else {
      if (proprieteItem?.id && proprieteItem?.chambres?.length) {
        setNbChambre(0);
        setValue("nb_chambre", proprieteItem?.chambres?.length);
      } else {
        setNbChambre(1);
        setValue("nb_chambre", 1);
        for (let index = 0; index < parseInt(e.target.value); index++) {
          register(`chambres[${0}].slug`);
          register(`chambres[${0}].surface`);
          if (pictures?.length && !proprieteItem?.id) {
            setValue(`chambres[${0}].image`, pictures[index]);
          }
          // console.log("chambres", index);
        }
      }
    }
  };
  const handleChangeNumberCuisine = (e: any) => {
    unregister("cuisines");

    if (e.target.value && parseInt(e.target.value) > 0) {
      if (
        proprieteItem?.id &&
        proprieteItem?.cuisines?.length &&
        parseInt(e.target.value) >= 1
      ) {
        setNbCuisine(
          parseInt(e.target.value) - proprieteItem?.cuisines?.length
        );
      } else {
        setNbCuisine(parseInt(e.target.value));
      }
      let number =
        proprieteItem && proprieteItem?.cuisines?.length
          ? parseInt(e.target.value) - proprieteItem?.cuisines?.length
          : parseInt(e.target.value);
      // console.log(getValues("cuisines"), number);
      if (number <= 0) {
        register(`cuisines[${0}].slug`);
        register(`cuisines[${0}].surface`);
      } else {
        for (let index = 0; index < number; index++) {
          register(`cuisines[${index}].surface`);
        }
      }
    } else {
      if (proprieteItem?.id && proprieteItem?.cuisines?.length) {
        setNbCuisine(0);
      } else {
        setNbCuisine(1);
        for (let index = 0; index < parseInt(e.target.value); index++) {
          register(`cuisines[${0}].slug`);
          register(`cuisines[${0}].surface`);
          if (pictures?.length && !proprieteItem?.id) {
            setValue(`cuisines[${0}].image`, pictures[index]);
          }
        }
      }
    }
  };
  const handleChangeNumberSDB = (e: any) => {
    unregister("salle_de_bains");

    if (e.target.value && parseInt(e.target.value) > 0) {
      if (
        proprieteItem?.id &&
        proprieteItem?.salle_de_bains?.length &&
        parseInt(e.target.value) >= 1
      ) {
        setNbSDB(
          parseInt(e.target.value) - proprieteItem?.salle_de_bains?.length
        );
      } else {
        setNbSDB(parseInt(e.target.value));
      }
      let number =
        proprieteItem && proprieteItem?.salle_de_bains?.length
          ? parseInt(e.target.value) - proprieteItem?.salle_de_bains?.length
          : parseInt(e.target.value);
      // console.log(getValues("salle_de_bains"), number);
      if (number <= 0) {
        register(`salle_de_bains[${0}].slug`);
        register(`salle_de_bains[${0}].surface`);
      } else {
        for (let index = 0; index < number; index++) {
          register(`salle_de_bains[${index}].surface`);
        }
      }
    } else {
      if (proprieteItem?.id && proprieteItem?.salle_de_bains?.length) {
        setNbSDB(0);
      } else {
        setNbSDB(1);
        for (let index = 0; index < parseInt(e.target.value); index++) {
          register(`salle_de_bains[${0}].slug`);
          register(`salle_de_bains[${0}].surface`);
          if (pictures?.length && !proprieteItem?.id) {
            setValue(`salle_de_bains[${0}].image`, pictures[index]);
          }
          // console.log("salle_de_bains", index);
        }
      }
    }
  };

  const handleChangeNumberSalon = (e: any) => {
    unregister("salons");

    if (e.target.value && parseInt(e.target.value) > 0) {
      if (
        proprieteItem?.id &&
        proprieteItem?.salons?.length &&
        parseInt(e.target.value) >= 1
      ) {
        setNbSalon(parseInt(e.target.value) - proprieteItem?.salons?.length);
      } else {
        setNbSalon(parseInt(e.target.value));
      }
      let number =
        proprieteItem && proprieteItem?.salons?.length
          ? parseInt(e.target.value) - proprieteItem?.salons?.length
          : parseInt(e.target.value);
      // console.log(getValues("salons"), number);
      if (number <= 0) {
        register(`salons[${0}].slug`);
        register(`salons[${0}].surface`);
      } else {
        for (let index = 0; index < number; index++) {
          register(`salons[${index}].surface`);
        }
      }
    } else {
      if (proprieteItem?.id && proprieteItem?.salons?.length) {
        setNbSalon(0);
      } else {
        setNbSalon(1);
        for (let index = 0; index < parseInt(e.target.value); index++) {
          register(`salons[${0}].slug`);
          register(`salons[${0}].surface`);
          if (pictures?.length && !proprieteItem?.id) {
            setValue(`salons[${0}].image`, pictures[index]);
          }
          // console.log("salons", index);
        }
      }
    }
  };

  const handleChangeNumberClim = (e: any) => {
    unregister("climatiseurs");

    if (e.target.value && parseInt(e.target.value) > 0) {
      if (
        proprieteItem?.id &&
        proprieteItem?.climatiseurs?.length &&
        parseInt(e.target.value) >= 1
      ) {
        setNbClim(
          parseInt(e.target.value) - proprieteItem?.climatiseurs?.length
        );
      } else {
        setNbClim(parseInt(e.target.value));
      }
      let number =
        proprieteItem && proprieteItem?.climatiseurs?.length
          ? parseInt(e.target.value) - proprieteItem?.climatiseurs?.length
          : parseInt(e.target.value);
      // console.log(getValues("climatiseurs"), number);
      if (number <= 0) {
        register(`climatiseurs[${0}].slug`);
        // register(`climatiseurs[${0}].surface`);
      }
      //   else {
      //   	for (let index = 0; index < number; index++) {
      //   		register(`climatiseurs[${index}].surface`);
      //   	}
      //   }
    } else {
      if (proprieteItem?.id && proprieteItem?.climatiseurs?.length) {
        setNbClim(0);
      } else {
        setNbClim(1);
        for (let index = 0; index < parseInt(e.target.value); index++) {
          register(`climatiseurs[${0}].slug`);
          //   register(`climatiseurs[${0}].surface`);
          if (pictures?.length && !proprieteItem?.id) {
            setValue(`climatiseurs[${0}].image`, pictures[index]);
          }
          // console.log("climatiseurs", index);
        }
      }
    }
  };

  const onSubmit = async (data: ProprieteFormData) => {
    const proprietaire = authUser?.user?.id;
    setFormData(data);
    if (data?.step === 1) {
      // console.log(data);
      // console.log(data);
      navigation.go("details-propriete");
    } else if (data?.step === 2) {
      // console.log(data);
      // console.log({ pictures });
      navigation.go("gestion-propriete");
    } else {
      console.log("data submitted", data);

      data["proprietaire"] = user?.id;
      const fd = new FormData();
      if (
        pictures?.length &&
        pictures?.filter((el: any) => !el?.slug)?.length
      ) {
        for (const picture of pictures) {
          fd.append("pictures", picture);
        }
      }
      if (data?.charges?.length) {
        data.charges = data?.charges?.filter((el) => el?.libelle);
      }
      if (proprietaire) {
        fd.append("proprietaire", proprietaire);
      }

      for (let key of Object.keys(data)) {
        const val = data[key];
        // fd.append(key, val);
        if (key !== "pictures") {
          if (
            (key === "chambres" ||
              key === "cuisines" ||
              key === "salons" ||
              key === "salle_de_bains" ||
              key === "climatiseurs" ||
              key === "charges") &&
            data[key]
          ) {
            fd.append(key, JSON.stringify(val));
          } else {
            if (val !== undefined) {
              fd.append(key, val);
            }
          }
        }
      }

      await addOrUpdatePropriete({ slug: data?.slug, data: fd });
    }
  };

  const { data: commoditedatas } = useGetCommoditesQuery({});
  return {
    unregister,
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    formData,
    description,
    pictures,
    mandatPicture,
    address,
    onChangeAddress,
    handleChangeDescription,
    setPictures,
    handleChangeMandatPicture,
    isLoading,
    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    proprieteItem,
    nbChambre,
    handleChangeNumber,
    nbCuisine,
    handleChangeNumberCuisine,
    nbSDB,
    handleChangeNumberSDB,
    nbSalon,
    handleChangeNumberSalon,
    nbClim,
    handleChangeNumberClim,
    control,
    Controller,
    commoditesList: commoditedatas?.results,
    commodites,
    onChangeCommodite,
    agences,
    handleChangeAgence,
    option,
  };
}

export default useCrudPropriete;

export function UseDeletePropriete(slug: string, item: IPropriete) {
  const [deleteData] = useDeleteProprieteMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer la propriété ${item?.nom} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: "Propriété supprimée avec succès!",
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
