/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ProprietaireAdminSkeleton from "./ProprietaireAdminSkeleton";
import {
  useDeleteProprietaireMutation,
  useGetProprietaireQuery,
} from "../../../../utils/api/proprietaire/proprietaire.api";
import { AlertInfo } from "../../../common/Alert";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { formattedDate, showModal } from "../../../../utils/Utils";
import { IProprietaire } from "../../../../utils/api/proprietaire/proprietaire.type";
import { ButtonEdit } from "../../../common/Button";
import { MdEdit, MdOutlinePayments } from "react-icons/md";
import { CustomPagination } from "../../../common/CustomPagination";
import { logoFormatter } from "./AgenceAdminTable";
import { GeneratePassword } from "../../agence/ProprietaireAgenceTable/ProprietaireAgenceTable";
import { IoMdTrash } from "react-icons/io";
import { useDelete } from "../../../../utils/helpers";
import SendPaymentModal from "../../../TableauDebord/Admin/MesClients/modal/SendPaymentModal";
import { useCrudProprietaire } from "../../../TableauDebord/Agence/ProprietaireAgence/useForm/useCrudProprietaire";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";

function DeleteProprietaire({ item }: { item: IProprietaire }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteProprietaireMutation();
  const onDelete = useDelete<IProprietaire>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce propriétaire?",
    successMessage: "Propriétaire supprimé",
  });
  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <IoMdTrash />
    </button>
  );
}
function ProprietaireAdminTable({
  word,
  proprio,
}: {
  word: string | null;
  proprio: string | null;
}) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [] }, isLoading } = useGetProprietaireQuery({
    word,
    proprio,
    page,
    limit: perPage,
  });
  const { handleChangeGerePar } = useCrudProprietaire();
  console.log("proprietaire", data);
  const actionFormatter: any = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes gap-2 d-flex">
          <div>
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Paiements"
              onClick={() => showModal(`sendPaymentModal${cell}`)}
            >
              <MdOutlinePayments />
            </button>
          </div>
          <div>
            <Link
              className="btn btn-action-modal-icon with-tooltip"
              to={`/admin/details-proprietaire/${row?.slug}`}
              state={row}
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </Link>
          </div>
          <div>
            {" "}
            <Link
              className="btn btn-action-modal-icon with-tooltip"
              to={`/admin/modifier-client-proprietaire/${row?.slug}`}
              state={row}
              data-tooltip-content="Modifier"
            >
              <MdEdit />
            </Link>
          </div>
          <div>
            <GeneratePassword slug={row?.slug} />
          </div>
          <div>
            <DeleteProprietaire item={row} />
          </div>
        </div>
        <SendPaymentModal modalId={`sendPaymentModal${cell}`} item={row} />
      </>
    );
  };
  const gererParFormatter: any = (cell: any, row: any) => {
    return (
      <div
        onClick={async () => {
          await Swal.fire({
            title: `Êtes-vous sûr de vouloir changer le rôle ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            showLoaderOnConfirm: true,
            iconColor: Color.bleu,
            confirmButtonColor: Color.bleu,
            preConfirm: () => {
              return handleChangeGerePar(row);
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result: any) => {
            if (result?.value) {
              // console.log(modalID)
              Swal.fire({
                icon: "success",
                title: `Rôle changé avec succès!`,
                iconColor: Color.bleu,
                showConfirmButton: false,
                timer: 1500,
              });
              // .then(() => {
              // onHide("EnvoieSuppressionNoteModal");
              // });
            }
          });
        }}
        className="table-actions-btn-container-commandes gap-2 d-flex"
      >
        <div
          className={
            cell === true
              ? "custom-btn-charge-locataire"
              : "custom-btn-charge-agence"
          }
        >
          {cell === true ? "PROPRIÉTAIRE" : "AGENCE"}
        </div>
      </div>
    );
  };
  const columns = [
    {
      dataField: "avatar",
      text: "Photos",
      formatter: (cell: any, row: any) => logoFormatter(cell, row),
    },
    {
      dataField: "nom",
      text: "Noms",
      style: { textAlign: "left" },
      formatter: (cell: any, row: IProprietaire) =>
        row?.prenom + " " + row?.nom,
    },
    {
      dataField: "email",
      text: "Email",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) => (
        <a className="no-link" href={`mailto:${cell}`}>
          {cell}
        </a>
      ),
    },
    {
      dataField: "telephone",
      text: "Téléphones",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) => (
        <a className="no-link" href={`tel:${cell}`}>
          {cell}
        </a>
      ),
    },
    {
      dataField: "created_at",
      text: "Date d’inscription",
      style: { textAlign: "left" },
      formatter: (cell: string, row: any) => formattedDate(cell),
    },
    {
      dataField: "gerer_par_moi",
      text: "Gérer par",
      style: { textAlign: "left" },
      formatter: (cell: string, row: any) => gererParFormatter(cell, row),
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <ProprietaireAdminSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results
              ?.slice()
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
            rowStyle={{ cursor: "pointer" }}
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export default ProprietaireAdminTable;
