import React from "react";
import { BiArrowBack } from "react-icons/bi";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import { BtnSubmit, GoBackButton } from "../../../common/Button";
import GoogleInput from "../../../common/GoogleInput";
import { Input, InputFile, RequiredText } from "../../../common/Input";
import { useCrudNews } from "./useForm/useCrudNews";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ITag } from "../../../../utils/api/Tag/tag.type";
import { FormError } from "../../../common/CustomInputCheckbox";
import { getImage } from "../../../../utils/Utils";

function AjoutNewsAdmin() {
  const navigate = useNavigate();
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    description,
    onChangeDescription,
    imageCouverture,
    handleChangeImageCouverture,
    Controller,
    control,
    tagsList,
    onChangeTags,
    currentNews,
    tags,
  } = useCrudNews();
  const animatedComponents = makeAnimated();

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="kanimmo-dash-locataire-requetes-header-titre d-flex align-items-baseline gap-2">
                  <BiArrowBack
                    className="locataire-btn-arrow-back pe-2"
                    onClick={() => navigate(-1)}
                  />{" "}
                  {currentNews?.slug ? "Modifier" : "Ajouter"} une news
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-3 filter-and-table-container">
          <div className="stepper-proprietes-form-container">
            <div className="container-fluid  px-0">
              <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
                <div className="tabs-component-container locataire-requete-filtre-container">
                  <div className="pt-4 pb-5">
                    <form onSubmit={onSubmit}>
                      <div className="row">
                        <div className="col-12 mb-1">
                          <Input
                            type="text"
                            label="Titre"
                            id="titre"
                            placeholder="Titre"
                            required
                            {...register("titre")}
                            error={errors?.titre}
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="type_propriété"
                              className="form-label form-label-modal-custom"
                            >
                              Tags
                              {/* <RequiredText /> */}
                            </label>
                            <Controller
                              name="tag"
                              control={control}
                              render={({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={tagsList?.map((tag: ITag) => ({
                                      value: tag?.id,
                                      label: tag?.nom,
                                    }))}
                                    classNamePrefix="react-select-custom"
                                    onChange={onChangeTags}
                                    value={tags}
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <Input
                            label="Vignette"
                            id="couverture"
                            error={errors.couverture}
                            onChange={handleChangeImageCouverture}
                            accept="image/*"
                            type="file"
                          />
                        </div>
                        {(imageCouverture || currentNews?.couverture) && (
                          <div className="offset-md-6 col-md-4 mb-4 mb-md-0">
                            <img
                              src={
                                imageCouverture
                                  ? imageCouverture
                                  : getImage(currentNews?.couverture)
                              }
                              alt="image-couverture img-thumbnail rounded"
                              width="200px"
                              height="180px"
                            />
                          </div>
                        )}
                        <div className="col-12">
                          <div className="mb-3">
                            <label
                              htmlFor="proprietaire"
                              className="form-label form-label-modal-custom"
                            >
                              Description
                            </label>
                            <ReactQuill
                              className="editeur-react-quill"
                              value={description}
                              onChange={onChangeDescription}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="container-btn-modal row my-4">
                        <div className="flex-r gap-3">
                          <GoBackButton />
                          <BtnSubmit
                            label={currentNews?.id ? "Modifier" : "Ajouter"}
                            isLoading={isLoading}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutNewsAdmin;
