import React, { useState } from "react";
import { useGetOffersQuery } from "../../../../../utils/api/offer/offer.api";
import BootstrapTable from "react-bootstrap-table-next";
import { MdEdit } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { ISubscriber, SubscriberFormaData } from "../../../../../utils/api/subscriber/subscriber.type";
import { useDeleteOneSubscriberMutation, useShowAllSubscriberQuery } from "../../../../../utils/api/subscriber/subscriber.api";
import { IoMdTrash } from "react-icons/io";
import { formatAmount, showModal } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import { CustomPagination } from "../../../../common/CustomPagination";
import CrudOfferModal from "../modal/CrudOfferModal";
import { UseDeleteOffer } from "../request/UseCrudOfferForm";
import OfferDetailsModal from "../modal/OfferDetailsMofal";
import TableSkeleton from "../../../../common/TableSkeleton";
import { format } from 'date-fns';
import { UseDeleteSubscriber } from '../request/UseAddAbonnementForm';
import EdithSubscriberModal from "../modal/EdithSubscriberModal";



function DeleteButton({ item }: { item: SubscriberFormaData }) {
  const onDelete = UseDeleteSubscriber(item);

  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
    >
      <IoMdTrash />
    </button>
  );
}
function SubscriberTable() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } = useShowAllSubscriberQuery({
    page,
    limit,
  });
  const actionFormatter = (cell: string, row: ISubscriber) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes gap-2 d-flex">
          {/* <div>
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              onClick={() => showModal(`showOfferModal${cell}`)}
            >
              <AiFillEye />
            </button>
          </div> */}
          <div>
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              onClick={() => showModal(`SubscriberEdith${cell}`)}
            >
              <MdEdit />
            </button>
          </div>

          <div>
            <DeleteButton item={row} />
          </div>
        </div>
        <EdithSubscriberModal modalId={`SubscriberEdith${cell}`} subscriber={row} />
        {/* <OfferDetailsModal modalId={`showOfferModal${cell}`} offer={row} /> */}
      </>
    );
  };

//   const nameFormatter = (cell: number, row: ISubscriber) => {
//     return row?.max_numb ? cell + "-" + row?.max_numb : cell + "+";
//   };
const formatDate = (dateString: string) => {
    const formattedDate = format(new Date(dateString), 'dd/MM/yyyy');
    return formattedDate;
  };

  const columns = [
    {
      dataField: "adherant",
      text: "Adhérant",
    },
    {
      dataField: "gratuit",
      text: "Gratuit",
      formatter: (cell: boolean, row: ISubscriber) => (cell ? "Gratuit" : "Payant"),
      style: (cell: boolean) => {
        return {
          color: cell ? 'green' : 'red',
          fontWeight: 'bold',
        };
      },
    },
    {
      dataField: "date_debut",
      text: "date_debut",
      formatter: (cell: string, row: ISubscriber) => formatDate(cell),    },
    {
      dataField: "date_fin",
      text: "date_fin",
      formatter: (cell: string, row: ISubscriber) => formatDate(cell), 
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: ISubscriber) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];
  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
            rowStyle={{ cursor: "pointer" }}
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            perPage={limit}
            onChange={(page, perPage) => {
              setLimit(perPage);
              setPage(page);
            }}
          />
        </>
      )}
    </>
  );
}

export default SubscriberTable;