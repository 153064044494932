import React from "react";
import "./ProprietaireAgence.css";
import { AiFillPlusSquare } from "react-icons/ai";
import { NavLink, useSearchParams } from "react-router-dom";
import ProprietaireAgenceTable from "../../../tables/agence/ProprietaireAgenceTable/ProprietaireAgenceTable";

function ProprietaireAgence() {
  const [searchParams, setSearchParams] = useSearchParams();
  const word = searchParams.get("name");
  const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        name: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Mes Propriétaires
          </h4>
          <div className="container-btn-add">
            <NavLink
              to="/agence/ajouter-proprietaire"
              className="btn btn-see-more"
            >
              <AiFillPlusSquare style={{ fontSize: 22 }} />
              <span className="ps-2">Ajouter un propriétaire</span>
            </NavLink>
          </div>
        </div>
        <div className="kanimmo-proprietes-container">
          <div className="proprietes-component-container">
            <div className="d-flex gap-3">
              <div className="locataire-requete-filtre-container my-4">
                <div className="row g-3 locataire-requete-filtre-form">
                  <div className="col-auto">
                    <label
                      htmlFor="filtreLogement"
                      className="locataire-requete-filtre-label"
                    >
                      Filtrer
                    </label>
                  </div>
                  <div className="col-auto">
                    <input
                      type="text"
                      className="form-control locataire-requete-filtre-input"
                      id="filtreLogement"
                      placeholder="Entrer un nom"
                      onChange={handleFilterByName}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="content-proprietes mt-5">
              <ProprietaireAgenceTable name={word} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProprietaireAgence;
