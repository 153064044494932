import React, { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NewsletterFormData } from "../../../utils/api/newsletter/newsletter.type";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { useSubscribeNewsletterMutation, useDesabonnerUserNewsletterMutation } from "../../../utils/api/newsletter/newsletter.api";
import { cleannerError } from "../../../utils/Utils";
import { Env , ApiBaseUrl } from "../../../utils/http";


export const useNewsLetter = () => {
	const validationSchema = yup.object().shape({
		email: yup.string().required().email().label("Adresse mail"),
	});
	const [subscribeNewsLetter, { isError, error, isSuccess, isLoading }] =
		useSubscribeNewsletterMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		reset,
		clearErrors,
		
	} = useForm<NewsletterFormData>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 3000);
		}
	}, [errors]);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Souscription reussi!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			});
			reset();
		}
		if (isError) {
			const err = error as any;
			console.log(err.data);
			if (err?.data?.email?.includes("newsletter with this email already exists.")) {
				setError("email", { message: "Cet email existe déjà!" });
			} else {
				const { message = "An error occured" } = err.data || {};
				setError("email", { message: message });
			}
		}
	}, [isLoading]);

	const onSubmit = (data: NewsletterFormData) => {
		
		if(Env === 'dev')
		{
			data.env = 'http://localhost:3000' ;
		} else
		{
			data.env = ApiBaseUrl ;
		}
		
		subscribeNewsLetter(data);
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		isLoading
	};
};

export function UseDesabonnerUserNewsletter(email: string) {
	const [desabonnerUser] = useDesabonnerUserNewsletterMutation();
	const onDesabonner = async () => {
	  await Swal.fire({
		title: `Êtes-vous sûr de vouloir vous Désabonner des newsletters?`,
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: "OUI",
		cancelButtonText: "NON",
		showLoaderOnConfirm: true,
		iconColor: Color.themeColor,
		confirmButtonColor: Color.themeColor,
		preConfirm: () => {
		  return desabonnerUser(email);
		},
		allowOutsideClick: () => !Swal.isLoading(),
	  }).then((result: any) => {
		console.log('resultser',result)
		if (result?.value) {
		  Swal.fire({
			icon: "success",
			title: "Vous etes désabonner avec succès!",
			iconColor: Color.themeColor,
			showConfirmButton: false,
			timer: 1500,
		  });
		}
	  });
	};
	return onDesabonner;
  }


