import Pusher from "pusher-js";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ChatApi } from "./api/chat/chat.api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  isAgence,
  isAgenceJuriste,
  isComptable,
  isLocataire,
  isProprietaireAgence,
  isProprietaireOwner,
  isProprioJuriste,
} from "./Utils";
import { IUser } from "./api/user/user.type";

// Enable pusher logging - don't include this in production
Pusher.logToConsole = false;

const pusher = new Pusher("08d3524adfa99fe518f9", {
  cluster: "eu",
});

export const useChatListener = () => {
  const userId = useAppSelector((s) => s.user.user?.id);
  const user = useAppSelector((s) => s.user.user);
  const dispatch = useAppDispatch();

  usePusher(
    "message",
    () => {
      if (userId) {
        dispatch(ChatApi.util.invalidateTags(["chat"]));
      }
    },
    [userId]
  );
};

const usePusher = (eventName: string, cb: () => any, deps?: any[]) => {
  const userId = useAppSelector((s) => s.user.user?.id);
  const user = useAppSelector((s) => s.user.user);
  const [idChat, setIdChat] = useState<any>(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    const channel = pusher.subscribe("my-channel");
    const subscription = channel.bind(eventName, (data: any) => {
      // console.log("data pusher",data)
      let id = data?.data?.recever?.id
        ? data?.data?.recever?.id
        : data?.data?.recever;

      if (userId === id && data?.data?.is_read === false) {
        setIdChat((prevState: any) => {
          if (prevState !== data?.data?.id) {
            toast("Vous avez reçu un nouveau message!", {
              position: "top-right",
              theme: "colored",
              type: "info",
              onClick: () => navigate(getRoutePath(user)),
            });
            return data?.data?.id;
          }

          return prevState;
        });

        // var newTitle = "Nouveau message";
        // document.title = newTitle;

        // setTimeout(() => {
        //   document.title = "Kanimmo"
        // }, 5000);
      }
      cb();
    });
    return () => {
      try {
        if (subscription.subscribed) {
          subscription.unsubscribe();
        } else if (subscription.subscriptionPending) {
          subscription.cancelSubscription();
        }
      } catch (ex) {}
    };
  }, deps);
};

const getRoutePath = (user: IUser) => {
  let route = "/admin/messages";
  if (isLocataire(user)) {
    route = `/locataire/messages`;
  }

  if (isComptable(user)) {
    route = `/comptable/messagerie`;
  }

  if (isProprietaireAgence(user)) {
    route = `/proprietes/gerer-par-agence/messages`;
  }

  if (isAgence(user) || user?.agence_id) {
    route = `/agence/messagerie`;
  }

  if (isProprietaireOwner(user) || user?.proprietaire_id) {
    route = `/proprietaire/gerer-par-le-proprietaire/messages`;
  }

  return route;
};
