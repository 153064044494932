import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, onHide } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { ContratFormData, IContrat } from "../../../../../utils/api/contrat/contrat.type";
import { useAppSelector } from "../../../../../redux/hooks";
import { useAddOrUpdateContratAdminAgenceMutation, useAddOrUpdateContratAdminProprietaireMutation, useAddOrUpdateContratMutation, useDeleteContratMutation } from "../../../../../utils/api/contrat/contrat.api";
import { useGetUserByAdminQuery } from "../../../../../utils/api/admin/admin.api";

function useCrudContratAdminAgence(item?: IContrat) {
    const validationSchema = yup.object().shape({
		agence: yup
			.number()
			.required()
			.label("Agence")
			.typeError("Agence est un champ obligatoire"),
        nom_document: yup
			.string()
			.label("Titre")
			.typeError("Titre est un champ obligatoire"),
		document: yup.mixed().required().label("Document"),
	});
    const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
        control,
        reset,
	} = useForm<ContratFormData>({
		resolver: yupResolver(validationSchema),
	});

    const admin = useAppSelector((s) => s.user.user);
    const { data: userAdmin } = useGetUserByAdminQuery({});
	const [document, setDocument] = useState<File>();

	const ListeAgenceAdmin = userAdmin?.filter(item => item?.user_type === "agence")

    const [agences, setAgences] = useState<number | null>();

    const onChangeAgences = (selected: any) => {
		setValue(
			"agence",
			selected?.value
		);
	};

	const handleChangeDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files !== null) {
			console.log(e.currentTarget.files[0]);
			setValue("document", e.currentTarget.files[0]);
			setDocument(e.currentTarget.files[0]);
		}
	};

    const [addOrUpdateContrat, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateContratAdminAgenceMutation();

        useEffect(() => {
            if (isSuccess) {
                Swal.fire({
                    icon: "success",
                    title: `Contrat ${item ? "modifiée" : "ajouté"} avec succès!`,
                    iconColor: Color.success,
                    showConfirmButton: false,
                    timer: 1200,
                }).then(() => {
                    reset();
                    setAgences(null)
                    onHide("AjouterContratAgenceModal");
                });
            }
            const err = error as any;
            if (isError) {
                Swal.fire({
                    icon: "error",
                    title: err?.data?.message
                        ? err?.data?.message
                        : `Erreur de statut ${err?.status}`,
                    showConfirmButton: false,
                    timer: 5000,
                });
            }
        }, [isLoading]);
        useEffect(() => {
            cleannerError(errors, clearErrors);
        }, [errors]);
    
        useEffect(() => {
            if (item?.id) {
                // setLocataires(item?.locataire)
                // setValue("propriete", item?.propriete?.id);
                // setValue("locataire", item?.locataire?.id);
    
            }
        }, [item]);

        const onSubmit = (data: ContratFormData) => {
            data["admin"] = admin?.id
            data["concernant"] = "agence"
            const fd = new FormData();
            for (let key of Object.keys(data)) {
                const val = data[key];
                fd.append(key, val);
            }
          // console.log("data subm",data)
            addOrUpdateContrat({ slug: item?.slug, data: fd });
        };
    
        return {
            register,
            errors: errors,
            onSubmit: handleSubmit(onSubmit),
            setValue,
            isLoading,
            handleChangeDocument,
            listAgence: ListeAgenceAdmin,
            control,
            Controller,
            onChangeAgences,
            agences
        };
}
export default useCrudContratAdminAgence


export function UseDeleteContrat(item: IContrat) {
	const [deleteData] = useDeleteContratMutation();
	const onDelete = async () => {
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer ce contrat ?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			confirmButtonColor: Color.success,
			preConfirm: () => {
				return deleteData(item?.slug);
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log(result, "deleted");
			if (result?.value) {
				Swal.fire({
					icon: "success",
					title: `Contrat supprimé avec succès!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};
	return onDelete;
}
