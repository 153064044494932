
import './BarChart.css'
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import { GraphType, IComptableData } from '../../../../../utils/api/user/user.type';
import { formatMontant } from '../../../../../utils/Utils';
import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';


  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  // export const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       display: false,
  //       position: 'top' as const,
  //     },
  //     title: {
  //       display: false,
  //       text: 'Chart.js Bar Chart',
  //     },
  //   },
  //   scales: {
  //     x: {
  //       stacked: true,
  //     },
  //     y: {
  //       stacked: true,
  //     },
  //   },
  // };
  
  // // const labels = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];
  // const labels = data?.graph?.map((item) => moment(item?.month).format("M"));
  // export const datas = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'Dataset 1',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 800000 })),
  //       backgroundColor: '#1F3CAD',
  //     },
  //     {
  //       label: 'Dataset 2',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 800000 })),
  //       backgroundColor: '#E4E8EF',
  //     },
  //   ],
  // };

function BarChart({ data, onTypeChange }: { data?: IComptableData, onTypeChange: (newType: string) => void }) {
  const [abscessBar, setAbscessBar] = useState<string | undefined>(undefined);   
  const [labels, setLabels] = useState<string[]>([]); 
  const handleButtonClick = (newType: string) => {
    onTypeChange(newType);
    setAbscessBar(newType);
    changeAbscessBar();
  };

  useEffect(() => {
    if (data?.graph) {
      const newLabels = data.graph.map((item) => moment(item?.month).format("MMM"));
      setLabels(newLabels);
      changeAbscessBar();
    }
  }, [data]);
  
  const changeAbscessBar = () => {
    if (abscessBar === "day" && data?.graph) {
      const newLabels = data.graph.map((item) => moment(item?.day).format("dddd"));
      setLabels(newLabels);
    } else if (abscessBar === "year" && data?.graph) {
      const newLabels = data.graph.map((item) => moment(item?.year).format("YYYY"));
      setLabels(newLabels);
    } else if (abscessBar === "month" && data?.graph) {
      const newLabels = data.graph.map((item) => moment(item?.month).format("MMM"));
      setLabels(newLabels);
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  
  // const labels = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];


  const datas = {
    labels,
    datasets: [
      {
        label: 'Entrée',
        data: data?.graph?.map((item) => item?.entree),
        backgroundColor: '#1F3CAD',
      },
      {
        label: 'Dépense',
        data: data?.graph?.map((item) => item?.depense),
        backgroundColor: '#E4E8EF',
      },
    ],
  };
  return (

    <div className="chart-item-container">
    <div className="row mb-3 chart-infos-row">
      <div className="col-md-12 mb-2">
        <div className='barchart-titre-container'>
            <h2 className="chart-item-title">Total revenue</h2>
            <div className="chart-item-btn-filter-container">
                   <button onClick={() => handleButtonClick('year')} className="chart-item-btn-filter">Annuel</button>
                  <button onClick={() => handleButtonClick('month')} className="chart-item-btn-filter">Mensuel</button>
                  <button onClick={() => handleButtonClick('day')} className="chart-item-btn-filter">Quotidien</button>
            </div>
        </div>
        <div className='barchart-revenue-container'>
            <div className='barchart-revenue-prix'>{formatMontant(data?.profit)}</div>
            <div className='barchart-revenue-augmentation-container'>
                <div className='barchart-revenue-augmentation-icon'><BsFillArrowUpCircleFill /></div>
                <div className='barchart-revenue-augmentation'>
                    <div className='barchart-revenue-augmentation-chiffre'>0,8%</div>
                    <div className='barchart-revenue-augmentation-periode'>que le dernier jour</div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <Bar options={options} data={datas} />
  </div>
  )
}

export default BarChart