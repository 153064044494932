import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ChangeEvent, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, closeModal } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { OfferFormData } from "../../../../../utils/api/offer/offer.type";
import {
  useAddOrUpdateOfferMutation,
  useDeleteOfferMutation,
} from "../../../../../utils/api/offer/offer.api";
import { Offer } from "../../../../../utils/api/offer/offer.type";

function UseCrudOfferForm(modalId: string, offer?: Offer) {
  const validationSchema = yup.object().shape(
    {
      min_numb: yup
        .number()
        .required()
        .label("Le nombre minimal de propriétés")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
      max_numb: yup
        .number()
        .label("Le nombre maximum de propriétés")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
        gratuit: yup.boolean().required().default(true).label("Le mode"),
      duree: yup.number().required().label("Le durée de l'offre ")
      .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
        libelle : yup.string().required().label("Le libelle de la durée de  l'offre"),
      montant: yup
        .number()
        .when("gratuit", {
          is: false,
          then: yup
            .number()
            .required()
            .label("Le montant de l'offre")
            .transform((value) => (isNaN(value) ? null : value))                
            .nullable(),                  
        })
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
      description: yup.string().nullable().label("La description"),
      type: yup.string().required().label("Le type").nullable(),
    },
    [["min_numb", "max_numb"]]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
  } = useForm<OfferFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [description, setDescription] = useState("");
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrUpdateOfferMutation();
  const [mode, setMode] = useState("gratuite")
  const onChangeDescription = (value: any) => {
    setDescription(value);
    setValue("description", value);
  };

  const handleReset = () => {
    if (!offer) {
      setDescription("");
      reset();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMode(e?.target?.value)
    if (e?.target?.value === "gratuite") {
      setValue("gratuit",true)
    } else {
      setValue("gratuit",false)                                                     
    }
  }

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Offre ${offer ? "modifiée" : "ajoutée"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        closeModal(modalId);
        handleReset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (offer?.id) {
      // setValue("hasEdit", true);
      const fields: (keyof OfferFormData)[] = [
        "slug",
        "description",
        "max_numb",
        "min_numb",
        "montant",
        "type",
        "gratuit",
        "duree",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, offer[field]);
      }
      if (offer?.gratuit) {
        setMode("gratuite")
      } else {
        setMode("payante");
      }
      setDescription(offer?.description);
    }
  }, [offer]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: OfferFormData) => {
    if (data?.max_numb && data?.min_numb && data?.max_numb < data?.min_numb) {
      
      setError("max_numb", {
        message: "Le maximum ne peut pas être inférieur au minimum",
      });
    } else {
      const montantValue = data.montant
      if(montantValue===null || montantValue === 0)  {
      data["montant"]=0
      data["gratuit"] = true
    }else if(montantValue !=null && montantValue != 0) {
      data["gratuit"] = false
    }
  
      await sendData(data);
    }
    
  };
  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    description,
    handleReset,
    onChangeDescription,
    mode,
    handleChange,
  };
}

export default UseCrudOfferForm;
export function UseDeleteOffer(item: Offer) {
  const [deleteData] = useDeleteOfferMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet offre ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'offre a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
