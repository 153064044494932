import React from "react";
import "./ComptabilitePQGSB.css";
import Key from "../../../../../assets/icons/key.png";
import Users from "../../../../../assets/icons/users.png";
import Paper from "../../../../../assets/icons/paper.png";
import DepComptabiliteTablePQGSB from "../../../../tables/Proprietaire/ProprietaireGererParProprietaire/Comptabilite/DepComptabiliteTablePQGSB";
import EntComptabiliteTablePQGSB from "../../../../tables/Proprietaire/ProprietaireGererParProprietaire/Comptabilite/EntComptabiliteTablePQGSB";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetComptabiliteByProprietaireBySlugQuery } from "../../../../../utils/api/proprietaire/proprietaire.api";
import {
  formatMontant,
  isProprietaireOwner,
  isProprioJuriste,
} from "../../../../../utils/Utils";
import {
  MdCategory,
} from "react-icons/md";
import { AiFillPlusSquare } from "react-icons/ai";
import AjoutEntreePQGSBModal from "../../../../modal/proprietaireQGSB/AjoutEntreePQGSBModal";
// import AjouterDepenseModal from "../../../../modal/comptabilite/AjouterDepenseModal";
import { ApiBaseUrl } from "../../../../../utils/http";
import { BiExport } from "react-icons/bi";
import AjoutDepensePQGSBModal from "../../../../modal/proprietaireQGSB/AjoutDepensePQGSBModal";
import { AlertInfo } from "../../../../common/Alert";
import { NavLink } from "react-router-dom";
// import Page401 from "../../../../../utils/Page401/Page401";

function ComptabilitePQGSB() {
  const user = useAppSelector((s) => s.user.user);
  const { data: compta, isLoading } =
    useGetComptabiliteByProprietaireBySlugQuery({
      slug: user?.proprietaire_id ? user?.proprietaire_id?.slug : user?.slug,
    });

  // console.log("compta", compta);
  if (isProprioJuriste(user)) {
    return (
      <div className="dashboard-admin-component kanimmo-tabs-container container-form-add-locataire no-access-apge">
        <div className="row container-dashboard-page">
          <AlertInfo message="Vous n'avez pas accès au données de cette page !" />
          {/* <Page401 /> */}
        </div>
      </div>
    );
  }

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-2">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Comptabilité
          </h4>
          {/* {isProprietaireOwner(user) &&
            user?.a_un_comptable === false && ( */}
            <NavLink
            className={`btn btn-see-more `}
            to="/proprietaire/gerer-par-le-proprietaire/categorie"
            data-tooltip-content="Catégorie E/S"
          >
            <MdCategory />
            <span className="ps-2">Listes des Catégories</span>
          </NavLink>
          
          {/* // )} */}
        </div>
        <div className="kanimmo-comptabilite-container">
          <div className="comptabilite-component-container">
            <div className="row px-md-2">
              <div className="col-md-3 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Key}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Recouvrements
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1"></p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {isLoading ? (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                              style={{ color: "#1F3CAD" }}
                            ></div>
                          ) : (
                            formatMontant(compta?.total_entrees || 0)
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Users}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Total des dépenses
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1"></p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {isLoading ? (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                              style={{ color: "#FCAE3B" }}
                            ></div>
                          ) : (
                            formatMontant(compta?.total_depenses || 0)
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Paper}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Total des revenus
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          Cette semaine
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {isLoading ? (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                              style={{ color: "#1AA981" }}
                            ></div>
                          ) : (
                            formatMontant(compta?.revenus || 0)
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="dropdown dis-flex justify-content-end">
            <button
              className="btn btn-see-more dropdown-toggle btn"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="user-acount-dashboard-header-display g-2">
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter</span>
              </div>
            </button>
            <div
              className="dropdown-menu dropdown-menu-custom"
              aria-labelledby="dropdownMenuButton"
            >
              <button
                className="btn btn-see-more"
                title="Note"
                data-bs-toggle="modal"
                data-bs-target="#AjoutEntreePQGSBModal"
              >
                <span className="ps-2">Entrée</span>
              </button>
              <button
                className="btn btn-see-more"
                title="Annonce"
                data-bs-toggle="modal"
                data-bs-target="#AjouterDepensePQGSBModal"
              >
                <span className="ps-2">Dépense</span>
              </button>
            </div>
            <div
              className="modal fade"
              id="AjoutEntreePQGSBModal"
              aria-labelledby="AjoutEntreePQGSBModalLabel"
              aria-hidden="true"
              data-bs-backdrop="static"
            >
              <AjoutEntreePQGSBModal modalId="AjoutEntreePQGSBModal" />
            </div>
            <div
              className="modal fade"
              id="AjouterDepensePQGSBModal"
              aria-labelledby="AjouterDepensePQGSBModalLabel"
              aria-hidden="true"
              data-bs-backdrop="static"
            >
              <AjoutDepensePQGSBModal modalId="AjouterDepensePQGSBModal" />
            </div>
          </div>
            </div>
          </div>
          <div className="container-table-comptabilite">
            <div className="row">
              <div className="col-lg-6 col-md-12 d-flex flex-column mb-3">
                <div className="col-left-comptabilite">
                  {/* <div className="content-title-page-comptabilite p-3">
                    <span className="title-page-comptabilite">Dépenses</span>
                  </div> */}
                  <div className="content-title-page-comptabilite p-3">
                    <span className="title-page-comptabilite">Dépenses</span>
                    {!!compta?.depenses?.length && (
                      <div className="btn-export-ontainer">
                        <button
                          className="btn btn-see-more dropdown-toggle btn"
                          type="button"
                          onClick={(e) => {
                            e?.preventDefault();

                            window
                              ?.open(
                                `${ApiBaseUrl}/api/proprietaire/${user?.slug}/export_depense`,
                                "_blank"
                              )
                              ?.focus();
                          }}
                        >
                          <div className="user-acount-dashboard-header-display g-2">
                            <BiExport style={{ fontSize: 22 }} />
                            <span className="ps-2">Exporter les dépenses</span>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="content-table-depense-comptabilte my-3">
                    <DepComptabiliteTablePQGSB />
                  </div>
                </div>
                <div className="container-balance-table">
                  <div className="content-text-balance-libelle">
                    <p className="text-balance-libelle mb-0">
                      Balance des dépences:
                    </p>
                  </div>
                  <div className="content-text-balance-value">
                    <p className="text-balance-value mb-0">
                      {isLoading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#1F3CAD" }}
                        ></div>
                      ) : (
                        formatMontant(compta?.total_depenses || 0)
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 d-flex flex-column mb-3">
                <div className="col-right-comptabilite">
                  <div className="content-title-page-comptabilite p-3">
                    <span className="title-page-comptabilite">Revenus</span>
                    {!!compta?.entrees?.length && (
                      <div className="btn-export-ontainer">
                        <button
                          className="btn btn-see-more dropdown-toggle btn"
                          type="button"
                          onClick={(e) => {
                            e?.preventDefault();

                            window
                              ?.open(
                                `${ApiBaseUrl}/api/proprietaire/${user?.slug}/export_recette`,
                                "_blank"
                              )
                              ?.focus();
                          }}
                        >
                          <div className="user-acount-dashboard-header-display g-2">
                            <BiExport style={{ fontSize: 22 }} />
                            <span className="ps-2">Exporter les entrées</span>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="content-table-depense-comptabilte my-3">
                    <EntComptabiliteTablePQGSB />
                  </div>
                </div>
                <div className="container-balance-table">
                  <div className="content-text-balance-libelle">
                    <p className="text-balance-libelle mb-0">
                      Balance des revenus:
                    </p>
                  </div>
                  <div className="content-text-balance-value">
                    <p className="text-balance-value mb-0">
                      {isLoading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#1F3CAD" }}
                        ></div>
                      ) : (
                        formatMontant(compta?.total_entrees || 0)
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="container-balance-table">
                  <div className="content-text-balance-libelle">
                    <p className="text-balance-libelle mb-0">Balance Total:</p>
                  </div>
                  <div className="content-text-balance-value">
                    <p className="text-balance-value mb-0">
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#1F3CAD" }}
                        ></span>
                      ) : (
                        formatMontant(compta?.revenus || 0)
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComptabilitePQGSB;
