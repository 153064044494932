import React from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { onHide } from "../../../../../utils/Utils";
import ErrorMessage from "../../../../common/ErrorMessage";

function CheckStatusPaymentModal({
  isLoading,
  onCheckStatus,
  disabled,
  seconds,
  modalId,
}: {
  isLoading: boolean;
  onCheckStatus: () => void;
  disabled?: boolean;
  seconds: string;
  modalId?: string;
}) {
  return (
    <div
      className="modal fade"
      id={modalId || "statusPaymentModal"}
      tabIndex={-1}
      aria-labelledby="statusPaymentModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="d-flex justify-content-between p-1">
            <h5 className="modal-title" id="statusPaymentModalLabel">
              Statut
            </h5>
            <button
              type="button"
              className="btn-close d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                onHide("statusPaymentModal");
              }}
            ></button>
          </div>
          <div className="modal-payment-body">
            <div className="my-5 flex-col itm-center">
              <BsFillInfoCircleFill className="icon-payment-modal mb-4" />
              <p className="title-payment-modal mb-4">
                Veuillez vérifier le statut de votre paiement!
              </p>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-confirm-payment"
              // disabled={isLoading}
              onClick={() => onCheckStatus()}
              disabled={
                isLoading ||
                disabled ||
                (parseInt(seconds) > 0 && parseInt(seconds) < 20)
              }
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Vérification...</span>
                </>
              ) : (
                "Vérifier le paiement"
              )}
            </button>
            {parseInt(seconds) <= 0 || parseInt(seconds) === 20 ? null : (
              <div className="col-12">
                <ErrorMessage
                  message={
                    <span>Veuillez revérifier dans {seconds} secondes</span>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckStatusPaymentModal;
