import React from "react";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import RequetesDatas from "./RequetesDatas";
import { AiFillEye } from "react-icons/ai";
import RequetesSkeleton from "./RequetesSkeleton";
import { Pagination } from "reactstrap";
import { useGetRequeteByLocataireBySlugQuery } from "../../../../utils/api/requete/requete.api";
import { useAppSelector } from "../../../../redux/hooks";
import { formattedDate } from "../../../../utils/Utils";
import { IAgence } from "../../../../utils/api/agence/agence.type";
import { AlertInfo } from "../../../common/Alert";
import { NavLink } from "react-router-dom";
import { CustomPagination } from "../../../common/CustomPagination";

function RequetesTable({titre}: {titre?: any}) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  // const [itemState, findBySlug] = useRequeteFromLocation();
  const { user } = useAppSelector((s) => s?.user);
  const { data = { results: [] }, isLoading } =
    useGetRequeteByLocataireBySlugQuery({
      page,
      limit: perPage,
      slug: user?.slug,
      titre
    });
  // console.log("data", data)
  const statusFormatter = (cell: any, row: any) => {
    if (cell === "non_regler") {
      return <span className="status-reservation-non-regle">Non réglé</span>;
    }
    if (cell === "regler") {
      return <span className="status-reservation-regle">Réglé</span>;
    }
    if (cell === "encours") {
      return <span className="status-reservation-encours">En cours</span>;
    }
  };

  const agenceproprietaireFormatter = (cell: any, row: any) => {
   return row?.agence ? (row?.agence?.prenom + " " + row?.agence?.nom) : (row?.proprietaire?.prenom + " " + row?.proprietaire?.nom)
  }

  const actionFormatter: any = (cell: any, row: any) => {
    return (
       <div className="table-actions-btn-container-commandes d-flex">
       <div className="container-btn-action-icon-modal">
         <NavLink
           className="btn btn-action-modal-icon with-tooltip"
           data-tooltip-content="Voir la requête"
           to={`/locataire/requetes/${row?.slug}`}
           state={row}
         >
           <AiFillEye />
         </NavLink>
       </div>
     </div>
    );
  };
  const columns = [
    {
      dataField: "id",
      text: "N°",
      style: { textAlign: "left" },
      headerStyle: () => {
        return { width: "50px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "agence",
      text: "Agence/propriétaire",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) => agenceproprietaireFormatter(cell, row),
    },
    {
      dataField: "created_at",
      text: "Date",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) => formattedDate(cell),
    },
    {
      dataField: "titre",
      text: "Requetes",
      style: { textAlign: "left", textTransform: "capitalize"},
      headerStyle: () => {
        return { width: "400px", whiteSpace: "normal" };
      },
    }, {
      dataField: "charge",
      text: "Charge",
      style: { textAlign: "left", textTransform: "capitalize"},
    },
    {
      dataField: "statut",
      text: "Status",
      headerStyle: () => {
        return { width: "500px", whiteSpace: "normal", textAlign: "center" };
      },
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => statusFormatter(cell, row),
    },
    {
      dataField: "actions",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  const options = {
    pageStartIndex: 1,
    sizePerPage: 5,
    hideSizePerPage: true,
  };

  return (
    <>
      {isLoading && <RequetesSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <CustomPagination
						nbPages={data?.count}
						page={page}
						perPage={perPage}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
					/>
        </>
      )}
    </>
  );
}

export default RequetesTable;