import React from "react";
import EmptyHeader from "../../../landingPage/EmptyHeader";
import FrontFooter from "../../../landingPage/FrontFooter/FrontFooter";
import BreadCrumb from "../../../shared/BreadCrumb";
import "./AboutUs.css";
import ReactPlayer from "react-player";
import PlayImg from "../../../../assets/icons/Play.png";
import Imgbg from "../../../../assets/appImages/Rectangle.png";
import aproposImg from "../../../../assets/appImages/aproposImg.png";
import { Document, Filter, Wallet } from 'react-iconly'

const AboutUs = () => {
  return (
    <div className="landing-page-component">
      <EmptyHeader />
      <div className="page-content">
        <section className="section-banner-landing-page-v2 py-5">
          <div className="banner-lading-page-v2-container">
            <div className="content-title-lading-page-v2 mt-5">
              <h2 className="title-lading-page-v2">
              À propos de nous
              </h2>
              <div className="title-lading-page-v2-para-container mt-4">
                <p className="title-lading-page-v2-para">
                Chez Kanimmo, nous sommes passionnés par la simplification de la gestion immobilière pour les propriétaires de biens locatifs et les agences immobilières. Notre solution de bout en bout élimine les tracas et les frustrations de la gestion locative afin que vous puissiez vous concentrer sur la croissance de votre entreprise et l'atteinte de vos objectifs.Avec notre expertise dans le domaine de l'immobilier, nous nous sommes rendu compte d'un besoin d'une meilleure façon de gérer les propriétés locatives en Afrique et avons constaté les défis auxquels sont confrontés les propriétaires et les agences immobilières:
                </p>
              </div>
            </div>
            <div className="content-btn-banner-landing-page-v2 my-5 text-center custom-width-80">
              <div className="row">
                <div className="col-md-4">
                  <div className="kanimmo-a-propos-card-container mb-3">
                    <Document set="light" primaryColor="white"  />
                    <div className="kanimmo-a-propos-card-text">Tâches administratives fastidieuses</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="kanimmo-a-propos-card-container mb-3">
                    <Filter set="light" primaryColor="white"/>
                    <div className="kanimmo-a-propos-card-text">Processus inefficaces</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="kanimmo-a-propos-card-container mb-3">
                    <Wallet set="light" primaryColor="white"/>
                    <div className="kanimmo-a-propos-card-text">Suivi des transactions financières</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-container-img-macbook">
          <div className="container-carousel-events">
            <div className="text-center kanimmo-a-propos-slogan-container custom-width-80">
              <img src={aproposImg} alt="a propos" className="kanimmo-image-a-propos" />
              <div className="kanimmo-a-propos-slogan">Plus qu'une plateforme , votre partenaire !</div>
            </div>
          </div>
        </section>

        <section className="section-about-kan-immo custom-width-80">
          <div className="row d-flex align-items-center mb-5">
            <div className="col-lg-6 mb-3">
              <div className="a-propos-card-mission-vision">
                <div className="a-propos-card-mission-marker"></div>
                <div className="a-propos-card-mission-vision-content">
                  <div className="title-col-about-kan-immo custom-padding">Mission</div>
                    <div className="content-text-description-about-kan-immo">
                      <p className="text-description-about-kan-immo">
                      Notre mission est de simplifier la gestion immobilière pour les propriétaires de biens locatifs et les agences immobilières en éliminant les tracas et les frustrations de la gestion locative afin qu'ils puissent se concentrer sur la croissance de leur entreprise et la réalisation de leurs objectifs grâce à une solution de bout en bout qui minimise les tâches administratives, améliore les relations avec les locataires et accroît la transparence des transactions financières.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="a-propos-card-mission-vision">
                <div className="a-propos-card-mission-marker2"></div>
                <div className="a-propos-card-mission-vision-content">
                  <div className="title-col-about-kan-immo custom-padding">Vision</div>
                    <div className="content-text-description-about-kan-immo">
                      <p className="text-description-about-kan-immo">
                      Notre vision est de révolutionner la façon dont les propriétés locatives sont gérées en Afrique en fournissant une solution de pointe, de bout en bout, qui donne aux propriétaires et aux agences immobilières les outils et le soutien dont ils ont besoin pour optimiser leurs opérations, augmenter leur rentabilité et, en fin de compte, transformer le secteur de l'immobilier locatif sur tout le continent.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section>
        
      </div>

      {/* <section className="page-content-body-container container">
        <div className="online-courses-container">
          <div className="news-list-container pt-5">
            <div className="content-video-player">
              <ReactPlayer
                className="about-video-kan-immo"
                light={Imgbg}
                controls
                url="Volkeno.mp4"
                playing
                playIcon={<img src="https://www.youtube.com/embed/sCrRjdW1A5U" alt="video" />}
              />
            </div>
          </div>
        </div>
      </section> */}

      <FrontFooter />
    </div>
  );
};

export default AboutUs;
