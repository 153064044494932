import React from "react";
import { HiHome } from "react-icons/hi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { NavLink } from "react-router-dom";
import "./BreadCrumb.css";

const BreadCrumbNews = ({ pageName, title }: { pageName: string; title: string }) => {
	return (
		<div className="breadcrumb-component">
			<section className="breadcrumb-container container-fluid">
				<div className="breadcrumb-page-title-content-body flex-sb-mobile">
					<div className="breadcrumb-page-container-title-content-body gap-2">
						<h1 className="page-title">{title}</h1>
						<div className="breadcrumb-row gap-3">
							<div className="base-home-container">
								<div className="home-icon-container">
									<NavLink
										to="/"
										className="home-breadcrumb-link"
									>
										<HiHome className="home-icon" />
									</NavLink>
								</div>
								<div className="home-label-container">
									<NavLink
										to="/"
										className="home-breadcrumb-link"
									>
										{" "}
										<span className="home-label">
											Accueil
										</span>
									</NavLink>
								</div>
								<div className="arrow-icon-container">
									<MdKeyboardArrowRight className="arrow-icon" />
								</div>
								<div className="home-label-container">
									<NavLink
										to="/news"
										className="home-breadcrumb-link"
									>
										{" "}
										<span className="home-label">News</span>
									</NavLink>
								</div>
								<div className="arrow-icon-container">
									<MdKeyboardArrowRight className="arrow-icon" />
								</div>
							</div>
							<div className="second-level-breadcrumb-container">
								<span className="breadcrumb-second-level-label">
									{" "}
									{pageName}{" "}
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default BreadCrumbNews;
