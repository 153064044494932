import React, { useEffect, useState } from "react";
import { useStep } from "react-hooks-helper";
import "./ListeLocataires.css";
import { useStepType } from "../../../../utils/type";
import GestionServive from "./ListeLocatairesTabs/GestionServive";
import ListeLitiges from "./ListeLocatairesTabs/ListeLitiges";
import { NavLink, useSearchParams } from "react-router-dom";
import { AiFillPlusSquare } from "react-icons/ai";
import Locataires from "./ListeLocatairesTabs/Locataires";
import AjoutLitigesModale from "../../../modal/agence/AjoutLitigesModale";
import { useGetAgencesQuery } from "../../../../utils/api/agence/agence.api";
import { useAppSelector } from "../../../../redux/hooks";
import { isAgence, isAgenceJuriste } from "../../../../utils/Utils";
import LocataireEnCoursLitige from "./ListeLocatairesTabs/LocataireEnCoursLitige";
import { useCompareNpPropieteAndMaxOffreQuery } from "../../../../utils/api/offer/expireataabonnement.api";
import ChangeOfferModal from "../OptionAgence/OptionsStep/ChangeOfferModal";
import { showModal } from "../../../../utils/Utils";
import Swal from "sweetalert2";
import { Button } from "reactstrap";
import LocataireArchives from "./ListeLocatairesTabs/LocataireArchives";
import LocataireExistantModal from "../../../modal/agence/LocataireExistantModal";

const callChangeOffreModal = (data: any, user: any) => {
  if (data?.max_offre != data?.nb_propriete) {
    Swal.fire({
      icon: "error",
      title: "Aucune propriété disponible",
      text:
        user?.user_type === "agence"
          ? "Il n'y a pas de propriété disponible pour ce locataire."
          : "Veuillez rapprocher de l'agence",
      showConfirmButton: false,
      timer: 5000,
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Modification de l'abonnement nécessaire",
      text:
        user?.user_type === "agence"
          ? "Veuillez modifier votre abonnement pour pouvoir effectuer cette opération."
          : "Veuillez rapprocher de l'agence pour pouvoir effectuer cette opération.",
      showConfirmButton: false,
      timer: 5000,
    });
    if (user?.user_type === "agence") {
      showModal("changeOfferModal");
    }
  }
};

function ListeLocataires() {
  const { user } = useAppSelector((s) => s?.user);
  const userType = user?.user_type;
  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));
  // console.log("is_Agence", is_Agence)
  const steps = [
    { id: "locataires", Component: Locataires },
    // { id: "gestion-service", Component: GestionServive },
    { id: "locataire-archive", Component: LocataireArchives },
    { id: "litiges", Component: ListeLitiges },
    { id: "locataire-litiges", Component: LocataireEnCoursLitige },
  ];

  const tabs = [
    userType !== "juriste" ? "Locataires actifs" : null,
    // (userType !== 'juriste') ? "Gestion des services" : null,
    userType !== "juriste" ? "Locataires archivés" : null,
    userType === "juriste" || is_Agence ? "Contentieux" : "",
    userType === "juriste" && "Locataires en cours de contentieux",
  ];
  const { data, error, refetch } = useCompareNpPropieteAndMaxOffreQuery(
    user?.agence_id ? user?.agence_id.slug : user?.slug
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    refetch();
    handleActionCompleted();
  }, [refreshKey, refetch]);

  const handleActionCompleted = () => {
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({
    initialStep: !isAgenceJuriste(user) ? 0 : 2,
    steps,
  });
  const { Component } = step;

  const { data: ListeAgence } = useGetAgencesQuery({});
  const AgenceSlug = ListeAgence?.results
    ?.filter((item) => item?.id === user?.agence_id)
    ?.map((itm) => itm?.slug);

  const props = { searchParams, setSearchParams, AgenceSlug, is_Agence };

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            {isAgenceJuriste(user) ? "Contentieux" : "Locataires"}
          </h4>
          <div className="container-btn-add">
            {data?.can_add_locataire && (
              <>
                {index === 0 && userType !== "juriste" && (
                  <div className="dropdown dis-flex justify-content-end">
                    <button
                      className="btn btn-see-more dropdown-toggle btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="user-acount-dashboard-header-display g-2">
                        <AiFillPlusSquare style={{ fontSize: 22 }} />
                        <span className="ps-2">Ajouter</span>
                      </div>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-custom"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <NavLink
                        to="/agence/ajouter-locataire"
                        className="btn btn-see-more"
                      >
                        <span className="" style={{ fontSize: 14 }}>
                          Nouveau locataire
                        </span>
                      </NavLink>
                      <button
                        className="btn btn-see-more"
                        data-bs-toggle="modal"
                        data-bs-target="#locataireExistantModal"
                      >
                        <span className="" style={{ fontSize: 14 }}>
                          Locataire existant
                        </span>
                      </button>
                    </div>
                    <LocataireExistantModal />
                  </div>
                )}
              </>
            )}
            {!data?.can_add_locataire && (
              <>
                {index === 0 && userType !== "juriste" && (
                  <Button
                    data-bs-toggle="modal"
                    // data-bs-target="#AjouterProprietesModald"
                    className="btn btn-see-more"
                    onClick={() => callChangeOffreModal(data, user)}
                  >
                    <AiFillPlusSquare style={{ fontSize: 22 }} />
                    <span className="ps-2">Ajouter un locataire</span>
                  </Button>
                )}
              </>
            )}
          </div>
          {index === 2 && !isAgenceJuriste(user) && (
            <>
              <button
                className="btn btn-see-more dropdown-toggle btn"
                type="button"
                data-bs-target="#AjouterLitigesModal"
                data-bs-toggle="modal"
              >
                <div className="user-acount-dashboard-header-display g-2">
                  <AiFillPlusSquare style={{ fontSize: 22 }} />
                  <span className="ps-2">Déclarer un contentieux</span>
                </div>
              </button>
              <div
                className="modal fade"
                id="AjouterLitigesModal"
                aria-labelledby="AjouterLitigesModalLabel"
                data-bs-backdrop="static"
                aria-hidden="true"
              >
                <AjoutLitigesModale />
              </div>
            </>
          )}
        </div>
        <div className="kanimmo-tabs-locataire-container">
          <ul className="tabs-container pt-5 tabs-container-agence">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component {...props} />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="changeOfferModal"
        aria-labelledby="changeOfferModalLabel"
        aria-hidden="true"
      >
        <ChangeOfferModal
          type={user?.user_type === "agence" ? "agence" : "proprietaire"}
        />
      </div>
    </div>
  );
}

export default ListeLocataires;
