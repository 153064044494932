import React from "react";
import { ICompteRendu } from "../../../utils/api/comteRendu/conterendu.type";
import { ApiBaseUrl } from "../../../utils/http";
import { getFile, get_url_extension } from "../../../utils/Utils";
import { GoogleDocs } from "../agence/DocumentLitigeModal";
import moment from "moment";


function DetailCompteRenduModal({
  item
}: {
  item: ICompteRendu;
}) 
 {
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container-form custom-container-form">
            <div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex flex-column align-items-start mb-3">
                    <div className="custom-envoie-note-date">
                      Compte rendu de geston{" "}
                      <span className="agence-detail-facture-titre">
                        {moment(item?.created_at).format("LL")}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn-close custom-btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <hr />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <div>
                      {/* {get_url_extension(item?.pdf) === "pdf" ? (
                        <iframe
                          src={ApiBaseUrl + item?.pdf}
                          frameBorder="0"
                          width={`100%`}
                          height={500}
                          allowFullScreen
                          seamless
                          title="doc"
                        ></iframe>
                      ) : (
                        <GoogleDocs
                        file={ApiBaseUrl + item?.pdf}
                          height={"700px"}
                        />
                      )} */}
                      <iframe
              src={getFile(item?.pdf)}
              frameBorder="0"
              width={`100%`}
              height={500}
              allowFullScreen
              seamless
              title="doc"
            ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailCompteRenduModal;
