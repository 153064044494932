import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useGetAnnoncesByLocataireQuery } from "../../../../../../utils/api/annonces/annonce.api";
import { IAnnonce } from "../../../../../../utils/api/annonces/annonce.type";
import { createMarkup, getImage } from "../../../../../../utils/Utils";
import { AlertInfo } from "../../../../../common/Alert";
import Pagination from "../../../../../common/Pagination";
import EnvoieSuppressionNoteLocataireModal from "../../../../../modal/agence/EnvoieSuppressionNoteLocataireModal";
import NoteSkeleton from "../../../../../Skeleton/NoteSkeleton";

function NotesAnnoncesLocataire() {
  const {
    annonces,
    handleFilterDataByType,
    item,
    setItem,
    data,
    isLoading,
    perPage,
    setPage,
    page,
    cancelFilter,
    type,
  } = useAnnonces();

// console.log("annonces", annonces);
  return (
    <div className="bg-white py-3 px-1">
      <div className="locataire-requete-filtre-container my-4">
        <div className="custom-btn-filter-container my-4">
          <button
            className={`custom-btn-filter ${type === null && "active"}`}
            onClick={cancelFilter}
          >
            Tout
          </button>
          <button
            className={`custom-btn-filter ${type === "note" && "active"}`}
            onClick={() => handleFilterDataByType("note")}
          >
            <div className="custom-card-type-note"></div>
            Note
          </button>
          <button
            className={`custom-btn-filter ${type === "annonce" && "active"}`}
            onClick={() => handleFilterDataByType("annonce")}
          >
            <div className="custom-card-type-annonce"></div>
            Annonce
          </button>
        </div>
        <div className="row">
          {!!isLoading && <NoteSkeleton />}
          {!isLoading &&
            (annonces && annonces?.length > 0 ? (
              annonces?.map((item: IAnnonce) => (
                <div className="col-md-3 mb-3 d-flex" key={item?.id}>
                  <div style={{ position: "relative", width: "100%" }}>
                    <div
                      className="card custom-card-container"
                      data-bs-toggle="modal"
                      data-bs-target="#EnvoieSuppressionNoteModal"
                      onClick={() => setItem(item)}
                    >
                      <div className="custom-card-date">
                        {moment(item?.created_at).format("LL")}
                      </div>
                      <div className="custom-card-titre-container mb-3">
                        {item?.data?.type === "note" ? (
                          <div className="custom-card-type-note"></div>
                        ) : (
                          <div className="custom-card-type-annonce"></div>
                        )}
                        <div className="custom-card-titre">
                          {item?.data?.titre}
                        </div>
                      </div>
                      {item?.data?.type === "note" && item?.data?.content && (
                        <div
                          className="mb-3 custom-card-description custom-envoie-note-description"
                          dangerouslySetInnerHTML={createMarkup(
                            item?.data?.content
                          )}
                        />
                      )}
                      {item?.data?.type === "annonce" && item?.data && (
                        <img
                          src={getImage(item?.data?.image)}
                          alt="image annonce"
                          className="custom-img-annonce"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <AlertInfo message="Aucune élement trouvé" />
            ))}
          <div
            className="modal fade"
            id="EnvoieSuppressionNoteModal"
            aria-labelledby="EnvoieSuppressionNoteModalLabel"
            aria-hidden="true"
          >
            <EnvoieSuppressionNoteLocataireModal
              item={item}
              modalId={`EnvoieSuppressionNoteModal${item?.id}`}
            />
          </div>
        </div>
        {annonces && annonces?.length > perPage && (
          <div className="flex-r">
            <Pagination
              page={page}
              total={data?.count}
              perPage={perPage}
              onPageChange={(page: number) => setPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default NotesAnnoncesLocataire;

const useAnnonces = () => {
  const [item, setItem] = useState<IAnnonce>();
  const [type, setType] = useState<IAnnonce["type"] | null>(null);
  const [annonces, setAnnonces] = useState<IAnnonce[]>();
  const [page, setPage] = useState(1);
  const perPage = 12;

  const { user } = useAppSelector((s) => s?.user);
  const { data = { results: [] }, isLoading } = useGetAnnoncesByLocataireQuery({
    slug: user?.slug,
    page,
    limit: perPage,
  });
  useEffect(() => {
    if (data?.results) {
      setAnnonces(data?.results);
    }
  }, [data?.results]);
  const handleFilterDataByType = (type: IAnnonce["type"] | null) => {
    const filteredAnnonces = data?.results?.filter(
      (item: IAnnonce) => item?.data?.type === type
    );
    setAnnonces(filteredAnnonces);
    setType(type);
  };
  const cancelFilter = () => {
    setAnnonces(data?.results);
    setType(null);
  };

  return {
    item,
    setItem,
    annonces,
    handleFilterDataByType,
    setPage,
    isLoading,
    data,
    perPage,
    page,
    cancelFilter,
    type,
  };
};
