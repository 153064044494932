import React, { useState } from "react";
import "./Login.css";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/appImages/kanimmoLogoNoir.png";
import useLoginForm from "./useAuthForm/useLoginForm";
import ErrorMessage from "../common/ErrorMessage";
import { BtnSubmit } from "../common/Button";
import { AiFillEyeInvisible } from "react-icons/ai";
import { RiEyeLine } from "react-icons/ri";
import RegisterModal from "../modal/user/RegisterModal";

interface FormProps {
  register: any;
  errors: any;
  onSubmit: any;
  isLoading: any;
  minutes: any;
  secondes: any;
  disabled: boolean;
}

export const LoginForm: React.FC<FormProps> = ({
  onSubmit,
  errors,
  register,
  isLoading,
  minutes,
  secondes,
  disabled,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  return (
    <form id="auth-form" onSubmit={onSubmit}>
      <div className="row auth-form-row">
        <div className="col-md-12 auth-input-col mb-3">
          <div className="auth-form-group">
            <label
              htmlFor="email-or-username"
              className="form-label form-label-login"
            >
              Email ou Nom d’utilisateur
            </label>
            <input
              type={`text`}
              className="form-control auth-form-control"
              id="email-or-username"
              placeholder="exemple@gmail.com"
              {...register("email")}
              data-testid="emailId"
            />
            {errors?.email && <ErrorMessage message={errors?.email?.message} />}
          </div>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <Link to={"/mot-de-passe-oublie"} className="forget-password-link">
            Mot de passe oublié?
          </Link>
        </div>
        <div className="col-md-12 auth-input-col mb-2">
          <div className="auth-form-group  position-relative ">
            <label htmlFor="password" className="form-label form-label-login">
              Mot de passe
            </label>
            <input
              type={`${showPassword ? "text" : "password"}`}
              className="form-control auth-form-control"
              id="password"
              {...register("password")}
              data-testid="passwordId"
              placeholder="**************"
            />

            <span
              className="span-eye-password-log"
              onClick={toggleShowPassword}
            >
              {!showPassword ? <RiEyeLine /> : <AiFillEyeInvisible />}
            </span>
          </div>
          {errors?.password && (
            <ErrorMessage message={errors?.password?.message} />
          )}
        </div>

        <div className="col-md-12 auth-input-col mb-2">
          <div className="checkbox py-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault."
            />
            <label
              className="form-check-label form-label-login ms-2"
              htmlFor="flexCheckDefault."
            >
              Se souvenir de moi
            </label>
          </div>
        </div>
        {parseInt(minutes) <= 0 && parseInt(secondes) <= 0 ? null : (
          <div className="col-12">
            <ErrorMessage
              message={
                <span>
                  Veuillez regénèrer un nouveau mot de passse en cliquant sur
                  &nbsp;
                  <NavLink
                    to={"/mot-de-passe-oublie"}
                    className="forget-password-link"
                  >
                    Mot de passe oublié?
                  </NavLink>
                  &nbsp; ou rééssayer dans {minutes}:{secondes}
                </span>
              }
            />
          </div>
        )}

        <div className="col-md-6 offset-md-3 auth-submit-btn-container pt-4">
          <BtnSubmit
            label="Connexion"
            disabled={
              disabled || parseInt(minutes) > 0 || parseInt(secondes) > 0
            }
            isLoading={isLoading}
          />
        </div>
      </div>
    </form>
  );
};
const Login = () => {
  const { register, onSubmit, errors, isLoading, minutes, seconds, disabled } =
    useLoginForm();
  const [show, setShow] = React.useState(false);

  return (
    <div className="auth-component">
      <div className="nav-empty-header-login">
        <NavLink className="navbar-brand" to="/">
          <img src={Logo} alt="Logo" className="logo-app"
          style={{ maxWidth: '200px', height: '170px' }}

           />
        </NavLink>
      </div>
      <div className="fixed-login-component">
        <div className="auth-container">
          <div className="auth-row row">
            <div className="col-md-6 offset-md-3 auth-col auth-right-side-col">
              <div className="auth-right-side-container">
                <div className="auth-form-container">
                  <h1 className="auth-form-title">Connectez-vous </h1>
                  <div>
                    <LoginForm
                      register={register}
                      onSubmit={onSubmit}
                      errors={errors}
                      isLoading={isLoading}
                      minutes={minutes}
                      secondes={seconds}
                      disabled={disabled}
                    />
                  </div>
                  <div className="flex-c align-items-center my-4 text-right-auth">
                    <p className="text-inscription mb-0">Nouveau ?</p>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#RegisterModal"
                      className="btn-inscription-color btn"
                    >
                      Creer un compte
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="RegisterModal"
        aria-labelledby="RegisterModalLabel"
        aria-hidden="true"
      >
        <RegisterModal />
      </div>
    </div>
  );
};

export default Login;
