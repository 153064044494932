import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiOutlineEye } from "react-icons/ai";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetFactureByProprietaireQuery } from "../../../../../utils/api/facture/facture.api";
import {
  IFacture,
  StatusFacture,
} from "../../../../../utils/api/facture/facture.type";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { formattedDate } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import { CustomPagination } from "../../../../common/CustomPagination";
import ChangementStatusFactureModal from "../../../../modal/agence/ChangementStatusFactureModal";
import DetailsfactureModal from "../../../../modal/agence/DetailsfactureModal";
import FactureAgenceSkeleton from "../../../agence/Administratif/Facture/FactureAgenceSkeleton";
import { DeleteFacture } from "../../../agence/Administratif/Facture/FactureAgenceTable";

function FacturesTable({ name }: { name: string }) {
  const { user } = useAppSelector((s) => s?.user);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [] }, isLoading } =
    useGetFactureByProprietaireQuery({
      name,
      page: page,
      limit: perPage,
      slug: user?.proprietaire_id ? user?.proprietaire_id?.slug : user?.slug,
    });
  // console.log({ data });
  const [item, setItem] = useState<IFacture>();

  const actionFormatter: any = (cell: any, row: IFacture) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <button
          className="btn btn-action-modal-icon with-tooltip"
          data-tooltip-content="Voir"
          data-bs-toggle="modal"
          data-bs-target={`#DetailsFactureModal${row?.slug}`}
        >
          <AiOutlineEye />
        </button>

        <DeleteFacture item={row} />
        <div
          className="modal fade"
          id={`DetailsFactureModal${row?.slug}`}
          aria-labelledby={`DetailsFactureModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <DetailsfactureModal item={row} />
        </div>
      </div>
    );
  };
  const statutFormatter: any = (cell: any, row: IFacture) => {
    return (
      <>
        <div
          role="button"
          className="table-actions-btn-container-commandes gap-2 d-flex"
          data-bs-toggle="modal"
          data-bs-target="#ChangementStatusFactureModal"
        >
          {cell === StatusFacture.caution ? (
            <span className="custom-statut-caution text-capitalize">
              {"Caution"}
            </span>
          ) : cell === StatusFacture.commission ? (
            <span className="custom-statut-commission text-capitalize">
              {"Commission"}
            </span>
          ) : cell === StatusFacture.mensualite ? (
            <span className="custom-statut-loyer text-capitalize">
              {"Mensualité"}
            </span>
          ):  (
            <span className="custom-statut-loyer text-capitalize">
              {row?.autres}
            </span>
          )}
        </div>
        <ChangementStatusFactureModal item={row} />
      </>
    );
  };

  const columns = [
    {
      dataField: "numero",
      text: "N°",
      formatter: (cell: any, row: any, rowIndex: number) => `#${rowIndex + 1}`,
    },
    {
      dataField: "created_at",
      text: "Date",
      style: { textAlign: "left" },
      formatter: (cell: string) => formattedDate(cell),
    },
    {
      dataField: "locataire",
      text: "Client",
      style: { textAlign: "left" },
      formatter: (cell: ILocataire) => cell?.prenom + " " + cell?.nom,
    },

    {
      dataField: "propriete",
      text: "Location",
      style: { textAlign: "left" },
      formatter: (cell: IPropriete) => cell?.nom,
    },
    {
      dataField: "statut",
      text: "Statut",
      style: { textAlign: "left" },
      formatter: (cell: any, row: IFacture) => statutFormatter(cell, row),
    },
    {
      dataField: "montant",
      text: "Facture",
      style: { textAlign: "left" },
      formatter: (cell: string) => cell + " FCFA",
    },
    {
      dataField: "actions",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: IFacture) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <FactureAgenceSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune facture trouvéé" />
            )}
          />
          {data?.count && data?.count > 0 && (
            <CustomPagination
              nbPages={1}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          )}
        </>
      )}
    </>
  );
}

export default FacturesTable;
