import { Color } from "./theme";
type Authorization = {
	field: FieldType;
	label: string;
	path: string;
};
export const REQUETE_STATUT_SELECT = [
	{ value: "non_regler", label: "Non réglée" },
	{ value: "encours", label: "En cours" },
	{ value: "regler", label: "Réglée" },
];
export const REQUETE_STATUT = {
	non_regler: {
		label: REQUETE_STATUT_SELECT[0].label,
		color: Color.themeColor,
	},
	encours: {
		label: REQUETE_STATUT_SELECT[1].label,
		color: Color.jaune,
	},
	regler: {
		label: REQUETE_STATUT_SELECT[2].label,
		color: Color.success,
	},
};

export type FieldType =
	| "dashboard"
	| "options"
	| "administratif"
	| "message"
	| "client"
	| "new"
	| "recette"
	| "faq"
	| "lead"
	| "bien"
	| "locatair"
	| "location"
	| "paiement"
	| "parametre"
	| "type_requete"
	| "probleme";
export const Authorisations: Authorization[] = [
	{
		field: "dashboard",
		label: "Dashboard",
		path: "/admin/dashboard",
	},
	{
		field: "message",
		label: "Messages",
		path: "/admin/messages",
	},
	{
		field: "client",
		label: "Clients",
		path: "/admin/mes-clients",
	},
	{
		field: "new",
		label: "News",
		path: "/admin/news",
	},
	{
		field: "recette",
		label: "Récettes",
		path: "/admin/recettes",
	},
	{
		field: "administratif",
		label: "Administratif",
		path: "/admin/administratif",
	},
	{
		field: "lead",
		label: "Lead",
		path: "/admin/lead",
	},
	{
		field: "options",
		label: "Options",
		path: "/admin/options",
	},
	{
		field: "faq",
		label: "FAQ",
		path: "/admin/faq",
	},
	{
		field: "probleme",
		label: "Problemes",
		path: "/admin/problemes",
	},
	{
		field: "type_requete",
		label: "Types requêtes",
		path: "/admin/types-requetes",
	},
];


export const ApiKey = "C6C23763-3DFA-4A7A-BC02-404D88399923";
