import React from 'react'
import CategorieEntreeTableComptable from '../../../../tables/Comptables/Categorie/CategorieEntreeTableComptable';

function CategorieEntree() {
    return (
		<div className="bg-white p-3">
			<CategorieEntreeTableComptable />
		</div>
	);
}

export default CategorieEntree