import React, { useEffect, useState } from "react";
import { BsCameraFill } from "react-icons/bs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import ChangePasswordModal from "../../../../modal/admin/ChangePasswordModal";
import UseEditUserFom from "../../../Agence/OptionAgence/requestForm/UseEditUserFom";
import Swal from "sweetalert2";
import { onSetUser } from "../../../../../redux/slice/User.slice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useAddOrEditUserMutation } from "../../../../../utils/api/user/user.api";
import { Color } from "../../../../../utils/theme";
import { FormError } from "../../../../common/CustomInputCheckbox";
import GoogleInput from "../../../../common/GoogleInput";
import { getAvatar, isProprietaireAgence, isProprietaireOwner } from "../../../../../utils/Utils";
import { BtnSubmit } from "../../../../common/Button";
import AccountInfo from "../../../Agence/OptionAgence/OptionsStep/AccountInfo";
import MyOfferItem from "../../../Agence/OptionAgence/OptionsStep/MyOfferItem";

function ParametrePQNGPSB() {
  const user = useAppSelector((s) => s?.user?.user);
  // console.log("user", user);
  const [avatar, setAvatar] = useState<any>(null);
  const { register, errors, onSubmit, address, onChangeAddress, isLoading } =
    UseEditUserFom("account");
  const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
    useAddOrEditUserMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (done) {
      dispatch(onSetUser(data));
      setAvatar(null);
      Swal.fire({
        icon: "success",
        title: "Photo de profil modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [done, isError]);

  const [values, setValues] = React.useState({
    password: user?.password,
    showPassword: false,
  });

  useEffect(() => {
    if (done) {
      dispatch(onSetUser(data));
      setAvatar(null);
      Swal.fire({
        icon: "success",
        title: "Photo de profil modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [done, isError]);

  const changeImage = (e: any) => {
    const file = e.target.files[0];
    setAvatar(URL.createObjectURL(file));
    let fd = new FormData();
    fd.append("avatar", file);
    sendData({ slug: user?.slug, userType: user?.user_type, data: fd });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const handlePasswordChange =
    (prop: string) => (event: { target: { value: any } }) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Paramètres
          </h4>
          {/* <button
            className="btn kanimmo-dash-locataire-parametre-header-button"
            // title="Faire une requête"
            // data-bs-toggle="modal"
            // data-bs-target="#AjoutRequetesModal"
          >
            <FiEdit className="kanimmo-dash-locataire-parametre-header-icon" />{" "}
            Editer le profil
          </button> */}
          {/* <div className="modal fade" id="AjoutRequetesModal" aria-labelledby="AjoutRequetesModalLabel" aria-hidden="true">
                    <AjoutRequetesModal  />
                </div> */}
        </div>
        <div className="kanimmo-dash-locataire-param-main-page-container py-4">
          <section className="mb-3">
            {/* <div className='kanimmo-dash-locataire-param-title'>Informations générales</div> */}
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-center align-items-center py-4 mb-4 kanimmo-user-avatar-container">
                  <label htmlFor="input-test" className="input-testt">
                    <img
                      src={avatar || getAvatar(user?.avatar)}
                      alt="user avatar"
                      className="kanimmo-dash-locataire-param-img-avatar"
                    />
                    <div className="kanimmo-user-avatar-edit-hover">
                      <BsCameraFill className="edit-avatar-camera-icon" />
                    </div>
                  </label>
                  <input
                    type="file"
                    className="input-add-avatar"
                    id="input-test"
                    onChange={(e) => changeImage(e)}
                  />
                </div>
              </div>
            </div>
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="nom"
                      className="form-label form-label-modal-custom"
                    >
                      Nom
                    </label>
                    <input
                      type="text"
                      placeholder="Nom"
                      className="form-control form-control-modal-custom form-control-params-custom"
                      id="nom"
                      {...register("nom")}
                    />
                    <FormError error={errors?.nom?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="prenom"
                      className="form-label form-label-modal-custom"
                    >
                      Prénom
                    </label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      className="form-control form-control-modal-custom form-control-params-custom"
                      id="prenom"
                      {...register("prenom")}
                    />
                    <FormError error={errors?.prenom?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className="form-label form-label-modal-custom"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="Email"
                      className="form-control form-control-modal-custom form-control-params-custom"
                      id="email"
                      {...register("email")}
                    />
                    <FormError error={errors?.email?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="tel"
                      className="form-label form-label-modal-custom"
                    >
                      Téléphone
                    </label>
                    <input
                      type="text"
                      placeholder="Email"
                      className="form-control form-control-modal-custom form-control-params-custom"
                      id="tel"
                      {...register("telephone")}
                    />
                    <FormError error={errors?.telephone?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="adress"
                      className="form-label form-label-modal-custom"
                    >
                      Adress
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom form-control-params-custom"
                      value={address}
                      onChange={onChangeAddress}
                    />
                    <FormError error={errors?.adresse?.message} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="boite"
                      className="form-label form-label-modal-custom"
                    >
                      Code postale
                    </label>
                    <input
                      type="text"
                      placeholder="Code postale"
                      className="form-control form-control-modal-custom form-control-params-custom"
                      id="boite"
                      {...register("code_postal")}
                    />
                    <FormError error={errors?.code_postal?.message} />
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row my-4">
                <div className="col-md-12">
                  <div className="d-flex gap-3 justify-content-end">
                    <BtnSubmit label="Enregistrer" isLoading={isLoading} />
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>

        {/* <div className="kanimmo-dash-locataire-param-main-page-container py-4">
          <div className="d-flex justify-content-between">
            <div className="kanimmo-dash-locataire-param-password-title">
              Mot de passe
            </div>
            <button
              className="btn kanimmo-dash-locataire-parametre-header-button"
              data-bs-toggle="modal"
              data-bs-target="#ChangePasswordModal"
            >
              <FiEdit className="kanimmo-dash-locataire-parametre-header-icon" />{" "}
              Changer de mot de passe
            </button>
          </div>

          <ChangePasswordModal />
        </div> */}
      </div>
      {(user?.user_type === "agence" || isProprietaireOwner(user)) &&
        user?.offre?.id && <MyOfferItem />}
      {isProprietaireAgence(user) && <AccountInfo />}
      <div className="kanimmo-dash-locataire-param-main-page-container py-4 mb-3">
        <div className="kanimmo-dash-locataire-param-title mb-4">
          Modifier votre mot de passe
        </div>
        <div className="d-flex justify-content-between">
          <div className="kanimmo-dash-locataire-param-password-title">
            Mot de passe
          </div>
          <button
            className="btn  auth-submit-btn"
            data-bs-toggle="modal"
            data-bs-target="#ChangePasswordModal"
          >
            <FiEdit /> Changer de mot de passe
          </button>
        </div>
        <ChangePasswordModal />
        {/* <div className="row">
          <div className="col-md-6">
            <div className="input-group mb-3 kanimmo-custom-input-groupe">
              <input
                type={values.showPassword ? "text" : "password"}
                onChange={handlePasswordChange("password")}
                value={values.password}
                className="form-control form-control-modal-custom form-control-params-custom"
                placeholder="*******"
                id="password"
              />
              <span
                className="input-group-text kanimmo-custom-showHideIcon"
                id="basic-addon2"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? (
                  <FaEye className="showPasswordIcon" />
                ) : (
                  <FaEyeSlash className="hidePasswordIcon" />
                )}
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ParametrePQNGPSB;
