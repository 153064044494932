import React, { useEffect, useState, useRef } from "react";
import { AiFillPlusSquare } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetAgencesQuery } from "../../../../../utils/api/agence/agence.api";
import { useLocataireFromLocation } from "../../../../../utils/api/locataire/locataire.api";
import { useGetProprietesByAgenceQuery } from "../../../../../utils/api/propriete/propriete.api";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { ApiBaseUrl } from "../../../../../utils/http";
import { getAvatar, isAgence } from "../../../../../utils/Utils";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { getRoute } from "../../ProprietesAgence/ProprieteItem";

function InfosLocataire() {
  const [itemState] = useLocataireFromLocation();

  // console.log("itemState", itemState);

  const { user } = useAppSelector((s) => s?.user);
  const item = itemState?.propriete;
  const user_connect = user?.user_type   === "gerant" ? user?.proprietaire_id?.id : user?.id

  console.log( user_connect );
  

  return (
    // <div className="landing-page-container">
    //   <div className="container-fluid px-0">
    <div className="card-historie-paie-tab-container p-3">
      <div className="row">
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="col-md-12">
              <div className="col-left-detail-proprietaire">
                <div className="content-title-detail-proprietaire d-flex justify-content-end">
                  <button className="btn btn-see-more">
                    {itemState?.propriete?.proprietaire === user_connect && <div className="user-acount-dashboard-header-display g-2">
                      <AiFillPlusSquare style={{ fontSize: 22 }} />
                      <NavLink
                        to={
                          isAgence(user)
                            ? `/agence/modifier-locataire`
                            : `/proprietaire/gerer-par-le-proprietaire/locataires/modifier-locataire/${itemState?.slug}`
                        }
                        state={itemState}
                        className="ps-2 btn btn-action-modal-icon"
                      >
                        Modifier locataire
                      </NavLink>
                    </div> }
                    {itemState?.propriete?.agence === user_connect && <div className="user-acount-dashboard-header-display g-2">
                      <AiFillPlusSquare style={{ fontSize: 22 }} />
                      <NavLink
                        to={
                          isAgence(user)
                            ? `/agence/modifier-locataire`
                            : `/proprietaire/gerer-par-le-proprietaire/locataires/modifier-locataire/${itemState?.slug}`
                        }
                        state={itemState}
                        className="ps-2 btn btn-action-modal-icon"
                      >
                        Modifier locataire
                      </NavLink>
                    </div> }
                    
                  </button>
                </div>
                <div className="text-center pt-3">
                  <div className="content-pp-proprietaire pb-2">
                    <img
                      src={getAvatar(itemState?.avatar)}
                      alt="Avatar"
                      className="pp-proprietaire"
                    />
                  </div>
                  <p className="title-detail-proprietaire">
                    {itemState?.prenom + " " + itemState?.nom}
                  </p>
                  <p className="title-detail-proprietaire">
                    {itemState?.propriete?.adresse}
                  </p>
                </div>
                <div className="content-info-proprietaire row ">
                  <div className="d-flex flex-column flex-md-row ">
                    <div className="content-info-prprietaire-item col-12 me-md-1 col-md-6 mb-2">
                      <MdOutlineMailOutline />
                      <span className="ps-3">
                        <a
                          className="no-link"
                          href={`mailto:${itemState?.email}`}
                        >
                          {itemState?.email}
                        </a>
                      </span>
                    </div>
                    <div className="content-info-prprietaire-item col-12 ms-md-1 col-md-6 mb-2">
                      <IoCallOutline />
                      <span className="ps-3">
                        <a
                          className="no-link"
                          href={`tel:${itemState?.telephone}`}
                        >
                          {itemState?.telephone}
                        </a>
                      </span>
                    </div>
                  </div>
                  {itemState?.propriete && (
                    <div className="content-info-prprietaire-item mb-2">
                      <IoHomeOutline />
                      <Link
                        to={getRoute(item, user)}
                        className="btn fw-semibold btn-action-modal-icon p-0 ps-3"
                        state={item}
                      >
                        {itemState?.propriete === null
                          ? "Locataire archivé"
                          : itemState?.propriete?.nom}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfosLocataire;
