import React from 'react'
import { useSearchParams } from 'react-router-dom';
import HistoriquePaiementTable from '../../../../../tables/Locataire/Administratif/HistoriquePaiement/HistoriquePaiementTable'

function HistoriquePaiement() {
    const [searchParams, setSearchParams] = useSearchParams();
	const date = searchParams.get("date");
	const handleFilterByDate = (e: React.FormEvent<HTMLInputElement>): void => {
		if (e.currentTarget.value) {
			setSearchParams({
				date: e.currentTarget.value,
			});
		} else {
			setSearchParams({});
		}
	};
  return (
    <div className="bg-white p-3">
        <div className='locataire-requete-filtre-container my-4'>
            <form className="row g-3 locataire-requete-filtre-form">
                <div className="col-auto">
                    <label
                        htmlFor="filtreLogement"
                        className="locataire-requete-filtre-label"
                    >
                        Filtrer
                    </label>
                </div>
                <div className="col-auto">
                    <input
                        type="date"
                        className="form-control locataire-requete-filtre-input"
                        id="filtreLogement"
                        placeholder="Entrer une date"
                        onChange={handleFilterByDate}
                    />
                </div>
            </form>
        </div>
        <div>
            <HistoriquePaiementTable date={date} />
        </div>
    </div>
  )
}

export default HistoriquePaiement