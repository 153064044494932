import React from 'react'
import {
  ProprieteFormData,
} from "../../../../../utils/api/propriete/propriete.type";
import { Input } from "../../../../common/Input";
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form";

type ContentProps = {
  register: UseFormRegister<ProprieteFormData>;
  errors: any;
};
function ProprieteContent({
  register,
  errors
}: ContentProps) {
  return (
    <>
      {/* ==================== CLIMATISEUR ==================== */}
      <div className="col-md-6">
        <Input
          type="text"
          label="Nombre de climatisateur"
          id="chambre"
          placeholder="Nombre de climatisateur"
          error={errors.nbre_climatiseurs}
          {...register("nbre_climatiseurs")}
          min={0}
        />
      </div>

      {/* ==================== CHAMBRE ==================== */}
      <div className="col-md-6">
        <Input
          type="number"
          label="Nombre de chambres"
          id="chambre"
          placeholder="Nombre de chambre"
          required
          error={errors.nbre_chambres}
          {...register("nbre_chambres")}
          min={0}
        />
      </div>

      {/* ==================== CUISINE ==================== */}
      <div className="col-md-6">
        <Input
          type="number"
          label="Nombre de Cuisine"
          id="cuisine"
          placeholder="Nombre de cuisine"
          required
          error={errors.nbre_cuisines}
          {...register("nbre_cuisines")}
          min={0}
        />
      </div>
      {/* ==================== SDB ==================== */}
      <div className="col-md-6">
        <Input
          type="number"
          label="Nombre de salle de bain"
          id="sdb"
          placeholder="Nombre de salle de bain"
          required
          error={errors.nbre_salle_de_bains}
          {...register("nbre_salle_de_bains")}
          min={0}
        />
      </div>
      {/* ==================== SALON ==================== */}
      <div className="col-md-6">
        <Input
          type="number"
          label="Nombre de salon"
          id="salons"
          placeholder="Nombre de salon"
          required
          error={errors.nbre_salon}
          {...register("nbre_salon")}
          min={0}
        />
      </div>
    </>
  );
}

export default ProprieteContent