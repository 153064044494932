import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../redux/hooks";
import { useAddOrUpdateProblemeMutation } from "../../../utils/api/probleme/probleme.api";
import { TProblemeForm } from "../../../utils/api/probleme/probleme.type";
import { Color } from "../../../utils/theme";
import { cleannerError, onHide } from "../../../utils/Utils";

const useSignalerProblemeForm = () => {
	const validationSchema = yup.object().shape({
		prenom: yup.string().required().label("Nom"),
		email: yup.string().required().label("Email"),
		nom: yup.string().required().label("Prenom"),
		autre: yup.string().label("Autre"),
		message: yup.string().required().label("Message"),
		fonction: yup.string().required().label("Fonction"),
		inscrit: yup.boolean().nullable(),
		type_probleme: yup.string().required().label("Type de probléme"),

	});
	const { user: authUser } = useAppSelector((s) => s.user);

	const [sendProbleme, { isLoading, isError, isSuccess, error }] = useAddOrUpdateProblemeMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm<TProblemeForm>({
		resolver: yupResolver(validationSchema),
	});
	// console.log('isLoading',isLoading)

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Probléme signalé avec succés!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				reset();
				onHide("SignalerProbleme");
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status || "inconnue"} est survenue`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);
	const onsubmit = (data: TProblemeForm) => {
		sendProbleme({ data});
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
		authUser,
	};
};

export default useSignalerProblemeForm;
