import React, { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useProprieteFromLocation } from "../../../../../../utils/api/propriete/propriete.api";
import { useStepType } from "../../../../../../utils/type";
import StepperHorizontal from "../../../../../common/StepperHorizontal";
import UseCrudPropriete from "../requestPropriete/UseCrudPropriete";
import "./AjoutProprietes.css";
import BasicInformation from "./AjoutProprietesStep/BasicInformation";
import InfoDetaillee from "./AjoutProprietesStep/InfoDetaillee";
import {
  showModal,
} from "../../../../../../utils/Utils";

import ChangeOfferModal from "../../../../Agence/OptionAgence/OptionsStep/ChangeOfferModal";
import { useAppSelector } from "../../../../../../redux/hooks";



const steps = [
  {
    id: "info-propiete",
    Component: BasicInformation,
  },
  {
    id: "details-propiete",
    Component: InfoDetaillee,
  },
];

function AjoutProprietes() {
  const { step, navigation, index }: useStepType = useStep({
    initialStep: 0,
    steps,
  });
  const { Component } = step;
  const [proprieteItem] = useProprieteFromLocation();
  const {
    register,
    onSubmit,
    errors,
    setValue,
    description,
    pictures,
    mandatPicture,
    handleChangeDescription,
    setPictures,
    handleChangeMandatPicture,
    isLoading,
    level,
    dataForm,
    address,
    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    control,
    Controller,
    commoditesList,
    commodites,
    onChangeCommodite,
    onChangeAddress,
    unregister,
    showChangeOffre
  } = UseCrudPropriete(proprieteItem);
  const user = useAppSelector((s) => s?.user?.user);


  const props = {
    navigation,
    register,
    onSubmit,
    errors,
    description,
    pictures,
    mandatPicture,
    handleChangeDescription,
    setPictures,
    handleChangeMandatPicture,
    isLoading,
    setValue,
    propriete: proprieteItem,
    level,
    dataForm,

    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    control,
    Controller,
    commoditesList,
    commodites,
    onChangeCommodite,
    onChangeAddress,
    address,
    unregister
  };
  const navigate = useNavigate();

  useEffect(() => {
    setValue("step", index + 1);
  }, [index]);

  useEffect(() => {
    if (level === 1) {
      navigation.go("details-propiete");
    }
  }, [level, dataForm]);

  useEffect(() => {
    window.scrollTo(10, 10);
  }, [index]);

  if (showChangeOffre != "") {
    showModal("changeOfferModal")
  }
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="kanimmo-dash-locataire-requetes-header-titre d-flex align-items-baseline gap-2">
                  <BiArrowBack
                    className="locataire-btn-arrow-back pe-2"
                    onClick={() => navigate(-1)}
                  />{" "}
                  Ajouter une propriété
                  {steps.map((tab, key) => {
                    if (index === key && tab.id === "info-propiete") {
                      return (
                        <span className="kanimmo-ajout-propriete-titre-span">
                          Basic informations
                        </span>
                      );
                    }
                    if (index === key && tab.id === "details-propiete") {
                      return (
                        <span className="kanimmo-ajout-propriete-titre-span">
                          informations détaillées
                        </span>
                      );
                    }
                  })}
                </h4>
              </div>
              <StepperHorizontal index={index} tabNum={2} />
            </div>
          </div>
        </div>
        <div className="bg-white p-3 filter-and-table-container">
          <div className="stepper-proprietes-form-container">
            <form onSubmit={onSubmit}>
              <Component {...props} />
            </form>
          </div>
        </div>
        <div
            className="modal fade"
            id="changeOfferModal"
            aria-labelledby="changeOfferModalLabel"
            aria-hidden="true"
          >
            <ChangeOfferModal
              type={user?.user_type === "agence" ? "agence" : "proprietaire"}
            />
          </div>
      </div>
    </div>
  );
}

export default AjoutProprietes;
