import React, { useState } from "react";
import { AiFillPlusSquare } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import {
  useDeleteDetailTypeRequeteMutation,
  useDeleteTypeRequeteMutation,
  useGetTypeRequeteQuery,
} from "../../../../utils/api/typeRequete/typerequete.api";
import {
  IDetailTypeRequete,
  ITypeRequete,
} from "../../../../utils/api/typeRequete/typeRequete.type";
import { useDelete } from "../../../../utils/helpers";
import { AlertInfo } from "../../../common/Alert";
import { BtnDeleteAll } from "../../../common/Button";
import AjoutDetailTypeRequeteModal from "../../../modal/admin/AjoutDetailTypeRequeteModal";
import AjoutTypeRequeteModal from "../../../modal/admin/AjoutTypeRequeteModal";
import ModifierDetailTypeRequeteModal from "../../../modal/admin/ModifierDetailTypeRequeteModal";

function TypeRequetes() {
  const [item, setItem] = useState<ITypeRequete>();
  const { data = { results: [] }, isLoading } = useGetTypeRequeteQuery({});
// console.log("Type requêtes", data);

  const [detailType, setDetailType] = useState<IDetailTypeRequete>();
  const reset = () => {
    setItem(undefined);
  };
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0  bg-white">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Types de requêtes
          </h4>
          <div className="container-btn-add">
            <button
              className="btn btn-see-more"
              data-bs-toggle="modal"
              data-bs-target="#AddTypeRequeteModal"
            >
              <AiFillPlusSquare style={{ fontSize: 22 }} />
              <span className="ps-2">Ajouter un type de requete</span>
            </button>
          </div>
        </div>

        <div className="filter-and-table-container filter-and-table-container-lead-admin ms-5 me-4 mt-3">
          {/* ================= Test sur l'affichage ================== */}

          <div className="wayf-admin-card py-3 equipements-page">
            <ul className="ps-0">
              {!isLoading && data?.results?.length === 0 && (
                <AlertInfo message="Pas de données" />
              )}
              {!isLoading &&
                data?.results?.map((equipement) => (
                  <div className="bg-slate p-3 mb-3" key={equipement?.id}>
                    <div className="d-flex gap-3 mb-2">
                      <li className="equipement-title">
                        {equipement?.titre} :
                      </li>
                      <div className="d-flex gap-3">
                        <DeleteTypeRequete item={equipement} />
                        <button
                          className="btn btn-action-edit-user-admin"
                          data-bs-toggle="modal"
                          data-bs-target={`#AddTypeRequeteModal${equipement?.slug}`}
                          type="button"
                          onClick={() => setItem(equipement)}
                          style={{
                            color: "#75163e",
                          }}
                        >
                          <MdEdit />
                        </button>
                      </div>
                    </div>
                    <div className="row ">
                      {equipement?.detail_type_requete?.map(
                        (item: IDetailTypeRequete) => (
                          <div
                            className="col-md-4 col-xl-3 pe-md-3 pe-xl-5 flex-m mb-1"
                            key={item?.slug}
                          >
                            <div className="flex-m pe-3">
                              <h6 className="sous-equipement">{item?.nom}</h6>
                            </div>
                            <div className="flex-m gap-2">
                              <DeleteDetailTypeRequete item={item} />
                              <button
                                className="btn btn-small-action"
                                data-bs-toggle="modal"
                                data-bs-target="#EditDetailTypeRequeteModal"
                                type="button"
                                onClick={() => setDetailType(item)}
                              >
                                <MdEdit
                                  style={{
                                    width: "14px",
                                    height: "12px",
                                    color: "#75163e",
                                  }}
                                />
                              </button>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <div className="d-flex flex-column justify-content-sm-between flex-sm-row mt-4">
                      <div>
                        <button
                          className="btn-add btn-add-delete flex-m mb-3 mb-sm-0"
                          data-bs-toggle="modal"
                          data-bs-target="#AddDetailTypeRequete"
                          onClick={() => setItem(equipement)}
                        >
                          <FiPlus style={{ fontSize: 18 }} />
                          <span className="ms-2">Ajouter</span>
                        </button>
                      </div>
                      <div className="d-flex flex-column flex-sm-row gap-3">
                        <DeleteAllSousEquipement item={equipement} />
                      </div>
                    </div>
                  </div>
                ))}
              {/* {isLoading && <Skeleton height={600} width="100%" />} */}
            </ul>
            {/* <div
				className="modal fade"
				id="ajouterEquipement"
				data-bs-backdrop="static"
				aria-labelledby="ajouterEquipement"
				aria-hidden="true"
			>
				<AddEquipementModal modalId="ajouterEquipement" equipement={item} />
			</div> */}
            <div
              className="modal fade"
              id="AddDetailTypeRequete"
              aria-labelledby="AddDetailTypeRequeteModal"
              aria-hidden="true"
            >
              <AjoutDetailTypeRequeteModal item={item} />
            </div>

            <div
              className="modal fade"
              id="EditDetailTypeRequeteModal"
              aria-labelledby="EditDetailTypeRequeteModal"
              aria-hidden="true"
            >
              <ModifierDetailTypeRequeteModal item={detailType} />
            </div>
          </div>

          {/* ================= Fin test sur l'affichage ================== */}
        </div>
      </div>

      <div
        className="modal fade"
        id="AddTypeRequeteModal"
        aria-labelledby="AddTypeRequeteModalLabel"
        aria-hidden="true"
      >
        <AjoutTypeRequeteModal item={item} />
      </div>
      <div
        className="modal fade"
        id={`AddTypeRequeteModal${item?.slug}`}
        aria-labelledby="AddTypeRequeteModalLabel"
        aria-hidden="true"
      >
        <AjoutTypeRequeteModal item={item} />
      </div>
    </div>
  );
}

export default TypeRequetes;

export function DeleteTypeRequete({ item }: { item: ITypeRequete }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteTypeRequeteMutation();
  const onDelete = useDelete<ITypeRequete>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce type de requete?",
    successMessage: "Type de requete supprimé",
  });
  return (
    <button className="btn btn-action-user-admin" onClick={onDelete}>
      <FaTrash />
    </button>
  );
}

export function DeleteDetailTypeRequete({
  item,
}: {
  item: IDetailTypeRequete;
}) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteDetailTypeRequeteMutation();
  const onDelete = useDelete<IDetailTypeRequete>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cet item?",
    successMessage: "Item supprimé",
  });
  return (
    <button className="btn btn-small-action" onClick={onDelete}>
      <FaTrash style={{ width: "10px", height: "10px" }} />
    </button>
    // <button
    //     className="btn btn-action-user-admin"
    //     onClick={onDelete}
    // >
    // 	<FaTrash />
    // </button>
  );
}

// function DeleteEquipement(
//     { item }: {
//         item: ITypeRequete
//     }
//     ) {
// 	// const onDelete = UseDeleteEquipement(item?.slug);
// 	return (
// 		<button
//             className="btn btn-action-user-admin"
//             // onClick={onDelete}
//         >
// 			<FaTrash />
// 		</button>
// 	);
// }

function DeleteSousEquipement({
  item,
}: {
  item: any;
  // ISousEquipement
}) {
  // const onDelete = UseDeleteSousEquipement(item?.slug);
  return (
    <button
      className="btn btn-small-action"
      // onClick={onDelete}
    >
      <FaTrash style={{ width: "10px", height: "10px" }} />
    </button>
  );
}

function DeleteAllSousEquipement({
  item,
}: {
  item: any;
  // IEquipement
}) {
  // const onDelete = UseDeleteAllSousEquipement(item?.slug);
  return <BtnDeleteAll label="Supprimer tous" />; //onClick={onDelete}
}

// const data = [
//     {
//         id: 1,
//         slug: "eq-1",
//         nom: "Travaux d'amélioration de l'espace vie",
//         detail_equipement: [
//             {
//                 id: 3,
//                 slug: "eq-3",
//                 nom: "Peinture"
//             },
//             {
//                 id: 4,
//                 slug: "eq-4",
//                 nom: "Etagère"
//             },
//             {
//                 id: 5,
//                 slug: "eq-5",
//                 nom: "Placard"
//             },
//         ]
//     },
//     {
//         id: 2,
//         slug: "eq-2",
//         nom: "Installation d'appareils de TSF",
//         detail_equipement: [
//             {
//                 id: 6,
//                 slug: "eq-6",
//                 nom: "Ménagère"
//             },
//             {
//                 id: 7,
//                 slug: "eq-7",
//                 nom: "Agréments"
//             },
//         ]
//     },
//     {
//         id: 8,
//         slug: "eq-8",
//         nom: "Entretient",
//         detail_equipement: [
//             {
//                 id: 9,
//                 slug: "eq-9",
//                 nom: "Placards et armoires fixés à demeure"
//             },
//             {
//                 id: 10,
//                 slug: "eq-10",
//                 nom: "Electrique"
//             },
//             {
//                 id: 11,
//                 slug: "eq-11",
//                 nom: "A gaz"
//             },
//             {
//                 id: 12,
//                 slug: "eq-12",
//                 nom: "Sanitaire"
//             },
//             {
//                 id: 13,
//                 slug: "eq-13",
//                 nom: "Cuisine"
//             },
//             {
//                 id: 14,
//                 slug: "eq-14",
//                 nom: "Fourneaux"
//             },
//         ]
//     },
//     {
//         id: 15,
//         slug: "eq-15",
//         nom: "Désengorgement",
//         detail_equipement: [
//             {
//                 id: 16,
//                 slug: "eq-16",
//                 nom: "Baignoire"
//             },
//             {
//                 id: 17,
//                 slug: "eq-17",
//                 nom: "Lavabo"
//             },
//             {
//                 id: 18,
//                 slug: "eq-18",
//                 nom: "Evier"
//             },
//             {
//                 id: 19,
//                 slug: "eq-19",
//                 nom: "WC"
//             },
//             {
//                 id: 20,
//                 slug: "eq-20",
//                 nom: "Douche"
//             },
//             {
//                 id: 21,
//                 slug: "eq-21",
//                 nom: "Appareils sanitaires"
//             },
//         ]
//     },
//     {
//         id: 22,
//         slug: "eq-22",
//         nom: "Réparations",
//         detail_equipement: [
//             {
//                 id: 23,
//                 slug: "eq-23",
//                 nom: "Dégats causés par pose de clous"
//             },
//             {
//                 id: 24,
//                 slug: "eq-24",
//                 nom: "Dégats causés par pose de pitons"
//             },
//             {
//                 id: 25,
//                 slug: "eq-25",
//                 nom: "Trous fait par l'occupant sur murs, planchers et cloisons"
//             },
//         ]
//     },
// ]
