import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { Pagination } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { AlertInfo }  from "../../../../common/Alert"

import {
  ICompteRendu,
  PaginationResults,
} from "../../../../../utils/api/comteRendu/conterendu.type";
import { NavLink } from "react-router-dom";

import DetailCompteRenduModal from "../../../../modal/agence/DetailCompteReduModal";
import CompteRenduSkeleton from "./CompteRenduSkeleton"
import {
  formattedDate,
} from "../../../../../utils/Utils";


function CompteRenduTable({
  data,
  isLoading,
  page,
  setPage,
  limit,
  setLimit,
}: {
  data: PaginationResults<ICompteRendu>;
  isLoading: boolean;
  page: number;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
}) {
  // const [page, setPage] = useState(1);
  // const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  const [item, setItem] = useState<ICompteRendu>();

  const actionFormatter: any = (cell: any, row: any) => {

    return (
      <>
         <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <button
          className="btn btn-action-modal-icon with-tooltip"
          data-tooltip-content="Voir"
          data-bs-toggle="modal"
          data-bs-target={`#DetailCompteRenduModal${row?.slug}`}
          // onClick={() => {
          // 	setItem(row)
          // }}
        >
          <AiOutlineEye />
        </button>
        {/* <button
					className="btn btn-action-modal-icon with-tooltip"
					data-tooltip-content="Modifier"
					data-bs-toggle="modal"
					data-bs-target="#AjouterFactureModal"
					onClick={() => {
						setItem(row)
					}}
				>
					<MdEdit />
				</button> */}
        {/* <DeleteFacture item={row} /> */}
        <div
          className="modal fade"
          id={`DetailCompteRenduModal${row?.slug}`}
          aria-labelledby={`DetailCompteRenduModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <DetailCompteRenduModal
            item={row}
          />
        </div>
        {/* <AjouterFactureModal item={row} /> */}
      </div>
      </>
    );
  };

  // const logoFormatter: any = (cell: IUser) => {
  //   return (
  //     <div className="table-actions-btn-container-commandes gap-2 d-flex">
  //       <div>
  //         <div>
  //           <img
  //             src={getAvatar(
  //               cell?.user_type === "agence" ? cell?.logo : cell?.avatar
  //             )}
  //             alt="img type document"
  //             className="kanimmo-img-avatar-table-admin"
  //           />
  //         </div>
  //       </div>
        
  //     </div>
  //   );
  // };
  // const nameFormatter = (cell: IUser) => {
  //   return (
  //     <div className="table-actions-btn-container-commandes gap-2 ">
  //       <div>
  //         {cell?.user_type === "agence" ? cell?.nom_agence : getName(cell)}
  //       </div>
  //       <p className="fs-13 fw-400"> {cell?.user_type === "agence" ? "Agence" : "Propriétaire"} </p>
  //     </div>
  //   );
  // };
 
  // const statusFormatter = (cell: boolean) => {
  //   if (cell) {
  //     return <div className="custom-statut-facture-paye">Payé</div>;
      
  //   }

  //   return <div className="custom-statut-facture-non-paye">Non payé</div>;
  // };

  //  const nameFormatterOffer = (cell: Offer) => {
  //    return cell?.max_numb
  //      ? cell?.min_numb + "-" + cell?.max_numb
  //      : cell?.min_numb + "+";
  //  };
  
  const columns = [
    {
      dataField: "proprietaire",
      text: "Proprietaire",
      style: { textAlign: "left" },
      formatter: (cell: any, row: ICompteRendu) => row.proprietaire.nom + " " + row.proprietaire.prenom    },
    {
      dataField: "total_loyers",
      text: "Total des loyers",
      formatter: (cell: ICompteRendu) => cell + " FCFA",
    },
    {
      dataField: "total_depenses",
      text: "Total des depenses",
      formatter: (cell: ICompteRendu) => cell + " FCFA",
    },
    {
      dataField: "total_commissions",
      text: "Total des commissions",
      formatter: (cell: ICompteRendu) => cell + " FCFA",
    },
    {
      dataField: "created_at",
      text: "Date de paiement",
      formatter: (cell: string) => formattedDate(cell),
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: ICompteRendu) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  // const tableRowEvents = {
  // 	onClick: () => navigate("/admin/details-agence/:slug"),
  // }

  return (
    <>
      {isLoading && <CompteRenduSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
            // rowEvents={ tableRowEvents }
            // rowStyle={ { cursor: 'pointer' } }
          />
          {/* <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setLimit(perPage);
              setPage(page);
            }}
            perPage={limit}
          /> */}
        </>
      )}
    </>
  );
}

export default CompteRenduTable;