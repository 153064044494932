import React from "react";
import ListeLocataireTablePQGSB from "../../../../../tables/Proprietaire/ProprietaireGererParProprietaire/ListeLocataireTablePQGSB";
import ListeLocataireArchiveTablePQGSB from "../../../../../tables/Proprietaire/ProprietaireGererParProprietaire/ListeLocataireArchiveTablePQGSB";

function ListeLocataireArchivePQGSB({
  searchParams,
  setSearchParams,
}: {
  searchParams: any;
  setSearchParams: any;
}) {
  const word = searchParams.get("name");
  const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        name: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };

  const uptodate = searchParams.get("uptodate");

  const handleFilterByUpToDate = (
    e: React.FormEvent<HTMLSelectElement>
  ): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        uptodate: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  return (
    <div className="bg-white p-3 filter-and-table-container">
      <div className="locataire-requete-filtre-container my-4">
        <form className="row g-3 locataire-requete-filtre-form">
          <div className="d-flex gap-5 align-items-center">
            
            <div className="d-flex align-items-center gap-3">
              <div className="col-auto">
                <label
                  htmlFor="filtreLogement"
                  className="locataire-requete-filtre-label"
                >
                  Filtrer
                </label>
              </div>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control locataire-requete-filtre-input"
                  id="filtreLogement"
                  placeholder="Entrer un nom"
                  onChange={handleFilterByName}
                />
              </div>
            </div>
            {/* <div className='d-flex align-items-center gap-3'>
                        <div className="col-auto">
                            <label
                                htmlFor="filtreLogement"
                                className="locataire-requete-filtre-label"
                            >
                                Catégorie
                            </label>
                        </div>
                        <div className="col-auto">
                            <div className="col-auto">
                                <select className="form-select locataire-requete-filtre-input" aria-label="Default select example">
                                    <option selected>Particulier</option>
                                    <option value="1">One</option>
                                </select>
                            </div>
                        </div>
                    </div> */}
          </div>
        </form>
      </div>
      <div>
        <ListeLocataireArchiveTablePQGSB name={word} uptodate={uptodate} />
      </div>
    </div>
  );
}

export default ListeLocataireArchivePQGSB;

