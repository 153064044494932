import React from "react";
import { IPaiement } from "../../../utils/api/paiement/paiement.type";
import { ApiBaseUrl } from "../../../utils/http";
import { IRappel } from "../../../utils/api/rappel/rappel.type";

function DocumentRappelModal({ item }: { item?: IRappel }) {
  console.log({item});
  
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            <iframe
              src={`${ApiBaseUrl + item?.pdf}`}
              width="100%"
              height="500px"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentRappelModal;
