import React from "react";
import { useStep } from "react-hooks-helper";
import { AiFillPlusSquare } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useStepType } from "../../../../utils/type";
import AjouterCommoditesModal from "../../../modal/admin/AjouterCommoditesModal";
import AjouterConditionModal from "../../../modal/admin/AjouterConditionModal";
import AjouterSlideModal from "../../../modal/admin/AjouterSlideModal";
import AjoutRsociauxModal from "../../../modal/admin/AjoutRsociauxModal";
import AjoutTypeProblemeModal from "../../../modal/admin/AjoutTypeProblemeModal";
import "./OptionsAdmin.css";
import Administrateur from "./OptionsAdminSteps/Administrateur";
import Commodites from "./OptionsAdminSteps/Commodites";
import ConditionsUtilisation from "./OptionsAdminSteps/ConditionsUtilisation";
import CrudSliderHomepage from "./OptionsAdminSteps/CrudSliderHomepage";
import MonCompteAdmin from "./OptionsAdminSteps/MonCompteAdmin";
import ReseauSociaux from "./OptionsAdminSteps/ReseauSociaux";
import TypeProbleme from "./OptionsAdminSteps/TypeProbleme";
import Utilisateur from "./OptionsAdminSteps/Utilisateur";
import { showModal } from "../../../../utils/Utils";

const steps = [
  { id: "mon-compte", Component: MonCompteAdmin },
  { id: "administrateur", Component: Administrateur },
  // { id: "utilisateur", Component: Utilisateur },
  { id: "conditions-utilisataion", Component: ConditionsUtilisation },
  { id: "slider", Component: CrudSliderHomepage },
  { id: "reseau", Component: ReseauSociaux },
  { id: "type-probleme", Component: TypeProbleme },
  { id: "commodites", Component: Commodites },
];

const tabs = [
  "Mon compte",
  "Administrateurs",
  // "Collaborateurs",
  "Conditions utilisation",
  "Slider",
  "Réseau sociaux",
  "Type problème",
  "Commodites",
];

function OptionsAdmin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Options
          </h4>
          {index === 1 ? (
            <div className="container-btn-add">
              <NavLink
                to="/admin/ajouter-administrateur"
                className="btn btn-see-more"
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter un administrateur</span>
              </NavLink>
            </div>
          // ) : index === 2 ? (
          //   <NavLink
          //     to="/admin/ajouter-utilisateur"
          //     className="btn btn-see-more"
          //   >
          //     <AiFillPlusSquare style={{ fontSize: 22 }} />
          //     <span className="ps-2">Ajouter un collaborateur</span>
          //   </NavLink>
          ) : index === 2 ? (
            <>
              <button
                className="btn btn-see-more"
                onClick={() => showModal("AjouterConditionModal")}
                // data-bs-toggle="modal"
                // data-bs-target="#AjouterConditionModal"
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter une condition</span>
              </button>

              <div
                className="modal fade"
                id="AjouterConditionModal"
                aria-labelledby="AjouterConditionModalLabel"
                aria-hidden="true"
              >
                <AjouterConditionModal modalId="AjouterConditionModal" />
              </div>
            </>
          ) : index === 3 ? (
            <>
              <button
                className="btn btn-see-more"
                // data-bs-toggle="modal"
                // data-bs-target="#AjouterSlideModal"
                onClick={() => showModal("AjouterSlideModal")}
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter </span>
              </button>

              <div
                className="modal fade"
                id="AjouterSlideModal"
                aria-labelledby="AjouterSlideModalLabel"
                aria-hidden="true"
              >
                <AjouterSlideModal modalId="AjouterSlideModal" />
              </div>
            </>
          ) : index === 4 ? (
            <>
              <button
                className="btn btn-see-more"
                // data-bs-toggle="modal"
                // data-bs-target="#AddRSocialModal"
                onClick={() => showModal("AddRSocialModal")}
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter </span>
              </button>

              <div
                className="modal fade"
                id="AddRSocialModal"
                aria-labelledby="AddRSocialModalLabel"
                aria-hidden="true"
              >
                <AjoutRsociauxModal />
              </div>
            </>
          ) : index === 5 ? (
            <>
              <button
                className="btn btn-see-more"
                // data-bs-toggle="modal"
                // data-bs-target="#AddTypeProblemeModal"
                onClick={() => showModal("AddTypeProblemeModal")}
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter </span>
              </button>

              <div
                className="modal fade"
                id="AddTypeProblemeModal"
                aria-labelledby="AddTypeProblemeModalLabel"
                aria-hidden="true"
              >
                <AjoutTypeProblemeModal />
              </div>
            </>
          ) : index === 6 ? (
            <>
              <button
                className="btn btn-see-more"
                // data-bs-toggle="modal"
                // data-bs-target="#AddCommoditeModal"
                onClick={() => showModal("AddCommoditeModal")}
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter une commodité </span>
              </button>

              <div
                className="modal fade"
                id="AddCommoditeModal"
                aria-labelledby="AddCommoditeModalLabel"
                aria-hidden="true"
              >
                <AjouterCommoditesModal />
              </div>
            </>
          ) : null}
        </div>
        <div className="kanimmo-tabs-container py-3">
          <ul className="tabs-container custom-tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OptionsAdmin;
