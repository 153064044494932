import React, { useEffect, useState, useRef } from "react";
import { BsCameraFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getAvatar, isProprietaireOwner } from "../../../../../utils/Utils";
import UseEditUserFom from "../requestForm/UseEditUserFom";
import { FormError } from "../../../../common/CustomInputCheckbox";
import GoogleInput from "../../../../common/GoogleInput";
import { BtnSubmit } from "../../../../common/Button";
import { useAddOrEditUserMutation } from "../../../../../utils/api/user/user.api";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { onSetUser } from "../../../../../redux/slice/User.slice";
import ChangePasswordModal from "../../../../modal/admin/ChangePasswordModal";
import PhoneInput from "react-phone-input-2";
import DeleteAccountSection from "./DeleteAccountSection";
import AccountInfo from "./AccountInfo";
import MyOfferItem from "./MyOfferItem";

function Securite() {
  const user = useAppSelector((s) => s?.user?.user);
  const [avatar, setAvatar] = useState<any>(null);
  const {
    register,
    errors,
    onSubmit,
    address,
    addressAgence,
    onChangeAddress,
    isLoading,
    setValue,
    setPhone,
    phone,
    setPhoneWhatsapp,
    phoneWhatsapp,
  } = UseEditUserFom("account");
  const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
    useAddOrEditUserMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (done) {
      // console.log("data", data);
      dispatch(onSetUser(data));
      setAvatar(null);
      Swal.fire({
        icon: "success",
        title: "Photo de profil modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [done, isError]);

  const changeImage = (e: any) => {
    const file = e.target.files[0];
    setAvatar(URL.createObjectURL(file));
    let fd = new FormData();
    fd.append("avatar", file);
    sendData({ slug: user?.slug, userType: user?.user_type, data: fd });
  };

  const ref = useRef<any>();

  useEffect(() => {
    if (errors?.telephone?.message) {
      ref.current.scrollIntoView();
    }
  }, [errors?.telephone]);

  return (
    <div
      className="tab-pane fade show active"
      id="compte"
      role="tabpanel"
      aria-labelledby="compte-tab"
    >
      {/* Change password */}
      <div className="kanimmo-dash-locataire-param-main-page-container py-4 mb-3">
        <div className="kanimmo-dash-locataire-param-title mb-4">
          Modifier votre mot de passe
        </div>
        <div className="kanimmo-dash-locataire-param-password-container">
          <div className="kanimmo-dash-locataire-param-password-title">
            Mot de passe
          </div>
          <button
            className="btn edit-offer-btn"
            data-bs-toggle="modal"
            data-bs-target="#ChangePasswordModal"
          >
            <FiEdit /> Changer de mot de passe
          </button>
        </div>
        <ChangePasswordModal />
      </div>
      {/* Demande suppression section */}
      <DeleteAccountSection />
    </div>
  );
}

export default Securite;
