import React from "react";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../../../redux/hooks";
import { cleannerError } from "../../../../utils/Utils";
import { BtnCloseModal, BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/CustomInputCheckbox";

function ChangeLogoModal({
  sendData,
  isLoading,
}: {
  sendData: any;
  isLoading: boolean;
}) {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm();
  const user = useAppSelector((s) => s?.user?.user);
  React.useEffect(() => {
    register("logo", {
      required: "Veuillez télécharger une image",
    });
  }, [register]);
  React.useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);
  const onSubmit = (data: any, e: any) => {
    let fd = new FormData();
    fd.append("logo", data?.logo);
    sendData({ slug: user?.slug, userType: user?.user_type, data: fd });
    setTimeout(() => {
      e.target.reset();
      reset();
    }, 5000);
  };
  return (
    <div
      className="modal fade"
      id="editLogoModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editLogoModalLabel">
              Changer le logo
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => reset()}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row my-1">
                <div className="col-md-12 mb-3">
                  <div className="">
                    <label
                      htmlFor="nom"
                      className="form-label form-label-form-custom"
                    >
                      Logo de l’agence
                    </label>
                    <div className="input-group input-group-personnalisation">
                      <input
                        type="file"
                        className="form-control py-3"
                        accept="image/*"
                        onChange={(e) => {
                          if (e.target.files && e.target.files?.length > 0) {
                            let file = e.target.files[0];
                            setValue("logo", file);
                          }
                        }}
                      />
                    </div>
                    <FormError error={errors?.logo?.message} />
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row my-4">
                <div className="col-md-12">
                  <div className="d-flex gap-3 justify-content-end">
                    <BtnCloseModal label="Annuler" onClick={() => reset()} />
                    <BtnSubmit label="Enregistrer" isLoading={isLoading} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeLogoModal;
