import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ChargeRequeteType, EditDetailTypeRequeteItemData } from "../../../../utils/api/typeRequete/typeRequete.type";
import { useEditDetailTypeRequeteMutation } from "../../../../utils/api/typeRequete/typerequete.api";
import { Color } from "../../../../utils/theme";
import { cleannerError, onHide } from "../../../../utils/Utils";

function UseEditDetailTypeRequete() {
    const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Le nom de l'élement"),
		charge: yup.string().nullable(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm<EditDetailTypeRequeteItemData>({
		resolver: yupResolver(validationSchema),
	});

	const [editDetailTypeRequete, { isLoading, isSuccess, error, isError }] =
		useEditDetailTypeRequeteMutation();

	const [charge, setCharge] = useState<any>(null);

	const handleChangeCharge = useCallback((option: any) => {
		setCharge(option);
		setValue("charge", option?.value);
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Sous équipement modifié avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				// reset();
				onHide("EditDetailTypeRequeteModal");
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Error status ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (data: EditDetailTypeRequeteItemData) => {
		// console.log(formValues);

		console.log(data);
		editDetailTypeRequete({ slug: data?.slug, data: data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		reset,
		setValue,
		handleChangeCharge,
		charge,
		setCharge
	};
}

export default UseEditDetailTypeRequete
