import moment from "moment";
import React from "react";
import { IoClose } from "react-icons/io5";
import { createMarkup, getImage } from "../../../utils/Utils";

function EnvoieSuppressionNoteLocataireModal({
  item,
  modalId,
}: {
  item: any;
  modalId: string;
}) {
  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container-form custom-container-form">
            <div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <div className="custom-envoie-note-titre">
                      {item?.data?.titre}
                    </div>
                    <button
                      type="button"
                      className="btn-close custom-btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <div className="custom-envoie-note-date">
                      {moment(item?.data?.created_at).format("LL")}
                    </div>
                    <hr />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    {item?.data?.content && (
                      <div
                        className="mb-3 custom-envoie-note-description"
                        dangerouslySetInnerHTML={createMarkup(
                          item?.data?.content
                        )}
                      />
                    )}
                    {item?.data?.image && (
                      <div className="w-100">
                        <img
                          src={getImage(item?.data?.image)}
                          alt="img annonce"
                          className="custom-envoie-note-image"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="row">
                            <div className="col-md-12 custom-envoie-note-btn-container ">
                                <button className="custom-envoie-note-btn-delete" onClick={() => onDelete()}>
                                    <BsFillTrashFill />
                                </button>
                                <button className="btn custom-envoie-note-btn-submit"  data-bs-dismiss="modal">
                                    <IoIosSend />
                                </button>
                            </div>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnvoieSuppressionNoteLocataireModal;
