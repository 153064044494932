import React from "react";
import { IEtatLieu } from "../../../utils/api/etatlieu/etatlieu.type";
import { ApiBaseUrl } from "../../../utils/http";
import { get_url_extension } from "../../../utils/Utils";
import { BtnCloseModal } from "../../common/Button";

function ViewDocModal({ item }: { item?: IEtatLieu }) {
	return (
		<div
			className="modal fade"
			id="ViewDocModal"
			aria-labelledby="ViewDocModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="ViewDocModalLabel">
							Etat de lieu
						</h5>
						<BtnCloseModal label="" />
					</div>
					<div>
					{get_url_extension(item?.document) === "pdf" ? (
						<iframe
							src={ApiBaseUrl + item?.document}
							frameBorder="0"
							width={`100%`}
							height={800}
							allowFullScreen
							seamless
							title="doc"
						></iframe>
						) : (
						<iframe
							src={`https://docs.google.com/gview?url=${
							ApiBaseUrl + item?.document
							}&embedded=true`}
							frameBorder="0"
							width={`100%`}
							height={800}
							title="doc"
						></iframe>
						)}
					</div>
					{/* <iframe src={ApiBaseUrl + item?.document} height={600}></iframe> */}
				</div>
			</div>
		</div>
	);
}

export default ViewDocModal;
