import React from "react";
import { useStep } from "react-hooks-helper";
import { AiFillPlusSquare } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetAgencesQuery } from "../../../../utils/api/agence/agence.api";
import { useStepType } from "../../../../utils/type";
import { isAgence } from "../../../../utils/Utils";
import AjouterAnnonceModal from "../../../modal/admin/AjouterAnnonceModal";
import AjouterNoteModal from "../../../modal/admin/AjouterNoteModal";
import "./AdministratifAgence.css";
import Etatlieux from "./Administratiftabs/Etatlieux";
import Factures from "./Administratiftabs/Factures";
import NoteAnnonce from "./Administratiftabs/NoteAnnonce";
import Quittance from "./Administratiftabs/Quittance";
import CompteRenduDeGestion from "./Administratiftabs/CompteRenduDeGestion";
import AjoutEtatLieuxModalPQGSB from "../../../modal/proprietaireQGSB/AjoutEtatLieuxModalPQGSB";

const steps = [
  { id: "avis d'echeance", Component: Factures },
  { id: "quittance", Component: Quittance },
  { id: "contes-rendu-gestion", Component: CompteRenduDeGestion },
  { id: "etat-des-lieux", Component: Etatlieux },
  { id: "notes-et-annonces", Component: NoteAnnonce },
  // { id: "representant-justice", Component: RepresentationJustice },
];

const tabs = [
  "Avis d'échéance",
  "Quittance",
  "Compte rendu de gestion",
  "Etat des lieux",
  "Notes et Annonces",
  // "Representation justice",
];

function AdministratifAgence() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useAppSelector((s) => s?.user);
  const { data: ListeAgence } = useGetAgencesQuery({});
  const AgenceSlug = ListeAgence?.results
    ?.filter((item) => item?.id === user?.agence_id)
    ?.map((itm) => itm?.slug);
  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));

  const props = { searchParams, setSearchParams, AgenceSlug, is_Agence };

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Administratif
          </h4>
          {/* {index === 2 && (
            <button
              className="btn btn-see-more dropdown-toggle btn"
              type="button"
              data-bs-target="#AjouterEtatLieuModal"
              data-bs-toggle="modal"
            >
              <div className="user-acount-dashboard-header-display g-2">
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Générer</span>
              </div>
            </button>
          )} */}
          {index === 3 && (
            <div className="dropdown dis-flex">
              <button
                className="btn btn-see-more dropdown-toggle btn"
                type="button"
                data-bs-target="#AjouterEtatLieuModal"
                data-bs-toggle="modal"
                // id="dropdownMenuButton"
                // data-bs-toggle="dropdown"
                // aria-haspopup="true"
                // aria-expanded="false"
              >
                <div className="user-acount-dashboard-header-display g-2">
                  <AiFillPlusSquare style={{ fontSize: 22 }} />
                  <span className="ps-2">Ajouter état des lieux</span>
                </div>
              </button>
              {/* <div
                className="dropdown-menu dropdown-menu-custom"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  className="btn btn-see-more"
                  title="Note"
                  data-bs-toggle="modal"
                  data-bs-target="#AjouterNoteModal"
                >
                  <span className="ps-2">Note</span>
                </button>
                <button
                  className="btn btn-see-more"
                  title="Annonce"
                  data-bs-toggle="modal"
                  data-bs-target="#AjouterAnnonceModal"
                >
                  <span className="ps-2">Annonce</span>
                </button>
              </div>
              <div
                className="modal fade"
                id="AjouterNoteModal"
                aria-labelledby="AjouterNoteModalLabel"
                aria-hidden="true"
              >
                <AjouterNoteModal modalId="AjouterNoteModal" />
              </div>
              <div
                className="modal fade"
                id="AjouterAnnonceModal"
                aria-labelledby="AjouterAnnonceModalLabel"
                aria-hidden="true"
              >
                <AjouterAnnonceModal modalId="AjouterAnnonceModal" />
              </div> */}
            </div>
          )}
          {index === 4 && (
            <div className="dropdown dis-flex">
              <button
                className="btn btn-see-more dropdown-toggle"
                type="button"
                // data-bs-target="#AjouterRepresentationJusticeModal"
                // data-bs-toggle="modal"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="user-acount-dashboard-header-display g-2">
                  <AiFillPlusSquare style={{ fontSize: 22 }} />
                  <span className="ps-2">Ajouter</span>
                </div>
              </button>
              <div
                className="dropdown-menu dropdown-menu-custom"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  className="btn btn-see-more"
                  title="Note"
                  data-bs-toggle="modal"
                  data-bs-target="#AjouterNoteModal"
                >
                  <span className="ps-2">Note</span>
                </button>
                <button
                  className="btn btn-see-more"
                  title="Annonce"
                  data-bs-toggle="modal"
                  data-bs-target="#AjouterAnnonceModal"
                >
                  <span className="ps-2">Annonce</span>
                </button>
              </div>
              <div
                className="modal fade"
                id="AjouterNoteModal"
                aria-labelledby="AjouterNoteModalLabel"
                aria-hidden="true"
              >
                <AjouterNoteModal modalId="AjouterNoteModal" />
              </div>
              <div
                className="modal fade"
                id="AjouterAnnonceModal"
                aria-labelledby="AjouterAnnonceModalLabel"
                aria-hidden="true"
              >
                <AjouterAnnonceModal modalId="AjouterAnnonceModal" />
              </div>
            </div>
          )}
        </div>
        <div className="kanimmo-tabs-container py-3">
          <ul className="tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component {...props} />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={"AjouterEtatLieuModal"}
        aria-labelledby="AjouterEtatLieuModalLabel"
        aria-hidden="true"
      >
        <AjoutEtatLieuxModalPQGSB modalId="AjouterEtatLieuModal" />
      </div>
    </div>
  );
}

export default AdministratifAgence;
