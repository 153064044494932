/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useCreatePaimentMutation } from "../../../../../../utils/api/paiement/paiement.api";
import moment from "moment";
import { PaymentMethodFormData } from "../../../../../../utils/api/paiement/paiement.type";
import { ApiBaseUrl, AppBaseUrl, Env } from "../../../../../../utils/http";
import {
  getInTechPhone,
  getInTechTel,
  onHide,
  onShow,
} from "../../../../../../utils/Utils";
import { Color } from "../../../../../../utils/theme";
import { ApiKey } from "../../../../../../utils/constant";
// import { PaiementFormData } from "../../../../../../utils/api/paiement/paiement.type";

function usePaiementForm(user: any) {
  // const locataire = useAppSelector((s) => s.user.user);
  const [onLoading, setOnLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [seconds, setSeconds] = useState<any>(20);
  const [infosPayment, setInfosPayment] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [addPaiement, { isLoading, isSuccess, error, isError, data }] =
    useCreatePaimentMutation();
  let withPaiement = true;
  const [mode, setMode] = useState<string>("");
  // useEffect(() => {
  //   onShow("verifStatutPaymentLoyer");
  //   // calculateCountdown();
  // }, []);
  useEffect(() => {
    if (isFetching) {
      const timer =
        seconds > 0 ? setInterval(() => setSeconds(seconds - 1), 1000) : 0;

      return () => clearInterval(timer);
    }
  }, [seconds, isFetching]);
  useEffect(() => {
    if (isSuccess && data) {
      if (data) {
        // console.log("data", data);
      }
      // window.localStorage.removeItem("montant")
      // window.location.href = data?.url;
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  const onGetTransactionStatus = () => {
    console.log("click", infosPayment);
    setOnLoading(true);
    // 600255dc-b6c0-11ed-91c6-0242ac130003
    var raw = {
      externalTransactionId: infosPayment?.externalTransactionId,
    };

    var myHeaders = new Headers();
    myHeaders.append("Secretkey", ApiKey);

    // var requestOptions: any = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };
    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Secretkey: ApiKey,
      },
      body: JSON.stringify({
        externalTransactionId: infosPayment?.externalTransactionId,
      }),
    };
    console.log("raw", raw);
    if (infosPayment?.externalTransactionId) {
      fetch(
        "https://api.intech.sn/api-services/get-transaction-status",
        requestOptions
      )
        .then((response) => response.json())
        .then((result: any) => {
          setOnLoading(false);
          console.log(result);
          if (result?.data?.status === "SUCCESS") {
            setIsFetching(false);
            onHide("verifStatutPaymentLoyer");
            setIsChecked(true);
            Swal.fire({
              icon: "success",
              title: `Paiement effectué avec succès!`,
              iconColor: Color.success,
              showConfirmButton: false,
              timer: 3000,
            });
            console.log("done");
          } else if (result?.data?.status === "PENDING") {
            setIsFetching(true);
            Swal.fire({
              icon: "info",
              title: `Votre paiement est en attente!`,
              iconColor: Color.themeColor,
              showConfirmButton: false,
              timer: 3000,
            });
          } else if (result?.data?.status === "REFUNDED") {
            setIsFetching(false);
            onHide("verifStatutPaymentLoyer");
            Swal.fire({
              icon: "info",
              title: `Votre paiement a été remboursé!`,
              iconColor: Color.themeColor,
              showConfirmButton: false,
              timer: 3000,
            });
          } else if (result?.data?.status === "PROCESSING") {
            setIsFetching(true);
            Swal.fire({
              icon: "info",
              title: `Votre paiement est en cours de traitement!`,
              iconColor: Color.themeColor,
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            console.log("no done");
            setIsFetching(false);
            onHide("verifStatutPaymentLoyer");
            Swal.fire({
              icon: "error",
              title: result?.data?.errorType?.message
                ? result?.data?.errorType?.message
                : `${result?.msg}`,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          setIsFetching(false);
          setOnLoading(false);
          console.log("error", error);
          Swal.fire({
            icon: "error",
            title: error?.data?.message
              ? error?.data?.message
              : `Erreur de statut ${error?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
          onHide("verifStatutPaymentLoyer");
        });
    }
  };

  const onCreatePaiment = async (apiCash: string) => {
    const date_du = moment(user.prochain_date_paiement).format("YYYY-MM-DD");
    const res = await addPaiement({
      date_du,
      type_paiement: user.cautionnement ? "caution" : "mensualite",
      locataire: user.id,
      montant: (user.cautionnement
        ? user?.montant_caution
        : user.prochain_montant_paiement ?? 0) as number,
      propriete: (user.propriete?.id || user.propriete) as number,
      env: window.location.origin,
      page: "locataire/mon-loyer",
      code_service: apiCash,
    });
    console.log("res", res);
    if ("data" in res) {
      return res.data;
    } else {
      throw res.error;
    }
  };

  const onPressSubmit = async (
    apiCash: string,
    { phone, ...data }: PaymentMethodFormData & { phone?: string } = {}
  ) => {
    setMode(apiCash);
    setLoading(true);
    try {
      const paiement = await onCreatePaiment(apiCash);
      let externalTransactionId = paiement?.transaction_id as unknown as number;

      let amount = Env !== "dev" ? user.prochain_montant_paiement : 5;
      if (amount === 10 && apiCash === "BANK_CARD_API_CASH_OUT") {
        amount = 100;
      }
      const tel = phone || user.telephone;
      let dataSend = {
        amount: amount,
        phone: "",
        codeService: apiCash,
        externalTransactionId,
        callbackUrl: `${ApiBaseUrl}/api/paiements/callback/`,
        apiKey: ApiKey,
        data: {
          env: "test",
        },
        sender: "Kanimmo",
        merchantName: "Kanimmo",
        operationDescription: "Paiement sur la plateforme Kanimmo avec paytech",
        successRedirectUrl: `${AppBaseUrl}/locataire/mon-loyer?status=success`,
        errorRedirectUrl: `${AppBaseUrl}/locataire/mon-loyer?status=error`,
        ...(data ?? {}),
      };
      if (apiCash !== "BANK_CARD_API_CASH_OUT") {
        dataSend.phone = getInTechPhone(tel)?.toString() ?? "";
      } else {
        dataSend.phone = getInTechTel(tel)?.toString() ?? "";
      }
      const requestOptions: RequestInit = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(dataSend),
      };

      if (externalTransactionId) {
        const res = await fetch(
          "https://api.intech.sn/api-services/operation",
          requestOptions
        ).then((res) => {
          if (res.ok) return res.json();
          throw {
            data: { message: `Une erreur est survenue code: ${res.status}` },
          };
        });
        console.log("res", res);
        setLoading(false);
        if (res?.error) {
          let message = "";
          if (res.data) {
            for (let [k, v] of Object.entries(res.data)) {
              if (Array.isArray(v)) {
                message = `${message}\n${k}: ${v.join(", ")}`;
              }
            }
          }
          if (message) {
            // toast(message, true);
            Swal.fire({
              icon: "error",
              title: message,
              showConfirmButton: false,
              timer: 5000,
            });
          } else if (res.msg) {
            // toast(res.msg, true);
            Swal.fire({
              icon: "error",
              title: res.msg,
              showConfirmButton: false,
              timer: 5000,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: res?.msg
                ? res?.msg
                : `Erreur de statut code ${res?.code} est survenue!`,
              showConfirmButton: false,
              timer: 5000,
            });
          }
          return false;
        } else {
          const { externalTransactionId } = res.data;
          setInfosPayment(res.data);
          onHide("choicePaymentMethod");
          Swal.fire({
            icon: "success",
            title: res?.msg,
            showConfirmButton: false,
            timer: 5000,
          }).then(() => {
            if (externalTransactionId) {
              onShow("verifStatutPaymentLoyer");
            }
            // window.location.href = deepLinkUrl;
          });
          // if (deepLinkUrl) {
          //   // setUrl(deepLinkUrl);

          //   Swal.fire({
          //     icon: "success",
          //     title: res?.msg,
          //     showConfirmButton: false,
          //     timer: 5000,
          //   }).then(() => {
          //     // window.location.href = deepLinkUrl;
          //   });
          // } else {
          //   // setMsg(res.msg);
          //   Swal.fire({
          //     icon: "success",
          //     title: res?.msg,
          //     showConfirmButton: false,
          //     timer: 5000,
          //   });
          // }

          return true;
        }
      }
    } catch (error: any) {
      setLoading(false);
      // console.log("error", error);
      const data = error?.data as any;

      let m = data?.data?.message;
      if (!m) m = data?.message;
      if (!m && data) m = data["message "] || null;
      if (m) {
        // toast(m, true);
        Swal.fire({
          icon: "error",
          title: m,
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        // toast("Une erreur est survenue", true);
        Swal.fire({
          icon: "error",
          title: `Erreur de statut ${error?.status} est survenue`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
      return false;
    }
  };

  return {
    onSubmit: onPressSubmit,
    isLoading: loading,
    mode,
    onLoading,
    onCheckStatus: onGetTransactionStatus,
    isChecked,
    seconds,
    disabled,
  };
}

export default usePaiementForm;
