import React from "react";
import {
  ProprieteStepProps,
  TypeGerance,
} from "../../../../../../utils/api/propriete/propriete.type";
import { checkExtension } from "../../../../../../utils/Utils";
import { BtnSubmit, GoBackButton } from "../../../../../common/Button";
import { FormError } from "../../../../../common/CustomInputCheckbox";
import { PreviewImg } from "../../../../../common/Preview";
import { SelectInput } from "../../../../../common/SelectInput";
import { Input } from "../../../../../common/Input";
import { BsPlusSquare } from "react-icons/bs";
import ChargesComponent from "../../proporieteTabs/ChargesComponent";

const TypeGeranceOptions = [
  { label: "Partiel", value: TypeGerance.partiel },
  { label: "Total", value: TypeGerance.total },
];

function GestionAppartement({
  register,
  onSubmit,
  errors,
  navigation,
  handleChangeMandatPicture,
  isLoading,
  unregister,
  setValue
}: ProprieteStepProps) {
  
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <form onSubmit={onSubmit}>
                <div className="row">
                  {/* <div className="col-md-6">
                    <SelectInput
                      label="Type de gérance"
                      options={TypeGeranceOptions}
                      required
                      id="gerance"
                      {...register("gerance")}
                      error={errors.gerance}
                    />
                  </div> */}
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="rue"
                        className="form-label form-label-modal-custom"
                      >
                        Mandat de gérance
                      </label>
                      <input
                        type="file"
                        className="form-control form-control-modal-custom"
                        id="rue"
                        onChange={handleChangeMandatPicture}
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      />
                      <FormError error={errors.mandat} />
                    </div>
                  </div>
                  <ChargesComponent
                    register={register}
                    unregister={unregister}
                    errors={errors}
                    setValue={setValue}
                  />
                </div>
                <div className="container-btn-modal row my-4">
                  <div className="col-md-4 offset-md-8">
                    <div className="flex-r d-flex gap-3">
                      {/* <GoBackButton label="Annuler" /> */}
                      <button
                        className="btn auth-submit-annuler"
                        onClick={() => navigation.go("details-appartement")}
                      >
                        Retourner
                      </button>
                      <BtnSubmit label="Enregistrer" isLoading={isLoading} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GestionAppartement;
