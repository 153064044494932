import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ITypeRequete, TypeRequeteFormData } from "../../../../utils/api/typeRequete/typeRequete.type";
import { useAddOrUpdateTypeRequeteMutation } from "../../../../utils/api/typeRequete/typerequete.api";
import { Color } from "../../../../utils/theme";
import { cleannerError, onHide } from "../../../../utils/Utils";

export function useCrudTypeRequete(item?: ITypeRequete) {
	const validationSchema = yup.object().shape({
		titre: yup.string().required().label("Titre"),
		description: yup.string().label("Description"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<TypeRequeteFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdateTypeRequete, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateTypeRequeteMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Type requête ${item ? "modifié" : "ajouté"} avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
				onHide(item ? `AddTypeRequeteModal${item?.slug}` : "AddTypeRequeteModal");
				if(!item) {
					reset();
				}
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("titre", item?.titre);
			setValue("description", item?.description);
		}
	}, [item]);
	const onSubmit = (data: TypeRequeteFormData) => {
		console.log("data", data);
		addOrUpdateTypeRequete({ slug: item?.slug, data: data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
	};
}
