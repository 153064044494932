import { IAgence } from "../agence/agence.type"
import { ILocataire } from "../locataire/locataire.type"
import { IProprietaire } from "../proprietaire/proprietaire.type"
import { IPropriete } from "../propriete/propriete.type"
import { IDetailTypeRequete } from "../typeRequete/typeRequete.type"

export enum StatusRequete {
	nonRegler = "non_regler",
	enCours = "encours",
	regler = "regler",
}

export enum ChargeRequete {
	agence = "agence",
	locataire = "locataire",
	proprietaire = "proprietaire",
	comptable = "comptable",
	gerant = "gerant",
	admin = "admin",
	superadmin = "superadmin",
}

export enum UrgenceRequete {
	normal = "normal",
	moyen = "moyen",
	maximal = "maximal",
}

export interface IRequete {
    id: number
    slug: string		
    titre: string	
    requete:string	
    autre: string	
    urgence: UrgenceRequete	
    charge: ChargeRequete	
    statut: StatusRequete	
    type_requete: ITypeRequete
    detail_type_requete: any
    locataire: ILocataire
    agence: IAgence
    proprietaire: IProprietaire
    created_at: string
    propriete: IPropriete
}

export type RequeteFormData = {
    id: number
    slug: string		
    titre: string	
    requete:string	
    autre: string	
    urgence: UrgenceRequete	
    charge: ChargeRequete	
    statut: StatusRequete	
    type_requete: ITypeRequete
    detail_type_requete: any
    // locataire: ILocataire
    agence: IAgence
    proprietaire: IProprietaire
}

export interface ITypeRequete {
    slug: string	
    id: number	
    titre: string	
    description: string
}

export type ChangeStatusRequeteType = IRequete & {
	slug: IRequete["slug"];
	status: IRequete["statut"];
};

// export type ChangeStatusRequeteType = {
// 	slug: IRequete["slug"];
// 	statut: "non_regler" | "encours" | "regler";
// };