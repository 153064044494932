import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ImgSlider from "../../../../../assets/property.jpg";
import SliderTableSkeleton from "./SliderTableSkeleton";
import SliderTableData from "./SliderTableData";
import { IoMdTrash } from "react-icons/io";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  useDeleteSliderMutation,
  useGetSliderQuery,
} from "../../../../../utils/api/slider/slider.api";
import { ISlider } from "../../../../../utils/api/slider/slider.type";
import { UseDeleteSlider } from "../../../../TableauDebord/Admin/Options/CrudSlider/UseCrudSlider";
import { useDelete } from "../../../../../utils/helpers";
import { getImage, showModal } from "../../../../../utils/Utils";
import { MdEdit } from "react-icons/md";
import ModifierSlideModal from "../../../../modal/admin/ModofierSlideModal";
import { AiFillEye } from "react-icons/ai";
import DetailSliderModal from "../../../../modal/admin/DetailSliderModal";
import moment from "moment";

function SliderTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const admin = useAppSelector((s) => s.user.user);
  const { data, isLoading } = useGetSliderQuery({
    page: page,
    limit: perPage,
  });
  // console.log({ data });
  const actionFormatter: any = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <button
          className="btn btn-action-modal-icon with-tooltip"
          data-tooltip-content="Modifier"
          // data-bs-toggle="modal"
          // data-bs-target={`#ModifierSlideModal${row?.slug}`}
          onClick={() => showModal(`ModifierSlideModal${row?.slug}`)}
        >
          <MdEdit />
        </button>
        <DeleteSlider item={row} />

        <ModifierSlideModal
          modalId={`ModifierSlideModal${row?.slug}`}
          slider={row}
        />
      </div>
    );
  };
  const dateFormatter = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        {moment(cell).format("DD/MM/YYYY à HH:mm")}
      </div>
    );
  };

  const columns = [
    {
      dataField: "titre",
      text: "Titre",
      style: { textAlign: "left" },
    },
    {
      dataField: "created_at",
      text: "Créé le",
      formatter: (cell: any, row: any) => dateFormatter(cell, row),
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <SliderTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => "Pas de données"}
            rowStyle={{ cursor: "pointer" }}
          />
        </>
      )}
    </>
  );
}

export default SliderTable;

export function DeleteSlider({ item }: { item: ISlider }) {
  const [deleteItem, { isSuccess, isError, error }] = useDeleteSliderMutation();
  const onDelete = useDelete<ISlider>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce slide?",
    successMessage: "slide supprimé",
  });
  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <IoMdTrash />
    </button>
  );
}
