import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { validatePassword } from "./useRegisterAgence";
import { LocataireFormData } from "../../../utils/api/locataire/locataire.type";
import { useRegisterLocataireMutation } from "../../../utils/api/locataire/locataire.api";
import { cleannerError, onShow } from "../../../utils/Utils";
import { Data } from "../../common/GoogleInput";

function useRegisterLocataire() {
  const validationSchema = yup.object().shape({
    step: yup.number().nullable(),
    nom: yup.string().when("step", {
      is: 2,
      then: yup.string().required().label("Nom"),
    }),
    prenom: yup.string().when("step", {
      is: 2,
      then: yup.string().required().label("Prénom"),
    }),
    civilite: yup.string().when("step", {
      is: 2,
      then: yup.string().label("Civilite"),
    }),
    email: yup.string().when("step", {
      is: 2,
      then: yup.string().email().required().label("Email"),
    }),
    telephone: yup.string().when("step", {
      is: 2,
      then: yup.string().required().label("Téléphone"),
    }),
    // username: yup.string().when("step", {
    //   is: 2,
    //   then: yup.string().required().label("UserName"),
    // }),
    password: yup.string().when("step", {
      is: 2,
      then: validatePassword(yup),
    }),
    role: yup.string().label("Rôle"),
    whatsapp: yup.string().label("Whatsapp"),
    notification_whatsapp: yup.boolean().nullable(),
    avatar: yup.mixed().nullable().label("Photo"),

    adresse: yup.string().label("Adresse"),
    nom_agence: yup.string().label("Nom agence"),
    adresse_agence: yup.string().label("Adresse de l'agence"),
    pays: yup.string().label("Pays"),
    region: yup.string().label("Région"),
    code_postal: yup.string().label("Code postal"),
    lieu_de_travail: yup.string().label("Lieu de travail"),
    agence_id: yup.string().label("Agence id"),
    propriete: yup.string().label("Propriete"),
    collocataire: yup
      .string()
      .default("0")
      .nullable()
      .label("Collocataire")
      .min(0),
    confirmPassword: yup.string().when("step", {
      is: 2,
      then: yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          "Les mots de passe ne correspond pas"
        ),
    }),
    fonction: yup.string().label("Fonction"),
    nationalite: yup.string().label("Nationalite"),
    situation_matrimoniale: yup.string().label("Situation matrimoniale"),
    date_de_naissance: yup.string().label("Date de naissance"),
    date_debut: yup.string().required().label("Date de début"),
    date_fin: yup.string().required().label("Date de fin"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm<LocataireFormData>({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  //  Calling the Register Mutation
  const [registerLocataire, { isLoading, isSuccess, error, isError }] =
    useRegisterLocataireMutation();

  const [level, setLevel] = React.useState<number>(0);
  const [dataForm, setDataForm] = useState<any>({});

  // const [address, setAddress] = useState("");
  const [addressAgence, setAddressAgence] = useState("");

  // const onChangeAddress = (val: {
  //   rue: string;
  //   latitude: string;
  //   longitude: string;
  //   ville: string;
  //   country: string;
  // }) => {
  //   setAddress(val?.rue);
  //   setValue('adresse', val?.rue)
  // };

  const [country, setCountry] = useState<any>("");
  const [state, setState] = useState<any>("");

  const handleCountrySelect = (option: Data) => {
    setCountry(option?.country);
    setValue("pays", option?.country);
  };

  const handleStateSelect = (option: Data) => {
    if (option?.region) {
      setState(option?.region);
      setValue("region", option?.region);
    }
  };

  const onChangeAddressAgence = (val: Data) => {
    setAddressAgence(val?.rue);
    setValue("adresse", val?.rue);
    if (val?.country) {
      setValue("pays", val?.country);
      setCountry(val?.country);
    }

    if (val?.region) {
      setValue("region", val?.region);
      setState(val?.region);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Inscription réussie!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate("/connexion", { replace: true });
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : "Une erreur a survenu lors de l'inscription",
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const [previewAvatar, setPreviewAvatar] = useState("");

  const handelAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("avatar", { message: "Image format is incorrect." });
    }
    if (file) {
      setPreviewAvatar(URL.createObjectURL(file));
      register("avatar");
      setValue("avatar", file);
    }
  }, []);

  const onSubmit = (data: any) => {
    data["user_type"] = "locataire";
    if (data?.email) {
      data["email"] = (data?.email).toLowerCase();
    }
    if (data?.username) {
      data["username"] = (data?.username).toLowerCase();
    }
    setDataForm(data);
    if (data?.step !== 2) {
      return setLevel(data?.step);
    }

    onShow("ConditionsModal");
  };

  const onRegister = (e: any) => {
    e?.preventDefault();
    let data = dataForm;
    // console.log("data", data);
    data["condition"] = true;
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "agence_id") {
        if (data[key] && data[key] !== "autres") {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }

    registerLocataire(fd);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    level,
    dataForm,
    handelAvatarChange,
    previewAvatar,
    // onChangeAddress,
    // address,
    onChangeAddressAgence,
    addressAgence,
    onRegister,
    handleCountrySelect,
    handleStateSelect,
    country,
    state,
  };
}

export default useRegisterLocataire;
