import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  createMarkup,
  formatAmount,
  showModal,
} from "../../../../../utils/Utils";
import { FiEdit } from "react-icons/fi";
import ChangeOfferModal from "./ChangeOfferModal";
import UsePayAbonnementForm from "../../../../modal/user/UsePayAbonnementForm";
import ChoicePaymentMethod from "../../../locataire/Dashboard/MonLoyer/ChoicePaymentMethod";
import CheckStatusPaymentModal from "../../../locataire/Dashboard/MonLoyer/CheckStatusPaymentModal";
import { useLazyMeQuery } from "../../../../../utils/api/user/user.api";
import { onSetUser } from "../../../../../redux/slice/User.slice";

function MyOfferItem() {
  const user = useAppSelector((s) => s?.user?.user);
  const {
    isLoading: loading,
    mode,
    onCheckStatus,
    onLoading,
    onPressSubmit,
    onPayDifference,
    seconds,
    isChecked,
  } = UsePayAbonnementForm();
  const [fetchMe, { data }] = useLazyMeQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isChecked) {
      fetchMe();
    }
  }, [isChecked]);

  useEffect(() => {
    if (data) {
      dispatch(onSetUser(data));
    }
  }, [data]);

  return (
    <div className="kanimmo-dash-locataire-param-main-page-container py-4 mb-3">
      <div className="kanimmo-dash-locataire-param-title mb-4">
        Mon offre choisi
      </div>
      <div className="kanimmo-dash-locataire-param-password-container">
        <div className="row w-100">
          <div className="col-md-8">
            <div className="my-offer-container">
              <h4
                className={`text-title-card-offre-step pb-3 text-title-card-offre-step1`}
              >
                {user?.offre?.max_numb
                  ? user?.offre?.min_numb + "-" + user?.offre?.max_numb
                  : "Illimité"}
              </h4>
              <span
                className={`content-btn-price-offre-step content-btn-price-offre-step1`}
              >
                {formatAmount(user?.offre?.montant)}-{user?.offre.libelle}
              </span>
            </div>
          </div>
          <div className="offer-elements col-md-4">
            {!!user?.is_up_to_date && (
              <button
                className="btn edit-offer-btn"
                onClick={() => showModal("changeOfferModal")}
              >
                <FiEdit /> Changer d'offre
              </button>
            )}

            {!user?.is_up_to_date && (
              <button
                className="btn edit-offer-btn"
                onClick={() => showModal("PaymentMethodModal")}
              >
                <FiEdit /> Payer mon abonnement
              </button>
            )}
          </div>
          <div className="col-md-12">
            <div
              className="content-describe-card-offre-step mt-2"
              dangerouslySetInnerHTML={createMarkup(
                user?.offre?.description === null
                  ? ""
                  : user?.offre?.description
              )}
            ></div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="changeOfferModal"
        aria-labelledby="changeOfferModalLabel"
        aria-hidden="true"
      >
        <ChangeOfferModal
          type={user?.user_type === "agence" ? "agence" : "proprietaire"}
        />
      </div>
      <div
        className="modal fade"
        id="PaymentMethodModal"
        aria-labelledby="PaymentMethodModalLabel"
        aria-hidden="true"
      >
        <ChoicePaymentMethod
          onPay={onPressSubmit}
          isLoading={loading}
          mode={mode}
        />
      </div>
      <div
        className="modal fade"
        id="test"
        aria-labelledby="testLabel"
        aria-hidden="true"
      >
        <ChoicePaymentMethod
          onPay={onPayDifference}
          isLoading={loading}
          mode={mode}
        />
      </div>
      <CheckStatusPaymentModal
        isLoading={onLoading}
        onCheckStatus={onCheckStatus}
        seconds={seconds}
        modalId={"checkStatusPaymentModal"}
      />
    </div>
  );
}

export default MyOfferItem;
