import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, isProprietaireOwner } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import { PersonnelFormData } from "../../../../../utils/api/agence/agence.type";
import {
  useAddOrUpdatePersonnelMutation,
  useDeletePersonnelMutation,
} from "../../../../../utils/api/agence/agence.api";

function UseAddOrUpdatePersonnel(personnel?: PersonnelFormData) {
  const validationSchema = yup.object().shape({
    id: yup.number(),
    nom: yup.string().required().label("Nom"),
    prenom: yup.string().required().label("Prenom"),
    email: yup.string().required().label("Email"),
    telephone: yup.string().required().label("Telephone"),
    user_type: yup.string().label("Type d'utilisateur"),
    dashboard: yup.boolean(),
    bien: yup.boolean(),
    administratif: yup.boolean(),
    messsage: yup.boolean(),
    locatair: yup.boolean(),
    location: yup.boolean(),
    paiement: yup.boolean(),
    parametre: yup.boolean(),
    password: yup.string().label("Password"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
    watch,
  } = useForm<PersonnelFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [addOrUpdatePersonnel, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdatePersonnelMutation();

  // const userType = watch("user_type");

  const [userType, setUserType] = useState<string | undefined>(undefined);

  useEffect(() => {
    const userTypeValue = watch("user_type");
    setUserType(userTypeValue);
  }, [watch("user_type")]);

  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [personne, setPersonne] = React.useState<string>();

  // const [address, setAddress] = useState("");
  const [phone, setPhone] = useState<string>();

  const onChangeAddress = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    // setAddress(val?.rue);
    setValue("adresse", val?.rue);
  };

  useEffect(() => {
    if (isSuccess) {
      openModal();
      setPersonne(data?.prenom + " " + data?.nom);
    }
    const err = error as any;
    if (isError) {
      if (
        err?.data?.email &&
        err?.data?.email.includes("user with this email already exists.")
      ) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email existe déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.email
            ? err?.data?.email
            : err?.data?.user_type
            ? err?.data?.user_type
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors, 5000);
  }, [errors]);

  function openModal() {
    setIsOpen(true);
  }

  const { user } = useAppSelector((s) => s?.user);

  const onSubmit = (data: any) => {
    // console.log("data submitted", data);
    if (isProprietaireOwner(user)) {
      data["proprietaire_id"] = user?.id;
    } else {
      data["agence_id"] = user?.id;
    }

    const fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    // console.log("data", data);
    console.log(
      "cerce detail",
      addOrUpdatePersonnel({ slug: personnel?.slug, data: fd })
    );
  };

  const handleReset = () => {
    if (!personnel) {
      reset();
    }
  };

  useEffect(() => {
    if (personnel?.id) {
      const fields: (keyof PersonnelFormData)[] = [
        "nom",
        "prenom",
        "email",
        "telephone",
        "user_type",
        "dashboard",
        "bien",
        "locatair",
        "location",
        "paiement",
        "parametre",
        "administratif",
        "message",
      ];
      // console.log("fields");

      for (let field of fields) {
        register(field);
        if (field === "user_type") {
          setValue(
            field,
            personnel[field] //?.id
          );
        } else {
          setValue(field, personnel[field]);
        }
      }
      if (
        (personnel?.telephone?.substring(0, 2) === "77" ||
          personnel?.telephone?.substring(0, 2) === "76" ||
          personnel?.telephone?.substring(0, 2) === "78" ||
          personnel?.telephone?.substring(0, 2) === "70" ||
          personnel?.telephone?.substring(0, 2) === "75") &&
        !personnel?.telephone?.includes("221")
      ) {
        setPhone("221" + personnel?.telephone);
      } else {
        setPhone(personnel?.telephone);
      }
    }
  }, [personnel]);

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    modalIsOpen,
    setIsOpen,
    personne,
    onChangeAddress,
    phone,
    setPhone,
    userType,
  };
}

export default UseAddOrUpdatePersonnel;

export function UseDeletePersonnel(slug: string) {
  const [deleteData] = useDeletePersonnelMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce personnel ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      // console.log(result, "archiver");
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Personnel supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
