/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { IImmeubles } from '../../../../../utils/api/immeubles/immeubles.type'
import { IPropriete } from '../../../../../utils/api/propriete/propriete.type'
import { AlertInfo } from '../../../../common/Alert'
import Pagination from '../../../../common/Pagination'
import ProprieteItem from '../ProprieteItem'
import ProprieteItemImmeuble from '../ProprieteItemImmeuble'
import { ProprieteCardSkeleton } from '../ProprietesAgence'

function Immeubles({
  proprietes,
  isLoading,
  perPage,
  setPageImmo,
  pageImmo,
  immoCount,
  immeubles,
  handleFilterByNameImmo,
  query,
}: {
  handleFilterByTypeGerance: () => void;
  proprietes: IPropriete;
  isLoading: boolean;
  handleFilterByTypePropriete: () => void;
  perPage: any;
  setPageImmo: any;
  pageImmo: any;
  immoCount: any;
  immeubles: IPropriete;
  handleFilterByNameImmo: any;
  query: string;
}) {
  // console.log("immo", immeubles);
  return (
    <div className="kanimmo-proprietes-container">
      <div className="proprietes-component-container">
        <div className="locataire-requete-filtre-container my-3">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            {/* <div className="col-auto">
              <select
                id="villa"
                className="form-select filtre-select"
                onChange={handleFilterByTypePropriete}
              >
                <option value="" selected>
                  Toutes mes propriétés
                </option>
                <option value="villa">Villas</option>
                <option value="immeuble">Immeubles / Appartements</option>
              </select>
            </div> */}
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                placeholder="Entrer un nom"
                value={query}
                onChange={handleFilterByNameImmo}
              />
            </div>
          </form>
        </div>
        <div className="content-proprietes locataire-requete-filtre-container mt-5">
          <div className="row">
            {!isLoading &&
              immeubles?.map((item: IPropriete) => (
                <ProprieteItem item={item} />
              ))}
            {isLoading &&
              [...Array(6)]?.map((item, i) => (
                <ProprieteCardSkeleton key={i} />
              ))}
            {!isLoading &&
              // proprietes &&
              (immeubles?.length === 0 || immeubles == undefined) && (
                <AlertInfo message="Aucune propriété trouvée" />
              )}
          </div>
          {immeubles && immeubles?.length > perPage && (
            <div className="flex-r mb-t">
              <Pagination
                page={pageImmo}
                total={immoCount}
                perPage={perPage}
                onPageChange={(page: number) => setPageImmo(page)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Immeubles