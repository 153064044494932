import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  IAgence,
  IAgenceFormData,
} from "../../../../../../utils/api/agence/agence.type";
import {
  useAddOrUpdateAgenceMutation,
  useDeleteAgenceMutation,
} from "../../../../../../utils/api/agence/agence.api";
import { cleannerError, useLocationState } from "../../../../../../utils/Utils";
import { Color } from "../../../../../../utils/theme";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../../redux/hooks";
import { countries } from "../../../../../../utils/Countries";
import { Data } from "../../../../../common/GoogleInput";

import moment from "moment";
import { Offer } from "../../../../../../utils/api/offer/offer.type";
import { useGetOffersQuery } from "../../../../../../utils/api/offer/offer.api";


export function useCrudAgence() {
  const item = useLocationState<IAgence>(undefined);
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Nom"),
    prenom: yup.string().required().label("Prenom"),
    telephone: yup.string().required().label("Téléphone"),
    email: yup.string().email().required().label("Email"),
    offre: yup
    .number()
    .required()
    .label("L'offre")
    .transform((value) => (isNaN(value) ? null : value)).nullable(),
    nom_agence: yup.string().required().label("Nom de l'agence"),
    pays: yup.string().label("Pays").nullable(),
    date_fin: yup.string().label("Fin du bail").nullable(),
    region: yup.string().label("Région").nullable(),
    adresse_agence: yup.string().label("Adresse").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<IAgenceFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [
    addOrUpdateProprietaire,
    { isLoading, isSuccess, error, isError, data },
  ] = useAddOrUpdateAgenceMutation();
  const { data: dataOffres = { results: [] } } = useGetOffersQuery({ limit: 1000 });
  let pays: any = countries?.find((el) => el?.name === item?.pays);
  const navigate = useNavigate();
  const [phone, setPhone] = useState<string>();

  const [addressAgence, setAddressAgence] = useState("");
  const [country, setCountry] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [offres, setOffres] = useState<Offer[]>([]);

  const handleCountrySelect = (option: Data) => {
    setCountry(option?.country);
    setValue("pays", option?.country);
  };

  const handleStateSelect = (option: Data) => {
    if (option?.region) {
      setState(option?.region);
      setValue("region", option?.region);
    }
  };

  const onChangeAddressAgence = (val: Data) => {
    setAddressAgence(val?.rue);
    setValue("adresse_agence", val?.rue);
    if (val?.country) {
      setValue("pays", val?.country);
      setCountry(val?.country);
    }

    if (val?.region) {
      setValue("region", val?.region);
      setState(val?.region);
    }
  };

  useEffect(() => {
    if (dataOffres?.results?.length) {
      setOffres(
        dataOffres?.results?.filter((it: any) => it?.type === "agence" && it?.gratuit)
      );
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      // console.log("data", data);
      Swal.fire({
        icon: "success",
        title: `Agence ${item?.slug ? "modifiée" : "ajoutée"} avec succès!`,
        showConfirmButton: false,
        timer: 2000,
        iconColor: Color.success,
      }).then(() => {
        setCountry("");
        setState("");
        navigate("/admin/mes-clients");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);
console.log("item", item);
  useEffect(() => {
    if (item?.id) {
      let fields: (keyof IAgenceFormData)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "offre",
        "adresse_agence",
        "nom_agence",
        "code_postal",
        "pays",
        "region",
        "date_fin",
      ];
      for (let field of fields) {
        if (field !== "date_fin"){
          setValue(field, item[field]);
        }
        
      }
      setAddressAgence(item?.adresse_agence);
      setCountry(item?.pays);
      setState(item?.region);
      if (item?.telephone) {
        if (
          (item?.telephone?.substring(0, 2) === "77" ||
            item?.telephone?.substring(0, 2) === "76" ||
            item?.telephone?.substring(0, 2) === "78" ||
            item?.telephone?.substring(0, 2) === "70" ||
            item?.telephone?.substring(0, 2) === "75") &&
          !item?.telephone?.includes("221")
        ) {
          setPhone("221" + item.telephone);
        } else {
          setPhone(item.telephone);
        }
      }
      // Vérifier si la clé "date_fin" est présente dans fields
      // if (item?.abonnements?.length > 0) {
      //   let date_fin = moment(item.abonnements[0].date_fin).format("MM/DD/YYYY")
      //   console.log("date_fin", date_fin)
      //   setValue("date_fin", date_fin); 
      // }
    }
  }, [item]);
  const onSubmit = (data: IAgenceFormData) => {
    // console.log("data", data);
    addOrUpdateProprietaire({ slug: item?.slug, data: data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    item,
    offres,
    onChangeAddressAgence,
    addressAgence,
    phone,
    setPhone,
    handleCountrySelect,
    handleStateSelect,
    country,
    state,
    setCountry,
  };
}

export function UseDeleteAgence(slug: string) {
  const [deleteAgence] = useDeleteAgenceMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet agence?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteAgence(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log("resultser", result);
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: "Agence supprimée avec succès!",
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
