import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../../../assets/appImages/logo.png";
import Key from "../../../../../assets/icons/CB.png";
import OM from "../../../../../assets/icons/ico-orange.png";
import Wave from "../../../../../assets/icons/wave-sn.png";

import Free from "../../../../../assets/icons/free-money.png";
import Expresso from "../../../../../assets/icons/ico-em.png";
import Wizal from "../../../../../assets/icons/wizall.jpeg";
// import { onHide } from '../../../../../utils/Utils';
import { IoClose } from "react-icons/io5";
import InfosCard, { Infos } from "./InfosCard";
import { onHide } from "../../../../../utils/Utils";
import { Offer } from "../../../../../utils/api/offer/offer.type";

function ChoichePayementMethodeMensualite({
  onPressSubmit,
  isLoading,
  mode,
}: {
  onPressSubmit: (apiCash: string, data?: Infos) => void;
  isLoading: boolean;
  mode: string;
}) {
  const onSubmit = (data: Infos) => {
    // console.log("data",data)

    onPressSubmit("BANK_CARD_API_CASH_OUT", data);
  };

  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container-form">
            <div className="flex-sb">
              <div className="container-logo-page-register">
                <NavLink className="navbar-brand" to="/">
                  <img src={Logo} alt="Logo" className="logo-app" />
                </NavLink>
              </div>
              <button
                className="close-modal auth-submit-annuler btn-close"
                data-bs-dismiss="modal"
                type="button"
                onClick={() => onHide("choichePayementMethodeMensualite")}
              >
                <IoClose />
              </button>
            </div>
            <div className="m-1">
              <div className="content-title-page-register-animate mt-4">
                <p className="title-page-register-animate text-center">
                  Vous payez avec
                </p>
              </div>
              <div className="row">
                {/* <div className="col-md-4 mb-3">
                  <div className="card-animate-register-item animate__animated animate__fadeInTopLeft animate__delay-400ms">
                    <div className="content-card-animate-register-item">
                      <div className="content-title-card-animate">
                        <h5 className="title-card-animate fs-15">Carte Visa</h5>
                      </div>
                      <div className="content-icon-card-animate-register">
                        <img
                          src={Key}
                          alt="BANK_CARD_API_CASH_OUT"
                          className="icon-card-register-animate w-50"
                        />
                      </div>

                      <div
                        className="content-btn-register-card-animate"
                        // onClick={() => handleSubmit(onSubmit)}
                      >
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#infosCard"
                          className="btn btn-animate-card-register"
                        >
                          Payer
                          <span className="span-register-animate"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="offset-md-2 col-md-4 mb-3">
                  <div className="card-animate-register-item animate__animated animate__fadeInUpBig animate__delay-600ms">
                    <div className="content-card-animate-register-item">
                      <div className="content-title-card-animate">
                        <h5 className="title-card-animate fs-15">
                          Orange Money
                        </h5>
                      </div>
                      <div className="content-icon-card-animate-register">
                        <img
                          src={OM}
                          alt="ORANGE_SN_API_CASH_OUT"
                          className="icon-card-register-animate w-50"
                        />
                      </div>
                      <div
                        className="content-btn-register-card-animate"
                        // onClick={() => onHide("RegisterModal")}
                      >
                        <button
                          onClick={() => onPressSubmit("ORANGE_SN_API_CASH_OUT")}
                          className="btn btn-animate-card-register"
                          disabled={isLoading}
                        >
                          {isLoading && mode === "ORANGE_SN_API_CASH_OUT" ? (
                            <span
                              className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
                              role="status"
                            ></span>
                          ) : (
                            "Payer"
                          )}

                          <span className="span-register-animate"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="card-animate-register-item animate__animated animate__fadeInBottomRight animate__delay-800ms">
                    <div className="content-card-animate-register-item">
                      <div className="content-title-card-animate">
                        <h5 className="title-card-animate fs-15">Wave</h5>
                      </div>
                      <div className="content-icon-card-animate-register">
                        <img
                          src={Wave}
                          alt="WAVE_SN_API_CASH_OUT"
                          className="icon-card-register-animate w-50"
                        />
                      </div>
                      <div
                        className="content-btn-register-card-animate"
                        // onClick={() => onHide("RegisterModal")}
                      >
                        <button
                          onClick={() => onPressSubmit("WAVE_SN_API_CASH_OUT")}
                          className="btn btn-animate-card-register"
                          disabled={isLoading}
                        >
                          {isLoading && mode === "WAVE_SN_API_CASH_OUT" ? (
                            <span
                              className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
                              role="status"
                            ></span>
                          ) : (
                            "Payer"
                          )}
                          <span className="span-register-animate"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-4 mb-3">
                  <div className="card-animate-register-item animate__animated animate__fadeInTopLeft animate__delay-400ms">
                    <div className="content-card-animate-register-item">
                      <div className="content-title-card-animate">
                        <h5 className="title-card-animate fs-15">Free Money</h5>
                      </div>
                      <div className="content-icon-card-animate-register">
                        <img
                          src={Free}
                          alt="FREE_SN_WALLET_CASH_OUT"
                          className="icon-card-register-animate w-60"
                        />
                      </div>

                      <div
                        className="content-btn-register-card-animate"
                        // onClick={() => onHide("RegisterModal")}
                      >
                        <button
                          onClick={() => onPressSubmit("FREE_SN_WALLET_CASH_OUT")}
                          className="btn btn-animate-card-register"
                          disabled={isLoading}
                        >
                          {isLoading && mode === "FREE_SN_WALLET_CASH_OUT" ? (
                            <span
                              className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
                              role="status"
                            ></span>
                          ) : (
                            "Payer"
                          )}
                          <span className="span-register-animate"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="card-animate-register-item animate__animated animate__fadeInUpBig animate__delay-600ms">
                    <div className="content-card-animate-register-item">
                      <div className="content-title-card-animate">
                        <h5 className="title-card-animate fs-15">E-Money</h5>
                      </div>
                      <div className="content-icon-card-animate-register">
                        <img
                          src={Expresso}
                          alt="EXPRESSO_SN_WALLET_CASH_OUT"
                          className="icon-card-register-animate w-50"
                        />
                      </div>
                      <div
                        className="content-btn-register-card-animate"
                        // onClick={() => onHide("RegisterModal")}
                      >
                        <button
                          onClick={() => onPressSubmit("EXPRESSO_SN_WALLET_CASH_OUT")}
                          className="btn btn-animate-card-register"
                          disabled={isLoading}
                        >
                          {isLoading &&
                          mode === "EXPRESSO_SN_WALLET_CASH_OUT" ? (
                            <span
                              className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
                              role="status"
                            ></span>
                          ) : (
                            "Payer"
                          )}
                          <span className="span-register-animate"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="card-animate-register-item animate__animated animate__fadeInBottomRight animate__delay-800ms">
                    <div className="content-card-animate-register-item">
                      <div className="content-title-card-animate">
                        <h5 className="title-card-animate fs-15">Wizall</h5>
                      </div>
                      <div className="content-icon-card-animate-register">
                        <img
                          src={Wizal}
                          alt="WIZALL_SN_API_CASH_OUT"
                          className="icon-card-register-animate w-50"
                        />
                      </div>
                      <div
                        className="content-btn-register-card-animate"
                        // onClick={() => onHide("RegisterModal")}
                      >
                        <button
                          onClick={() => onPressSubmit("WIZALL_SN_API_CASH_OUT")}
                          className="btn btn-animate-card-register"
                          disabled={isLoading}
                        >
                          {isLoading && mode === "WIZALL_SN_API_CASH_OUT" ? (
                            <span
                              className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
                              role="status"
                            ></span>
                          ) : (
                            "Payer"
                          )}
                          <span className="span-register-animate"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div
              className="modal fade"
              id="infosCard"
              aria-labelledby="InfosCardLabel"
              aria-hidden="true"
            >
              <InfosCard onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChoichePayementMethodeMensualite;