import React, { useEffect, useRef, useState } from "react";
import "../../../Agence/Messagerie/Messagerie.css";
import { AiFillPlusSquare } from "react-icons/ai";
import Sommaire from "./Sommaire";
import DetailsMessageTabsAdmin from "../../../Agence/Messagerie/DetailsMessageTabsAdmin";
import {
  ChatApi,
  useChatByUserQuery,
  useUpdateCHatMutation,
} from "../../../../../utils/api/chat/chat.api";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { IUser } from "../../../../../utils/api/user/user.type";
import { ChatData } from "../../../../../utils/api/chat/chat.type";
import { useLocationState } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import NewDiscussionPQGSBModal from "../../../../modal/agence/NewDiscussionPQGSBModal";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";

function MessagesPQNGPSB() {
  const userId = useAppSelector((s) => s.user.user?.id);
  const [searchParams, setSearchParams] = useSearchParams();
  const name = searchParams.get("name");
  const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        name: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  const { data: chatData = [], isSuccess } = useChatByUserQuery({ name });
  const [updateChat] = useUpdateCHatMutation();
  const [filteredChat, setFilteredChat] = useState<ChatData[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedChat, setSelectedChat] = useState<ChatData | null>(null);
  const dispatch = useAppDispatch();
  const sUser = useLocationState<IUser>(null);
  const [count, setCount] = useState(0);


  useEffect(() => {
    if (isSuccess) {
      setFilteredChat(chatData);
    }
  }, [chatData, isSuccess]);
  useEffect(() => {
    let newCount = 0;
    if (filteredChat?.length) {
      filteredChat.forEach((item) => {
        newCount += item?.messages?.filter(
          (el) => el?.recever?.id === userId && el?.is_read === false
        )?.length;
      });
    }
    setCount(newCount);
  }, [filteredChat]);

  useEffect(() => {
    if (!selectedUser) {
      if (sUser && userId !== sUser?.id) {
        setSelectedUser(sUser);
      } else {
        if (chatData?.length > 0) {
          setSelectedUser(chatData[0].user);
        }
      }
    }
  }, [sUser, chatData, selectedUser, userId]);
  useEffect(() => {
    if (selectedUser) {
      setSelectedChat(
        chatData.find((l) => l.user.id === selectedUser.id) || null
      );
    } else {
      setSelectedChat(null);
    }
  }, [selectedUser, chatData]);

  useEffect(() => {
    dispatch(ChatApi.util.invalidateTags(["chat"]));
  }, []);

  useEffect(() => {
    if (selectedChat) {
      setTimeout(() => {
        const promises = selectedChat?.messages
          .filter((chat) => {
            return !chat.is_read && chat.sender.id !== userId;
          })
          .map((chat) => updateChat({ slug: chat.slug, is_read: true }));
        Promise.all(promises).then((res) =>
          console.log("marked as read", res?.length)
        );
      }, 3000);
    }
  }, [selectedChat, userId]);

  const marqueToutLu = async () => {
    await Swal.fire({
      title: `Voulez vous tout marqué comme lu ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        chatData?.map((item) =>
          item?.messages?.map((chat) =>
            updateChat({ slug: chat.slug, is_read: true })
          )
        );
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const messageNonLu = () => {
    setSelectedIndex(1);
    let nonLus = chatData?.filter(
      (el) =>
        el?.lastMessage?.recever?.id === userId &&
        el?.lastMessage?.is_read === false
    );

    if (nonLus?.length > 0) {
      setFilteredChat(nonLus);
      setSelectedUser(nonLus[0].user);
      setSelectedChat(
        nonLus.find((l) => l.user.id === nonLus[0].user.id) || null
      );
    }

    // console.log(nonLus);
  };

  const allMessage = () => {
    setSelectedIndex(0);
    setFilteredChat(chatData);
  };

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Messages
          </h4>
          <div className="container-btn-add">
            <button
              className="btn btn-see-more"
              data-bs-toggle="modal"
              data-bs-target="#NewDiscussionModalPqgsb"
            >
              <AiFillPlusSquare style={{ fontSize: 22 }} />
              <span className="ps-2">Nouvelle discussion</span>
            </button>
          </div>
        </div>
        <div className="kanimmo-tabs-container">
          <div className="tabs-component-container">
            <div className="tabs-component-container">
              <div className="users-network-connect-list mt-4">
                <div className="container-btn-filter-message mb-3">
                  <div className="content-btn-filter-message">
                    <button
                      className={`btn btn-filter-message ${
                        selectedIndex === 0 && "active"
                      }`}
                      onClick={() => allMessage()}
                    >
                      Tout
                    </button>
                  </div>
                  <div className="content-btn-filter-message">
                    {count > 0 && (
                      <button
                        className={`btn btn-filter-message ${
                          selectedIndex === 1 && "active"
                        }`}
                        type="button"
                        onClick={() => messageNonLu()}
                      >
                        Non lus
                      </button>
                    )}
                  </div>
                  <div className="content-btn-filter-message">
                    {count > 0 && (
                      <button
                        className="btn btn-filter-message"
                        onClick={marqueToutLu}
                      >
                        Marquer tout comme lu
                      </button>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="bloc-search-message mb-3 position-relative">
                      <form>
                        <input
                          className="form-control"
                          type="search"
                          placeholder="Rechercher"
                          onChange={handleFilterByName}
                        />
                        <button type="submit" value="search">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </form>
                    </div>
                    <div className="parent-table-container-message-tabs custom-parent-table-container-message-tabs">
                      <div>
                        <p className="titre-messages-bloc ps-3 pb-3">
                          {" "}
                          Vos discussions
                        </p>
                      </div>
                      <ul className="list-group list-group-message list-group-flush">
                        {filteredChat?.length === 0 && (
                          <AlertInfo message="Pas de discussion ouverte" />
                        )}
                        {filteredChat.map((chat) => (
                          <Sommaire
                            active={selectedChat === chat}
                            item={chat}
                            onClick={() => setSelectedUser(chat.user)}
                            key={chat.user.id}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <DetailsMessageTabsAdmin
                      user={selectedUser}
                      chat={selectedChat}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="NewDiscussionModalPqgsb"
        aria-labelledby="NewDiscussionModalLabel"
        aria-hidden="true"
      >
        <NewDiscussionPQGSBModal onSelect={setSelectedUser} />
      </div>
    </div>
  );
}

export default MessagesPQNGPSB;
