import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";


import {
    cleannerError,
    onHide,
  } from "../../../../../utils/Utils";
import {
  Gquittence,
} from "../../../../../utils/api/facture/facture.type";
import { Color } from "../../../../../utils/theme";
import { useSendLocataireEmailMutation } from "../../../../../utils/api/locataire/locataire.api";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import { ifError } from "assert";

export function locataireExistant(){
    const validationSchema = yup.object().shape({
        email: yup.string().required().label("Email locataire"),
      });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm<Gquittence>({
    resolver: yupResolver(validationSchema),
  });
  

  const {user} = useAppSelector((s:any) => s?.user)
  
  const navigate = useNavigate()
  const [ sendEmail, { isLoading, isSuccess, error, data, isError }] = useSendLocataireEmailMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Email Envoyer avec sucess`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then((result) => {
        // const { locataire } = data || {}
        if (data) {
          console.log((data as any)?.locataire)
        }
        handleReset();
        onHide("locataireExistantModal");
        if (user?.agence_id || user?.user_type === 'agence') {
          
          navigate('/agence/modifier-locataire', {state: (data as any)?.locataire })
        } else {
          navigate(`/proprietaire/gerer-par-le-proprietaire/locataires/modifier-locataire/${(data as any)?.locataire?.slug}`, {state: (data as any)?.locataire })
        }
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: any) => {
    console.log("email locataire", data.email);
    await sendEmail({email: data.email});
  };


  const handleReset = () => {
    reset();
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    handleReset
  };
}

