import React, { useEffect, useState } from 'react'
import { Pagination } from 'reactstrap';
import EtatLieuxDatasPQGSB from '../../../ProprietaireGererParProprietaire/Administratif/EtatLieux/EtatLieuxDatasPQGSB';
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import word from "../../../../../../assets/appImages/word.png"
import EtatLieuxSkeletonPQNGPSB from './EtatLieuxSkeletonPQNGPSB';
import EtatLieuxModal from '../../../../../modal/proprietaireGPA/EtatLieuxModal';
import { useAppSelector } from "../../../../../../redux/hooks";
import { useGetEtatLieuByProprietaireQngpsbQuery } from '../../../../../../utils/api/etatlieu/etatlieu.api'
import { HiDocumentDownload } from "react-icons/hi";
import ViewDocModal from "../../../../../modal/agence/ViewDocModal";
import { IEtatLieu } from "../../../../../../utils/api/etatlieu/etatlieu.type";
import { AlertInfo } from '../../../../../common/Alert';
import { CustomPagination } from '../../../../../common/CustomPagination';





function EtatLieuxTablePQNGPSB({nom}: {nom?: any}) {
    const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [item, setItem] = useState<IEtatLieu>();


    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal(e:any) {
        e.preventDefault();
        setIsOpen(true);
    }

    const documentFormatter: any = (cell: any, row: any) => {
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				<div>
                    <div>
                        <img src={word} alt='img type document' className='kanimmo-img-type-document' /> 
						{" "}{ cell?.substring(12 , cell?.length - 4)}
                    </div>
                </div>
			</div>
		);
	};


	const locataireFormatter: any = (cell: any, row: any) => {
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				<div>
                    {row.locataire?.nom}  {row.locataire?.prenom}
                </div>
			</div>
		);
	};
	const proprieteFormatter: any = (cell: any, row: any) => {
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				<div>
                    {row.propriete?.nom}
                </div>
			</div>
		);
	};
	
    const etatLieuxFormatter: any = (cell: any, row: any) => {
        return(
            <button
				data-bs-toggle="modal"
				data-bs-target="#ViewDocModal"
				onClick={() => setItem(row)}
                role="button"
                className="btn btn-action-voir-etat-lieux"
            >
                Voir l’etat des lieux
            </button>
        )
    }

	const columns = [

        {
			dataField: "document",
			text: "Nom du document",
            formatter: (cell: any, row: any) =>
            documentFormatter(cell, row),
		},
		{
			dataField: "locataire",
			text: "Locataire",
			style: { textAlign: "left" },
			formatter: (cell: any, row: any) =>
            locataireFormatter(cell, row),
		},
		{
			dataField: "propriete",
			text: "Propriétés",
			style: { textAlign: "left" },
			formatter: (cell: any, row: any) =>
            proprieteFormatter(cell, row),
		},
		{
			dataField: "date",
			text: "Date de validation",
			style: { textAlign: "left" },
		},
		
		{
			dataField: "id",
			text: "Découvrir",
			style: { color: "#1AA981", textAlign: 'left' },
            formatter: (cell: any, row: any) =>
            etatLieuxFormatter(cell, row),
		},
	];



	const proprietaire = useAppSelector((s) => s.user.user);
    
    const { data = { results: [] }, isLoading } = useGetEtatLieuByProprietaireQngpsbQuery({slug: proprietaire?.slug, nom});

	console.log('datata',data)
    return (
        <>
			{isLoading && <EtatLieuxSkeletonPQNGPSB />}
			{!isLoading && (
				<>
                    <BootstrapTable
                        keyField="id"
						data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
                        columns={columns}
                        bordered={true}
                        condensed={false}
                        responsive
                        wrapperClasses="table-responsive locataire-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvéé" />
						)}
                    />
                    <CustomPagination
                        nbPages={8}
                        page={page}
                        onChange={(page, perPage) => {
                            setPerPage(perPage);
                            setPage(page);
                        }}
                        perPage={perPage}
                    />
                </>
			)}
            <div
				className="modal fade"
				id="DetailsMessageModal"
				aria-labelledby="DetailsMessageModalLabel"
				aria-hidden="true"
			>
				<EtatLieuxModal />
			</div>
			<ViewDocModal item={item} />

		</>
    )
}

export default EtatLieuxTablePQNGPSB
