import React from "react";
import {
  IFacture,
  StatusFacture,
} from "../../../utils/api/facture/facture.type";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import { Input } from "../../common/Input";
import { SelectInput } from "../../common/SelectInput";
import { useCrudFacture } from "../../TableauDebord/Agence/Administratif/requestForm/useCrudFacture";

export const optionsStatusFacture = [
  {
    label: "Mensualité",
    value: StatusFacture.mensualite,
  },
  {
    label: "Caution",
    value: StatusFacture.caution,
  },
  {
    label: "Commission",
    value: StatusFacture.commission,
  },
  {
    label: "Autre",
    value: StatusFacture.other,
  },
];
function AjouterFactureModal({ item }: { item?: IFacture }) {
  const {
    register,
    onSubmit,
    errors,
    locataires,
    locataireItem,
    handleChangePropriete,
    handleChangeLocataire,
    isLoading,
    Controller,
    control,
    options,
    option,
    handleReset,
    watchAllFields,
  } = useCrudFacture(item);
  const animatedComponents = makeAnimated();

  return (
    <div
      className="modal fade"
      id={item ? `editFactureModal${item?.slug}` : "AjouterFactureModal"}
      aria-labelledby="AjouterFactureModalLabel"
      data-bs-backdrop="true"
      // data-bs-dismiss="modal"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {item ? "Modifier la" : "Créer une"} facture
            </h5>
          </div>
          <div className="modal-body">
            <div className="container-form pt-3">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label
                        htmlFor="nombre_stock"
                        className="form-label form-label-modal-custom"
                        aria-labelledby="propriete"
                      >
                        Sélectionner la propriété
                        <span
                          className="text-danger"
                          style={{
                            fontSize: 24,
                          }}
                        >
                          *
                        </span>
                      </label>
                      {/* <select
                        id="propriete"
                        className="form-select form-select-modal-custom mb-2"
                        onChange={handleChangePropriete}
                      >
                        <option value="" selected disabled >Selectionner une propriété</option>
                        {proprietes?.map((item) => (
                          <option value={item.id} key={item.slug}>
                            {item?.nom}
                          </option>
                        ))}
                      </select> */}
                      <Controller
                        name="propriete"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              options={options}
                              placeholder="Selectionner une propriété"
                              inputClass="form-select form-select-modal-custom mb-2"
                              classNamePrefix="select-comodite"
                              onChange={(e: any) => handleChangePropriete(e)}
                              value={option}
                            />
                          );
                        }}
                      />

                      {<FormError error={errors.propriete} />}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="mb-3">
                      <label
                        htmlFor="nombre_stock"
                        className="form-label form-label-modal-custom"
                        aria-labelledby="propriete"
                      >
                        Locataire
                        <span
                          className="text-danger"
                          style={{
                            fontSize: 24,
                          }}
                        >
                          *
                        </span>
                      </label>
                      {/* {item && (
                        <div className="d-none">
                          <Input
                            type="text"
                            label="Locataire"
                            id="locataire"
                            disabled
                            {...register("locataire")}
                          />
                        </div>
                      )}
                      <select
                        id="propriete"
                        className="form-select form-select-modal-custom mb-2"
                        {...register("locataire")}
                      >
                        <option value="" selected disabled>
                          Selectionner un locataire
                        </option>
                        {locataires?.map((item) => (
                          <option value={item.value} key={item.value}>
                            {item?.label}
                          </option>
                        ))}
                      </select> */}
                      <Controller
                        name="locataire"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              options={locataires}
                              placeholder="Selectionner une locataire"
                              inputClass="form-select form-select-modal-custom mb-2"
                              classNamePrefix="select-comodite"
                              onChange={handleChangeLocataire}
                              value={locataireItem}
                            />
                          );
                        }}
                      />
                      {locataires && locataires?.length === 0 && option && (
                        <small className="badge text-bg-info px-2">
                          Aucun locataire trouvé pour cette propriété!
                        </small>
                      )}

                      {<FormError error={errors.locataire} />}
                    </div>
                  </div>
                  <div className="col-12">
                    <SelectInput
                      label="Statut"
                      required
                      options={optionsStatusFacture}
                      {...register("statut")}
                      error={errors.statut}
                    />
                  </div>
                  {watchAllFields?.statut === "other" && (
                    <div className="col-12">
                      <Input
                        type="text"
                        label="Statut de la facture"
                        id="autres"
                        placeholder="Statut de la facture"
                        required
                        {...register("autres")}
                        error={errors.autres}
                      />
                    </div>
                  )}
                  <div className="col-12">
                    <Input
                      type="number"
                      label="Montant TTC"
                      min={1}
                      id="montant"
                      placeholder="Montant TTC"
                      required
                      {...register("montant")}
                      error={errors.montant}
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      type="date"
                      label="Date d'échéance"
                      min={1}
                      id="date"
                      placeholder="Date d'échéance"
                      required
                      {...register("date")}
                      error={errors.date}
                    />
                  </div>
                </div>
                <div className="container-btn-modal row mt-5">
                  <div className="col-md-3">
                    <button
                      className="btn btn-cancelled"
                      data-bs-dismiss="modal"
                      type="button"
                      onClick={() => handleReset()}
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="col-md-6 offset-md-3 flex-r">
                    <BtnSubmit
                      label={item ? "Modifier" : "Envoyer"}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterFactureModal;
