import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, closeModal, onHide } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import {
    FaqFormData,
    faqFormData
} from "../../../../../utils/api/faq/faq.type";
import { useCreateFaqMutation } from "../../../../../utils/api/faq/faq.api";
import { useAppSelector } from "../../../../../redux/hooks";


export function useCrudFaq(item?: FaqFormData) {
  const validationSchema = yup.object().shape({
    question: yup.string().required().label("question"),
    reponse: yup.string().required().label("reponse"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<FaqFormData>({
    resolver: yupResolver(validationSchema),
  });
  const user = useAppSelector((s) => s.user.user);
  const [addOrUpdateFaq, { isLoading, isSuccess, error, isError }] =
  useCreateFaqMutation();

  useEffect(() => {
    console.log('le user connecte', user)
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Type ${item ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        closeModal(
          item ? `AddTypeFaqModal${item?.slug}` : "AddTypeFaqModal"
        );
        if (!item) {
          reset();
        }
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      setValue("question", item?.question);
      setValue("reponse", item?.reponse);
    }
  }, [item]);


  const onSubmit = (data: faqFormData) => {
    const username = user?.username;
    if (username) {
      data.user = username;

      addOrUpdateFaq({ slug: item?.slug, data: data });
    }
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    reset,
  };
}