import moment from "moment";
import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  useAddOrUpdateDeletedUserMutation,
  useRequestFromLocation,
} from "../../../../utils/api/user/user.api";
import { SuppressionType } from "../../../../utils/api/user/user.type";
import { Color } from "../../../../utils/theme";
import { createMarkup, getAvatarFromUser } from "../../../../utils/Utils";

function AccountManagerDetails() {
  const [sendData, { isLoading: loading, isSuccess, isError, error, data }] =
    useAddOrUpdateDeletedUserMutation();
  const [item, isLoading, slug, findBySlug] = useRequestFromLocation();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title:
          data?.status === "acceptee"
            ? "La suppression de ce compte a été validée avec succés !"
            : "la suppression de ce compte a été rejetée avec succés !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }

    if (isError) {
      const err = error as any;
    // console.log("error", err);
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [loading]);

  const validateOrRejectDeleted = async (status: SuppressionType["status"]) => {
    let active = status === "acceptee" ? false : true;
    await Swal.fire({
      title: `Êtes vous sûr de vouloir ${
        status === "acceptee" ? "valider" : "refuser"
      } la suppression de ce compte ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          slug: item?.user?.slug,
          status: status,
          is_active: active,
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
    // console.log(result, "archiver");
    });
  };
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4
            className="kanimmo-dash-locataire-requetes-header-titre"
            onClick={() => navigate(-1)}
          >
            <BiArrowBack />
            Retour
          </h4>
        </div>
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="kanimmo-dash-locataire-param-main-page-container ps-0 py-3">
              {/* Start */}
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex gap-3 mb-3 justify-content-end">
                    <div>
                      {item?.status === "nouvelle" && (
                        <button
                          type="button"
                          className="btn btn-valider-reservation"
                          onClick={(e) => {
                            e.preventDefault();
                            validateOrRejectDeleted("acceptee");
                          }}
                          disabled={item?.status !== "nouvelle"}
                        >
                          Valider la suppression
                        </button>
                      )}
                      {item?.status === "acceptee" && (
                        <button
                          type="button"
                          className="btn btn-valider-reservation"
                          disabled={true}
                        >
                          Compte supprimé
                        </button>
                      )}
                    </div>
                    <div>
                      {item?.status === "nouvelle" && (
                        <button
                          className="btn btn-refuser-reservation"
                          onClick={(e) => {
                            e.preventDefault();
                            validateOrRejectDeleted("refusee");
                          }}
                          disabled={item?.status !== "nouvelle"}
                        >
                          Refuser
                        </button>
                      )}
                      {item?.status === "refusee" && (
                        <button
                          className="btn btn-refuser-reservation"
                          disabled={true}
                        >
                          Demande refusée
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="card-bloc-detail-group mb-3">
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div className="text-center pt-4 pb-5">
                          <img
                            src={getAvatarFromUser(item?.user)}
                            alt="profil"
                            className="img-profil-deleted"
                          />

                          <div className="b-b-1 py-2 mx-4"></div>
                        </div>
                      </div>
                      <div className="col-md-3 pb-3">
                        <div>
                          <p className="title-text-section-right">Prénom</p>
                          <p className="value-text-section-right pb-2">
                            {item?.user?.prenom}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3 pb-3">
                        <div>
                          <p className="title-text-section-right">Nom</p>
                          <p className="value-text-section-right pb-2">
                            {item?.user?.nom}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-3 pb-3">
                        <div>
                          <p className="title-text-section-right">Email</p>
                          <p className="value-text-section-right pb-2">
                          <a className='no-link' href={`mailto:${item?.user?.email}`}>
                            {item?.user?.email}
                          </a>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3 pb-3">
                        <div>
                          <p className="title-text-section-right">Téléphone</p>
                          <p className="value-text-section-right pb-2">
                          <a className='no-link' href={`tel:${item?.user?.telephone}`}>

                            {item?.user?.telephone}
                          </a>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3 pb-3">
                        <div>
                          <p className="title-text-section-right">Adresse</p>
                          <p className="value-text-section-right pb-2">
                            {item?.user?.adresse}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3 pb-3">
                        <div>
                          <p className="title-text-section-right">
                            Date d'inscription
                          </p>
                          <p className="value-text-section-right pb-2">
                            {moment(item?.user?.created_at).format(
                              "DD/MM/YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3 pb-3">
                        <div>
                          <p className="title-text-section-right">
                            Date de la demande
                          </p>
                          <p className="value-text-section-right pb-2">
                            {moment(item?.created_at).format("DD/MM/YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="pt-4">
                      <p className="title-text-section-right">
                        Motif de la demande
                      </p>

                      <p
                        className="value-text-section-right pb-2"
                        dangerouslySetInnerHTML={createMarkup(
                          item?.motif || ""
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountManagerDetails;
