import React from 'react'
import Skeleton from 'react-loading-skeleton'

function AgenceAdminSkeleton() {
    return (
        <div className="col-md-12">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            style={{
                                fontFamily: 'Raleway',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#AAB2C8',
                            }}
                        >
                            Logo
                        </th>
                        <th
                            scope="col"
                            style={{
                                fontFamily: 'Raleway',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#AAB2C8',
                            }}
                        >
                            Nom de l’agence
                        </th>
                        <th
                            scope="col"
                            style={{
                                fontFamily: 'Raleway',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#AAB2C8',
                            }}
                        >
                            Email
                        </th>
                        <th
                            scope="col"
                            style={{
                                fontFamily: 'Raleway',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#AAB2C8',
                            }}
                        >
                            Contacts
                        </th>
                        <th
                            scope="col"
                            style={{
                                fontFamily: 'Raleway',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#AAB2C8',
                            }}
                        >
                            Date d’inscription
                        </th>
                        <th
                            scope="col"
                            style={{
                                fontFamily: 'Raleway',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#AAB2C8',
                            }}
                        >
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">
                            <Skeleton />
                        </th>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            <Skeleton />
                        </th>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            <Skeleton />
                        </th>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            <Skeleton />
                        </th>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default AgenceAdminSkeleton