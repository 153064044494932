import React, { useEffect, useState } from "react";
import "./Messagerie.css";
import { AiFillPlusSquare } from "react-icons/ai";
import DetailsMessageTabsAdmin from "./DetailsMessageTabsAdmin";
import { RiCheckDoubleFill } from "react-icons/ri";
import Profil from "../../../../assets/appImages/avatar.png";
import NewDiscussionModal from "../../../modal/agence/NewDiscussionModal";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  ChatApi,
  useChatByUserQuery,
  useUpdateCHatMutation,
} from "../../../../utils/api/chat/chat.api";
import { getAvatar, useLocationState } from "../../../../utils/Utils";
import { IUser } from "../../../../utils/api/user/user.type";
import { ChatData } from "../../../../utils/api/chat/chat.type";
import { AlertInfo } from "../../../common/Alert";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { NavLink } from "react-router-dom";

function Messagerie() {
  const userId = useAppSelector((s) => s.user.user?.id);
  const [searchParams, setSearchParams] = useSearchParams();
  const [count, setCount] = useState(0);
  const name = searchParams.get("name");
  const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        name: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  const {
    data: chatData = [],
    isLoading,
    isSuccess,
  } = useChatByUserQuery({ name });
  const [updateChat] = useUpdateCHatMutation();
  const [filteredChat, setFilteredChat] = useState<ChatData[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedChat, setSelectedChat] = useState<ChatData | null>(null);
  const dispatch = useAppDispatch();
  const sUser = useLocationState<IUser>(null);

  useEffect(() => {
    if (isSuccess) {
      setFilteredChat(chatData);
    }
  }, [chatData, isSuccess]);
  useEffect(() => {
    let newCount = 0;
    if (filteredChat?.length) {
      filteredChat.forEach((item) => {
        newCount += item?.messages?.filter(
          (el) => el?.recever?.id === userId && el?.is_read === false
        )?.length;
      });
    }
    setCount(newCount);
  }, [filteredChat]);

  useEffect(() => {
    if (!selectedUser) {
      if (sUser && userId !== sUser?.id) {
        setSelectedUser(sUser);
      } else {
        if (chatData?.length > 0) {
          setSelectedUser(chatData[0].user);
        }
      }
    }
  }, [sUser, chatData, selectedUser, userId]);
  useEffect(() => {
    if (selectedUser) {
      setSelectedChat(
        chatData.find((l) => l.user.id === selectedUser.id) || null
      );
    } else {
      setSelectedChat(null);
    }
  }, [selectedUser, chatData]);

  useEffect(() => {
    dispatch(ChatApi.util.invalidateTags(["chat"]));
  }, []);

  // useEffect(() => {
  //   if (selectedChat) {
  //     setTimeout(() => {
  //       const promises = selectedChat?.messages
  //         .filter((chat) => {
  //           return !chat.is_read && chat.sender.id !== userId;
  //         })
  //         .map((chat) => updateChat({ slug: chat.slug, is_read: true }));
  //       Promise.all(promises).then((res) =>
  //       console.log("marked as read", res)
  //       );
  //     }, 3000);
  //   }
  // }, [selectedChat, userId]);

  const marqueToutLu = async () => {
    await Swal.fire({
      title: `Voulez vous tout marqué comme lu ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        chatData?.map((item) =>
          item?.messages?.map((chat) =>
            updateChat({ slug: chat.slug, is_read: true })
          )
        );
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const messageNonLu = () => {
    setSelectedIndex(1);
    let nonLus = chatData?.filter(
      (el) =>
        el?.lastMessage?.recever?.id === userId &&
        el?.lastMessage?.is_read === false
    );

    if (nonLus?.length > 0) {
      setFilteredChat(nonLus);
      setSelectedUser(nonLus[0].user);
      setSelectedChat(
        nonLus.find((l) => l.user.id === nonLus[0].user.id) || null
      );
    }

  // console.log(nonLus);
  };

  const allMessage = () => {
    setSelectedIndex(0);
    setFilteredChat(chatData);
  };

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Messages
          </h4>
          <div className="container-btn-add">
            <button
              className="btn btn-see-more"
              data-bs-toggle="modal"
              data-bs-target="#NewDiscussionModal"
            >
              <AiFillPlusSquare style={{ fontSize: 22 }} />
              <span className="ps-2">Nouvelle discussion</span>
            </button>
          </div>
        </div>
        <div className="kanimmo-tabs-container">
          <div className="tabs-component-container">
            <div className="users-network-connect-list mt-4">
              <div className="container-btn-filter-message mb-3">
                <div className="content-btn-filter-message">
                  <button
                    className={`btn btn-filter-message ${
                      selectedIndex === 0 && "active"
                    }`}
                    onClick={() => allMessage()}
                  >
                    Tout
                  </button>
                </div>
                <div className="content-btn-filter-message">
                  {count > 0 && (
                    <button
                      className={`btn btn-filter-message ${
                        selectedIndex === 1 && "active"
                      }`}
                      type="button"
                      onClick={() => messageNonLu()}
                    >
                      Non lus
                    </button>
                  )}
                </div>
                <div className="content-btn-filter-message">
                  {count > 0 && (
                    <button
                      className="btn btn-filter-message"
                      onClick={marqueToutLu}
                    >
                      Marquer tout comme lu
                    </button>
                  )}
                </div>
              </div>
              <div className="no-view-mobile">
                <div className="row">
                  <div className="col-md-4">
                    <div className="bloc-search-message mb-3 position-relative">
                      <form>
                        <input
                          className="form-control"
                          type="search"
                          placeholder="Rechercher"
                          onChange={handleFilterByName}
                        />
                        <button type="submit" value="search">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </form>
                    </div>
                    <div className="parent-table-container-message-tabs custom-parent-table-container-message-tabs">
                      <div>
                        <p className="titre-messages-bloc ps-3 pb-3">
                          Vos discussions
                        </p>
                      </div>
                      <ul className="list-group list-group-message list-group-flush">
                        {filteredChat?.length === 0 && (
                          <AlertInfo message="Pas de discussion ouverte" />
                        )}
                        {filteredChat.map((chat) => (
                          <ContactItem
                            active={selectedChat === chat}
                            item={chat}
                            onClick={() => setSelectedUser(chat.user)}
                            key={chat.user.id}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <DetailsMessageTabsAdmin
                      user={selectedUser}
                      chat={selectedChat}
                    />
                  </div>
                </div>
              </div>
              <div className="no-view-desktop">
                <div className="bloc-content-message-mobile">
                  <div className="bloc-search-message mb-3 position-relative">
                    <form>
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Rechercher"
                        onChange={handleFilterByName}
                      />
                      <button type="submit" value="search">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </form>
                  </div>
                  <div className="parent-table-container-message-tabs custom-parent-table-container-message-tabs">
                    <div>
                      <p className="titre-messages-bloc ps-3 pb-3">
                        Vos discussions
                      </p>
                    </div>
                    <ul className="list-group list-group-message list-group-flush">
                      {chatData?.length === 0 && (
                        <AlertInfo message="Pas de discussion ouverte" />
                      )}
                      {chatData.map((chat) => (
                        <NavLink className="no-link" to={`/agence/message/${chat?.lastMessage?.slug}`} state={chat}>
                          <ContactItem
                            active={selectedChat === chat}
                            item={chat}
                            onClick={() => setSelectedUser(chat.user)}
                            key={chat.user.id}
                          />
                        </NavLink>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="NewDiscussionModal"
          aria-labelledby="NewDiscussionModalLabel"
          aria-hidden="true"
        >
          <NewDiscussionModal onSelect={setSelectedUser} />
        </div>
      </div>
    </div>
  );
}

export default Messagerie;

function ContactItem({
  item,
  onClick,
  active,
}: {
  item: ChatData;
  onClick: () => any;
  active: boolean;
}) {
  const {user} = useAppSelector((s) => s?.user)
  const send = item.lastMessage.sender.id !== item.user.id;
  const receive = item.lastMessage.recever.id !== item.user.id;
  const [notRead, setNotRead] = useState([{}]);
  useEffect(() => {
    setNotRead(item.messages?.filter((itm) => itm?.is_read === false && itm?.sender?.id !== user?.id));
  }, [item?.messages]);
  return (
    <li
      onClick={onClick}
      className={`list-group-item list-group-item-meessage linkable ${
        active && "active"
      }`}
    >
      <div>
        <div className="bloc-profil-contact">
          <div className="d-flex align-items-center w-100">
            <img
              src={getAvatar(item.user?.avatar)}
              className="img-messge-contact"
              alt="image profil contact"
            />
            <div className='"d-flex flex-column justify-content-center'>
              <p className={`nom-contact ps-3 mb-0 ${active && "active"}`}>
                {item.user.prenom} {item.user.nom}
              </p>
              <div>
                <p className="time-message-contact ps-3 mb-0">
                  {moment(item.lastMessage.created_at).calendar()}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bloc-message-contact">
          <div className="w-85">
            <p
              className="contenu-message-contact ps-5 linkable mb-0"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {item.lastMessage.content.slice(0, 100)}
            </p>
          </div>
          <div className="">
            {receive && notRead?.length > 0 && (
              <span>
                <span className="statut-message-tabs-non-lu">
                  {notRead?.length}
                </span>
              </span>
            )}
            {/* {item.count > 0 && (
              <span>
                <span className="statut-message-tabs-non-lu">{item.count}</span>
              </span>
            )} */}
            {send && (
              <span className="">
                <span
                  className={
                    notRead?.length === 0
                      ? "statut-message-tabs-traite"
                      : "statut-message-tabs-non-traite"
                  }
                >
                  <RiCheckDoubleFill />
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}
