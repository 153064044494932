import React from "react";
import EmptyHeader from "../../../landingPage/EmptyHeader";
import FrontFooter from "../../../landingPage/FrontFooter/FrontFooter";
import BreadCrumb from "../../../shared/BreadCrumb";
import "./ContactUs.css";
import { Map, Marker } from "google-maps-react";
import { GoogleApiWrapper } from "google-maps-react";
import { GoogleApikey } from "../../../../http/GoogleSheetUrl";
import ContactForm from "./ContactForm";

const ContactUs = (props: any) => {
	return (
		<div className="landing-page-component">
			<EmptyHeader />
			<div className="page-content">
				<BreadCrumb pageName="Nous contacter" title="Nous contacter" />
			</div>

			<section className="page-content-body-container container">
				<div className="online-courses-container">
					<div className="news-list-container pt-5">
						<div className="row my-5">
							{/* <div className="col-lg-4 col-md-12">
								<div className="col-left-contact-us">
									<div className="content-title-contact-us pb-4">
										<h3 className="title-contact-us">
											C O N T A C T E Z - N O U S
										</h3>
									</div>
									<div className="content-text-contact-us">
										<p className="text-contact-us">
											Lorem ipsum dolor sit amet,
											consect adipiscing elit. Non
											ut habitant ullamcorper nunc
											turpis iaculis. Quam.
										</p>
										<p className="text-contact-us">
											Lorem ipsum dolor sit amet,
											consect adipiscing elit. Non
											ut habitant ullamcorper nunc
											turpis iaculis. Quam.
										</p>
										<p className="text-contact-us">
											Lorem ipsum dolor sit amet,
											consect adipiscing elit. Non
											ut habitant ullamcorper nunc
											turpis iaculis. Quam.
										</p>
									</div>
								</div>
							</div> */}
							<div className="col-lg-8 col-md-12 mx-auto">
								<div className="col-right-contact-us">
									<ContactForm />
								</div>
							</div>
						</div>
						{/* <div className="content-map-constact-us">
							<div className="content-title-contact-us pb-4">
								<h3 className="title-contact-us">
									L O C A L I S A T I O N
								</h3>
							</div>
							<div></div>
						</div> */}
					</div>
				</div>
			</section>
			{/* <div className="col-item-map">
				<div
					style={{ height: "650px", width: "100%" }}
					className="container-map mt-3 position-relative"
				>
					<Map google={props.google} zoom={14}>
						<Marker name={"Current location"} />
					</Map>
				</div>
			</div> */}

			<FrontFooter />
		</div>
	);
};

export default GoogleApiWrapper({
	apiKey: GoogleApikey,
})(ContactUs);
