import moment from "moment";
import React, { useState } from "react";
import { useLocataireInfoDetailsFromLocation } from "../../../../../../utils/api/locataire/locataire.api";
import { useGetRappelByLocatairesQuery } from "../../../../../../utils/api/rappel/rappel.api";
import { createMarkup } from "../../../../../../utils/Utils";
import { AlertInfo } from "../../../../../common/Alert";
import Pagination from "../../../../../common/Pagination";
import DocumentRappelModal from "../../../../../modal/locataire/DocumentRappelModal";

function HistoriqueTappelStep() {
  const [page, setPage] = useState(1);
  const [item] = useLocataireInfoDetailsFromLocation();
  const { data = { results: [], count: 0 }, isLoading } =
    useGetRappelByLocatairesQuery({
      slug: item?.slug,
    });
  console.log("rapp", data);
  return (
    <div className="card-historie-paie-tab-container p-3">
      <div className="row">
        <div className="col-md-12">
          <div className="historie-requetes-tab mb-3">
            {!isLoading &&
              (data?.results?.length ? (
                data?.results?.map((rappel) => (
                  <div
                    className="historie-requetes-tab-container mb-3"
                    key={rappel?.id}
                  >
                    <div className="historie-requetes-tab-username mb-3">
                      Envoyé le
                    </div>
                    <div className="historie-requetes-tab-desc-container mb-3">
                      {rappel?.pdf === null ? (
                        <>
                          {" "}
                          <div className="col-md-3">
                            <div className="historie-requetes-tab-description">
                              {moment(rappel?.created_at).format(
                                "DD/MM/YYYY à HH:mm"
                              )}
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div
                              className="historie-requetes-tab-description"
                              dangerouslySetInnerHTML={createMarkup(
                                rappel?.message
                              )}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-md-8">
                            <div className="historie-requetes-tab-description">
                              {moment(rappel?.created_at).format(
                                "DD/MM/YYYY à HH:mm"
                              )}
                            </div>
                          </div>

                          <div className="table-actions-btn-container-commandes gap-2 d-flex">
                            <button
                              className="btn btn-action-modal-icon with-tooltip"
                              data-bs-toggle="modal"
                              data-bs-target={`#VoirRappelModal${rappel?.id}`}
                            >
                              Voir detail rappel
                            </button>
                            <div
                              className="modal fade"
                              id={`VoirRappelModal${rappel?.id}`}
                              aria-labelledby="VoirRappelModalLabel"
                              aria-hidden="true"
                            >
                              <DocumentRappelModal item={rappel} />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <AlertInfo message="Aucun rappel disponible pour le moment" />
              ))}
          </div>
        </div>
      </div>
      {!!data?.results?.length && (
        <div className="flex-r">
          <Pagination
            page={page}
            total={data?.count}
            perPage={10}
            onPageChange={(page: number) => setPage(page)}
          />
        </div>
      )}
    </div>
  );
}

export default HistoriqueTappelStep;
