import React from "react";
import { IoClose } from "react-icons/io5";
import { IEtatLieu } from "../../../utils/api/etatlieu/etatlieu.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import { Input } from "../../common/Input";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import useCrudEtatdesLieux from "../../TableauDebord/Proprietaire/ProprietaireGererParLeProprietaire/Administratif/requestForm/useCrudEtatdesLieux";

function AjoutEtatLieuxModalPQGSB({
  modalId,
  item,
}: {
  modalId: string;
  item?: IEtatLieu;
}) {
  const {
    onSubmit,
    errors,
    locataires,
    handleChangePropriete,
    handleChangeDocument,
    isLoading,
    handleReset,
    Controller,
    control,
    options,
    option,
    handleChangeLocataire,
    locataireItem,
  } = useCrudEtatdesLieux(modalId, item);
  const animatedComponents = makeAnimated();

  return (
    // <div
    //   className="modal fade"
    //   id={item ? `AjouterEtatLieuModal${item?.slug}` : "AjouterEtatLieuModal"}
    //   aria-labelledby="AjouterEtatLieuModalLabel"
    //   aria-hidden="true"
    // >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header flex-sb">
          <h5 className="modal-title">
            {item ? "Modifier l'" : "Ajouter un"} état de lieu
          </h5>
          <button
            className="close-modal auth-submit-annuler"
            data-bs-dismiss="modal"
            type="button"
          >
            <IoClose />
          </button>
        </div>
        <div className="modal-body text-start">
          <div className="container-form pt-3">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label
                      htmlFor="nombre_stock"
                      className="form-label form-label-modal-custom"
                      aria-labelledby="propriete"
                    >
                      Sélectionner la propriété
                      <span
                        className="text-danger"
                        style={{
                          fontSize: 24,
                        }}
                      >
                        *
                      </span>
                    </label>
                    <Controller
                      name="propriete"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={options}
                            placeholder="Selectionner une propriété"
                            inputClass="form-select form-select-modal-custom mb-2"
                            classNamePrefix="select-comodite"
                            onChange={(e: any) => handleChangePropriete(e)}
                            value={option}
                          />
                        );
                      }}
                    />
                    {errors?.propriete && (
                      <FormError error={errors?.propriete?.message} />
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label
                      htmlFor="nombre_stock"
                      className="form-label form-label-modal-custom"
                      aria-labelledby="propriete"
                    >
                      Locataire
                      <span
                        className="text-danger"
                        style={{
                          fontSize: 24,
                        }}
                      >
                        *
                      </span>
                    </label>
                    <Controller
                      name="locataire"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={locataires}
                            placeholder="Selectionner une locataire"
                            inputClass="form-select form-select-modal-custom mb-2"
                            classNamePrefix="select-comodite"
                            onChange={handleChangeLocataire}
                            value={locataireItem}
                          />
                        );
                      }}
                    />
                    {locataires && locataires?.length === 0 && (
                      <small className="badge text-bg-info px-2">
                        Aucun locataire trouvé pour cette propriété!
                      </small>
                    )}
                    {<FormError error={errors.locataire} />}
                  </div>
                </div>
                <div className="col-12">
                  <Input
                    type="file"
                    label="Document"
                    id="document"
                    required
                    error={errors.document}
                    onChange={handleChangeDocument}
                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                </div>
              </div>
              <div className="container-btn-modal row mt-5">
                <div className="col-md-3">
                  <button
                    className="btn btn-cancelled"
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-md-6 offset-md-3 flex-r">
                  <BtnSubmit
                    label="Envoyer"
                    isLoading={isLoading}
                    onClick={handleReset}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default AjoutEtatLieuxModalPQGSB;
