import React from "react";
// import { IRecette } from "../../../utils/api/facture/facture.type";
import moment from "moment";
import { IRecette } from "../../../../../utils/api/facture/facture.type";
import { ApiBaseUrl } from "../../../../../utils/http";

function ContratBailLocataire({ item }: any) {
  console.log({ item });

  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container-form custom-container-form">
            <div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex flex-column align-items-start mb-3">
                    <button
                      type="button"
                      className="btn-close custom-btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <hr />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <div>
                      {/* {get_url_extension(item?.pdf) === "pdf" ? (
                        <iframe
                          src={ApiBaseUrl + item?.pdf}
                          frameBorder="0"
                          width={`100%`}
                          height={500}
                          allowFullScreen
                          seamless
                          title="doc"
                        ></iframe>
                      ) : (
                        <GoogleDocs
                        file={ApiBaseUrl + item?.pdf}
                          height={"700px"}
                        />
                      )} */}
                      <iframe
                        src={ApiBaseUrl + item?.contrat}
                        frameBorder="0"
                        width={`100%`}
                        height={500}
                        allowFullScreen
                        seamless
                        title="doc"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContratBailLocataire;
