import React from "react";
import { IReseauSociaux } from "../../../../utils/api/reseauSociaux/reseauSociaux.type";
import { FaqFormData } from "../../../../utils/api/faq/faq.type";
import { BtnCloseModal, BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/CustomInputCheckbox";
import { Input } from "../../../common/Input";
import { useCrudFaq } from "../../../TableauDebord/Admin/Options/faq/useCrudFaq";   

function AjoutFaqModal({ item }: { item?: FaqFormData }) {
    const { register, onSubmit, errors, isLoading, reset } = useCrudFaq(item);
	return (
			<div className="modal-dialog modal-md modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header px-2 py-1">
						<h5 className="modal-title" id="AddTypeFaqModal">
							{item ? "Modifier " : "Ajouter " } un faq
						</h5>
						<BtnCloseModal label="" onClick={reset} /> 
					</div>
					<form action="" onSubmit={onSubmit}>
						<div className="row py-4 px-3">
							<div className="col-12 mb-3 ">
								<Input
									label="Question"
									id="tag"
									type="text"
									placeholder="Question"
									{...register("question")}
									error={errors?.question}
									className="form-control"
								/>
							</div>
							<div className="col-12 mb-4 ">
							<label
								htmlFor="content"
								className="form-label form-label-modal-custom"
							>
								Description
							</label>
                            <textarea
                                className="form-control form-control-modal-custom"
                                id="message"
                                rows={3}
                                {...register("reponse")}
                            ></textarea>
                            {<FormError error={errors.reponse} />}
							</div>

							<div className="flex-r">
								<BtnSubmit
									label="Ajouter"
									isLoading={isLoading}
									style={{ padding: "5px 10px", fontSize: 14 }}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
	);
}

export default AjoutFaqModal