import React, { useEffect, useMemo } from "react";
import { FiSend } from "react-icons/fi";
import { useNewsLetter } from "./useNewsLetter";

const NewsLetterForm = () => {
	const { register, errors, onSubmit, isLoading } = useNewsLetter();

	return (
		<form id="subscribe-newsletter-form" onSubmit={onSubmit}>
			<div className="subscribe-newsletters-form-container position-relative">
				<div className="subscribe-newsletters-form-group form-group">
					<input
						type="email"
						placeholder="Adresse mail"
						{...register("email")}
					/>
					
				</div>
				{!isLoading ? (
					<button className="bt" type="submit">
						<FiSend />
					</button>
				) : (
					<button className="bt" type="submit" disabled>
						<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					</button>
				)}
			</div>
			{errors?.email && (
				<span className="text-danger">{errors?.email?.message}</span>
			)}
		</form>
	);
};

export default NewsLetterForm;
