import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiOutlineEye } from "react-icons/ai";
import { useAppSelector } from "../../../../../redux/hooks";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";
import { useGetPaiementByAgenceBySlugQuery } from "../../../../../utils/api/paiement/paiement.api";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { formattedDate } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import { CustomPagination } from "../../../../common/CustomPagination";
import DocumentQuittanceModal from "../../../../modal/locataire/DocumentQuittanceModal";
import QuittanceSkeleton from "./QuittanceSkeleton";

function QuittanceTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const user = useAppSelector((s) => s.user.user);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetPaiementByAgenceBySlugQuery({
      slug: user?.agence_id
        ? user?.agence_id?.slug
        : user?.proprietaire_id
        ? user?.proprietaire_id?.slug
        : user?.slug,
      page: page,
      limit: perPage,
      type:
        user?.user_type === "gerant" && user?.agence_id !== null
          ? "agence"
          : user?.user_type === "gerant" && user?.proprietaire_id !== null
          ? "proprietaire"
          : user?.user_type,
    });

  const quittances = data?.results?.filter((item) => item?.quittance !== null);

  const actionFormatter: any = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <button
          className="btn btn-action-modal-icon with-tooltip"
          data-tooltip-content="Voir"
          data-bs-toggle="modal"
          data-bs-target={`#VoirQuittanceModal${row?.id}`}
        >
          <AiOutlineEye />
        </button>
        <div
          className="modal fade"
          id={`VoirQuittanceModal${row?.id}`}
          aria-labelledby="VoirQuittanceModalLabel"
          aria-hidden="true"
        >
          <DocumentQuittanceModal item={row} />
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "N°",
      formatter: (cell: any, row: any, rowIndex: number) => `#${rowIndex + 1}`,
    },
    {
      dataField: "date_du",
      text: "Date",
      style: { textAlign: "left" },
      formatter: (cell: string) => formattedDate(cell),
    },
    {
      dataField: "locataire",
      text: "Client",
      style: { textAlign: "left" },
      formatter: (cell: ILocataire) => cell?.prenom + " " + cell?.nom,
    },
    {
      dataField: "propriete",
      text: "Location",
      style: { textAlign: "left" },
      formatter: (cell: IPropriete) => cell?.nom,
    },
    {
      dataField: "created_at",
      text: "Date de paiement",
      style: { textAlign: "left" },
      formatter: (cell: string) => formattedDate(cell),
    },
    {
      dataField: "montant",
      text: "Facture",
      style: { textAlign: "left" },
      formatter: (cell: IPropriete) => cell + " FCFA",
    },
    {
      dataField: "actions",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <QuittanceSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            // data={quittances}
            data={data?.results
              ?.filter((item) => item?.quittance !== null)
              ?.slice()
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune Quittance trouvée" />
            )}
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export default QuittanceTable;
