import React, {  } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import MyDropzone, {
  ImagesView,
} from "../../../../../../common/MyDropzone";
import {
  ProprieteStepProps,
} from "../../../../../../../utils/api/propriete/propriete.type";
import { FormError } from "../../../../../../common/CustomInputCheckbox";
import { BtnSubmit } from "../../../../../../common/Button";
import { Input } from "../../../../../../common/Input";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ITypeCommodites } from "../../../../../../../utils/api/commodites/commodites.type";
import ChargesComponent from "../../../../../Agence/ProprietesAgence/proporieteTabs/ChargesComponent";
import ProprieteContent from "../../../../../Agence/ProprietesAgence/proporieteTabs/ProprieteContent";

// const typesProprietesOptions = [
// 	{
// 		label: "Villa",
// 		value: TypePropriete.villa,
// 	},
// 	{
// 		label: "Appartement",
// 		value: TypePropriete.appartement,
// 	},
// ];

function InfoDetaillee({
  register,
  errors,
  isLoading,
  description,
  pictures,
  handleChangeDescription,
  setPictures,
  navigation,
  control,
  Controller,
  commoditesList,
  commodites,
  onChangeCommodite,
  unregister,
  setValue,
}: ProprieteStepProps) {
  const animatedComponents = makeAnimated();

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Surface de la propriété (en mètre carré)"
                      id="surface"
                      min={1}
                      placeholder="Surface"
                      required
                      {...register("surface")}
                      error={errors.surface}
                    />
                  </div>

                  <ProprieteContent register={register} errors={errors} />

                  {/* ================= COMMODITES=============== */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="type_propriété"
                        className="form-label form-label-modal-custom"
                      >
                        Commodités
                      </label>
                      <Controller
                        name="commodites"
                        control={control}
                        render={({ field }: any) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              options={commoditesList?.map(
                                (com: ITypeCommodites) => ({
                                  value: com?.id,
                                  label: com?.nom,
                                })
                              )}
                              inputClass="form-control-modal-custom"
                              classNamePrefix="select-comodite"
                              onChange={onChangeCommodite}
                              value={commodites}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Lien vers la video"
                      id="url_youtube"
                      placeholder="Lien youtube"
                      {...register("url_youtube")}
                      error={errors.url_youtube}
                    />
                  </div>
                  <ChargesComponent
                    register={register}
                    unregister={unregister}
                    errors={errors}
                    setValue={setValue}
                  />
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="proprietaire"
                        className="form-label form-label-modal-custom"
                      >
                        Description{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <ReactQuill
                        className="editeur-react-quill"
                        value={description}
                        onChange={handleChangeDescription}
                      />
                      <FormError error={errors?.description} />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="mb-3">
                      <label
                        htmlFor="annonces-images"
                        className="espace-client-form-label"
                      >
                        Images{" "}
                        {/* <span className="max-img-alert text-danger">
                          (max: 4)
                        </span> */}
                      </label>
                      <MyDropzone setImages={setPictures} />
                      {/* {pictures?.length && (
                        <div className="row">
                          <ImageGrid images={pictures} />
                        </div>
                      )} */}
                      {pictures && (
                        <ImagesView setImages={setPictures} images={pictures} />
                      )}
                      <FormError error={errors.pictures} />
                    </div>
                  </div>
                </div>
                <div className="container-btn-modal row my-4">
                  <div className="col-md-4 offset-md-7">
                    <div className="d-flex gap-3">
                      {/* <GoBackButton label="Annuler" /> */}
                      <button
                        className="btn auth-submit-annuler"
                        onClick={() => navigation.go("info-propiete")}
                      >
                        Retourner
                      </button>
                      <BtnSubmit label="Enregistrer" isLoading={isLoading} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoDetaillee;
