import React, { useEffect, useState } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../../utils/type";
import GestionServicesPQGSB from "./LocataireStep/GestionServicesPQGSB";
import ListeLocatairePQGSB from "./LocataireStep/ListeLocatairePQGSB";
import "./LocatairesPQGSB.css";
import { NavLink, useSearchParams } from "react-router-dom";
import { AiFillPlusSquare } from "react-icons/ai";
import Litiges from "../Administratif/AdministratifStep/Litiges";
import AjoutLitigesModalePQGSB from "../../../../modal/agence/AjoutLitigesModalePQGSB";
import {
  isAgence,
  isProprietaireOwner,
  isProprioJuriste,
} from "../../../../../utils/Utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { AlertInfo } from "../../../../common/Alert";
import LocatairesEnCoursDeLitige from "./LocataireStep/LocatairesEnCoursDeLitige";
import { useCompareNpPropieteAndMaxOffreQuery } from "../../../../../utils/api/offer/expireataabonnement.api";
import ChangeOfferModal from "../../../Agence/OptionAgence/OptionsStep/ChangeOfferModal";
import { showModal } from "../../../../../utils/Utils";
import Swal from "sweetalert2";
import { Button } from "reactstrap";
import ListeLocataireArchivePQGSB from "./LocataireStep/ListeLocataireArchivePQGSB";
import LocataireExistantModal from "../../../../modal/agence/LocataireExistantModal";

// const steps = [
//   { id: "liste", Component: ListeLocatairePQGSB },
//   { id: "gestion-services", Component: GestionServicesPQGSB },
//   { id: "litige", Component: Litiges },
// ];

const callChangeOffreModal = (data: any, user: any) => {
  if (data?.max_offre != data?.nb_propriete) {
    Swal.fire({
      icon: "error",
      title: "Aucune propriété disponible",
      text: user?.user_type === "proprietaire" ? "Il n'y a pas de propriété disponible pour ce locataire." : "Veuillez vous rapprocher du proprietaire ",
      showConfirmButton: false,
      timer: 5000,
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Modification de l'abonnement nécessaire",
      text:
        user?.user_type === "proprietaire"
          ? `Veuillez modifier votre abonnement pour pouvoir effectuer cette opération.`
          : "Veuillez vous rapprocher du proprietaire pour pouvoir effectuer cette opération.",
      showConfirmButton: false,
      timer: 5000,
    });
    if (user?.user_type === "proprietaire") {
      showModal("changeOfferModal");
    }
  }
};

function LocatairesPQGSB() {
  const { user } = useAppSelector((s) => s?.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const props = { searchParams, setSearchParams };
  const is_proprio = useAppSelector((s) => isProprietaireOwner(s.user?.user));
  const steps = [
    { id: "locataires", Component: ListeLocatairePQGSB },
    // { id: "gestion-service", Component: GestionServicesPQGSB },
    { id: "locataire-archive", Component: ListeLocataireArchivePQGSB },
    { id: "litiges", Component: Litiges },
    { id: "locataire-litiges", Component: LocatairesEnCoursDeLitige },
  ];
  const { data, error, refetch } = useCompareNpPropieteAndMaxOffreQuery(
    user?.proprietaire_id ? user?.proprietaire_id?.slug : user.slug
  );
  // console.log("wat is data", data, user);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    refetch();
    handleActionCompleted()
  }, [refreshKey, refetch]);

  const handleActionCompleted = () => {
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const tabs = [
    !isProprioJuriste(user) ? "Locataires actifs" : null,
    !isProprioJuriste(user) ? "Locataires archivés" : null,
    // !isProprioJuriste(user) ? "Gestion des services" : null,
    isProprioJuriste(user) || is_proprio ? "Contentieux" : "",
    isProprioJuriste(user) && "Locataires en cours de contentieux",
  ];
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({
    initialStep: !isProprioJuriste(user) ? 0 : 3,
    steps,
  });
  const { Component } = step;

  // let tabs = [];
  // if (user?.user_type === "juriste") {
  //   tabs = ["Litiges", "Locataires en cours de litige"];
  // } else {
  //   tabs = ["Locataires", "Gestion des services", "Litiges"];
  // }

  if (isProprioJuriste(user) && !user?.locatair) {
    return (
      <div className="dashboard-admin-component kanimmo-tabs-container container-form-add-locataire no-access-apge">
        <div className="row container-dashboard-page">
          <AlertInfo message="Vous n'avez pas accès au données de cette page !" />
        </div>
      </div>
    );
  }

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Locataires
          </h4>
          <div className="container-btn-add">
            {data?.can_add_locataire && (
              <>
                {index === 0 && !isProprioJuriste(user) && (
                  // <NavLink
                  //   to="/proprietaire/gerer-par-le-proprietaire/locataires/ajouter-locataire"
                  //   className="btn btn-see-more"
                  // >
                  //   <AiFillPlusSquare style={{ fontSize: 22 }} />
                  //   <span className="ps-2">Ajouter un locataire moi</span>
                  // </NavLink>

                  <div className="dropdown dis-flex justify-content-end">
                    <button
                      className="btn btn-see-more dropdown-toggle btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="user-acount-dashboard-header-display g-2">
                        <AiFillPlusSquare style={{ fontSize: 22 }} />
                        <span className="ps-2">Ajouter</span>
                      </div>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-custom"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {/* <button
                        className="btn btn-see-more"
                        title="Note"
                        data-bs-toggle="modal"
                        data-bs-target="#AjoutEntreePQGSBModal"
                      >
                        <span className="ps-2">Entrée</span>
                      </button> */}

                      <NavLink
                        to="/proprietaire/gerer-par-le-proprietaire/locataires/ajouter-locataire"
                        className="btn btn-see-more"
                      >
                        {/* <AiFillPlusSquare style={{ fontSize: 22 }} /> */}
                        <span className="" style={{ fontSize: 14 }}>
                          Nouveau locataire
                        </span>
                      </NavLink>
                      <button
                        className="btn btn-see-more"
                        data-bs-toggle="modal"
                        data-bs-target="#locataireExistantModal"
                      >
                        <span className="" style={{ fontSize: 14 }}>
                          Locataire existant
                        </span>
                      </button>
                    </div>
                    <LocataireExistantModal />
                  </div>
                )}
              </>
            )}
            {!data?.can_add_locataire && (
              <>
                {index === 0 && (
                  <Button
                    data-bs-toggle="modal"
                    // data-bs-target="#AjouterProprietesModald"
                    className="btn btn-see-more"
                    onClick={() => callChangeOffreModal(data, user)}
                  >
                    <AiFillPlusSquare style={{ fontSize: 22 }} />
                    <span className="ps-2">Ajouter un locataire</span>
                  </Button>
                )}
              </>
            )}
            {index === 2 && !!isProprietaireOwner(user) && (
              <>
                <button
                  className="btn btn-see-more dropdown-toggle btn"
                  type="button"
                  data-bs-target="#AjouterLitigesModal"
                  data-bs-toggle="modal"
                >
                  <div className="user-acount-dashboard-header-display g-2">
                    <AiFillPlusSquare style={{ fontSize: 22 }} />
                    <span className="ps-2">Ajouter un contentieux</span>
                  </div>
                </button>
                <div
                  className="modal fade"
                  id="AjouterLitigesModal"
                  aria-labelledby="AjouterLitigesModalLabel"
                  aria-hidden="true"
                >
                  <AjoutLitigesModalePQGSB />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="kanimmo-tabs-container py-3">
          <ul className="tabs-container custom-tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component {...props} />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="changeOfferModal"
        aria-labelledby="changeOfferModalLabel"
        aria-hidden="true"
      >
        <ChangeOfferModal
          type={user?.user_type === "agence" ? "agence" : "proprietaire"}
        />
      </div>
    </div>
  );
}

export default LocatairesPQGSB;
