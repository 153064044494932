import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IoMdTrash } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useDelete } from "../../../../../utils/helpers";
import { IReseauSociaux } from "../../../../../utils/api/reseauSociaux/reseauSociaux.type";
import {
  useDeleteRSociauxMutation,
  useGetRSociauxQuery,
} from "../../../../../utils/api/reseauSociaux/reseauSociaux.api";
import ReseauSociauxSkeleton from "./ReseauSociauxSkeleton";
import { AlertInfo } from "../../../../common/Alert";
import AjoutRsociauxModal from "../../../../modal/admin/AjoutRsociauxModal";
import { showModal } from "../../../../../utils/Utils";

function ReseauSociauxTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading } = useGetRSociauxQuery({
    page: page,
    limit: perPage,
  });
// console.log("RSociaux", data);

  const actionFormatter: any = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <button
          className="btn btn-action-modal-icon with-tooltip"
          data-tooltip-content="Modifier"
          // data-bs-toggle="modal"
          // data-bs-target={`#AddRSocialModal${row?.slug}`}
          onClick={() => showModal(`AddRSocialModal${row?.slug}`)}
        >
          <MdEdit />
        </button>
        <DeleteRS item={row} />

        <div
          className="modal fade"
          id={`AddRSocialModal${row?.slug}`}
          aria-labelledby={`#AddRSocialModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <AjoutRsociauxModal item={row} />
        </div>
      </div>
    );
  };
  const columns = [
    {
      dataField: "url",
      text: "Url",
    },
    {
      dataField: "description",
      text: "Description",
      style: { textAlign: "left" },
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <ReseauSociauxSkeleton />}
      {!isLoading && data && (
        <>
          <BootstrapTable
            keyField="id"
            data={data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => <AlertInfo message="Aucune donnée disponible" />}
            rowStyle={{ cursor: "pointer" }}
          />
        </>
      )}
    </>
  );
}

export default ReseauSociauxTable;

export function DeleteRS({ item }: { item: IReseauSociaux }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteRSociauxMutation();
  const onDelete = useDelete<IReseauSociaux>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce réseau?",
    successMessage: "Réseau supprimé",
  });
  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <IoMdTrash />
    </button>
  );
}
