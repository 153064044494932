import React, { useRef, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { BtnSubmit } from "../../../../common/Button";
import { FormError } from "../../../../common/CustomInputCheckbox";
import GoogleInput from "../../../../common/GoogleInput";
import { Input } from "../../../../common/Input";
import { useCrudAgence } from "./useForm/useCrudAgence";
import {
  CountrySelector,
  StateSelector,
} from "volkeno-react-country-state-city";
import ErrorMessage from "../../../../common/ErrorMessage";
import { useAppSelector } from "../../../../../redux/hooks";
import { SelectInput } from "../../../../common/SelectInput";

function AjouterAgence() {
  const { user } = useAppSelector((state) => state.user);
  const userType = user?.user_type;

  const navigate = useNavigate();
  const {
    setValue,
    register,
    onSubmit,
    errors,
    isLoading,
    item,
    offres,
    onChangeAddressAgence,
    addressAgence,
    phone,
    setPhone,
    handleCountrySelect,
    handleStateSelect,
    country,
    state,
  } = useCrudAgence();
  const ref = useRef<any>();

  useEffect(() => {
    if (errors?.telephone?.message) {
      ref.current.scrollIntoView();
    }
  }, [errors?.telephone]);

  console.log("my user ty", userType);

  return (
    <div className="landing-page-container">
      <div className="container-fluid  px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            {item ? "Modifier le" : "Ajouter un"} client
          </h4>
        </div>
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Nom"
                      id="nom"
                      placeholder="Nom"
                      required
                      {...register("nom")}
                      error={errors?.nom}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Prénom"
                      id="prenom"
                      placeholder="Prénom"
                      required
                      {...register("prenom")}
                      error={errors?.prenom}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="email"
                      label="Email"
                      id="email"
                      placeholder="Email"
                      required
                      {...register("email")}
                      error={errors?.email}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="phone"
                        className="form-label form-label-modal-custom"
                        aria-labelledby="phone"
                      >
                        Téléphone
                        <span
                          ref={ref}
                          className="text-danger"
                          style={{ fontSize: 24 }}
                        >
                          *
                        </span>
                      </label>
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        containerClass="form-control form-control-modal-custom mb-3 p-0"
                        inputClass="form-control form-control-modal-custom"
                        country={"sn"}
                        placeholder=""
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        countryCodeEditable={false}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        onChange={(phone, country: any) => {
                          setPhone(phone);

                          if (
                            country?.format?.match(/\./g)?.length ===
                            phone?.length
                          ) {
                            setValue("telephone", phone);
                          }
                        }}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      <FormError error={errors?.telephone?.message} />
                    </div>
                  </div>
                  {/* <div className="col-md-6 auth-input-col">
                    <div className="form-group auth-form-group">
                      <label className="form-label form-label-login">
                        Pays
                      </label>
                      <CountrySelector
                        onChange={handleCountrySelect}
                        name="pays"
                        placeholder={item?.pays || "Pays"}
                        value={country}
                        containerClass="tx country-select-container custom-country-select-container p-0"
                      />
                      {errors?.pays && (
                        <ErrorMessage message={errors?.pays?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 auth-input-col">
                    <div className="form-group auth-form-group">
                      <label className="form-label form-label-login">
                        Région
                      </label>
                      <StateSelector
                        country={country}
                        name="region"
                        value={state}
                        placeholder={item?.region ? item?.region : "Region"}
                        countryPlaceholder="Region"
                        onChange={handleStateSelect}
                        containerClass="tx country-select-container custom-country-select-container p-0"
                      />
                      {errors?.region && (
                        <ErrorMessage message={errors?.region?.message} />
                      )}
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <label
                      htmlFor="Adresse"
                      className="form-label form-label-modal-custom"
                      aria-labelledby="Adresse"
                    >
                      Adresse
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom form-control-params-custom"
                      value={addressAgence}
                      onChange={onChangeAddressAgence}
                    />
                    <FormError error={errors?.adresse_agence?.message} />
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="pays"
                        className="form-label form-label-modal-custom"
                      >
                        Pays{" "}
                      </label>
                      <GoogleInput
                        className="form-control form-control-modal-custom"
                        placeholder="Pays"
                        value={country}
                        types={["country"]}
                        onChange={handleCountrySelect}
                      />

                      {errors?.pays && (
                        <FormError error={errors?.pays?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="region"
                        className="form-label form-label-modal-custom"
                      >
                        Région{" "}
                      </label>
                      <GoogleInput
                        className="form-control form-control-modal-custom"
                        placeholder="Région"
                        value={state}
                        types={[
                          "administrative_area_level_1",
                          "administrative_area_level_2",
                        ]}
                        onChange={handleStateSelect}
                      />

                      {errors?.region && (
                        <FormError error={errors?.region?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Code postale"
                      id="boite_postale"
                      placeholder="Code postale"
                      {...register("code_postal")}
                      error={errors?.code_postal}
                    />
                  </div>             
                  {/* <div className="col-md-6">
										<div className="mb-3">
											<label
												htmlFor="type_client"
												className="form-label form-label-modal-custom"
											>
												Type de client
											</label>
											<select
												className="form-select form-select-modal-custom"
												aria-label="Default select example"
											>
												<option selected>
													Agence
												</option>
												<option value="1">
													Propriétaire
												</option>
											</select>
										</div>
									</div> */}
                  {item ?  <div className="col-md-6">
                    {(userType === "superadmin" || userType === "admin") && (
                      // <Input
                      //   type="date"
                      //   label="Date de fin"
                      //   id="boite_postale"
                      //   {...register("date_fin")}
                      // />
                      // <div className="col-md-6">
                    // <div className="mb-3">
                    <>
                      <label
                        htmlFor="fin_bail"
                        className="form-label form-label-modal-custom"
                      >
                        Fin du bail{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <input
                        type="date"
                        placeholder="I"
                        className="form-control form-control-modal-custom"
                        id="date_fin"
                        {...register("date_fin")}
                      />
                      {errors?.date_fin && (
                        <ErrorMessage message={errors?.date_fin?.message} />
                      )}
                      </>
                    )}{" "}
                  </div> : ""}
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Nom de l’agence"
                      id="nom_agence"
                      required
                      placeholder="Nom de l’agence"
                      {...register("nom_agence")}
                      error={errors?.nom_agence}
                    />
                  </div>
                  <div className="col-md-6">
                    {(userType === "superadmin" || userType === "admin") && (
                      <SelectInput
                        options={
                          offres?.map((el) => {
                            return {
                              label: el?.max_numb
                                ? el?.min_numb + "-" + el?.max_numb
                                : "Illimité",
                              value: el?.id,
                            };
                          }) || []
                        }
                        label="Offre"
                        required
                        id="offre"
                        {...register("offre")}
                        error={errors?.offre?.message}
                      />
                    )}
                  </div>
                </div>
                <div className="container-btn-modal row my-4">
                  <div className="col-md-6 offset-md-6">
                    <div className="flex-r gap-4">
                      <button
                        className="btn auth-submit-annuler"
                        onClick={() => navigate(-1)}
                      >
                        Annuler
                      </button>
                      <BtnSubmit
                        label={item ? "Modifier" : "Envoyer"}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterAgence;
