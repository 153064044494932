import React from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";

function VillasTableSkeleton() {
  return (
    <div className="col-md-12">
      <table className="table table-striped">
        <thead>
          <tr>
            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#1F3CAD",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              N° villa
            </th>
            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#1F3CAD",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              Nom de la villa
            </th>
            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#1F3CAD",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              Emplacement
            </th>
            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#1F3CAD",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              Locataires
            </th>
            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#1F3CAD",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              Détail
            </th>
            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#1F3CAD",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <VolkenoSkeleton height={20} variant="rect" />
            </th>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <VolkenoSkeleton height={20} variant="rect" />
            </th>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <VolkenoSkeleton height={20} variant="rect" />
            </th>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <VolkenoSkeleton height={20} variant="rect" />
            </th>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={15} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={15} variant="rect" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default VillasTableSkeleton;
