import React from 'react'
import CommoditesTable from '../../../../tables/Admin/Options/Commodites/CommoditesTable';

function Commodites() {
    return (
        <div className="bg-white p-3 filter-and-table-container">
          <div>
            <CommoditesTable />
          </div>
        </div>
    );
}

export default Commodites