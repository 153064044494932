import React from "react";
import { useGetAdminProprietaireDataQuery } from "../../../../utils/api/admin/admin.api";
import ProprietaireAdminTable from "../../../tables/Admin/MesClients/ProprietaireAdminTable";
import LineChart from "./LineChart/LineChart";
import ExportUsersButton from "./ExportUsersButton";


function ProprietaireAdmin({
	searchParams,
	setSearchParams,
}: {
	searchParams: any;
	setSearchParams: any;
}) {
	const word = searchParams.get("nom_proprietaire");
	const proprio = searchParams.get("proprio");
	const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
		if (e.currentTarget.value) {
			setSearchParams({
				nom_proprietaire: e.currentTarget.value,
			});
		} else {
			setSearchParams({});
		}
	};

	const handleFilterByType = (
		e: React.FormEvent<HTMLSelectElement>
	  ): void => {
		if (e.currentTarget.value) {
		  setSearchParams({
			proprio: e.currentTarget.value,
		  });
		} else {
		  setSearchParams({});
		}
	  };

	const { data = { admin_graphe: [] } } = useGetAdminProprietaireDataQuery({});

	// console.log("data graph proprio", data)
	return (
		<>
			<div className="bg-white p-3 filter-and-table-container">
				<div className="locataire-requete-filtre-container my-4 d-flex justify-content-between align-items-md-center gap-3">
					<form className="row g-3 locataire-requete-filtre-form">
						<div className="col-auto">
							<label
								htmlFor="filtreLogement"
								className="locataire-requete-filtre-label"
							>
								Filtrer
							</label>
						</div>
						<div className="col-auto">
							<input
								type="text"
								className="form-control locataire-requete-filtre-input"
								id="filtreLogement"
								placeholder="Entrer un nom"
								onChange={handleFilterByName}
							/>
						</div>
						<div className="col-auto">
							<select
								id="villa"
								className="form-select filtre-select"
								onChange={handleFilterByType}
							>
								<option value="" selected disabled>Sélectionnez une option</option>
								<option value="">
									Tout les propriétaires
								</option>
								<option value="proprietairesqgpb">Propriétaires gérés par agence</option>
								<option value="proprietairesqgb">
									Propriétaires qui gèrent leur bien
								</option>
								<option value="proprietairesmix">
									Propriétaires mixte
								</option>
							</select>
						</div>
					</form>
					{/* <a
						className="kanimmo-admin-client-btn-link-graph"
						href={`#graph-admin-proprietaire`}
					>
						Accéder au graphique
					</a> */}
					<div className="user-acount-dashboard-header-display g-2">
                		<ExportUsersButton modelType="proprietaire" /> {/* Utilisation du composant ExportUsersButton */}
          			</div>
				</div>
				<div>
					<ProprietaireAdminTable word={word} proprio={proprio} />
				</div>
			</div>
			{/* <div className="bg-white p-5 filter-and-table-container"  id="graph-admin-proprietaire">
				<LineChart data={data?.admin_graphe} />
			</div> */}
		</>
	);
}

export default ProprietaireAdmin;
