import React from 'react'
import { useAppSelector } from '../../../../../../redux/hooks';
import EtatLieuxTable from '../../../../../tables/Locataire/Administratif/EtatLieux/EtatLieuxTable'

function EtatDesLieux() {
    const {user} = useAppSelector((s) => s.user);
// console.log('locataire', user)
  return (
    <div className="bg-white p-3">
        <div className='locataire-requete-filtre-container my-4'>
            <form className="row g-3 locataire-requete-filtre-form">
                <div className="col-auto">
                    <label
                        htmlFor="filtreLogement"
                        className="locataire-requete-filtre-label"
                    >
                        Filtrer
                    </label>
                </div>
                <div className="col-auto">
                    <input
                        type="text"
                        className="form-control locataire-requete-filtre-input"
                        id="filtreLogement"
                        placeholder="Entrer un nom"
                    />
                </div>
            </form>
        </div>
        <div>
            <EtatLieuxTable />
        </div>
    </div>
  )
}

export default EtatDesLieux