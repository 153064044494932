import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { useGetAgencesQuery } from "../../../../../../../utils/api/agence/agence.api";
import { useGetImmeublesQuery } from "../../../../../../../utils/api/immeubles/immeubles.api";
import { useGetProprietairesByAgenceQuery } from "../../../../../../../utils/api/proprietaire/proprietaire.api";
import { IProprietaire } from "../../../../../../../utils/api/proprietaire/proprietaire.type";
import { ProprieteStepProps } from "../../../../../../../utils/api/propriete/propriete.type";
import { isAgence } from "../../../../../../../utils/Utils";
import { GoBackButton } from "../../../../../../common/Button";
import { FormError } from "../../../../../../common/CustomInputCheckbox";
import GoogleInput from "../../../../../../common/GoogleInput";
import { Input } from "../../../../../../common/Input";

function InfoPropriete({
  register,
  onSubmit,
  errors,
  address,
  onChangeAddress,
  handleCountrySelect,
  handleStateSelect,
  handleCitySelect,
  country,
  state,
  city,
  propriete,
}: ProprieteStepProps) {
  const [proprietaires, setProprietaires] = useState<IProprietaire[]>();
  const [proprietes, setProprietes] = useState<
    { label: string; value: number }[] | undefined
  >();
  const [immeublesList, setImmeublesList] = useState<
    { label: string; value: any }[] | undefined
  >();
  const agence = useAppSelector((s) => s.user.user);

  const { user } = useAppSelector((s) => s?.user);

  const { data: ListeAgence } = useGetAgencesQuery({});
  const AgenceData = ListeAgence?.results
    ?.filter((item) => item?.id === user?.agence_id)
    ?.map((itm) => itm?.slug);

  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));
  const { data } = useGetProprietairesByAgenceQuery({
    slug: is_Agence ? agence?.slug : AgenceData && AgenceData[0],
  });
  useEffect(() => {
    if (data) {
      setProprietaires(data?.results);
    }
  }, [data]);

  const [showImmeubles, setShowImmeubles] = useState(false);

  const toggleShow = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value === "appartement") {
      setShowImmeubles(true);
    } else {
      setShowImmeubles(false);
    }
  };

  const { data: immeubles } = useGetImmeublesQuery({});
  useEffect(() => {
    if (immeubles) {
      const ListeImb = immeubles?.results.map((item) => {
        return {
          label: item.nom,
          value: item.id,
        };
      });
      setImmeublesList(ListeImb);
    }
  }, [immeubles]);

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Numéro"
                      id="numero"
                      placeholder="Numéro"
                      required
                      {...register("numero")}
                      error={errors?.numero}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Nom"
                      id="adress"
                      placeholder="Nom"
                      required
                      {...register("nom")}
                      error={errors?.nom}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="adress"
                        className="form-label form-label-modal-custom"
                      >
                        Adresse
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <GoogleInput
                        className="form-control form-control-modal-custom"
                        value={address}
                        onChange={onChangeAddress}
                      />
                      {errors?.adresse && <FormError error={errors?.adresse} />}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Rue"
                      id="rue"
                      placeholder="Rue"
                      required
                      {...register("rue")}
                      error={errors?.rue}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="pays"
                      className="form-label form-label-modal-custom"
                    >
                      Pays{" "}
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom"
                      placeholder="Pays"
                      value={country}
                      types={["country"]}
                      onChange={handleCountrySelect}
                    />
                    {/* <CountrySelector
                      onChange={handleCountrySelect}
                      name="pays"
                      placeholder={propriete?.pays || "Pays"}
                      value={country}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                    {errors?.pays && (
                      <FormError error={errors?.pays?.message} />
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="region"
                      className="form-label form-label-modal-custom"
                    >
                      Région{" "}
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom"
                      placeholder="Région"
                      value={state}
                      types={[
                        "administrative_area_level_1",
                        "administrative_area_level_2",
                      ]}
                      onChange={handleStateSelect}
                    />
                    {/* <StateSelector
                      country={country}
                      name="region"
                      value={state}
                      placeholder={
                        propriete?.region ? propriete?.region : "Region"
                      }
                      countryPlaceholder="Region"
                      onChange={handleStateSelect}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                    {errors?.region && (
                      <FormError error={errors?.region?.message} />
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="ville"
                      className="form-label form-label-modal-custom"
                    >
                      Ville
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    <GoogleInput
                      className="form-control form-control-modal-custom"
                      placeholder="Ville"
                      value={city}
                      types={["locality", "administrative_area_level_3"]}
                      onChange={handleCitySelect}
                    />
                    {/* <CitySelector
                      state={state}
                      name="ville"
                      value={city}
                      placeholder="Ville"
                      statePlaceholder={
                        propriete?.ville ? propriete?.ville : "Ville"
                      }
                      onChange={handleCitySelect}
                      containerClass="tx country-select-container custom-country-select-container p-0"
                    /> */}
                    {errors?.ville && (
                      <FormError error={errors?.ville?.message} />
                    )}
                  </div>

                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Code postale"
                      id="code_postale"
                      placeholder="Code postale"
                      {...register("code_postale")}
                      error={errors?.code_postale}
                    />
                  </div>
                </div>
                <div className="container-btn-modal row my-4">
                  <div className="col-md-4 offset-md-8 flex-r">
                    <div className="d-flex gap-3">
                      <GoBackButton label="Annuler" />
                      <button className="btn auth-submit-btn" type="submit">
                        Continuer
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoPropriete;
