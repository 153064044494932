import React, { useState } from "react";
import { useGetOffersQuery } from "../../../../../utils/api/offer/offer.api";
import BootstrapTable from "react-bootstrap-table-next";
import { MdEdit } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { Offer } from "../../../../../utils/api/offer/offer.type";
import { IoMdTrash } from "react-icons/io";
import { formatAmount, showModal } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import { CustomPagination } from "../../../../common/CustomPagination";
import CrudOfferModal from "../modal/CrudOfferModal";
import { UseDeleteOffer } from "../request/UseCrudOfferForm";
import OfferDetailsModal from "../modal/OfferDetailsMofal";
import TableSkeleton from "../../../../common/TableSkeleton";

function DeleteButton({ item }: { item: Offer }) {
  const onDelete = UseDeleteOffer(item);

  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
    >
      <IoMdTrash />
    </button>
  );
}
function OffresTable() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } = useGetOffersQuery({
    page,
    limit,
  });
  const actionFormatter = (cell: string, row: Offer) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes gap-2 d-flex">
          <div>
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              onClick={() => showModal(`showOfferModal${cell}`)}
            >
              <AiFillEye />
            </button>
          </div>
          <div>
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              onClick={() => showModal(`editOfferModal${cell}`)}
            >
              <MdEdit />
            </button>
          </div>

          <div>
            <DeleteButton item={row} />
          </div>
        </div>
        <CrudOfferModal modalId={`editOfferModal${cell}`} offer={row} />
        <OfferDetailsModal modalId={`showOfferModal${cell}`} offer={row} />
      </>
    );
  };

  const nameFormatter = (cell: number, row: Offer) => {
    return row?.max_numb ? cell + "-" + row?.max_numb : cell + "+";
  };

  const columns = [
    {
      dataField: "min_numb",
      text: "Nom",
      formatter: (cell: number, row: Offer) => nameFormatter(cell, row),
    },
    {
      dataField: "montant",
      text: "Montant",
      formatter: (cell: number) => formatAmount(cell),
    },
    {
      dataField: "type",
      text: "Pour",
      //   style: { textAlign: "left" },
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: string, row: Offer) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
    },
  ];
  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
            rowStyle={{ cursor: "pointer" }}
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            perPage={limit}
            onChange={(page, perPage) => {
              setLimit(perPage);
              setPage(page);
            }}
          />
        </>
      )}
    </>
  );
}

export default OffresTable;
