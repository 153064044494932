import React, { InputHTMLAttributes, useState } from "react";
import { FieldError } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdAddAPhoto } from "react-icons/md";
import { FormError } from "./CustomInputCheckbox";
type InputPropsType = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  error?: string | FieldError;
  hasSmallText?: boolean;
};
export const Input = React.forwardRef<HTMLInputElement, InputPropsType>(
  (props, ref) => {
    const {
      name,
      error,
      id,
      label,
      required,
      readOnly,
      hasSmallText = false,
      ...rest
    } = props;
    return (
      <>
        <div className="mb-3">
          <label
            htmlFor={id}
            className="form-label form-label-modal-custom"
            aria-labelledby={id}
          >
            {label}
            {required && (
              <span className="text-danger" style={{ fontSize: 24 }}>
                *
              </span>
            )}
          </label>
          <input
            className="form-control form-control-modal-custom"
            name={name}
            id={id}
            ref={ref}
            readOnly={readOnly || false}
            {...rest}
            onWheel={(event) => event.currentTarget.blur()}
          />
          {hasSmallText && (
            <small className="fs-11 text-danger">
              Veuillez renseigner ce champs pour que le nombre soit pris en
              compte!
            </small>
          )}
          {<FormError error={error} />}
        </div>
      </>
    );
  }
);

export const InputFile = React.forwardRef<HTMLInputElement, InputPropsType>(
  (props, ref) => {
    const { name, error, id, label, required, readOnly, ...rest } = props;
    return (
      <>
        <div className="mb-3">
          <label
            style={{
              border: ".2px solid #959595",
              overflow: "hidden",
              opacity: 1,
              borderRadius: "7px",
              display: "flex",
              justifyContent: "center",
              padding: "3rem 15px",
              background: "rgb(243, 243, 243)",
              cursor: "pointer",
            }}
            htmlFor={id}
          >
            <input
              type="file"
              accept="image/*"
              hidden
              name={name}
              id={id}
              ref={ref}
              readOnly={readOnly || false}
              {...rest}
            />
            <div className="start-label-input-choose-file">
              <div className="flex-c">
                <MdAddAPhoto
                  style={{
                    color: "#666666",
                    fontSize: "36px",
                  }}
                />
              </div>
              <span className="first-label-input-choose-file">
                Sélectionnez un fichier.
              </span>
              <span className="second-label-input-choose-file">
                Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à
                200Mo.
              </span>
            </div>
          </label>
          {<FormError error={error} />}
        </div>
      </>
    );
  }
);

export const InputIcon = React.forwardRef<HTMLInputElement, InputPropsType>(
  (props, ref) => {
    const { name, error, id, label, required, readOnly, ...rest } = props;
    return (
      <>
        <div className="form-group position-relative mb-4">
          <label
            htmlFor={id}
            className="label-input custom-label-input"
            aria-labelledby={id}
          >
            {label}
            {required && (
              <span className="text-danger" style={{ fontSize: 24 }}>
                *
              </span>
            )}
          </label>
          <input
            className="form-control custom-form-control-modal custom-form-control-params-custom"
            name={name}
            id={id}
            ref={ref}
            readOnly={readOnly || false}
            {...rest}
          />
          <span
            className="input-group-text custom-kanimmo-custom-showHideIcon"
            id="basic-addon2"
          >
            <FiEdit />
          </span>
          {<FormError error={error} />}
        </div>
      </>
    );
  }
);

export const InputPassword = React.forwardRef<HTMLInputElement, InputPropsType>(
  (props, ref) => {
    const { name, error, id, label, required, readOnly, ...rest } = props;
    const [showPassword, setShowPassword] = useState<boolean>(false);
    return (
      <>
        <label
          htmlFor={id}
          className="form-label form-label-modal-custom"
          aria-labelledby={id}
        >
          {label}
          {required && (
            <span className="text-danger" style={{ fontSize: 24 }}>
              *
            </span>
          )}
        </label>
        <div className="input-group kanimmo-custom-input-groupe mb-1">
          <input
            className="form-control form-control-modal-custom"
            name={name}
            id={id}
            ref={ref}
            readOnly={readOnly || false}
            type={showPassword ? "text" : "password"}
            {...rest}
          />
          <span
            className="input-group-text kanimmo-custom-showHideIcon"
            id="basic-addon2"
            onClick={() => setShowPassword((showPassword) => !showPassword)}
          >
            {showPassword ? (
              <FaEye className="showPasswordIcon" />
            ) : (
              <FaEyeSlash className="hidePasswordIcon" />
            )}
          </span>
        </div>
        {<FormError error={error} />}
      </>
    );
  }
);

export function RequiredText() {
  return (
    <span className="text-danger" style={{ fontSize: 24 }}>
      *
    </span>
  );
}
