import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useGetAgencesQuery } from "../../../../../../utils/api/agence/agence.api";
import { ITypeCommodites } from "../../../../../../utils/api/commodites/commodites.type";
import { useGetProprietairesByAgenceQuery } from "../../../../../../utils/api/proprietaire/proprietaire.api";
import { IProprietaire } from "../../../../../../utils/api/proprietaire/proprietaire.type";
import {
  ProprieteStepProps,
  TypePropriete,
} from "../../../../../../utils/api/propriete/propriete.type";
import {
  isAgence,
  isProprietaireOwner,
  isProprioJuriste,
} from "../../../../../../utils/Utils";
import { BtnSubmit, GoBackButton } from "../../../../../common/Button";
import { FormError } from "../../../../../common/CustomInputCheckbox";
import { Input } from "../../../../../common/Input";
import MyDropzone, {
  ImageGrid,
  ImagesView,
} from "../../../../../common/MyDropzone";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ProprieteContent from "../../proporieteTabs/ProprieteContent";

function DetailsAppartement({
  register,
  onSubmit,
  errors,
  description,
  pictures,
  handleChangeDescription,
  setPictures,
  navigation,
  control,
  Controller,
  commoditesList,
  commodites,
  onChangeCommodite,
  isLoading,
}: ProprieteStepProps) {
  const [proprietaires, setProprietaires] = useState<IProprietaire[]>();
  const agence = useAppSelector((s) => s.user.user);

  const { user } = useAppSelector((s) => s?.user);
  // console.log("userID", user?.id);

  const { data: ListeAgence } = useGetAgencesQuery({});
  const AgenceData = ListeAgence?.results
    ?.filter((item) => item?.id === user?.agence_id)
    ?.map((itm) => itm?.slug);
  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));

  const { data } = useGetProprietairesByAgenceQuery({
    slug: is_Agence ? agence?.slug : AgenceData && AgenceData[0],
  });
  useEffect(() => {
    if (data) {
      setProprietaires(data?.results);
    }
  }, [data]);

  const animatedComponents = makeAnimated();

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      type="number"
                      label="Surface de la propriété (en mètre carré)"
                      id="surface"
                      step={"any"}
                      min={0}
                      placeholder="Surface"
                      required
                      {...register("surface")}
                      error={errors.surface}
                    />
                  </div>
                  <ProprieteContent register={register} errors={errors} />

                  {/* ================= COMMODITES=============== */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="type_propriété"
                        className="form-label form-label-modal-custom"
                      >
                        Commodités
                      </label>
                      <Controller
                        name="commodites"
                        control={control}
                        render={({ field }: any) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              classNamePrefix="select-comodite"
                              isMulti
                              options={commoditesList?.map(
                                (com: ITypeCommodites) => ({
                                  value: com?.id,
                                  label: com?.nom,
                                })
                              )}
                              inputClass="form-control-modal-custom"
                              onChange={onChangeCommodite}
                              value={commodites}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="url"
                      label="Lien vers la video"
                      id="url_youtube"
                      placeholder="Lien youtube"
                      {...register("url_youtube")}
                      error={errors.url_youtube}
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="description"
                        className="form-label form-label-modal-custom"
                      >
                        Description
                        <span
                          className="text-danger"
                          style={{
                            fontSize: 24,
                          }}
                        >
                          *
                        </span>
                      </label>
                      <ReactQuill
                        className="editeur-react-quill"
                        value={description}
                        onChange={handleChangeDescription}
                      />
                      <FormError error={errors?.description} />
                    </div>
                  </div>

                  <div className="col-12">
                    <MyDropzone setImages={setPictures} />
                    {/* {pictures?.length ? (
                      <div className="row">
                        <ImageGrid images={pictures} />
                      </div>
                    ) : null} */}
                    {pictures && (
                      <ImagesView images={pictures} setImages={setPictures} />
                    )}
                    <FormError error={errors.pictures} />
                  </div>
                </div>
                <div className="container-btn-modal row my-4">
                  <div className="col-md-4 offset-md-8">
                    <div className="flex-r gap-3">
                      <button
                        className="btn auth-submit-annuler"
                        onClick={() => navigation.go("info-appartement")}
                      >
                        Retourner
                      </button>

                      <BtnSubmit
                        label={
                          isProprietaireOwner(user) || isProprioJuriste(user)
                            ? "Enregistrer"
                            : "Continuer"
                        }
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsAppartement;
