import * as React from "react";
import logoHeader from "../../assets/kanimmoLogoBlancCouper.png";
import lplogo from "../../assets/appImages/lplogo.png";
import "./LandingPage.css";
import { Toaster } from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { onlogout } from "../../redux/slice/User.slice";
import Carousel from "react-multi-carousel";
import FrontFooter from "./FrontFooter/FrontFooter";
import WhatsappImg from "../../assets/icons/icon-whatsapp.png";
import { useGetHomeSliderQuery } from "../../utils/api/slider/slider.api";
import VolkenoSkeleton from "volkeno-react-skeleton";
// import { AlertInfo } from "../common/Alert";
import {
  isAgenceJuriste,
  isAgenceMarketeur,
  isProprioGerant,
} from "../../utils/Utils";
import { FiChevronDown } from "react-icons/fi";
import { HiMenuAlt1 } from "react-icons/hi";
import { RiArrowDropDownFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { UserType } from "../../utils/api/user/user.type";
import {
  getAvatar,
  isAgenceAgent,
  isProprietaireOwner,
} from "../../utils/Utils";
import { isAdminAgent } from "../../routes/router";
// import imgProperty from "../../assets/property.jpg";
import RegisterModal from "../modal/user/RegisterModal";
import { useGetNewsForVisitorsQuery } from "../../utils/api/new/news.api";

import { useMarkUserOflineMutation } from "../../utils/api/auth/auth.api";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    itemsToScroll: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    itemsToScroll: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    itemsToScroll: 1,
  },
};

const LandingPage = () => {
  const isConnected = useAppSelector((s) => !!s.user?.user?.id);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((s) => s?.user);
  const usertype = user?.user_type;

  const [markUserOfline] = useMarkUserOflineMutation();

  // const handleLogout = () => {
  //   markUserOfline({ slug: user?.slug });
  //   dispatch(onlogout());
  //   window.location.pathname = "/connexion";
  // };
  const handleLogout = async () => {
    try {
      await markUserOfline({ slug: user?.slug });
      dispatch(onlogout());
      window.location.pathname = "/connexion";
    } catch (error) {
      console.error("Error marking user offline:", error);
    }
  };
  const { data, isLoading } = useGetHomeSliderQuery();
  const { data: news } = useGetNewsForVisitorsQuery({});

  // console.log("user", user);

  return (
    <div>
      <div className="landing-page-container">
        <div
          className="container-landing-page-v1"
          id="carousel-landinng-page-v1"
        >
          <div className="container-fluid kanimmo-hp-container">
            <div className="empty-fluidy-landing-page">
              <div className="empty-headerlp landing-page-header">
                <div className="logo-item">
                  <div className="logo-container-landing-page teste dashboard-header-logo">
                    <NavLink className="navbar-brand" to="/">
                      <img
                        src={logoHeader}
                        alt="KanImmo"
                        className="app-logo-admin"
                        style={{ maxWidth: "200px", height: "auto" }}
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="menu-landingpage-container me-auto ms-auto">
                  <ul className="navbar-nav navbar-nav-header-landing-page me-auto mb-2 mb-lg-0">
                    <li className="nav-item nav-item-li">
                      <NavLink
                        to="/services"
                        className="nav-link nav-link-linkv1"
                      >
                        Services
                      </NavLink>
                    </li>
                    {news?.results?.length && news?.results?.length > 0 ? (
                      <li className="nav-item nav-item-li">
                        <NavLink
                          className="nav-link nav-link-linkv1"
                          to="/news"
                        >
                          News
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
                {!isConnected ? (
                  <>
                    <div className="auth-btn-container no-view-mobile">
                      <div className="login-btn-container">
                        <NavLink
                          to="/connexion"
                          className="btn btn-default ldpg-login-btn-landingpage auth-btns"
                        >
                          Connexion
                        </NavLink>
                      </div>
                      <div className="login-btn-container">
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#RegisterModal"
                          className="btn btn-default ldpg-register-btn auth-btns"
                        >
                          Inscription
                        </button>
                      </div>
                    </div>

                    <div className="connected-user-container no-view-desktop">
                      <div className="connected-user-content ml-auto">
                        <div className="dropdown dis-flex">
                          <button
                            className="login-btn-container dropdown-toggle custom-login-btn-container"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <div className="user-acount-dashboard-header-display">
                              <span className="navbar-toggler-icon navbar-toggler-icon-landingpage-v1">
                                <HiMenuAlt1 />
                              </span>

                              <FiChevronDown className="no-view-mobile" />
                            </div>
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-custom p-2"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div className="login-btn-container mb-2">
                              <NavLink
                                to="/connexion"
                                className="btn btn-default  auth-btns w-100"
                              >
                                Connexion
                              </NavLink>
                            </div>
                            <div className="login-btn-container">
                              <button
                                data-bs-toggle="modal"
                                data-bs-target="#RegisterModal"
                                className="btn btn-default ldpg-register-btn auth-btns"
                              >
                                Inscription
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="connected-user-container">
                    <div className="connected-user-content ml-auto">
                      <div className="dropdown dis-flex">
                        <button
                          className="user-acount-dashboard-header dropdown-toggle btn"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="user-acount-dashboard-header-display g-2">
                            <img
                              src={getAvatar(user?.avatar)}
                              className="user-avatar"
                              alt="User Avatar"
                            />
                            <span className="user-name-connect custom-user-name-connect px-2 no-view-mobile">
                              {`${user?.prenom}`}
                            </span>
                            <RiArrowDropDownFill />
                          </div>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-custom"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {usertype !== UserType.admin &&
                          usertype !== UserType.superAdmin ? (
                            <>
                              <NavLink
                                to={
                                  usertype === UserType.agence ||
                                  isAgenceAgent(user) ||
                                  isAgenceJuriste(user) ||
                                  isAgenceMarketeur(user)
                                    ? "/agence/dashboard"
                                    : usertype === UserType.locataire
                                    ? "/locataire/mon-loyer"
                                    : usertype === UserType.comptable
                                    ? "/comptable/dashboard"
                                    : isProprietaireOwner(user) ||
                                      isProprioGerant(user)
                                    ? "/proprietaire/gerer-par-le-proprietaire/dashboard"
                                    : isAdminAgent(user)
                                    ? "/admin/dashboard"
                                    : "/proprietes/gerer-par-agence/dashboard"
                                }
                                className="dropdown-item "
                              >
                                Profil
                              </NavLink>
                              <div className="dropdown-divider"></div>
                              <button
                                type="button"
                                className="dropdown-item "
                                onClick={handleLogout}
                              >
                                Deconnexion
                              </button>
                            </>
                          ) : (
                            <>
                              <NavLink
                                to={"/admin/dashboard"}
                                className="dropdown-item "
                              >
                                Profil
                              </NavLink>
                              <div className="dropdown-divider"></div>
                              <button
                                type="button"
                                className="dropdown-item "
                                onClick={handleLogout}
                              >
                                Deconnexion
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="page-couverture pb-5">
              <div className="logo-container logo-container-landing-page pt-5 pt-md-0">
                <img
                  src={lplogo}
                  alt="KanImmo, Market place immobilier"
                  className="app-logo-landing-page"
                />
              </div>
              <div className="page-couverture-contents">
                <div className="container-carousel-events">
                  <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={false}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    keyBoardControl={true}
                    arrows={true}
                    customTransition="transform 1000ms ease-in-out"
                    transitionDuration={2000}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {!isLoading &&
                      data?.map((item) => (
                        <div
                          key={item?.slug}
                          className="container-item-slide-landing-page-v1"
                          id="carousel-landinng-page-v1"
                        >
                          <div className="page-couverture-title">
                            <h1 className="couverture-title">{item?.titre}</h1>
                          </div>
                        </div>
                      ))}
                    {isLoading && <SliderCardSkeleton />}
                    {!isLoading && data && data?.length === 0 && (
                      <div
                        className="container-item-slide-landing-page-v1"
                        id="carousel-landinng-page-v1"
                      >
                        <div className="page-couverture-title">
                          <h1 className="couverture-title">
                            Première plateforme complète de gestion
                            d'immobilière au Sénégal
                          </h1>
                        </div>
                      </div>
                    )}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="RegisterModal"
          aria-labelledby="RegisterModalLabel"
          aria-hidden="true"
        >
          <RegisterModal />
        </div>
        <FrontFooter />

        <Toaster />
      </div>
    </div>
  );
};

export default LandingPage;

export const SliderCardSkeleton = () => {
  return (
    <div className="container-item-slide-landing-page-v1">
      <div className="page-couverture-title flex-c">
        <VolkenoSkeleton variant="rect" height={12} width={"50%"} />
      </div>
    </div>
  );
};
// <div className="page-couverture-actions-container">
//                 <div className="subscribe-newsletters-btn-container">
//                   <button
//                     type="button"
//                     data-bs-toggle="modal"
//                     data-bs-target="#subscribeNewsletterModal"
//                     className="btn btn-success kanimmo-btn-success"
//                   >
//                     me tenir au courant
//                   </button>
//                   <div
//                     className="modal fade"
//                     id="subscribeNewsletterModal"
//                     tabIndex={-1}
//                     aria-labelledby="subscribeModalLabel"
//                     aria-hidden="true"
//                   >
//                     <SubscribeNewsletterModal />
//                   </div>
//                 </div>
//                 <div className="play-video-btn-container">
//                   <button
//                     data-bs-toggle="modal"
//                     data-bs-target="#playVideoModal"
//                     className="btn btn-default kanimmo-btn-default"
//                   >
//                     <FiPlay className="play-video-icon" />
//                     &nbsp;
//                     <span className="play-video-label">Lire la vidéo</span>
//                   </button>
//                   <div
//                     className="modal fade"
//                     id="playVideoModal"
//                     tabIndex={-1}
//                     aria-labelledby="playVideoModalLabel"
//                     aria-hidden="true"
//                   >
//                     <PlayVideoModal />
//                   </div>
//                 </div>
//               </div>
