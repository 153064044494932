import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  ILitiges,
  LitigesFormData,
} from "../../../../../../../utils/api/litiges/litiges.type";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { useGetLocataireByProprietaireBySlugQuery } from "../../../../../../../utils/api/proprietaire/proprietaire.api";
import { useAddOrUpdateLitigeMutation } from "../../../../../../../utils/api/litiges/litiges.api";
import { Color } from "../../../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../../../utils/Utils";

function useCrudLitigePQGSB(item?: ILitiges) {
  const validationSchema = yup.object().shape({
    locataire: yup
      .number()
      .required()
      .label("Locataire")
      .typeError("Locataire est un champ obligatoire"),
    titre: yup
      .string()
      .required()
      .label("Titre")
      .typeError("Titre est un champ obligatoire"),
    description: yup.string().label("La note"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm<LitigesFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [option, setOption] = useState<any>();

  const proprietaire = useAppSelector((s) => s.user.user);
  const { data } = useGetLocataireByProprietaireBySlugQuery({
    slug: proprietaire?.slug,
  });

  const [addOrUpdateLitige, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateLitigeMutation();
  const [description, setDescription] = useState<string>("");

  const handleChangeDescription = useCallback((value: string) => {
    setDescription(value);
    setValue("description", value);
  }, []);

  const handleChangeLocataire = (e: any) => {
    setValue("locataire", e?.value);
    setOption(e);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Contentieux ${item ? "modifiée" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        reset();
        onHide(item ? "ModifierLitigesModal" : "AjouterLitigesModal");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (item?.id) {
      setValue("slug", item?.slug);
      setValue("locataire", item?.locataire?.id);
      setValue("statut", item?.statut);
      setValue("type_litige", item?.type_litige);
      setValue("date", item?.date);
      setValue("titre", item?.titre);
    }
  }, [item]);

  const onSubmit = (data: LitigesFormData) => {
    data["proprietaire"] = proprietaire?.id;
    data["date"] = new Date();
    // console.log("data data",data);
    addOrUpdateLitige({ slug: item?.slug, data: data });
  };

  const handleReset = () => {
    setOption("");
    reset();
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    locataires: data?.results,
    handleChangeDescription,
    description,
    user: proprietaire,
    control,
    Controller,
    handleChangeLocataire,
    option,
    handleReset,
  };
}

export default useCrudLitigePQGSB;
