import React from 'react'
import { IContrat } from '../../../utils/api/contrat/contrat.type'
import { BtnSubmit } from '../../common/Button';
import { FormError } from '../../common/CustomInputCheckbox';
import { Input } from '../../common/Input';
import useCrudContratAdminProprio from '../../TableauDebord/Admin/Administratif/requestForm/useCrudContratAdminPropriétaire';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { IUser } from '../../../utils/api/user/user.type';

function AjouterContratProprioModal({modalId, item}: {modalId: string, item?: IContrat}) {
    const {
		register,
		onSubmit,
		errors,
		handleChangeDocument,
		isLoading,
        listProprios,
        control,
        Controller,
        onChangeProprios,
        proprios
	} = useCrudContratAdminProprio(modalId, item);
    const animatedComponents = makeAnimated();
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-body">
                    <div className="container-form">
                        <form onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="titre"
                                            className="form-label form-label-modal-custom"
                                        >
                                            Titre du contrat
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Titre"
                                            className="form-control form-control-modal-custom"
                                            id="titre"
                                            {...register("nom_document")}
                                        />
                                        {
												<FormError
													error={
														errors.nom_document
													}
												/>
											}
                                    </div>
                                </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="type"
                                                className="form-label form-label-modal-custom"
                                            >
                                                Sélectionner le proprietaire
                                            </label>
                                            {/* <select className="form-select form-control-modal-custom" aria-label=".form-select-sm example"
                                                {...register("proprietaire")}
                                            >
                                                <option value="">Choisissez le proprietaire</option>
                                                {ListeProprietairsAdmin && ListeProprietairsAdmin?.map(item => (
                                                    <option value={item?.id} key={item?.id}>{item?.prenom + " " + item?.nom}</option>
                                                ))}
                                            </select> */}
                                            <Controller
                                                    name="proprietaire"
                                                    control={control}
                                                    render={({ field }) => {
                                                        return (
                                                        <Select
                                                            {...field}
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                            // isMulti
                                                            options={listProprios?.map((agence: IUser) => ({
                                                            value: agence?.id,
                                                            label: agence?.prenom + " " + agence?.nom,
                                                            }))}
                                                            classNamePrefix="react-select-custom"
                                                            onChange={onChangeProprios}
                                                            value={proprios}
                                                        />
                                                        );
                                                    }}
                                                    />
                                            {
                                                <FormError
                                                    error={
                                                        errors.proprietaire
                                                    }
                                                />
                                            }

                                        </div>
                                    </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                    <Input
											type="file"
											label="Document"
											id="document"
											required
											error={errors.document}
											onChange={handleChangeDocument}
											accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
										/>
                                    </div>
                                </div>
                            </div>
                            <div className="container-btn-modal row mt-5">
									<div className="col-md-3">
										<button
											className="btn btn-cancelled"
											data-bs-dismiss="modal"
											type="button"
										>
											Annuler
										</button>
									</div>
									<div className="col-md-6 offset-md-3 flex-r">
										<BtnSubmit
											label="Envoyer"
											isLoading={isLoading}
										/>
									</div>
								</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AjouterContratProprioModal