import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { Pagination } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import EtatLieuxSkeleton from "./EtatLieuxSkeleton";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  useGetEtatLieuByLocataireQuery,
  useGetEtatLieuQuery,
} from "../../../../../utils/api/etatlieu/etatlieu.api";
import { IEtatLieu } from "../../../../../utils/api/etatlieu/etatlieu.type";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { IoPrint } from "react-icons/io5";
import DocumentVueModal from "../../../../modal/locataire/DocumentVueModal";
import { AlertInfo } from "../../../../common/Alert";
import { formattedDate } from "../../../../../utils/Utils";
import { CustomPagination } from "../../../../common/CustomPagination";
import AjouterEtatLieuModal from "../../../../modal/agence/AjouterEtatLieuModal";
import AjoutEtatLieuxLocataireModal from "../../../../modal/locataire/AjoutEtatLieuxLocataireModal";
import { UseDeleteEtatLieuLocataire } from "../../../../TableauDebord/locataire/Dashboard/Administratif/AdministratifTabs/AjouterEtatDesLieux/useCrudEtatLieuxLocataire";
import { IoMdTrash } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";

function EtatLieuxTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const locataire = useAppSelector((s) => s.user.user);
  const { data = { results: [] }, isLoading } = useGetEtatLieuByLocataireQuery({
    slug: locataire?.slug,
    page: page,
    limit: perPage,
  });
  const [item, setItem] = useState<IEtatLieu>();

  const actionFormatter: any = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <div>
          <div className="d-flex gap-1">
            <button
              className="btn btn-action-requete-locataire"
              data-tooltip-content="Modifier"
              data-bs-toggle="modal"
              data-bs-target="#AjouterEtatLieuLocataireModal"
              onClick={() => setItem(row)}
            >
              <MdEdit />
            </button>
            <DeleteEtatdeLieuLocataire item={row} />
          </div>
        </div>
      </div>
    );
  };

  const documentFormatter = (cell: any, row: any) => {
    return (
      <>
        {row.document !== "undefined" && row.document !== null ? (
          <button
            className="document-formatter-container btn"
            role="button"
            data-bs-toggle="modal"
            data-bs-target={`#DocumentVueModal${row?.slug}`}
          >
            <IoPrint className="document-formatter-icon-print" />
          </button>
        ) : <span className="document-formatter-pas-quittance">Pas de document</span>}
        <div
          className="modal fade"
          id={`DocumentVueModal${row?.slug}`}
          aria-labelledby="DocumentVueModalLabel"
          aria-hidden="true"
        >
          <DocumentVueModal item={row} />
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "id",
      text: "N°",
      style: { textAlign: "left" },
      headerStyle: () => {
        return { width: "50px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell: string) => formattedDate(cell),
    },
    {
      dataField: "locataire",
      text: "Locataire",
      style: { textAlign: "left" },
      formatter: (cell: ILocataire) => cell?.prenom + " " + cell?.nom,
    },
    {
      dataField: "propriete",
      text: "Locations",
      style: { textAlign: "left" },
      formatter: (cell: IPropriete) => cell?.nom,
    },

    {
      dataField: "slug",
      text: "Document",
      style: { color: "#1AA981", textAlign: "left" },
      formatter: (cell: any, row: any) => documentFormatter(cell, row),
    },
    // {
    //   dataField: "actions",
    //   text: "Actions",
    //   style: { textAlign: "center" },
    //   formatter: (cell: any, row: any) => actionFormatter(cell, row),
    //   headerStyle: () => {
    //     return { width: "80px", whiteSpace: "normal" };
    //   },
    // },
  ];

  return (
    <>
      {isLoading && <EtatLieuxSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={perPage}
					/>
        </>
      )}
      <AjoutEtatLieuxLocataireModal item={item} setItem={setItem} />
    </>
  );
}

export default EtatLieuxTable;

function DeleteEtatdeLieuLocataire({ item }: { item: IEtatLieu }) {
	const deleteEtatdeLieu = UseDeleteEtatLieuLocataire(item);
	return (
		<button
			className="btn btn-action-administratif-proprietaire with-tooltip"
			data-tooltip-content="Supprimer"
			onClick={deleteEtatdeLieu}
		>
			<IoMdTrash />
		</button>
	);
}