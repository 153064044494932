import React from "react";
import QuittanceLocataireTable from "../../../../../tables/Locataire/Administratif/Quittance/QuittanceLocataireTable";

function QuittanceLocataire() {
  
  return (
    <div className="bg-white py-3 px-1">
      <div className="locataire-requete-filtre-container my-4">
        <div className="row">
          <QuittanceLocataireTable />
        </div>
      </div>
    </div>
  );
}

export default QuittanceLocataire;
