import React from 'react'
import ProprietesListe from '../ProprietesListe';

function VillaAppartementPQNGPSB({
  handleFilterByTypePropriete,
    handleFilterByName,
    type_propriete,
    name,
  }: {
    handleFilterByTypePropriete: () => void;
    handleFilterByName: () => void;
    type_propriete: string;
    name: string;
  }) {
  return (
    <div className="bg-white p-3 filter-and-table-container">
      <div className="d-flex gap-3">
        <div className="locataire-requete-filtre-container my-3">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <select
                id="villa"
                className="form-select filtre-select"
                onChange={handleFilterByTypePropriete}
              >
                <option value="">Tout</option>
                <option value="villa">Villa</option>
                <option value="appartement">Apartement</option>
              </select>
            </div>
          </form>
        </div>
        <div className="locataire-requete-filtre-container my-4">
          <div className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                placeholder="Entrer un nom"
                onChange={handleFilterByName}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="p-0 p-md-4">
        <ProprietesListe name={name} type_propriete={type_propriete} />
      </div>
    </div>
  )
}

export default VillaAppartementPQNGPSB
