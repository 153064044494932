import React from "react";
import ReactQuill from "react-quill";
import { IContact } from "../../../utils/api/contact/contact.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import useResponseContactForm from "../../TableauDebord/Admin/Lead/useResponseContactForm";
import { getAvatarFromUser } from "../../../utils/Utils";
import moment from "moment";
import { isAdminUser } from "../../../routes/router";

const DetailsMessageModal = ({
  modalId,
  item,
}: {
  modalId: string;
  item?: IContact;
}) => {
  const {
    errors,
    onSubmit,
    response,
    handleChangeMessage,
    isLoading,
    responses,
  } = useResponseContactForm(modalId, item);
console.log("item", item);
  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header">
         
            <p> <strong>Nom : </strong>{item?.name}</p>
            <p><strong>Email : </strong>{item?.email}</p> 
        </div>
        <div className="modal-header">
            <p><strong>Phone : </strong>{item?.phone}</p> 
            <p><strong>Type d'utilisateur : </strong>{item?.user_type ? item?.user_type : item?.autres}</p> 
        </div>
        
        <div className="form-container px-3">
          <div className="row border-bottom pb-2">
            <div className="col-md-12 form-group">
              <h2 className="modal-title-custom">Message</h2>
              <div className="admin-content-message">{item?.message}</div>
            </div>
          </div>
          <div className="mb-4">
            <form onSubmit={onSubmit}>
              <div className="row mb-4 mt-4">
                <div className="col-md-12 mb-3">
                  <div className="mb-4 form-group">
                    <label className="form-label label-form-admin">
                      Réponse
                    </label>

                    <ReactQuill
                      className="editor-cgu"
                      value={response}      
                      onChange={handleChangeMessage}
                      modules={{
                        toolbar: false,
                        clipboard: {
                          matchVisual: true, // Permet les retours à la ligne
                        },
                      }}
                      formats={["align", "paragraph"]}
                    />
                    {errors?.response && <FormError error={errors?.response} />}
                  </div>
                </div>
              </div>
              <div className="custom-container-btn-modal">
                <div className="">
                  <button
                    className="btn btn-cancelled"
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    Retour
                  </button>
                </div>
                <div className="">
                  <BtnSubmit label="Envoyer" isLoading={isLoading} />
                </div>
              </div>
            </form>
          </div>
          <div>
            {responses?.length
              ? responses.map((resp) => (
                  <div className="row my-3">
                    <div className="col-12 itm-center d-flex">
                      <img
                        loading="lazy"
                        className="avatar-auteur-response-card mx-1"
                        src={getAvatarFromUser(resp?.user)}
                        alt="Avatar"
                        width={"50"}
                        height={"50"}
                      />
                      <p className="text-response-card fs-12 fw-300 sender-name">
                        Par&nbsp;
                        <strong>
                          {isAdminUser(resp?.user)
                            ? "Admin"
                            : resp?.user?.prenom + " " + resp?.user?.nom}
                        </strong>
                        <br />
                        <span className="cm-sender-email">
                          {resp?.user?.email}{" "}
                        </span>
                        <br />
                        <span className="cm-sender-email">
                          {moment(resp?.created_at).format(
                            "DD/MM/YYYY à HH:mm"
                          )}{" "}
                        </span>
                      </p>
                    </div>
                    <div className="col-12 itm-center mx-1">
                      <p
                        className="text-response-card fs-12 fw-300"
                        style={style.resp_style}
                        dangerouslySetInnerHTML={{ __html: resp?.response }}
                      />
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsMessageModal;
const style = {
  resp_style: {
    margin: "1em 0px 0em 0em",
    fontSize: "20px",
    fontWeight: "500",
  },
  select_style: {
    display: "flex",
    alignItems: "self-start",
    flexDirection: "row-reverse",
  },
};
