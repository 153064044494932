import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { IoMdUnlock } from "react-icons/io";
import { MdModeEdit, MdOutlinePayments } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";

import { useGetProprietairesByAgenceQuery } from "../../../../utils/api/proprietaire/proprietaire.api";
import { IProprietaire } from "../../../../utils/api/proprietaire/proprietaire.type";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import { UseGeneratePassword } from "../../../TableauDebord/Agence/ProprietaireAgence/useForm/useCrudProprietaire";
import ProprietaireAgenceTableSkeleton from "./ProprietaireAgenceTableSkeleton";

export function GeneratePassword({ slug }: { slug: string }) {
  const onGenerate = UseGeneratePassword(slug);
  return (
    <button
      className="btn btn-action-modal-icon with-tooltip"
      data-tooltip-content="Génération de mot de passe"
      onClick={onGenerate}
    >
      <IoMdUnlock />
    </button>
  );
}
function ProprietaireAgenceTable({ name }: { name?: string | null }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { user } = useAppSelector((s) => s?.user);

  const { data = { results: [] }, isLoading } =
    useGetProprietairesByAgenceQuery({
      slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
      name,
      page,
      limit: perPage,
    });

  const actionFormatter = (cell: string, row: IProprietaire) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex">
          <div className="container-btn-action-icon-modal d-flex gap-1">
            {/* <Link
              to={`/agence/paiements-proprietaire/${row?.slug}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Paiements"
              state={row}
            >
              <MdOutlinePayments />
            </Link>
            <Link
              to={`/agence/modifier-proprietaire/${row?.slug}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdModeEdit />
            </Link>
            <Link
              to={`/agence/proprietaire/${row?.slug}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              state={row}
            >
              <AiFillEye />
            </Link> */}

            <Link
              to={`/agence/proprietaireinfos/${row?.slug}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              state={row}
            >
              <AiFillEye />
            </Link>
            <GeneratePassword slug={cell} />
          </div>
        </div>
      </>
    );
  };
  const columnRedirect = (cell: any, row: IProprietaire) => {
    return (
      <Link
        to={`/agence/proprietaire/${row?.slug}`}
        className="no-link"
        state={row}
      >
        {cell}
      </Link>
    );
  };
  const columns = [
    {
      dataField: "adresse",
      text: "Adresse",
      //   formatter: (cell: any, row: IProprietaire) => columnRedirect(cell, row),
    },
    {
      dataField: "nom",
      text: "Nom",
      //   formatter: (cell: any, row: IProprietaire) => columnRedirect(cell, row),
    },
    {
      dataField: "prenom",
      text: "Prénom",
      //   formatter: (cell: any, row: IProprietaire) => columnRedirect(cell, row),
    },
    {
      dataField: "telephone",
      text: "Téléphone",
      formatter: (cell: any, row: IProprietaire) => (
        <a className="no-link" href={`tel:${cell}`}>
          {cell}
        </a>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell: any, row: IProprietaire) => (
        <a className="no-link" href={`mailto:${cell}`}>
          {cell}
        </a>
      ),
    },
    // {
    //   dataField: "inscrit",
    //   text: "Inscrit ",
    // },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: IProprietaire) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <ProprietaireAgenceTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            // data={data?.results}
            data={data?.results
              ?.slice()
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
            rowStyle={{ cursor: "pointer" }}
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            perPage={perPage}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
          />
        </>
      )}
    </>
  );
}

export default ProprietaireAgenceTable;
