import React from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useStep } from "react-hooks-helper";
import DetailsAppartementPQNGPSB from "./DetailsAppartementPQNGPSB";
import { useGetAgencesQuery } from "../../../../../utils/api/agence/agence.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { isAgence, useLocationState } from "../../../../../utils/Utils";
import { isAdminUser } from "../../../../../routes/router";
import { useStepType } from "../../../../../utils/type";
import { useFindProprieteBySlugQuery } from "../../../../../utils/api/propriete/propriete.api";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import InfosDetails from "./InfosDetails";
import InfosPropriete from "./InfosPropriete";
import InfosHistorique from "./InfosHistorique";

const steps = [
  { id: "Informations", Component: InfosDetails },
  { id: "Propriété", Component: InfosPropriete },
  { id: "Historique", Component: InfosHistorique },
];

const tabs = ["Informations", "Propriété", "Historique"];
function DetailsPropriete() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  const navigate = useNavigate();

  const { user } = useAppSelector((s) => s?.user);
  const { data: ListeAgence } = useGetAgencesQuery({});
  const AgenceSlug = ListeAgence?.results
    ?.filter((item) => item?.id === user?.agence_id)
    ?.map((itm) => itm?.slug);
  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));

  const [searchParams, setSearchParams] = useSearchParams();
  const props = { searchParams, setSearchParams, AgenceSlug, is_Agence };
  // console.log("item", item)
  const itemState = useLocationState<IPropriete>(undefined);
  const { data: propriete } = useFindProprieteBySlugQuery(itemState?.slug);
  return (
    <div className="landing-page-container">
      <div className="">
        <div className="kanimmo-dash-locataire-requetes-header py-3">
          <h4
            className="kanimmo-dash-locataire-requetes-header-titre"
            onClick={() =>
              isAdminUser(user) ? navigate("/admin/mes-clients") : navigate(-1)
            }
          >
            <BiArrowBack />
            <span className="ps-3">Propriété</span>
          </h4>
        </div>
      </div>
      <div className="kanimmo-tabs-container py-3">
        <ul className="tabs-container">
          {tabs.map((tab, key) => (
            <li
              className={`tab-item  ${index === key && "tab-active-item"}`}
              key={key}
              onClick={() => go(key)}
              role="button"
            >
              {tab}
            </li>
          ))}
        </ul>
        <div className="tabs-component-container">
          <Component {...props} />
        </div>
      </div>
    </div>
  );
}

export default DetailsPropriete;
