import React from "react";
import "./DashboardAdmin.css";
import Key from "../../../../assets/icons/key.png";
import Users from "../../../../assets/icons/users.png";
import Paper from "../../../../assets/icons/paper.png";
import ClientChart from "./Charts/ClientChart";
import AparcuChart from "./Charts/AparcuChart";
import CommissionChart from "./Charts/CommissionChart";
import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { useGetAdminDataQuery } from "../../../../utils/api/admin/admin.api";
import { isAdminUser } from "../../../../routes/router";

function DashboardAdmin() {
  const { user } = useAppSelector((s) => s?.user);
  const navigate = useNavigate();
  const is_Admin = useAppSelector((s) => isAdminUser(s.user?.user));
  const { data, isLoading } = useGetAdminDataQuery({});
  React.useEffect(() => {
    if (!user?.dashboard && !is_Admin) {
      setTimeout(() => {
        navigate(-1);
      }, 50);
    }
  }, [user]);
  // console.log("donné de l'admin", data);
  return (
    <div className="dashboard-admin-component">
      <div className="row container-dashboard-page bg-white marginTop mx-1">
        <div className="col-md-12 pb-3 pr-inherit-mobile">
          <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
            <div className="row px-md-2">
              <div className="col-xl-4 col-lg-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Paper}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Total agences
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          Ce mois
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.total_agences}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Users}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Total propriétaires
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          Ce mois
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {data?.total_proprietaires}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={Paper}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Total CA
                          {/* CA agences à date */}
                        </p>
                        <p className="users-item-stat-dashboad-view mb-1">
                          Ce mois
                        </p>
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {/* {data?.total_ca} */}
                          {parseFloat(data?.total_ca || "0")?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 mb-3 dis-flex px-md-2">
                                <div className="item-stat-dashboad-view">
                                    <div className="content-stat-dashboard-view">
                                        <div className="content-item-icon-stat-dashboard-view">
                                            <img src={Users} alt="icon" className="icon-item-stat-dashboad-view" />
                                        </div>
                                        <div className="content-text-item-stat-dashboad-view ms-3">
                                            <div className="content-item-stat-dashboad-view mt-2">
                                                <p className="titre-item-stat-dashboad-view mb-1">CA propriétaires à date</p>
                                                <p className="users-item-stat-dashboad-view mb-1">Cette semaine</p>
                                            </div>
                                            <div className="icon-item-stat-dashboad-view">
                                                <p className="chiffre-item-stat-dashboad-view mb-1">123</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-lg-12 pr-inherit-mobile">
          <div className="custom-col-left-dashboard mb-3">
            <ClientChart data={data?.clientsgraphe} />
          </div>
          {/* <div className="custom-col-left-dashboard mb-3">
            <div className="container-table-locataires-non-paye">
              <CommissionChart data={data?.commissiongraphe} />
            </div>
          </div> */}
        </div>
        <div className="col-xl-4 col-lg-12 pb-3">
          <AparcuChart />
        </div>
      </div>
    </div>
  );
}

export default DashboardAdmin;
