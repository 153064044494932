import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { ConditionFormdata, ICondition } from '../../../../../../utils/api/condition/condition.type'
import { useAddConditionMutation, useDeleteConditionMutation } from '../../../../../../utils/api/condition/condition.api'
import { cleannerError, closeModal, onHide } from '../../../../../../utils/Utils'
import { Color } from '../../../../../../utils/theme'

function UseCrudCondition(modalId: string, condition?: ICondition) {
	const validationSchema = yup.object().shape({
		text: yup.string().required('Le contenu est requis.'),
        titre: yup.string().label('Titre'),
	});
    
    const {
        register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
      } = useForm<ConditionFormdata | any>({
        resolver: yupResolver(validationSchema),
      })
      const [addOrUpdateCondition, { isLoading, isSuccess, error, isError, data }] =
      useAddConditionMutation();

      const [description, setDescription] = React.useState<any>('')
      const handleChange = (value: any) => {
        setDescription(value)
        setValue('text', value)
      }
    
      useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Condition ${condition ? "modifié" : "ajouté"} avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
                if (!condition) {
                    reset();
                    // setDescription("")
                }
                setDescription("")
				closeModal(modalId);
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	// useEffect(() => {
	// 	if (condition?.id) {
	// 		setValue("text", condition?.text);
	// 		setValue("type", condition?.type);
	// 	}
	// }, [condition]);
	const onSubmit = (data: ConditionFormdata) => {
		console.log("data", data);
		addOrUpdateCondition({ slug: condition?.slug, data: data });
	};
      
      return {
        register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
        description, 
        setDescription,
        handleChange
      }
}

export default UseCrudCondition

export function UseDeleteCondition(item: ICondition) {
	const [deleteData] = useDeleteConditionMutation();
	const onDelete = async () => {
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer cette condition ?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			confirmButtonColor: Color.themeColor,
			preConfirm: () => {
				return deleteData(item?.slug);
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			if (result?.value) {
            Swal.fire({
                icon: "success",
                title: `Condition supprimée avec succès!`,
                iconColor: Color.themeColor,
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
        //   onHide("EnvoieSuppressionNoteModal");
        });
			}
		});
	};
	return onDelete;
}