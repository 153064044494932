import moment from "moment";
import { IoMdTrash } from "react-icons/io";
import ReactQuill from "react-quill";
import { useAppSelector } from "../../../../redux/hooks";
import { useDeleteResponseCommentNewMutation } from "../../../../utils/api/new/news.api";
import { IComment, IResponseComment } from "../../../../utils/api/new/news.type";
import { useDelete } from "../../../../utils/helpers";
import { getAvatar, getName } from "../../../../utils/Utils";
import { BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/CustomInputCheckbox";
import { usePostResponseComment } from "./useForm/usePostResponseComment";
export function ResponseCommentNew(props: {
	comment: IComment;
	isConnected: boolean;
	is_admin?: boolean;
	newSlug: string;
}) {
	const { comment, isConnected, is_admin, newSlug } = props;
	const { isLoading, response, handleChangeResponseCommentField, onSubmit, errors } =
		usePostResponseComment(comment, newSlug, isConnected);
	const { user } = useAppSelector((s) => s.user);
	return (
		<>
			{comment?.responses?.map((res: IResponseComment) => (
				<div className="container-response-forum mb-3" key={res?.slug}>
					<div className="comment-message-container">
						<div className="container-profil-message">
							<img
								src={getAvatar(res?.auteur?.avatar)}
								alt="Profil"
								className="img-profil-message"
							/>
						</div>
						<div className="container-content-message custom-container-content-message ps-3">
							<div className="flex-sb flex-sb-mobile">
								<div>
									<p className="profil-name">
										{res?.auteur ? getName(res?.auteur) : "Anonyme"}
									</p>
									<p className="comment-pub-date-color">
										{moment(res?.created_at)?.format(
											"DD MMMM YYYY"
										)}
									</p>
								</div>
							<div>
                          {is_admin || res?.auteur?.slug === user?.slug ? (
                            <DeleteResponseCommentNews item={res} />
                          ) : null}
                        </div>
							</div>
							<div className="container-comment-text py-2  pe-4">
								<p
									className="comment-text"
									dangerouslySetInnerHTML={{
										__html: res?.content || "",
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			))}
			{
			// isConnected && 
			!is_admin ? (
				<div className="add-comment-container">
					<div className="container-messages-number py-3">
						<p className="messages-number">AJOUTER UNE RÉPONSE</p>
					</div>
					<div className="mt-4 container-add-comment-forum">
						<div className="form-content pt-4">
							<form onSubmit={onSubmit}>
								<div className="mb-4 form-group">
									<ReactQuill
										className="editor-forum-response"
										value={response}
										onChange={
											handleChangeResponseCommentField
										}
									/>
									{errors?.content && (
										<FormError error={errors.content} />
									)}
								</div>
								<div className="btn-comment-form text-right">
									<BtnSubmit
										label="Commenter"
										isLoading={isLoading}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
}


export function DeleteResponseCommentNews({ item }: { item: IResponseComment }) {
	const [deleteItem, { isSuccess, isError, error }] =
	useDeleteResponseCommentNewMutation();
	const onDelete = useDelete<IResponseComment>({
	  item,
	  deleteItem,
	  isSuccess,
	  isError,
	  error,
	  question: "cette réponse?",
	  successMessage: "Réponse supprimée",
	});
	return (
	  <button
		className="btn btn-action-administratif-proprietaire with-tooltip"
		data-tooltip-content="Supprimer"
		onClick={onDelete}
	  >
		<IoMdTrash />
	  </button>
	);
  }