import React, { useEffect, useState } from "react";
import { useLocataireInfoDetailsFromLocation } from "../../../../../../utils/api/locataire/locataire.api";
import { IRequete } from "../../../../../../utils/api/requete/requete.type";
import { AlertInfo } from "../../../../../common/Alert";
import ChangerStatutRequeteModal from "../../../../../modal/agence/ChangerStatutRequeteModal";

function HistoriqueRequetesStep() {
  const [isLoading, setIsLoading] = useState(true);
  const [itemState, findBySlug] = useLocataireInfoDetailsFromLocation();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);
  
  const [item, setItem] = useState<IRequete>();
  // console.log("itemState", itemState)

  return (
    <div className="card-historie-paie-tab-container p-3">
      <div className="row">
        <div className="col-md-12">
          <div className="historie-requetes-tab mb-3">
            {!isLoading && itemState?.requetes?.length > 0 ?
              itemState?.requetes?.map((item: IRequete, index) => (
                <div className="historie-requetes-tab-container mb-3">
                  <div className="historie-requetes-tab-username mb-3">
                    {itemState?.prenom + " " + itemState?.nom}
                  </div>
                  <div className="historie-requetes-tab-desc-container mb-3">
                    <div className="col-md-9">
                      <div className="historie-requetes-tab-description">
                        {item?.requete}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <button
                        className={`btn message-btn ${
                          item?.statut === "non_regler"
                            ? "bg-status"
                            : item?.statut === "regler"
                            ? "bg-success"
                            : "bg-secondary"
                        }`}
                          data-bs-toggle="modal"
                          data-bs-target="#ChangeStatusRequeteModal"
                          aria-expanded="false"
                          onClick={() => setItem(item)}
                      >
                        {item?.statut === "non_regler"
                          ? "Non réglée"
                          : item?.statut === "regler"
                          ? "Réglée"
                          : "En cours"}
                      </button>
                    </div>
                  </div>
                </div>
              )) : (
                <AlertInfo message="Pas d'historique de requêtes" />
            )
            }
            
              <div
            className="modal fade"
            id="ChangeStatusRequeteModal"
            aria-labelledby="ChangeStatusRequeteModalLabel"
            aria-hidden="true"
          >
            <ChangerStatutRequeteModal
              modalId={"ChangeStatusRequeteModalLabel"}
              item={item}
              reload
            />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoriqueRequetesStep;
