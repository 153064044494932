import React from "react";
import { Offer } from "../../../../../utils/api/offer/offer.type";
import { BtnCloseModal, BtnSubmit } from "../../../../common/Button";
import { Input } from "../../../../common/Input";
import { FormError } from "../../../../common/CustomInputCheckbox";
import ReactQuill from "react-quill";
import { SelectInput } from "../../../../common/SelectInput";
import UseCrudOfferForm from "../request/UseCrudOfferForm";
import ErrorMessage from "../../../../common/ErrorMessage";
import UseAddSubscriber from "../request/UseAddSubscriber";
import { ISubscriber, SubscriberFormaData } from "../../../../../utils/api/subscriber/subscriber.type";
import { ChangeEvent, useEffect, useState } from "react";




function EdithSubscriberModal({
  modalId,
  subscriber,
}: {
  modalId: string;
  subscriber?: ISubscriber;
}) {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
  } = UseAddSubscriber(modalId, subscriber);
  

  

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header px-2 py-1">
            <h5 className="modal-title" id="SubscriberEdith">Modifiér l'abonnement de cet adherant
            </h5>
            <BtnCloseModal label=""/>
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit}>
              <div className="row py-4 px-3">


              <div className="col-md-6 mb-3 ">
                <Input
                    label="Date de fin"
                    id="date"
                    type="date"
                    {...register("date_fin")} 
                    error={errors?.date_fin?.message}  
                />
                </div>


                

                <div className="flex-r">
                  <BtnSubmit
                    label={subscriber ? "Modifier" : "Ajouter"}
                    isLoading={isLoading}
                    style={{ padding: "5px 10px", fontSize: 14 }}
                  />
                </div>
              </div>    
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EdithSubscriberModal;