import React from "react";
import moment from "moment";
import { checkExtension, formatMontant } from "../../../utils/Utils";
import { ApiBaseUrl } from "../../../utils/http";
import { IDepense } from "../../../utils/api/depense/depense.type";

function DetailsDepenseModal({ item }: { item: IDepense }) {
  //   useEffect(() => {
  //     const Proprietaires = Proprios?.results?.find(
  //       (itm: any) => itm?.id === parseInt(item?.id)
  //     );
  //     setProprietaire(Proprietaires);
  //   }, []);

  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container-form custom-container-form">
            <div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex flex-column align-items-start mb-3">
                    <div className="custom-envoie-note-date">
                      Détails dépense du{" "}
                      <span className="agence-detail-facture-titre">
                        {moment(item?.created_at).format("LL")}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn-close custom-btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <hr />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    {item?.locataire?.id && (
                      <div className="detail-facture-infos-container mb-3">
                        <div className="detail-facture-infos-name">
                          Locataire:
                        </div>
                        <div className="detail-facture-infos-value">
                          {item?.locataire?.prenom + " " + item?.locataire?.nom}
                        </div>
                      </div>
                    )}
                    {item?.nom_proprieta && (
                      <div className="detail-facture-infos-container mb-3">
                        <div className="detail-facture-infos-name">
                          propriétaire:
                        </div>
                        <div className="detail-facture-infos-value">
                          {item?.nom_proprieta}
                        </div>
                      </div>
                    )}
                    <div className="detail-facture-infos-container mb-3">
                      <div className="detail-facture-infos-name">
                        Catégorie:
                      </div>
                      <div className="detail-facture-infos-value">
                        {item?.categorie?.nom}
                      </div>
                    </div>

                    {item?.categorie?.nom !==
                      "paiement_proprietaire_manuel" && (
                      <>
                        <div className="detail-facture-infos-container mb-3">
                          <div className="detail-facture-infos-name">
                            Propriété:
                          </div>
                          <div className="detail-facture-infos-value">
                            {item?.propriete?.nom}
                          </div>
                        </div>
                        <div className="detail-facture-infos-container mb-3">
                          <div className="detail-facture-infos-name">
                            Numéro de la propriété:
                          </div>
                          <div className="detail-facture-infos-value">
                            {item?.propriete?.numero}
                          </div>
                        </div>
                      </>
                    )}

                    {item?.categorie?.nom ===
                      "paiement_proprietaire_manuel" && (
                      <>
                        <div className="detail-facture-infos-container mb-3">
                          <div className="detail-facture-infos-name">
                            propriétaire:
                          </div>
                          <div className="detail-facture-infos-value">
                            {item?.proprietaire?.prenom}{" "}
                            {item?.proprietaire?.nom}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="detail-facture-infos-container mb-3">
                      <div className="detail-facture-infos-name">Montant:</div>
                      <div className="detail-facture-infos-value">
                        {formatMontant(item?.montant)}
                      </div>
                    </div>
                    {!!item?.specification && (
                      <>
                        <div className="detail-facture-infos-container mb-3">
                          <div className="detail-facture-infos-name">
                            Spécification:
                          </div>
                          <div className="detail-facture-infos-value">
                            {item?.specification}
                          </div>
                        </div>
                      </>
                    )}

                    {!!item?.justificatif && (
                      <div className="detail-facture-infos-container mb-3 row text-start">
                        <div className="detail-facture-infos-name col-md-12">
                          Justificatif:
                        </div>
                        <div className="detail-facture-infos-value">
                          {checkExtension(ApiBaseUrl + item?.justificatif) ? (
                            <img
                              src={ApiBaseUrl + item?.justificatif}
                              width={`100%`}
                              height={500}
                              title="doc"
                            />
                          ) : (
                            <iframe
                              src={ApiBaseUrl + item?.justificatif}
                              frameBorder="0"
                              width={`100%`}
                              height={500}
                              allowFullScreen
                              seamless
                              title="doc"
                            ></iframe>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsDepenseModal;
