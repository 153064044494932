import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IoMdTrash } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useDeleteTypeProblemeMutation, useGetTypeProblemeQuery } from "../../../../../utils/api/typeProbleme/typeProbleme.api";
import { ITypeProbleme } from "../../../../../utils/api/typeProbleme/typeProbleme.type";
import { useDelete } from "../../../../../utils/helpers";
import { AlertInfo } from "../../../../common/Alert";
import { CustomPagination } from "../../../../common/CustomPagination";
import AjoutTypeProblemeModal from "../../../../modal/admin/AjoutTypeProblemeModal";
import TypeProblemeSkeleton from "./TypeProblemeSkeleton";
import { showModal } from "../../../../../utils/Utils";

function TypeProblemeTable() {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { data= { results: [] }, isLoading } = useGetTypeProblemeQuery({
        page: page,
        limit: perPage,
    });
    
    const actionFormatter: any = (cell: any, row: any) => {
        return (
          <div className="table-actions-btn-container-commandes gap-2 d-flex">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              // data-bs-toggle="modal"
              // data-bs-target={`#AddTypeProblemeModal${row?.slug}`}
              onClick={() => showModal(`AddTypeProblemeModal${row?.slug}`)}
            >
              <MdEdit />
            </button>
            <DeleteTypeProbleme item={row} />

            <div
              className="modal fade"
              id={`AddTypeProblemeModal${row?.slug}`}
              aria-labelledby={`#AddTypeProblemeModal${row?.slug}Label`}
              aria-hidden="true"
            >
              <AjoutTypeProblemeModal item={row} />
            </div>
          </div>
        );
      };
      const Datas = [{
        id: 1,
        titre: "Titre",
        description: "Description"
      }]
      const columns = [
        {
          dataField: "titre",
          text: "Titre",
        },
        {
          dataField: "description",
          text: "Description",
          style: { textAlign: "left" },
        },
        {
          dataField: "slug",
          text: "Actions",
          style: { textAlign: "center" },
          formatter: (cell: any, row: any) => actionFormatter(cell, row),
        },
      ];
    
      return (
        <>
          {isLoading && <TypeProblemeSkeleton />}
          {!isLoading && data?.results && (
            <>
              <BootstrapTable
                keyField="id"
                data={data?.results}
                columns={columns}
                bordered={true}
                condensed={false}
                responsive
                wrapperClasses="table-responsive locataire-table"
                noDataIndication={() => <AlertInfo message="Pas de données" />}
                rowStyle={{ cursor: "pointer" }}
              />
              <CustomPagination
                nbPages={data?.count}
                page={page}
                perPage={perPage}
                onChange={(page, perPage) => {
                  setPerPage(perPage);
                  setPage(page);
                }}
              />
            </>
          )}
        </>
      );
}

export default TypeProblemeTable

export function DeleteTypeProbleme({ item }: { item: ITypeProbleme }) {
    const [deleteItem, { isSuccess, isError, error }] =
      useDeleteTypeProblemeMutation();
    const onDelete = useDelete<ITypeProbleme>({
      item,
      deleteItem,
      isSuccess,
      isError,
      error,
      question: "ce type de problème?",
      successMessage: "Type de problème supprimé",
    });
    return (
      <button
        className="btn btn-action-administratif-proprietaire with-tooltip"
        data-tooltip-content="Supprimer"
        onClick={onDelete}
      >
        <IoMdTrash />
      </button>
    );
  }