import React, { ChangeEvent, useCallback, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  ISlider,
  SliderFormdata,
} from "../../../../../utils/api/slider/slider.type";
import { cleannerError, closeModal, onHide } from "../../../../../utils/Utils";
import {
  useAddOrUpdateSliderMutation,
  useDeleteSliderMutation,
} from "../../../../../utils/api/slider/slider.api";
import { Color } from "../../../../../utils/theme";
import { useAppSelector } from "../../../../../redux/hooks";

function UseCrudSlider(modalId: string, slider?: SliderFormdata) {
  const validationSchema = yup.object().shape({
    titre: yup.string().label("Titre"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
  } = useForm<SliderFormdata | any>({
    resolver: yupResolver(validationSchema),
  });
  const [addOrUpdateSlider, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateSliderMutation();
  const { user } = useAppSelector((s) => s?.user);
  const userID = user?.id;
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Slide ${slider ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        if (!slider) {
          reset();
          setMessage("");
        }
        closeModal(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: any) => {
    const fd = new FormData();

    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    // console.log("data submitted", data);
    addOrUpdateSlider({ slug: slider?.slug, data: fd });
  };

  const handleReset = () => {
    if (!slider) {
      reset();
    }
  };

  useEffect(() => {
    if (slider?.id) {
      const fields: (keyof SliderFormdata)[] = ["titre"];
      for (let field of fields) {
        register(field);
        if (field === "titre") {
          setValue(field, slider[field]);
        }
      }
    }
  }, [slider]);

  const handleChangeMessage = useCallback((value: string) => {
    setMessage(value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    reset,
    isLoading,
    handleReset,
    handleChangeMessage,
  };
}

export default UseCrudSlider;

export function UseDeleteSlider(item: ISlider) {
  const [deleteData] = useDeleteSliderMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette slide ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Slide supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          //   onHide("EnvoieSuppressionNoteModal");
        });
      }
    });
  };
  return onDelete;
}
