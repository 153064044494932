import React from "react";
import ValidationsTable from "./validationTable";

function ValidationList() {
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-tabs-locataire-container">
          <div className="tabs-component-container">
            <div className="bg-white p-3 filter-and-table-container">
              <div>
                <ValidationsTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ValidationList;
