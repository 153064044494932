import React from "react";
import { useSearchParams } from "react-router-dom";
import EtatDesLieuxTable from "../../../../tables/agence/Administratif/EtatDesLieux/EtatDesLieuxTable";

function Etatlieux({
  AgenceSlug,
  is_Agence,
}: {
  AgenceSlug: string;
  is_Agence: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const word = searchParams.get("locataire");
  const propriete = searchParams.get("propriete");
  const handleFilterByTitre = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        locataire: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  const handleFilterByPropriete = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        propriete: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  return (
    <div className="bg-white p-3">
      <div className="locataire-requete-filtre-container my-4">
        <form className="row g-3 locataire-requete-filtre-form">
          <div className="col-auto">
            <label
              htmlFor="filtreLogement"
              className="locataire-requete-filtre-label"
            >
              Filtrer
            </label>
          </div>
          <div className="col-auto">
            <input
              type="text"
              className="form-control locataire-requete-filtre-input"
              id="filtreLogement"
              placeholder="Entrer un nom"
              onChange={handleFilterByTitre}
            />
          </div>
          <div className="col-auto">
            <input
              type="text"
              className="form-control locataire-requete-filtre-input"
              id="filtreLogement"
              placeholder="Propriété"
              onChange={handleFilterByPropriete}
            />
          </div>
        </form>
      </div>
      <EtatDesLieuxTable
        locataire={word}
        propriete={propriete}
        AgenceSlug={AgenceSlug}
        isAgence={is_Agence}
      />
    </div>
  );
}

export default Etatlieux;
