export type IReseauSociaux = {
	id: number;
	slug: string;
	url: string;
	type_reseau: string;
	description: string;
};

export type ReseauSociauxFormData = Partial<{
	url: string;
	description: string;
	type_reseau: string;
	slug?: string;
}>;

export enum TypeReseau {
    facebook = 'facebook',
    linkedin = 'linkedin',
    instagram = 'instagram',
    twitter = 'twitter',
}