import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useGetAgencesQuery } from "../../../../../../utils/api/agence/agence.api";
import { useGetImmeublesByUserQuery } from "../../../../../../utils/api/immeubles/immeubles.api";
import { useGetProprietairesByAgenceQuery } from "../../../../../../utils/api/proprietaire/proprietaire.api";
import { IProprietaire } from "../../../../../../utils/api/proprietaire/proprietaire.type";
import { ProprieteStepProps } from "../../../../../../utils/api/propriete/propriete.type";
import {
  isAgence,
  isProprietaireOwner,
  isProprioJuriste,
} from "../../../../../../utils/Utils";
import { GoBackButton } from "../../../../../common/Button";
import { Input } from "../../../../../common/Input";
import ChargesComponent from "../../proporieteTabs/ChargesComponent";

function InfoAppartement({
  register,
  onSubmit,
  errors,
  setValue,
  unregister,
  proprieteItem,
}: ProprieteStepProps) {
  const [proprietaires, setProprietaires] = useState<IProprietaire[]>();
  const [proprietes, setProprietes] = useState<
    { label: string; value: number }[] | undefined
  >();
  const [immeublesList, setImmeublesList] = useState<
    { label: string; value: any }[] | undefined
  >();
  const agence = useAppSelector((s) => s.user.user);

  const { user } = useAppSelector((s) => s?.user);
  // console.log("userID", user?.id);

  const location: any = useLocation();

  const { data: ListeAgence } = useGetAgencesQuery({});
  const AgenceData = ListeAgence?.results
    ?.filter((item) => item?.id === user?.agence_id)
    ?.map((itm) => itm?.slug);

  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));
  const { data } = useGetProprietairesByAgenceQuery({
    slug: is_Agence ? agence?.slug : AgenceData && AgenceData[0],
  });
  useEffect(() => {
    if (data) {
      setProprietaires(data?.results);
    }
  }, [data]);

  // console.log("proprietaires proprietaires", proprietaires)

  const [showImmeubles, setShowImmeubles] = useState(false);

  const { data: immeubles } = useGetImmeublesByUserQuery({
    slug: user?.slug,
  });
  useEffect(() => {
    if (immeubles) {
      const ListeImb = immeubles?.immeubles?.map((item) => {
        return {
          label: item.nom,
          value: item.id,
        };
      });
      setImmeublesList(ListeImb);
    }
  }, [immeubles]);

  useEffect(() => {
    if (location?.state?.type_propriete) {
      register("type_propriete");
      setValue("type_propriete", location.state.type_propriete);
    }
    if (location?.state?.immeuble) {
      register("immeuble");
      setValue("immeuble", location.state.immeuble);
    }
  }, [location]);

  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <form onSubmit={onSubmit}>
                <div className="row">
                  {/* <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="nombre_stock"
                        className="form-label form-label-modal-custom"
                        aria-labelledby="proprietaire"
                      >
                        Sélectionner le propriétaire
                        <span
                          className="text-danger"
                          style={{
                            fontSize: 24,
                          }}
                        >
                          *
                        </span>
                      </label>
                      <select
                        id="proprietaire"
                        className="form-select form-select-modal-custom mb-2"
                        {...register("proprietaire")}
                      >
                        <option value="" selected disabled>
                          Sélectionner le propriétaire
                        </option>
                        {proprietaires?.map((item) => (
                          <option value={item.id} key={item.slug}>
                            {item.prenom + " " + item.nom}
                          </option>
                        ))}
                        <option value="9990">Nous même</option>
                      </select>

                      {<FormError error={errors.proprietaire} />}
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Numéro"
                      id="numero"
                      placeholder="Numéro"
                      required
                      {...register("numero")}
                      error={errors?.numero}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Nom de l'appartement"
                      id="nom"
                      placeholder="Nom"
                      required
                      {...register("nom")}
                      error={errors?.nom}
                    />
                  </div>
                  {!isProprietaireOwner(user) && !proprieteItem?.agence_owner && (
                    <div className="col-md-6">
                      <Input
                        type="number"
                        max={100}
                        min={0}
                        label="Taux de rémunération"
                        id="commission_agence"
                        placeholder="Taux de rémunération"
                        required
                        {...register("commission_agence")}
                        error={errors?.commission_agence}
                      />
                    </div>
                  )}

                  {/* <div className="col-md-6">
                    <Input
                      type="number"
                      label="Prix de la location"
                      id="prix_location"
                      placeholder="Prix de la location"
                      min={1}
                      required
                      {...register("prix")}
                      error={errors.prix}
                    />
                  </div> */}
                  {isProprietaireOwner(user) || isProprioJuriste(user) ? (
                    <ChargesComponent
                      register={register}
                      unregister={unregister}
                      errors={errors}
                      setValue={setValue}
                    />
                  ) : null}
                </div>
                <div className="container-btn-modal row my-4">
                  <div className="col-md-4 offset-md-8 flex-r">
                    <div className="d-flex gap-3">
                      <GoBackButton label="Annuler" />
                      <button className="btn auth-submit-btn" type="submit">
                        Continuer
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoAppartement;
