import React, { useEffect } from "react";
import {
  IRequete,
  RequeteFormData,
} from "../../../utils/api/requete/requete.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  useAddOrUpdateRequeteMutation,
  useEditRequeteMutation,
} from "../../../utils/api/requete/requete.api";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, onHide } from "../../../utils/Utils";
import { Color } from "../../../utils/theme";
import Swal from "sweetalert2";
import ErrorMessage from "../../common/ErrorMessage";
import { IoClose } from "react-icons/io5";

const Status = [
  {
    value: "non_regler",
    label: "Non réglée",
  },
  {
    value: "encours",
    label: "En cours",
  },
  {
    value: "regler",
    label: "Réglée",
  },
];

function ChangerStatutRequeteModal({
  modalId,
  item,
  reload,
}: {
  item?: IRequete;
  modalId: any;
  reload?: boolean;
}) {
  const validationSchema = yup.object().shape({
    statut: yup.string().required().label("Status"),
  });

  const [changeStatus, { isSuccess, isError, error, isLoading }] =
    useEditRequeteMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm<RequeteFormData>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (item?.id) {
      setValue("slug", item?.slug);
      setValue("statut", item?.statut);
    }
  }, [item]);

  useEffect(() => {
    if (errors) {
      return cleannerError(errors, clearErrors);
    }
  }, [errors]);

  useEffect(() => {
    if (isSuccess && reload) {
      window.location.reload()
      // location.reload();
    }
  }, [isLoading]);
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Changement de statut réussi !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        reset();
        onHide("ChangeStatusRequeteModal");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: `${err ? err?.message : "Une erreur s'est produite"}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  const onSubmit = (data: any) => {
  // console.log(data);
    changeStatus({ slug: data?.slug, data: data });
  };
  return (
    <div className="modal-dialog" id="ChangeStatusRequeteModal">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title"></h5>
          <button
            className="close-modal auth-submit-annuler"
            data-bs-dismiss="modal"
            type="button"
          >
            <IoClose />
          </button>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="titre"
                      className="form-label form-label-modal-custom"
                    >
                      statut
                    </label>
                    <select
                      className="form-control form-control-input-admin py-3"
                      id="statut"
                      {...register("statut")}
                    >
                      <option value="" selected disabled>
                        Statut
                      </option>
                      {Status?.map((option: any) => (
                        <option value={option?.value} key={option.value}>
                          {option?.label}
                        </option>
                      ))}
                    </select>
                    {errors?.titre && (
                      <ErrorMessage message={errors?.titre?.message} />
                    )}
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row">
                <div className="col w-100 d-flex justify-content-between align-items-center">
                  <div className="btn-cancelled-container">
                    <button
                      className="btn btn-cancelled"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="btn-submit-container">
                    <BtnSubmit label="Changer" isLoading={isLoading} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangerStatutRequeteModal;
