/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import key from "../../../../../assets/icons/key.png";
import "./AgencesPQNGPSB.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useAppSelector } from "../../../../../redux/hooks";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocationState } from "../../../../../utils/Utils";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";
import { AlertInfo } from "../../../../common/Alert";
import { useFindProprieteBySlugQuery } from "../../../../../utils/api/propriete/propriete.api";
import ContratFileModal from "../../../../modal/agence/ContratFileModal";
import { Link } from "react-router-dom";
import { AiFillEye, AiOutlineArrowLeft } from "react-icons/ai";
import HistoriqueVillasAP from "../../../Agence/Locataires/HistoriqueVillasAP";
import { NavLink } from "react-router-dom";

function InfosHistorique() {
  const { user } = useAppSelector((state) => state.user);
  const itemState = useLocationState<IPropriete>(undefined);

  const navigate = useNavigate();

  const { data: propriete } = useFindProprieteBySlugQuery(itemState?.slug);

  const [historique, setHistorique] = useState(false);
  // console.log("détails prop", propriete)
  const columns = [
    {
      dataField: "nom",
      text: "Nom",
      style: { textAlign: "left" },
      formatter: (cell: any, row: ILocataire) => row?.prenom + " " + row?.nom,
    },
    {
      dataField: "date_debut",
      text: "Date début",
    },
    {
      dataField: "date_fin",
      text: "Date Fin",
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-proprietes-container">
          <div className="proprietaire-component-container">
            <div className="content-detail-proprietaire">
              <div className="row">
                <div className="col-12">
                  <div className="col-right-detail-proprietaire mb-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="kanimmo-detail-appart-titre-historique-locataire mb-3">
                          Historique des locataires
                        </div>
                        {propriete?.historique?.length > 0 ? (
                          //   propriete?.historique?.map((item: ILocataire) => (
                          <BootstrapTable
                            keyField="id"
                            data={propriete?.historique}
                            columns={columns}
                            bordered={true}
                            condensed={false}
                            responsive={true}
                            wrapperClasses="table-responsive admin-table"
                            noDataIndication={() => (
                              <AlertInfo message="Aucune donnée trouvéé" />
                            )}
                          />
                        ) : (
                          // <div
                          //   className="historie-paie-tab mb-3"
                          //   key={item?.id}
                          // >
                          //   <div className="d-flex flex-column justify-content-between">
                          //     <div className="historie-paie-tab-mensulite mb-2">
                          //       {item?.prenom + " " + item?.nom}
                          //     </div>
                          //     <div className="historie-paie-tab-date">
                          //       {moment(item?.date_debut).format(
                          //         "DD/MM/YYYY"
                          //       )}{" "}
                          //       -{" "}
                          //       {moment(item?.date_fin).format("DD/MM/YYYY")}
                          //     </div>
                          //   </div>
                          // </div>
                          //   )
                          // )
                          <AlertInfo message="Pas d'historique disponible" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id={`contratFileModal${propriete?.slug}`}
                  aria-labelledby="contratFileModalLabel"
                  aria-hidden="true"
                >
                  <ContratFileModal item={propriete} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfosHistorique;

function CaracteristiqueCard({
  image,
  title,
  number,
}: {
  image: string;
  title: string;
  number: number;
}) {
  return (
    <div className="kanimmo-detail-appart-caracteristiques-image-item">
      <img
        src={image}
        alt="img illustration"
        className="kanimmo-detail-appart-caracteristiques-image"
      />
      <p className="kanimmo-detail-appart-caracteristiques-titre-image">
        {number} {title}
      </p>
    </div>
  );
}

const CardIcon = ({
  Image,
  title,
  number,
}: {
  Image: any;
  title: string;
  number: number;
}) => {
  return (
    <div className="kanimmo-detail-appart-caracteristiques-image-item">
      <Image className="kanimmo-detail-appart-caracteristiques-image app-svg-img" />
      <p className="kanimmo-detail-appart-caracteristiques-titre-image">
        {number} {title}
      </p>
    </div>
  );
};

const actionFormatter = (cell: any, row: any) => {
  console.log({ row });
  console.log({ cell });

  return (
    <>
      <div className="table-actions-btn-container-commandes d-flex">
        <div className="container-btn-action-icon-modal d-flex gap-1">
          <NavLink
            className="btn btn-see-list"
            title="Voir"
            to={`/agence/historique-locataire/${row?.slug}`}
            state={row}
          >
            Voir Historique
          </NavLink>
        </div>
      </div>
    </>
  );
};
