import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useAppSelector } from "../../../redux/hooks";
import { useGetTypeRequetesQuery } from "../../../utils/api/requete/requete.api";
import {
  ChargeRequete,
  IRequete,
} from "../../../utils/api/requete/requete.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import useAddOrUpdaterequete from "../../TableauDebord/locataire/Dashboard/Requetes/requestForm/useRequeteForm";

function AjoutRequetesModal({
  requete,
  modalId,
}: {
  requete?: IRequete;
  modalId: string;
}) {
  const { data = { results: [] } } = useGetTypeRequetesQuery({});
  const FilteredData = data?.results?.filter(
    (item: any) => item?.titre !== "autre"
  );
  const { user } = useAppSelector((s) => s?.user);
// console.log("type requete", data?.results);
  const [showAutre, setShowAutre] = useState(true);
  const [detailTypeRequete, setDetailTypeRequete] = useState<
    { label: string; value: any; charge: string }[] | undefined
  >();
  const [charge, setCharge] = useState<{ label: any }[] | undefined>();

  const {
    register,
    errors,
    onSubmit,
    isLoading,
    setValue,
    handleChangeMessage,
    message,
  } = useAddOrUpdaterequete(modalId, requete);
  useEffect(() => {
    if (requete) {
      setValue("slug", requete?.slug);
      setValue("titre", requete?.titre);
      setValue("requete", requete?.requete);
    }
  }, [requete]);

  const handleChangeTypeRequete = (e: React.FormEvent<HTMLSelectElement>) => {
    const ListeImb = data?.results
      ?.filter((itm: IRequete) => itm?.id === parseInt(e.currentTarget.value))
      ?.map((item: any) =>
        item?.detail_type_requete?.map((elem: any) => {
          return {
            label: elem.nom,
            value: elem.id,
            charge: elem.charge,
          };
        })
      );
    setDetailTypeRequete(ListeImb);
    if (e.currentTarget.value === "autre") {
      setShowAutre(true);
    } else {
      setShowAutre(false);
    }
  };
  const handleChangeDetailTypeRequete = (
    e: React.FormEvent<HTMLSelectElement>
  ) => {
    const test = detailTypeRequete?.map((item: any) =>
      item
        ?.filter((itm: any) => itm?.value === parseInt(e.currentTarget.value))
        ?.map((elm: any) => {
          return {
            label: elm?.charge,
          };
        })
    );
    setCharge(test);
    if (e.currentTarget.value === "autre") {
      setShowAutre(true);
    } else {
      setShowAutre(false);
    }
  };

  const ChargeRequete =
    showAutre === true && user?.agence_id !== null ? (
      <option value="agence">
        Agence / (Charge à déterminée par l'agence)
      </option>
    ) : showAutre === true && user?.proprietaire_id !== null ? (
      <option value="proprietaire">
        Proprietaire / (Charge à déterminée par le propriétaire)
      </option>
    ) : (
      charge
        ?.map((item) => item)
        ?.map((itm: any, index) => (
          <option value={itm[0]?.label} key={index}>
            {itm[0]?.label}
          </option>
        ))
    );
  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header flex-sb">
          <h5 className="modal-title">Ajouter une requête</h5>
          <button
            className="close-modal auth-submit-annuler"
            data-bs-dismiss="modal"
            type="button"
          >
            <IoClose />
          </button>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="titre"
                      className="form-label form-label-modal-custom"
                    >
                      Titre de la requête
                    </label>
                    <input
                      type="text"
                      placeholder="Titre"
                      className="form-control form-control-modal-custom"
                      id="titre"
                      {...register("titre")}
                    />
                    {errors?.titre && (
                      <FormError error={errors?.titre?.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="type"
                      className="form-label form-label-modal-custom"
                    >
                      Type de requête
                    </label>
                    <select
                      className="form-select form-control-modal-custom"
                      aria-label=".form-select-sm example"
                      {...register("type_requete")}
                      onChange={handleChangeTypeRequete}
                    >
                      <option value="" selected>
                        Sélectionner le type de requête
                      </option>
                      {FilteredData?.map((item: any) => (
                        <option value={item.id} key={item.id}>
                          {item.titre == "autre"
                            ? item?.description
                            : item.titre + ": " + item.description}
                        </option>
                      ))}
                    </select>
                    {errors?.type_requete && (
                      <FormError error={errors?.type_requete?.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="type"
                      className="form-label form-label-modal-custom"
                    >
                      Détail type de requête
                    </label>
                    <select
                      className="form-select form-control-modal-custom"
                      aria-label=".form-select-sm example"
                      {...register("detail_type_requete")}
                      onChange={handleChangeDetailTypeRequete}
                    >
                      <option value="" selected>
                        Sélectionner un élément
                      </option>
                      {detailTypeRequete?.map((item: any) =>
                        item?.map((itm: any) => (
                          <option value={itm?.value} key={itm?.value}>
                            {itm?.label}
                          </option>
                        ))
                      )}
                      <option value="autre">Autres</option>
                    </select>
                    {errors?.detail_type_requete && (
                      <FormError error={errors?.detail_type_requete?.message} />
                    )}
                  </div>
                </div>
                <div className={showAutre ? "d-block" : "d-none"}>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="titre"
                        className="form-label form-label-modal-custom"
                      >
                        Saisir le détail du type de requête
                      </label>
                      <input
                        type="text"
                        placeholder="Autre"
                        className="form-control form-control-modal-custom"
                        id="autre"
                        {...register("autre")}
                      />
                      {errors?.autre && (
                        <FormError error={errors?.autre?.message} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="urgence"
                      className="form-label form-label-modal-custom"
                    >
                      Urgence de la requête
                    </label>
                    <select
                      className="form-select form-control-modal-custom"
                      aria-label=".form-select-sm example"
                      {...register("urgence")}
                    >
                      <option value="" selected>
                        Urgence de la requête{" "}
                      </option>
                      {UrgenceRequetDatas?.map((item) => (
                        <option value={item?.value} key={item?.id}>
                          {item?.display_name}
                        </option>
                      ))}
                    </select>
                    {errors?.urgence && (
                      <FormError error={errors?.urgence?.message} />
                    )}
                  </div>
                </div>
                {/* <div className={!showAutre ? "d-block" : "d-none"}> */}
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="charge"
                      className="form-label form-label-modal-custom"
                    >
                      Prise en charge
                    </label>
                    <select
                      className="form-select form-control-modal-custom"
                      aria-label=".form-select-sm example"
                      {...register("charge")}
                    >
                      <option value="" selected>
                        Prise en charge{" "}
                      </option>
                      {ChargeRequete}
                    </select>
                    {errors?.charge && (
                      <FormError error={errors?.charge?.message} />
                    )}
                  </div>
                </div>
                {/* </div> */}
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="requete"
                      className="form-label form-label-modal-custom"
                    >
                      Requête
                    </label>
                    <textarea
                      className="form-control form-control-modal-custom"
                      id="requete"
                      rows={4}
                      {...register("requete")}
                    ></textarea>
                    {errors?.requete && (
                      <FormError error={errors?.requete?.message} />
                    )}
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row mt-5">
                <div className="col-md-3">
                  <button
                    className="btn btn-cancelled"
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-md-6 offset-md-3 flex-r">
                  <BtnSubmit label="Envoyer" isLoading={isLoading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutRequetesModal;

const UrgenceRequetDatas = [
  {
    id: 1,
    value: "faible",
    display_name: "Faible",
  },
  {
    id: 2,
    value: "des_que_possible",
    display_name: "Dès que possible",
  },
  {
    id: 3,
    value: "urgent",
    display_name: "Urgent",
  },
];

export const optionsChargeRequete = [
  {
    id: 1,
    label: "Locataire",
    value: ChargeRequete.locataire,
  },
  {
    id: 2,
    label: "Agence",
    value: ChargeRequete.agence,
  },
];
