import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ITypeCommodites, TypeCommoditesFormData } from "../../../../../utils/api/commodites/commodites.type";
import { useAddOrUpdateCommoditesMutation } from "../../../../../utils/api/commodites/commodites.api";
import { cleannerError, closeModal, onHide } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";

function UseCrudCommodites(item?: ITypeCommodites) {
    const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<TypeCommoditesFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdateCommodites, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateCommoditesMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Commodité ${item ? "modifiée" : "ajoutée"} avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
				closeModal(
          item ? `AddCommoditeModal${item?.slug}` : "AddCommoditeModal"
        );
				if(!item) {
					reset();
				}
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("nom", item?.nom);
			// setValue("description", item?.description);
		}
	}, [item]);
	const onSubmit = (data: TypeCommoditesFormData) => {
		console.log("data", data);
		addOrUpdateCommodites({ slug: item?.slug, data: data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
	};
}

export default UseCrudCommodites