import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { AiFillEye } from "react-icons/ai";
import { TbWorld } from "react-icons/tb";
import Facebook from "../../../../../assets/icons/facebook.png";
import Twitter from "../../../../../assets/icons/twitter.png";
import Linkedin from "../../../../../assets/icons/linkedin.png";
import "./AgencesPQNGPSB.css";
import word from "../../../../../assets/appImages/word.png";
import {
  formatMontant,
  formatPlurial,
  getAvatar,
  getImage,
  useLocationState,
} from "../../../../../utils/Utils";
import { IAgence } from "../../../../../utils/api/agence/agence.type";
import {
  useGetProprietesByAgenceQuery,
  useGetProprietesProprioByAgenceQuery,
} from "../../../../../utils/api/propriete/propriete.api";
import { AlertInfo } from "../../../../common/Alert";
import Pagination from "../../../../common/Pagination";
import ProprieteSkeleton from "./ProprieteSkeleton";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { useAppSelector } from "../../../../../redux/hooks";

function DetailsAgencePQNGPSB() {
  const agence = useLocationState<IAgence>(undefined);
  const { user } = useAppSelector((s) => s.user);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [slug, setSlug] = useState("");
  const [agenceDatas, setAgenceDatas] = useState<IAgence>();

  const { data: proprietes = { results: [] }, isLoading } =
    useGetProprietesProprioByAgenceQuery({
      slug_proprio: user?.slug,
      slug_agence: agence?.slug,
      page: page,
      limit: perPage,
    });

  const navigate = useNavigate();

  useEffect(() => {
    setSlug(agence?.slug);
    setAgenceDatas(agence);
  }, []);

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <div>
            <div
              onClick={() => navigate(-1)}
              className="kanimmo-dash-locataire-requetes-header-titre no-link"
            >
              <BiArrowBack />
              <span className="ps-3">{agenceDatas?.nom_agence}</span>
            </div>
            <span className="kanimmo-detail-agence-adresse pt-2">
              {agenceDatas?.adresse_agence}
            </span>
          </div>
          {/* <div>
            <button className="btn custom-btn-contrat">
              <img src={word} alt="img-word" style={{ width: "22px" }} />{" "}
              Mandat de gérance
            </button>
          </div> */}
        </div>
        <div className="kanimmo-proprietes-container">
          <div className="proprietaire-component-container">
            <div className="content-detail-proprietaire">
              <div className="row">
                <div className="col-md-4">
                  <div className="col-left-detail-proprietaire">
                    <div className="content-title-detail-proprietaire">
                      <p className="title-detail-proprietaire">
                        Information de l’agence
                      </p>
                    </div>
                    <div className="text-center pt-3">
                      <div className="content-pp-proprietaire pb-2">
                        <img
                          src={getAvatar(agenceDatas?.avatar)}
                          alt="Avatar"
                          className="pp-proprietaire"
                        />
                      </div>
                      <p className="title-detail-proprietaire">
                        {agenceDatas?.prenom + " " + agenceDatas?.nom}
                      </p>
                      <p className="title-detail-proprietaire">
                        {agenceDatas?.adresse}
                      </p>
                    </div>
                    <div className="custom-btn-social-media-container my-2">
                      <span className="input-group-text input-group-text-img">
                        <img
                          src={Facebook}
                          alt="social media"
                          className="social-media-contact"
                        />
                      </span>
                      <span className="input-group-text input-group-text-img">
                        <img
                          src={Linkedin}
                          alt="social media"
                          className="social-media-contact"
                        />
                      </span>
                      <span className="input-group-text input-group-text-img">
                        <img
                          src={Twitter}
                          alt="social media"
                          className="social-media-contact"
                        />
                      </span>
                    </div>
                    <div className="content-info-proprietaire">
                      {agenceDatas?.site !== null && (
                        <div className="content-info-prprietaire-item mb-2">
                          <TbWorld />
                          <span className="ps-3">
                            <a
                              className="no-link"
                              href={`${agenceDatas?.site}`}
                              target="_blank"
                            >
                              {agenceDatas?.site}
                            </a>
                          </span>
                        </div>
                      )}
                      {agenceDatas?.email !== null && (
                        <div className="content-info-prprietaire-item mb-2">
                          <MdOutlineMailOutline />
                          <span className="ps-3">
                            <a
                              className="no-link"
                              href={`mailto${agenceDatas?.email}`}
                            >
                              {agenceDatas?.email}
                            </a>
                          </span>
                        </div>
                      )}
                      {agenceDatas?.telephone !== null && (
                        <div className="content-info-prprietaire-item mb-2">
                          <IoCallOutline />
                          <span className="ps-3">
                            <a
                              className="no-link"
                              href={`tel${agenceDatas?.telephone}`}
                            >
                              {agenceDatas?.telephone}
                            </a>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="col-right-detail-proprietaire">
                    <div className="content-title-col-right gap-3">
                      <h4 className="title-col-right-detail-proprietaire">
                        Liste des propriétés gérées par{" "}
                        {agenceDatas?.nom_agence}
                      </h4>
                    </div>
                    <div className="content-tabs-detail-propriete mt-3">
                      <div
                        className="tab-pane fade show active"
                        id="villa"
                        role="tabpanel"
                        aria-labelledby="villa-tab"
                      >
                        <div className="row">
                          {!isLoading && proprietes?.results?.length === 0 && (
                            <AlertInfo message="Aucune donnée trouvéé" />
                          )}
                          {!isLoading &&
                            proprietes?.results?.map((item, index) => (
                              <div className="col-md-6 mb-3 d-flex" key={index}>
                                <div className="detail-proprietes-card-item card">
                                  <div className="position-relative">
                                    <img
                                      src={getImage(item?.pictures[0]?.image)}
                                      className="card-img-top custom-card-image-top-propriete"
                                      alt={item?.nom}
                                    />
                                  </div>
                                  <div className="content-prix-propriete-detail">
                                    <p className="text-prix-propriete-detail mb-0">
                                      {formatMontant(item?.prix)}
                                    </p>
                                  </div>
                                  <div className="card-body-detail-propriete">
                                    <div className="p-3 content-info-propriete-card">
                                      <p className="text-nom-propriete mb-1 text-start">
                                        {item?.nom}
                                      </p>
                                      <p className="card-text text-adresse-propriete mb-0 text-start">
                                        {item?.type_propriete} - {item?.adresse}
                                      </p>
                                    </div>
                                    <div className="flex-sb mt-3 b-t-1 align-items-center">
                                      {item?.type_propriete !== "immeuble" ? (
                                        <>
                                          <div className="b-rh-1 p-3-custom">
                                            <p className="text-description-propriete mb-0">
                                              {formatPlurial(
                                                item.nbre_chambres,
                                                "chambre"
                                              )}
                                            </p>
                                          </div>
                                          <div className="b-rh-1 p-3-custom text-start">
                                            <p className="text-description-propriete mb-0">
                                              {formatPlurial(
                                                item?.nbre_cuisines,
                                                "cuisine"
                                              )}
                                            </p>
                                          </div>
                                          <div className="p-3-custom text-start">
                                            <p className="text-description-propriete mb-0">
                                              {item.nbre_salle_de_bains}
                                              {item?.nbre_salle_de_bains <= 1
                                                ? " salle de bain"
                                                : " salles de bains"}
                                            </p>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="b-rh-1 p-3-custom">
                                          <p className="text-description-propriete mb-0">
                                            {formatPlurial(
                                              item?.nbre_appart,
                                              "appartement"
                                            )}
                                          </p>
                                        </div>
                                      )}
                                      <div className="p-3-custom mt-0 d-flex align-item-center">
                                        <NavLink
                                          to={getRoute(item)}
                                          // to={`/proprietes/gerer-par-agence/appartement/${item?.slug}`}
                                          className="btn btn-see-more p-0"
                                          state={item}
                                        >
                                          <AiFillEye className="custom-icon-detail-agence" />
                                        </NavLink>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {isLoading &&
                            [...Array(6)]?.map((item, i) => (
                              <ProprieteSkeleton key={i} />
                            ))}
                          <div className="flex-r">
                            <Pagination
                              page={page}
                              total={proprietes?.count}
                              perPage={perPage}
                              onPageChange={(page: number) => setPage(page)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsAgencePQNGPSB;

function getRoute(item: IPropriete) {
  let route = "/";
  if (item?.type_propriete === "immeuble") {
    route = `/proprietes/gerer-par-agence/immeuble/${item?.slug}`;
  } else {
    route = `/proprietes/gerer-par-agence/appartement/${item?.slug}`;
  }

  return route;
}
