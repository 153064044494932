import React, { useEffect, useState } from "react";
import { MdReply } from "react-icons/md";
import { ResponseCommentNew } from "./ResponsesComments";
import ReactQuill from "react-quill";
import { useAppSelector } from "../../../../redux/hooks";
import { isAdminUser } from "../../../../routes/router";
import { FiAlertCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import { usePostComment } from "./useForm/useCrudComment";
import { FormError } from "../../../common/CustomInputCheckbox";
import { BtnSubmit } from "../../../common/Button";
import { IComment, INews } from "../../../../utils/api/new/news.type";
import { getAvatar, getName } from "../../../../utils/Utils";
import moment from "moment";
import { IoMdTrash } from "react-icons/io";
import { useDelete } from "../../../../utils/helpers";
import {
  useDeleteCommentNewMutation,
  useDeleteNewMutation,
} from "../../../../utils/api/new/news.api";
import { Input } from "../../../common/Input";

export function CommentsNew({ item }: { item: INews }) {
  const { user } = useAppSelector((s) => s.user);
  const isConnected = useAppSelector((s) => !!s.user?.user?.id);
  const is_admin = useAppSelector((s) => isAdminUser(s.user?.user));
  const [expand, setExpand] = useState(false);
  const [itemToShow, setItemToShow] = useState(4);
  useEffect(() => {
    if (itemToShow >= item.comments?.length) {
      setExpand(true);
    } else {
      setExpand(false);
    }
  }, [itemToShow]);

  const showMore = () => {
    if (itemToShow >= item.comments?.length) {
      setItemToShow(4);
    } else {
      setItemToShow(itemToShow + 4);
    }
  };
// console.log('item', item)
  return (
    <div className="container-col-left-news-item">
      <div className="">
        <div className="container-messages-number py-3">
          <p className="messages-number">
            {item.comments?.length}{" "}
            {item.comments?.length > 1 ? "Commentaires" : "Commentaire"}
          </p>
        </div>
        <div className="my-4">
          {item.comments?.length
            ? item.comments.slice(0, itemToShow).map((comment: IComment) => (
                <div className="reponse-comment-container" key={comment?.slug}>
                  <div className="comment-message-container mb-3">
                    <div className="container-profil-message">
                      <img
                        src={getAvatar(comment?.auteur?.avatar)}
                        alt="Profil"
                        className="img-profil-message"
                      />
                    </div>
                    <div className="container-content-message custom-container-content-message ps-3">
                      <div className="flex-sb flex-sb-mobile">
                        <div>
                          <p className="profil-name">
                            {comment?.auteur ? getName(comment?.auteur) : comment?.prenom + " " + comment?.nom}
                          </p>
                          <p className="comment-pub-date-color">
                            {moment(comment?.created_at)?.format(
                              "DD MMMM YYYY"
                            )}
                          </p>
                        </div>
                        <div>
                          <button
                            className="btn btn-repondre-message"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseReponseComment${comment?.id}`}
                            aria-expanded="false"
                            aria-controls={`#collapseReponseComment${comment?.id}`}
                          >
                            <MdReply />
                            <span className="ps-2">Répondre</span>
                          </button>
                          {is_admin || comment?.auteur?.slug === user?.slug ? (
                            <DeleteCommentNews item={comment} />
                          ) : null}
                        </div>
                      </div>
                      <div className="container-comment-text py-2  pe-4">
                        <p
                          className="comment-text"
                          dangerouslySetInnerHTML={{
                            __html: comment.content || "",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id={`collapseReponseComment${comment.id}`}
                  >
                    <ResponseCommentNew
                      comment={comment}
                      isConnected={isConnected}
                      is_admin={is_admin}
                      newSlug={item?.slug}
                    />
                  </div>
                </div>
              ))
            : null}

          <div className="text-center container-btn-load-more-comment my-5">
            {item.comments?.length > 4 ? (
              <button
                className="btn btn-load-more-comment trans-0-2"
                onClick={showMore}
              >
                {expand ? "Voir moins" : "Voir plus"}
              </button>
            ) : null}
          </div>
        </div>
        {
		// isConnected && 
		!is_admin
		 ? (
          <CommentForm item={item} isConnected={isConnected} />
        ) :  
		null
		// !is_admin ? (
    //       <div className="px-3">
    //         <div className="kanimmo-alert kanimmo-alert-info py-3" role="alert">
    //           <FiAlertCircle style={{ fontSize: 20 }} />
    //           <h4 style={{ fontSize: 18 }}>
    //             Veuillez vous <Link to="/connexion">connecter</Link>, pour
    //             pouvoir commenter
    //           </h4>
    //         </div>
    //       </div>
    //     ) : null
		}
      </div>
    </div>
  );
}

function CommentForm({ item, isConnected }: { item: INews, isConnected: boolean }) {
  const { errors, onSubmit, handleChangeCommentField, comment, isLoading, register } =
    usePostComment(item, isConnected);
  return (
    <div className="add-comment-container">
      <div className="container-messages-number py-3">
        <p className="messages-number">AJOUTER UN COMMENTAIRE</p>
      </div>

      <div className="mt-4 container-add-comment-forum">
        <div className="form-content pt-4">
          <form onSubmit={onSubmit}>
            <div className="row">
              {!isConnected && (
                <>
                  <div className="col-6 mb-3 ">
                    <Input
                      label="Prénom"
                      id="prenom"
                      type="text"
                      placeholder="Prénom"
                      {...register("prenom")}
                      error={errors?.prenom}
                      className="form-control"
                    />
                  </div>
                  <div className="col-6 mb-3 ">
                    <Input
                      label="Nom"
                      id="nom"
                      type="text"
                      placeholder="Nom"
                      {...register("nom")}
                      error={errors?.nom}
                      className="form-control"
                    />
                  </div>
                  <div className="col-12 mb-3 ">
                    <Input
                      label="Email"
                      id="email"
                      type="text"
                      placeholder="Email"
                      {...register("email")}
                      error={errors?.email}
                      className="form-control"
                    />
                  </div>
                </>
              )}
              <div className="col-12 mb-4 form-group">
              <ReactQuill
                className="editor-forum-response"
                value={comment}
                onChange={handleChangeCommentField}
              />
              {<FormError error={errors?.content} />}
            </div>
            </div>
            {/* <div className="mb-4 form-group">
              <ReactQuill
                className="editor-forum-response"
                value={comment}
                onChange={handleChangeCommentField}
              />
              {<FormError error={errors?.content} />}
            </div> */}
            <div className="btn-comment-form text-right">
              <BtnSubmit label="COMMENTER" isLoading={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export function DeleteCommentNews({ item }: { item: IComment }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteCommentNewMutation();
  const onDelete = useDelete<IComment>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "ce commentaire?",
    successMessage: "Commentaire supprimée",
  });
  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <IoMdTrash />
    </button>
  );
}
