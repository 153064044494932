/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
  useGetLocataireArchiveBySlugQuery,
  useGetLocataireByAgenceBySlugQuery,
} from "../../../../utils/api/locataire/locataire.api";
import { useAppSelector } from "../../../../redux/hooks";
import { IoMdTrash } from "react-icons/io";
import {
  UseArchiveLocataire,
  UseDeleteLocataire,
} from "../../../TableauDebord/Agence/Locataires/CRUDLocataire/useAddOrUpdateLocataire";
import { CustomPagination } from "../../../common/CustomPagination";
import { AlertInfo } from "../../../common/Alert";
import VillasTableSkeleton from "./VillasTableSkeleton";
import { GeneratePassword } from "../ProprietaireAgenceTable/ProprietaireAgenceTable";
import { MdArchive, MdUnarchive } from "react-icons/md";
import { Color } from "../../../../utils/theme";
import Swal from "sweetalert2";
import { IProprietaire } from "../../../../utils/api/proprietaire/proprietaire.type";

function LocataireArchiveTable({
  name,
  uptodate,
  type,
}: {
  name?: string | null;
  uptodate?: string | null;
  AgenceSlug?: string;
  isAgence?: boolean;
  type?: string | null;
}) {
  const { user } = useAppSelector((s) => s?.user);
  const userType = user?.user_type;

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  // const { data = { results: [] }, isLoading } =
  //   useGetLocataireByAgenceBySlugQuery({
  //     name,
  //     uptodate,
  //     type,
  //     page,
  //     limit: perPage,
  //     slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
  //   });

  const {
    data = { results: [] },
    error,
    isLoading,
  } = useGetLocataireArchiveBySlugQuery(
    user?.agence_id ? user?.agence_id?.slug : user?.slug
  );

  // console.log("Locataires", data?.results);
  

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        {userType !== "juriste" && (
          <div className="table-actions-btn-container-commandes gap-2 d-flex">
            <ArchiveLocataireBtn2 slug={row?.slug} row={row} />
          </div>
        )}
      </>
    );
  };
  const seeDetailFormatter = (cell: any, row: any) => {
    return (
      <>
        {/* {userType !== "juriste" && */}
        <div className="table-actions-btn-container-commandes d-flex">
          <div>
            <NavLink
              className="btn btn-see-list"
              title="Voir"
              to={`/agence/liste-locataire/${row?.slug}`}
              state={row}
            >
              Voir
            </NavLink>
          </div>
        </div>
        {/* } */}
      </>
    );
  };

  const proprieteFormatter = (cell: any) => <div>{cell?.nom}</div>;
  const statusFormatter = (cell: any, row: any) => (
    <div style={{ color: row?.is_archive ? "orange" : "green" }}>
      {row?.is_archive ? "Archivé" : "Active"}
    </div>
  );

  const fullnameFormatter = (cell: any, row: any) => (
    <div>{row?.prenom + " " + row?.nom}</div>
  );

  const columns = [
    {
      dataField: "nom",
      text: "Nom du locataire",
      formatter: (cell: any, row: any) => fullnameFormatter(cell, row),
    },
    {
      dataField: "telephone",
      text: "Téléphone",
      formatter: (cell: any) => (
        <a className="no-link" href={`tel:${cell}`}>
          {cell}
        </a>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell: any, row: IProprietaire) => <a className='no-link' href={`mailto:${cell}`}>{cell}</a>,
    },
    // {
    //   dataField: "propriete",
    //   text: "Propriete",
    //   formatter: (cell: any) => proprieteFormatter(cell),
    // },
    {
      dataField: "",
      text: "Détails",
      formatter: (cell: any, row: any) => seeDetailFormatter(cell, row),
      style: { textAlign: "center" },
    },
    userType !== "juriste" && {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <VillasTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            // data={data?.results}
            data={data?.results
              ?.slice()
              .sort(
                (a: any, b: any) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              // <AlertInfo message="Aucune donnée trouvéé" />
              <AlertInfo message="Aucune locataire archives trouvés" />
            )}
          />
          <CustomPagination
            nbPages={3}
            page={page}
            perPage={perPage}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
          />
        </>
      )}
    </>
  );
}

export default LocataireArchiveTable;

function DeleteLocataireBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteLocataire(slug);

  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
    >
      <IoMdTrash />
    </button>
  );
}
export function ArchiveLocataireBtn2({ slug, row }: { slug: string; row: any }) {
  const onArchive = UseArchiveLocataire(slug);
  const navigate = useNavigate();
  const onDeArchive = () => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir désarchiver ce locataire?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/agence/modifier-locataire", { state: row });
      }
    });
  };
  const onActif = () => {
    Swal.fire({
      title: `Cette locataire est active sur la plateforme.`,
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  return (
    <>
      {!row?.is_archive ? (
        <button
          className="btn btn-action-administratif-proprietaire with-tooltip"
          data-tooltip-content="Archiver"
          onClick={() => onActif()}
        >
          <MdArchive />
        </button>
      ) : (
        <button
          className="btn btn-action-administratif-proprietaire with-tooltip ms-3"
          data-tooltip-content="Désarchiver"
          onClick={() => onDeArchive()}
        >
          <MdUnarchive />
        </button>
      )}
    </>
  );
}
