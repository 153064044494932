import React, { useState } from "react";
import { AiFillPlusSquare } from "react-icons/ai";
import { useAppSelector } from "../../../../../../redux/hooks";
import { isProprioJuriste } from "../../../../../../utils/Utils";
import { AlertInfo } from "../../../../../common/Alert";
import AjouterFactureModal from "../../../../../modal/agence/AjouterFactureModal";
import FacturesTable from "../../../../../tables/Proprietaire/ProprietaireGererParProprietaire/Factures/FacturesTable";

function Factures() {
  const { user } = useAppSelector((s) => s?.user);
  const [name, setName] = useState<string>("");

  return (
    <div className="bg-white p-3">
      <div className="locataire-requete-filtre-container flex-sb my-4">
        <form className="row g-3 locataire-requete-filtre-form">
          {!isProprioJuriste(user) && (
            <>
              <div className="col-auto">
                <label
                  htmlFor="filtreLogement"
                  className="locataire-requete-filtre-label"
                >
                  Filtrer
                </label>
              </div>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control locataire-requete-filtre-input"
                  id="filtreLogement"
                  placeholder="Entrer un nom"
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setName(e?.currentTarget?.value)
                  }
                />
              </div>
            </>
          )}
        </form>
      </div>
      <div>
        {!!isProprioJuriste(user) ? (
          <AlertInfo message="Vous n'avez pas accès au données de cette section !" />
        ) : (
          <FacturesTable name={name} />
        )}
      </div>
      <AjouterFactureModal />
    </div>
  );
}

export default Factures;
