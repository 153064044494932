import React from "react";
import { BsEmojiWink, BsFillMicFill } from "react-icons/bs";
import { FiPaperclip } from "react-icons/fi";
import { HiOutlineCamera } from "react-icons/hi";
import { RiSendPlaneFill } from "react-icons/ri";
import Profil from "../../../../assets/appImages/avata.png";

const MobileDetailMessage = () => {
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-tabs-container mt-5">
          <div className="dtails-messages-tabs-component">
            <div className="d-flex gap-2 align-items-center border-bottom p-3">
              <div className="content-header-img-user-message-mobile">
                <img
                  src={Profil}
                  className="image-profil-entete pe-0 image_responsive"
                  alt="profil detail message"
                  style={{ width: "60", height: "60" }}
                />
              </div>
              <div className="content-header-info-user-message-mobile">
                <div className="msg-user-infos-container">
                  <div className="msg-user-name">
                    <p className="profil-detail-message mb-0">Mbaye Niass</p>
                  </div>
                  <div className="bloc-user-disconnect-time msg-user-lastonline">
                    <p className="text-disconnect-time mb-0">
                      En ligne hier à 14:22
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bloc-details pb-5">
              <Message />
              <Response />
            </div>
            <ChatInput />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileDetailMessage;

function Message() {
  return (
    <div className="position-relative received-msg-item">
      <div className="bloc-message-recu p-3">
        <div className="text-message-recu">Hello</div>
      </div>
      <div className="d-flex align-items-center pb-2">
        <span className="time-detail-message ps-3">8:39</span>
      </div>
    </div>
  );
}

function Response() {
  return (
    <div className="bloc-reponse">
      <div className="position-relative sending-msg-item">
        <div className="bloc-message-envoyer p-3">
          <div className="text-message-envoyer">Hoooo</div>
        </div>
        <div className="">
          <div className="d-flex justify-content-end align-items-center">
            <span className="time-detail-message px-2">13:12</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function ChatInput() {
  return (
    <div className="pt-3 border-top">
      <form>
        <div className="left-footer">
          <div className="left-footer-container">
            <div className="input-group">
              <div className="input-container">
                <div className="container-display-input-message">
                  <div className="inp w-100">
                    <textarea
                      className="textarrea-message-custom form-control"
                      rows={1}
                      required
                      name="reponse"
                    >
                      Écrivez votre message ici
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-container custom-btn-chat-container">
              <button type="submit" className="btn btn-send-message-tabs">
                <RiSendPlaneFill />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
