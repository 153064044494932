/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Facebook from "../../../../../assets/icons/facebook.png";
import Twitter from "../../../../../assets/icons/twitter.png";
import Linkedin from "../../../../../assets/icons/linkedin.png";
import bed from "../../../../../assets/icons/bed.png";
import bathtub from "../../../../../assets/icons/bathtub.png";
import kitchen from "../../../../../assets/icons/kitchen.png";
import plant from "../../../../../assets/icons/plant.png";
import pool from "../../../../../assets/icons/pool.png";
import carpark from "../../../../../assets/icons/carpark.png";
import officechair from "../../../../../assets/icons/officechair.png";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import "./AgencesPQNGPSB.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  createMarkup,
  formatMontant,
  getAvatar,
  getImage,
  isProprietaireAgence,
  isProprietaireOwner,
  isProprioJuriste,
  onHide,
  showModal,
  useLocationState,
} from "../../../../../utils/Utils";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { AlertInfo } from "../../../../common/Alert";
import { AiFillPlusSquare } from "react-icons/ai";
import ProprieteItemImmeuble from "../../../Agence/ProprietesAgence/ProprieteItemImmeuble";
import {
  useFindProprieteBySlugQuery,
  useLazyGetAppartmentByProprieteQuery,
  useProprieteFromLocation,
} from "../../../../../utils/api/propriete/propriete.api";
import Pagination from "../../../../common/Pagination";
import { IUser } from "../../../../../utils/api/user/user.type";
import { Button } from "reactstrap";
import { useCompareNpPropieteAndMaxOffreQuery } from "../../../../../utils/api/offer/expireataabonnement.api";
import Swal from "sweetalert2";
import ChangeOfferModal from "../../../Agence/OptionAgence/OptionsStep/ChangeOfferModal";

function DetailsImmeubles() {
  const [page, setPage] = useState(1);
  const { user } = useAppSelector((state) => state.user);
  // const userType = user?.user_type;
  // const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams<{ slug: string }>();
  const itemState = useLocationState<IPropriete>(undefined);
  const [
    getAppartments,
    { isSuccess, isLoading, data: appartments = { results: [], count: 0 } },
  ] = useLazyGetAppartmentByProprieteQuery();

  const navigate = useNavigate();
  const [item] = useProprieteFromLocation();

  const callChangeOffreModal = () => {
    onHide("AjouterProprietesModal");
    Swal.fire({
      icon: "error",
      title:
        "Veuillez modifier votre abonnement pour pouvoir effectuer cette opération.",
      showConfirmButton: false,
      timer: 5000,
    });
    showModal("changeOfferModal");
  };

  const { data } = useCompareNpPropieteAndMaxOffreQuery(
    user.slug
  );


  // const { data: propriete } = useFindProprieteBySlugQuery(
  //   itemState?.slug || (slug as string)
  // );

  useEffect(() => {
    if (slug) {
      getAppartments({ slug: slug as string, page, limit: 10 });
    }
  }, [slug]);

  useEffect(() => {
    if (isSuccess) {
      // console.log(appartments, item);
    }
  }, [isSuccess]);

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <div>
            <div
              onClick={() => navigate(-1)}
              className="kanimmo-dash-locataire-requetes-header-titre no-link"
            >
              <BiArrowBack />
              <span className="ps-3">{item?.nom}</span>
            </div>
            <span className="kanimmo-detail-agence-adresse pt-2">
              {item?.pays}, {item?.ville}, {item?.adresse}
            </span>
          </div>
          {item?.prix && parseInt(item?.prix as any) > 0 && (
            <div>
              <div className="kanimmo-detail-agence-titre-prix-location">
                Prix de location
              </div>
              <div className="kanimmo-detail-agence-prix-location">
                {formatMontant(item?.prix)}
              </div>
            </div>
          )}
        </div>
        <div className="kanimmo-proprietes-container">
          <div className="proprietaire-component-container">
            <div className="content-detail-proprietaire">
              <div className="row">
                <div className="col-md-4">
                  <div className="col-left-detail-proprietaire">
                    <div className="content-title-detail-proprietaire">
                      <p className="title-detail-proprietaire">
                        Information du propriétaire{" "}
                        {/* {userType !== "agence"
                          ? "du propriétaire"
                          : "de l'agence"} */}
                      </p>
                    </div>
                    <div className="text-center pt-3">
                      <div className="content-pp-proprietaire pb-2">
                        <img
                          src={getAvatar(
                            item?.proprietaire?.avatar || item?.agence?.avatar
                          )}
                          alt="Avatar"
                          className="pp-proprietaire"
                        />
                      </div>
                      <p className="title-detail-proprietaire">
                        {item?.proprietaire
                          ? item?.proprietaire?.prenom +
                            " " +
                            item?.proprietaire?.nom
                          : item?.agence?.nom_agence}
                      </p>
                      <p className="title-detail-proprietaire">
                        {item?.proprietaire?.adresse || item?.agence?.adresse}
                      </p>
                    </div>
                    <div className="custom-btn-social-media-container my-2">
                      <span className="input-group-text input-group-text-img">
                        <img
                          src={Facebook}
                          alt="social media"
                          className="social-media-contact"
                        />
                      </span>
                      <span className="input-group-text input-group-text-img">
                        <img
                          src={Linkedin}
                          alt="social media"
                          className="social-media-contact"
                        />
                      </span>
                      <span className="input-group-text input-group-text-img">
                        <img
                          src={Twitter}
                          alt="social media"
                          className="social-media-contact"
                        />
                      </span>
                    </div>
                    <div className="content-info-proprietaire">
                      <div className="content-info-prprietaire-item mb-2">
                        <MdOutlineMailOutline />
                        <span className="ps-3">
                          <a
                            className="no-link"
                            href={`mailto:${
                              item?.proprietaire?.email || item?.agence?.email
                            }`}
                          >
                            {item?.proprietaire?.email || item?.agence?.email}
                          </a>
                        </span>
                      </div>
                      <div className="content-info-prprietaire-item mb-2">
                        <IoCallOutline />
                        <span className="ps-3">
                          <a
                            className="no-link"
                            href={`tel:${
                              item?.proprietaire?.telephone ||
                              item?.agence?.telephone
                            }`}
                          >
                            {item?.proprietaire?.telephone ||
                              item?.agence?.telephone}
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="col-right-detail-proprietaire mb-3">
                    <div className="content-tabs-detail-propriete">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="custom-img-detail-appart-container">
                            <img
                              src={getImage(item?.pictures[0]?.image)}
                              alt="img details appartement"
                              className="custom-img-detail-appart"
                            />
                            <p className="custom-img-detail-appart-legend">
                              Vue de face
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="kanimmo-detail-appart-titre-description-contrainer my-4">
                            <h5 className="kanimmo-detail-appart-titre-description">
                              {item?.nom}
                            </h5>
                          </div>
                          {item?.type_propriete !== "immeuble" && (
                            <div className="kanimmo-detail-appart-caracteristiques-contrainer">
                              <div className="kanimmo-detail-appart-titre-caracteristique mb-3">
                                Caractéristiques
                              </div>
                              <div className="kanimmo-detail-appart-caracteristiques-image-container mb-3">
                                {item?.nbre_chambres > 0 ? (
                                  <CaracteristiqueCard
                                    image={bed}
                                    title="chambres"
                                    number={item?.nbre_chambres}
                                  />
                                ) : null}
                                {item?.nbre_salle_de_bains > 0 ? (
                                  <CaracteristiqueCard
                                    image={bathtub}
                                    title="Salle de bain"
                                    number={item?.nbre_salle_de_bains}
                                  />
                                ) : null}
                                {item?.nbre_cuisines > 0 ? (
                                  <CaracteristiqueCard
                                    image={kitchen}
                                    title="Cuisine"
                                    number={item?.nbre_cuisines}
                                  />
                                ) : null}
                                {item?.jardin ? (
                                  <CaracteristiqueCard
                                    image={plant}
                                    title="Jardin"
                                    number={1}
                                  />
                                ) : null}
                                {item?.piscine ? (
                                  <CaracteristiqueCard
                                    image={pool}
                                    title="Piscine"
                                    number={1}
                                  />
                                ) : null}
                                {item?.meuble ? (
                                  <CaracteristiqueCard
                                    image={officechair}
                                    title="Entièrement meublé"
                                    number={6}
                                  />
                                ) : null}
                                {item?.parking ? (
                                  <CaracteristiqueCard
                                    image={carpark}
                                    title="Parking"
                                    number={7}
                                  />
                                ) : null}
                              </div>
                            </div>
                          )}
                          <div className="kanimmo-detail-appart-caracteristiques-contrainer">
                            <div className="kanimmo-detail-appart-titre-caracteristique mb-3">
                              Description
                            </div>
                            <div
                              className="kanimmo-detail-appart-caracteristiques-description-container mb-3"
                              dangerouslySetInnerHTML={createMarkup(
                                item?.description
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-right-detail-proprietaire mb-3">
                    <div className="row">
                      <div className="col-md-12">
                        <Carousel
                          showThumbs={false}
                          // showIndicators={false}
                          autoPlay
                          // interval={5000}
                          infiniteLoop
                        >
                          {item?.pictures?.map((item: any) => (
                            <div key={item.name}>
                              <img
                                src={getImage(item?.image)}
                                alt="img appartement"
                                className="custom-img-appart-details-appart"
                              />
                            </div>
                          ))}
                        </Carousel>
                        {item?.pictures?.length === 0 && (
                          <AlertInfo message="Pas d'images disponible" />
                        )}
                      </div>
                      {item?.url_youtube && (
                        <div className="col-md-12">
                          <div className="mt-4 mb-3">
                            <a
                              href={item?.url_youtube}
                              target="_blank"
                              className="kanimmo-custom-url-link-youtube"
                            >
                              Lien vers la vidéo youtube
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-right-detail-proprietaire mb-3">
                    <div className="row">
                      {item?.type_propriete === "immeuble" && (
                        <div className="col-md-12">
                          <div className="kanimmo-detail-appart-titre-historique-locataire custom-detail-immeubles-titre-ajout mb-3">
                            <span>Appartements de l'immeuble</span>
                            <div className="container-btn-add">
                              {!data?.can_add_proprete ? (
                                <Button
                                  data-bs-toggle="modal"
                                  // data-bs-target="#AjouterProprietesModald"
                                  className="btn btn-see-more"
                                  onClick={() => callChangeOffreModal()}
                                >
                                  <AiFillPlusSquare style={{ fontSize: 22 }} />
                                  <span className="ps-2">
                                    Ajouter un appartement
                                  </span>
                                </Button>
                              ) : (
                                <NavLink
                                  to={getRoute(item, user)}
                                  className="btn btn-see-more"
                                  state={{
                                    type_propriete: "appartement",
                                    immo: item,
                                  }}
                                >
                                  <AiFillPlusSquare style={{ fontSize: 22 }} />
                                  <span className="ps-2">
                                    Ajouter un appartement
                                  </span>
                                </NavLink>
                              )}
                            </div>
                            <div
                              className="modal fade"
                              id="changeOfferModal"
                              aria-labelledby="changeOfferModalLabel"
                              aria-hidden="true"
                            >
                              <ChangeOfferModal
                                type={
                                  user?.user_type === "agence"
                                    ? "agence"
                                    : "proprietaire"
                                }
                              />
                            </div>
                          </div>

                          {!isLoading &&
                            (appartments?.results?.length ? (
                              <>
                                <div className="row d-flex">
                                  {appartments?.results?.map((item, i) => (
                                    <ProprieteItemImmeuble item={item} />
                                  ))}
                                </div>
                                <Pagination
                                  page={page}
                                  total={appartments?.count}
                                  perPage={10}
                                  onPageChange={(page: number) => setPage(page)}
                                />
                              </>
                            ) : (
                              <AlertInfo message="Pas d'appartement disponible" />
                            ))}
                        </div>
                      )}

                      {item?.type_propriete !== "immeuble" && (
                        <div className="col-md-12 mt-4">
                          <div className="kanimmo-detail-appart-titre-historique-locataire mb-3">
                            Taux de rémunération agence
                          </div>
                          <div className="historie-paie-tab mb-3">
                            <div className="d-flex flex-column justify-content-between">
                              <div className="historie-paie-tab-mensulite mb-2">
                                {item?.commission_agence
                                  ? item?.commission_agence + "%"
                                  : "0%"}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsImmeubles;

function CaracteristiqueCard({
  image,
  title,
  number,
}: {
  image: string;
  title: string;
  number: number;
}) {
  return (
    <div className="kanimmo-detail-appart-caracteristiques-image-item">
      <img
        src={image}
        alt="img illustration"
        className="kanimmo-detail-appart-caracteristiques-image"
      />
      <p className="kanimmo-detail-appart-caracteristiques-titre-image">
        {number} {title}
      </p>
    </div>
  );
}

function getRoute(item: IPropriete, user: IUser) {
  let route = "/";

  if (item?.type_propriete === "immeuble") {
    if (user?.user_type === "agence") {
      route = `/agence/ajouter-appartement`;
    }

    if (isProprietaireOwner(user) || isProprioJuriste(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/ajouter-appartement`;
    }

    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/ajouter-appartement`;
    }
  }

  return route;
}
