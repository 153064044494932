import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useLocataireInfoDetailsFromLocation } from "../../../../../../utils/api/locataire/locataire.api";
import { IPaiement } from "../../../../../../utils/api/paiement/paiement.type";
import {
  formatMontant,
  formattedDate,
  isProprioJuriste,
} from "../../../../../../utils/Utils";
import { AlertInfo } from "../../../../../common/Alert";

function HistoriquePaiementStep() {
  const [isLoading, setIsLoading] = useState(true);
  const [itemState, findBySlug] = useLocataireInfoDetailsFromLocation();
  const { user } = useAppSelector((s) => s?.user);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);
  if (isProprioJuriste(user)) {
    return (
      <div className="card-historie-paie-tab-container kanimmo-tabs-container container-form-add-locataire no-access-apge">
        <div className="row container-dashboard-page">
          <AlertInfo message="Vous n'avez pas accès au données de cette page !" />
        </div>
      </div>
    );
  }

  return (
    <div className="card-historie-paie-tab-container p-3">
      <div className="row">
        <div className="col-md-12">
          {!isLoading && itemState?.paiements?.length > 0 ? (
            itemState?.paiements?.map((item: IPaiement, index) => (
              <div className="historie-paie-tab mb-3" key={index}>
                <div className="d-flex justify-content-between">
                  <div className="historie-paie-tab-mensulite">
                    {item?.type_paiement}
                  </div>
                  <div className="historie-paie-tab-montant">
                    {formatMontant(item?.montant)}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="historie-paie-tab-date">
                    {formattedDate(item?.date_du)}
                  </div>
                  {/* <div className="historie-paie-tab-recu">Générer un reçu</div> */}
                </div>
              </div>
            ))
          ) : (
            <AlertInfo message="Pas d'historique de paiements" />
          )}
        </div>
      </div>
    </div>
  );
}

export default HistoriquePaiementStep;
