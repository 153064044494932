import React, { useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai';
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useAppSelector } from '../../../../../redux/hooks';
import { ILocataire } from '../../../../../utils/api/locataire/locataire.type';
import { useGetPaiementByLocataireBySlugQuery } from '../../../../../utils/api/paiement/paiement.api';
import { IPropriete } from '../../../../../utils/api/propriete/propriete.type';
import { formattedDate } from '../../../../../utils/Utils';
import { AlertInfo } from '../../../../common/Alert';
import { CustomPagination } from '../../../../common/CustomPagination';
import DocumentQuittanceModal from '../../../../modal/locataire/DocumentQuittanceModal';
import QuittanceSkeleton from '../../../agence/Administratif/Quittance/QuittanceSkeleton';

function QuittanceLocataireTable() {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const locataire = useAppSelector((s) => s.user.user);
    const { data, isLoading } = useGetPaiementByLocataireBySlugQuery({
        slug: locataire?.slug,
        page: page,
        limit: perPage,
    });

    const quittances = data?.results?.filter((item) => item?.quittance !== null);
  
    const actionFormatter: any = (cell: any, row: any) => {
        return (
          <div className="table-actions-btn-container-commandes gap-2 d-flex">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              data-bs-toggle="modal"
              data-bs-target={`#VoirQuittanceModal${row?.id}`}
            >
              <AiOutlineEye />
            </button>
            <div
              className="modal fade"
              id={`VoirQuittanceModal${row?.id}`}
              aria-labelledby="VoirQuittanceModalLabel"
              aria-hidden="true"
            >
              <DocumentQuittanceModal item={row} />
            </div>
          </div>
        );
      };
    
      const columns = [
        {
          dataField: "id",
          text: "N°",
          formatter: (cell: string) => "#" + cell,
        },
        {
          dataField: "date_du",
          text: "Date",
          style: { textAlign: "left" },
          formatter: (cell: string) => formattedDate(cell),
        },
        {
          dataField: "locataire",
          text: "Client",
          style: { textAlign: "left" },
          formatter: (cell: ILocataire) => cell?.prenom + " " + cell?.nom,
        },
        {
          dataField: "propriete",
          text: "Location",
          style: { textAlign: "left" },
          formatter: (cell: IPropriete) => cell?.nom,
        },
        {
          dataField: "created_at",
          text: "Date de paiement",
          style: { textAlign: "left" },
          formatter: (cell: string) => formattedDate(cell),
        },
        {
          dataField: "montant",
          text: "Facture",
          style: { textAlign: "left" },
          formatter: (cell: IPropriete) => cell + " FCFA",
        },
        {
          dataField: "actions",
          text: "Actions",
          style: { textAlign: "center" },
          formatter: (cell: any, row: any) => actionFormatter(cell, row),
          headerStyle: () => {
            return { width: "80px", whiteSpace: "normal" };
          },
        },
      ];
    
      return (
        <>
          {isLoading && <QuittanceSkeleton />}
          {!isLoading && (
            <>
              <BootstrapTable
                keyField="id"
                // data={quittances}
                data={quittances?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
                columns={columns}
                bordered={true}
                condensed={false}
                responsive
                wrapperClasses="table-responsive locataire-table"
                noDataIndication={() => (
                  <AlertInfo message="Aucune Quittance trouvéé" />
                )}
              />
              <CustomPagination
                nbPages={1}
                page={page}
                onChange={(page, perPage) => {
                  setPerPage(perPage);
                  setPage(page);
                }}
                perPage={perPage}
              />
            </>
          )}
        </>
      );
}

export default QuittanceLocataireTable
