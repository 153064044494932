import { IAgence } from "../agence/agence.type";
import { ILocataire } from "../locataire/locataire.type";
import { IProprietaire } from "../proprietaire/proprietaire.type";


export enum TypesLitiges {
	assignationExpulsion = "assignation_expulsion",
	commandementPayer = "commandement_payer",
	jugement = "jugement",
}
export enum StatusLitiges {
  nonRegler = "non_regler",
  enCours = "encours",
  regler = "regler",
  revision = "revision",
  assignationExpulsion = "assignation_expulsion",
  commandementPayer = "commandement_payer",
  jugement = "jugement",
}

export interface ILitiges {
  slug: string;
  id: number;
  locataire: ILocataire | any;
  statut: StatusLitiges;
  type_litige: TypesLitiges;
  date: Date;
  titre: string;
  agence: IAgence;
  proprietaire: IProprietaire;
  description: string;
  constat: string;
  document: File | string | any;
  created_at: Date;
  historiques: any[]
}

export type LitigesFormData = Partial<ILitiges>;